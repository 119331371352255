import React, { createContext, FC, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Logger from "../../services/Logger";
import classNames from "classnames";
import { format } from "date-fns";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DocumentReference } from "@firebase/firestore-types";
import { AppState } from "../../store/rootReducer";
import { getProofers, getTranscribers } from "../../store/user/actions";
import {
  setArchivedMeetings,
  updateMeetingRevenue,
  updateArchivedMeeting,
} from "../../store/archive/actions";
import { setClientsOptions } from "../../store/client/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import { ArchiveFormatedJobs, ElasticJob } from "../../models/meeting";
import { MeetingPreviewData } from "../../models";
import { JobData } from "../../models/job";
import { ExportConfigData } from "../ExportConfig/ExportConfigTypes";

import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import InfinityMeetingsTable from "../../components/MeetingsTable/InfiniteMeetingsTable";
import EditModal from "../../components/Modals/EditModal/EditModal";
import NoteModal from "../../components/Modals/NoteModal/NoteModal";
import ContextMenu from "./ContextMenu";
import ExportModal from "../../components/common/ExportModal/ExportModal";
import JobAuditModal from "../../components/common/JobAuditModal/JobAuditModal";
import TranslationJobModal from "../../components/Modals/TranslationJobModal/TranslationJobModal";
import ExportModalV3 from "../../components/common/ExportModalV3/ExportModalV3";

import TimeService from "../../services/TimeService";
import FirebaseService from "../../services/FirebaseService";
import MeetingService from "../../services/MeetingService";
import ExportService from "../../services/ExportService";
import ElasticSearchService from "../../services/ElasticSearchService";
import EditorService from "../../services/EditorService";

import { clearMeetings } from "../../store/meeting/actions";

import { getJobsUrl } from "../../utils/url";
import { archiveExcelFields } from "../../constants/excelFields";

import "./ArchivePage.scss";

const logger = Logger("InfinityArchivePage");

const InfiniteArchivePage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const archivedMeetings = useSelector(
    (state: AppState) => state.archiveStore.archivedMeetings
  );
  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const proofers = useSelector((state: AppState) => state.userStore.proofers);

  const [meetingsTableData, setMeetingsTableData] = useState<
    ArchiveFormatedJobs[]
  >([]);

  const selectedJobs = useRef<any[]>([]);
  const setSelectedJobs = (jobs: any) => {
    selectedJobs.current = jobs;
  };
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openExportV3Modal, setOpenExportV3Modal] = useState(false);
  const [exportV3JobsData, setExportV3JobsData] = useState<JobData[]>([]);

  const [selectedMeeting, setSelectedMeeting] = useState<ElasticJob[] | null>(
    null
  );
  const [contextMenuJob, setContextMenuJob] = useState<MeetingPreviewData>();
  const [dataCount, setDataCount] = useState(0);
  const defaultFilters = {
    status: {
      value: [5],
      type: "normal",
    },
  };
  const archivePageRef = useRef(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [clientState, setClientState] = useState<
    { id: string; name: string }[]
  >();
  const [exportConfig, setExportConfig] = useState<ExportConfigData>();
  const [exportConfigPresets, setExportConfigPresets] = useState<
    { id: string; config: ExportConfigData }[]
  >([]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [translationJobModal, setTranslationJobModal] = useState(false);
  const [meetingId, setMeetingId] = useState<string>();

  const getClients = async () => {
    const clientsObj: {
      id: string;
      name: string;
    }[] = await FirebaseService.getClients();
    const clientsOptions = clientsObj.map((client) => {
      return { value: client.id, icon: "", label: client.name };
    });
    dispatch(setClientsOptions(clientsOptions));
    setClientState(clientsObj);
    return clientsObj;
  };

  const validRoles = ["super_user"];
  useEffect(() => {
    const init = async () => {
      if (loggedInUser) {
        if (!validRoles.includes(loggedInUser.role)) {
          history.push("/");
          return;
        }
        dispatch(setLoadingReason(t("loading_archived_meetings")));
        dispatch(getTranscribers());
        dispatch(getProofers());
        await getClients();

        archivedMeetings.length <= 0
          ? await loadArchiveData()
          : dispatch(clearLoadingReason());
      }
    };

    if (loggedInUser && loggedInUser.role) {
      init();
    }
    return () => {
      dispatch(setArchivedMeetings([]));
      setMeetingsTableData([]);
    };
  }, [loggedInUser]);

  const editJob = async (newJob: MeetingPreviewData) => {
    let updatedMeetings = meetingsTableData;
    setMeetingsTableData((m) => {
      updatedMeetings = m;
      return m;
    });
    const mappedJob = MeetingService.formatJobToElasticJob(
      newJob as MeetingPreviewData
    );

    dispatch(updateArchivedMeeting(mappedJob));

    const formatMeetings = await getElasticFormatJobsTable([mappedJob]);

    if (formatMeetings && updatedMeetings) {
      const meetingIndex = updatedMeetings.findIndex((m) => {
        return m.id === formatMeetings[0].id;
      });
      const meetingsTableDataClone = [...updatedMeetings];

      meetingsTableDataClone[meetingIndex] = formatMeetings[0];

      setMeetingsTableData(meetingsTableDataClone);
    }
  };

  const handleJobSelection = (jobIds: string[]) => {
    setSelectedJobs(jobIds);
  };

  const exportV3 = async () => {
    if (_.isEmpty(selectedJobs.current)) return;

    dispatch(
      popIndicator({
        type: "info",
        txt: t("export_downloading_jobs"),
        sticky: true,
      })
    );

    const clientId = selectedJobs.current[0].clientid;
    const newExportConfigPresets = await FirebaseService.getClientExportConfigPresets(
      clientId
    );
    let newClientExportConfig = await FirebaseService.getClientExportConfigData(
      clientId,
      "default"
    );
    if (!newClientExportConfig) {
      newClientExportConfig = await FirebaseService.getExportConfigData();
    }
    const jobIds = selectedJobs.current.map((j) => j.id);
    const jobsData = await EditorService.getJobs(jobIds);
    setExportConfig(newClientExportConfig);
    setExportConfigPresets(newExportConfigPresets || []);
    setExportV3JobsData(jobsData);
    setOpenExportV3Modal(true);
    dispatch(popIndicator(null));
  };

  const loadArchiveData = async (
    setIsloading?: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      dispatch(setArchivedMeetings([]));
      setMeetingsTableData([]);
      if (loggedInUser) {
        const formatedFilters = {
          filters: ElasticSearchService.filterFormater(
            defaultFilters as {
              [key: string]: {
                value: string | number | Date | string[] | number[];
                type: "normal" | "range";
              };
            }
          ),
        };
        const {
          results: jobs,
          resTotalPages,
          resTotalResults,
        } = await ElasticSearchService.getJobs(formatedFilters);
        setHasMore(resTotalPages > 1);
        dispatch(setArchivedMeetings(jobs));
        const formatJobs = await getElasticFormatJobsTable(jobs);

        if (formatJobs) {
          setMeetingsTableData(formatJobs);
        }
        setIsloading && setIsloading(false);
        dispatch(clearLoadingReason());
        setDataCount(resTotalResults);
        return;
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const loadMore = async (
    searchValue = "",
    newSearch?: boolean,
    addedFilters?: {
      [key: string]: {
        value: string | number | string[] | Date | number[];
        type: "normal" | "range";
      };
    }
  ): Promise<boolean | undefined> => {
    try {
      if (loggedInUser) {
        const mergedFilters = _.merge(defaultFilters, addedFilters);
        const formatedFilters = ElasticSearchService.filterFormater(
          mergedFilters
        );
        const currentPage = newSearch
          ? 1
          : Math.ceil(
              archivedMeetings.length / ElasticSearchService.defaultChunkSize +
                1
            );
        const filters = {
          searchValue,
          page: currentPage,
          filters: formatedFilters,
        };
        const {
          results: meetings,
          resTotalPages,
          resTotalResults,
        } = await ElasticSearchService.getJobs(filters);
        const formatJobs = await getElasticFormatJobsTable(meetings);

        if (formatJobs) {
          if (newSearch) {
            setMeetingsTableData(formatJobs);
            dispatch(setArchivedMeetings(meetings));
          } else {
            setMeetingsTableData([...meetingsTableData, ...formatJobs]);
            dispatch(setArchivedMeetings([...archivedMeetings, ...meetings]));
          }
        }
        if (currentPage >= resTotalPages) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setDataCount(resTotalResults);
        return;
      }
    } catch (err) {
      logger.log(err);
      throw "fail to load more data at infiniteArchivePage";
    }
  };

  const getElasticFormatJobsTable = async (
    meetings: ElasticJob[]
  ): Promise<ArchiveFormatedJobs[] | undefined> => {
    try {
      const formatJobs = await Promise.all(
        meetings.map(async (meeting) => {
          const {
            id,
            name,
            status,
            deliveredtoclient: deliveredToClient,
            representative,
            representativeprice: representativePrice,
            invoicesent: invoiceSent,
            revenue,
            processprogresslastupdate: processProgressLastUpdate,
            revenuecurrency: revenueCurrency,
            archivedat: archivedAt,
            meetinglength: meetingLength,
            clientid: clientId,
            transcriberid: transcriberId,
            reviewerid: reviewerId,
            lang,
            notes,
            translation,
            creationtime: creationTime,
            ownername: ownerName,
            jobtype: previewFormat,
            processprogress: processProgress,
            price,
            prooferprice: prooferPrice,
          } = meeting;

          const clientName =
            clientId && clientState
              ? _.find(clientState, (client: any) => client.id === clientId)
                  ?.name
              : t("no_owner");

          const prooferName = reviewerId
            ? MeetingService.getProoferFromProofers(
                reviewerId,
                proofers,
                "username"
              )
            : t("no_proofer");
          const parsedLang = lang ? JSON.parse(lang) : "";
          const parsedTranslation = translation ? JSON.parse(translation) : "";

          const input = t(
            _.isArray(parsedLang.input) ? parsedLang.input[0] : parsedLang.input
          );
          const output = t(
            _.isArray(parsedLang.output)
              ? parsedLang.output[0]
              : parsedLang.output
          );

          let langText = "-";
          if (parsedLang) {
            langText =
              previewFormat === "subtitles" ? input : `${input} - ${output}`;
          }

          const transName = transcriberId
            ? MeetingService.getTranscriberFromTranscribers(
                transcriberId,
                transcribers,
                "username"
              )
            : t("no_transcriber");

          const processProgressObj = processProgress
            ? JSON.parse(processProgress)
            : "";
          const lastUpdate = processProgressObj
            ? _.get(processProgressObj, "lastUpdate")
            : "";
          const meetingObj = {
            invoiceSent: invoiceSent,
            invoice: t(invoiceSent),
            representativePrice: representativePrice ? representativePrice : 0,
            representative: representative
              ? representative
              : t("no_representative"),
            archivedAt: archivedAt
              ? format(new Date(archivedAt), "dd/MM/yyyy")
              : " - ",
            creationTime: creationTime
              ? format(new Date(creationTime), "dd/MM/yyyy")
              : " - ",
            meetingLength: meetingLength
              ? TimeService.getTimeStringFromSecs(meetingLength)
              : "-",
            prooferPrice: prooferPrice ? prooferPrice : 0,
            assignedProofer: prooferName ? prooferName : t("no_proofer"),
            price: price ? price : 0,
            assignedTranscriber: transName ? transName : t("no_transcriber"),
            revenueCurrency: revenueCurrency
              ? t(revenueCurrency)
              : t("currency"),
            lang: langText,
            revenue: revenue ? revenue : "-",
            status: status,
            output: previewFormat === "subtitles" ? input : output,
            input: input,
            previewFormat: t(previewFormat),
            clientName: clientName ? clientName : t("no_owner"),
            ownerId: clientId,
            translation: parsedTranslation,
            processProgressLastUpdate: lastUpdate
              ? new Date(lastUpdate._seconds * 1000)
              : false,
            deliveredToClient: deliveredToClient,
            name: name,
            id: id,
            contextMenu: (
              <ContextMenu
                menuItems={[
                  {
                    onClick: async () => {
                      if (clientState) {
                        const fireStoreMeetingData = await FirebaseService.getJob(
                          meeting.id,
                          clientState
                        );
                        if (fireStoreMeetingData) {
                          setContextMenuJob(fireStoreMeetingData);
                        }

                        setIsEditModalOpen(true);
                      }
                    },
                    text: t("edit"),
                  },
                  {
                    onClick: async () => {
                      if (clientState) {
                        const fireStoreMeetingData = await FirebaseService.getJob(
                          meeting.id,
                          clientState
                        );
                        if (fireStoreMeetingData) {
                          setContextMenuJob(fireStoreMeetingData);
                        }
                        setIsNoteModalOpen(true);
                      }
                    },
                    text: t("notes"),
                  },
                  {
                    onClick: () => {
                      setSelectedMeeting([meeting]);
                      setOpenExportModal(true);
                    },
                    text: t("export"),
                  },
                  {
                    onClick: () => {
                      setSelectedJobs([meeting]);
                      exportV3();
                    },
                    text: t("export_v3"),
                  },
                  {
                    onClick: () => {
                      invoiceSentUpdate && invoiceSentUpdate("open", meeting);
                    },
                    text: t("open"),
                  },
                  {
                    onClick: () => {
                      invoiceSentUpdate && invoiceSentUpdate("check", meeting);
                    },
                    text: t("check"),
                  },
                  {
                    onClick: () => {
                      invoiceSentUpdate && invoiceSentUpdate("sent", meeting);
                    },
                    text: t("sent"),
                  },
                  {
                    onClick: () => {
                      setMeetingId(meeting.id);
                      setJobModalOpen(true);
                    },
                    text: t("audit"),
                  },
                  {
                    onClick: () => {
                      setMeetingId(meeting.id);
                      setTranslationJobModal(true);
                    },
                    text: t("createTranslation"),
                  },
                ]}
              ></ContextMenu>
            ),
          };

          return meetingObj;
        })
      );
      return formatJobs;
    } catch (err) {
      console.log("getElasticFormatJobsTable", err);
    }
  };

  const goToMeeting = (
    meeting: { [key: string]: any },
    event: React.MouseEvent
  ) => {
    const jobUrl = getJobsUrl(meeting, loggedInUser);
    if (event.ctrlKey || event.metaKey) {
      window.open(jobUrl, "_blank");
      return;
    }

    history.push(jobUrl);
    dispatch(clearMeetings());
  };

  const revenueUpdate = async (
    newValue: number | string,
    meeting: MeetingPreviewData
  ) => {
    try {
      const newRevenue = Number(newValue);
      if (_.isNaN(newRevenue)) return false;
      await FirebaseService.changeRevenue(meeting.id, newRevenue);
      dispatch(updateMeetingRevenue(meeting.id, newRevenue));
    } catch (error) {
      dispatch(popIndicator({ type: "failure", txt: t("updating_fail") }));
      logger.error(error, "revenueUpdate");
    }
    return true;
  };

  const invoiceSentUpdate = async (value: string, job: ElasticJob) => {
    try {
      dispatch(popIndicator({ type: "info", txt: t("update_invoice") }));
      await FirebaseService.changeInvoiceSent(job.id, value);
      dispatch(popIndicator({ type: "success", txt: t("invoice_updated") }));
      const updatedJob = _.update(job, "invoicesent", (preValue) =>
        value ? value : preValue
      );
      await editJob(updatedJob);
    } catch (error) {
      dispatch(
        popIndicator({ type: "failure", txt: t("update_invoice_failed") })
      );
      console.log(error);
    }
    return true;
  };

  const getJobsForExcel = async (options: any) => {
    const { filter, searchValue } = options;
    const mergedFilters = _.merge(defaultFilters, filter);

    const formatedFilter = {
      filters: ElasticSearchService.filterFormater(mergedFilters),
      page: 1,
      searchValue,
    };

    const results = [];
    const { results: jobs, resTotalPages } = await ElasticSearchService.getJobs(
      { ...formatedFilter, page: 1 },
      1000
    );
    results.push(jobs);
    if (resTotalPages > 1) {
      for (let x = 1; x < resTotalPages; x++) {
        const { results: jobs } = await ElasticSearchService.getJobs(
          { ...formatedFilter, page: x + 1 },
          1000
        );
        results.push(jobs);
      }
    }
    return results;
  };

  const exportTable = async (options: any) => {
    const rawJobs = await getJobsForExcel(options);
    const jobsArr = _.flatten(rawJobs);

    const formatedJobs = await getElasticFormatJobsTable(
      jobsArr as ElasticJob[]
    );

    if (formatedJobs) {
      const jobsIds = formatedJobs.map((job) => job.id);
      const jobsAuditEvents = await FirebaseService.getAuditEvents(jobsIds);
      const jobsWithAudit = await ExportService.getAuditEventsForExcel(
        formatedJobs,
        jobsAuditEvents,
        t
      );
      ExportService.exportDataToExcel(
        jobsWithAudit,
        "archive-jobs",
        archiveExcelFields
      );
    }
  };

  return (
    <>
      {loggedInUser && (
        <main ref={archivePageRef} className="main-container">
          <PageHeader title={t("archive")} />
          {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
          {!!errorReason && <ErrorModal errorReason={errorReason} />}
          {!loadingReason && !errorReason && (
            <div className="meetings-page flex column align-center">
              <div className="meetingsTable">
                <InfinityMeetingsTable
                  hasMore={hasMore}
                  data={meetingsTableData}
                  additionalSearchFields={["archivedAt", "clientName"]}
                  onRowClick={goToMeeting}
                  onSelection={handleJobSelection}
                  loadMore={loadMore}
                  dataCount={dataCount}
                  config={{
                    multiSelect: true,
                    actions: {
                      exportSubtitles: {
                        label: "export-subtitles",
                        icon: (
                          <FontAwesomeIcon icon={["far", "file-download"]} />
                        ),
                        action: (option: any) => {
                          const { jobSelected } = option;
                          setOpenExportModal(true);
                          setSelectedMeeting(
                            jobSelected.map((job: any) => {
                              return job;
                            })
                          );
                        },
                        bulk: false,
                        hidden: false,
                      },
                      export: {
                        label: "export",
                        icon: <FontAwesomeIcon icon={["far", "file-excel"]} />,
                        action: exportTable,
                        bulk: false,
                        hidden: false,
                      },
                      exportV3: {
                        label: "export_v3",
                        icon: <FontAwesomeIcon icon={["far", "file-export"]} />,
                        action: exportV3,
                        bulk: false,
                        hidden: false,
                      },
                    },
                    fieldsToFilter: [
                      "clientid",
                      "transcriber",
                      "proofer",
                      "archivedat",
                      "jobtype",
                      "meetinglength",
                      "transcriberid",
                      "reviewerid",
                      "price",
                    ],
                    tableColumns: [
                      "id",
                      "name",
                      "translation",
                      "status",
                      "member",
                      "deliveredToClient",
                      "representative",
                      "invoiceSent",
                      "revenue",
                      "archivedAt",
                      "meetingLength",
                      "ownerId",
                      "ownerName",
                      "previewFormat",
                      "processProgressLastUpdate",
                      "contextMenu",
                      "representativePrice",
                    ],
                    tableName: t("archive"),
                    columns: {
                      status: { hidden: true },
                      price: { hidden: true },
                      prooferPrice: { hidden: true },
                    },
                    formatters: {
                      representative: (representative, meeting) => (
                        <div className="representativeContainer">
                          <span className="representativeName">
                            {representative}
                          </span>
                          <div className="representativePrice">
                            (
                            <span className="price">
                              {meeting.representativePrice}
                            </span>
                            <span className="currency">{t("currency")}</span>)
                          </div>
                        </div>
                      ),
                      revenue: (revenue: number, meeting) => (
                        <div className="revenue">{`${revenue}${t(
                          meeting.revenueCurrency
                        )}`}</div>
                      ),
                      invoiceSent: (invoiceSent: string, meeting) => (
                        <div className={classNames("invoiceSent", invoiceSent)}>
                          {t(invoiceSent)}
                        </div>
                      ),
                    },
                  }}
                />
              </div>
            </div>
          )}
          {isEditModalOpen && contextMenuJob && (
            <EditModal
              dataSource="elastic"
              fieldsToEdit={[
                "name",
                "jobType",
                "representative",
                "representativePrice",
                "price",
                "prooferPrice",
                "revenue",
                "revenueCurrency",
                "pageCount",
                "invoiceSent",
              ]}
              job={contextMenuJob}
              handleSubmit={(newJob: MeetingPreviewData) => editJob(newJob)}
              showModal={isEditModalOpen}
              closeModal={() => setIsEditModalOpen(false)}
            ></EditModal>
          )}
          <ExportModal
            isOpen={openExportModal}
            setOpen={setOpenExportModal}
            meetings={selectedMeeting}
            saveMeeting={false}
          ></ExportModal>
          {selectedJobs.current.length > 0 &&
            openExportV3Modal &&
            exportConfig && (
              <ExportModalV3
                isOpen={openExportV3Modal}
                setOpen={setOpenExportV3Modal}
                jobsData={exportV3JobsData}
                //Currently Needs To Handle Both in Case Client Does Not Have Any Presets
                exportConfig={exportConfig}
                presets={exportConfigPresets}
              />
            )}
          {contextMenuJob && isNoteModalOpen && (
            <NoteModal
              jobId={contextMenuJob.id}
              notes={contextMenuJob.notes || ""}
              showModal={isNoteModalOpen}
              closeModal={() => setIsNoteModalOpen(false)}
              onSubmit={async (note: string) => {
                await FirebaseService.updateNotes(note, contextMenuJob.id);
                editJob({ ...contextMenuJob, notes: note });
                setIsNoteModalOpen(false);
              }}
            ></NoteModal>
          )}
          {meetingId && jobModalOpen && (
            <JobAuditModal
              id={meetingId}
              setShowModal={setJobModalOpen}
              showModal={jobModalOpen}
            ></JobAuditModal>
          )}
          {meetingId && translationJobModal && (
            <TranslationJobModal
              jobId={meetingId}
              showModal={translationJobModal}
              closeModal={() => setTranslationJobModal(false)}
            ></TranslationJobModal>
          )}
        </main>
      )}
    </>
  );
};

export default InfiniteArchivePage;
