/* eslint @typescript-eslint/no-var-requires: "off" */
import _ from "lodash";
import { Word } from "../models";
import { SubtitlesTranslationRange } from "../models/range";
import { generateId } from "../utils/generators";
import FirebaseService from "./FirebaseService";

const Diff = require("diff");

const generateSubtitlesTranslationRanges = (
  sourceWords: Word[],
  autoTranslationWords: Word[],
  readyWords: Word[]
) => {
  const ranges: SubtitlesTranslationRange[] = [];

  const transWordsByRangesIndex = _.groupBy(autoTranslationWords, "range_ix");
  const sourceWordsByRangesIndex = _.groupBy(sourceWords, "range_ix");
  const readyWordsWordsByRangesIndex = _.groupBy(readyWords, "range_ix");
  const rangesNumber = Object.keys(transWordsByRangesIndex).length;
  let startIndex = 0;
  let endIndex = 0;

  for (let i = 0; i < rangesNumber; i++) {
    const lastWordAtRange = sourceWordsByRangesIndex[i].length - 1;
    const st = sourceWordsByRangesIndex[i][0].start;
    const et = sourceWordsByRangesIndex[i][lastWordAtRange].end;

    endIndex = endIndex === 0 ? sourceWordsByRangesIndex[i].length : endIndex;

    const range = {
      id: generateId("r_"),
      six: startIndex,
      eix: endIndex,
      st,
      et,
      sourceWords: sourceWordsByRangesIndex[i],
      words:
        readyWordsWordsByRangesIndex && readyWordsWordsByRangesIndex[i]
          ? readyWordsWordsByRangesIndex[i]
          : [],
      autoTranslationWords: transWordsByRangesIndex[i],
      type: "subtitles-translation",
    } as SubtitlesTranslationRange;

    const rangeLength =
      i < rangesNumber - 1 ? sourceWordsByRangesIndex[i + 1].length : 0;
    startIndex = endIndex + 1;

    endIndex = rangeLength + endIndex;

    ranges.push(range);
  }
  return ranges;
};

const getRangeWordsFromStringForSubTrans = (object: {
  rangeWords: Word[];
  newInputString: string;
  oldInputString: string;
  rangesCount: number;
  range: SubtitlesTranslationRange;
  rangeIndex?: number;
}): Word[] => {
  const {
    rangeWords,
    newInputString,
    oldInputString,
    rangesCount,
    range,
    rangeIndex,
  } = object;
  const fullWordsArray = rangeWords.filter((i) => i.text !== "\n"); // Removing linebreaks
  const rangeNewWords = newInputString
    .trim()
    .split(" ")
    .filter((words) => words); // Removing whitespace
  const rangeOldWords = oldInputString
    .trim()
    .split(" ")
    .filter((word) => word); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;
  const newChangedWords = getCalculatedNewWordsTimesForSubtitleTranslation(
    rangeNewWords,
    rangeIndex,
    range
  ).slice(startI, rangeNewWords.length - endI);
  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  return updatedRangeWords;
};

const getCalculatedNewWordsTimesForSubtitleTranslation = (
  newWords: string[],
  rangeIndex = 0,
  range: SubtitlesTranslationRange
) => {
  const newWordsLength = newWords.length;
  const wordTotalTime = (range.et - range.st) / newWordsLength;

  const words = [];

  let currWordIndex = 0;
  let prevWordEndTime = 0;

  for (const word of newWords) {
    const wordStartTime = currWordIndex === 0 ? range.st : prevWordEndTime;
    const wordEndTime =
      currWordIndex === newWords.length - 1
        ? range.et
        : wordTotalTime + wordStartTime;

    const wordObj = {
      id: generateId("w_"),
      speaker: "speaker",
      word: word,
      text: word,

      start: wordStartTime,
      end: wordEndTime,
      range_ix: rangeIndex,
    };
    words.push(wordObj);

    prevWordEndTime = wordEndTime;
    currWordIndex++;
  }
  return words;
};

const getRangeWordsFromMultilineStringForSubTrans = ({
  rangeWords,
  newInputString,
  oldInputString,
  rangesCount,
  range,
  rangeIndex,
}: {
  rangeWords: Word[];
  newInputString: string;
  oldInputString: string;
  rangesCount: number;
  range: SubtitlesTranslationRange;
  rangeIndex?: number;
}): Word[] => {
  const fullWordsArray = rangeWords.filter((i) => i.text !== "\n"); // Removing linebreaks

  let wordCount = 0;
  const lineBreaks = newInputString
    .trim()
    .split("\n")
    .slice(0, -1)
    .map((line) => {
      const lineLength = line.trim().split(" ").length;
      wordCount = wordCount + lineLength;
      return wordCount;
    });
  lineBreaks.unshift(0);

  const rangeNewWords = newInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace
  const rangeOldWords = oldInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = getCalculatedNewWordsTimesForSubtitleTranslation(
    rangeNewWords,
    rangeIndex,
    range
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);

  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  // Add line index to words
  for (const [lineIndex, lineBreak] of lineBreaks.entries()) {
    const nextLineBreak = lineBreaks[lineIndex + 1]
      ? lineBreaks[lineIndex + 1]
      : updatedRangeWords.length;
    for (let i = lineBreak; i < nextLineBreak; i++) {
      updatedRangeWords[i].line_ix = lineIndex;
    }
  }

  return updatedRangeWords;
};

const getRangesForSubTrans = async (id: string) => {
  const sourceWords = (await FirebaseService.getRoomWords(id, {
    filename: "source.json",
  })) as Word[];
  const readyWords = (await FirebaseService.getRoomWords(id, {
    filename: "ready.json",
  })) as Word[];
  const autoTranslationWords = (await FirebaseService.getRoomWords(id, {
    filename: "translation.json",
  })) as Word[];
  const ranges = generateSubtitlesTranslationRanges(
    sourceWords,
    autoTranslationWords,
    readyWords
  );
  return ranges;
};

export default {
  getRangesForSubTrans,
  getCalculatedNewWordsTimesForSubtitleTranslation,
  generateSubtitlesTranslationRanges,
  getRangeWordsFromStringForSubTrans,
  getRangeWordsFromMultilineStringForSubTrans,
};
