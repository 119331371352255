import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./IndicatorPopup.scss";

const IndicatorPopup: FC = (): JSX.Element => {
  const indicatorPopupState = useSelector(
    (state: AppState) => state.systemStore.indicatorPopupState
  );

  const [popupInfo, setPopupInfo] = useState<{
    type: string;
    txt: string;
  } | null>(null);
  const [transition, setTransition] = useState<number>(0);

  useEffect(() => {
    if (indicatorPopupState) {
      setPopupInfo(indicatorPopupState);
      setTimeout(() => {
        setTransition(100);
      }, 0);
    } else {
      setTransition(0);
      setTimeout(() => setPopupInfo(null), 300);
    }
  }, [indicatorPopupState]);

  return (
    <>
      {popupInfo && (
        <div style={{ opacity: `${transition}%` }} className="indicator-popup">
          <div className="icn-wrapper">
            {popupInfo.type === "success" && (
              <FontAwesomeIcon
                className="icn success"
                icon={["fal", "shield-check"]}
              />
            )}
            {popupInfo.type.startsWith("fail") ||
              (popupInfo.type === "error" && (
                <FontAwesomeIcon
                  className="icn failure"
                  icon={["fal", "exclamation-triangle"]}
                />
              ))}
            {popupInfo.type === "info" && (
              <FontAwesomeIcon
                className="icn info"
                icon={["fal", "info-circle"]}
              />
            )}
            {popupInfo.type === "warning" && (
              <FontAwesomeIcon
                className="icn warning"
                icon={["fal", "exclamation-triangle"]}
              />
            )}
          </div>
          <p>{popupInfo.txt}</p>
        </div>
      )}
    </>
  );
};

export default IndicatorPopup;
