import React, { FC, useState, ChangeEvent, useRef, useEffect } from "react";
import _ from "lodash";
import i18n from "../../i18n";

import { useTranslation } from "react-i18next";
import classNames from "classnames";

import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";
import { getLanguageDirection } from "../../utils/locales";

import "./PopupDropdown.scss";

interface Option {
  label: string;
  value: any;
  icon?: any;
}

interface Props {
  options: Option[];
  onChange: (value: string[] | string, labels: string | string[]) => void;
  value?: string | string[];
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  multiSelect?: boolean;
  searchOption?: boolean;
}

const PopupDropdown: FC<Props> = ({
  options,
  onChange,
  value,
  className,
  isLoading,
  isDisabled,
  multiSelect,
  searchOption = true,
}) => {
  const { t } = useTranslation();
  const mainElement = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [orderedOptions, setOrderedOptions] = useState(options);
  const [multiSelected, setMultiSelected] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setOrderedOptions(_.sortBy(options, "label"));
  }, []);
  useEffect(() => {
    if (!value || _.isEmpty(value) || !orderedOptions) return;
    // Single Selected
    if (_.isString(value)) {
      return;
    }

    // Multiselected
    setMultiSelected(_.filter(orderedOptions, (o) => value.includes(o.value)));
  }, [value]);

  useOutsideClick(mainElement, () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  });

  const handleSelect = (option: Option) => {
    onChange(option.value, option.label);
    setIsOpen(false);
  };

  const handleMultiSelect = (option: Option) => {
    let newSelected = [];
    if (multiSelected.includes(option)) {
      newSelected = multiSelected.filter((o) => o.value !== option.value);
    } else {
      newSelected = [...multiSelected, option];
    }

    setMultiSelected(newSelected);
    const values: string[] = [];
    const labels: string[] = [];
    newSelected.map((s) => {
      values.push(s.value);
      labels.push(s.label);
    });
    onChange(values, labels);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return (
    <div className={classNames("PopupDropdown")} ref={mainElement}>
      {searchOption && (
        <div className="search">
          <input
            type="text"
            placeholder={t("search")}
            onChange={handleSearch}
          />
        </div>
      )}
      <div className={classNames("optionsContainer", { isOpen })}>
        {_.map(
          orderedOptions.filter((o) =>
            t(o.label).toLowerCase().includes(searchValue)
          ),
          (o) =>
            multiSelect ? (
              <div
                title={t(o.label)}
                className={classNames(
                  "option checkbox",
                  getLanguageDirection(i18n.language)
                )}
                key={o.value}
              >
                <label htmlFor={o.value}>{t(o.label)}</label>
                <input
                  type="checkbox"
                  id={o.value}
                  name={o.value}
                  value={o.value}
                  checked={
                    multiSelected.find((option) => option.value === o.value)
                      ? true
                      : false
                  }
                  onChange={() => handleMultiSelect(o)}
                />
              </div>
            ) : (
              <div
                className="option"
                onClick={() => handleSelect(o)}
                key={o.value}
              >
                {t(o.label)}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default PopupDropdown;
