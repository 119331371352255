import {
  ClientOption,
  ClientPreviewData,
  CurrClientInfo,
  MinimalClient,
} from "../../models/client";

import {
  SET_CLIENTS,
  CLEAR_CLIENTS,
  SET_CURR_CLIENT,
  SET_MINIMAL_CLIENTS,
  CLEAR_CURR_CLIENT,
  SET_CLIENTS_OPTIONS,
  ClientActionTypes,
} from "./types";

export function setClients(clients: ClientPreviewData[]): ClientActionTypes {
  return {
    type: SET_CLIENTS,
    clients,
  };
}

export function setClientsOptions(clients: ClientOption[]): ClientActionTypes {
  return {
    type: SET_CLIENTS_OPTIONS,
    clients,
  };
}

export function setMinimalClients(
  minimalClients: MinimalClient[]
): ClientActionTypes {
  return {
    type: SET_MINIMAL_CLIENTS,
    minimalClients,
  };
}

export function clearClients(): ClientActionTypes {
  return {
    type: CLEAR_CLIENTS,
  };
}

export function setCurrClient(client: CurrClientInfo): ClientActionTypes {
  return {
    type: SET_CURR_CLIENT,
    client,
  };
}

export function clearCurrClient(): ClientActionTypes {
  return {
    type: CLEAR_CURR_CLIENT,
  };
}
