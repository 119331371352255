import { RoomData } from "../../models/room";

export interface RoomState {
  roomData: RoomData;
}

export const SET_ROOM_DATA = "SET_ROOM_DATA";
export const CLEAR_ROOM_DATA = "CLEAR_ROOM_DATA";

interface SetRoomData {
  type: typeof SET_ROOM_DATA;
  roomData: RoomData;
}

interface ClearRoomData {
  type: typeof CLEAR_ROOM_DATA;
}

export type RoomActionTypes = SetRoomData | ClearRoomData;
