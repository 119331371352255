import React, { FC, useRef, useEffect } from "react";
import "./Textarea.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  onInput: (input: string) => void;
  value: string;
  focus?: boolean;
  disabled: boolean;
  width: number;
  height: number;
  placeholder: string;
  Icn?: IconProp;
}

const Textarea: FC<Props> = ({
  onInput,
  value,
  focus,
  disabled,
  width,
  height,
  placeholder,
  Icn,
}): JSX.Element => {
  const textareaInput = useRef(document.createElement("textarea"));

  useEffect(() => {
    if (focus) textareaInput.current.focus();
  }, [focus]);

  return (
    <>
      <div className="textarea-wrapper">
        {!!Icn && (
          <div className="icn-wrapper">
            <FontAwesomeIcon icon={Icn} />
          </div>
        )}
        <textarea
          ref={textareaInput}
          onChange={(e) => onInput(e.target.value)}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          style={{ width: `${width}px`, height: `${height}px`, resize: "none" }}
          cols={30}
          rows={10}
        ></textarea>
      </div>
    </>
  );
};

export default Textarea;
