import { JobData } from "../models/job";

const jobMock: JobData = {
  roomId: "jPYYVy9VPWTUmLiiBtHx",
  name: "ישיבת מליאה שלא מן המניין 15.6.2022",
  jobType: "protocol",
  clientId: "_f6bi2nt68x",
  creationTime: new Date("2022-06-22T08:18:58.000Z"),
  deadline: new Date("2022-06-25T08:18:58.000Z"),
  lang: {
    input: ["he-IL"],
    output: ["he-IL"],
  },
  price: 46,
  prooferPrice: 18.4,
  meetingLength: 1103.01,
  proofer: "dJH5pddu9GbrUclZFZ0eT2rd5m82",
  prooferName: "Adi Folk",
  transcriber: "dJH5pddu9GbrUclZFZ0eT2rd5m82",
  transcriberName: "Adi Folk",
  status: 5,
  process: {
    status: "ready",
    progress: 100,
  },
  workTime: {
    review: {
      dJH5pddu9GbrUclZFZ0eT2rd5m82: {
        textEdit: 2450.566,
        timeSync: 0,
        userRole: "super_user",
      },
    },
    transcribe: {
      vJaJ1enuZANJR06mIr9jv4FgF6r2: {
        textEdit: 15.349,
        timeSync: 0,
        userRole: "transcriber",
      },
    },
  },
  editProgress: 100,
  assets: [],
  words: [],
  notes: "רמת תמלול B, צריך ממש הכל",
  speakers: [
    "ד״ר חפצי זוהר",
    "רוביק דנילוביץ׳",
    "משה ינאי",
    "ליאור דרעי",
    "חבר מועצה",
    "מוטי בן יהודה",
    "עו״ד חיים ארקל",
    "יחיאל חמו ",
    "עו״ד מינה קלמן",
    "הערה",
  ],
  markers: {
    shots: [{ start: 1.2 }, { start: 8.8 }],
  },
  ranges: [
    {
      id: "r_tz3qwqxtnm",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מתכבדת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לפתוח",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ישיבת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המועצה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המניין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מס׳",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "74,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יום",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רביעי,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ט״ז",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בסיוון",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תשפ״ב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ה-15",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ביוני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "2022.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סדר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היום",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "2023",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לרבות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פרוטוקול",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ועדת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כספים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מס׳",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "50/1022",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מיום",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ה-23",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "במאי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "2022",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שדנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בנושא,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ראש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בבקשה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ד״ר חפצי זוהר",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_z5zpnksc6ek",
      six: 0,
      eix: 0,
      words: [
        {
          text: "שלום",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "וברכה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "חברות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "וחברי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מועצת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיר.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [
        {
          id: "eexvfxjpzo",
          range_ix: 1,
          text: "ראש העיר סיפר בדיחה",
          type: "note",
          annotation_index: 2,
        },
        {
          id: "eexvfxjpzo",
          range_ix: 1,
          text: "נלך על פיצה. פלאפל שבוע הבא.",
          type: "subject",
          annotation_index: 2,
        },
      ],
    },
    {
      id: "r_8vd74sd5wmu",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מבקשת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גברת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לחזור,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שמעתי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כלום.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_3r56a5ezbf8",
      six: 0,
      eix: 0,
      words: [
        {
          text: "היא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רק",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אמרה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שעל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סדר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היום,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רק",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בקשה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [
        {
          id: "eexvfxjpzo46",
          range_ix: 3,
          text: "החלטה חשובה מאוד",
          type: "decision",
          annotation_index: 1,
        },
      ],
    },
    {
      id: "r_3krlga2cynq",
      six: 0,
      eix: 0,
      words: [
        {
          text: "פשוט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רציתי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשמוע",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הקול",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קצת.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_j3f0zjo7btp",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "איזה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יופי.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "האמת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שקיימנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דיון",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בועדת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכספים,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בסך",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעיקר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מביאים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשורות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בצו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שקורה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מעלים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שאנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מעלים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המקדם,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המקדם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כפי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אתם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יודעים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הסמכי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שכר,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "התייקרויות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "וכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שחי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "במדינה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזאת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ובכלל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעולם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מבין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "התייקרויות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היום,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דבר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "התייקרויות,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אבל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דרמטיות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הענפים.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רואים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המדדים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאחרונה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אז",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "החלטנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בניגוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לערים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מסוימות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לישובים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מסוימים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהחליטו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המקדם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להעלות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ארנונה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "החלטנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שאנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מעלים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למעט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המקדם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שזה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ההחלטה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המדינה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ההתייקרויות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "במשק,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ההתייקרויות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "האלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מזון,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בניה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תוספות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שכר,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שקורה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היום",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "במשק.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למעט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מעלים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כלום.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שכן,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מביאים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשורות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "חשובות,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העתיקה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ניתן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לליאור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לסקור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בקצרה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "חושב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהבשורות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשורות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "חשובות,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "והן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מצטרפות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למדיניות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העירונית",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לעודד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יזמות,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לעשות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שיכנסו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יזמים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למקומות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שחשוב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להחיות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אותם,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שכונות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ותיקות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עתיקה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "וכדומה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ליאור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רק",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תסקור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בקצרה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בבקשה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_yc8sganv62",
      six: 0,
      eix: 0,
      words: [
        {
          text: "ערב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "טוב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לכולם,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בכדי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשמור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הצביון",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המיוחד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העתיקה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ועל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לעודד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הגעתם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "האוכלוסיות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הרלוונטיות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למגורים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ועסקים,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מביאים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כרגע",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ההצעה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הבאה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאישורכם.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דברים,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קודם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העובדה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שבשנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הקודמת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הייתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפחתת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שומה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאולמות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שמחה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נכלל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ברשימת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העסקים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להפחתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ועל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ליישר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יתר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העסקים,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הבקשה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כוללת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפחתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאולמות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שמחה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העתיקה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כאמור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מוגשת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זו.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דבר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שני,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אזור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מס",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "חדש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לעיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העתיקה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ברחובות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הנכללים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "באזור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מס",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ד׳,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפירוט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נמצא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ברשימה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלפניכם,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נקרא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אותה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עכשיו.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מדברים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפחתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נוספת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמעט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "35%",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מעבר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להפחתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שניתנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הקודמת.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המשמעות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכספית",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמעט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמליון",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שקלים.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הסעיף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_oxm4ip3akys",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אתם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רוצים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סעיף,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סעיף?",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_yk2c36nc68",
      six: 0,
      eix: 0,
      words: [
        {
          text: "איך",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מקובל,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פעם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ראשונה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מקובל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ביחד?",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_dp92txpjcyc",
      six: 0,
      eix: 0,
      words: [
        {
          text: "לא,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מצביעים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ביחד.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_b3vzmmk26sh",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אוקי,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בסדר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גמור.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ושני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דברים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נוספים,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נושא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המחסנים.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נקדים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ונאמר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "2016",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פעלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיריה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להפחתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תעריפי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אולמות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "השמחה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעיר,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "וקיבלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כך",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שרי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפנים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "והאוצר.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעצם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נכון",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להיום,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מבקשים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בסעיף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "24א׳",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להתייחס",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למספר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סיווגים.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_ijyya3utfq",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קורא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפרוטוקול?",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קראנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הכל,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשביל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צריך",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לקרוא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מחדש?",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "חבר מועצה",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_f8y186f7klh",
      six: 0,
      eix: 0,
      words: [
        {
          text: "לא,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קורא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אותו,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נכתב-",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_p2v4uzd8x2e",
      six: 0,
      eix: 0,
      words: [
        {
          text: "כולם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הדבר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קראנו,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ראינו.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "חבר מועצה",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_1w0x9soxas",
      six: 0,
      eix: 0,
      words: [
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "משפט",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אחד.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
      speakerNameEdit: false,
    },
    {
      id: "r_ul9nyehqcun",
      six: 0,
      eix: 0,
      words: [
        {
          text: "תקן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אותו,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעיר,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העתיקה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "מוטי בן יהודה",
      speakerId: null,
      type: "speaker",
      annotations: [],
      speakerNameEdit: false,
    },
    {
      id: "r_5byp053pyf2",
      six: 0,
      eix: 0,
      words: [
        {
          text: "כן,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שאמרתי,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העתיקה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_r0phij5okh",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אתה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יודע",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שבהגה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הבאר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שבעית,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "״בעיר״",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העיר.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_meoc7qg6c7",
      six: 0,
      eix: 0,
      words: [
        {
          text: "ואני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מקריא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בכוונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מתומלל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ואני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רוצה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שדברים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יהיו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מדויקים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לצורך",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפרוטוקול.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למספר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סיווגים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שאמרנו,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אולמות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שמחה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מבנים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "וסככות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תחנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דלק,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "משרדים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ושירותים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ומסחר.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מדברים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תעריף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למחסנים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהוא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשיעור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "75%",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מהתעריף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אותם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סיווגים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המצוינים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לעיל.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מבקשים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ממכם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאשר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשנת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "2023",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ואם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ברגע",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהאישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלכם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ינתן,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נעביר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפניה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפנים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ושר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "האוצר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לאישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סופי.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ליאור דרעי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_5swu3s3g82d",
      six: 0,
      eix: 0,
      words: [
        {
          text: "טוב,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ערב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "טוב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לכולם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רבותי.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_0433vcwd6xtj",
      six: 0,
      eix: 0,
      words: [
        {
          text: "בבקשה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ערב",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "טוב.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "ד״ר חפצי זוהר",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_906dhz7zt2",
      six: 0,
      eix: 0,
      words: [
        {
          text: "הרי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כולנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היינו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בוועדת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כספים,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כולנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ראינו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ושמענו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ואכן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשורות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "טובות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שם.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רק",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "רוצה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להוסיף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דבר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אחד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קטן,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "קטן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לתקן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ולהוסיף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ולהגיש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בבקשה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הפנים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "באישור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בבקשה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לעשות.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש...",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נתקלתי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "באחד,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ובשבוע",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שניים.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בתים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סגורים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כתוצאה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ממוות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעל",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המקום",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "והבית",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סגור,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לפטור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אותו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בשנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "של",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ארנונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ולא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בחצי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בלבד,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מפני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהבית",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "סגור",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ויש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הרבה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מאוד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ניואנסים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעצם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "משפיעים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המשפחות",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ולא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תמיד",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לכולם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הבן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "או",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הבת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזאת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "או",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הבן",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יכולים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לשלם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "המיסוי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הזה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ועיריית",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "באר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שבע",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יכולה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לקחת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ולהבין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "העניין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בצורה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הנכונה.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
      speakerNameEdit: false,
    },
    {
      id: "r_w1kw5z7lq5b",
      six: 0,
      eix: 0,
      words: [
        {
          text: "השאלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "צריך",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "להכניס",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בצו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הארנונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "או",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שאין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שיקול",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דעת-",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_t2a60729t8",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שיקול",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דעת,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "חיים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אמר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שאין",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שיקול",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "דעת.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_l7rh14gkwnh",
      six: 0,
      eix: 0,
      words: [
        {
          text: "העלת",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "את",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "זה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "בעבר,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כמה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כאלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שמבקשים?",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_natez7f9a9",
      six: 0,
      eix: 0,
      words: [
        {
          text: "תראה,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אלי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כבר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלוש.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_xgma2j1ghb",
      six: 0,
      eix: 0,
      words: [
        {
          text: "הנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יחיאל,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יחיאל.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_r59uo8ziv7l",
      six: 0,
      eix: 0,
      words: [
        {
          text: "אלי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלוש,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "עכשיו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "משער",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שיש",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "כאלה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שלא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יודעים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "למי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לפנות.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "משה ינאי",
      speakerId: null,
      type: "speaker",
      annotations: [],
      speakerNameEdit: false,
    },
    {
      id: "r_0c5bn3la1x58",
      six: 0,
      eix: 0,
      words: [
        {
          text: "בוא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "יחיאל,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "תצטרף",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אלינו.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "רוביק דנילוביץ׳",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
    {
      id: "r_iwwnf2uiaoh",
      six: 0,
      eix: 0,
      words: [
        {
          text: "רוביק,",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מדובר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שינוי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שהוא",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שינוי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "גורף.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אני",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "מזכיר",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "לכם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "ששנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שעברה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "אנחנו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שינינו.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "פעם",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "היינו",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "נותנים",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "על",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "החצי",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "שנה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "הראשונה",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
        {
          text: "100%.",
          start: 0,
          end: 2.3,
          range_ix: 0,
          speaker: "speaker",
        },
      ],
      st: 0,
      et: 2.3,
      speakerName: "עו״ד חיים ארקל",
      speakerId: null,
      type: "speaker",
      annotations: [],
    },
  ],
};

export default jobMock;
