import _ from "lodash";
import React, { FC, useEffect, useState } from "react";

import "./BarLoader.scss";

interface Props {
  isAnimating?: boolean;
  progress?: number;
}

const BarLoader: FC<Props> = ({
  isAnimating = true,
  progress,
}): JSX.Element => {
  const intervals = 250;
  const [lines, setLines] = useState<number[][]>([]);
  const [linesState, setLineState] = useState<number>(0);

  useEffect(() => {
    setLines([
      [49, 15, 24.5, 15],
      [39.5, 24.5, 32, 32],
      [32, 39.5, 15, 53.5],
      [15, 24.5, 53.5, 32],
      [53.5, 15, 39, 49],
      [60.5, 32, 24.5, 39],
      [15, 53.5, 32, 60.5],
      [32, 24.5, 53.5, 24.5],
      [24.5, 59.5, 39, 15],
      [53.5, 32, 15, 24.5],
      [24.5, 15, 39, 32],
    ]);

    if (isAnimating) {
      let counter = 0;
      const interval = setInterval(() => {
        counter < 3 ? (counter = counter + 1) : (counter = 0);
        setLineState(counter);
      }, intervals);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div className="BarLoader">
      <div className="lines-container">
        {lines.length > 0 &&
          lines.map((line, idx) => (
            <div
              key={idx}
              className="line"
              style={{ minHeight: `${line[linesState]}%` }}
            ></div>
          ))}
      </div>
      {!_.isNil(progress) && (
        <div className="progressBar">
          <div className="bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
    </div>
  );
};

export default BarLoader;
