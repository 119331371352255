import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../store/rootReducer";

import FirebaseService from "../../services/FirebaseService";
import { popIndicator } from "../../store/system/actions";

import EditableText from "../../components/EditableText/EditableText";
import Button from "../../components/common/Button/Button";

import "./TranslationsEditor.scss";

const TranslationsEditor: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const clearTranslationCache = (wait: boolean): Promise<any> => {
    return new Promise((res: any, rej: any) => {
      try {
        if (i18n.options.preload) {
          i18n.options.preload.forEach((lang: string) => {
            localStorage.removeItem(`i18next_res_${lang}-translation`);
          });
        }
        if (wait) {
          setTimeout(() => {
            res();
          }, 1000);
        } else {
          res();
        }
      } catch (err) {
        rej(err);
      }
    });
  };

  const [i18nResources, setI18nResources] = useState<{
    [lang: string]: {
      [key: string]: string;
    };
  }>({});
  const [translationKeys, setTranslationKeys] = useState<string[]>([]);
  const [isSaveing, setIsSaving] = useState(false);
  const [numOfOpenEditableTexts, setNumOfOpenEditableTexts] = useState<number>(
    0
  );

  useEffect(() => {
    if (loggedInUser && loggedInUser.role !== "super_user") {
      history.push("/");
    }
  }, [loggedInUser]);

  useEffect(() => {
    const fetchData = async () => {
      //Clear translations cache to enforce fetching from backend
      await clearTranslationCache(true);
      if (i18n.options.preload) {
        const languages: string[] = i18n.options.preload;
        const langMap = {};
        let uniqTranslationKeys: string[] = [];
        for (const lang of languages) {
          const resource = i18n.getResourceBundle(lang, "translation");
          _.set(langMap, lang, resource);
          uniqTranslationKeys = _.uniq(
            _.concat(uniqTranslationKeys, _.keys(resource))
          );
        }
        setI18nResources(langMap);
        setTranslationKeys(uniqTranslationKeys);
      }
    };
    fetchData();
  }, []);

  const saveTranslations = async () => {
    try {
      setIsSaving(true);
      await FirebaseService.saveTranslations(i18nResources);
      await clearTranslationCache(false);
      dispatch(
        popIndicator({ type: "success", txt: t("update_translation_success") })
      );
    } catch (err) {
      dispatch(
        popIndicator({ type: "failure", txt: t("update_translation_fail") })
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangeString = (lang: string, key: string, newString: string) => {
    const updatedStrings = _.clone(i18nResources);
    updatedStrings[lang][key] = newString;
    setI18nResources(updatedStrings);
  };

  return (
    <main className="main-container TranslationsEditor">
      <div className="translationTable">
        <div className="header">
          <div className="title"></div>
          {_.map(i18nResources, (langVal, langKey) => (
            <div className="title" key={langKey}>
              {t(langKey)}
            </div>
          ))}
          <Button
            disabled={numOfOpenEditableTexts > 0}
            className="saveButton"
            onClick={saveTranslations}
            loading={isSaveing}
            label={t("save")}
          />
        </div>
        <div className="translationKeys">
          {_.map(translationKeys, (k) => (
            <div className="translationString" key={k}>
              <div className="translationKey">{k}</div>
              {_.map(i18nResources, (langVal, langKey) => (
                <div className="languageKeyValue" key={langKey}>
                  <EditableText
                    type="string"
                    showIcon={true}
                    ellipsis={false}
                    value={i18nResources[langKey][k]}
                    placeholder={i18nResources[langKey][k]}
                    handleSetNewValue={(newString) =>
                      handleChangeString(langKey, k, newString as string)
                    }
                    onFocusEditMode={() => {
                      const newVal = numOfOpenEditableTexts + 1;
                      setNumOfOpenEditableTexts(newVal);
                    }}
                    onCancleEditMode={() => {
                      const newVal = numOfOpenEditableTexts - 1;
                      setNumOfOpenEditableTexts(newVal);
                    }}
                    onConfirmEditMode={() => {
                      const newVal = numOfOpenEditableTexts - 1;
                      setNumOfOpenEditableTexts(newVal);
                    }}
                  />
                </div>
              ))}
              <div className="spacer" />
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default TranslationsEditor;
