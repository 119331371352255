export default {
  _w9qlh3gjcu: {
    roomId: "_w9qlh3gjcu",
    name: "kan_mil1.mp4",
    speakers: ["אהוד"],
    deadline: new Date(
      "Sat Sep 04 2021 18:10:51 GMT+0300 (Israel Daylight Time)"
    ),
    creationTime: new Date(
      "Fri Sep 03 2021 18:10:51 GMT+0300 (Israel Daylight Time)"
    ),
    meetingLength: 394.692789,
    previewFormat: "subtitles",
    price: 120,
    lang: {
      output: "he-IL",
      input: "he-IL",
    },
    processProgressLastUpdate: null,
    timeTrack: 28068,
    assets: [
      {
        name: "Default",
        src:
          "https://firebasestorage.googleapis.com/v0/b/sumit-dev-0.appspot.com/o/rooms%2F_w9qlh3gjcu%2Forig_raw.mp4?alt=media&token=cb2ec6f6-c6a0-42e7-8e2b-51a1554f9b79",
        type: "video",
      },
      {
        name: "Default",
        src:
          "https://firebasestorage.googleapis.com/v0/b/sumit-dev-0.appspot.com/o/rooms%2F_w9qlh3gjcu%2Fready.wav?alt=media&token=ccdf6399-3fb5-4cfa-963d-b974d7d9e024",
        type: "audio",
      },
    ],
    words: [
      {
        text: "אחד",
        start: 1,
        end: 2.5,
        word: "אחד",
        range_ix: 0,
        start_time: 1,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "שתיים",
        start: 6.14994245177169,
        end: 2.5,
        word: "שתיים",
        range_ix: 0,
        start_time: 6.14994245177169,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "שלוש",
        start: 6.14994245177169,
        end: 3,
        word: "שלוש",
        range_ix: 0,
        start_time: 6.14994245177169,
        end_time: 3,
        speaker: "אהוד",
      },
      {
        text: "ארבע",
        start: 4,
        end: 2.5,
        word: "ארבע",
        range_ix: 1,
        start_time: 4,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "חמש",
        start: 3.1,
        end: 2.5,
        word: "חמש",
        range_ix: 1,
        start_time: 3.1,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "שש",
        start: 3.1,
        end: 6,
        word: "שש",
        range_ix: 1,
        start_time: 3.1,
        end_time: 6,
        speaker: "אהוד",
      },
      {
        text: "שבע",
        start: 7,
        end: 16.44979995139682,
        word: "שבע",
        range_ix: 2,
        start_time: 7,
        end_time: 16.44979995139682,
        speaker: "אהוד",
      },
      {
        text: "שמונה",
        start: 3.1,
        end: 16.44979995139682,
        word: "שמונה",
        range_ix: 2,
        start_time: 3.1,
        end_time: 16.44979995139682,
        speaker: "אהוד",
      },
      {
        text: "תשע",
        start: 3.1,
        end: 8,
        word: "תשע",
        range_ix: 2,
        start_time: 3.1,
        end_time: 8,
        speaker: "אהוד",
      },
      {
        text: "עשר",
        start: 10,
        end: 2.5,
        word: "עשר",
        range_ix: 3,
        start_time: 10,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "אחת",
        start: 3.1,
        end: 2.5,
        word: "אחת",
        range_ix: 3,
        start_time: 3.1,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "עשרה",
        start: 3.1,
        end: 2.5,
        word: "עשרה",
        range_ix: 3,
        start_time: 3.1,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "שתיים",
        start: 3.1,
        end: 2.5,
        word: "שתיים",
        range_ix: 3,
        start_time: 3.1,
        end_time: 2.5,
        speaker: "אהוד",
      },
      {
        text: "עשרה",
        start: 3.1,
        end: 12,
        word: "עשרה",
        range_ix: 3,
        start_time: 3.1,
        end_time: 12,
        speaker: "אהוד",
      },
    ],
  },
  _sun4gsrxv: {
    roomId: "_sun4gsrxv",
    name: "מחלקת שפע - קובי בריגע 04/11",
    speakers: [
      "דובר לזיהוי 1",
      "שמעון רוזיליו",
      "דובר לזיהוי 2",
      "דניאל אגרונוב",
      "לימור אללוף",
    ],
    deadline: new Date(
      "Sat Sep 04 2021 18:10:51 GMT+0300 (Israel Daylight Time)"
    ),
    creationTime: new Date(
      "Sat Sep 04 2021 18:10:51 GMT+0300 (Israel Daylight Time)"
    ),
    meetingLength: 2946.090667,
    previewFormat: "protocol",
    price: "96.4",
    lang: {
      output: "he-IL",
      input: "he-IL",
    },
    processProgressLastUpdate: null,
    assets: [
      {
        name: "Default",
        src:
          "https://firebasestorage.googleapis.com/v0/b/sumit-dev-0.appspot.com/o/rooms%2F_sun4gsrxv%2Forig_raw.mp4?alt=media&token=aac21d10-166d-4be8-aa5b-58f3c76db92d",
        type: "video",
      },
      {
        name: "Default",
        src:
          "https://firebasestorage.googleapis.com/v0/b/sumit-dev-0.appspot.com/o/rooms%2F_sun4gsrxv%2Fready.wav?alt=media&token=64973a29-6972-4ba9-887e-6df5c758cd58",
        type: "audio",
      },
    ],
    words: [
      {
        text: "הכל",
        start: 1,
        end: 5.7,
        word: "הכל",
        range_ix: 0,
        start_time: 1,
        end_time: 5.7,
        speaker: "דובר לזיהוי 1",
      },
      {
        text: "בסגדרמ",
        start: 5.7,
        end: 6.1,
        word: "בסגדרמ",
        range_ix: 0,
        start_time: 5.7,
        end_time: 6.1,
        speaker: "דובר לזיהוי 1",
      },
      {
        text: "טוב",
        start: 6.1,
        end: 10.4,
        word: "טוב",
        range_ix: 0,
        start_time: 6.1,
        end_time: 10.4,
        speaker: "שמעון רוזיליו",
      },
      {
        text: "בוא",
        start: 10.4,
        end: 10.8,
        word: "בוא",
        range_ix: 0,
        start_time: 10.4,
        end_time: 10.8,
        speaker: "שמעון רוזיליו",
      },
      {
        text: "אני",
        start: 10.8,
        end: 11.4,
        word: "אני",
        range_ix: 0,
        start_time: 10.8,
        end_time: 11.4,
        speaker: "שמעון רוזיליו",
      },
      {
        text: "רוצה",
        start: 11.4,
        end: 11.4,
        word: "רוצה",
        range_ix: 0,
        start_time: 11.4,
        end_time: 11.4,
        speaker: "שמעון רוזיליו",
      },
      {
        text: "להתחיל",
        start: 11.4,
        end: 14,
        word: "להתחיל",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "שמעון רוזיליו",
      },
      {
        text: ",",
        start: 11.4,
        end: 14,
        word: ",",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "תראו",
        start: 11.4,
        end: 14,
        word: "תראו",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אני",
        start: 11.4,
        end: 14,
        word: "אני",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אעשה",
        start: 11.4,
        end: 14,
        word: "אעשה",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "איזשהו",
        start: 11.4,
        end: 14,
        word: "איזשהו",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "פתיח",
        start: 11.4,
        end: 14,
        word: "פתיח",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הבטיח",
        start: 11.4,
        end: 14,
        word: "הבטיח",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "ואחר",
        start: 11.4,
        end: 14,
        word: "ואחר",
        range_ix: 0,
        start_time: 11.4,
        end_time: 14,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כך",
        start: 16.1,
        end: 16.5,
        word: "כך",
        range_ix: 0,
        start_time: 16.1,
        end_time: 16.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "גם",
        start: 16.5,
        end: 17.5,
        word: "גם",
        range_ix: 0,
        start_time: 16.5,
        end_time: 17.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לשבת",
        start: 17.5,
        end: 17.9,
        word: "לשבת",
        range_ix: 0,
        start_time: 17.5,
        end_time: 17.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "קובי",
        start: 17.9,
        end: 20,
        word: "קובי",
        range_ix: 0,
        start_time: 17.9,
        end_time: 20,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "ולשמוע",
        start: 20,
        end: 20.5,
        word: "ולשמוע",
        range_ix: 0,
        start_time: 20,
        end_time: 20.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "את",
        start: 20.5,
        end: 20.6,
        word: "את",
        range_ix: 0,
        start_time: 20.5,
        end_time: 20.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הדברים",
        start: 20.6,
        end: 21,
        word: "הדברים",
        range_ix: 0,
        start_time: 20.6,
        end_time: 21,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כי",
        start: 21,
        end: 21.2,
        word: "כי",
        range_ix: 0,
        start_time: 21,
        end_time: 21.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אני",
        start: 21.2,
        end: 22.9,
        word: "אני",
        range_ix: 0,
        start_time: 21.2,
        end_time: 22.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 22.9,
        end: 23,
        word: "לא",
        range_ix: 0,
        start_time: 22.9,
        end_time: 23,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מכיר",
        start: 23,
        end: 25.3,
        word: "מכיר",
        range_ix: 0,
        start_time: 23,
        end_time: 25.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "את",
        start: 25.3,
        end: 25.4,
        word: "את",
        range_ix: 0,
        start_time: 25.3,
        end_time: 25.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הבחור",
        start: 25.4,
        end: 26,
        word: "הבחור",
        range_ix: 0,
        start_time: 25.4,
        end_time: 26,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהיה",
        start: 26,
        end: 27.5,
        word: "שהיה",
        range_ix: 0,
        start_time: 26,
        end_time: 27.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מעורב",
        start: 27.5,
        end: 27.7,
        word: "מעורב",
        range_ix: 0,
        start_time: 27.5,
        end_time: 27.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "זאת",
        start: 27.7,
        end: 28.1,
        word: "זאת",
        range_ix: 0,
        start_time: 27.7,
        end_time: 28.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מירה",
        start: 28.1,
        end: 28.4,
        word: "מירה",
        range_ix: 0,
        start_time: 28.1,
        end_time: 28.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אבל",
        start: 28.4,
        end: 29.3,
        word: "אבל",
        range_ix: 0,
        start_time: 28.4,
        end_time: 29.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אני",
        start: 29.3,
        end: 29.5,
        word: "אני",
        range_ix: 0,
        start_time: 29.3,
        end_time: 29.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אתן",
        start: 29.5,
        end: 29.6,
        word: "אתן",
        range_ix: 0,
        start_time: 29.5,
        end_time: 29.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "את",
        start: 29.6,
        end: 29.7,
        word: "את",
        range_ix: 0,
        start_time: 29.6,
        end_time: 29.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "זה",
        start: 29.7,
        end: 29.8,
        word: "זה",
        range_ix: 0,
        start_time: 29.7,
        end_time: 29.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהוא",
        start: 29.8,
        end: 30,
        word: "שהוא",
        range_ix: 0,
        start_time: 29.8,
        end_time: 30,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "תקציר",
        start: 30,
        end: 30.5,
        word: "תקציר",
        range_ix: 0,
        start_time: 30,
        end_time: 30.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "ומשם",
        start: 30.5,
        end: 31.5,
        word: "ומשם",
        range_ix: 0,
        start_time: 30.5,
        end_time: 31.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אנחנו",
        start: 31.5,
        end: 31.8,
        word: "אנחנו",
        range_ix: 0,
        start_time: 31.5,
        end_time: 31.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מתקדמים",
        start: 31.8,
        end: 32.4,
        word: "מתקדמים",
        range_ix: 0,
        start_time: 31.8,
        end_time: 32.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כי",
        start: 32.4,
        end: 32.9,
        word: "כי",
        range_ix: 0,
        start_time: 32.4,
        end_time: 32.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "המטרה",
        start: 32.9,
        end: 33.3,
        word: "המטרה",
        range_ix: 0,
        start_time: 32.9,
        end_time: 33.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שלנו",
        start: 33.3,
        end: 33.6,
        word: "שלנו",
        range_ix: 0,
        start_time: 33.3,
        end_time: 33.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כרגע",
        start: 33.6,
        end: 34,
        word: "כרגע",
        range_ix: 0,
        start_time: 33.6,
        end_time: 34,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "ולהחזיר",
        start: 34,
        end: 35.4,
        word: "ולהחזיר",
        range_ix: 0,
        start_time: 34,
        end_time: 35.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "את",
        start: 35.4,
        end: 35.6,
        word: "את",
        range_ix: 0,
        start_time: 35.4,
        end_time: 35.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "קובי",
        start: 35.6,
        end: 35.8,
        word: "קובי",
        range_ix: 0,
        start_time: 35.6,
        end_time: 35.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לעבודה",
        start: 35.8,
        end: 36.3,
        word: "לעבודה",
        range_ix: 0,
        start_time: 35.8,
        end_time: 36.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בסדר",
        start: 36.3,
        end: 37,
        word: "בסדר",
        range_ix: 0,
        start_time: 36.3,
        end_time: 37,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הסיפור",
        start: 37,
        end: 39.7,
        word: "הסיפור",
        range_ix: 0,
        start_time: 37,
        end_time: 39.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "היה",
        start: 39.7,
        end: 39.8,
        word: "היה",
        range_ix: 0,
        start_time: 39.7,
        end_time: 39.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כשכל",
        start: 39.8,
        end: 40.4,
        word: "כשכל",
        range_ix: 0,
        start_time: 39.8,
        end_time: 40.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הסיפור",
        start: 40.4,
        end: 40.9,
        word: "הסיפור",
        range_ix: 0,
        start_time: 40.4,
        end_time: 40.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בעצם",
        start: 40.9,
        end: 41.1,
        word: "בעצם",
        range_ix: 0,
        start_time: 40.9,
        end_time: 41.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "התחיל",
        start: 41.1,
        end: 41.5,
        word: "התחיל",
        range_ix: 0,
        start_time: 41.1,
        end_time: 41.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בסדר",
        start: 41.5,
        end: 42.1,
        word: "בסדר",
        range_ix: 0,
        start_time: 41.5,
        end_time: 42.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "היה",
        start: 42.1,
        end: 42.3,
        word: "היה",
        range_ix: 0,
        start_time: 42.1,
        end_time: 42.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "איזה",
        start: 42.3,
        end: 42.5,
        word: "איזה",
        range_ix: 0,
        start_time: 42.3,
        end_time: 42.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהוא",
        start: 42.5,
        end: 42.7,
        word: "שהוא",
        range_ix: 0,
        start_time: 42.5,
        end_time: 42.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "יחשוב",
        start: 42.7,
        end: 43.1,
        word: "יחשוב",
        range_ix: 0,
        start_time: 42.7,
        end_time: 43.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שקובי",
        start: 43.1,
        end: 45.5,
        word: "שקובי",
        range_ix: 0,
        start_time: 43.1,
        end_time: 45.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אמר",
        start: 45.5,
        end: 47,
        word: "אמר",
        range_ix: 0,
        start_time: 45.5,
        end_time: 47,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שיש",
        start: 47,
        end: 47.5,
        word: "שיש",
        range_ix: 0,
        start_time: 47,
        end_time: 47.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "קובי",
        start: 47.5,
        end: 47.9,
        word: "קובי",
        range_ix: 0,
        start_time: 47.5,
        end_time: 47.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "פרץ",
        start: 47.9,
        end: 48.2,
        word: "פרץ",
        range_ix: 0,
        start_time: 47.9,
        end_time: 48.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "פטר",
        start: 48.2,
        end: 48.6,
        word: "פטר",
        range_ix: 0,
        start_time: 48.2,
        end_time: 48.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 48.6,
        end: 48.9,
        word: "לא",
        range_ix: 0,
        start_time: 48.6,
        end_time: 48.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "עשינו",
        start: 48.9,
        end: 49.4,
        word: "עשינו",
        range_ix: 0,
        start_time: 48.9,
        end_time: 49.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "איזה",
        start: 49.4,
        end: 49.6,
        word: "איזה",
        range_ix: 0,
        start_time: 49.4,
        end_time: 49.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהוא",
        start: 49.6,
        end: 49.8,
        word: "שהוא",
        range_ix: 0,
        start_time: 49.6,
        end_time: 49.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "ברור",
        start: 49.8,
        end: 50.1,
        word: "ברור",
        range_ix: 0,
        start_time: 49.8,
        end_time: 50.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שעשינו",
        start: 50.1,
        end: 50.5,
        word: "שעשינו",
        range_ix: 0,
        start_time: 50.1,
        end_time: 50.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "היה",
        start: 50.5,
        end: 51.3,
        word: "היה",
        range_ix: 0,
        start_time: 50.5,
        end_time: 51.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "התאריך",
        start: 51.3,
        end: 52.5,
        word: "התאריך",
        range_ix: 0,
        start_time: 51.3,
        end_time: 52.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "של",
        start: 52.5,
        end: 52.8,
        word: "של",
        range_ix: 0,
        start_time: 52.5,
        end_time: 52.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כבר",
        start: 52.8,
        end: 52.9,
        word: "כבר",
        range_ix: 0,
        start_time: 52.8,
        end_time: 52.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מאחורינו",
        start: 52.9,
        end: 53.4,
        word: "מאחורינו",
        range_ix: 0,
        start_time: 52.9,
        end_time: 53.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "עשינו",
        start: 53.4,
        end: 53.8,
        word: "עשינו",
        range_ix: 0,
        start_time: 53.4,
        end_time: 53.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הכל",
        start: 53.8,
        end: 54.1,
        word: "הכל",
        range_ix: 0,
        start_time: 53.8,
        end_time: 54.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "איפה",
        start: 54.1,
        end: 54.4,
        word: "איפה",
        range_ix: 0,
        start_time: 54.1,
        end_time: 54.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הבעיה",
        start: 54.4,
        end: 54.7,
        word: "הבעיה",
        range_ix: 0,
        start_time: 54.4,
        end_time: 54.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כרגע",
        start: 54.7,
        end: 55.3,
        word: "כרגע",
        range_ix: 0,
        start_time: 54.7,
        end_time: 55.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מכינת",
        start: 57.7,
        end: 58.4,
        word: "מכינת",
        range_ix: 0,
        start_time: 57.7,
        end_time: 58.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "קובי",
        start: 58.4,
        end: 59.7,
        word: "קובי",
        range_ix: 0,
        start_time: 58.4,
        end_time: 59.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הוא",
        start: 59.7,
        end: 61,
        word: "הוא",
        range_ix: 0,
        start_time: 59.7,
        end_time: 61,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "חוזר",
        start: 61,
        end: 62,
        word: "חוזר",
        range_ix: 0,
        start_time: 61,
        end_time: 62,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "למערכת",
        start: 62,
        end: 62.3,
        word: "למערכת",
        range_ix: 0,
        start_time: 62,
        end_time: 62.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בסדר",
        start: 62.3,
        end: 63.2,
        word: "בסדר",
        range_ix: 0,
        start_time: 62.3,
        end_time: 63.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "וחוזר",
        start: 63.2,
        end: 63.7,
        word: "וחוזר",
        range_ix: 0,
        start_time: 63.2,
        end_time: 63.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לעבודה",
        start: 63.7,
        end: 64.2,
        word: "לעבודה",
        range_ix: 0,
        start_time: 63.7,
        end_time: 64.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שלו",
        start: 64.2,
        end: 65.4,
        word: "שלו",
        range_ix: 0,
        start_time: 64.2,
        end_time: 65.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "במועצה",
        start: 65.4,
        end: 66.7,
        word: "במועצה",
        range_ix: 0,
        start_time: 65.4,
        end_time: 66.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שיש",
        start: 66.7,
        end: 67.9,
        word: "שיש",
        range_ix: 0,
        start_time: 66.7,
        end_time: 67.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "פה",
        start: 67.9,
        end: 68.1,
        word: "פה",
        range_ix: 0,
        start_time: 67.9,
        end_time: 68.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "איזה",
        start: 68.1,
        end: 68.2,
        word: "איזה",
        range_ix: 0,
        start_time: 68.1,
        end_time: 68.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהיא",
        start: 68.2,
        end: 68.6,
        word: "שהיא",
        range_ix: 0,
        start_time: 68.2,
        end_time: 68.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בעיה",
        start: 68.6,
        end: 68.7,
        word: "בעיה",
        range_ix: 0,
        start_time: 68.6,
        end_time: 68.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כי",
        start: 68.7,
        end: 69,
        word: "כי",
        range_ix: 0,
        start_time: 68.7,
        end_time: 69,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הוא",
        start: 69,
        end: 69.2,
        word: "הוא",
        range_ix: 0,
        start_time: 69,
        end_time: 69.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שאנחנו",
        start: 69.2,
        end: 69.4,
        word: "שאנחנו",
        range_ix: 0,
        start_time: 69.2,
        end_time: 69.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "צריכים",
        start: 69.4,
        end: 69.7,
        word: "צריכים",
        range_ix: 0,
        start_time: 69.4,
        end_time: 69.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לדבר",
        start: 69.7,
        end: 70.3,
        word: "לדבר",
        range_ix: 0,
        start_time: 69.7,
        end_time: 70.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "עליה",
        start: 70.3,
        end: 70.7,
        word: "עליה",
        range_ix: 0,
        start_time: 70.3,
        end_time: 70.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מה",
        start: 70.7,
        end: 71.2,
        word: "מה",
        range_ix: 0,
        start_time: 70.7,
        end_time: 71.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "זה",
        start: 71.2,
        end: 71.2,
        word: "זה",
        range_ix: 0,
        start_time: 71.2,
        end_time: 71.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לשבת",
        start: 71.2,
        end: 71.5,
        word: "לשבת",
        range_ix: 0,
        start_time: 71.2,
        end_time: 71.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בדירה",
        start: 71.5,
        end: 72.1,
        word: "בדירה",
        range_ix: 0,
        start_time: 71.5,
        end_time: 72.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הייתה",
        start: 72.1,
        end: 73.5,
        word: "הייתה",
        range_ix: 0,
        start_time: 72.1,
        end_time: 73.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "איזה",
        start: 73.5,
        end: 73.7,
        word: "איזה",
        range_ix: 0,
        start_time: 73.5,
        end_time: 73.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהיא",
        start: 73.7,
        end: 73.7,
        word: "שהיא",
        range_ix: 0,
        start_time: 73.7,
        end_time: 73.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "התנהלות",
        start: 73.7,
        end: 74.4,
        word: "התנהלות",
        range_ix: 0,
        start_time: 73.7,
        end_time: 74.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "משמעותית",
        start: 74.4,
        end: 75.4,
        word: "משמעותית",
        range_ix: 0,
        start_time: 74.4,
        end_time: 75.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מאוד",
        start: 75.4,
        end: 75.7,
        word: "מאוד",
        range_ix: 0,
        start_time: 75.4,
        end_time: 75.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מאוד",
        start: 75.7,
        end: 76.2,
        word: "מאוד",
        range_ix: 0,
        start_time: 75.7,
        end_time: 76.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 76.2,
        end: 76.4,
        word: "לא",
        range_ix: 0,
        start_time: 76.2,
        end_time: 76.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "טובה",
        start: 76.4,
        end: 77,
        word: "טובה",
        range_ix: 0,
        start_time: 76.4,
        end_time: 77,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "של",
        start: 77,
        end: 78,
        word: "של",
        range_ix: 0,
        start_time: 77,
        end_time: 78,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "קובי",
        start: 78,
        end: 78.5,
        word: "קובי",
        range_ix: 0,
        start_time: 78,
        end_time: 78.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מול",
        start: 78.5,
        end: 79.1,
        word: "מול",
        range_ix: 0,
        start_time: 78.5,
        end_time: 79.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מספר",
        start: 79.1,
        end: 79.6,
        word: "מספר",
        range_ix: 0,
        start_time: 79.1,
        end_time: 79.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "גורמים",
        start: 79.6,
        end: 80.2,
        word: "גורמים",
        range_ix: 0,
        start_time: 79.6,
        end_time: 80.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אחד",
        start: 80.2,
        end: 81.1,
        word: "אחד",
        range_ix: 0,
        start_time: 80.2,
        end_time: 81.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מהם",
        start: 81.1,
        end: 81.4,
        word: "מהם",
        range_ix: 0,
        start_time: 81.1,
        end_time: 81.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "זה",
        start: 81.4,
        end: 81.5,
        word: "זה",
        range_ix: 0,
        start_time: 81.4,
        end_time: 81.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הוסיף",
        start: 81.5,
        end: 84.1,
        word: "הוסיף",
        range_ix: 0,
        start_time: 81.5,
        end_time: 84.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מאוד",
        start: 84.1,
        end: 84.5,
        word: "מאוד",
        range_ix: 0,
        start_time: 84.1,
        end_time: 84.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 84.5,
        end: 84.6,
        word: "לא",
        range_ix: 0,
        start_time: 84.5,
        end_time: 84.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "יפה",
        start: 84.6,
        end: 84.7,
        word: "יפה",
        range_ix: 0,
        start_time: 84.6,
        end_time: 84.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "והיה",
        start: 84.7,
        end: 88.1,
        word: "והיה",
        range_ix: 0,
        start_time: 84.7,
        end_time: 88.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "סיפור",
        start: 88.1,
        end: 88.6,
        word: "סיפור",
        range_ix: 0,
        start_time: 88.1,
        end_time: 88.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "יחד",
        start: 88.6,
        end: 89.2,
        word: "יחד",
        range_ix: 0,
        start_time: 88.6,
        end_time: 89.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "עם",
        start: 89.2,
        end: 89.5,
        word: "עם",
        range_ix: 0,
        start_time: 89.2,
        end_time: 89.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "דניאל",
        start: 89.5,
        end: 90.2,
        word: "דניאל",
        range_ix: 0,
        start_time: 89.5,
        end_time: 90.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הוא",
        start: 90.2,
        end: 90.9,
        word: "הוא",
        range_ix: 0,
        start_time: 90.2,
        end_time: 90.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "המנהל",
        start: 90.9,
        end: 91.3,
        word: "המנהל",
        range_ix: 0,
        start_time: 90.9,
        end_time: 91.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "הישיר",
        start: 91.3,
        end: 91.6,
        word: "הישיר",
        range_ix: 0,
        start_time: 91.3,
        end_time: 91.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שלו",
        start: 91.6,
        end: 92,
        word: "שלו",
        range_ix: 0,
        start_time: 91.6,
        end_time: 92,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "וכמובן",
        start: 92,
        end: 93.1,
        word: "וכמובן",
        range_ix: 0,
        start_time: 92,
        end_time: 93.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מה",
        start: 93.1,
        end: 93.4,
        word: "מה",
        range_ix: 0,
        start_time: 93.1,
        end_time: 93.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שנוצר",
        start: 93.4,
        end: 93.8,
        word: "שנוצר",
        range_ix: 0,
        start_time: 93.4,
        end_time: 93.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בתוך",
        start: 93.8,
        end: 94.1,
        word: "בתוך",
        range_ix: 0,
        start_time: 93.8,
        end_time: 94.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "המחלקה",
        start: 94.1,
        end: 94.8,
        word: "המחלקה",
        range_ix: 0,
        start_time: 94.1,
        end_time: 94.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בין",
        start: 94.8,
        end: 95.7,
        word: "בין",
        range_ix: 0,
        start_time: 94.8,
        end_time: 95.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לבין",
        start: 95.7,
        end: 97.6,
        word: "לבין",
        range_ix: 0,
        start_time: 95.7,
        end_time: 97.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "עצמם",
        start: 97.6,
        end: 98,
        word: "עצמם",
        range_ix: 0,
        start_time: 97.6,
        end_time: 98,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אני",
        start: 98,
        end: 100.8,
        word: "אני",
        range_ix: 0,
        start_time: 98,
        end_time: 100.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מעלה",
        start: 100.8,
        end: 101.1,
        word: "מעלה",
        range_ix: 0,
        start_time: 100.8,
        end_time: 101.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "את",
        start: 101.1,
        end: 101.2,
        word: "את",
        range_ix: 0,
        start_time: 101.1,
        end_time: 101.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "זה",
        start: 101.2,
        end: 101.2,
        word: "זה",
        range_ix: 0,
        start_time: 101.2,
        end_time: 101.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "בכלל",
        start: 101.2,
        end: 101.6,
        word: "בכלל",
        range_ix: 0,
        start_time: 101.2,
        end_time: 101.6,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כי",
        start: 101.6,
        end: 103.2,
        word: "כי",
        range_ix: 0,
        start_time: 101.6,
        end_time: 103.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אחד",
        start: 103.2,
        end: 103.7,
        word: "אחד",
        range_ix: 0,
        start_time: 103.2,
        end_time: 103.7,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "אני",
        start: 103.7,
        end: 105.3,
        word: "אני",
        range_ix: 0,
        start_time: 103.7,
        end_time: 105.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 105.3,
        end: 105.4,
        word: "לא",
        range_ix: 0,
        start_time: 105.3,
        end_time: 105.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שמתי",
        start: 105.4,
        end: 106.9,
        word: "שמתי",
        range_ix: 0,
        start_time: 105.4,
        end_time: 106.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "זה",
        start: 106.9,
        end: 107.8,
        word: "זה",
        range_ix: 0,
        start_time: 106.9,
        end_time: 107.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 107.8,
        end: 108.1,
        word: "לא",
        range_ix: 0,
        start_time: 107.8,
        end_time: 108.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "התנהגות",
        start: 108.1,
        end: 108.5,
        word: "התנהגות",
        range_ix: 0,
        start_time: 108.1,
        end_time: 108.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "או",
        start: 108.5,
        end: 109.1,
        word: "או",
        range_ix: 0,
        start_time: 108.5,
        end_time: 109.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "התנהגות",
        start: 109.1,
        end: 109.8,
        word: "התנהגות",
        range_ix: 0,
        start_time: 109.1,
        end_time: 109.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "נאותה",
        start: 109.8,
        end: 110.5,
        word: "נאותה",
        range_ix: 0,
        start_time: 109.8,
        end_time: 110.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "ונכונה",
        start: 110.5,
        end: 111.9,
        word: "ונכונה",
        range_ix: 0,
        start_time: 110.5,
        end_time: 111.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מה",
        start: 111.9,
        end: 112.4,
        word: "מה",
        range_ix: 0,
        start_time: 111.9,
        end_time: 112.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שקובי",
        start: 112.4,
        end: 113,
        word: "שקובי",
        range_ix: 0,
        start_time: 112.4,
        end_time: 113,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "התנהג",
        start: 113,
        end: 113.9,
        word: "התנהג",
        range_ix: 0,
        start_time: 113,
        end_time: 113.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "כלפינו",
        start: 113.9,
        end: 115.1,
        word: "כלפינו",
        range_ix: 0,
        start_time: 113.9,
        end_time: 115.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שזה",
        start: 115.1,
        end: 115.8,
        word: "שזה",
        range_ix: 0,
        start_time: 115.1,
        end_time: 115.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "גם",
        start: 115.8,
        end: 115.9,
        word: "גם",
        range_ix: 0,
        start_time: 115.8,
        end_time: 115.9,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לא",
        start: 115.9,
        end: 116,
        word: "לא",
        range_ix: 0,
        start_time: 115.9,
        end_time: 116,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מגיע",
        start: 116,
        end: 116.3,
        word: "מגיע",
        range_ix: 0,
        start_time: 116,
        end_time: 116.3,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לי",
        start: 116.3,
        end: 116.5,
        word: "לי",
        range_ix: 0,
        start_time: 116.3,
        end_time: 116.5,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "באיזה",
        start: 116.5,
        end: 116.8,
        word: "באיזה",
        range_ix: 0,
        start_time: 116.5,
        end_time: 116.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שהיא",
        start: 116.8,
        end: 117,
        word: "שהיא",
        range_ix: 0,
        start_time: 116.8,
        end_time: 117,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "צורה",
        start: 117,
        end: 117.1,
        word: "צורה",
        range_ix: 0,
        start_time: 117,
        end_time: 117.1,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "מצד",
        start: 117.7,
        end: 118.2,
        word: "מצד",
        range_ix: 0,
        start_time: 117.7,
        end_time: 118.2,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "שני",
        start: 118.2,
        end: 118.4,
        word: "שני",
        range_ix: 0,
        start_time: 118.2,
        end_time: 118.4,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "יצא",
        start: 118.4,
        end: 118.8,
        word: "יצא",
        range_ix: 0,
        start_time: 118.4,
        end_time: 118.8,
        speaker: "דובר לזיהוי 2",
      },
      {
        text: "לי",
        start: 118.8,
        end: 118.9,
        word: "לי",
        range_ix: 0,
        start_time: 118.8,
        end_time: 118.9,
        speaker: "דובר לזיהוי 2",
      },
    ],
  },
};
