import React from "react";
import { useTranslation } from "react-i18next";

import { ImagesPageSlice, SettingPagesProps } from "../ExportConfigTypes";

import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";

import "./../SettingPages.scss";

export const ImagesPage = ({ form }: SettingPagesProps<ImagesPageSlice>) => {
  const { t } = useTranslation();

  const imagesWatch: ImagesPageSlice = form?.watch() as ImagesPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_images_title")}</div>
            <ControllerHOC control={control} name="images.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_images_explanation_1")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <ControllerHOC
              control={control}
              name="images.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!imagesWatch?.images?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
