import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";

const writeLog = (
  error: unknown,
  warningLevel: Severity,
  tags?: { label: string; value: string }[]
) => {
  Sentry.withScope(function (scope) {
    tags &&
      tags.map((tag) => {
        scope.setTag(tag.label, tag.value);
      });
    scope.setLevel(warningLevel);
    Sentry.captureException(error);
  });
  console.log(error);
};

const error = (err: unknown, data?: { label: string; value: any }[]) => {
  writeLog(err, Sentry.Severity.Error, data);
};

const log = (err: unknown, data?: { label: string; value: any }[]) => {
  writeLog(err, Sentry.Severity.Log, data);
};

const getLogger = (context: string) => {
  const contextTag = { label: "context", value: context };
  return {
    error: (err: unknown, functionName?: string) => {
      if (functionName) {
        return error(err, [
          contextTag,
          { label: "function-name", value: functionName },
        ]);
      }
      return error(err, [contextTag]);
    },
    log: (err: unknown, functionName?: string) => {
      if (functionName) {
        return log(err, [
          contextTag,
          { label: "function-name", value: functionName },
        ]);
      }
      return log(err, [contextTag]);
    },
  };
};

export default getLogger;
