import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getLanguages } from "../../../utils/locales";

import { popIndicator } from "../../../store/system/actions";

import MessageModal from "../../../components/MessageModal/MessageModal";
import Dropdown from "../../../components/common/Dropdown/Dropdown";

import Logger from "../../../services/Logger";

import "./SelectLangModal.scss";

const logger = Logger("SelectLangModal");

interface Props {
  showModal: boolean;
  closeModal: () => void;
  handleApprove?: (lang: string[] | string) => void;
  multiSelect?: boolean;
}

const SelectLangModal: FC<Props> = ({
  showModal,
  closeModal,
  handleApprove,
  multiSelect = true,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [languages] = useState(getLanguages(t));
  const [selectedLang, setSelectedLang] = useState<string[]>([]);

  const onChange = (output: string | string[]) => {
    let langs = output;

    if (!_.isArray(langs)) {
      langs = [langs];
    }

    setSelectedLang(langs);
  };
  const onApprove = async () => {
    try {
      if (!selectedLang.length) {
        throw "No languages was selected";
      }
      dispatch(popIndicator({ type: "success", txt: t("lang_added") }));
    } catch (err) {
      logger.error(err, "onApprove");
      dispatch(popIndicator({ type: "failure", txt: t("add_lang_fail") }));
    }
    closeModal();
    if (handleApprove) {
      handleApprove(selectedLang);
    }
  };

  return (
    <MessageModal
      className="SelectLangModal warning"
      title={t("chose_lang")}
      isApproveDisabled={!selectedLang.length}
      body={
        <div className="bodyContainer">
          <Dropdown
            options={languages}
            onChange={(option) => onChange(option)}
            value={selectedLang}
            placeholder={t("choose_languages")}
            multiSelect={multiSelect}
          ></Dropdown>
        </div>
      }
      showModal={showModal}
      approve={{ text: t("approve"), action: onApprove }}
      cancel={{ text: t("cancel"), action: closeModal }}
    />
  );
};

export default SelectLangModal;
