import {
  Footer,
  Header,
  Paragraph,
  TextRun,
  Document,
  Packer,
  UnderlineType,
  AlignmentType,
  ImageRun,
  PageNumber,
  ISectionOptions,
  HeadingLevel,
  TableOfContents,
  BorderStyle,
  LineNumberRestartFormat,
  Table,
  TableRow,
  TableCell,
  WidthType,
  VerticalAlign,
  SectionType,
} from "docx";

import _ from "lodash";

import i18n from "../../i18n";

import EditorService, {
  getRangesForDocxExport,
} from "../../services/EditorService";

import { JobData } from "../../models/job";
import { TranscriptType } from "../../models";

import Logger from "../../services/Logger";

import {
  getImageWithRatioFromImageUrl,
  ImageWithRatio,
} from "../../utils/image";

import { getDocumentDate } from "./ExportConfigHelpers";
import getRTLXMLStyles from "./customXMLStyles";

import {
  ExportConfigData,
  DocxData,
  ParagraphContent,
  TextRunContent,
  TableOfContentsContent,
  ExportConfigFontSettings,
  DocxHeaderItemSettings,
  SignatureTableContent,
  IntervalsTable,
} from "./ExportConfigTypes";

import { TextDecoration } from "../../components/common/V3/TextDecorationSelection/TextDecorationSelection";
import { Align } from "../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { getListNumberBySign } from "./Preview";
import { jobTypes } from "../../models/jobTypes";

const logger = Logger("DocxCreator");

//================================================================================
// CLASS DOCX CREATOR START
//================================================================================
class DocxCreator {
  private settings: ExportConfigData;

  private firstPageLogo: ImageWithRatio | null;
  private otherPagesLogo: ImageWithRatio | null;
  private footerLogo: ImageWithRatio | null;
  private jobName: string;

  private headerName: string;
  private headerDate: string;

  private direction: "rtl" | "ltr";
  private paragraphMargin = 250;
  private pageMargin = 1000;
  private lineHeight = 240;
  private letterSpacingPages = 40;

  public setSettings(settings: ExportConfigData) {
    this.settings = settings;
  }

  public setDirection(direction: "rtl" | "ltr") {
    this.direction = direction;
  }

  public setFirstPageLogo(firstPageLogo: ImageWithRatio | null) {
    this.firstPageLogo = firstPageLogo;
  }

  public setOtherPagesLogo(otherPagesLogo: ImageWithRatio | null) {
    this.otherPagesLogo = otherPagesLogo;
  }

  public setFooterLogo(footerLogo: ImageWithRatio | null) {
    this.footerLogo = footerLogo;
  }

  public setHeaderName(headerName: string) {
    this.headerName = headerName;
  }

  public setHeaderDate(headerDate: string) {
    this.headerDate = headerDate;
  }

  public setJobName(jobName: string) {
    this.jobName = jobName;
  }

  private getTextStyle = (fontSettings: ExportConfigFontSettings) => {
    return {
      color: fontSettings?.color ? fontSettings?.color : undefined,
      size: fontSettings?.fontSize ? fontSettings?.fontSize * 2 : undefined,
      underline: fontSettings?.textDecoration?.includes(
        TextDecoration.underline
      )
        ? { color: undefined, type: UnderlineType.SINGLE }
        : undefined,
      italics: fontSettings?.textDecoration?.includes(TextDecoration.italic),
      bold: fontSettings?.textDecoration?.includes(TextDecoration.bold),
      rightToLeft: this.direction === "rtl" ? true : false,
      font: this.settings?.general?.font?.fontFamily,
      characterSpacing:
        this.settings?.general?.format === "pages"
          ? this.letterSpacingPages
          : 0,
    };
  };

  private getAlignment = (align?: Align): AlignmentType => {
    switch (align) {
      case Align.center:
        return AlignmentType.CENTER;
      case Align.left:
        return this.direction === "ltr"
          ? AlignmentType.START
          : AlignmentType.END;
      case Align.right:
        return this.direction === "rtl"
          ? AlignmentType.START
          : AlignmentType.END;
      case Align.justify:
        return AlignmentType.JUSTIFIED;
      default:
        return AlignmentType.START;
    }
  };

  private generateTableOfContents = (
    content: TableOfContentsContent[]
  ): (TableOfContents | Paragraph)[] => {
    const tableOfContentTitle: ParagraphContent[] = [
      {
        textRunContent: [
          {
            phrases: [
              {
                text: content[0].title,
                fontSettings: content[0].fontSettings,
              },
            ],
            disableRowCount: true,
          },
        ],
        fontSettings: content[0].fontSettings,
        marginTop: 0,
      },
    ];
    const tableOfContentTitlePara: Paragraph[] = this.generateParagraph(
      tableOfContentTitle
    );

    return this.settings?.tableOfSubjects?.tableOfSubjects?.isActive
      ? [
          ...tableOfContentTitlePara,
          new TableOfContents(content[0].title, {
            hyperlink: true,
            headingStyleRange: "1-5",
          }),
        ]
      : [];
  };

  private generateSignatureTable = (
    content: SignatureTableContent[]
  ): Table[] => {
    const _pushContentToCells = (
      c: SignatureTableContent,
      cells: TableCell[]
    ): void => {
      cells.push(
        new TableCell({
          // @ts-ignore
          width: 0,
          columnWidths: [3000, 3000],
          margins: {
            top: this.pageMargin / 3,
            bottom: this.pageMargin / 3,
            left: this.pageMargin / 2,
            right: this.pageMargin / 2,
          },
          borders: {
            top: {
              style: BorderStyle.NIL,
              size: 0,
            },
            bottom: {
              style: BorderStyle.NIL,
              size: 0,
            },
            left: {
              style: BorderStyle.NIL,
              size: 0,
            },
            right: {
              style: BorderStyle.NIL,
              size: 0,
            },
          },
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              bidirectional: this.direction === "rtl" ? true : false,
              alignment: AlignmentType.CENTER,
              border: {
                top: {
                  color: "auto",
                  space: 10,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
              children: [
                new TextRun({
                  text: c.columsContent,
                  ...this.getTextStyle(c.fontSettings),
                }),
              ],
            }),
          ],
        })
      );
    };

    const _pushRowsInTables = (): Table[] => {
      const rows: TableRow[] = [];
      let cells: TableCell[] = [];
      content.map((c: SignatureTableContent, i: number) => {
        if (i > 0 && i % 3 === 0) {
          rows.push(new TableRow({ children: cells }));
          cells = [];
          _pushContentToCells(c, cells);
        } else {
          _pushContentToCells(c, cells);
        }
      });

      if (cells.length > 0) {
        rows.push(new TableRow({ children: cells }));
      }

      return rows.map(
        (r) =>
          new Table({
            visuallyRightToLeft: this.direction === "rtl" ? true : false,
            alignment: AlignmentType.CENTER,
            margins: {
              top: this.pageMargin / 3,
              bottom: this.pageMargin / 3,
              left: this.pageMargin / 2,
              right: this.pageMargin / 2,
            },
            // @ts-ignore
            width: 0,
            columns: 3,
            columnWidths: [3000, 3000, 3000],
            rows: [r],
          })
      );
    };

    return _pushRowsInTables();
  };

  private generateIntervalsTable = (content: IntervalsTable[]): Table[] => {
    const margins = {
      top: 200,
      bottom: 200,
      left: 200,
      right: 200,
    };
    const table = [
      new Table({
        // @ts-ignore
        width: 0,
        columnWidths: [4500, 4500],
        columns: 2,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                margins,
                children: [
                  new Paragraph({
                    text: this.jobName,
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              new TableCell({
                margins,
                children: [
                  new Paragraph({
                    text: "TC",
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            ],
          }),
          ...content.map(
            (c) =>
              new TableRow({
                children: [
                  new TableCell({
                    margins,
                    children: c.contentSlice
                      ? this.generateParagraph(c.contentSlice)
                      : [],
                  }),
                  new TableCell({
                    margins,
                    children: [new Paragraph(c.time)],
                  }),
                ],
              })
          ),
        ],
      }),
    ];
    return table;
  };

  private generateContent = (
    content:
      | ParagraphContent[]
      | TableOfContentsContent[]
      | SignatureTableContent[]
      | IntervalsTable[]
  ) => {
    if (_.isArray(content) && _.has(content[0], "title")) {
      return this.generateTableOfContents(content as TableOfContentsContent[]); //TableOfContent
    } else if (_.isArray(content) && _.has(content[0], "columsContent")) {
      return this.generateSignatureTable(content as SignatureTableContent[]); //Signature Table
    } else if (_.isArray(content) && _.has(content[0], "time")) {
      return this.generateIntervalsTable(content as IntervalsTable[]); //Intervals Table
    } else if (_.isArray(content) && _.has(content[0], "textRunContent")) {
      return this.generateParagraph(content as ParagraphContent[]); //paragraphs
    }
    return [];
  };

  private generateParagraph = (content: ParagraphContent[]): Paragraph[] => {
    const paragraphs: Paragraph[] = [];
    content.map((con: ParagraphContent) => {
      con.textRunContent.map((textRun: TextRunContent) => {
        paragraphs.push(
          new Paragraph({
            style: !textRun.heading ? "paragraphStyle" : undefined,
            //This option will remove row count for current row
            suppressLineNumbers: textRun.disableRowCount,
            indent: textRun.indent
              ? {
                  start: 2000,
                  end: 0,
                  hanging: 2000,
                }
              : undefined,
            border: textRun?.frame
              ? {
                  top:
                    this.settings?.decisions?.decisions?.viewType == "a" ||
                    this.settings?.decisions?.decisions?.viewType == "b"
                      ? {
                          color: "auto",
                          space: 1,
                          style: BorderStyle.SINGLE,
                          size: 6,
                        }
                      : undefined,
                  bottom:
                    this.settings?.decisions?.decisions?.viewType === "a" ||
                    this.settings?.decisions?.decisions?.viewType === "b"
                      ? {
                          color: "auto",
                          space: 1,
                          style: BorderStyle.SINGLE,
                          size: 6,
                        }
                      : undefined,
                  left:
                    this.settings?.decisions?.decisions?.viewType === "a"
                      ? {
                          color: "auto",
                          space: 1,
                          style: BorderStyle.SINGLE,
                          size: 6,
                        }
                      : undefined,
                  right:
                    this.settings?.decisions?.decisions?.viewType === "a"
                      ? {
                          color: "auto",
                          space: 1,
                          style: BorderStyle.SINGLE,
                          size: 6,
                        }
                      : undefined,
                }
              : undefined,
            heading: textRun.heading ? textRun.heading : undefined,
            bidirectional: this.direction === "rtl" ? true : false,
            alignment: textRun?.fontSettings?.align
              ? this.getAlignment(textRun.fontSettings.align)
              : this.getAlignment(con.fontSettings.align),
            spacing: {
              line:
                this.settings.general.format === "pages"
                  ? this.lineHeight * 2.5
                  : this.lineHeight * 1.5,
              before:
                con.textRunContent.length > 1
                  ? con.marginTop
                  : this.paragraphMargin,
            },
            children: [
              ...textRun.phrases.map(
                (phrase) =>
                  new TextRun({
                    break: phrase?.break ? phrase.break : 0,
                    text: phrase.text,
                    ...this.getTextStyle(phrase.fontSettings),
                  })
              ),
            ],
          })
        );
      });
    });
    return paragraphs;
  };

  private generateHeader = (
    isActive: boolean,
    logo: ImageWithRatio | null,
    logoSettings: DocxHeaderItemSettings,
    nameSettings: DocxHeaderItemSettings,
    dateSettings: DocxHeaderItemSettings
  ): Header => {
    const headerChildren = [];

    if (isActive) {
      if (logoSettings.show) {
        headerChildren.push(
          new Paragraph({
            suppressLineNumbers: true,
            style: "headerStyle",
            bidirectional: this.direction === "rtl" ? true : false,
            alignment: this.getAlignment(logoSettings.align),
            children: logo
              ? [
                  new ImageRun({
                    data: logo.buffer,
                    transformation: {
                      width: logo.width,
                      height: logo.height,
                    },
                  }),
                ]
              : [],
          })
        );
      }
      if (nameSettings.show) {
        headerChildren.push(
          new Paragraph({
            suppressLineNumbers: true,
            style: "headerStyle",
            bidirectional: this.direction === "rtl" ? true : false,
            alignment: this.getAlignment(nameSettings.align),
            children: this.headerName
              ? [
                  new TextRun({
                    text: this.headerName,
                    ...this.getTextStyle(nameSettings.style),
                  }),
                ]
              : [],
          })
        );
      }
      if (dateSettings.show) {
        headerChildren.push(
          new Paragraph({
            suppressLineNumbers: true,
            style: "headerStyle",
            bidirectional: this.direction === "rtl" ? true : false,
            alignment: this.getAlignment(dateSettings.align),
            children: this.headerDate
              ? [
                  new TextRun({
                    text: this.headerDate,
                    ...this.getTextStyle(dateSettings.style),
                  }),
                ]
              : [],
          })
        );
      }
    }
    return new Header({
      children: headerChildren,
    });
  };

  private generateFooter = (): Footer => {
    const footerChildren = [];
    if (this.settings?.headerFooter?.footer?.isActive) {
      if (this.settings?.headerFooter?.footer?.logo?.isActive) {
        footerChildren.push(
          new Paragraph({
            suppressLineNumbers: true,
            style: "footerStyle",
            bidirectional: this.direction === "rtl" ? true : false,
            alignment: this.getAlignment(
              this.settings?.headerFooter?.footer?.logo?.align
            ),
            children: this.footerLogo
              ? [
                  new TextRun({
                    text: this.settings?.headerFooter?.footer?.footerText,
                    size: 12 * 2,
                  }),
                  new ImageRun({
                    data: this.footerLogo.buffer,
                    transformation: {
                      width: this.footerLogo.width,
                      height: this.footerLogo.height,
                    },
                  }),
                ]
              : [],
          })
        );
      }
      if (this.settings?.headerFooter?.footer?.pageNumbering?.isActive) {
        footerChildren.push(
          new Paragraph({
            suppressLineNumbers: true,
            style: "footerStyle",
            bidirectional: this.direction === "rtl" ? true : false,
            alignment: this.getAlignment(
              this.settings.headerFooter.footer.pageNumbering.align
            ),
            children: [
              new TextRun({
                children: [PageNumber.CURRENT],
              }),
            ],
          })
        );
      }
    }

    return new Footer({
      children: footerChildren,
    });
  };

  public generateSection = (sectionOptions: {
    isFirstPage: boolean;
    continuous: boolean;
    content:
      | ParagraphContent[]
      | SignatureTableContent[]
      | TableOfContentsContent[]
      | IntervalsTable[];
  }): ISectionOptions => {
    const lrMarginsByPagesFormat =
      this.settings.general.format === "audio" ||
      this.settings.general.format === "intervals"
        ? this.pageMargin * 1.44
        : this.pageMargin * 2.5;
    return {
      properties: {
        type: sectionOptions.continuous ? SectionType.CONTINUOUS : undefined,
        titlePage: sectionOptions.isFirstPage,
        lineNumbers: this.settings.general.rowCount
          ? {
              countBy: 1,
              restart: LineNumberRestartFormat.NEW_PAGE,
            }
          : undefined,
        page: {
          margin: {
            top: this.pageMargin,
            right: lrMarginsByPagesFormat,
            bottom: this.pageMargin,
            left: lrMarginsByPagesFormat,
            header: this.pageMargin,
            footer: this.pageMargin,
          },
        },
      },
      headers: {
        first: this.generateHeader(
          this.settings?.headerFooter?.firstPageHeader?.isActive,
          this.firstPageLogo,
          {
            show: this.settings?.headerFooter?.firstPageHeader?.logo?.isActive,
            align: this.settings?.headerFooter?.firstPageHeader?.logo?.align,
            style: {},
          },
          {
            show: this.settings?.headerFooter?.firstPageHeader?.jobName
              ?.isActive,
            align: this.settings?.headerFooter?.firstPageHeader?.jobName?.align,
            style: this.settings?.general?.font,
          },
          {
            show: this.settings?.headerFooter?.firstPageHeader?.jobDate
              ?.isActive,
            align: this.settings?.headerFooter?.firstPageHeader?.jobDate?.align,
            style: this.settings?.general?.font,
          }
        ),
        default: this.generateHeader(
          this.settings?.headerFooter?.otherPagesHeader?.isActive,
          this.otherPagesLogo,
          {
            show: this.settings?.headerFooter?.otherPagesHeader?.logo?.isActive,
            align: this.settings?.headerFooter?.otherPagesHeader?.logo?.align,
            style: {},
          },
          {
            show: this.settings?.headerFooter?.otherPagesHeader?.jobName
              ?.isActive,
            align: this.settings?.headerFooter?.otherPagesHeader?.jobName
              ?.align,
            style: this.settings?.general?.font,
          },
          {
            show: this.settings?.headerFooter?.otherPagesHeader?.jobDate
              ?.isActive,
            align: this.settings?.headerFooter?.otherPagesHeader?.jobDate
              ?.align,
            style: this.settings?.general?.font,
          }
        ),
      },
      footers: {
        default: this.generateFooter(),
      },
      children: this.generateContent(sectionOptions.content),
    };
  };

  public generateDocx(data: DocxData, lang: string) {
    const sections = [];
    sections.push(
      this.generateSection({
        isFirstPage: true,
        continuous: false,
        content: data.sections.initialSection,
      })
    );
    if (this.settings.general.format === "intervals") {
      sections.push(
        this.generateSection({
          isFirstPage: false,
          continuous: false,
          content: data.sections.jobContentSectionAsIntervals,
        })
      );
    } else {
      sections.push(
        this.generateSection({
          isFirstPage: false,
          continuous: false,
          content: data.sections.tableOfSubjectsSection,
        })
      );
      sections.push(
        this.generateSection({
          isFirstPage: false,
          continuous: false,
          content: data.sections.jobContentSection,
        })
      );
    }
    sections.push(
      this.generateSection({
        isFirstPage: false,
        continuous: true,
        content: data.sections.jobSignatureSection,
      })
    );
    return new Document({
      externalStyles:
        this.direction === "rtl" ? getRTLXMLStyles(lang) : undefined,
      features: {
        updateFields: true,
      },
      styles: {
        paragraphStyles: [
          {
            id: "paragraphStyle",
            name: "Paragraph Style",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              ...this.getTextStyle(this.settings.general.font),
            },
            paragraph: {
              alignment: this.getAlignment(this.settings.general.font.align),
              spacing: {
                before: this.paragraphMargin,
              },
            },
          },
          {
            id: "footerStyle",
            name: "Footer Style",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              ...this.getTextStyle(this.settings.general.font),
            },
            paragraph: {
              spacing: {
                before: this.paragraphMargin,
              },
            },
          },
          {
            id: "headerStyle",
            name: "Header Style",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              rightToLeft: this.direction === "rtl" ? true : false,
              font: this.settings.general.font.fontFamily,
              size: this.settings.general.font.fontSize
                ? this.settings.general.font.fontSize * 2
                : 16 * 2,
            },
            paragraph: {
              spacing: {
                after: this.paragraphMargin,
              },
            },
          },
        ],
      },
      sections,
    });
  }

  public exportDocx = (docx: Document, jobName: string) => {
    Packer.toBlob(docx).then((blob) => {
      saveAs(blob, `${jobName}.docx`);
    });
  };
}
//================================================================================
// CLASS DOCX CREATOR END
//================================================================================

//================================================================================
// PUSH SECTIONS START
//================================================================================
const pushInitialSection = (
  generateData: DocxData,
  settings: ExportConfigData,
  jobData: JobData
): void => {
  //JOB NAME
  if (settings.information.jobName.isActive) {
    generateData.sections.initialSection.push({
      textRunContent: [
        {
          phrases: [
            {
              text: jobData?.name ? jobData?.name : "",
              fontSettings: settings.information.jobName,
            },
          ],
          disableRowCount: true,
        },
      ],
      fontSettings: settings.information.jobName,
      marginTop: 0,
    });
  }

  // JOB DATES
  if (settings.information.jobDate.isActive) {
    generateData.sections.initialSection.push({
      textRunContent: [
        {
          phrases: [
            {
              text: getDocumentDate(
                jobData.lang.output[0],
                settings.information.jobDate.calendars,
                jobData.creationTime
              ),
              fontSettings: settings.information.jobDate,
            },
          ],
          disableRowCount: true,
        },
      ],
      fontSettings: settings.information.jobDate,
      marginTop: 0,
    });
  }

  //JOB MEMBERS
  const jobSpeakers = jobData?.speakers
    ? jobData?.speakers.filter((s) => s)
    : [];
  if (settings.information.attendees.isActive && !_.isEmpty(jobSpeakers)) {
    generateData.sections.initialSection.push({
      textRunContent: [
        {
          phrases: [
            {
              text: `${i18n.t("participants", {
                lng: jobData?.lang?.output?.length
                  ? jobData?.lang?.output[0]
                  : "en",
              })}: `,
              fontSettings: {
                textDecoration: [TextDecoration.bold, TextDecoration.underline],
              },
            },
          ],
          disableRowCount: true,
        },
      ],
      fontSettings: {},
      marginTop: 0,
    });

    generateData.sections.initialSection.push({
      textRunContent:
        settings.information.format === "row"
          ? [
              {
                phrases: [
                  {
                    text: jobData?.speakers ? jobData?.speakers.join(", ") : "",
                    fontSettings: {},
                  },
                ],
                disableRowCount: true,
              },
            ]
          : jobData?.speakers
          ? jobData?.speakers.map((s) => ({
              phrases: [
                {
                  text: s,
                  fontSettings: {},
                },
              ],
              disableRowCount: true,
            }))
          : [],

      fontSettings: {
        align: settings.speakers.font.align,
      },
      marginTop: 0,
    });
  }
};

const pushTableOfSubjectsSection = (
  generateData: DocxData,
  settings: ExportConfigData,
  jobData: JobData
): void => {
  //TABLE OF CONTENT
  generateData.sections.tableOfSubjectsSection.push({
    title: `${i18n.t("agenda", {
      lng: jobData?.lang?.output?.length ? jobData?.lang?.output[0] : "en",
    })}: `,
    fontSettings: settings.tableOfSubjects.font,
  });
};

const pushJobContentSection = (
  generateData: DocxData,
  settings: ExportConfigData,
  jobData: JobData
): void => {
  const ranges = getRangesForDocxExport(jobData);

  //JOB START
  if (settings.remarks?.startEnd?.isActive)
    generateData.sections.jobContentSection.push({
      textRunContent: [
        {
          phrases: [
            {
              text: settings.remarks.startEnd.text1,
              fontSettings: settings.remarks.startEnd.font,
            },
          ],
        },
      ],
      fontSettings: settings.remarks.startEnd.font,
      marginTop: 300,
    });

  //RANGES
  let subjectsCounter = 1;
  ranges.map((transcript) => {
    if (transcript?.type === TranscriptType.annotation) {
      if (
        settings?.subjects?.subject?.isActive &&
        transcript?.annotationType === "subject"
      ) {
        generateData.sections.jobContentSection.push({
          textRunContent: [
            {
              phrases: [
                {
                  text: `${getListNumberBySign(
                    settings.subjects.sign,
                    subjectsCounter
                  )} ${transcript.text}`,
                  fontSettings: settings.subjects.font,
                },
              ],
              heading: HeadingLevel.HEADING_2,
            },
          ],
          fontSettings: settings.subjects.font,
          marginTop: 0,
        });
        subjectsCounter++;
      } else if (
        settings?.notes?.notes?.isActive &&
        transcript?.annotationType === "note"
      ) {
        generateData.sections.jobContentSection.push({
          textRunContent: [
            {
              phrases: [
                {
                  text: `* ${transcript.text}`,
                  fontSettings: settings.notes.notes.font,
                },
              ],
            },
          ],
          fontSettings: settings.notes.notes.font,
          marginTop: 0,
        });
      } else if (
        settings?.decisions?.decisions?.isActive &&
        transcript?.annotationType === "decision"
      ) {
        generateData?.sections?.jobContentSection?.push({
          textRunContent: [
            {
              phrases: [
                {
                  text: `${i18n.t("decision", {
                    lng: jobData?.lang?.output?.length
                      ? jobData?.lang?.output[0]
                      : "en",
                  })}: ${transcript.text}`,
                  fontSettings: settings?.decisions?.decisions?.font,
                },
              ],
              frame:
                settings?.decisions?.decisions?.viewType === "a" ||
                settings?.decisions?.decisions?.viewType === "b",
            },
          ],
          fontSettings: settings?.decisions?.decisions?.font,
          marginTop: 0,
        });
      }
    } else {
      const jobContentRows: TextRunContent[] = [];
      if (settings?.speakers?.newLine) {
        //SPEAKERS NEWLINE
        jobContentRows.push({
          phrases: [
            {
              text: `${transcript.speaker}:`,
              fontSettings: settings?.speakers?.font,
            },
          ],
          fontSettings: settings?.speakers?.font,
        });
        jobContentRows.push({
          phrases: [
            {
              text:
                (settings?.speakers?.indentation ? `\t` : "") + transcript.text,
              fontSettings: settings?.general?.font,
            },
          ],
          fontSettings: settings?.general?.font,
          indent: settings?.speakers?.indentation,
        });
      } else {
        //SPEAKERS NO NEWLINE
        jobContentRows.push({
          phrases: [
            {
              text: `${transcript.speaker}:`,
              fontSettings: settings.speakers.font,
            },
            {
              text:
                (settings?.speakers?.indentation ? `\t` : " ") +
                transcript.text,
              fontSettings: settings?.general?.font,
            },
          ],
          fontSettings: settings?.general?.font,
          indent: settings?.speakers?.indentation,
        });
      }
      generateData.sections.jobContentSection.push({
        textRunContent: jobContentRows,
        fontSettings: {},
        marginTop: 0,
      });
    }
  });

  //JOB END
  if (settings.remarks?.startEnd?.isActive)
    generateData.sections.jobContentSection.push({
      textRunContent: [
        {
          phrases: [
            {
              text: settings.remarks.startEnd.text2,
              fontSettings: settings.remarks.startEnd.font,
            },
          ],
        },
      ],
      fontSettings: settings.remarks.startEnd.font,
      marginTop: 0,
    });

  //GREETINGS
  if (settings.remarks?.greeting?.isActive)
    generateData.sections.jobContentSection.push({
      textRunContent: [
        {
          phrases: [
            {
              text: settings.remarks.greeting.text1,
              fontSettings: settings.remarks.greeting.font,
            },
          ],
        },
      ],
      fontSettings: settings.remarks.greeting.font,
      marginTop: 300,
    });
};

const pushJobContentSectionAsInterval = (
  generateData: DocxData,
  settings: ExportConfigData,
  jobData: JobData,
  docDir: "ltr" | "rtl"
) => {
  const ranges = EditorService.getProtocolTranscriptForExport(jobData, {
    subtitles: {
      embed: false,
      flip: false,
    },
    docx: {
      direction: docDir,
      layout: "intervals",
      times: true,
      interval: settings.general.interval,
      timeOffset: settings.general.timeOffset,
      startIntervalWithSpeakerName: true,
    },
  });

  ranges.map((r) => {
    const jobContentRows: TextRunContent[] = [];
    jobContentRows.push({
      phrases: r.text.split("\r\n").map((lines) => ({
        text: lines,
        fontSettings: settings?.general?.font,
        break: 1,
      })),
      fontSettings: settings?.general?.font,
    });

    generateData.sections.jobContentSectionAsIntervals?.push({
      time: r.time ? r.time.toString() : "",
      contentSlice: [
        {
          textRunContent: jobContentRows,
          fontSettings: {},
          marginTop: 0,
        },
      ],
    });
  });
};

const pushJobSignaturesSection = (
  generateData: DocxData,
  settings: ExportConfigData,
  jobData: JobData
) => {
  if (settings.remarks?.signature?.names?.length > 0) {
    settings.remarks.signature.names.map((name) => {
      generateData.sections.jobSignatureSection.push({
        columsContent: name,
        fontSettings: settings.remarks.signature.font,
      });
    });
  }
};
//================================================================================
// PUSH SECTIONS END
//================================================================================

//================================================================================
// EXPORT DOCX FUNCTION START
//================================================================================
export const createDocx = async (
  settings: ExportConfigData,
  jobData: JobData,
  docDir: "rtl" | "ltr"
) => {
  try {
    const docxCreator = new DocxCreator();

    docxCreator.setJobName(jobData.name);

    docxCreator.setFirstPageLogo(
      settings.headerFooter.firstPageHeader?.logo?.url
        ? await getImageWithRatioFromImageUrl(
            settings.headerFooter.firstPageHeader.logo.url,
            settings.headerFooter.firstPageHeader.logo.size
          )
        : null
    );

    docxCreator.setOtherPagesLogo(
      settings.headerFooter.otherPagesHeader?.logo?.url
        ? await getImageWithRatioFromImageUrl(
            settings.headerFooter.otherPagesHeader.logo.url,
            settings.headerFooter.otherPagesHeader.logo.size
          )
        : null
    );

    docxCreator.setFooterLogo(
      settings.headerFooter.footer?.logo?.url
        ? await getImageWithRatioFromImageUrl(
            settings.headerFooter.footer.logo.url,
            settings.headerFooter.footer.logo.size
          )
        : null
    );

    docxCreator.setHeaderDate(
      getDocumentDate(
        jobData.lang.output[0],
        settings.information.jobDate.calendars,
        jobData.creationTime
      )
    );

    docxCreator.setHeaderName(jobData.name);

    //Must Set Settings Before Generate New Docx
    docxCreator.setSettings(settings);
    //Must Set direction Before Generate New Docx
    docxCreator.setDirection(docDir);

    const docxData: DocxData = {
      sections: {
        initialSection: [],
        tableOfSubjectsSection: [],
        jobContentSection: [],
        jobContentSectionAsIntervals: [],
        jobSignatureSection: [],
      },
    };

    pushInitialSection(docxData, settings, jobData);
    pushTableOfSubjectsSection(docxData, settings, jobData);
    pushJobContentSectionAsInterval(docxData, settings, jobData, docDir);
    pushJobContentSection(docxData, settings, jobData);
    pushJobSignaturesSection(docxData, settings, jobData);

    const outputLang = jobData.lang[jobTypes[jobData.jobType].lang][0];
    const docx = docxCreator.generateDocx(docxData, outputLang);
    return docx;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
//================================================================================
// EXPORT DOCX FUNCTION END
//================================================================================
