import React, { FC } from "react";
import stc from "string-to-color";

import SpeakerNameEditor from "../../components/SpeakerNameEditor/SpeakerNameEditor";

interface Props {
  speakers: string[];
  renameSpeaker: (
    speaker: string,
    newSpeaker: string,
    speakers: string[],
    mergeSpeakerRanges: boolean
  ) => boolean;
}

const JobSpeakersPanel: FC<Props> = ({ speakers, renameSpeaker }) => {
  return (
    <div className="JobSpeakersPanel">
      <div className="speakersContainer">
        {speakers.map((speaker, i) => (
          <span
            className="speaker"
            style={{
              color: stc(speaker),
            }}
            key={i}
          >
            <SpeakerNameEditor
              speakerName={speaker}
              speakers={speakers}
              autocomplete={false}
              handleSetNewSpeakerName={(newSpeaker) =>
                renameSpeaker(speaker, newSpeaker, speakers, true)
              }
              placeholder={speaker}
              isEditing={false}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default JobSpeakersPanel;
