import React, { FC, useEffect, useState, useRef } from "react";
import _ from "lodash";
import config from "../../config";
import Logger from "../../services/Logger";
import { AppState } from "../../store/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setRoomData, clearRoomData } from "../../store/room/actions";
import { getProofers, getTranscribers } from "../../store/user/actions";
import useActivity from "../../hooks/useActivity";
import { useIdleTimer } from "react-idle-timer";
import { jobTypes } from "../../models/jobTypes";
import {
  setLoadingReason,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";

import {
  format,
  differenceInMinutes,
  differenceInHours,
  isPast,
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stc from "string-to-color";
import LazyLoad from "react-lazyload";
import classNames from "classnames";

import {
  RoomData,
  Word,
  TimeTrack,
  SpeakerSample,
  RoomAsset,
} from "../../models";

import MeetingService from "../../services/MeetingService";
import { FirebaseService } from "../../services/ServiceControler";
import EditorService from "../../services/EditorService";
import TimeService from "../../services/TimeService";
import TrackingService from "../../services/TrackingService";
import SoundManager from "../../services/SoundManager";
import FeatureFlagsService from "../../services/FeatureFlagsService";

import Input from "../../components/common/Input/Input";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import TextRange from "../../components/TextRange/TextRange";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import MyMediaPlayer from "../../components/MediaPlayers/MyMediaPlayer";
import SpeakerNameEditor from "../../components/SpeakerNameEditor/SpeakerNameEditor";
import WaveformRanges from "../../components/WaveformRanges/WaveformRanges";
import MessageModal from "../../components/MessageModal/MessageModal";
import EditableText from "../../components/EditableText/EditableText";
import CircleProgress from "../../components/common/CircleProgress/CircleProgress";
import SelectList from "../../components/common/SelectList/SelectList";

import ExportModal from "../../components/common/ExportModal/ExportModal";
import TimeTrackModal from "../../components/Modals/TimeTrack/TimeTrack";
import NoteModal from "../../components/Modals/NoteModal/NoteModal";
import SpeakerSamples from "../../components/SpeakerSamples/SpeakerSamples";
import MediaPlayerV3 from "../../components/MediaPlayerV3/MediaPlayerV3";

import { scrollInto } from "../../utils/focusAndScroll";

import "./EditorPage.scss";

const logger = Logger("EditorPage");

const MeetingsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const idleTimer = useIdleTimer({
    timeout: config.idleTime,
    onIdle: () => setUserActive(false),
    onActive: () => setUserActive(true),
    debounce: 500,
  });

  const { id } = useParams<Record<string, string>>();
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const proofers = useSelector((state: AppState) => state.userStore.proofers);
  const {
    autoSave,
    playerRewind,
    playerForward,
    playerRewindResume,
  } = useSelector((state: AppState) => state.userStore.settings);
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );

  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const roomData = useSelector((state: AppState) => state.roomStore.roomData);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [userActive, setUserActive] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [ranges, setRanges] = useState<number[]>([]);
  const [timeRanges, setTimeRanges] = useState<
    { rangeIndex: number; start: number; end: number; overlapping: boolean }[]
  >([]);
  const [rangesSizes, setRangesSizes] = useState<number[]>([]);
  const [deadlineStr, setDeadlineStr] = useState<string>("");

  const [currentTime, setCurrentTime] = useState(0);
  const [focusedRangeIndex, setFocusedRangeIndex] = useState(-1);
  const [focusedRangeWordsString, setFocusedRangeWordsString] = useState("");
  const [tempSpeakerRange, setTempSpeakerRange] = useState(-1);
  const [unidentifiedSpeakerCount, setUnidentifiedSpeakerCount] = useState(1);
  const [isSplitView, setIsSplitView] = useState(true);
  const [openExportModal, setOpenExportModal] = useState(false);
  const editorPageRef = useRef<HTMLDivElement>(null);
  const exportBtn = useRef<HTMLButtonElement>(null);

  const isActive = useActivity({ timeToIdle: 30000 });
  const [lastActive, setLastActive] = useState(Date.now());
  const [timeTrack, setTimeTrack] = useState<{
    transcriber: TimeTrack;
    proofer: TimeTrack;
  }>();

  const [wordsSnapshots, setWordsSnapshots] = useState<[Word[]]>();
  const [jobRevisions, setJobRevisions] = useState<any>([]);
  const [selectedJobRevision, setSelectedJobRevision] = useState<string | null>(
    null
  );
  const [timestampsEnterMethod, setTimestampsEnterMethod] = useState("word");
  const [isWaveform, setIsWaveform] = useState(false);

  const [showAlignModal, setShowAlignModal] = useState(false);
  const [transcriberName, setTranscriberName] = useState("");
  const [prooferName, setProoferName] = useState("");
  const [splitCount, setSplitCount] = useState<number | "">("");
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [editorMode, setEditorMode] = useState<
    "protocol" | "subtitles" | "none" | "subtitles-translation"
  >("protocol");
  const [editorDirection, setEditorDirection] = useState<"ltr" | "rtl">("ltr");
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);
  const [showTimeTrackModal, setShowTimeTrackModal] = useState(false);
  const [showRestoreJobModal, setShowRestoreJobModal] = useState(false);
  const [speakerSamples, setSpeakerSamples] = useState<SpeakerSample[] | null>(
    null
  );

  const [chosenMedia, setChosenMedia] = useState<RoomAsset>();

  const validRoles = [
    "super_user",
    "transcriber",
    "proofer",
    "transcriber+proofer",
  ];

  const updateActivity = async () => {
    try {
      if (!timeTrack) return;

      const role = roomData.status === 3 ? "transcriber" : "proofer";
      const currentActivity = ((Date.now() - lastActive) / 1000).toFixed(0);
      const updatedTotalTime =
        (Number(timeTrack[role].totalTime) || 0) + Number(currentActivity);

      // const eventsTimes = await TrackingService.calcEvents();

      const updatedTimeTrack = {
        ...timeTrack,
        [role]: {
          // ...timeTrack[role],
          // ...eventsTimes,
          totalTime: updatedTotalTime,
        },
      };

      setTimeTrack(updatedTimeTrack);
      return updatedTimeTrack;
    } catch (err) {
      logger.error(err, "updateActivity");
    }
  };

  useEffect(() => {
    if (isActive) {
      setLastActive(Date.now());
    }
    if (!isActive) {
      updateActivity();
    }
  }, [isActive]);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.role === "super_user") {
        dispatch(getTranscribers());
        dispatch(getProofers());
      }
      if (!validRoles.includes(loggedInUser.role)) {
        history.push("/");
        return;
      }
      loadRoomData(id);
    }
    return () => {
      dispatch(clearRoomData());
    };
  }, [loggedInUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateRoomData();
    }, 1000 * 60 * 1);
    if (autoSave && userActive) {
      interval;
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [autoSave, userActive]);

  useEffect(() => {
    if (loggedInUser) {
      if (!roomData) return;
      if (loggedInUser.role === "super_user") {
        getTranscriberAndProoferName(roomData);
      }
      const generatedRanges: {
        ranges: number[];
        timeRanges: {
          rangeIndex: number;
          start: number;
          end: number;
          overlapping: boolean;
        }[];
        currentRange: number;
      } = EditorService.generateRanges(roomData, editorMode);
      setRanges(generatedRanges.ranges);
      calculateRangeSizes(generatedRanges.ranges);
      setTimeRanges(generatedRanges.timeRanges);
    }
  }, [roomData]);

  useEffect(() => {
    const keyStrokesHandler = async (e: KeyboardEvent) => {
      if (!e.ctrlKey) return;
      switch (e.code) {
        case "KeyM":
          e.preventDefault();
          SoundManager.togglePlay((playerRewindResume || 0) * -1);
          break;
        case "KeyS":
          e.preventDefault();
          if (isReadOnly) return;
          updateRoomData();
          break;
        case "Period":
          e.preventDefault();
          SoundManager.playRelative(playerForward || 5);
          break;
        case "Comma":
          e.preventDefault();
          SoundManager.playRelative((playerRewind || 5) * -1);
          break;
        case "KeyB":
          e.preventDefault();
          if (isReadOnly) return;
          selectCurrentWord();
          return false;
          break;
      }
    };

    document.addEventListener("keydown", keyStrokesHandler, false);
    return () => document.removeEventListener("keydown", keyStrokesHandler);
  }, [roomData, playerRewind, playerForward, playerRewindResume]);

  const loadRoomData = async (id: string) => {
    try {
      dispatch(setLoadingReason(t("loading")));
      const options = id === "demo" ? { filename: "demo.json" } : {};
      const fetchedRoom = await FirebaseService.getRoomData(id, options);

      if (
        FeatureFlagsService.isEnabled("newSubtitlesEditor", loggedInUser) ||
        fetchedRoom.previewFormat === "protocol" ||
        _.get(fetchedRoom, "translation.type") === "target"
      ) {
        window.location.href = `/job/${fetchedRoom.roomId}`;
      }
      getJobRevisions(fetchedRoom);
      if (fetchedRoom) {
        const languageDirection = EditorService.getLangDirection(
          fetchedRoom.lang.input
        );
        setEditorDirection(languageDirection);
        fetchedRoom.deadline &&
          setDeadlineStr(getDeadlineStr(fetchedRoom.deadline));
        setIsReadOnly(id !== "demo" && fetchedRoom.process?.status !== "ready");
        setEditorMode(jobTypes[fetchedRoom.previewFormat].editMode);
        dispatch(setRoomData(fetchedRoom));

        FirebaseService.getSpeakerSamples(
          fetchedRoom.clientId
        ).then((samples) => setSpeakerSamples(samples));
      }
      dispatch(clearLoadingReason());
    } catch (err) {
      console.log(err);
      dispatch(clearLoadingReason());
      setErrorReason(t("indicator_error_ocurred"));
    }
  };

  const updateRoomData = async () => {
    const addedWorkTime = idleTimer.getTotalActiveTime();
    const updatedTimeTrack = await updateActivity();
    try {
      dispatch(popIndicator({ type: "info", txt: t("saving") }));
      await EditorService.updateRoomData(
        addedWorkTime,
        { timeTrack: updatedTimeTrack },
        isChanged
      );
      idleTimer.reset();
      setIsChanged(false);
      dispatch(popIndicator({ type: "success", txt: t("save_success") }));
    } catch (err) {
      dispatch(popIndicator({ type: "failure", txt: t("save_fail") }));
    }
  };

  const getJobRevisions = (room: RoomData) => {
    FirebaseService.getJobRevisions(room).then((res) => {
      const formattedJobRevisions = _.map(res, (rev) => ({
        ...rev,
        label: `${t(rev.type)} - ${format(
          rev.date,
          "dd/MM/yy HH:mm"
        )} | ${_.get(rev, "metadata.username")}`,
        value: rev.filename,
      }));
      setJobRevisions(formattedJobRevisions);
    });
  };

  const handleUserIdle = () => {
    //
  };

  const handleUserActive = () => {
    const lastIdleTime = idleTimer.getLastActiveTime()?.getTime();
    if (lastIdleTime) {
      const idleDuration = Date.now() - lastIdleTime;
    }
  };

  useEffect(() => {
    if (userActive) {
      handleUserActive();
    } else {
      handleUserIdle();
    }
  }, [userActive]);

  const handlePriceUpdate = async (newValue: string | number | Date) => {
    const newPrice = Number(newValue);
    if (_.isNaN(newPrice)) return false;
    const prooferPrice = roomData.status === 4;
    if (!prooferPrice) {
      dispatch(setRoomData({ ...roomData, price: newValue as number }));
    } else {
      dispatch(setRoomData({ ...roomData, prooferPrice: newValue as number }));
    }
    await FirebaseService.changePrice(roomData.roomId, newValue, prooferPrice);
    return true;
  };

  const changeRangeIndex = ({
    wordIndex,
    rangeIndex,
    words,
    newRange,
  }: {
    wordIndex: number;
    rangeIndex: number;
    words?: Word[];
    newRange: boolean;
  }) => {
    const updatedMeetingWords: Word[] = !words
      ? _.clone(roomData.words)
      : _.clone(words);

    if (timestampsEnterMethod === "player") {
      if (!updatedMeetingWords[wordIndex - 1])
        console.log("error getting lastword of index");
      updatedMeetingWords[wordIndex - 1].end = Number(currentTime.toFixed(1));
      updatedMeetingWords[wordIndex].start = Number(currentTime.toFixed(1));
    }
    while (updatedMeetingWords[wordIndex].range_ix === rangeIndex) {
      updatedMeetingWords[wordIndex].range_ix =
        updatedMeetingWords[wordIndex].range_ix + 1;
      wordIndex++;
      if (wordIndex === updatedMeetingWords.length) break;
    }

    if (newRange) {
      for (let i = wordIndex; i < updatedMeetingWords.length; i++) {
        updatedMeetingWords[i].range_ix = updatedMeetingWords[i].range_ix + 1;
      }
    }

    setIsChanged(true);
    dispatch(setRoomData({ ...roomData, words: updatedMeetingWords }));
  };

  const breakRange = (
    rangeIndex: number,
    rangeWordIndex: number,
    wordCharIndex: number,
    newRange: boolean,
    words: Word[] = roomData.words
  ) => {
    const wordIndex = rangeWordIndex + ranges[rangeIndex];
    if (wordCharIndex === 0) {
      // Character is in start of word
      changeRangeIndex({ wordIndex, rangeIndex, newRange, words });
      return;
    }
    if (words[wordIndex].text.length === wordCharIndex) {
      // Character is in end of word, need to start from next word
      changeRangeIndex({
        wordIndex: wordIndex + 1,
        rangeIndex,
        newRange,
        words,
      });
      return;
    }

    // Character is in the middle, splitting word
    const updatedMeetingWords = EditorService.getSplitMeetingWords(
      words,
      wordIndex,
      wordCharIndex
    );

    changeRangeIndex({
      wordIndex: wordIndex + 1,
      rangeIndex,
      words: updatedMeetingWords,
      newRange,
    });
  };

  const mergeRanges = (rangeIndex: number, mergeWithNextRange = false) => {
    const updatedMeetingWords = _.clone(roomData.words);
    const rangeFirstWordIndex = mergeWithNextRange
      ? ranges[rangeIndex + 1] // Merging current range (rangeIndex) with the next one (rangeIndex+1), using enter key
      : ranges[rangeIndex]; // Merging current range (rangeIndex) with previous range (rangeIndex-1), using backspace

    for (
      let i = rangeFirstWordIndex;
      i <= updatedMeetingWords.length - 1;
      i++
    ) {
      updatedMeetingWords[i].range_ix = updatedMeetingWords[i].range_ix - 1;
    }

    setIsChanged(true);
    dispatch(setRoomData({ ...roomData, words: updatedMeetingWords }));
  };

  const deleteRange = (rangeIndex: number) => {
    let updatedMeetingWords = _.clone(roomData.words);
    updatedMeetingWords = updatedMeetingWords.filter(
      (w) => w.range_ix !== rangeIndex
    );
    updatedMeetingWords = EditorService.reorderWordsRangeIndex(
      updatedMeetingWords
    );

    setIsChanged(true);
    dispatch(setRoomData({ ...roomData, words: updatedMeetingWords }));
  };

  const changeRangeSpeaker = (
    rangeIndex: number,
    wordIndex: number,
    wordCharIndex: number,
    newSpeaker: string | null,
    words: Word[] = roomData.words
  ) => {
    const unidentifiedSpeakerName =
      newSpeaker === "$unidentified_speaker$"
        ? `${t("unidentified_speaker")} ${unidentifiedSpeakerCount}`
        : null;
    if (unidentifiedSpeakerName)
      setUnidentifiedSpeakerCount(unidentifiedSpeakerCount + 1);

    const speakers = _.clone(roomData.speakers);
    const tempSpeakerName = t("unidentified_speaker");
    const speaker = unidentifiedSpeakerName || newSpeaker || tempSpeakerName;
    const updatedMeetingWords = EditorService.getSplitMeetingWords(
      words,
      wordIndex,
      wordCharIndex
    );
    const startIndex = wordCharIndex > 0 ? wordIndex + 1 : wordIndex;
    const oldSpeaker = updatedMeetingWords[startIndex].speaker;

    if (
      !roomData.speakers.includes(speaker) &&
      (unidentifiedSpeakerName || speaker !== tempSpeakerName)
    )
      speakers.push(speaker);

    if (!newSpeaker) {
      // Handle as "Enter" on protocol, should set temporary range
      setTempSpeakerRange(rangeIndex + 1);
    }
    if (newSpeaker && tempSpeakerRange === rangeIndex) {
      setTempSpeakerRange(-1);
    }

    for (
      let i = startIndex;
      updatedMeetingWords[i] && updatedMeetingWords[i].speaker === oldSpeaker;
      i++
    ) {
      updatedMeetingWords[i].speaker = speaker;
    }

    setIsChanged(true);
    dispatch(
      setRoomData({
        ...roomData,
        speakers: speakers,
        words: updatedMeetingWords,
      })
    );

    scrollInto(`range-${rangeIndex + 1}`);
  };

  const renameSpeaker = (
    oldSpeaker: string,
    newSpeaker: string,
    allSpeakers: string[]
  ) => {
    if (allSpeakers.includes(newSpeaker)) return false;
    const updatedMeetingWords = roomData.words.map((word) => ({
      ...word,
      speaker: word.speaker === oldSpeaker ? newSpeaker : word.speaker,
    }));
    const newSpeakers = roomData.speakers.map((s) =>
      s === oldSpeaker ? newSpeaker : s
    );

    dispatch(
      setRoomData({
        ...roomData,
        speakers: newSpeakers,
        words: updatedMeetingWords,
      })
    );
    setIsChanged(false);
    return true;
  };

  const mergeRangeSpeakers = (
    rangeIndex: number,
    words: Word[] = roomData.words,
    mergeWithNextRange = false
  ) => {
    const updatedMeetingWords = _.clone(words);

    const rangeToChangeSpeaker = mergeWithNextRange
      ? ranges[rangeIndex + 1]
      : ranges[rangeIndex];

    const rangeToMergeWith = mergeWithNextRange
      ? ranges[rangeIndex]
      : ranges[rangeIndex - 1];

    const oldSpeaker = updatedMeetingWords[rangeToChangeSpeaker].speaker;
    const speakerToMergeWith = words[rangeToMergeWith].speaker;

    for (
      let i = rangeToChangeSpeaker;
      updatedMeetingWords[i] && updatedMeetingWords[i].speaker === oldSpeaker;
      i++
    ) {
      updatedMeetingWords[i].speaker = speakerToMergeWith;
    }

    scrollInto(`range-${mergeWithNextRange ? rangeIndex + 1 : rangeIndex - 1}`);

    setIsChanged(true);
    dispatch(setRoomData({ ...roomData, words: updatedMeetingWords }));
  };

  const handleApprove = (selectedOption: string, checkboxValue?: boolean) => {
    callToExport(selectedOption, checkboxValue);
    setOpenExportModal(false);
  };

  const updateWordTime = (
    wordIndex: number,
    timeString: string,
    position: string,
    method?: "button" | "text"
  ) => {
    const updatedMeetingWords: Word[] = roomData.words;

    const timeInSecs = TimeService.getTimeNumberFromString(timeString);
    const oldTime = _.get(updatedMeetingWords, `${wordIndex}.${position}`);
    const timeDiff = !_.isNaN(timeInSecs - oldTime)
      ? Math.round((timeInSecs - oldTime) * 1000)
      : null;

    updatedMeetingWords[wordIndex] = {
      ...updatedMeetingWords[wordIndex],
      [position]: timeInSecs,
    };

    setIsChanged(true);
    dispatch(setRoomData({ ...roomData, words: updatedMeetingWords }));
  };

  const selectCurrentWord = () => {
    const currentWord: {
      word: Word;
      start_index: number;
      end_index: number;
    } | null = getCurrentWord();
    if (!currentWord) return;

    const range = document.getElementById(
      `range-${currentWord.word.range_ix}`
    ) as HTMLTextAreaElement;
    range?.focus();
    range?.setSelectionRange(currentWord.start_index, currentWord.end_index);
  };

  const getCurrentWord = () => {
    if (!roomData || !ranges) return null;
    let _currentTime: number;
    setCurrentTime((ct) => {
      _currentTime = ct;
      return ct;
    });

    const currentWord = _.find(
      roomData.words,
      (w, i) =>
        (w.start < _currentTime && w.end > _currentTime) ||
        (w.end < _currentTime && roomData.words[i + 1].start > _currentTime)
    );

    if (!currentWord) return null;

    let wordStartIndex = -1;
    let wordEndIndex = 0;
    for (const rangeWord of roomData.words.filter(
      (w) => w.range_ix === currentWord.range_ix
    )) {
      wordStartIndex++;
      if (currentWord === rangeWord) {
        wordEndIndex = wordStartIndex + rangeWord.text.length;
        break;
      }
      wordStartIndex = wordStartIndex + rangeWord.text.length;
    }

    return {
      word: currentWord,
      start_index: wordStartIndex,
      end_index: wordEndIndex,
    };
  };

  const getCurrentCaptionWords = () => {
    const currentRangeIndex = getCurrentRangeIndex() || [];
    if (currentRangeIndex.includes(focusedRangeIndex))
      return focusedRangeWordsString;
    return getCurrentPlayingRangeWords();
  };

  const getCurrentRangeIndex = () => {
    try {
      if (!timeRanges) return;
      // @ts-ignore
      if (currentTime > _.last(timeRanges)) {
        return [ranges.length - 1];
      }
      const matchingRanges = timeRanges
        .filter(
          (range: { start: number; end: number }) =>
            range.start <= currentTime && range.end >= currentTime
        )
        .map((range) => range.rangeIndex);
      return matchingRanges;
    } catch (err) {
      console.log("error getCurrentRangeIndex", err);
    }
  };

  const getCurrentPlayingRangeWords = () => {
    //@ts-ignore
    const currentRangeIndex: number[] = getCurrentRangeIndex();
    if (_.isEmpty(currentRangeIndex) || _.isNil(currentRangeIndex)) {
      return [];
    }
    return _.map(currentRangeIndex, (rangeIndex: number) => {
      const firstWordRangeIndex = ranges[rangeIndex];
      const lastWordRangeIndex = ranges[rangeIndex + 1] - 1;
      const rangeWords = roomData.words.slice(
        firstWordRangeIndex,
        firstWordRangeIndex == _.last(ranges)
          ? roomData.words.length + 1
          : lastWordRangeIndex + 1
      );
      return rangeWords;
    });
  };

  const getRangeSize = (index: number) => {
    const afterRangeIndex =
      index === ranges.length - 1 ? roomData?.words?.length : ranges[index + 1];

    if (!afterRangeIndex) return -1;

    return afterRangeIndex - ranges[index];
  };

  const calculateRangeSizes = (newRanges: number[]) => {
    const start = Date.now();
    const sizes = [];

    for (let i = 0; i < newRanges.length; i++) {
      const nextRangeIndex =
        i === newRanges.length - 1 ? roomData?.words?.length : newRanges[i + 1];
      sizes.push(nextRangeIndex - newRanges[i]);
    }

    setRangesSizes(sizes);
  };

  const getDeadlineStr = (deadline: Date) => {
    if (isPast(deadline)) return "00:00";
    const hours = differenceInHours(deadline, Date.now());
    const minutes = differenceInMinutes(deadline, Date.now()) % 60;
    let hoursZero = "";
    let minutesZero = "";
    if (hours < 10 && hours >= 0) hoursZero = "0";
    if (minutes < 10 && minutes >= 0) minutesZero = "0";
    return hoursZero + hours + ":" + minutesZero + minutes;
  };

  const changePreviewFormat = () => {
    if (roomData) {
      const words = EditorService.reorderWordsRangeIndex(roomData.words);
      const roomDataClone = { ...roomData, words };
      editorMode === "protocol"
        ? setEditorMode("subtitles")
        : setEditorMode("protocol");

      dispatch(setRoomData(roomDataClone));
    }
  };

  const updateRoomWords = (updatedRoomWords: Word[]) => {
    setIsChanged(_.difference(roomData.words, updatedRoomWords).length > 0);
    dispatch(setRoomData({ ...roomData, words: updatedRoomWords }));
  };

  const callToExport = async (toType: string, checkboxValue?: boolean) => {
    try {
      dispatch(popIndicator({ type: "info", txt: t("exporting") }));
      if (roomData) {
        const result = await EditorService.exportRoomToFileByType(
          [{ id: id, name: roomData.name }],
          toType,
          checkboxValue
        );
        if (!result?.success) {
          dispatch(popIndicator({ type: "error", txt: t("exporting_fail") }));
        }
        dispatch(popIndicator({ type: "info", txt: t("exporting_success") }));
      }
    } catch (error) {
      dispatch(popIndicator({ type: "error", txt: t("exporting_fail") }));
    }
  };

  const alignSubtitles = async () => {
    setShowAlignModal(false);
    dispatch(setLoadingReason(t("loading_aligning_subtitles")));
    if (loggedInUser) {
      updateRoomData();
      const response = await EditorService.alignSubtitles(id);
      if (!response.success) {
        dispatch(
          popIndicator({ type: "failure", txt: t("subtitles_align_failed") })
        );
      } else {
        history.push(`/`);
        dispatch(
          popIndicator({ type: "sucsess", txt: t("subtitles_align_success") })
        );
      }
      idleTimer.reset();
      dispatch(clearLoadingReason());
    }
  };

  const splitJob = async () => {
    setShowSplitModal(false);
    dispatch(setLoadingReason(t("loading_splitting_job")));
    if (loggedInUser) {
      updateRoomData();
      const response = await EditorService.splitJob(
        id,
        splitCount === "" ? null : splitCount,
        loggedInUser.id
      );
      if (!response.success) {
        dispatch(popIndicator({ type: "failure", txt: t("job_split_failed") }));
      } else {
        history.push(`/`);
        dispatch(
          popIndicator({ type: "sucsess", txt: t("job_split_success") })
        );
      }
      idleTimer.reset();
      dispatch(clearLoadingReason());
    }
  };

  const restoreJob = async () => {
    setShowRestoreJobModal(false);
    dispatch(setLoadingReason(t("job_restore_loading")));
    if (selectedJobRevision) {
      const response = await FirebaseService.restoreJob(
        id,
        selectedJobRevision
      );
      if (!response.success) {
        dispatch(
          popIndicator({ type: "failure", txt: t("job_restore_failed") })
        );
      } else {
        await loadRoomData(id);
        dispatch(
          popIndicator({ type: "success", txt: t("job_restore_success") })
        );
      }
      dispatch(clearLoadingReason());
    } else {
      dispatch(popIndicator({ type: "failure", txt: t("job_restore_failed") }));
      dispatch(clearLoadingReason());
    }
  };

  const resetSubtitlesRanges = () => {
    setWordsSnapshots([roomData.words]);
    const resetWords = EditorService.resetSubtitlesRanges(roomData.words);
    updateRoomWords(resetWords);
  };

  const restoreWords = () => {
    if (!wordsSnapshots) return;
    setWordsSnapshots([roomData.words]);
    updateRoomWords(wordsSnapshots[0]);
  };

  const toggleTimestampsEnterMethod = () => {
    if (timestampsEnterMethod === "word") {
      setTimestampsEnterMethod("player");
    } else {
      setTimestampsEnterMethod("word");
    }
  };

  const toggleWaveform = () => {
    setIsWaveform(!isWaveform);
  };

  const isEditAllowed = (roomData: RoomData) => {
    const { assignedProofer, assignedTranscriber, status } = roomData;
    if (loggedInUser?.role === "super_user") {
      if (status === 3 && !assignedTranscriber) return true;
      if (status === 4 && !assignedProofer) return true;
    }
    return false;
  };

  const focusAndSetCursor = (
    rangeIndexToFocus: number,
    cursorPosition: number
  ) => {
    const rangeToFocus = document.getElementById(
      `range-${rangeIndexToFocus}`
    ) as HTMLInputElement;
    if (rangeToFocus) {
      const cursorPositionToSet =
        cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length + 1; // +1 to set cursor after the space
      rangeToFocus.focus();
      setTimeout(
        () =>
          rangeToFocus.setSelectionRange(
            cursorPositionToSet,
            cursorPositionToSet
          ),
        0
      );
    } else {
      // Retry - for creating new range at the end
      setTimeout(
        () => focusAndSetCursor(rangeIndexToFocus, cursorPosition),
        10
      );
    }
  };

  const getTranscriberAndProoferName = async (fetchedRoom: RoomData) => {
    const transcriberId = fetchedRoom.assignedTranscriber?.id;
    const getTransName =
      transcriberId && transcribers
        ? MeetingService.getTranscriberFromTranscribers(
            transcriberId,
            transcribers,
            "username"
          )
        : "";

    const prooferId = fetchedRoom.assignedProofer?.id;
    const getProoferName = prooferId
      ? MeetingService.getProoferFromProofers(prooferId, proofers, "username")
      : "";
    setProoferName(getProoferName);
    setTranscriberName(getTransName);
  };
  return (
    <div className="EditorPage" ref={editorPageRef}>
      {loggedInUser && (
        <main
          id="editorContainer"
          className={classNames("main-container", { waveform: isWaveform })}
        >
          {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
          {!!errorReason && <ErrorModal errorReason={errorReason} />}
          {!loadingReason && !errorReason && roomData && (
            <div className={classNames("editor-page", editorDirection)}>
              <div className="headerSection">
                {isReadOnly && <div className="readOnly">{t("read_only")}</div>}
                <div className="editorHeader">
                  <div className="roomName">{roomData?.name}</div>
                  {loggedInUser.role === "super_user" && (
                    <div className="transcriberAndProoferName">
                      <div className="editProgress">
                        <CircleProgress
                          size={"lg"}
                          progress={roomData.editProgress}
                        />
                      </div>
                      {transcriberName && (
                        <span>
                          {`${t("transcriber")}: `}
                          <Link
                            to={{
                              pathname: `/transcriber/${roomData.assignedTranscriber?.id}`,
                              state: { name: "back_to_meeting" },
                            }}
                          >
                            {transcriberName}
                          </Link>
                        </span>
                      )}
                      {transcriberName && prooferName && " | "}
                      {prooferName && (
                        <span>
                          {`${t("proofer")}: `}
                          <Link
                            to={{
                              pathname: `/transcriber/${roomData.assignedProofer?.id}`,
                              state: { name: "back_to_meeting" },
                            }}
                          >
                            {prooferName}
                          </Link>
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="detailsContainer">
                  <div className="roomMetadata">
                    <div className="detail-wrapper">
                      <div className="icn-wrapper">
                        <FontAwesomeIcon icon={["fal", "typewriter"]} />
                      </div>
                      <span>{t(roomData.previewFormat)}</span>
                    </div>
                    <div className="detail-wrapper">
                      <div className="icn-wrapper">
                        <FontAwesomeIcon icon={["fal", "calendar-alt"]} />
                      </div>
                      <span>
                        {roomData.creationTime &&
                          format(roomData.creationTime, "dd/MM/yyyy")}
                      </span>
                    </div>
                    <div className="detail-wrapper">
                      <div className="icn-wrapper">
                        <FontAwesomeIcon icon={["fal", "hourglass"]} />
                      </div>
                      <span>{deadlineStr}</span>
                    </div>
                    <div className="detail-wrapper">
                      <div className="icn-wrapper">
                        <FontAwesomeIcon icon={["fal", "clock"]} />
                      </div>
                      <span>
                        {TimeService.getTimeStringFromSecs(
                          roomData.meetingLength
                        )}
                      </span>
                    </div>
                    <div className="detail-wrapper">
                      <div className="icn-wrapper">
                        <FontAwesomeIcon icon={["fal", "shekel-sign"]} />
                      </div>
                      <EditableText
                        type="number"
                        spinnerColor="white"
                        value={
                          roomData.status === 3
                            ? roomData.price
                            : roomData.prooferPrice
                        }
                        handleSetNewValue={handlePriceUpdate}
                        isEditable={isEditAllowed(roomData)}
                      />
                    </div>
                  </div>

                  {!isReadOnly && (
                    <div className="roomActions">
                      <button
                        className="actionButton notesButton"
                        onClick={() => setIsNoteModalOpen(true)}
                      >
                        <FontAwesomeIcon size="lg" icon={"sticky-note"} />
                        <span>{t("notes")}</span>
                      </button>
                      {loggedInUser.role === "super_user" && (
                        <>
                          {roomData.previewFormat === "protocol" && (
                            <button
                              className="actionButton timeTrack"
                              onClick={() => setShowTimeTrackModal(true)}
                            >
                              <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "clock"]}
                              />
                              <span>{t("time_track")}</span>
                            </button>
                          )}
                        </>
                      )}
                      {editorMode === "protocol" &&
                        FeatureFlagsService.isEnabled(
                          "restoreJob",
                          loggedInUser
                        ) && (
                          <button
                            className="actionButton restoreJob formatButton"
                            onClick={() => setShowRestoreJobModal(true)}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon={["fal", "history"]}
                            />
                            <span>{t("job_restore")}</span>
                          </button>
                        )}
                      <button
                        className="formatButton actionButton"
                        onClick={() => {
                          setEditorDirection(
                            editorDirection === "ltr" ? "rtl" : "ltr"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={["fal", "paragraph"]} />
                        <span>{editorDirection.toUpperCase()}</span>
                      </button>
                      {/* <button
                        className="formatButton actionButton"
                        onClick={changePreviewFormat}
                      >
                        <FontAwesomeIcon icon={["fal", "file-alt"]} />
                        <span>
                          {editorMode === "protocol"
                            ? t("subtitles")
                            : t("protocol")}
                        </span>
                      </button> */}
                      <button
                        className="langButton actionButton"
                        onClick={() => console.log("lang")}
                      >
                        <FontAwesomeIcon icon={["fal", "globe"]} />
                        <span>{t(roomData.lang.output)}</span>
                      </button>

                      {loggedInUser.role !== "transcriber" && (
                        <button
                          ref={exportBtn}
                          className="exportButton actionButton"
                          onClick={async () => {
                            await updateRoomData();
                            setOpenExportModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={["fal", "file-export"]} />
                          <span>{t("export")}</span>
                        </button>
                      )}

                      <button
                        className="saveButton actionButton"
                        onClick={updateRoomData}
                      >
                        <FontAwesomeIcon icon={["fal", "save"]} />
                        <span>{t("save")}</span>
                      </button>
                    </div>
                  )}
                </div>
                {editorMode === "protocol" && roomData.speakers && (
                  <>
                    <div className="speakersContainer">
                      {roomData.speakers.map((speaker, i) => (
                        <span
                          className="speaker"
                          style={{
                            color: stc(speaker),
                          }}
                          key={i}
                        >
                          <SpeakerNameEditor
                            speakerName={speaker}
                            speakers={roomData.speakers}
                            autocomplete={false}
                            handleSetNewSpeakerName={(newSpeaker) =>
                              renameSpeaker(
                                speaker,
                                newSpeaker,
                                roomData.speakers
                              )
                            }
                            placeholder={speaker}
                            isEditing={false}
                          />
                        </span>
                      ))}
                    </div>
                  </>
                )}
                {editorMode === "subtitles" && !isReadOnly && (
                  <div className="subtitlesActionsContainer">
                    {/* REMOVE FEATURE FLAG */}
                    {!FeatureFlagsService.isEnabled(
                      "mediaPlayerV3",
                      loggedInUser
                    ) && (
                      <div
                        className="action waveformToggle"
                        onClick={toggleWaveform}
                      >
                        <div className="label">{t("waveform")}</div>
                        <div className="icon">
                          <FontAwesomeIcon
                            size="lg"
                            icon={["far", "waveform"]}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="action timestampsEnterMethod"
                      onClick={toggleTimestampsEnterMethod}
                    >
                      <div className="label">
                        {t("timestamps_enter_method")}:{" "}
                        {t(timestampsEnterMethod)}
                      </div>
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={["far", "level-up"]} />
                      </div>
                    </div>
                    <div
                      className="action align"
                      onClick={() => setShowAlignModal(true)}
                    >
                      <div className="label">{t("align_subtitles")}</div>
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={["far", "stream"]} />
                      </div>
                    </div>
                    {FeatureFlagsService.isEnabled(
                      "restoreJob",
                      loggedInUser
                    ) && (
                      <div
                        className="action restoreJob"
                        onClick={() => setShowRestoreJobModal(true)}
                      >
                        <div className="label">{t("job_restore")}</div>
                        <div className="icon">
                          <FontAwesomeIcon
                            size="lg"
                            icon={["fal", "history"]}
                          />
                        </div>
                      </div>
                    )}

                    {loggedInUser.role === "super_user" && (
                      <>
                        {!roomData.split && (
                          <div
                            className="action split"
                            onClick={() => setShowSplitModal(true)}
                          >
                            <div className="label">{t("split_job")}</div>
                            <div className="icon">
                              <FontAwesomeIcon
                                size="lg"
                                icon={["far", "page-break"]}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className="action timeTrack"
                          onClick={() => setShowTimeTrackModal(true)}
                        >
                          <div className="label">{t("time_track")}</div>
                          <div className="icon">
                            <FontAwesomeIcon
                              size="lg"
                              icon={["fal", "clock"]}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <div
                      className="action reset"
                      onClick={resetSubtitlesRanges}
                    >
                      <div className="label">{t("reset_subtitles_ranges")}</div>
                      <div className="icon">
                        <FontAwesomeIcon
                          size="lg"
                          icon={["far", "align-slash"]}
                        />
                      </div>
                    </div> */}
                    {/* <div className="action sync" onClick={syncSubtitles}>
                      <div className="label">{t("sync_subtitles")}</div>
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={["far", "stream"]} />
                      </div>
                    </div> */}
                    {/* <div
                      className={classNames("action", "restore", {
                        disabled: !wordsSnapshots || _.isEmpty(wordsSnapshots),
                      })}
                      onClick={restoreWords}
                    >
                      <div className="label">
                        {t("restore_subtitles_ranges")}
                      </div>
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={["far", "history"]} />
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
              <div
                className={classNames("editorSection", {
                  splitView: isSplitView,
                  waveformEnabled: isWaveform,
                  multiRanges: ranges.length > 1,
                })}
              >
                <div className="editorRanges">
                  {ranges.map((rangeStart, rangeIndex) => (
                    <LazyLoad
                      height={100}
                      once
                      offset={
                        // Determain divs height by its number of words
                        (rangesSizes[rangeIndex] * 1.3 || 400) + 800
                      }
                      scrollContainer="#editorContainer"
                      unmountIfInvisible={true}
                      key={`${rangeIndex}-${
                        roomData.words[rangeStart]
                          ? roomData.words[rangeStart].speaker
                          : "old"
                      }`}
                    >
                      <TextRange
                        meeting={roomData}
                        firstWordIndex={rangeStart}
                        format={editorMode}
                        rangeSize={rangesSizes[rangeIndex]}
                        rangesCount={ranges.length}
                        changeRangeSpeaker={changeRangeSpeaker}
                        mergeRangeSpeakers={mergeRangeSpeakers}
                        breakRange={(
                          rangeWordIndex,
                          wordCharIndex,
                          newRange,
                          words
                        ) =>
                          breakRange(
                            rangeIndex,
                            rangeWordIndex,
                            wordCharIndex,
                            newRange,
                            words
                          )
                        }
                        mergeRanges={(mergeWithNextRange) =>
                          mergeRanges(rangeIndex, mergeWithNextRange)
                        }
                        deleteRange={() => deleteRange(rangeIndex)}
                        updateWordTime={updateWordTime}
                        isPassed={
                          editorMode === "subtitles" &&
                          timeRanges[rangeIndex] &&
                          Number(currentTime) >
                            Number(timeRanges[rangeIndex].end)
                        }
                        rangeIndex={rangeIndex}
                        setFocusedRangeIndex={(rangeIndex) =>
                          rangeIndex !== focusedRangeIndex &&
                          setFocusedRangeIndex(rangeIndex)
                        }
                        setFocusedRangeWordsString={setFocusedRangeWordsString}
                        overlapping={
                          true &&
                          timeRanges[rangeIndex] &&
                          timeRanges[rangeIndex].overlapping
                        }
                        updateRoomWords={updateRoomWords}
                        isTemporarySpeaker={tempSpeakerRange === rangeIndex}
                        direction={editorDirection}
                        disabled={isReadOnly}
                      />
                    </LazyLoad>
                  ))}
                </div>
                {/* REMOVE FEATURE FLAG */}
                {FeatureFlagsService.isEnabled(
                  "mediaPlayerV3",
                  loggedInUser
                ) && (
                  <div className="editorPlayer">
                    <SpeakerSamples speakers={speakerSamples} />
                  </div>
                )}
                {/* REMOVE FEATURE FLAG */}
                {!FeatureFlagsService.isEnabled(
                  "mediaPlayerV3",
                  loggedInUser
                ) && (
                  <div className="editorPlayer">
                    {!_.isEmpty(roomData.assets) &&
                    roomData.assets[0].type === "video" ? (
                      <VideoPlayer
                        videoSources={roomData.assets}
                        currentCaption={getCurrentCaptionWords()}
                        isFloating={!isSplitView}
                        direction={editorDirection}
                      />
                    ) : null}
                    <MyMediaPlayer
                      chosenMedia={chosenMedia}
                      offsets={roomData.offsets}
                      setChosenMedia={setChosenMedia}
                      showMediaSourceManagement={false}
                      mediaSources={roomData.assets}
                      sendCurrTime={setCurrentTime}
                      isDraggable={!isSplitView}
                      isFloating={!isSplitView}
                      isSplitView={isSplitView}
                      toggleIsFloating={() => setIsSplitView(!isSplitView)}
                      allowSplit={true}
                    />
                    <SpeakerSamples speakers={speakerSamples} />

                    {roomData.assets.length ? (
                      <div className="audioList">
                        <div className="audioFilesTitle">
                          <h4>{t("audio_files")}</h4>
                        </div>
                        <div className="audios">
                          {roomData.assets.map((audio, i) => (
                            <div
                              className={classNames("audio", {
                                current: chosenMedia?.src === audio.src,
                              })}
                              key={i}
                              onClick={() => setChosenMedia(audio)}
                            >
                              {audio.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* REMOVE FEATURE FLAG */}
          {FeatureFlagsService.isEnabled("mediaPlayerV3", loggedInUser) &&
            !_.isEmpty(roomData.assets) && (
              <MediaPlayerV3
                initialPlaybackPosition={
                  EditorService.getLastPosition(roomData.roomId)
                    .initialPlaybackPosition
                }
                loggedInUser={loggedInUser}
                currentCaption={getCurrentCaptionWords()}
                direction={editorDirection}
                mediaSources={roomData.assets}
                hasVideo={chosenMedia?.type === "video"}
                chosenMedia={chosenMedia}
                setChosenMedia={setChosenMedia}
                sendCurrTime={setCurrentTime}
                offsets={roomData.offsets}
                jobId={roomData.roomId}
              >
                <WaveformRanges
                  initialZoomValue={
                    roomData?.previewFormat === "subtitles" ? 50 : 0
                  }
                  showZoomOption={roomData?.previewFormat === "subtitles"}
                  ranges={ranges}
                  timeRanges={timeRanges}
                  words={roomData.words}
                  updateRoomWords={updateRoomWords}
                  roomId={roomData.roomId}
                  focusRange={focusAndSetCursor}
                />
              </MediaPlayerV3>
            )}
        </main>
      )}
      {/* REMOVE FEATURE FLAG */}
      {!FeatureFlagsService.isEnabled("mediaPlayerV3", loggedInUser) &&
        editorMode === "subtitles" &&
        isWaveform && (
          <WaveformRanges
            initialZoomValue={roomData?.previewFormat === "subtitles" ? 50 : 0}
            showZoomOption={roomData?.previewFormat === "subtitles"}
            ranges={ranges}
            timeRanges={timeRanges}
            words={roomData.words}
            updateRoomWords={updateRoomWords}
            roomId={roomData.roomId}
            focusRange={focusAndSetCursor}
          />
        )}
      <MessageModal
        className="alignSubtitlesWarning warning"
        title={t("align_subtitles")}
        body={t("modal_align_warning", {
          eta: Math.round((roomData.meetingLength + 360) / 60 / 3),
        })}
        showModal={showAlignModal}
        approve={{ text: t("approve"), action: alignSubtitles }}
        cancel={{ text: t("cancel"), action: () => setShowAlignModal(false) }}
      />
      <MessageModal
        className="jobSplitModal warning"
        title={t("split_job")}
        body={
          <div className="bodyContainer">
            <div className="note">
              {t("modal_split_warning", {
                splitCount: `1-${(roomData.meetingLength / 60 - 1).toFixed(0)}`,
              })}
            </div>
            <div className="countSelect">
              <Input
                value={splitCount}
                placeholder={Math.ceil(roomData.meetingLength / 60 / 30)}
                type="number"
                min={1}
                max={Number((roomData.meetingLength / 60 - 1).toFixed(0))}
                onChange={(e) =>
                  setSplitCount(
                    e.currentTarget.value ? Number(e.currentTarget.value) : ""
                  )
                }
              />
              <div className="splitLength">
                {splitCount &&
                  t("modal_split_count_calc", {
                    splitLength: Math.ceil(
                      roomData.meetingLength / 60 / splitCount
                    ),
                  })}
              </div>
            </div>
          </div>
        }
        showModal={showSplitModal}
        approve={{ text: t("approve"), action: splitJob }}
        cancel={{ text: t("cancel"), action: () => setShowSplitModal(false) }}
      />
      <TimeTrackModal
        showModal={showTimeTrackModal}
        closeModal={() => setShowTimeTrackModal(false)}
        workTime={roomData.workTime}
        previewFormat={roomData.previewFormat}
      />
      {isNoteModalOpen && (
        <NoteModal
          jobId={roomData.roomId}
          notes={roomData.notes || ""}
          showModal={isNoteModalOpen}
          closeModal={() => setIsNoteModalOpen(false)}
          onSubmit={async (note) => {
            await FirebaseService.updateNotes(note || "", roomData.roomId);
            roomData.notes = note;
            setIsNoteModalOpen(false);
          }}
        ></NoteModal>
      )}
      <ExportModal
        isOpen={openExportModal}
        setOpen={setOpenExportModal}
        meetings={[{ id: id, name: roomData.name }]}
        saveMeeting={true}
        roomData={roomData}
      ></ExportModal>
      <MessageModal
        className="restoreWordsModal"
        title={t("job_restore")}
        body={
          <div className="body">
            <div className="label">{t("select_revision")}</div>
            <div className="revisionSelect">
              <SelectList
                options={jobRevisions}
                onChange={(file) =>
                  setSelectedJobRevision(_.isArray(file) ? file[0] : file)
                }
                className="styledInput"
              />
            </div>
          </div>
        }
        showModal={showRestoreJobModal}
        approve={{ text: t("approve"), action: () => restoreJob() }}
        cancel={{
          text: t("cancel"),
          action: () => {
            setSelectedJobRevision(null);
            setShowRestoreJobModal(false);
          },
        }}
        isApproveDisabled={!selectedJobRevision}
      />
    </div>
  );
};

export default MeetingsPage;
