import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { TimeTrack } from "../../../models";

import MessageModal from "../../MessageModal/MessageModal";

import { UserEventSumup, WorkTime } from "../../../services/TrackingService";
import "./TimeTrack.scss";

interface Props {
  showModal: boolean;
  workTime: WorkTime;
  closeModal: () => void;
  previewFormat?: string;
}

const TimeTrackModal: FC<Props> = ({
  showModal,
  workTime,
  previewFormat,
  closeModal,
}): JSX.Element => {
  const { t } = useTranslation();

  const [workTimeState, setWorkTimeState] = useState<WorkTime>({
    transcribe: {},
    review: {},
  });

  const sumup = (
    status: "review" | "transcribe",
    stage: "timeSync" | "textEdit"
  ) => {
    let res = 0;
    _.forIn(workTimeState[status], (value: UserEventSumup) => {
      res = res + value[stage];
    });

    return Math.floor(res / 60);
  };

  const getTotalTime = (status: "review" | "transcribe"): number => {
    let res = 0;
    _.forIn(workTimeState[status], (value: UserEventSumup) => {
      res = res + value.timeSync + value.textEdit;
    });

    return Math.floor(res / 60);
  };

  useEffect(() => {
    if (workTime) {
      setWorkTimeState(workTime);
    }
  }, [workTime]);

  return (
    <MessageModal
      showModal={showModal}
      className="TimeTrackModal"
      title={`${t("time_track")} (${t("minutes")})`}
      body={
        <div className="bodyContainer">
          <div className="transcriberTimeTrack timeTrackBox">
            <div className="title">{t("transcriber")}</div>
            <div className="textEdit timeTrackEvent">
              <div className="eventName">{t("time_track_text_edit")}</div>
              <div className="eventValue">
                {sumup("transcribe", "textEdit")}
              </div>
            </div>
            <>
              <div className="subtitleSync timeTrackEvent">
                <div className="eventName">
                  {t("time_track_subtitles_sync")}
                </div>
                <div className="eventValue">
                  {sumup("transcribe", "timeSync")}
                </div>
              </div>
            </>
            <div className="totalTime timeTrackEvent">
              <div className="eventName">{t("time_track_total_time")}</div>
              <div className="eventValue">{getTotalTime("transcribe")}</div>
            </div>
          </div>
          <div className="prooferTimeTrack timeTrackBox">
            <div className="title">{t("proofer")}</div>
            <div className="textEdit timeTrackEvent">
              <div className="eventName">{t("time_track_text_edit")}</div>
              <div className="eventValue">{sumup("review", "textEdit")}</div>
            </div>
            <>
              <div className="subtitleSync timeTrackEvent">
                <div className="eventName">
                  {t("time_track_subtitles_sync")}
                </div>
                <div className="eventValue">{sumup("review", "timeSync")}</div>
              </div>
            </>
            <div className="totalTime timeTrackEvent">
              <div className="eventName">{t("time_track_total_time")}</div>
              <div className="eventValue">{getTotalTime("review")}</div>
            </div>
          </div>
        </div>
      }
      approve={{ text: t("close"), action: closeModal }}
    />
  );
};

export default TimeTrackModal;
