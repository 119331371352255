import React, { FC, useState, ChangeEvent, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { CircularProgress } from "@material-ui/core";

import "./SelectList.scss";

interface Option {
  label: string;
  value: any;
  icon?: any;
}

interface Props {
  searchOption?: boolean;
  options: Required<Option>[];
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  multiSelect?: boolean;
  onChange: (value: string[] | string) => void;
}

const SelectList: FC<Props> = ({
  options,
  className,
  onChange,
  searchOption = true,
  multiSelect,
  isLoading,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<Option>();
  const [multiSelected, setMultiSelected] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    onChange(option.value);
  };

  const handleMultiSelect = (option: Option) => {
    let newSelected = [];
    if (multiSelected.includes(option)) {
      newSelected = multiSelected.filter((o) => o.value !== option.value);
    } else {
      newSelected = [...multiSelected, option];
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return (
    <div className={classNames("SelectList", className)}>
      <div
        className={classNames("listContainer", {
          isDisabled,
          isLoading,
        })}
      >
        {searchOption && (
          <div className="search">
            <input
              type="text"
              placeholder={t("search")}
              onChange={handleSearch}
            />
          </div>
        )}
        <div className={classNames("optionsContainer")}>
          {isLoading ? (
            <div className="loading-spinner">
              <CircularProgress style={{ width: "20px" }} />
            </div>
          ) : (
            _.map(
              options.filter((o) =>
                o.label.toLowerCase().includes(searchValue)
              ),
              (o) =>
                multiSelect ? (
                  <div className="option checkbox" key={o.value}>
                    <input
                      type="checkbox"
                      id={o.value}
                      name={o.value}
                      value={o.value}
                      onChange={() => handleMultiSelect(o)}
                    />
                    <label htmlFor={o.value}>{o.label}</label>
                  </div>
                ) : (
                  <div
                    className={classNames("option", {
                      selected:
                        selectedOption && o.value === selectedOption.value,
                    })}
                    onClick={() => handleSelect(o)}
                    key={o.value}
                  >
                    {o.label}
                  </div>
                )
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectList;
