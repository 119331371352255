import React, { FC } from "react";
import i18n from "../../i18n";

import SearchBar from "../SearchBar/SearchBar";

import Sorting from "../Sorting/Sorting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getLanguageDirection } from "../../utils/locales";

import "./PageHeader.scss";

interface Props {
  title: string;
  backButton?: {
    title: string;
    backAction: () => void;
  };
  searchAndSorting?: {
    onSearchInput: (searchBy: string) => void;
    currSearchBy: string;
  };
}

const PageHeader: FC<Props> = ({
  title,
  backButton,
  searchAndSorting,
}): JSX.Element => {
  return (
    <div className="page-header flex align-center justify-center">
      <div className="header-grid-container">
        <div className="back-wrapper flex align-center justify-center">
          {backButton && backButton.title && (
            <div className="back-btn" onClick={backButton.backAction}>
              <div className="back-icn-wrapper">
                <FontAwesomeIcon
                  icon={[
                    "fal",
                    getLanguageDirection(i18n.language) === "ltr"
                      ? "angle-left"
                      : "angle-right",
                  ]}
                />
              </div>
              <h3>{backButton.title}</h3>
            </div>
          )}
        </div>
        {searchAndSorting && <Sorting />}
        <h1 className="main-title">{title}</h1>
        {searchAndSorting && (
          <SearchBar onInput={searchAndSorting.onSearchInput} />
        )}
      </div>
    </div>
  );
};

export default PageHeader;
