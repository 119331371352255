const env = process.env.REACT_APP_ENV;
import realNotificationService from "./NotificationService";
import Firebase from "./FirebaseService";
import firebaseForAuth from "firebase";
import FirebaseMock from "./MockServices/FirebaseMock";
import NotificationMock from "./MockServices/NotificationMock";
import AuthService from "./AuthService";

const firebase = firebaseForAuth;
const FirebaseService =
  // @ts-ignore
  env === "puppeteer" ? (FirebaseMock as typeof Firebase) : Firebase;
const NotificationService =
  env === "puppeteer" ? NotificationMock : realNotificationService;

export { FirebaseService, firebase, NotificationService, AuthService };
