import { format } from "date-fns";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";
import FirebaseService from "../../../services/FirebaseService";
import { popIndicator } from "../../../store/system/actions";
import MessageModal from "../../MessageModal/MessageModal";
import AuditEvent from "../AuditEvent/AuditEvent";

import "./JobAuditModal.scss";

interface Props {
  id: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobAuditModal: FC<Props> = ({ id, showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const [body, setBody] = useState<JSX.Element[]>();
  useEffect(() => {
    createModalBody();
  }, []);

  const { t } = useTranslation();

  const mainElement = useRef<HTMLDivElement>(null);

  useOutsideClick(mainElement, () => {
    if (showModal) {
      setShowModal(!showModal);
    }
  });

  const createModalBody = async () => {
    const audits = await FirebaseService.getAuditEvents([id]);

    if (!audits[id].length) {
      dispatch(popIndicator({ type: "failure", txt: t("no_audit") }));
      setShowModal(!showModal);
    } else {
      const auditsBody = audits[id].map((auditEvent, index) => {
        return <AuditEvent key={index} auditEvent={auditEvent}></AuditEvent>;
      });
      setBody(auditsBody);
    }
  };
  return body ? (
    <div className="JobAuditModal" ref={mainElement}>
      <MessageModal
        className="JobAudit"
        title={t("job_audit")}
        body={body}
        showModal={showModal}
      ></MessageModal>
    </div>
  ) : (
    <></>
  );
};

export default JobAuditModal;
