import _ from "lodash";
import firebase from "firebase";
import * as Sentry from "@sentry/react";
import config from "../config";
import FirebaseService from "./FirebaseService";
import { getFormattedPhone } from "../utils/formatters";
import CloudFunctionsService from "./CloudFunctionsService";

const supportedErrorCodes = [
  "auth/invalid-phone-number",
  "auth/invalid-verification-code",
  "auth/unsupported-first-factor",
  "auth/requires-recent-login",
  "auth/email-already-in-use",
  "auth/email-already-exists",
  "auth/phone-number-already-exists",
  "auth/too-many-requests",
  "auth/user-not-found",
  "auth/phone-not-exists",
];

const providers = {
  google: new firebase.auth.GoogleAuthProvider(),
  facebook: new firebase.auth.FacebookAuthProvider(),
};

const initLogin = async () => {
  FirebaseService.verifier = new firebase.auth.RecaptchaVerifier(
    "verifier-container",
    {
      size: "invisible",
    }
  );
  FirebaseService.provider = new firebase.auth.GoogleAuthProvider();
};

const getMfaState = (
  userData?: firebase.firestore.DocumentData | undefined
) => {
  const user = FirebaseService.auth.currentUser;

  if (!user) {
    return "logged_out";
  }
  if (
    !user.email ||
    !_.some(
      user.providerData,
      (provider) =>
        provider &&
        ["password", "google.com", "facebook.com"].includes(provider.providerId)
    )
  ) {
    return "missing_email";
  }
  if (user.email && !user.emailVerified) {
    return "missing_email_verification";
  }
  if (user.emailVerified && _.isEmpty(user.multiFactor.enrolledFactors)) {
    return "missing_mfa";
  }
  if (
    user.emailVerified &&
    !_.isEmpty(user.multiFactor.enrolledFactors) &&
    userData &&
    (!userData.username || !userData.country)
  ) {
    return "missing_user_details";
  }

  return "mfa_active";
};

const login = async (email: string, password: string) => {
  try {
    const user = await FirebaseService.auth.signInWithEmailAndPassword(
      email,
      password
    );
    return { success: true, user };
  } catch (err: any) {
    Sentry.captureException(err);
    const secondFactorError = "auth/multi-factor-auth-required";
    if (err.code === secondFactorError) {
      return { secondFactor: err.resolver };
    } else if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    } else {
      throw "auth/default";
    }
  }
};

const loginPhone = async (phonenumber: string) => {
  try {
    const formattedPhoneNumber = getFormattedPhone(phonenumber).toString();
    const isExistingUser = await CloudFunctionsService.auth.isExistingUser({
      key: "phonenumber",
      value: formattedPhoneNumber,
    });
    const {
      emailVerified,
    } = await CloudFunctionsService.auth.getMfaStatusByPhone(
      formattedPhoneNumber
    );

    if (!isExistingUser) {
      throw "auth/phone-not-exists";
    }
    if (emailVerified) {
      throw "auth/unsupported-first-factor";
    }
    const verification = await FirebaseService.auth.signInWithPhoneNumber(
      formattedPhoneNumber,
      FirebaseService.verifier
    );
    return verification;
  } catch (err: any) {
    Sentry.captureException(err);

    initLogin();
    if (
      supportedErrorCodes.includes(
        _.isString(err) ? err : err.code || err.message
      )
    ) {
      throw _.isString(err) ? err : err.code || err.message;
    }
    throw "auth/default";
  }
};

const signUp = async (userData: any) => {
  try {
    await CloudFunctionsService.auth.createUser(userData);
  } catch (err: any) {
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    } else {
      throw "auth/default";
    }
  }
};

const signUpWithProvider = async (provider: "google" | "facebook") => {
  try {
    const user = await FirebaseService.auth.signInWithPopup(
      providers[provider]
    );
    if (user.additionalUserInfo?.isNewUser) {
      await CloudFunctionsService.auth.completeProviderSignup(user.user);
    }
    return { success: true, user, isNew: user.additionalUserInfo?.isNewUser };
  } catch (err: any) {
    Sentry.captureException(err);
    const secondFactorError = "auth/multi-factor-auth-required";
    if (err.code === secondFactorError) {
      return { secondFactor: err.resolver };
    } else if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    } else {
      throw "auth/default";
    }
  }
};

const linkPhoneWithEmail = async (email: string, password: string) => {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    await FirebaseService.auth.currentUser?.linkWithCredential(credential);
    await FirebaseService.auth.currentUser?.sendEmailVerification({
      url: `${config.envUrl}${config.mfaEnrollmentPath}`,
    });
    await FirebaseService.updateUserMail(email);

    FirebaseService.analytics.logEvent("link_phone_with_mail");
  } catch (err: any) {
    Sentry.captureException(err);

    FirebaseService.verifier.clear();
    FirebaseService.verifier = new firebase.auth.RecaptchaVerifier(
      "verifier-container",
      { size: "invisible" }
    );
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    }
    throw "auth/default";
  }
};

const linkPhoneWithProvider = async (provider: "google" | "facebook") => {
  try {
    const user = await FirebaseService.auth.currentUser?.linkWithPopup(
      providers[provider]
    );
    if (user?.user && user.user.email) {
      await FirebaseService.updateUserMail(user.user.email);
    }

    FirebaseService.analytics.logEvent("link_phone_with_provider");

    return { success: true, user };
  } catch (err) {
    Sentry.captureException(err);
    return { success: false };
  }
};

const sendEmailVerification = async () => {
  try {
    await FirebaseService.auth.currentUser?.sendEmailVerification({
      url: `${config.envUrl}${config.mfaEnrollmentPath}`,
    });
  } catch (err: any) {
    Sentry.captureException(err);
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    }
    throw "auth/default";
  }
};

const requestEnrollMultiFactor = async (
  phonenumber: string,
  isPhoneLinked = false
) => {
  try {
    if (!FirebaseService.auth.currentUser)
      throw new Error("No user is logged in");
    if (isPhoneLinked) {
      if (
        _.some(
          FirebaseService.auth.currentUser.providerData,
          (provider) => provider && provider.providerId === "phone"
        )
      ) {
        await FirebaseService.auth.currentUser?.unlink("phone");
      }

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const mfaSession = await FirebaseService.auth.currentUser?.multiFactor.getSession();
      const phoneInfoOptions = {
        phoneNumber:
          phonenumber || FirebaseService.auth.currentUser?.phoneNumber,
        session: mfaSession,
      };

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        FirebaseService.verifier
      );
      return verificationId;
    } else {
      const verificationId = await firebase
        .auth()
        .currentUser?.linkWithPhoneNumber(
          phonenumber,
          FirebaseService.verifier
        );
      return verificationId;
    }
  } catch (err: any) {
    Sentry.captureException(err);
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    }
    throw "auth/default";
  }
};
const signInWithToken = async (token: string) => {
  await firebase.auth().signInWithCustomToken(token);
};
const completeEnrollMultiFactor = async (
  verificationId: firebase.auth.ConfirmationResult | string,
  verificationCode: string,
  phonenumber: string,
  uid: string,
  isPhoneLinked = false
) => {
  try {
    if (!FirebaseService.auth.currentUser)
      throw new Error("No user is logged in");
    if (isPhoneLinked) {
      const creds = firebase.auth.PhoneAuthProvider.credential(
        verificationId as string,
        verificationCode
      );
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        creds
      );
      await FirebaseService.auth.currentUser.multiFactor.enroll(
        multiFactorAssertion,
        "Phonenumber"
      );
    } else {
      //@ts-ignore
      await verificationId.confirm(verificationCode);
      const customToken = await CloudFunctionsService.auth.approveMultiFactor(
        phonenumber,
        uid
      );

      await signInWithToken(customToken);
    }

    FirebaseService.analytics.logEvent("complete_mfa_enroll");
  } catch (err: any) {
    Sentry.captureException(err);
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    }
    throw "auth/default";
  }
};

const sendPasswordRecovery = async (email: string) => {
  try {
    await FirebaseService.auth.sendPasswordResetEmail(email);
  } catch (err: any) {
    console.log({ err });

    Sentry.captureException(err);
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    }
    throw "auth/default";
  }
};

const logout = async (reauthWithPhone = false) => {
  try {
    await FirebaseService.auth.signOut();
    if (reauthWithPhone) {
      window.location.href = `${config.envUrl}login?phone=true`;
    }
  } catch (err: any) {
    Sentry.captureException(err);
    const secondFactorError = "auth/multi-factor-auth-required";
    if (err.code === secondFactorError) {
      return { secondFactor: err.resolver };
    } else if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    } else {
      throw "auth/default";
    }
  }
};

const getPhonenumberFromAuthUser = (user: firebase.User) => {
  const phoneFactor = _.find(
    user.multiFactor.enrolledFactors,
    (f) => f.factorId === "phone"
  );
  if (!phoneFactor) return;
  //@ts-ignore
  return phoneFactor.phoneNumber;
};

const addDetails = async (userData: any) => {
  try {
    await FirebaseService.addUserDetails(userData);
  } catch (err: any) {
    if (supportedErrorCodes.includes(err.code || err.message)) {
      throw err.code || err.message;
    }
  }
};

export default {
  initLogin,
  addDetails,
  getMfaState,
  login,
  loginPhone,
  linkPhoneWithEmail,
  linkPhoneWithProvider,
  sendEmailVerification,
  requestEnrollMultiFactor,
  completeEnrollMultiFactor,
  sendPasswordRecovery,
  logout,
  signUp,
  signUpWithProvider,
  getPhonenumberFromAuthUser,
};
