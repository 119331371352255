import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Dialog, DialogTitle } from "@material-ui/core";
import i18n from "../../i18n";
import classNames from "classnames";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { JobType } from "../../models/jobTypes";
import FirebaseService from "../../services/FirebaseService";
import Logger from "../../services/Logger";

import ValidationsConfig from "./ValidationsConfig";
import {
  ValidationsConfigData,
  ValidationsFormPageSlice,
} from "./ValidationsConfigTypes";

import { getLanguageDirection } from "../../utils/locales";

import "./ValidationsConfigModal.scss";

const logger = Logger("ValidationsConfigModal");

interface Props {
  isOpen: boolean;
  jobType: JobType;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ValidationsConfigModal = ({
  isOpen,
  jobType,
  setIsOpen,
}: Props & {
  allowSaveNew?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const { id } = useParams<Record<string, string>>();
  const [config, setConfig] = useState<ValidationsConfigData>();
  const [availableValidations, setAvailableValidations] = useState<{
    [v: string]: {
      [k: string]: "number" | "text";
    };
  }>();

  useEffect(() => {
    const getValidationConfig = async () => {
      const validationsConfig = await FirebaseService.getValidationsConfigData(
        id,
        jobType
      );
      const _validationConfig = {
        default: { ...new ValidationsFormPageSlice() },
        ...validationsConfig,
      };
      setConfig(_validationConfig);
    };
    const getAvailableValidations = async () => {
      const newAvailableValidations = await FirebaseService.getAvailableValidations();
      setAvailableValidations(newAvailableValidations);
    };
    getAvailableValidations();
    getValidationConfig();
  }, []);

  const handleSaveValidationsConfig = async (
    validations: ValidationsConfigData
  ) => {
    try {
      const dataToUpdate = {
        validation: {
          [jobType]: validations,
        },
      };

      if (!dataToUpdate) return;

      await FirebaseService.setValidationsConfigData(id, dataToUpdate);
      setIsOpen(false);
      logger.log(`Saved to ${id}`);
    } catch (err) {
      logger.error(err);
      throw err;
    }
  };

  return (
    <Dialog
      open={isOpen}
      className={classNames(
        "ValidationsConfigModal",
        getLanguageDirection(i18n.language)
      )}
    >
      <div className="x_icon_container">
        <div className="x_icon">
          <FontAwesomeIcon
            className="x"
            icon={["fal", "times"]}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>
      <DialogTitle>
        {t("validations_configuration", {
          jobType: t(jobType),
        })}
      </DialogTitle>
      {config && availableValidations ? (
        <ValidationsConfig
          availableValidations={availableValidations}
          handleSaveConfig={handleSaveValidationsConfig}
          validationsConfig={config}
        />
      ) : (
        <div className="modalLoadarContainer">
          <CircularProgress style={{ width: "100px" }} />
        </div>
      )}
    </Dialog>
  );
};

export default ValidationsConfigModal;
