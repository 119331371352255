import React from "react";
import { JobData } from "../../../models/job";
import { ExportConfigData } from "../ExportConfigTypes";

export interface ContentProps {
  settings: ExportConfigData;
  jobData: JobData;
  content: React.ReactNode;
  docDir: "ltr" | "rtl";
}

export const Content = ({ settings, content }: ContentProps) => {
  const formatMargins = settings.general.format === "pages" ? "100px" : "60px";
  return (
    <div
      className="pageContent"
      style={{ paddingLeft: formatMargins, paddingRight: formatMargins }}
    >
      {content}
    </div>
  );
};
