import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClientData, Contact } from "../../models/client";
import FirebaseService from "../../services/FirebaseService";
import PageHeader from "../../components/PageHeader/PageHeader";
import TextInput from "../../components/FormInputs/TextInput";
import Textarea from "../../components/FormInputs/Textarea";
import AddContactForm from "../../components/AddContactForm/AddContactForm";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./NewClientPage.scss";
import { popIndicator } from "../../store/system/actions";

const NewClientPage: FC = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [clientName, setClientName] = useState<string>("");
  const [clientNotes, setClientNotes] = useState<string>("");
  const [clientContacts, setClientContacts] = useState<Contact[]>([]);
  const [isContactForm, setIsContactForm] = useState<boolean>(false);

  //TODO: validate user ??
  const toggleIsContactForm = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.preventDefault();
    setIsContactForm(!isContactForm);
  };

  const addContact = (contact: Contact) => {
    toggleIsContactForm();
    clientContacts.push(contact);
  };

  const submitNewClient = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const newClient: ClientData = {
        name: clientName,
        notes: clientNotes,
        contacts: clientContacts,
      };
      await FirebaseService.updateClient(newClient);
      history.push("/clients");
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: "indicator_error_ocurred" })
      );
    }
  };

  const removeContact = (idx: number) => {
    const contactsClone = [...clientContacts];
    contactsClone.splice(idx, 1);
    setClientContacts(contactsClone);
  };

  const headerTitle = isContactForm ? t("add_contact") : t("add_client");

  const headerBackButton = isContactForm
    ? { title: t("add_client"), backAction: () => setIsContactForm(false) }
    : { title: t("clients"), backAction: () => history.push("/clients") };

  return (
    <main className="main-container">
      <PageHeader backButton={headerBackButton} title={headerTitle} />
      <div className="new-client-page">
        <form
          style={{ visibility: isContactForm ? "hidden" : "visible" }}
          className="client-form"
          onSubmit={(e) => submitNewClient(e)}
          action=""
        >
          <div className="client-info flex column align-flex-start">
            <TextInput
              type={"text"}
              focus={true}
              onInput={setClientName}
              width={408}
              placeholder={t("full_name")}
              required={true}
              Icn={["fal", "user"]}
            />
            <Textarea
              Icn={["fal", "edit"]}
              value={clientNotes}
              disabled={false}
              onInput={setClientNotes}
              width={408}
              height={312}
              placeholder={t("client_notes")}
            />
          </div>
          <div className="contacts-n-submit flex column align-flex-start">
            <div className="contacts">
              <button
                onClick={(e) => toggleIsContactForm(e)}
                className="add-contact-btn"
              >
                {t("add_contact")}
              </button>
              {clientContacts.length > 0 && (
                <div className="contacts-list">
                  {clientContacts.map((contact, idx) => (
                    <div key={idx} className="contact">
                      <div
                        className="remove-btn"
                        onClick={() => removeContact(idx)}
                      >
                        <FontAwesomeIcon icon={["fal", "times-circle"]} />
                      </div>
                      <p>{contact.fullname}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <SubmitButton text={t("add_client")} Icn={["fal", "user"]} />
          </div>
        </form>
        {!!isContactForm && <AddContactForm sendContact={addContact} />}
      </div>
    </main>
  );
};

export default NewClientPage;
