import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader/PageHeader";
import TextInput from "../../components/FormInputs/TextInput";
import SelectInput from "../../components/FormInputs/SelectInput";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import "./NewTranscriberPage.scss";

const NewTranscriberPage: FC = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );

  const [, setFullName] = useState<string>("");
  const [, setMail] = useState<string>("");
  const [, setBankNumber] = useState<string>("");
  const [, setBranchNumber] = useState<string>("");
  const [, setAccountNumber] = useState<string>("");
  const [, setIdNumber] = useState<string>("");
  const [, setPhoneNumber] = useState<string>("");
  const [vatIncluded, setVatIncluded] = useState<string>(
    t("not_including_vat")
  );
  //TODO: validate user
  const submitNewUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    vatIncluded === t("including_vat") ? true : false;
    // const newUser = {
    //     phonenumber: phoneNumber,
    //     username: fullName,
    //     mail,
    //     imgUrl: null
    // rejectedCount: 0,
    // completedCount: 0
    // };
    // await FirebaseService.addUser(newUser);
  };

  const backButton = {
    title: t("transcribers"),
    backAction: () => history.push("/transcribers"),
  };

  return (
    <main className="main-container">
      <PageHeader backButton={backButton} title={t("add_transcriber")} />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      <div className="new-transcriber-page">
        <form onSubmit={submitNewUser} action="">
          <div className="flex column align-flex-start">
            <TextInput
              type={"text"}
              onInput={setFullName}
              width={408}
              placeholder={t("full_name")}
              Icn={["fal", "user"]}
            />
            <TextInput
              type={"text"}
              onInput={setMail}
              width={408}
              placeholder={t("mail_address")}
              Icn={["fal", "at"]}
            />
            <div className="bank-details">
              <TextInput
                type={"number"}
                onInput={setBankNumber}
                width={96}
                placeholder={t("bank")}
                Icn={["fal", "university"]}
              />
              <TextInput
                type={"number"}
                onInput={setBranchNumber}
                width={104}
                placeholder={t("branch")}
              />
              <TextInput
                type={"number"}
                onInput={setAccountNumber}
                width={192}
                placeholder={t("account_number")}
              />
            </div>
          </div>

          <div className="flex column align-flex-start">
            <TextInput
              type={"number"}
              onInput={setIdNumber}
              width={272}
              placeholder={t("id_number")}
              Icn={["fal", "id-card"]}
            />
            <TextInput
              type={"text"}
              onInput={setPhoneNumber}
              width={272}
              placeholder={t("phone_number")}
              Icn={["fal", "phone"]}
            />
            <SelectInput
              onInput={setVatIncluded}
              width={272}
              values={[t("not_including_vat"), t("including_vat")]}
              placeholder={t("dealer_type")}
              Icn={["fal", "percentage"]}
            />
          </div>
          <SubmitButton text={t("add_transcriber")} Icn={["fal", "user"]} />
        </form>
      </div>
    </main>
  );
};

export default NewTranscriberPage;
