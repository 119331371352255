export const previewFormats = [
  {
    label: "translation",
    value: "translation",
    icon: "translation",
  },
  {
    label: "text-enhancement",
    value: "text-enhancement",
    icon: "text-enhancement",
  },
  {
    label: "interview",
    value: "interview",
    icon: "interview",
  },
  {
    label: "summary",
    value: "summary",
    icon: "summary",
  },
  {
    label: "protocol",
    value: "protocol",
    icon: "protocol",
  },
  {
    label: "subtitles",
    value: "subtitles",
    icon: "subtitles",
  },
  {
    label: "brief",
    value: "brief",
    icon: "brief",
  },
  {
    label: "subtitles-translation",
    value: "subtitles-translation",
    icon: "subtitles-translation",
  },
  {
    label: "sync-subtitles",
    value: "sync-subtitles",
    icon: "sync-subtitles",
  },
  {
    label: "live-interview",
    value: "live-interview",
    icon: "live-interview",
  },
];
