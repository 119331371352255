import React, { FC } from "react";
import "./NavigationButton.scss";

interface Props {
  isMenuOpen: boolean;
  onToggleMenu: () => void;
}

const NavigationButton: FC<Props> = ({
  isMenuOpen,
  onToggleMenu,
}): JSX.Element => {
  const isOpenClass = isMenuOpen ? "open" : "";

  return (
    <div
      className={`nav-btn flex column justify-between ${isOpenClass}`}
      onClick={() => onToggleMenu()}
    >
      <div className="line line1"></div>
      <div className="line line2"></div>
      <div className="line line3"></div>
    </div>
  );
};

export default NavigationButton;
