import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { UseFormSetValue } from "react-hook-form";
import classNames from "classnames";

import "./ColorPicker.scss";

export interface ColorPickerProps {
  controller?: {
    name: any;
    setValue: UseFormSetValue<any>;
    field: any;
  };
  value?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  icon?: JSX.Element;
}

export const ColorPicker = ({
  controller,
  value,
  onChange,
  disabled,
  icon,
}: ColorPickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleColorChange = (colors: any) => {
    if (controller) {
      controller.setValue(controller.name, colors.hex);
    } else if (onChange) {
      onChange(colors.hex);
    }
  };

  const fieldValue = controller?.field.value;

  return (
    <div className={classNames("ColorPicker", { disabled })}>
      <div
        className="colorPickerTrigger"
        style={
          !icon
            ? {
                borderColor: fieldValue ? fieldValue : value,
                background: fieldValue ? fieldValue : value,
              }
            : {
                color: fieldValue ? fieldValue : value,
              }
        }
        onClick={() => {
          if (disabled) return;
          setIsOpen(!isOpen);
        }}
      >
        {icon}
      </div>
      {isOpen && (
        <div className="sketchPickerWrapper">
          <SketchPicker
            width="130px"
            disableAlpha={false}
            presetColors={[]}
            color={controller?.field.value ? controller?.field.value : value}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </div>
  );
};
