import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./FileInput.scss";

interface Props {
  title: string;
  validFormats: string[];
  onFilesUpload: (
    e: React.ChangeEvent<HTMLInputElement>,
    isDroppedFile: boolean
  ) => void;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  uploadFileBtnClick?: () => void;
  multiple?: boolean;
  id?: string;
  disabled?: boolean;
}

export default function FileInput({
  id,
  title,
  onFilesUpload,
  forwardedRef,
  uploadFileBtnClick,
  validFormats,
  disabled = false,
  multiple = true,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="FileInputComponent">
      <input
        data-content={t("upload_file")}
        data-testid="addFilesInput"
        type="file"
        multiple={multiple}
        disabled={disabled}
        onChange={(e) => onFilesUpload(e, false)}
        ref={forwardedRef}
        className="add-file-input"
        accept={validFormats.join()}
        id={id}
      />
      {uploadFileBtnClick && (
        <button
          disabled={disabled}
          onClick={uploadFileBtnClick}
          className="add-file-btn"
        >
          <div className="icn-wrapper">
            <FontAwesomeIcon icon={["fal", "file-plus"]} />
          </div>
          <p>{t(title)}</p>
        </button>
      )}
    </div>
  );
}
