import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FilePicker } from "react-file-picker";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PaymentPreview.scss";

interface Props {
  previewMode: "debt" | "month";
  currPage: string;
  className?: string;
  title: string;
  subtitle: string | number;
  wasPaid?: boolean;
  invoiceUrl?: string;
  sum: number;
  onClick?: () => void | undefined;
  onAddInvoice?: (invoiceFile: File, onSuccess: () => void) => void;
  onApprovePayment?: (onSuccess: () => void) => void;
}

const EntityPreview: FC<Props> = ({
  previewMode,
  currPage,
  className,
  title,
  subtitle,
  wasPaid,
  invoiceUrl,
  sum,
  onClick,
  onAddInvoice,
  onApprovePayment,
}): JSX.Element => {
  const { t } = useTranslation();

  const [statusClass, setStatusClass] = useState<string>("");
  const [uploadProggress, setUploadProggress] = useState<boolean>(false);

  useEffect(() => {
    if (currPage === "my-payments" || currPage === "transcriber-payments") {
      if (previewMode === "month") {
        if (wasPaid) setStatusClass("positive");
        else if (invoiceUrl) setStatusClass("main");
        else setStatusClass("negative");
      }
    } else if (currPage === "client-payments" && previewMode === "debt") {
      if (wasPaid) setStatusClass("positive");
      else setStatusClass("main");
    }
  }, [currPage, previewMode, wasPaid, invoiceUrl]);

  const onAddInvoiceClick = (invoiceFile: File) => {
    setUploadProggress(true);
    if (onAddInvoice)
      onAddInvoice(invoiceFile, () => setUploadProggress(false));
  };

  const onApprovePaymentClick = () => {
    setUploadProggress(true);
    if (onApprovePayment) onApprovePayment(() => setUploadProggress(false));
  };

  return (
    <main
      onClick={() => {
        if (!uploadProggress && onClick) onClick();
      }}
      className={`payment-preview ${className} ${onClick && "clickable"}`}
    >
      <div className={`status-bar ${statusClass}`}></div>
      <div className="details">
        <h4>{title}</h4>
        <div className="subtitle-wrapper">
          {previewMode === "month" && (
            <div className="icn-wrapper">
              <FontAwesomeIcon icon={["fal", "folder-open"]} />
            </div>
          )}
          <p>{subtitle}</p>
        </div>
        <p className="sum">&#8362;{sum.toLocaleString()}</p>
        <div className="btns-container">
          {onApprovePayment &&
            previewMode === "month" &&
            invoiceUrl &&
            !uploadProggress && (
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  e.stopPropagation()
                }
              >
                <button
                  onClick={() => window.open(invoiceUrl)}
                  className="invoice-btn"
                >
                  <div className="icn-wrapper">
                    <FontAwesomeIcon icon={["fal", "file-plus"]} />
                  </div>
                  {t("show_invoice")}
                </button>
                {!wasPaid && (
                  <button onClick={onApprovePaymentClick} className="pay-btn">
                    {t("payment")}
                  </button>
                )}
              </div>
            )}
          {currPage === "client-payments" && !wasPaid && !uploadProggress && (
            <button onClick={onApprovePaymentClick} className="pay-btn client">
              {t("paid")}
            </button>
          )}
          {onAddInvoice &&
            !invoiceUrl &&
            previewMode === "month" &&
            !uploadProggress && (
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  e.stopPropagation()
                }
              >
                <FilePicker
                  extensions={["jpg", "png", "bmp", "docx", "pdf", "txt"]}
                  maxSize={4}
                  onChange={onAddInvoiceClick}
                >
                  <button className="invoice-btn">
                    <div className="icn-wrapper">
                      <FontAwesomeIcon icon={["fal", "file-plus"]} />
                    </div>
                    {t("add_invoice")}
                  </button>
                </FilePicker>
              </div>
            )}
          {uploadProggress && (
            <div className="loading-spinner">
              <CircularProgress style={{ width: "20px" }} />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default EntityPreview;
