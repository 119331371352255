import React from "react";
import { Slider } from "@material-ui/core";
import { UseFormSetValue } from "react-hook-form";

import "./SliderInput.scss";

export interface SliderInputProps {
  controller?: {
    name: any;
    setValue: UseFormSetValue<any>;
    field: any;
  };
  disabled?: boolean;
}

export const SliderInput = ({ controller, disabled }: SliderInputProps) => {
  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: any
  ) => {
    if (controller) {
      controller.setValue(controller.name, newValue);
    }
    //TODO: Move to sumit-ai-components & Handle
  };

  return (
    <div className="SliderInput">
      <Slider
        {...controller?.field}
        onChange={handleValueChange}
        disabled={disabled}
        min={50}
        max={250}
        valueLabelDisplay="auto"
        step={10}
        marks
      />
    </div>
  );
};
