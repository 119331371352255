import _ from "lodash";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TimerPicker from "../../components/TimerPicker/TimerPicker";
import TimerPickerNative from "../../components/TimerPicker/TimerPickerNative";
import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";

import { PreviewFormat, RoomData, Word } from "../../models";
import { JobData } from "../../models/job";

import EditorService from "../../services/EditorService";
import SoundManager from "../../services/SoundManager";
import TimeService from "../../services/TimeService";
import TrackingService from "../../services/TrackingService";

import SpeakerNameEditor from "../SpeakerNameEditor/SpeakerNameEditor";

import "./TextRange.scss";

interface Props {
  meeting: RoomData | JobData;
  rangeIndex: number;
  setFocusedRangeIndex: (i: number) => void;
  firstWordIndex: number;
  rangeSize: number;
  rangesCount: number;
  format: "protocol" | "subtitles" | "none" | "subtitles-translation";
  changeRangeSpeaker: (
    rangeIndex: number,
    wordIndex: number,
    wordCharIndex: number,
    speaker: string | null,
    words?: Word[]
  ) => void;
  mergeRangeSpeakers: (
    rangeIndex: number,
    words: Word[],
    mergeWithNextRange: boolean
  ) => void;
  mergeRanges: (mergeWithNextRange: boolean) => void;
  breakRange: (
    wordIndex: number,
    wordCharIndex: number,
    newRange: boolean,
    words: Word[]
  ) => void;
  deleteRange: () => void;
  updateWordTime: (
    wordIndex: number,
    time: string,
    position: string,
    method: "button" | "text"
  ) => void;
  isPassed: boolean;
  overlapping: boolean;
  updateRoomWords: (roomWords: Word[]) => void;
  setFocusedRangeWordsString: (wordsString: string) => void;
  isTemporarySpeaker: boolean;
  direction: "ltr" | "rtl";
  disabled?: boolean;
}

const TextRange: FC<Props> = ({
  meeting,
  rangeIndex,
  setFocusedRangeIndex,
  firstWordIndex,
  rangeSize,
  rangesCount,
  changeRangeSpeaker,
  mergeRangeSpeakers,
  mergeRanges,
  breakRange,
  deleteRange,
  updateWordTime,
  format,
  isPassed,
  overlapping,
  updateRoomWords,
  setFocusedRangeWordsString,
  direction,
  isTemporarySpeaker,
  disabled,
}) => {
  const { t } = useTranslation();
  const [speakerName, setSpeakerName] = useState(
    meeting.words[firstWordIndex].speaker
  );
  const previousSpeakerName =
    rangeIndex > 0 ? meeting.words[firstWordIndex - 1].speaker : null;

  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [rangeWords, setRangeWords] = useState<Word[]>([]);
  const [oldPlainWords, setOldPlainWords] = useState("");
  const [plainWords, setPlainWords] = useState("");
  const [rangeTimes, setRangeTimesState] = useState<{ [key: string]: string }>({
    start: "",
    end: "",
  });
  const [speakersPaneOpen, setSpeakersPaneOpen] = useState(false);
  const [isEditingSpeakerName, setIsEditingSpeakerName] = useState(
    isTemporarySpeaker
  );

  useEffect(() => {
    if (!meeting) return;
    setRangeAndPlainWords({ setOld: true });
    setRangeTimes();
  }, []);

  useEffect(() => {
    if (!meeting) return;
    const isFocused = document.activeElement === textInputRef.current;
    if (!isFocused || !isChanged) {
      setRangeAndPlainWords({ setOld: true });
      setRangeTimes();
      setSpeakerName(meeting.words[firstWordIndex].speaker);
    }
  }, [rangeSize, meeting, format, firstWordIndex]);

  useEffect(() => {
    setIsEditingSpeakerName(isTemporarySpeaker);
    setSpeakerName(meeting.words[firstWordIndex].speaker);
  }, [isTemporarySpeaker]);

  if (!meeting) return null;

  const getRangeWords = (
    meetingWords: Word[] = meeting.words,
    plainRangeSize = rangeSize
  ) => {
    return meetingWords.slice(firstWordIndex, firstWordIndex + plainRangeSize);
  };

  const setRangeAndPlainWords = ({
    meetingWords = meeting.words,
    isRangeEmpty = false,
    setOld = false,
  }: {
    meetingWords?: Word[];
    isRangeEmpty?: boolean;
    setOld?: boolean;
  } = {}) => {
    const rangeSlice = getRangeWords(meetingWords);
    const rangeString = rangeSlice.map((word) => word.text).join(" ");
    if (isRangeEmpty) {
      handleMergeRange();
      return;
    }
    setPlainWords(rangeString);
    setRangeWords(rangeSlice);
    setSpeakerName(meeting.words[firstWordIndex].speaker);
    if (setOld) setOldPlainWords(rangeString);
  };

  const setRangeTimes = () => {
    try {
      const start = TimeService.getTimeStringFromSecs(
        meeting.words[firstWordIndex].start,
        false,
        true
      );
      const end = TimeService.getTimeStringFromSecs(
        meeting.words[firstWordIndex + rangeSize - 1].end,
        false,
        true
      );
      setRangeTimesState({
        start,
        end,
      });
    } catch (err) {
      Sentry.captureException({
        err,
        rangesCount,
        rangeSize,
        rangeIndex,
        word: firstWordIndex,
      });
    }
  };

  if (_.isEmpty(rangeWords)) return null;

  // --- Range Manipulators ---

  const handleChangeSpeaker = async ({
    selectionStart,
    speaker,
  }: {
    selectionStart?: number;
    selectionEnd?: number;
    speaker?: string;
  }) => {
    const newMeetingWords = await EditorService.getMeetingWordsFromString(
      meeting.words,
      plainWords,
      oldPlainWords,
      firstWordIndex,
      rangeWords[0].speaker,
      rangesCount
    );

    updateRoomWords(newMeetingWords);
    setOldPlainWords(plainWords);

    const { rangeWordIndex, wordCharIndex } = getCurrentWordIndex(
      selectionStart || 0,
      newMeetingWords
    );

    if (!speaker && selectionStart) {
      changeRangeSpeaker(
        rangeIndex,
        firstWordIndex + rangeWordIndex,
        wordCharIndex,
        null,
        newMeetingWords
      );
    } else if (speaker) {
      changeRangeSpeaker(
        rangeIndex,
        firstWordIndex,
        wordCharIndex,
        speaker,
        newMeetingWords
      );
    }
  };

  const handleMergeRangeSpeakers = async (mergeWithNextRange = false) => {
    const newMeetingWords = await EditorService.getMeetingWordsFromString(
      meeting.words,
      plainWords,
      oldPlainWords,
      firstWordIndex,
      rangeWords[0].speaker,
      rangesCount
    );

    updateRoomWords(newMeetingWords);
    setOldPlainWords(plainWords);

    mergeRangeSpeakers(rangeIndex, newMeetingWords, mergeWithNextRange);
  };

  const handleBreakRange = async (
    e: React.KeyboardEvent,
    newRange: boolean
  ) => {
    const { selectionStart } = e.target as HTMLTextAreaElement;
    const newMeetingWords = await EditorService.getMeetingWordsFromString(
      meeting.words,
      plainWords,
      oldPlainWords,
      firstWordIndex,
      rangeWords[0].speaker,
      rangesCount
    );

    const { rangeWordIndex, wordCharIndex } = getCurrentWordIndex(
      selectionStart,
      newMeetingWords
    );
    updateRoomWords(newMeetingWords);
    setOldPlainWords(plainWords);
    breakRange(rangeWordIndex, wordCharIndex, newRange, newMeetingWords);
  };

  const handleMergeRange = (mergeWithNextRange = false) => {
    mergeRanges(mergeWithNextRange);
  };

  const getCurrentWordIndex = (cursorPosition: number, words: Word[]) => {
    const trimmedWordCount = plainWords
      .trim()
      .split(" ")
      .filter((word) => word).length;
    const rangeWordsMeeting = getRangeWords(words, trimmedWordCount);
    const cursorDiff =
      cursorPosition > 0 ? getCursorFixedPositionAfterTrim(cursorPosition) : 0;
    let rangeWordIndex = 0;
    let wordCharIndex: number = cursorPosition + cursorDiff;

    while (
      wordCharIndex > 0 &&
      wordCharIndex > rangeWordsMeeting[rangeWordIndex].text.length
    ) {
      const wordToCheck = rangeWordsMeeting[rangeWordIndex].text.length;
      wordCharIndex = wordCharIndex - (wordToCheck + 1); // +1 for space
      rangeWordIndex++;
    }

    return { rangeWordIndex, wordCharIndex };
  };

  const getCursorFixedPositionAfterTrim = (cursorPosition: number) => {
    const isBeginningOfWord =
      cursorPosition === 0 || plainWords[cursorPosition - 1] === " ";
    const rangeFirstHalfTrimmedLength = plainWords
      .slice(0, cursorPosition)
      .trim()
      .split(" ")
      .filter((word) => word)
      .join(" ").length;
    const cursorDiff =
      rangeFirstHalfTrimmedLength +
      (isBeginningOfWord ? 1 : 0) -
      cursorPosition;
    return cursorDiff;
  };

  const focusAndSetCursor = (
    rangeIndexToFocus: number,
    cursorPosition: number
  ) => {
    const rangeToFocus = document.getElementById(
      `range-${rangeIndexToFocus}`
    ) as HTMLInputElement;
    if (rangeToFocus) {
      const cursorPositionToSet =
        cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length + 1; // +1 to set cursor after the space
      rangeToFocus.focus();
      setTimeout(
        () =>
          rangeToFocus.setSelectionRange(
            cursorPositionToSet,
            cursorPositionToSet
          ),
        0
      );
    } else {
      // Retry - for creating new range at the end
      setTimeout(
        () => focusAndSetCursor(rangeIndexToFocus, cursorPosition),
        10
      );
    }
  };

  // --- Event Handlers ---

  const handleRangeTimeUpdate = (
    position: string,
    time: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newTime = _.isString(time) ? time : time.target.value;
    setRangeTimesState({
      ...rangeTimes,
      [position]: newTime,
    });
    setIsChanged(true);
  };

  const handleRangeTimeBlur = (position: string) => {
    const wordToUpdate = (position === "start"
      ? _.first(rangeWords)
      : _.last(rangeWords)) as Word;
    const wordIndex = meeting.words.indexOf(wordToUpdate);
    updateWordTime(wordIndex, rangeTimes[position], position, "text");
    setIsChanged(false);
  };

  const addDeductRangeTime = (seconds: number, position: string) => {
    const timeInSecs = TimeService.getTimeNumberFromString(
      rangeTimes[position]
    );
    const newTimeString = TimeService.getTimeStringFromSecs(
      timeInSecs + seconds < 0 ? 0 : timeInSecs + seconds,
      false,
      true
    );
    setRangeTimesState({
      ...rangeTimes,
      [position]: newTimeString,
    });
    const wordToUpdate = (position === "start"
      ? _.first(rangeWords)
      : _.last(rangeWords)) as Word;
    const wordIndex = meeting.words.indexOf(wordToUpdate);
    updateWordTime(wordIndex, newTimeString, position, "button");
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (
      handleKeyboardShortcut(
        e,
        ["Enter", "NumpadEnter"],
        [format === "subtitles"]
      )
    ) {
      if (selectionStart === textLength) return;
      if (selectionStart === 0) {
        handleMergeRange(true);
        focusAndSetCursor(rangeIndex, 0);
        return;
      }
      handleBreakRange(e, e.ctrlKey);
      focusAndSetCursor(rangeIndex + 1, 0);
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Enter", "NumpadEnter"],
        [format === "protocol", !e.ctrlKey]
      )
    ) {
      if (selectionStart === textLength) return;
      if (selectionStart === 0 || selectionEnd === textLength) return;

      handleChangeSpeaker({ selectionStart });
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === 0]
      )
    ) {
      if (rangeIndex === 0) return;
      if (format === "subtitles") {
        handleMergeRange();
        focusAndSetCursor(rangeIndex - 1, -textLength);
        return;
      }

      if (format === "protocol") {
        handleMergeRangeSpeakers();
        focusAndSetCursor(rangeIndex - 1, -textLength);
        return;
      }
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === textLength, rangesCount > 1]
      )
    ) {
      // Deletion of entire range
      deleteRange();
    }

    if (
      handleKeyboardShortcut(e, ["Delete"], [selectionStart === textLength])
    ) {
      if (format === "subtitles") {
        handleMergeRange(true);
        focusAndSetCursor(rangeIndex, textLength);
        return;
      } else {
        handleMergeRangeSpeakers(true);
        focusAndSetCursor(rangeIndex, textLength);
      }
    }

    if (handleKeyboardShortcut(e, ["Tab"])) {
      const rangeIndexToFocus = e.nativeEvent.shiftKey
        ? rangeIndex - 1
        : rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    }

    if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowLeft") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowRight") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey], false)) {
      handleBlur();
    }

    if (
      handleKeyboardShortcut(e, ["KeyD"], [e.ctrlKey, format === "protocol"])
    ) {
      handleBlur();

      setSpeakersPaneOpen(!speakersPaneOpen);
    }

    if (handleKeyboardShortcut(e, ["KeyX"], [e.ctrlKey || e.metaKey])) {
      return;
    }

    // -- Editor Shortcuts --
  };

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!e.altKey) return;
    let { selectionStart } = e.target as HTMLTextAreaElement;

    const editedWords = plainWords.split(" ");
    const lengths = editedWords.map((word) => word.length);

    let clickedWord = -1;
    while (selectionStart > 0) {
      clickedWord++;
      selectionStart = selectionStart - lengths[clickedWord] - 1;
    }
    if (selectionStart === 0) clickedWord++;

    !!rangeWords &&
      !!rangeWords[clickedWord] &&
      SoundManager.setOffset(rangeWords[clickedWord].start);
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (format === "protocol") {
      setFocusedRangeIndex(rangeIndex);
    }
  };

  const handleBlur = async () => {
    if (isChanged) {
      setFocusedRangeIndex(-1);
      const newMeetingWords = EditorService.getMeetingWordsFromString(
        meeting.words,
        plainWords,
        oldPlainWords,
        firstWordIndex,
        rangeWords[0].speaker,
        rangesCount
      );
      updateRoomWords(newMeetingWords);
      setOldPlainWords(plainWords);
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputString = e.target.value === "" ? "." : e.target.value;

    setIsChanged(true);
    setPlainWords(newInputString);
    setFocusedRangeWordsString(newInputString);
    setFocusedRangeIndex(rangeIndex);
  };

  return (
    <>
      <div
        className={classNames("textRange", format, direction, {
          passed: isPassed,
          overlapping:
            overlapping ||
            TimeService.getTimeNumberFromString(rangeTimes.start) >=
              TimeService.getTimeNumberFromString(rangeTimes.end),
        })}
      >
        {format === "subtitles" ? (
          <div className={"rangeTimes subtitles"}>
            <TimerPicker
              className={classNames({ ltr: direction === "ltr" })}
              value={rangeTimes.start}
              handleChange={(time: string) =>
                handleRangeTimeUpdate("start", time)
              }
              handleBlur={() => handleRangeTimeBlur("start")}
              step={0.1}
              deductTime={() => addDeductRangeTime(-0.1, "start")}
              addTime={() => addDeductRangeTime(0.1, "start")}
              disabled={disabled}
            />
            <TimerPicker
              className={classNames({ ltr: direction === "ltr" })}
              value={rangeTimes.end}
              handleChange={(time: string) =>
                handleRangeTimeUpdate("end", time)
              }
              handleBlur={() => handleRangeTimeBlur("end")}
              step={0.1}
              deductTime={() => addDeductRangeTime(-0.1, "end")}
              addTime={() => addDeductRangeTime(0.1, "end")}
              disabled={disabled}
            />
          </div>
        ) : (
          <div className="rangeTimes protocol">
            <div className="speakerBlockContainer">
              <span className="speakerTime">
                {TimeService.getTimeStringFromSecs(rangeWords[0].start)}
              </span>
              <div className="speakerNameContainer">
                <SpeakerNameEditor
                  speakerName={speakerName}
                  speakers={meeting.speakers.filter(
                    (s) =>
                      s !==
                      (isTemporarySpeaker ? previousSpeakerName : speakerName)
                  )}
                  handleSetNewSpeakerName={(speaker) => {
                    changeRangeSpeaker(rangeIndex, firstWordIndex, 0, speaker);
                    setSpeakerName(speaker);
                    setIsEditingSpeakerName(false);
                    focusAndSetCursor(rangeIndex, 0);
                    return true;
                  }}
                  cancelEdit={() => {
                    changeRangeSpeaker(
                      rangeIndex,
                      firstWordIndex,
                      0,
                      "$unidentified_speaker$"
                    );
                    setIsEditingSpeakerName(false);
                    focusAndSetCursor(rangeIndex, 0);
                  }}
                  placeholder={t("choose_speaker")}
                  isEditing={isEditingSpeakerName}
                />
              </div>
              {!_.isEmpty(meeting.speakers) && (
                <SpeakerList
                  speakers={meeting.speakers}
                  currentSpeaker={speakerName}
                  onSelect={handleChangeSpeaker}
                  isOpen={speakersPaneOpen}
                  close={() => setSpeakersPaneOpen(false)}
                />
              )}
            </div>
          </div>
        )}

        <div className="rangeText">
          <div className={classNames("textContainer", { disabled })}>
            {!disabled && (
              <textarea
                id={`range-${rangeIndex}`}
                ref={textInputRef}
                className="textRangeField"
                value={plainWords}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            )}
            <div className="dummyRange">{plainWords}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const SpeakerList: FC<{
  speakers: string[];
  currentSpeaker: string;
  onSelect: ({ speaker }: { speaker: string }) => void;
  isOpen: boolean;
  close: () => void;
}> = ({ speakers, currentSpeaker, onSelect, isOpen, close }) => {
  const { t } = useTranslation();
  const speakerListRef = useRef<HTMLDivElement>(null);

  const [isCreateNewSpeakerOpen, setIsCreateNewSpeakerOpen] = useState(false);
  const [filteredSpeakers, setFilteredSpeakers] = useState<string[]>([]);

  useEffect(() => {
    setFilteredSpeakers(speakers.filter((s) => s !== currentSpeaker));
  }, []);

  const handleSetNewSpeakerName = (newSpeakerName: string) => {
    onSelect({ speaker: newSpeakerName });
    setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
    close();
    return true;
  };

  const handleCancelEdit = () => {
    setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
    close();
  };

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!speakerListRef.current?.contains(event.target as Node)) {
        close();
      }
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div className="speakerListContainer" ref={speakerListRef}>
      <div className={classNames("speakerList", { open: isOpen })}>
        {filteredSpeakers.map((speaker, i) => (
          <div
            className="speaker"
            onClick={() => {
              onSelect({ speaker });
              close();
            }}
            key={i}
          >
            {speaker}
          </div>
        ))}
        {!isCreateNewSpeakerOpen ? (
          <div
            className="addSpeaker"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
            }}
          >
            <span>+</span>
            <span>{t("create_new_speaker")}</span>
          </div>
        ) : (
          <SpeakerNameEditor
            speakers={filteredSpeakers}
            handleSetNewSpeakerName={handleSetNewSpeakerName}
            cancelEdit={handleCancelEdit}
            placeholder={t("create_new_speaker")}
            isEditing={true}
          />
        )}
      </div>
    </div>
  );
};

export default TextRange;
