import React, { FC, useState, useEffect } from "react";
import BarLoader from "../BarLoader/BarLoader";
import "./LoadingModal.scss";

interface Props {
  loadingReason: string;
}

const LoadingModal: FC<Props> = ({ loadingReason }): JSX.Element => {
  let transitionPhase = 0;
  const [displayState, setDisplayState] = useState<{
    rotation: number;
    isWide: boolean;
  }>({ rotation: 0, isWide: false });

  useEffect(() => {
    transitionPhase++;
    setTimeout(() => animate(), 0);
    const interval = setInterval(() => {
      transitionPhase++;
      animate();
    }, 800);
    return () => clearInterval(interval);
  }, []);

  const animate = () => {
    setDisplayState({
      rotation: transitionPhase * 45,
      isWide: transitionPhase % 2 === 0 ? false : true,
    });
  };

  const dotStyle = {
    width: displayState.isWide ? "48px" : "36px",
    height: displayState.isWide ? "48px" : "36px",
  };

  const dotsWrapperStyle = {
    width: displayState.isWide ? "226px" : "102px",
  };

  const wrapperStyle = {
    transform: `rotate(${displayState.rotation}deg)`,
    height: displayState.isWide ? "226px" : "102px",
  };

  return (
    <div className="LoadingComponent">
      <div className="loading-modal">
        <div className="container">
          {/* <div style={dotsWrapperStyle} className="dots-wrapper">
            <div style={dotStyle} className="dot"></div>
            <div style={dotStyle} className="dot"></div>
          </div>
          <div style={dotsWrapperStyle} className="dots-wrapper">
            <div style={dotStyle} className="dot"></div>
            <div style={dotStyle} className="dot"></div>
          </div> */}
          <BarLoader />
        </div>
      </div>
      <div className="reason">{loadingReason}</div>
    </div>
  );
};

export default LoadingModal;
