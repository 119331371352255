import _ from "lodash";
import i18n from "../i18n";
import { TFunction, useTranslation } from "react-i18next";
import {
  NotificationEvent,
  Info,
  Recipient,
  NotificationData,
} from "../models";
import FirebaseService from "./FirebaseService";
import notificationLogo from "../logo192.png";
import config from "../config";

const t = i18n.t.bind(i18n);

const createNotificationBlueprint = (
  event: NotificationEvent,
  recipient: Recipient,
  info: Info
): NotificationData => {
  const notification: NotificationData = {
    event,
    recipient,
    info,
    creationTime: new Date(Date.now()),
    approvedBy: [],
  };
  return notification;
};
const dispatchNotification = async (
  event: NotificationEvent,
  recipient: Recipient,
  info: Info
): Promise<void> => {
  try {
    const notification = createNotificationBlueprint(event, recipient, info);
    await FirebaseService.sendNotification(notification);
  } catch (err) {
    //TODO handle errors
    console.log(err);
  }
};

const markAllUserNotificationsAsRead = async (userId: string) => {
  const rawUserRef = FirebaseService.db.collection("users-v2").doc(userId);
  const userData = await rawUserRef.get();
  const user = userData.data();
  if (!user) return;
  const myNotifications = await FirebaseService.db
    .collection("notifications")
    .where("recipient", "in", [rawUserRef, user.role])
    .get();
  if (!myNotifications.docs.length) return;

  const chunkedNotifications = _.chunk(myNotifications.docs, 500);
  for (const notificationChunk of chunkedNotifications) {
    const batch = FirebaseService.db.batch();
    for (const notification of notificationChunk) {
      const notificationData = notification.data();
      if (notificationData.approvedBy.includes(userId)) continue;
      try {
        batch.update(notification.ref, {
          approvedBy: [...notificationData.approvedBy, rawUserRef.id],
        });
      } catch (err) {
        console.log(err);
      }
    }
    await batch.commit();
  }
};

const getUserNotifications = async (
  userId: string
): Promise<NotificationData[] | undefined> => {
  const rawUserRef = await FirebaseService.db
    .collection(FirebaseService.collections.users)
    .doc(userId);
  const userData = await rawUserRef.get();
  const user = await userData.data();
  if (!user) return;
  const userRelatedNotifications = await FirebaseService.db
    .collection(FirebaseService.collections.notifications)
    .where("recipient", "in", [rawUserRef, user.role]) // check if user got notification either direct or by his role
    .get();
  let notReadNotifications: NotificationData[] = [];
  await userRelatedNotifications.forEach(async (n) => {
    const notification = await n.data();
    if (!notification.approvedBy.includes(userId))
      notReadNotifications.push(notification as NotificationData);
  });
  if (notReadNotifications.length)
    notReadNotifications = checkAndRemoveDuplicates(notReadNotifications);
  return notReadNotifications;
};

const checkAndRemoveDuplicates = (notificationsArray: NotificationData[]) => {
  // Duplicate shouldnt be, this function for double check & safty reasons.
  let uniqueArray: NotificationData[] = [];
  uniqueArray = notificationsArray.filter((not) => {
    const isDuplicate = uniqueArray.some(
      (uniqueNot: NotificationData) => uniqueNot.id === not.id
    );
    if (!isDuplicate) return not;
  });
  return uniqueArray;
};

const approveNotification = async (
  notificationId: string,
  userId: string
): Promise<void> => {
  const notificationRef = FirebaseService.db
    .collection(FirebaseService.collections.notifications)
    .doc(notificationId);
  const doc = await notificationRef.get();
  const data = doc.data();
  if (data) {
    const approvedBy = data.approvedBy;
    approvedBy.push(userId);
    await notificationRef.update({ approvedBy });
  }
};

const getNotificationBodyTextAndPath = (notification: NotificationData) => {
  let text: string;
  let path: string;
  if (!notification?.info) return;
  const { roomName, roomsCount, sentBy } = notification.info;
  const paymentStr = notification.info.paymentMonth
    ? t(notification.info.paymentMonth.split("-")[0]) +
      " " +
      notification.info.paymentMonth.split("-")[1]
    : "";
  // generate the notification string.

  switch (notification.event) {
    case "gotAssigned":
      path = "/my-jobs";
      text = `${t("notifications_got_assigned_1", {
        jobname: roomName,
        username: FirebaseService.currentUserMetadata?.username,
      })}\n${t("notifications_got_assigned_2")}`;
      break;
    case "meetingsAdded":
      path = "/";
      text = `${t("notifications_meetings_added_1")} ${roomsCount} ${t(
        "notifications_meetings_added_2"
      )}`;
      break;
    case "missingReceipt":
      path = "/";
      text = `${t("notifications_missing_receipt")} ${paymentStr}`;
      break;
    case "paymentReceived":
      path = "/my-payments";
      text = `${t("notifications_payment_received_1")} ${paymentStr} ${t(
        "notifications_payment_received_2"
      )}`;
      break;
    case "pendingTranscriber":
      path = `/transcriber/${sentBy.id}`;
      text = `${t("notifications_pending_transcriber_1")} ${sentBy.name} ${t(
        "notifications_pending_transcriber_2"
      )}`;
      break;
    case "sentToProof":
      path = `/`;
      text = `
      ${t("notifications_sent_to_proof_1")} ${sentBy.name}
      ${t("notifications_sent_to_proof_2")} ${roomName}
      ${t("notifications_sent_to_proof_3")}
      `;
      break;
    case "proofingsAdded":
      path = `/`;
      text = `${t("notifications_proofings_added_1")} ${roomsCount} ${t(
        "notifications_proofings_added_2"
      )}`;
      break;
    case "returnedForRevisions":
      path = `/my-jobs`;
      text = `${t("notifications_returned_for_revisions_1", {
        jobname: roomName,
        username: FirebaseService.currentUserMetadata?.username,
      })}\n${t("notifications_returned_for_revisions_2")}`;
      break;
    case "transcriberReturnedMeeting":
      path = `/`;
      text = `
      ${t("notifications_transcriber_returned_meeting_1")} ${sentBy.name} 
      ${t("notifications_transcriber_returned_meeting_2")} ${roomName}, 
      ${t("notifications_transcriber_returned_meeting_3")}
      `;
      break;
    case "requestTranscribe":
      path = `/`;
      text = `${sentBy.name} ${t(
        "notifications_request_transcribe"
      )} ${roomName}`;
      break;

    default:
      return;
  }
  return { text, path };
};

const pushBrowserNotification = (notification: NotificationData) => {
  const NotificationBodyText = getNotificationBodyTextAndPath(notification);
  const notificationBody = new Notification(t(notification.event), {
    body: NotificationBodyText?.text,
    icon: notificationLogo,
  });
  notificationBody.onclick = () => {
    window.location.href = `${config.envUrl}${NotificationBodyText?.path}`;
  };
};

export default {
  pushBrowserNotification,
  markAllUserNotificationsAsRead,
  createNotificationBlueprint,
  dispatchNotification,
  getUserNotifications,
  getNotificationBodyTextAndPath,
  approveNotification,
};
