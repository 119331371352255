import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";

import { AppState } from "../../store/rootReducer";
import AuthService from "../../services/AuthService";

import { getFormattedPhone } from "../../utils/formatters";

import Steps from "../../components/StepsComponent/Steps";
import LoginStep from "./LoginStep";
import EmailVerifyStep from "./EmailVerifyStep";
import PhoneVerifyStep from "./PhoneVerifyStep";
import AddUserDetails from "./AddUserDetails";
import ReAuth from "./ReAuth";

import Logger from "../../services/Logger";

const logger = Logger("MfaPage");

import "./AuthPages.scss";

const PhoneLoginPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const [isLoading, setIsloading] = useState(true);
  const [loginWithPhone] = useState(false);
  const [mfaStepVerificationId, setMfaStepVerificationId] = useState<
    firebase.default.auth.ConfirmationResult | undefined | string
  >();
  const [error, setError] = useState<string>();
  const [shouldReAuth, setShouldReAuth] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const [isPhoneLinked, setIsPhoneLinked] = useState<boolean>(false);

  useEffect(() => {
    loggedInUser && setIsPhoneLinked(loggedInUser?.providers.includes("phone"));
  }, [loggedInUser]);

  const handleRequestEnrollMultiFactor = async (phonenumber: string) => {
    setError("");
    try {
      const formattedPhone = getFormattedPhone(phonenumber).toString();
      if (
        loggedInUser?.phonenumber &&
        loggedInUser?.phonenumber !== formattedPhone
      )
        throw "invalid_phone";

      setPhonenumber(formattedPhone);
      const verificationId = await AuthService.requestEnrollMultiFactor(
        formattedPhone,
        isPhoneLinked
      );

      verificationId && setMfaStepVerificationId(verificationId);
      return verificationId;
    } catch (err: any) {
      logger.error(err, "handleRequestEnrollMultiFactor");
      if (err === "auth/requires-recent-login") {
        setShouldReAuth(true);
      }
      setError(t(err));
    }
  };

  const handleCompleteEnrollMultiFactor = async (code: string) => {
    setError("");
    try {
      if (loggedInUser?.id && mfaStepVerificationId) {
        await AuthService.completeEnrollMultiFactor(
          mfaStepVerificationId,
          code,
          phonenumber,
          loggedInUser.id,
          isPhoneLinked
        );
        location.reload();
      }
    } catch (err: any) {
      logger.error(err, "handleCompleteEnrollMultiFactor");
      setError(t(err));
    }
  };

  useEffect(() => {
    AuthService.initLogin();
    if (!loggedInUser) return;

    redirectToStep();
    setIsloading(false);
  }, [loggedInUser]);

  const redirectToStep = () => {
    const mfaState = loggedInUser?.mfaState as string;
    const stepToRedirect = _.findIndex(steps, (s) =>
      s.states.includes(mfaState)
    );

    if (stepToRedirect < 0) {
      history.push("/");
    }

    setCurrentStep(stepToRedirect);
  };

  const steps = [
    {
      label: t("login_step_phone"),
      states: ["", "logged_out"],
      step: <LoginStep />,
    },
    {
      label: t("login_step_add_email"),
      states: ["missing_email", "missing_email_verification"],
      step: <EmailVerifyStep />,
    },
    {
      label: t("login_step_phone_verify"),
      states: ["missing_mfa"],
      step: (
        <PhoneVerifyStep
          onPhoneSend={handleRequestEnrollMultiFactor}
          onVerificationSend={handleCompleteEnrollMultiFactor}
          error={error}
        />
      ),
      note: !mfaStepVerificationId
        ? t("phone_verification_note")
        : t("second_factor_note", { phonenumber }),
    },
    {
      label: t("login_add_user_details"),
      states: ["missing_user_details"],
      step: <AddUserDetails />,
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <div className="MfaPage">
      <div className={classNames("authContainer", { hidden: isLoading })}>
        <div className="headerTitle">
          {loginWithPhone ? t("attention") : t("welcome_login")}
        </div>
        <div className="note">
          <div className="noteBody">
            {t("phone_login_note")
              .split("/n")
              .map((l, i) => (
                <div className="noteLine" key={i}>
                  {l}
                </div>
              ))}
          </div>
        </div>
        <Steps steps={steps} currentStep={currentStep} className="center" />
        {shouldReAuth ? (
          <ReAuth />
        ) : (
          <div className="currentStepContainer">
            {/* {steps[currentStep].title && (
            <div className="headerTitle">{steps[currentStep].title}</div>
          )} */}
            {steps[currentStep].note && (
              <div className="note">
                <div className="noteBody">
                  {/* @ts-ignore */}
                  {steps[currentStep].note.split("/n").map((l, i) => (
                    <div
                      dangerouslySetInnerHTML={{ __html: l }}
                      className="noteLine"
                      key={i}
                    />
                  ))}
                </div>
              </div>
            )}
            {steps[currentStep].step}
          </div>
        )}
        <div className="logout">
          <span className="link" onClick={() => AuthService.logout()}>
            {t("log_out")}
          </span>
        </div>
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};

export default PhoneLoginPage;
