import React, { FC, useState, useEffect, useRef } from "react";
import config from "../../config";
import _ from "lodash";
import firebase from "firebase";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import FirebaseService from "../../services/FirebaseService";
import AuthService from "../../services/AuthService";

import AuthProvidersButtons from "../../components/AuthProvidersButtons/AuthProvidersButtons";
import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";
import ReAuth from "./ReAuth";

import Logger from "../../services/Logger";

const logger = Logger("EmailVerifyStep");

type EmailInputs = {
  email: string;
  password: string;
  passwordConfirm: string;
};

const EmailVerifyStep: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [shouldReAuth, setShouldReAuth] = useState(false);
  const [awaitingEmailVerify, setAwaitingEmailVerify] = useState(false);

  useEffect(() => {
    const mfaState = AuthService.getMfaState();
    setAwaitingEmailVerify(mfaState === "missing_email_verification");
  }, [FirebaseService.auth.currentUser?.emailVerified]);

  const handleSendEmail = async (data: EmailInputs) => {
    try {
      setIsLoading(true);
      await AuthService.linkPhoneWithEmail(data.email, data.password);
      setAwaitingEmailVerify(true);
    } catch (err: any) {
      logger.error(err, "handleSendEmail");
      if (err === "auth/requires-recent-login") {
        setShouldReAuth(true);
      }
      setEmailError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailVerifyRequest = async () => {
    try {
      setIsLoading(true);
      await AuthService.sendEmailVerification();
    } catch (err: any) {
      setEmailError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleProviderLink = async (provider: "google" | "facebook") => {
    try {
      setIsLoading(true);
      setEmailError("");
      const login = await AuthService.linkPhoneWithProvider(provider);
      if (login.user?.user) {
        login.user.user.getIdToken(true);
      }
    } catch (err: any) {
      logger.error(err, "handleProviderLink");
      setEmailError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const linkProviders = [
    {
      label: "google",
      onClick: () =>
        handleProviderLink("google")
          .then(location.reload)
          .catch((err) => logger.error(err, "handleProviderLink")),
    },
    // {
    //   label: "facebook",
    //   onClick: () => handleProviderLogin("facebook")
    // }
  ];

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<EmailInputs>({ mode: "onChange" });

  return (
    <div className="EmailVerifyStep">
      {shouldReAuth ? (
        <ReAuth />
      ) : awaitingEmailVerify ? (
        <div className="awaitingEmailVerify">
          <div className="note">
            <div className="noteBody">
              {t("email_verify_note")
                .split("/n")
                .map((l, i) => (
                  <div className="noteLine" key={i}>
                    {l}
                  </div>
                ))}
            </div>
            <Button
              onClick={handleEmailVerifyRequest}
              disabled={isLoading}
              loading={isLoading}
              label={t("send_again")}
              timer={30}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="note">
            <div className="noteBody">
              {t("add_email_note")
                .split("/n")
                .map((l, i) => (
                  <div className="noteLine" key={i}>
                    {l}
                  </div>
                ))}
            </div>
          </div>
          <div className="splitContainer">
            <div className="userPasswordContainer">
              <form
                className="styledForm phoneLogin"
                onSubmit={handleSubmit(handleSendEmail)}
              >
                <div className="formError phoneResult">{emailError}</div>
                <FormInput
                  className="styledInput alignEnd"
                  placeholder={t("email")}
                  register={register("email", {
                    required: { value: true, message: t("required_field") },
                  })}
                />

                <FormInput
                  className="styledInput alignEnd"
                  placeholder={t("password")}
                  type="password"
                  error={errors.password?.message}
                  register={register("password", {
                    required: { value: true, message: t("required_field") },
                    minLength: { value: 8, message: t("password_min_length") },
                    onBlur: () => trigger("password"),
                  })}
                />
                <FormInput
                  className="styledInput alignEnd"
                  placeholder={t("password_confirm")}
                  type="password"
                  error={errors.passwordConfirm?.message}
                  register={register("passwordConfirm", {
                    required: { value: true, message: t("required_field") },
                    validate: (value) =>
                      value === getValues("password") ||
                      (t("password_missmatch") as string),
                    onBlur: () => {
                      trigger("passwordConfirm");
                    },
                  })}
                />

                <Button
                  type="submit"
                  className="button"
                  label={t("approve")}
                  loading={isLoading}
                  disabled={!isValid}
                />
              </form>
            </div>
            {config.authProviders && (
              <>
                <div className="or">{t("or")}</div>
                <div className="providersContainer">
                  <AuthProvidersButtons providers={linkProviders} />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default EmailVerifyStep;
