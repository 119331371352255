import i18n from "../../i18n";
import {
  getDayByLang,
  getHebrewCalendar,
  getIslamicCalendar,
  getRegularCalendar,
} from "../../utils/locales";
import { ExportConfigCalendars } from "./ExportConfigTypes";

export const getDocumentDate = (
  lang: string,
  calendars: ExportConfigCalendars[],
  date: any
): string => {
  const _date = date?._seconds || date.getTime();
  let docxDate = `${i18n.t("from", { lng: lang })}${getDayByLang(_date, lang)}`;
  if (calendars.includes(ExportConfigCalendars.hebrew)) {
    docxDate = docxDate + `, ${getHebrewCalendar(_date)}`;
  }
  if (calendars.includes(ExportConfigCalendars.regular)) {
    docxDate = docxDate + `, ${getRegularCalendar(_date)}`;
  }
  if (calendars.includes(ExportConfigCalendars.muslim)) {
    docxDate = docxDate + `, ${getIslamicCalendar(_date)}`;
  }
  return docxDate;
};
