import _ from "lodash";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";

import { Word } from "../../models";
import { JobData } from "../../models/job";
import { JobRange, SpeakerRange } from "../../models/range";

import EditorService from "../../services/EditorService";
import SoundManager from "../../services/SoundManager";
import TimeService from "../../services/TimeService";
import TrackingService from "../../services/TrackingService";

import SpeakerNameEditor from "../SpeakerNameEditor/SpeakerNameEditor";
import TimerPicker from "../TimerPicker/TimerPicker";
import RangeValidation from "../../pages/Editor/components/RangeValidation";

import "./TextRange.scss";

interface Props {
  job: JobData;
  ranges: SpeakerRange[];
  range: SpeakerRange;
  rangeIndex: number;
  setFocusedRangeIndex: (i: number) => void;
  rangesCount: number;
  changeRangeSpeaker: (rangeIndex: number, speaker: string | null) => void;
  onPressEnter: (options: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    range: JobRange;
    event: React.KeyboardEvent;
  }) => void;
  mergeRange: (
    rangeIndex: number,
    words: Word[],
    mergeWithNextRange: boolean
  ) => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  isPassed: boolean;
  updateRangeWords: (rangeIndex: number, rangeWords: Word[]) => void;
  isTemporarySpeaker: boolean;
  direction: "ltr" | "rtl";
  disabled?: boolean;
  allowTimeEdit?: boolean;
}

const RangeSpeaker: FC<Props> = ({
  job,
  ranges,
  range,
  rangeIndex,
  setFocusedRangeIndex,
  rangesCount,
  changeRangeSpeaker,
  onPressEnter,
  mergeRange,
  isPassed,
  updateRangeWords,
  updateRangeTimes,
  direction,
  isTemporarySpeaker,
  disabled,
  allowTimeEdit,
}) => {
  const { t } = useTranslation();
  const [speakerName, setSpeakerName] = useState(range.speakerName);

  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [oldPlainWords, setOldPlainWords] = useState("");
  const [plainWords, setPlainWords] = useState("");
  const [speakersPaneOpen, setSpeakersPaneOpen] = useState(false);
  const [isEditingSpeakerName, setIsEditingSpeakerName] = useState(
    isTemporarySpeaker
  );

  const [isEditingTime, setIsEditingTime] = useState(false);
  const [rangeTimes, setRangeTimesState] = useState<{ [key: string]: string }>({
    start: "",
    end: "",
  });

  useEffect(() => {
    if (!range) return;
    setRangeAndPlainWords({ setOld: true });
    setRangeTimes();
  }, []);

  useEffect(() => {
    if (!range) return;
    const isFocused = document.activeElement === textInputRef.current;
    if (!isFocused || !isChanged) {
      setRangeAndPlainWords({ setOld: true });
      setSpeakerName(range.speakerName);
    }
  }, [job, range, range.words.length]);

  useEffect(() => {
    setIsEditingSpeakerName(isTemporarySpeaker);
    setSpeakerName(range.speakerName);
  }, [isTemporarySpeaker]);

  if (!range) return null;

  const setRangeAndPlainWords = ({
    rangeWords = range.words,
    isRangeEmpty = false,
    setOld = false,
  }: {
    rangeWords?: Word[];
    isRangeEmpty?: boolean;
    setOld?: boolean;
  } = {}) => {
    const rangeString = rangeWords.map((word) => word.text).join(" ");
    if (isRangeEmpty) {
      handleMergeRange();
      return;
    }
    setPlainWords(rangeString);
    if (setOld) setOldPlainWords(rangeString);
  };

  if (_.isEmpty(range.words)) return null;

  // --- Range Manipulators ---
  const handleChangeRangeSpeaker = async (speaker?: string) => {
    setSpeakerName(speaker || null);
    changeRangeSpeaker(rangeIndex, speaker || null);
  };

  const handlePressEnter = async (
    event: React.KeyboardEvent,
    selectionStart: number,
    selectionEnd?: number
  ) => {
    const updatedRangeWords = EditorService.getRangeWordsFromString(
      range.words,
      plainWords,
      oldPlainWords,
      rangesCount
    );

    const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
      selectionStart || 0,
      updatedRangeWords
    );

    onPressEnter({
      rangeIndex,
      updatedRangeWords,
      selectedWordIndex: rangeWordIndex,
      wordCharIndex,
      range,
      event,
    });
  };

  const handleMergeRange = async (mergeWithNextRange = false) => {
    const updatedRangeWords = EditorService.getRangeWordsFromString(
      range.words,
      plainWords,
      oldPlainWords,
      rangesCount
    );

    mergeRange(rangeIndex, updatedRangeWords, mergeWithNextRange);
  };

  const getSelectedWordIndex = (cursorPosition: number, words: Word[]) => {
    const trimmedWordCount = plainWords
      .trim()
      .split(" ")
      .filter((word) => word).length;
    const cursorDiff =
      cursorPosition > 0 ? getCursorFixedPositionAfterTrim(cursorPosition) : 0;
    let rangeWordIndex = 0;
    let wordCharIndex: number = cursorPosition + cursorDiff;

    while (
      wordCharIndex > 0 &&
      wordCharIndex > words[rangeWordIndex].text.length
    ) {
      const wordToCheck = words[rangeWordIndex].text.length;
      wordCharIndex = wordCharIndex - (wordToCheck + 1); // +1 for space
      rangeWordIndex++;
    }

    return { rangeWordIndex, wordCharIndex };
  };

  const getCursorFixedPositionAfterTrim = (cursorPosition: number) => {
    const isBeginningOfWord =
      cursorPosition === 0 || plainWords[cursorPosition - 1] === " ";
    const rangeFirstHalfTrimmedLength = plainWords
      .slice(0, cursorPosition)
      .trim()
      .split(" ")
      .filter((word) => word)
      .join(" ").length;
    const cursorDiff =
      rangeFirstHalfTrimmedLength +
      (isBeginningOfWord ? 1 : 0) -
      cursorPosition;
    return cursorDiff;
  };

  const focusAndSetCursor = (
    rangeIndexToFocus: number,
    cursorPosition: number
  ) => {
    const rangeToFocus = document.getElementById(
      `range-${rangeIndexToFocus}`
    ) as HTMLInputElement;
    if (rangeToFocus) {
      const cursorPositionToSet =
        cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length + 1; // +1 to set cursor after the space
      setTimeout(() => {
        rangeToFocus.setSelectionRange(
          cursorPositionToSet,
          cursorPositionToSet
        );
        rangeToFocus.focus();
      }, 0);
    } else {
      // Retry - for creating new range at the end
      setTimeout(
        () => focusAndSetCursor(rangeIndexToFocus, cursorPosition),
        10
      );
    }
  };

  // --- Range Manipulators ---

  /* --- Time Manipulators --- */

  const setRangeTimes = () => {
    try {
      const start = TimeService.getTimeStringFromSecs(range.st, false, true);
      const end = TimeService.getTimeStringFromSecs(range.et, false, true);
      setRangeTimesState({
        start,
        end,
      });
    } catch (err) {
      Sentry.captureException({
        err,
        rangesCount,
        rangeLength: range.words.length,
        rangeIndex,
      });
    }
  };

  const handleRangeTimeChange = (
    time: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    position: "start" | "end",
    method: "button" | "text"
  ) => {
    const newTime = _.isString(time) ? time : time.target.value;

    setRangeTimesState({
      ...rangeTimes,
      [position]: newTime,
    });
  };

  const handleRangeTimeBlur = (position: "start" | "end") => {
    const timeInSec = TimeService.getTimeNumberFromString(rangeTimes[position]);
    updateRangeTimes({ rangeIndex, [position]: timeInSec, method: "text" });
    setIsChanged(true);
  };

  const addSubtractRangeTime = (
    operator: "add" | "subtract",
    position: "start" | "end"
  ) => {
    let timeToAdd = SoundManager.frameLength || 0.1;
    if (operator === "subtract") {
      timeToAdd = -timeToAdd;
    }

    const updatedTimeInSecs = TimeService.getFixedFrameRateTime({
      time: range[position === "start" ? "st" : "et"],
      timeToAdd,
      frameRate: SoundManager.frameRate,
      operator,
    });

    const newTimeString = TimeService.getTimeStringFromSecs(
      updatedTimeInSecs,
      false,
      true
    );
    setRangeTimesState({
      ...rangeTimes,
      [position]: newTimeString,
    });

    updateRangeTimes({
      rangeIndex,
      [position]: updatedTimeInSecs,
      method: "button",
    });
  };

  /* --- Time Manipulators --- */

  // --- Event Handlers ---

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"])) {
      handlePressEnter(e, selectionStart);
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === 0]
      )
    ) {
      if (rangeIndex === 0 || !_.isEmpty(ranges[rangeIndex - 1].annotations))
        return;
      handleMergeRange();
      focusAndSetCursor(rangeIndex - 1, -textLength);
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === textLength, rangesCount > 1]
      )
    ) {
      // Deletion of entire range
      // deleteRange();
    }

    if (
      handleKeyboardShortcut(e, ["Delete"], [selectionStart === textLength])
    ) {
      handleMergeRange(true);
      focusAndSetCursor(rangeIndex, textLength);
    }

    if (handleKeyboardShortcut(e, ["Tab"])) {
      if (
        (e.nativeEvent.shiftKey && rangeIndex === 0) ||
        (!e.nativeEvent.shiftKey && rangeIndex === ranges.length - 1)
      ) {
        return;
      }
      const rangeIndexToFocus = e.nativeEvent.shiftKey
        ? rangeIndex - 1
        : rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    }

    if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowLeft") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowRight") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey], false)) {
      updateRanges();
    }

    if (handleKeyboardShortcut(e, ["KeyD"], [e.ctrlKey])) {
      updateRanges();

      setSpeakersPaneOpen(!speakersPaneOpen);
    }

    if (handleKeyboardShortcut(e, ["KeyX"], [e.ctrlKey || e.metaKey])) {
      return;
    }

    // -- Editor Shortcuts --
  };

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!e.altKey) return;
    let { selectionStart } = e.target as HTMLTextAreaElement;

    const editedWords = plainWords.split(" ");
    const lengths = editedWords.map((word) => word.length);

    let clickedWord = -1;
    while (selectionStart > 0) {
      clickedWord++;
      selectionStart = selectionStart - lengths[clickedWord] - 1;
    }
    if (selectionStart === 0) clickedWord++;

    if (range.words && range.words[clickedWord]) {
      SoundManager.setOffset(range.words[clickedWord].start);
    }
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocusedRangeIndex(rangeIndex);

    TrackingService.reportEvent("text_edit_start", {}, job);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputString = e.target.value === "" ? "." : e.target.value;

    setIsChanged(true);
    setPlainWords(newInputString);
    setFocusedRangeIndex(rangeIndex);
  };

  const handleBlur = async () => {
    setFocusedRangeIndex(-1);
    updateRanges();
  };

  const updateRanges = () => {
    if (isChanged) {
      const updatedRangeWords = EditorService.getRangeWordsFromString(
        range.words,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      updateRangeWords(rangeIndex, updatedRangeWords);
      setOldPlainWords(plainWords);
    }

    TrackingService.reportEvent(
      "text_edit_end",
      { text_changed: isChanged },
      job
    );
  };

  return (
    <div
      className={classNames("textRange", "protocol", direction, {
        error: !_.isEmpty(range.validation?.errors),
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
    >
      <div
        className={classNames("rangeTimes protocol", {
          overlapping:
            range.validation?.errors?.overlapping_start_prev ||
            range.validation?.errors?.overlapping_start_next ||
            range.validation?.errors?.start_after_end,
        })}
      >
        <div className="speakerBlockContainer">
          <div className="speakerNameContainer">
            <SpeakerNameEditor
              speakerName={speakerName}
              speakers={job.speakers.filter(
                (s) =>
                  s !==
                  (isTemporarySpeaker ? "previousSpeakerName" : speakerName)
              )}
              handleSetNewSpeakerName={(speaker) => {
                handleChangeRangeSpeaker(speaker);
                setSpeakerName(speaker);
                setIsEditingSpeakerName(false);
                focusAndSetCursor(rangeIndex, 0);
                return true;
              }}
              cancelEdit={() => {
                handleChangeRangeSpeaker();
                setIsEditingSpeakerName(false);
                focusAndSetCursor(rangeIndex, 0);
              }}
              placeholder={t("choose_speaker")}
              isEditing={isEditingSpeakerName}
            />
          </div>
          <div className="speakerTime">
            {isEditingTime ? (
              <TimerPicker
                className={classNames({
                  ltr: direction === "ltr",
                  overlapping:
                    range.validation?.errors?.overlapping_start_prev ||
                    range.validation?.errors?.overlapping_start_next ||
                    range.validation?.errors?.start_after_end ||
                    range.validation?.errors?.out_of_range,
                })}
                value={range.st}
                handleChange={(time: string) =>
                  handleRangeTimeChange(time, "start", "text")
                }
                handleBlur={() => handleRangeTimeBlur("start")}
                step={SoundManager.frameLength || 0.1}
                deductTime={() => addSubtractRangeTime("subtract", "start")}
                addTime={() => addSubtractRangeTime("add", "start")}
                disabled={disabled}
              />
            ) : (
              <span
                className={classNames("startTime", { allowTimeEdit })}
                onClick={() => {
                  if (allowTimeEdit) {
                    setIsEditingTime(true);
                  }
                }}
              >
                {TimeService.getTimeStringFromSecs(range.st, false, true)}
              </span>
            )}
          </div>
          {job.speakers && !_.isEmpty(job.speakers) && (
            <SpeakerList
              speakers={job.speakers}
              currentSpeaker={speakerName}
              onSelect={handleChangeRangeSpeaker}
              isOpen={speakersPaneOpen}
              close={() => setSpeakersPaneOpen(false)}
            />
          )}
        </div>
      </div>

      <div className="rangeText">
        <div className={classNames("textContainer", { disabled })}>
          {!disabled && (
            <textarea
              id={`range-${rangeIndex}`}
              ref={textInputRef}
              className="textRangeField"
              value={plainWords}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              onClick={handleClick}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          )}
          <div className="dummyRange">{plainWords}</div>
          {range.validation && (
            <RangeValidation
              validation={range.validation}
              direction={direction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SpeakerList: FC<{
  speakers: string[];
  currentSpeaker: string | null;
  onSelect: (speaker: string) => void;
  isOpen: boolean;
  close: () => void;
}> = ({ speakers, currentSpeaker, onSelect, isOpen, close }) => {
  const { t } = useTranslation();
  const speakerListRef = useRef<HTMLDivElement>(null);

  const [isCreateNewSpeakerOpen, setIsCreateNewSpeakerOpen] = useState(false);
  const [filteredSpeakers, setFilteredSpeakers] = useState<string[]>([]);

  useEffect(() => {
    setFilteredSpeakers(speakers.filter((s) => s !== currentSpeaker));
  }, []);

  const handleSetNewSpeakerName = (newSpeakerName: string) => {
    onSelect(newSpeakerName);
    setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
    close();
    return true;
  };

  const handleCancelEdit = () => {
    setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
    close();
  };

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!speakerListRef.current?.contains(event.target as Node)) {
        close();
      }
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div className="speakerListContainer" ref={speakerListRef}>
      <div className={classNames("speakerList", { open: isOpen })}>
        {filteredSpeakers.map((speaker, i) => (
          <div
            className="speaker"
            onClick={() => {
              onSelect(speaker);
              close();
            }}
            key={i}
          >
            {speaker}
          </div>
        ))}
        {!isCreateNewSpeakerOpen ? (
          <div
            className="addSpeaker"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
            }}
          >
            <span>+</span>
            <span>{t("create_new_speaker")}</span>
          </div>
        ) : (
          <SpeakerNameEditor
            speakers={filteredSpeakers}
            handleSetNewSpeakerName={handleSetNewSpeakerName}
            cancelEdit={handleCancelEdit}
            placeholder={t("create_new_speaker")}
            isEditing={true}
          />
        )}
      </div>
    </div>
  );
};

export default RangeSpeaker;
