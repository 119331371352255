import "date-fns";
import React, { FC, useState, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import "./Datepicker.scss";

interface Props {
  className?: string;
  placeholder: string;
  onChange: (value: Date) => void;
}

const Datepicker: FC<Props> = ({ className, onChange, placeholder }) => {
  const { t } = useTranslation();
  const mainElement = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>();
  const [selectedDateLabel, setSelectedDateLabel] = useState<string>(
    placeholder
  );

  const handleDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    setSelectedDateLabel(value as string);
    onChange(date as Date);
    setSelectedDate(date);
    setIsOpen(false);
  };

  return (
    <div className={classNames("Datepicker", className, { isOpen })}>
      <div
        className={classNames("selectedLabel", { placeholder: !selectedDate })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedDateLabel}
      </div>
      <div className={classNames("calendarContainer")}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            todayLabel
            variant="dialog"
            DialogProps={{ className: "ltr" }}
            format="dd/MM/yyyy/hh:mm"
            cancelLabel={
              <div onClick={() => setIsOpen(false)}>{t("cancel")}</div>
            }
            okLabel={t("approve")}
            ampm={false}
            id="date-picker-inline"
            label="Date picker inline"
            open={isOpen}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

export default Datepicker;
