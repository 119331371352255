import React from "react";
import { useTranslation } from "react-i18next";

import {
  fontSizeOptions,
  SettingPagesProps,
  TasksPageSlice,
} from "../ExportConfigTypes";

import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

import "./../SettingPages.scss";

export const TasksPage = ({ form }: SettingPagesProps<TasksPageSlice>) => {
  const { t } = useTranslation();

  const tasksWatch: TasksPageSlice = form?.watch() as TasksPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_tasks_title")}:</div>
            <ControllerHOC control={control} name="tasks.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_tasks_explanations_1")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="tasks.font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!tasksWatch?.tasks?.isActive}
              />
            </ControllerHOC>

            <ControllerHOC
              control={control}
              name="tasks.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!tasksWatch?.tasks?.isActive}
              />
            </ControllerHOC>

            <ControllerHOC
              control={control}
              name="tasks.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!tasksWatch?.tasks?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="tasks.font.color"
            >
              <ColorPicker disabled={!tasksWatch?.tasks?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_tasks_gather_tasks")}:</div>
            <ControllerHOC control={control} name="gatherTasks.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_tasks_explanation_2")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="gatherTasks.font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!tasksWatch?.gatherTasks?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="gatherTasks.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!tasksWatch?.gatherTasks?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="gatherTasks.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!tasksWatch?.gatherTasks?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="gatherTasks.font.color"
            >
              <ColorPicker disabled={!tasksWatch?.gatherTasks?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
