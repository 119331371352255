import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { UseFormSetValue } from "react-hook-form";

import "./TextDecorationSelection.scss";

export enum TextDecoration {
  bold = "bold",
  italic = "italic",
  underline = "underline",
}

export interface TextDecorationSelectionProps {
  controller?: {
    setValue: UseFormSetValue<any>;
    name: any;
    field: any;
  };
  disabled?: boolean;
}

export const TextDecorationSelection = ({
  controller,
  disabled,
}: TextDecorationSelectionProps) => {
  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (controller) {
      controller.setValue(controller.name, newValue);
    }
  };

  const textDecorationChildren = [
    <ToggleButton
      disabled={disabled}
      value={TextDecoration.bold}
      key={TextDecoration.bold}
    >
      <span className="selectionContent">
        <FontAwesomeIcon icon="bold"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
    <ToggleButton
      disabled={disabled}
      value={TextDecoration.underline}
      key={TextDecoration.underline}
    >
      <span className="selectionContent">
        <FontAwesomeIcon icon="underline"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
    <ToggleButton
      disabled={disabled}
      value={TextDecoration.italic}
      key={TextDecoration.italic}
    >
      <span className="selectionContent">
        <FontAwesomeIcon icon="italic"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
  ];

  return (
    <div className="TextDecorationSelection">
      <ToggleButtonGroup
        {...controller?.field}
        size="small"
        onChange={handleValueChange}
      >
        {textDecorationChildren}
      </ToggleButtonGroup>
    </div>
  );
};
