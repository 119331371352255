import React, { createContext, FC, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Logger from "../../services/Logger";
import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Switch } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DocumentReference } from "@firebase/firestore-types";
import { AppState } from "../../store/rootReducer";
import { getProofers, getTranscribers } from "../../store/user/actions";
import {
  updateArchivedMeeting,
  setArchivedMeetings,
  setArchiveTree,
  updateMeetingRepresentative,
  updateMeetingInvoiceSent,
  updateMeetingRevenue,
} from "../../store/archive/actions";
import {
  setLoadingReason,
  resetSorting,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import { ArchivedMeetingData, ArchiveFormatedJobs } from "../../models/meeting";
import { MeetingPreviewData } from "../../models";

import PageHeader from "../../components/PageHeader/PageHeader";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import MeetingsTable from "../../components/MeetingsTable/MeetingsTable";
import EditableText from "../../components/EditableText/EditableText";
import ContextMenu from "./ContextMenu";
import ExportModal from "../../components/common/ExportModal/ExportModal";

import TimeService from "../../services/TimeService";
import FirebaseService from "../../services/FirebaseService";
import MeetingService from "../../services/MeetingService";

import { MinimalProofer, MinimalTranscriber } from "../../models/user";
import { clearMeetings } from "../../store/meeting/actions";

import { getJobsUrl } from "../../utils/url";

import "./ArchivePage.scss";

const logger = Logger("ArchivePage");

const ArchivePage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const archivedMeetings = useSelector(
    (state: AppState) => state.archiveStore.archivedMeetings
  );
  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const proofers = useSelector((state: AppState) => state.userStore.proofers);

  const sorting = useSelector((state: AppState) => state.systemStore.sorting);

  const [searchBy, setSearchBy] = useState<string>("");
  const [currPath, setCurrPath] = useState<string[]>([]);
  const [meetingsToPreview, setMeetingsToPreview] = useState<
    ArchivedMeetingData[]
  >([]);
  const [isFolderForm, setIsFolderForm] = useState<boolean>(false);
  const [isExportBtnsDDOpen, setIsExportBtnsDDOpen] = useState(false);
  const [exportRefsBtn, setExportRefsBtn] = useState<HTMLButtonElement[]>([]);
  const [meetingsTableData, setMeetingsTableData] = useState<
    ArchiveFormatedJobs[]
  >([]);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<
    { id: string; name: string }[] | undefined
  >();
  const archivePageRef = useRef(null);

  const validRoles = ["super_user"];
  useEffect(() => {
    if (loggedInUser) {
      dispatch(getTranscribers());
      dispatch(getProofers());
      if (!validRoles.includes(loggedInUser.role)) {
        history.push("/");
        return;
      }
      loadArchiveData();
      if (sorting.type !== "archive") dispatch(resetSorting("archive"));
    }
    return () => {
      dispatch(setArchivedMeetings([]));
      dispatch(setArchiveTree(null));
    };
  }, [loggedInUser]);

  const reFormatMeetings = () => {
    if (loggedInUser) {
      const formatMeeting = async () => {
        const formatMeetings = await getFormatMeetingsTable(
          //@ts-ignore
          archivedMeetings,
          transcribers,
          proofers
        );
        if (formatMeetings) {
          //@ts-ignore
          setMeetingsTableData(formatMeetings);
        }
      };
      formatMeeting();
    }
  };

  useEffect(reFormatMeetings, [archivedMeetings]);

  const loadArchiveData = async () => {
    try {
      if (loggedInUser) {
        dispatch(setLoadingReason(t("loading_archived_meetings")));
        const meetings = await FirebaseService.getArchivedMeetings();
        const formatMeetings = await getFormatMeetingsTable(
          meetings,
          transcribers,
          proofers
        );
        if (formatMeetings) {
          setMeetingsTableData(formatMeetings);
        }
        //@ts-ignore
        dispatch(setArchivedMeetings(meetings));
        dispatch(clearLoadingReason());
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const goBackOnPath = () => {
    const pathClone = [...currPath];
    pathClone.pop();
    setCurrPath(pathClone);
  };

  const getHeaderBackButton = () => {
    if (isFolderForm) {
      if (currPath.length > 0)
        return {
          title: currPath[currPath.length - 1],
          backAction: () => setIsFolderForm(false),
        };
      else
        return {
          title: t("archive"),
          backAction: () => setIsFolderForm(false),
        };
    } else {
      if (currPath.length > 0)
        return {
          title:
            currPath.length > 1
              ? currPath.slice(0, currPath.length - 1).join("/")
              : t("archive"),
          backAction: () => goBackOnPath(),
        };
    }
  };

  const getHeaderTitle = () => {
    if (isFolderForm) return t("new_folder");
    else {
      if (currPath.length === 0) return t("archive");
      else return currPath[currPath.length - 1];
    }
  };

  const headerBackButton = getHeaderBackButton();
  const headerTitle = getHeaderTitle();

  const getFormatMeetingsTable = async (
    meetings: ArchivedMeetingData[],
    transcribers: MinimalTranscriber[],
    proofers: MinimalProofer[]
  ): Promise<ArchiveFormatedJobs[] | undefined> => {
    try {
      const formatMeetings = await Promise.all(
        meetings.map(async (meeting) => {
          const {
            id,
            name,
            status,
            deliveredToClient,
            assignedProofer,
            representative,
            invoiceSent,
            revenue,
            archivedAt,
            meetingLength,
            ownerId,
            ownerName,
            assignedTranscriber,
            previewFormat,
            processProgressLastUpdate,
            price,
            prooferPrice,
          } = meeting;
          const prooferId = assignedProofer?.id;
          const prooferName = prooferId
            ? MeetingService.getProoferFromProofers(
                prooferId,
                proofers,
                "username"
              )
            : t("no_proofer");
          const transcriberId = assignedTranscriber?.id;
          const transName = transcriberId
            ? MeetingService.getTranscriberFromTranscribers(
                transcriberId,
                transcribers,
                "username"
              )
            : t("no_transcriber");
          const meetingObj = {
            id,
            name,
            meetingLength: meetingLength
              ? TimeService.getTimeStringFromSecs(meetingLength)
              : "-",
            deliveredToClient,
            processProgressLastUpdate,
            archivedAt,
            invoiceSent: invoiceSent,
            ownerId,
            status,
            representative: representative
              ? representative
              : t("no_representative"),
            revenue: revenue ? revenue : "-",
            clientName: ownerName === "no_owner" ? t("no_client") : ownerName,
            assignedTranscriber: transName ? transName : t("no_transcriber"),
            assignedProofer: prooferName ? prooferName : t("no_proofer"),
            previewFormat,
            price,
            prooferPrice,
            contextMenu: (
              <ContextMenu
                menuItems={[
                  {
                    onClick: () => {
                      setSelectedMeeting([{ id, name }]);
                      setOpenExportModal(true);
                    },
                    text: t("export"),
                  },
                ]}
              ></ContextMenu>
            ),
          };
          return meetingObj;
        })
      );
      return formatMeetings;
    } catch (err) {
      console.log("getFormatMeetingsTable", err);
    }
  };
  const saveRefAtParent = (element: HTMLButtonElement) => {
    const temp = exportRefsBtn;
    temp.push(element);
    setExportRefsBtn(temp);
  };

  const goToMeeting = (
    meeting: { [key: string]: any },
    event: React.MouseEvent
  ) => {
    const jobUrl = getJobsUrl(meeting, loggedInUser);
    if (event.ctrlKey || event.metaKey) {
      window.open(jobUrl, "_blank");
      return;
    }

    history.push(jobUrl);
    dispatch(clearMeetings());
  };

  const revenueUpdate = async (
    newValue: number | string,
    meeting: MeetingPreviewData
  ) => {
    try {
      const newRevenue = Number(newValue);
      if (_.isNaN(newRevenue)) return false;
      await FirebaseService.changeRevenue(meeting.id, newRevenue);
      dispatch(updateMeetingRevenue(meeting.id, newRevenue));
    } catch (error) {
      dispatch(popIndicator({ type: "failure", txt: t("updating_fail") }));
      logger.error(error, "revenueUpdate");
    }
    return true;
  };

  const invoiceSentUpdate = async (
    value: string,
    meeting: MeetingPreviewData
  ) => {
    try {
      dispatch(popIndicator({ type: "info", txt: t("update_invoice") }));
      await FirebaseService.changeInvoiceSent(meeting.id, value);
      dispatch(popIndicator({ type: "sucsess", txt: t("invoice_updated") }));
      dispatch(updateMeetingInvoiceSent(meeting.id, value));
    } catch (error) {
      dispatch(
        popIndicator({ type: "failure", txt: t("update_invoice_failed") })
      );
      console.log(error);
    }
    return true;
  };

  const representativeUpdate = async (
    newValue: string,
    meeting: MeetingPreviewData
  ) => {
    try {
      await FirebaseService.changeRepresentative(meeting.id, newValue);
      dispatch(updateMeetingRepresentative(meeting.id, newValue));
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  return (
    <>
      {loggedInUser && (
        <main ref={archivePageRef} className="main-container">
          <PageHeader title={headerTitle} backButton={headerBackButton} />
          {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
          {!!errorReason && <ErrorModal errorReason={errorReason} />}
          {!loadingReason && !errorReason && (
            <div className="meetings-page flex column align-center">
              {archivedMeetings.length > 0 && (
                <div className="meetingsTable">
                  <MeetingsTable
                    data={meetingsTableData}
                    additionalSearchFields={["archivedAt", "clientName"]}
                    onRowClick={goToMeeting}
                    config={{
                      multiSelect: true,
                      actions: {
                        exportSubtitles: {
                          label: "export-subtitles",
                          icon: (
                            <FontAwesomeIcon icon={["far", "file-download"]} />
                          ),
                          action: (slectedMeetings) => {
                            setOpenExportModal(true);
                            setSelectedMeeting(
                              slectedMeetings.map((slectedMeeting) => {
                                return {
                                  id: slectedMeeting.id,
                                  name: slectedMeeting.name,
                                };
                              })
                            );
                          },
                          bulk: false,
                          hidden: false,
                        },
                      },
                      tableColumns: [
                        "id",
                        "name",
                        "status",
                        "deliveredToClient",
                        "assignedProofer",
                        "representative",
                        "invoiceSent",
                        "revenue",
                        "archivedAt",
                        "meetingLength",
                        "ownerId",
                        "ownerName",
                        "assignedTranscriber",
                        "previewFormat",
                        "processProgressLastUpdate",
                        "price",
                        "prooferPrice",
                        "contextMenu",
                      ],
                      tableName: t("archive"),
                      columns: {
                        status: { hidden: true },
                        price: { hidden: true },
                        prooferPrice: { hidden: true },
                      },
                      formatters: {
                        assignedTranscriber: (transcriber, meeting) =>
                          `(${t("currency")}${meeting.price}) ${transcriber}`,
                        assignedProofer: (proofer, meeting) =>
                          `(${t("currency")}${
                            meeting.prooferPrice
                          }) ${proofer}`,
                        representative: (representative: string, meeting) => (
                          <EditableText
                            type="text"
                            showIcon={true}
                            isEditable={loggedInUser?.role === "super_user"}
                            value={representative}
                            placeholder={
                              (representative
                                ? representative
                                : t("enter_representative")) as string
                            }
                            handleSetNewValue={async (newValue) => {
                              if (representativeUpdate) {
                                return await representativeUpdate(
                                  newValue as string,
                                  meeting
                                );
                              }
                              return false;
                            }}
                          />
                        ),
                        revenue: (revenue: number, meeting) => (
                          <EditableText
                            type="number"
                            showIcon={true}
                            prefix={t("currency")}
                            isEditable={loggedInUser?.role === "super_user"}
                            value={revenue}
                            placeholder={revenue}
                            handleSetNewValue={async (newValue) =>
                              await revenueUpdate(newValue, meeting)
                            }
                          />
                        ),
                        invoiceSent: (invoiceSent: string, meeting) => (
                          <div
                            className={classNames("invoiceSent", invoiceSent)}
                            onClick={(e) => {
                              e.stopPropagation();
                              invoiceSentUpdate &&
                                invoiceSentUpdate(invoiceSent, meeting);
                            }}
                          >
                            {t(invoiceSent)}
                          </div>
                        ),
                      },
                    }}
                  />
                </div>
              )}
              {archivedMeetings.length === 0 && (
                <EmptyPageComp title={t("no_archived_meetings")} />
              )}
            </div>
          )}
          <ExportModal
            isOpen={openExportModal}
            setOpen={setOpenExportModal}
            meetings={selectedMeeting}
            saveMeeting={false}
          ></ExportModal>
        </main>
      )}
    </>
  );
};

export default ArchivePage;
