import { combineReducers } from "redux";
import systemReducer from "./system/reducer";
import userReducer from "./user/reducer";
import clientReducer from "./client/reducer";
import paymentReducer from "./payment/reducer";
import meetingReducer from "./meeting/reducer";
import archiveReducer from "./archive/reducer";
import roomReducer from "./room/reducer";

const rootReducer = combineReducers({
  systemStore: systemReducer,
  userStore: userReducer,
  clientStore: clientReducer,
  paymentStore: paymentReducer,
  meetingStore: meetingReducer,
  archiveStore: archiveReducer,
  roomStore: roomReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
