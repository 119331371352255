export default [
  {
    id: "_w9qlh3gjcu",
    name: "kan_mil1.mp4",
    assignedTranscriber: null,
    speakers: [],
    assignedMethod: null,
    isApproved: false,
    assignedProofer: null,
    deadline: new Date(
      "Thu Nov 04 2021 10:31:57 GMT+0200 (Israel Standard Time)"
    ),
    creationTime: new Date(
      "Thu Nov 04 2021 10:31:57 GMT+0200 (Israel Standard Time)"
    ),
    meetingLength: 277.696,
    lang: {
      input: "he-IL",
      output: "he-IL",
    },
    price: 120,
    status: 3,
    processProgress: 100,
    processProgressLastUpdate: "2021-11-03T08:37:36.501Z",
  },
  {
    id: "_sun4gsrxv",
    name: "שלב מכוון.mp4",
    assignedTranscriber: null,
    speakers: [],
    assignedMethod: null,
    isApproved: false,
    assignedProofer: null,
    deadline: new Date(
      "Thu Nov 04 2021 10:31:57 GMT+0200 (Israel Standard Time)"
    ),
    creationTime: new Date(
      "Thu Nov 04 2021 10:31:57 GMT+0200 (Israel Standard Time)"
    ),
    meetingLength: 277.696,
    lang: {
      input: "he-IL",
      output: "he-IL",
    },
    price: 120,
    status: 3,
    processProgress: 100,
    processProgressLastUpdate: "2021-11-03T08:37:36.501Z",
  },
];
