import { getSettings } from "../../services/SettingService";
import {
  UserState,
  UserActionTypes,
  SET_LOGGED_IN_USER,
  SET_LOGGED_IN_USER_INFO,
  CLEAR_LOGGED_IN_USER_INFO,
  CLEAR_LOGGED_IN_USER,
  PUSH_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  SET_TRANSCRIBERS,
  SET_CURR_TRANSCRIBER,
  SET_PROOFERS,
  CLEAR_CURR_TRANSCRIBER,
  CLEAR_TRANSCRIBERS,
  UPDATE_TRANSCRIBER_ROLE,
  UPDATE_SETTINGS,
  GET_TRANSCRIBERS,
  GET_PROOFERS,
  SET_TRANSCRIBERS_OPTIONS,
  SET_PROOFERS_OPTIONS,
} from "./types";
import { TranscriberPreviewData } from "../../models/user";

const initialState: UserState = {
  loggedInUser: null,
  loggedInUserInfo: null,
  userNotifications: [],
  transcribers: [],
  proofers: [],
  transcribersOptions: [],
  proofersOptions: [],
  currTranscriber: null,
  settings: getSettings(),
};

function userReducer(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case SET_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: { ...action.user },
      };
    case CLEAR_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: null,
      };
    case SET_LOGGED_IN_USER_INFO:
      return {
        ...state,
        loggedInUserInfo: { ...action.loggedInUserInfo },
      };
    case CLEAR_LOGGED_IN_USER_INFO:
      return {
        ...state,
        loggedInUserInfo: null,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        userNotifications: [...action.notifications],
      };
    case PUSH_NOTIFICATION:
      return {
        ...state,
        userNotifications: [action.notification, ...state.userNotifications],
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: [],
      };
    case SET_TRANSCRIBERS:
      return {
        ...state,
        transcribers: action.transcribers,
      };
    case SET_PROOFERS:
      return {
        ...state,
        proofers: action.proofers,
      };
    case GET_TRANSCRIBERS:
      return {
        ...state,
        transcribers: action.transcribers,
      };
    case SET_TRANSCRIBERS_OPTIONS:
      return {
        ...state,
        transcribersOptions: action.transcribersOptions,
      };
    case SET_PROOFERS_OPTIONS:
      return {
        ...state,
        proofersOptions: action.proofersOptions,
      };
    case GET_PROOFERS:
      return {
        ...state,
        proofers: action.proofers,
      };
    case UPDATE_TRANSCRIBER_ROLE:
      return {
        ...state,
        transcribers: state.transcribers.map((t: TranscriberPreviewData) => {
          if (t.id === action.transcriber.id) {
            const newTranscriber = { ...t };
            newTranscriber.role = action.newRole;
            return newTranscriber;
          } else {
            return t;
          }
        }),
      };
    case CLEAR_TRANSCRIBERS:
      return {
        ...state,
        transcribers: [],
      };
    case SET_CURR_TRANSCRIBER:
      return {
        ...state,
        currTranscriber: action.transcriber,
      };
    case CLEAR_CURR_TRANSCRIBER:
      return {
        ...state,
        currTranscriber: null,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.settings },
      };
    default:
      return state;
  }
}

export default userReducer;
