import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import MessageModal from "../../MessageModal/MessageModal";
import Input from "../../common/Input/Input";

import "./SplitModal.scss";

interface Props {
  jobLength: number;
  splitJob: (splitCount: number | "") => void;
  showModal: boolean;
  closeModal: () => void;
}

const SplitModalModal: FC<Props> = ({
  jobLength,
  splitJob,
  showModal,
  closeModal,
}): JSX.Element => {
  const { t } = useTranslation();

  const [splitCount, setSplitCount] = useState<number | "">("");

  return (
    <MessageModal
      className="jobSplitModal warning"
      title={t("split_job")}
      body={
        <div className="bodyContainer">
          <div className="note">
            {t("modal_split_warning", {
              splitCount: `1-${(jobLength / 60 - 1).toFixed(0)}`,
            })}
          </div>
          <div className="countSelect">
            <Input
              value={splitCount}
              placeholder={Math.ceil(jobLength / 60 / 30)}
              type="number"
              min={1}
              max={Number((jobLength / 60 - 1).toFixed(0))}
              onChange={(e) =>
                setSplitCount(
                  e.currentTarget.value ? Number(e.currentTarget.value) : ""
                )
              }
            />
            <div className="splitLength">
              {splitCount &&
                t("modal_split_count_calc", {
                  splitLength: Math.ceil(jobLength / 60 / splitCount),
                })}
            </div>
          </div>
        </div>
      }
      showModal={showModal}
      approve={{ text: t("approve"), action: () => splitJob(splitCount) }}
      cancel={{ text: t("cancel"), action: closeModal }}
    />
  );
};

export default SplitModalModal;
