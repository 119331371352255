import React, { FC } from "react";
import "./AddEntityCard.scss";

interface Props {
  text: string;
  subContent?: string;
  onClick: () => void;
}

const AddEntityCard: FC<Props> = ({
  text,
  subContent,
  onClick,
}): JSX.Element => {
  return (
    <>
      <div onClick={() => onClick()} className="add-entity-card">
        <div className="status-bar"></div>
        <div className="content-container">
          <div className="content">
            <p>{text}</p>
            {subContent && <p className="sub">{subContent}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEntityCard;
