import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Debt1 } from "../../models/payment";
import TextInput from "../FormInputs/TextInput";
import SubmitButton from "../SubmitButton/SubmitButton";

import "./AddDebtForm.scss";

interface Props {
  sendDebt: (debt: Debt1) => void;
  onSuccess?: () => void;
  disabled?: boolean;
  userId: string;
}

const AddDebtForm: FC<Props> = ({
  sendDebt,
  userId,
  disabled,
}): JSX.Element => {
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>("");
  const [sum, setSum] = useState<number>(0);
  const [date, setDate] = useState<Date | string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (_.isUndefined(disabled)) return;
    setIsDisabled(disabled);
  }, [disabled]);

  const submitNewDebt = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsDisabled(true);
    const newDebt: Debt1 = {
      userId,
      creationTime: new Date(),
      description,
      sum,
      date: new Date(date),
      status: "unpaid",
    };
    await sendDebt(newDebt);
  };

  return (
    <div className="add-debt-form-wrapper">
      <form
        className="add-debt-form"
        onSubmit={(e) => submitNewDebt(e)}
        action=""
      >
        <TextInput
          type={"text"}
          className={"name"}
          onInput={setDescription}
          width={408}
          placeholder={t("payment_reason")}
          required={true}
          Icn={["fal", "user"]}
          disabled={isDisabled}
        />
        <TextInput
          type={"number"}
          className={"mail"}
          onInput={(value) => setSum(parseInt(value))}
          width={232}
          placeholder={t("payment_sum")}
          required={true}
          Icn={["fal", "shekel-sign"]}
          disabled={isDisabled}
        />
        <TextInput
          type={"date"}
          className={"date"}
          onInput={setDate}
          width={300}
          placeholder={t("payment_sum")}
          required={true}
          Icn={["fal", "calendar"]}
          disabled={isDisabled}
        />
        <SubmitButton
          text={t("approve")}
          Icn={["fal", "shekel-sign"]}
          disabled={isDisabled}
        />
      </form>
    </div>
  );
};

export default AddDebtForm;
