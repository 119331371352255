import React, { useEffect, useState } from "react";
import gematriya from "gematriya";
import i18n from "../../i18n";
import _ from "lodash";

import { getRangesForDocxExport } from "../../services/EditorService";

import { JobData } from "../../models/job";
import { Transcript, TranscriptType } from "../../models";

import { Align } from "../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextDecoration } from "../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

import { PreviewPage } from "./Preview/PreviewPage";
import { ProtocolRanges } from "./Preview/ProtocolRanges";

import { PageList } from "../../components/common/V3/PagerMenu/PagerMenu";

import {
  DecisionsPageSlice,
  ExportConfigFontSettings,
  FocusedDocumentPageSlice,
  GeneralPageSlice,
  HeaderFooterPageSlice,
  ImagesPageSlice,
  InformationPageSlice,
  ListType,
  NotesPageSlice,
  RemarksPageSlice,
  SpeakersPageSlice,
  SubjectsPageSlice,
  TableOfSubjectsPageSlice,
  TasksPageSlice,
  ExportConfigData,
} from "./ExportConfigTypes";

import "./Preview.scss";

export interface PreviewItem {
  content?: JSX.Element | string | number | Date;
  align: string;
  show: boolean;
  cssProps?: React.CSSProperties;
}

export interface PreviewProps {
  pageList: PageList;
  jobData: JobData;
  docDir: "ltr" | "rtl";
}

export const getFlexDirection = (docDir: "rtl" | "ltr", align: Align) => {
  switch (align) {
    case Align.center:
      return "center";
    case Align.justify:
      return "justify";
    case Align.left:
      return docDir === "ltr" ? "flex-start" : "flex-end";
    case Align.right:
      return docDir === "rtl" ? "flex-start" : "flex-end";
    default:
      return "flex-start";
  }
};

export const getTextStyling = (
  font: ExportConfigFontSettings
): React.CSSProperties => {
  const textStyle: React.CSSProperties = {
    fontStyle: font?.textDecoration?.includes(TextDecoration.italic)
      ? "italic"
      : "inherit",
    textDecoration: font?.textDecoration?.includes(TextDecoration.underline)
      ? "underline"
      : "auto",
    fontWeight: font?.textDecoration?.includes(TextDecoration.bold)
      ? "bold"
      : "normal",
    color: font?.color ? font.color : "inherit",
    fontSize: font?.fontSize ? font.fontSize + "pt" : "inherit",
    fontFamily: font?.fontFamily ? font.fontFamily : "inherit",
  };
  return textStyle;
};

export const getListClassName = (sign: ListType) => {
  switch (sign) {
    case ListType.hebrew:
      return "hebrew";
    case ListType.brackets:
      return "brackets";
    case ListType.minus:
      return "minus";
    default:
      return "none";
  }
};

export const getListNumberBySign = (sign: ListType, number: number): string => {
  switch (sign) {
    case ListType.hebrew:
      return `${gematriya(number).replace("׳", "")}. `;
    case ListType.brackets:
      return `[ ${number} ] `;
    case ListType.minus:
      return `- ${number} - `;
    default:
      return "";
  }
};

export const Preview = ({ pageList, jobData, docDir }: PreviewProps) => {
  const documentLayoutInitial: React.CSSProperties = {
    width: "21cm",
    height: "29.7cm",
  };

  const [ranges, setRanges] = useState<Transcript | null>(null);
  const [documentLayout, setdocumentLayout] = useState<React.CSSProperties>(
    documentLayoutInitial
  );

  //WATCHERS
  const generalWatch: GeneralPageSlice = pageList.general.form.watch() as GeneralPageSlice;
  const headerFooterWatch: HeaderFooterPageSlice = pageList.headerFooter.form.watch() as HeaderFooterPageSlice;
  const informationWatch: InformationPageSlice = pageList.information.form.watch() as InformationPageSlice;
  const tableOfSubjectsWatch: TableOfSubjectsPageSlice = pageList.tableOfSubjects.form.watch() as TableOfSubjectsPageSlice;
  const remarksWatch: RemarksPageSlice = pageList.remarks.form.watch() as RemarksPageSlice;
  const subjectsWatch: SubjectsPageSlice = pageList.subjects.form.watch() as SubjectsPageSlice;
  const speakersWatch: SpeakersPageSlice = pageList.speakers.form.watch() as SpeakersPageSlice;
  const decisionsWatch: DecisionsPageSlice = pageList.decisions.form.watch() as DecisionsPageSlice;
  const notesWatch: NotesPageSlice = pageList.notes.form.watch() as NotesPageSlice;
  const tasksWatch: TasksPageSlice = pageList.tasks.form.watch() as TasksPageSlice;
  const imagesWatch: ImagesPageSlice = pageList.images.form.watch() as ImagesPageSlice;
  const focusedDocument: FocusedDocumentPageSlice = pageList.focusedDocument.form.watch() as FocusedDocumentPageSlice;

  const settings: ExportConfigData = {
    general: generalWatch,
    headerFooter: headerFooterWatch,
    information: informationWatch,
    tableOfSubjects: tableOfSubjectsWatch,
    remarks: remarksWatch,
    subjects: subjectsWatch,
    speakers: speakersWatch,
    decisions: decisionsWatch,
    notes: notesWatch,
    tasks: tasksWatch,
    images: imagesWatch,
    focusedDocument: focusedDocument,
  };

  useEffect(() => {
    if (!docDir) return;
    setdocumentLayout({ ...documentLayout, direction: docDir });
  }, [docDir]);

  useEffect(() => {
    if (!jobData) return;
    const newTranscripts = getRangesForDocxExport(jobData);
    setRanges(newTranscripts);
  }, [jobData]);

  return (
    <div
      className="PreviewPageWrapper"
      style={{
        justifyContent: getFlexDirection(
          docDir,
          generalWatch?.font?.align ? generalWatch?.font?.align : Align.justify
        ),
        ...getTextStyling(generalWatch.font),
      }}
    >
      <PreviewPage
        jobData={jobData}
        settings={settings}
        pageNumber={1}
        documentLayout={documentLayout}
        docDir={docDir}
      >
        {settings.information.attendees.isActive && (
          <div
            className="paragraph"
            style={{
              flexWrap: "wrap",
              justifyContent: "start",
              alignItems: "start",
              flexDirection:
                informationWatch.format === "row" ? "row" : "column",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "start",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              {i18n.t("participants", { lng: jobData?.lang?.output[0] })}:
            </div>
            {jobData?.speakers
              ?.slice(0, 10)
              .map((speaker: string, i: number) => {
                return (
                  <div
                    style={{
                      marginInlineEnd:
                        informationWatch?.format === "row" ? "8pt" : "0pt",
                    }}
                    key={i}
                  >
                    {informationWatch.format === "row" && i < 9
                      ? speaker + ", "
                      : speaker}
                  </div>
                );
              })}
          </div>
        )}
      </PreviewPage>
      <PreviewPage
        jobData={jobData}
        settings={settings}
        pageNumber={2}
        documentLayout={documentLayout}
        docDir={docDir}
      >
        {settings?.tableOfSubjects?.tableOfSubjects?.isActive && (
          <div
            className="paragraph"
            style={{
              justifyContent: tableOfSubjectsWatch?.font?.align
                ? tableOfSubjectsWatch?.font?.align
                : generalWatch?.font?.align,
              textAlign: tableOfSubjectsWatch?.font?.align
                ? tableOfSubjectsWatch?.font?.align
                : generalWatch?.font?.align,
            }}
          >
            <div
              className="paragraph"
              style={{ width: "fit-content", flexDirection: "column" }}
            >
              <ul>
                <span
                  style={{
                    ...getTextStyling(
                      tableOfSubjectsWatch.font
                        ? tableOfSubjectsWatch.font
                        : generalWatch.font
                    ),
                  }}
                >
                  {i18n.t("agenda", { lng: jobData?.lang?.output[0] })}:
                </span>
                {ranges?.map((range, i: number) => {
                  let subjectsCounter = 0;
                  if (
                    range.type === TranscriptType.annotation &&
                    range.annotationType === "subject"
                  ) {
                    subjectsCounter = subjectsCounter + 1;
                    return (
                      <li key={i}>
                        {getListNumberBySign(tableOfSubjectsWatch?.sign, 1)}
                        {range.text}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        )}
      </PreviewPage>

      <PreviewPage
        settings={settings}
        jobData={jobData}
        pageNumber={3}
        documentLayout={documentLayout}
        docDir={docDir}
      >
        <>
          {remarksWatch.startEnd.isActive && (
            <div
              style={{
                justifyContent: remarksWatch.startEnd.font.align,
                ...getTextStyling(remarksWatch?.startEnd?.font),
              }}
              className="paragraph"
            >
              {remarksWatch.startEnd.text1}
            </div>
          )}

          <ProtocolRanges
            ranges={ranges}
            settings={settings}
            lang={jobData?.lang?.output[0]}
            from={0}
            to={8}
          />
        </>
      </PreviewPage>

      <PreviewPage
        jobData={jobData}
        settings={settings}
        pageNumber={4}
        documentLayout={documentLayout}
        docDir={docDir}
      >
        <>
          <ProtocolRanges
            ranges={ranges}
            settings={settings}
            lang={jobData?.lang?.output[0]}
            from={10}
            to={12}
          />
          {remarksWatch.startEnd.isActive && (
            <div
              style={{
                justifyContent: remarksWatch.startEnd.font.align,
                ...getTextStyling(remarksWatch?.startEnd?.font),
              }}
              className="paragraph"
            >
              {remarksWatch.startEnd.text2}
            </div>
          )}

          {remarksWatch?.greeting?.isActive && (
            <div
              className="paragraph"
              style={{
                justifyContent: remarksWatch?.greeting?.font?.align,
                ...getTextStyling(remarksWatch?.greeting?.font),
              }}
            >
              {remarksWatch?.greeting?.text1}
            </div>
          )}

          <div
            className="paragraph"
            style={{
              justifyContent: "center",
              flexWrap: "wrap",
              ...getTextStyling(remarksWatch?.signature?.font),
            }}
          >
            {remarksWatch?.signature?.names?.map((name: string, i: number) => (
              <p className="signatureStyle" key={i}>
                {name}
              </p>
            ))}
          </div>
        </>
      </PreviewPage>
    </div>
  );
};
