import React, { FC, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useKeyPress from "../../hooks/useKeyPress";
import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";

import { Annotation } from "../../models/annotations";

import "./RangeAnnotation.scss";

interface Props {
  rangeIndex: number;
  annotationIndex: number;
  annotation: Annotation;
  updateAnnotation: (
    rangeIndex: number,
    annotationIndex: number,
    annotation: Annotation
  ) => void;
  deleteAnnotation: (rangeIndex: number, annotationIndex: number) => void;
  direction: "ltr" | "rtl";
  isTemp: boolean;
  disabled: boolean;
}

const RangeAnnotation: FC<Props> = ({
  annotation,
  updateAnnotation,
  deleteAnnotation,
  rangeIndex,
  annotationIndex,
  direction,
  isTemp,
  disabled,
}) => {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const typeSelectorRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const annotationTypes = ["note", "subject", "decision", "task"];
  const [focusedTypeIndex, setFocusedTypeIndex] = useState(0);

  const arrowDownPressed = useKeyPress("ArrowDown", [typeSelectorRef, textRef]);
  const enterPressed = useKeyPress("Enter", [typeSelectorRef, textRef]);
  const arrowUpPressed = useKeyPress("ArrowUp", [typeSelectorRef, textRef]);

  useOutsideClick(typeSelectorRef, () => {
    if (editMode) {
      setEditMode(false);
    }
  });

  useEffect(() => {
    if (!isTemp) return;
    focusTypeSelector();
    setFocusedTypeIndex(0);
    setEditMode(true);
  }, [isTemp]);

  useEffect(() => {
    if (!arrowUpPressed) return;
    handleArrowNavigation("up");
  }, [arrowUpPressed]);

  useEffect(() => {
    if (!arrowDownPressed) return;
    handleArrowNavigation("down");
  }, [arrowDownPressed]);

  useEffect(() => {
    if (!annotation?.temp || !enterPressed) return;
    handleArrowNavigation("enter");
  }, [enterPressed]);

  const handleArrowNavigation = (arrow: "up" | "down" | "enter") => {
    const typesLength = annotationTypes.length;

    if (arrow === "down") {
      if (focusedTypeIndex === typesLength - 1) {
        setFocusedTypeIndex(0);
        return;
      }
      setFocusedTypeIndex(focusedTypeIndex + 1);
    }

    if (arrow === "up") {
      if (focusedTypeIndex === 0) {
        setFocusedTypeIndex(typesLength - 1);
        return;
      }
      setFocusedTypeIndex(focusedTypeIndex - 1);
    }

    if (arrow === "enter") {
      if (focusedTypeIndex < 0 || focusedTypeIndex >= typesLength || !editMode)
        return;
      handleChangeType(annotationTypes[focusedTypeIndex]);
      setEditMode(false);
      focusRangeText();
      setFocusedTypeIndex(-1);
    }
  };

  const handleUpdateAnnotation = (text: string) => {
    const newAnnotation = { ...annotation, text } as Annotation;
    updateAnnotation(rangeIndex, annotationIndex, newAnnotation);
  };

  const handleChangeType = (type: Annotation["type"]) => {
    updateAnnotation(rangeIndex, annotationIndex, {
      ...annotation,
      type,
      temp: false,
    });
    setEditMode(false);
  };

  const handleDeleteAnnotation = (e: any) => {
    e.stopPropagation();
    deleteAnnotation(rangeIndex, annotationIndex);
  };

  const focusTypeSelector = () => {
    if (typeSelectorRef.current) {
      typeSelectorRef.current.focus();
    } else {
      setTimeout(focusTypeSelector, 100);
    }
  };

  const focusRangeText = () => {
    textRef.current?.focus();
  };

  return (
    <div className="RangeAnnotation">
      <div className="annotation">
        <div
          className="annotationTypeContainer"
          onClick={() => setEditMode(!editMode)}
        >
          <FontAwesomeIcon
            className="deleteAnnotation"
            icon={["fal", "trash"]}
            onClick={handleDeleteAnnotation}
          />
          <div className="typeName">{t(annotation?.type || "")}</div>
          <div className={classNames("typeIcon", { open: editMode })}>
            <FontAwesomeIcon
              icon={[
                "fal",
                direction === "ltr" ? "chevron-right" : "chevron-left",
              ]}
            />
          </div>
        </div>
        <div
          className={classNames("typeSelector", {
            open: editMode,
            new: annotation?.temp,
          })}
          ref={typeSelectorRef}
          tabIndex={0}
        >
          {_.map(annotationTypes, (type: Annotation["type"], i: number) => (
            <div
              className={classNames("typeOption", {
                focused: annotation?.temp && focusedTypeIndex === i,
              })}
              onClick={() => handleChangeType(type)}
              key={i}
            >
              {type && t(type)}
            </div>
          ))}
        </div>
        <div
          className={classNames("annotationContent textContainer", {
            disabled,
          })}
        >
          {!disabled && (
            <textarea
              id={`range-${rangeIndex}`}
              className="textRangeField"
              defaultValue={annotation?.text || ""}
              placeholder={t("choose_annotation")}
              onBlur={(e) => handleUpdateAnnotation(e.target.value)}
              ref={textRef}
            />
          )}
          <div className="dummyRange">{annotation && annotation.text}</div>
        </div>
      </div>
    </div>
  );
};

export default RangeAnnotation;
