import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";

import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";

interface Props {
  data: { [key: string]: any };
  getTableActionButtons: (data: {
    [key: string]: any;
  }) => {
    onClick: () => void;
    label: string;
    className: string;
  }[];
  getActionText?: (data: { [key: string]: any }) => Promise<string>;
}
const TableActions: FC<Props> = ({
  data,
  getTableActionButtons,
  getActionText,
}): JSX.Element => {
  const isMounted = useRef(true);
  const [loading, setloading] = useState(false);
  const [actionText, setActionText] = useState("");
  const [buttons, setButtons] = useState<
    {
      onClick: () => void;
      label: string;
      className: string;
    }[]
  >([]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getActionText && getActionText(data).then((text) => setActionText(text));
    const tableActions = getTableActionButtons(data);
    setButtons(tableActions);
  }, [data]);

  return (
    <div className="tableActions">
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress style={{ width: "20px" }} />
        </div>
      ) : (
        <>
          {actionText && <div className="actionsText">{actionText}</div>}
          <div className="actions">
            {_.compact(buttons).map((action: any) => (
              <button
                className={classNames("actionButton", action.className)}
                onClick={async (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setloading(true);
                  await action.onClick();
                  isMounted.current && setloading(false);
                }}
                key={action.label}
              >
                {action.label}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TableActions;
