import React, { FC, useState, ChangeEvent, useRef, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Popup.scss";

interface Props {
  title: string | JSX.Element;
  body: JSX.Element;
  className?: string;
}

const Popup: FC<Props> = ({ title, body, className }) => {
  return (
    <div className={classNames("Popup", className)}>
      <div className="popupTitle">{title}</div>
      <div className="popupBody">{body}</div>
    </div>
  );
};

export default Popup;
