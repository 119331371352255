import React from "react";
import { MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";

export interface SelectOption {
  label: string;
  value: any;
}

export interface SelectInputProps {
  options: SelectOption[];
  controller?: {
    name: any;
    field: any;
  };
  disabled?: boolean;
}

export const SelectInput = ({
  options,
  controller,
  disabled,
}: SelectInputProps) => {
  return (
    <Select
      className="Select"
      {...controller?.field}
      size="small"
      inputProps={{ "aria-label": "Without label" }}
      disabled={disabled}
    >
      {options.map((option: any, i: number) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
