import { NotificationData } from "./../../models/notification";
import {
  MinimalTranscriber,
  TranscriberPreviewData,
  CurrTranscriberInfo,
  LoggedInUserInfo,
  Settings,
  MinimalProofer,
} from "./../../models/user";
import {
  SET_LOGGED_IN_USER,
  SET_LOGGED_IN_USER_INFO,
  UPDATE_NOTIFICATION,
  PUSH_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_LOGGED_IN_USER,
  CLEAR_LOGGED_IN_USER_INFO,
  SET_TRANSCRIBERS,
  SET_CURR_TRANSCRIBER,
  CLEAR_CURR_TRANSCRIBER,
  CLEAR_TRANSCRIBERS,
  SET_PROOFERS,
  UserActionTypes,
  UPDATE_SETTINGS,
  UPDATE_TRANSCRIBER_ROLE,
  GET_TRANSCRIBERS,
  GET_PROOFERS,
  SET_PROOFERS_OPTIONS,
  SET_TRANSCRIBERS_OPTIONS,
} from "./types";
import { LoggedInUser } from "../../models/user";
import FirebaseService from "../../services/FirebaseService";
import { DropdownOption } from "../../components/common/Dropdown/Dropdown";

export function setLoggedInUser(user: LoggedInUser): UserActionTypes {
  return {
    type: SET_LOGGED_IN_USER,
    user,
  };
}

export function updateSettings(settings: Settings): UserActionTypes {
  return {
    type: UPDATE_SETTINGS,
    settings,
  };
}

export function clearLoggedInUser(): UserActionTypes {
  return {
    type: CLEAR_LOGGED_IN_USER,
  };
}

export function setLoggedInUserInfo(
  loggedInUserInfo: LoggedInUserInfo
): UserActionTypes {
  return {
    type: SET_LOGGED_IN_USER_INFO,
    loggedInUserInfo,
  };
}

export function clearLoggedInUserInfo(): UserActionTypes {
  return {
    type: CLEAR_LOGGED_IN_USER_INFO,
  };
}

export function updateNotification(
  notifications: NotificationData[]
): UserActionTypes {
  return {
    type: UPDATE_NOTIFICATION,
    notifications,
  };
}

export function pushNotification(
  notification: NotificationData
): UserActionTypes {
  return {
    type: PUSH_NOTIFICATION,
    notification,
  };
}

export function clearNotifications(): UserActionTypes {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
}

export function setTranscribers(
  transcribers: MinimalTranscriber[] | TranscriberPreviewData[]
): UserActionTypes {
  return {
    type: SET_TRANSCRIBERS,
    transcribers,
  };
}

export function setProofers(transcribers: MinimalProofer[]): UserActionTypes {
  return {
    type: SET_TRANSCRIBERS,
    transcribers,
  };
}

export function setTranscribersOptions(
  transcribersOptions: DropdownOption[]
): UserActionTypes {
  return {
    type: SET_TRANSCRIBERS_OPTIONS,
    transcribersOptions,
  };
}

export function setProofersOptions(
  proofersOptions: DropdownOption[]
): UserActionTypes {
  return {
    type: SET_PROOFERS_OPTIONS,
    proofersOptions,
  };
}

export function updateTranscriberRole(
  transcriber: MinimalTranscriber | TranscriberPreviewData,
  newRole: string
): UserActionTypes {
  return {
    type: UPDATE_TRANSCRIBER_ROLE,
    transcriber,
    newRole,
  };
}

export function clearTranscribers(): UserActionTypes {
  return {
    type: CLEAR_TRANSCRIBERS,
  };
}

export function getTranscribers() {
  return async (dispatch: any, getState: any) => {
    let transcribers = getState().userStore.transcribers;
    let transcribersOptions = getState().userStore.transcribersOptions;
    if (!transcribers.length && !transcribersOptions.length) {
      transcribers = await await FirebaseService.getMinimalTranscribers();
      transcribersOptions = transcribers.map(
        (transcriber: { id: string; username: string }) => ({
          value: transcriber.id,
          label: transcriber.username,
          icon: "",
        })
      );
    }
    dispatch({ type: GET_TRANSCRIBERS, transcribers });
    dispatch({ type: SET_TRANSCRIBERS_OPTIONS, transcribersOptions });
  };
}

export function getProofers() {
  return async (dispatch: any, getState: any) => {
    let proofers = getState().userStore.proofers;
    let proofersOptions = getState().userStore.proofersOptions;
    if (!proofers.length) {
      proofers = await await FirebaseService.getMinimalProofers();
      proofersOptions = proofers.map(
        (transcriber: { id: string; username: string }) => ({
          value: transcriber.id,
          label: transcriber.username,
          icon: "",
        })
      );
    }
    dispatch({ type: GET_PROOFERS, proofers });
    dispatch({ type: SET_PROOFERS_OPTIONS, proofersOptions });
  };
}

export function setCurrTranscriber(
  transcriber: CurrTranscriberInfo
): UserActionTypes {
  return {
    type: SET_CURR_TRANSCRIBER,
    transcriber,
  };
}

export function clearCurrTranscriber(): UserActionTypes {
  return {
    type: CLEAR_CURR_TRANSCRIBER,
  };
}
