import { MeetingPreviewData, ElasticJob } from "../../models";
import {
  MeetingState,
  MeetingActionTypes,
  SET_MEETINGS,
  UPDATE_MEETING,
  REMOVE_MEETING,
  CLEAR_MEETINGS,
  UPDATE_MEETING_PRICE,
  UPDATE_MEETING_DEADLINE,
} from "./types";

const initialState: MeetingState = {
  meetings: [],
  myJobs: [],
};

function meetingReducer(
  state = initialState,
  action: MeetingActionTypes
): MeetingState {
  switch (action.type) {
    case SET_MEETINGS:
      return {
        ...state,
        [action.meetingPage]: action.meetings,
      };
    case UPDATE_MEETING_PRICE:
      return {
        ...state,
        meetings: state.meetings.map((m) => {
          if (m.id === action.meeting.id) {
            const newMeeting = { ...m };
            newMeeting[action.priceType] = action.price;
            return newMeeting;
          } else {
            return m;
          }
        }),
      };
    case UPDATE_MEETING_DEADLINE:
      return {
        ...state,
        meetings: state.meetings.map((m) => {
          if (m.id === action.meetingId) {
            const newMeeting = { ...m };
            newMeeting.deadline = action.deadline;
            return newMeeting;
          } else {
            return m;
          }
        }),
      };
    case UPDATE_MEETING:
      if (!action.meeting.id) return { ...state };
      return {
        ...state,
        [action.meetingPage]: (state as any)[
          action.meetingPage
        ].map((m: MeetingPreviewData | ElasticJob) =>
          m.id === action.meeting.id ? action.meeting : m
        ),
      };
    case CLEAR_MEETINGS:
      return {
        ...state,
        [action.meetingPage]: [],
      };
    case REMOVE_MEETING:
      return {
        ...state,
        [action.meetingPage]: (state as any)[action.meetingPage].filter(
          (meeting: MeetingPreviewData | ElasticJob) =>
            meeting.id !== action.meetingId
        ),
      };
    default:
      return state;
  }
}

export default meetingReducer;
