import _ from "lodash";
import mixpanel from "mixpanel-browser";
import { Event } from "../models";
import { JobData } from "../models/job";
import FirebaseService from "./FirebaseService";

export interface WorkTime {
  transcribe: any;
  review: any;
}

export interface UserEventSumup {
  textEdit: number;
  timeSync: number;
  userRole: string;
}

class TrackingService {
  private static instance: TrackingService;
  public events: Event[];

  public timeTrack = {
    text_edit: 0,
    subtitles_break: 0,
    speaker_break: 0,
    sync: 0,
  };

  constructor() {
    this.events = [];
  }

  public reportEvent = (
    eventName: Event["name"],
    eventData?: Record<string, any>,
    job?: JobData
  ) => {
    const event = {
      time: Date.now(),
      name: eventName,
      ...eventData,
    };
    this.events.push(event);

    const expandedEventData = {
      timestamp: Date.now(),
      job_id: job?.roomId,
      job_type: job?.jobType,
      lang_source: job?.lang.input,
      lang_target: job?.lang.output,
      duration: job?.meetingLength,
      ...eventData,
    };

    FirebaseService.analytics.logEvent(eventName, expandedEventData);
    mixpanel.track(eventName, expandedEventData);
  };

  public clearEvents = () => {
    this.events = [];
  };

  private _getEventTimeTrackName = (eventName: Event["name"]) => {
    switch (eventName) {
      case "text_edit":
        return "textEdit";
      case "subtitles_break":
        return "subtitlesBreak";
      case "speaker_break":
        return "speakerBreak";
      case "sync":
        return "sync";
    }
  };

  private _reset = () => {
    _.mapValues(this.timeTrack, () => 0);
    this.events = [];
  };

  public static getInstance = () => {
    if (!TrackingService.instance)
      TrackingService.instance = new TrackingService();
    return TrackingService.instance;
  };
}

export default TrackingService.getInstance();
