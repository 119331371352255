import React, { useEffect, useState, useRef, FC, useCallback } from "react";
import _ from "lodash";
import { jobTypes } from "../../models/jobTypes";
import { PreviewFormat } from "../../models";
import Logger from "../../services/Logger";

import TableFilter from "../TableFilter/TableFilter";

import ExportService from "../../services/ExportService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";

import InfiniteScroll from "react-infinite-scroll-component";

import classNames from "classnames";

import "./InfiniteGenericTable.scss";

const logger = Logger("InfiniteGenericTable");

export class TableConfig {
  fieldsToFilter: string[];
  tableName: string;
  columns: {
    [key: string]: { [key: string]: any };
  };
  formatters?: {
    [key: string]: (
      cellVal: any,
      rowVal: any,
      i?: number
    ) => string | number | JSX.Element;
  };
  multiSelect?: boolean;
  actions?: {
    [actionKey: string]: {
      label?: string;
      icon?: JSX.Element;
      action?: (option: any) => void;
      bulk?: boolean;
      hidden?: boolean;
    };
  };
  tableColumns?: string[];
  order?: "desc" | "asc";
  clickable?: (row: { [key: string]: any }) => boolean;
  orderBy?: string;
  faded?: (row: { [key: string]: any }) => boolean;
  disabled?: (row: { [key: string]: any }) => boolean;
  searchBar?: boolean;
  actionsBar?: boolean;
}

interface Props {
  config: TableConfig;
  data: { [key: string]: any }[];
  loadMore: (
    searchValue?: string,
    newSearch?: boolean,
    addedFilters?: {
      [key: string]: {
        value: string | number | string[] | Date | number[];
        type: "normal" | "range";
      };
    }
  ) => Promise<boolean | undefined>;
  dataCount?: number;
  additionalSearchFields?: string[];
  hasMore?: boolean;
  className?: string;
  rawData?: any[];
  orderTable?: (property: string, descOrAsc: "desc" | "asc") => void;
  onRowClick?: (row: { [key: string]: any }, event: React.MouseEvent) => void;
  onSelection?: (jobs: any[]) => void;
}
const GenericTable: FC<Props> = ({
  hasMore = false,
  data,
  className,
  loadMore,
  orderTable,
  dataCount,
  config,
  onRowClick,
  onSelection,
}) => {
  const { t } = useTranslation();
  const [orderedData, setOrderedData] = useState(data);
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState("creation_time");
  const [searchValue, setSearchValue] = useState("");
  const [jobSelected, setJobSelected] = useState<any[]>([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    loadMore(searchValue, true, filter);
  }, [filter]);

  useEffect(() => {
    setOrder(config["order"] ? config["order"] : order);
    setOrderBy(config["orderBy"] ? config["orderBy"] : orderBy);
  }, []);
  useEffect(() => {
    const ordered = _.orderBy(data, orderBy, order);
    setOrderedData(ordered);
  }, [data, order, orderBy, searchValue]);

  const defaultActions = {};

  const defaultConfig: TableConfig = {
    tableName: "table",
    columns: config.columns || _.mapValues(data[0], (c) => ({ label: c })),
    formatters: {},
    order: "desc",
    orderBy: "name",
    multiSelect: false,
    faded: () => false,
    clickable: () => true,
    actions: defaultActions,
    searchBar: true,
    actionsBar: true,
    fieldsToFilter: ["price"],
  };

  const [tableConfig, setTableConfig] = useState(
    _.merge(defaultConfig, config)
  );

  useEffect(() => {
    setTableConfig(_.merge(defaultConfig, config));
  }, [orderedData]);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";

    setOrder(isAsc);
    setOrderBy(property);
    orderTable && orderTable(property, isAsc);
  };

  const handleSearchDebounce = useCallback(
    _.debounce((value, filters) => loadMore(value, true, filters), 500),
    []
  );

  const handleOnSearch = (value: string) => {
    handleSearchDebounce(value, filter);
    setSearchValue(value);
  };

  const unselectJob = (selectedData: { [key: string]: string }) => {
    const selectedJobs = jobSelected.filter(
      (row) => row.id !== selectedData.id
    );
    setJobSelected(selectedJobs);
    if (onSelection) {
      onSelection(selectedJobs);
    }
  };

  const selectJob = (row: { [key: string]: string }) => {
    const selectedJobs = [...jobSelected, row];
    setJobSelected(selectedJobs);
    if (onSelection) {
      onSelection(selectedJobs);
    }
  };

  const handleSelectAll = (e: any) => {
    const isAllSelected = jobSelected.length === orderedData.length;
    const selected = isAllSelected ? [] : orderedData;

    const checkboxs = document.getElementsByClassName("rowCheckbox");
    for (const checkbox of checkboxs) {
      (checkbox as HTMLInputElement).checked = !isAllSelected;
    }

    e.target.checked = !isAllSelected;

    setJobSelected(selected);
    if (onSelection) {
      onSelection(selected);
    }
  };

  const handleOnRowClick = async (
    e: React.MouseEvent,
    row: { [key: string]: any }
  ) => {
    if (e.altKey) {
      navigator.clipboard.writeText(row.id).catch((err) => {
        logger.error("trying to copy row id");
      });
      return;
    }

    if (
      onRowClick &&
      jobTypes[row.previewFormat as PreviewFormat]?.editMode !== "none"
    ) {
      onRowClick(row, e);
    }
  };

  const handleLoadMore = async () => {
    try {
      setIsLoading(true);
      await loadMore(searchValue, false, filter);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <div className={classNames("InfiniteGenericTable", className)}>
      <InfiniteScroll
        dataLength={data.length} //This is important field to render the next data
        next={handleLoadMore}
        hasMore={hasMore}
        scrollThreshold={"400px"}
        loader={
          isLoading ? (
            <div className="loading-spinner">
              <CircularProgress style={{ width: "20px" }} />
            </div>
          ) : (
            <div
              className="loading-error-container"
              onClick={async () => {
                await loadMore(searchValue, false, filter);
              }}
            >
              <div className="loading-error-text">
                {t("additional_records")}
              </div>
            </div>
          )
        }
        endMessage={
          <div className="endMessageContainer" style={{ textAlign: "center" }}>
            <div className="endMessage">{t("no_more_data_to_load")}</div>
          </div>
        }
      >
        <TableContainer className="tableContainerComp" id="tableContainer">
          <TableFilter
            dataCount={dataCount}
            handleOnSearch={handleOnSearch}
            onChange={setFilter}
            fieldsToFilter={config.fieldsToFilter}
          >
            {tableConfig.actionsBar && tableConfig.actions && (
              <div className="actionsContainer">
                <div className="actions">
                  {_.map(
                    tableConfig.actions,
                    (action, key) =>
                      !action.hidden && (
                        <div
                          className={classNames("action", action.label)}
                          key={key}
                          onClick={() => {
                            action.action &&
                              action.action({
                                jobSelected,
                                filter,
                                searchValue,
                              });
                          }}
                        >
                          {action.icon}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
          </TableFilter>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="tableContainer"
          >
            <TableHead className="tableHeader">
              <TableRow>
                {tableConfig.multiSelect && (
                  <TableCell
                    className={classNames("headerCell", "checkbox", {
                      clickable: onRowClick,
                    })}
                    scope="row"
                    key={"checkbox-header"}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input type="checkbox" onClick={handleSelectAll} />
                  </TableCell>
                )}
                {_.map(tableConfig.columns, (column, key) =>
                  !column.hidden ? (
                    <TableCell
                      align={"right"}
                      className="headerCell"
                      sortDirection={orderBy === column.label ? order : false}
                      onClick={() => handleSort(key)}
                      key={column.label}
                    >
                      {t(column.label)}
                      <TableSortLabel
                        active={orderBy === column.label}
                        direction={orderBy === column.label ? order : "asc"}
                      />
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {orderedData.map((row, i) => (
                <TableRow
                  className={classNames(
                    "tableRow",
                    {
                      disabled:
                        tableConfig.disabled && tableConfig.disabled(row),
                      faded: tableConfig.faded && tableConfig.faded(row),
                    },
                    {
                      clickable:
                        tableConfig.clickable && tableConfig.clickable(row),
                    }
                  )}
                  hover
                  onClick={(e) => handleOnRowClick(e, row)}
                  role="checkbox"
                  aria-checked={false}
                  tabIndex={-1}
                  selected={false}
                  key={row.id}
                >
                  {tableConfig.multiSelect && (
                    <TableCell
                      className={classNames("rowCell", "checkbox", {
                        clickable: onRowClick,
                      })}
                      scope="row"
                      key={`checkbox-${row.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input
                        className="rowCheckbox"
                        type="checkbox"
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            selectJob(row);
                          } else {
                            unselectJob(row);
                          }
                        }}
                      />
                    </TableCell>
                  )}
                  {_.keys(tableConfig.columns).map((column) =>
                    !tableConfig.columns[column].hidden ? (
                      <TableCell
                        className={classNames("rowCell", column, {
                          clickable:
                            tableConfig.faded && tableConfig.faded(row),
                        })}
                        scope="row"
                        key={column}
                        style={{ width: tableConfig.columns[column].width }}
                      >
                        {tableConfig.formatters &&
                        tableConfig.formatters[column] ? (
                          <div
                            style={{ width: tableConfig.columns[column].width }}
                            className={classNames("tdContainer", column, {
                              ellipsis: tableConfig.columns[column].ellipsis,
                            })}
                          >
                            {tableConfig.formatters[column](
                              _.get(row, column),
                              row
                            )}
                          </div>
                        ) : React.isValidElement(_.get(row, column)) ? (
                          _.get(row, column)
                        ) : (
                          <div
                            style={{ width: tableConfig.columns[column].width }}
                            className={classNames("tdContainer", column, {
                              ellipsis: tableConfig.columns[column].ellipsis,
                            })}
                          >
                            {_.get(row, column)}
                          </div>
                        )}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </div>
  );
};

export default GenericTable;
