import React from "react";
import { useTranslation } from "react-i18next";

import AuthService from "../../services/AuthService";

import Button from "../../components/common/Button/Button";

export default function ReAuth() {
  const { t } = useTranslation();

  return (
    <div className="ReAuth">
      <div className="reAuthBody">{t("reauth_note")}</div>
      <Button label={t("reauth")} onClick={() => AuthService.logout()} />
    </div>
  );
}
