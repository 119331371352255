import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import "./RemoveEntityButton.scss";

interface Props {
  text: string;
  entityName: string;
  onApprove: () => void;
}

const AddEntityCard: FC<Props> = ({
  text,
  entityName,
  onApprove,
}): JSX.Element => {
  const { t } = useTranslation();

  const [isApproveModal, setIsApproveModal] = useState<boolean>(false);
  const [transition, setTransition] = useState<number>(100);

  const approveStr = t("remove_approval") + ` <span>${entityName}</span>` + "?"; // TODO: delete these

  const toggleApproveModal = () => {
    if (!isApproveModal) {
      setIsApproveModal(true);
      setTimeout(() => setTransition(8), 0);
    } else {
      setTransition(100);
      setTimeout(() => setIsApproveModal(false), 300);
    }
  };

  return (
    <>
      <div onClick={toggleApproveModal} className="remove-entity-btn">
        <h4>{text}</h4>
        {isApproveModal && (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ transform: `translateY(${transition}%)` }}
            className="approve-removal-modal"
          >
            <p dangerouslySetInnerHTML={{ __html: approveStr }}></p>
            <div className="btns">
              <button onClick={toggleApproveModal} className="neutral-hollow">
                {t("cancel")}
              </button>
              <button onClick={() => onApprove()} className="negative">
                {t("remove")}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddEntityCard;
