import {
  PaymentsData,
  PaymentMonthPreviewData,
  Debt,
} from "../../models/payment";

import {
  SET_RAW_PAYMENTS_DATA,
  CLEAR_RAW_PAYMENTS_DATA,
  SET_PAYMENTS_BY_MONTHS,
  CLEAR_PAYMENTS_BY_MONTHS,
  PaymentActionTypes,
  SET_USER_DEBT,
} from "./types";

export function setRawPaymentsData(payments: PaymentsData): PaymentActionTypes {
  return {
    type: SET_RAW_PAYMENTS_DATA,
    payments,
  };
}

export function setUserDebt(debts: any): PaymentActionTypes {
  return {
    type: SET_USER_DEBT,
    debts,
  };
}

export function clearRawPaymentsData(): PaymentActionTypes {
  return {
    type: CLEAR_RAW_PAYMENTS_DATA,
  };
}

export function setPaymentsByMonths(
  paymentsByMonths: PaymentMonthPreviewData[]
): PaymentActionTypes {
  return {
    type: SET_PAYMENTS_BY_MONTHS,
    paymentsByMonths,
  };
}

export function clearPaymentsByMonths(): PaymentActionTypes {
  return {
    type: CLEAR_PAYMENTS_BY_MONTHS,
  };
}
