import React, { FC } from "react";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import NotificationService from "../../services/NotificationService";
import FirebaseService from "../../services/FirebaseService";
import EditorService from "../../services/EditorService";

import { ExportsTypes, MeetingPreviewData } from "../../models";
import { removeMeeting, updateMeeting } from "../../store/meeting/actions";
import { popIndicator } from "../../store/system/actions";
import { AppState } from "../../store/rootReducer";

import TableActions from "./TableActions";

interface Props {
  meeting: MeetingPreviewData;
  setSelectedMeeting?: React.Dispatch<
    React.SetStateAction<MeetingPreviewData | null>
  >;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  recordsRemain?: boolean;
  setSelectedButton?: React.Dispatch<React.SetStateAction<string>>;
  storeName?: "myJobs";
}

const MeetingActions: FC<Props> = ({
  meeting,
  setSelectedButton,
  setSelectedMeeting,
  setShowModal,
  storeName,
  recordsRemain = false,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const actions = {
    approveAssignment: async (meeting: MeetingPreviewData) => {
      try {
        if (meeting.id && loggedInUser)
          await FirebaseService.approveTranscriber(meeting.id);
        const newMeeting = { ...meeting };
        newMeeting.isApproved = true;
        dispatch(updateMeeting(newMeeting, storeName));
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
        );
      }
    },
    cancelProcess: async (meeting: MeetingPreviewData) => {
      try {
        await FirebaseService.cancelMeetingProcess(meeting.id);
        recordsRemain
          ? dispatch(updateMeeting(meeting, storeName))
          : dispatch(removeMeeting(meeting.id, storeName));
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_process_failure") })
        );
      }
    },
    unAssignTranscriberFromMeeting: async (meeting: MeetingPreviewData) => {
      try {
        if (meeting.id) {
          if (loggedInUser) {
            await FirebaseService.unassignTranscriber(meeting.id);
          }
          const updatedMeeting = { ...meeting };
          updatedMeeting.assignedTranscriber = null;
          delete updatedMeeting.assignedUserInfo;
          updatedMeeting.assignedMethod = null;
          updatedMeeting.isApproved = false;
          dispatch(updateMeeting(updatedMeeting, storeName));
          dispatch(
            popIndicator({
              type: "success",
              txt: t("indicator_request_declined"),
            })
          );
        }
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
        );
      }
    },
    requestTranscribe: async (
      meeting: MeetingPreviewData,
      meetingName: string
    ) => {
      try {
        if (loggedInUser) {
          await FirebaseService.assignTranscriber(
            meeting.id,
            loggedInUser.id,
            "requested"
          );

          const info = {
            roomName: meetingName,
            sentBy: {
              name: loggedInUser.username,
              id: loggedInUser.id,
            },
          };
          // NotificationService.dispatchNotification(
          //   "requestTranscribe",
          //   "super_user",
          //   info
          // );
          recordsRemain
            ? dispatch(updateMeeting(meeting, storeName))
            : dispatch(removeMeeting(meeting.id, storeName));
          dispatch(
            popIndicator({ type: "success", txt: t("indicator_request_sent") })
          );
        }
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
        );
      }
    },
    approveTranscriber: async (meeting: MeetingPreviewData) => {
      try {
        if (loggedInUser) {
          await FirebaseService.approveTranscriber(meeting.id);
        }
        recordsRemain
          ? dispatch(updateMeeting(meeting, storeName))
          : dispatch(removeMeeting(meeting.id, storeName));
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_request_approved"),
          })
        );
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
        );
      }
    },
    refuseToJob: async (meeting: MeetingPreviewData) => {
      try {
        if (meeting.id) {
          if (loggedInUser) {
            await FirebaseService.unassignTranscriber(meeting.id);
          }
          dispatch(removeMeeting(meeting.id, storeName));
          dispatch(
            popIndicator({
              type: "success",
              txt: t("indicator_request_declined"),
            })
          );
        }
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
        );
      }
    },
    assignProoferToMeeting: async (meeting: MeetingPreviewData) => {
      try {
        if (loggedInUser) {
          await FirebaseService.assignProofer(meeting.id, loggedInUser.id);
          recordsRemain
            ? dispatch(updateMeeting(meeting, storeName))
            : dispatch(removeMeeting(meeting.id, storeName));
          dispatch(
            popIndicator({
              type: "success",
              txt: t("indicator_meeting_claimed"),
            })
          );
        }
      } catch (err) {
        console.log(err);
        dispatch(
          popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
        );
      }
    },
  };
  const getActionText = async (meeting: { [key: string]: any }) => {
    if (
      meeting.assignedTranscriber &&
      meeting.status === 3 &&
      !meeting.isApproved
    ) {
      const user = await FirebaseService.getUser(
        meeting.assignedTranscriber.id
      );
      return user ? user.username : "";
    }
    return "";
  };

  const getMeetingActionButtons = (meeting: MeetingPreviewData) => {
    let mainButton, secondaryButton, thirdButton;
    const buttonTypes = {
      refuse: {
        label: t("refuse"),
        className: "refuse",
        onClick: () => actions.unAssignTranscriberFromMeeting(meeting),
      },
      refuseToAcceptJob: {
        label: t("refuse"),
        className: "refuse",
        onClick: () => actions.refuseToJob(meeting),
      },
      acceptJob: {
        label: t("approve"),
        className: "approve",
        onClick: () => actions.approveAssignment(meeting),
      },
      assign: {
        label: t("assign"),
        className: "assign",
        onClick: () => {
          setSelectedMeeting && setSelectedMeeting(meeting);
          setShowModal && setShowModal(true);
        },
      },
      request: {
        label: t("request"),
        className: "request",
        onClick: () => actions.requestTranscribe(meeting, meeting.name),
      },
      process: {
        label: t("abort_process"),
        className: "process",
        onClick: () => actions.cancelProcess(meeting),
      },
      take: {
        label: t("take"),
        className: "take",
        onClick: () => actions.assignProoferToMeeting(meeting),
      },
      approve: {
        label: t("approve"),
        className: "approve",
        onClick: () => actions.approveTranscriber(meeting),
      },
      cancel_transcriber_assign: {
        label: t("cancel"),
        className: "cancel",
        onClick: () => {
          actions.unAssignTranscriberFromMeeting(meeting);
        },
      },
      release: {
        label: t("release"),
        className: "release",
        onClick: () => {
          setSelectedButton &&
            setSelectedButton(
              meeting.status === 3
                ? "unassign_transcriber_from_Meeting"
                : "unassign_proofer_from_meeting"
            );
          setSelectedMeeting && setSelectedMeeting(meeting);
          setShowModal && setShowModal(true);
        },
      },
      to_proof: {
        label: t("to_proof"),
        className: "approve",
        onClick: () => {
          setSelectedMeeting && setSelectedMeeting(meeting);
          setShowModal && setShowModal(true);
          setSelectedButton && setSelectedButton("to_proof");
        },
      },
      to_send: {
        label: t("send"),
        className: "approve",
        onClick: () => {
          setSelectedMeeting && setSelectedMeeting(meeting);
          setShowModal && setShowModal(true);
          setSelectedButton && setSelectedButton("to_send");
        },
      },
      return_to_available_meetings: {
        label: t("decline"),
        className: "refuse",
        onClick: () => {
          setSelectedMeeting && setSelectedMeeting(meeting);
          setShowModal && setShowModal(true);
          setSelectedButton &&
            setSelectedButton("return_to_available_meetings");
        },
      },
      return_to_transcriber: {
        label: t("return"),
        className: "refuse",
        onClick: () => {
          setSelectedMeeting && setSelectedMeeting(meeting);
          setShowModal && setShowModal(true);
          setSelectedButton && setSelectedButton("return_to_transcriber");
        },
      },
    };
    if (assignAction(meeting)) mainButton = buttonTypes.assign;
    if (sendToProofAction(meeting)) {
      secondaryButton = buttonTypes.to_proof;
      mainButton = buttonTypes.return_to_available_meetings;
    }
    if (sendToClientAction(meeting)) {
      mainButton = buttonTypes.return_to_transcriber;
      secondaryButton = buttonTypes.to_send;
    }
    if (requestAction(meeting)) mainButton = buttonTypes.request;
    if (approveAction(meeting)) secondaryButton = buttonTypes.approve;
    if (cancelTranscriberAssignAction(meeting))
      mainButton = buttonTypes.cancel_transcriber_assign;
    if (refuseAction(meeting)) mainButton = buttonTypes.refuse;
    if (processAction(meeting)) mainButton = buttonTypes.process;
    if (releaseAction(meeting)) mainButton = buttonTypes.release;
    if (takeAction(meeting)) mainButton = buttonTypes.take;
    if (acceptJob(meeting)) secondaryButton = buttonTypes.acceptJob;
    if (refuseToAcceptJob(meeting)) mainButton = buttonTypes.refuseToAcceptJob;

    return _.compact([mainButton, secondaryButton, thirdButton]);
  };

  const assignAction = (meeting: MeetingPreviewData) => {
    return (
      (loggedInUser?.role === "super_user" &&
        meeting.assignedMethod !== "requested" &&
        meeting.status === 3 &&
        !meeting.assignedTranscriber) ||
      (meeting.status === 4 && !meeting.assignedProofer)
    );
  };
  const requestAction = (meeting: MeetingPreviewData) => {
    return (
      (loggedInUser?.role === "transcriber" ||
        loggedInUser?.role === "transcriber+proofer") &&
      meeting.status === 3 &&
      !meeting.assignedTranscriber
    );
  };
  const approveAction = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.role.includes("super_user") &&
      meeting.assignedTranscriber &&
      meeting.assignedMethod === "requested" &&
      !meeting.isApproved &&
      meeting.status === 3
    );
  };
  const acceptJob = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.id === meeting.assignedTranscriber?.id &&
      meeting.assignedMethod === "assigned" &&
      !meeting.isApproved &&
      meeting.status === 3
    );
  };
  const refuseToAcceptJob = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.id === meeting.assignedTranscriber?.id &&
      meeting.assignedMethod === "assigned" &&
      !meeting.isApproved &&
      meeting.status === 3
    );
  };
  const cancelTranscriberAssignAction = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.role.includes("super_user") &&
      meeting.assignedTranscriber?.id !== loggedInUser?.id &&
      meeting.assignedMethod === "assigned" &&
      !meeting.isApproved &&
      meeting.status > 2
    );
  };
  const sendToProofAction = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.id === meeting.assignedTranscriber?.id &&
      meeting.status === 3 &&
      meeting.isApproved
    );
  };
  const sendToClientAction = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.id === meeting.assignedProofer?.id &&
      meeting.status === 4 &&
      meeting.isApproved
    );
  };
  const takeAction = (meeting: MeetingPreviewData) => {
    return (
      !loggedInUser?.role.includes("super_user") &&
      meeting.status === 4 &&
      !meeting.assignedProofer?.id
    );
  };
  const releaseAction = (meeting: MeetingPreviewData) => {
    return (
      (loggedInUser?.role.includes("super_user") &&
        meeting.isApproved &&
        meeting.status === 3 &&
        meeting.assignedTranscriber?.id !== loggedInUser.id &&
        meeting.assignedTranscriber) ||
      (meeting.status === 4 &&
        meeting.assignedProofer &&
        meeting.assignedProofer?.id !== loggedInUser?.id &&
        meeting.isApproved === true)
    );
  };
  const refuseAction = (meeting: MeetingPreviewData) => {
    return (
      loggedInUser?.role.includes("super_user") &&
      meeting.assignedMethod === "requested" &&
      !meeting.isApproved &&
      meeting.status === 3
    );
  };
  const processAction = (meeting: MeetingPreviewData) => {
    return loggedInUser?.role.includes("super_user") && meeting.status < 3;
  };

  return (
    <TableActions
      data={meeting}
      getTableActionButtons={
        getMeetingActionButtons as (data: {
          [key: string]: any;
        }) => {
          onClick: () => void;
          label: string;
          className: string;
        }[]
      }
      getActionText={getActionText}
    ></TableActions>
  );
};

export default MeetingActions;
