import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { format } from "date-fns";
import classNames from "classnames";

import { MeetingPreviewData, PreviewFormat } from "../../../models";
import { updateMeeting } from "../../../store/meeting/actions";

import { Option } from "react-select/src/filters";

import MessageModal from "../../MessageModal/MessageModal";
import Datepicker from "../../common/Datepicker/Datepicker";
import Input from "../../common/Input/Input";
import Dropdown from "../../common/Dropdown/Dropdown";

import { previewFormats } from "../../../constants/previewFormats";
import { invoiceSentOptions } from "../../../constants/invoiceSentOptions";
import { currency } from "../../../constants/currency";
import { getLanguages } from "../../../utils/locales";

import FirebaseService from "../../../services/FirebaseService";
import CloudFunctionsService from "../../../services/CloudFunctionsService";

import "./EditModal.scss";

type Field =
  | {
      field: string;
      label: string;
      type: "input";
      valueType: "number" | "text";
      options?: undefined;
      className?: string;
      onChange?: (value: string) => void;
    }
  | {
      field: string;
      type: "dropdown";
      label: string;
      options: Option[];
      className?: string;
      multiSelect?: boolean;
      onChange?: (value: string | string[]) => void;
    }
  | {
      field: string;
      type: "datepicker";
      fieldToUpdate: string;
      label: string;
      className?: string;
      onChange?: (value: Date) => void;
    }
  | {
      field: string;
      label: string;
      type: "currency";
      valueType: "number";
      options?: undefined;
      className?: string;
      onChange?: (value: string) => void;
    };

interface Props {
  dataSource?: "firebase" | "elastic";
  job: MeetingPreviewData;
  showModal: boolean;
  closeModal: () => void;
  fieldsToEdit: string[];
  handleSubmit: (newJob: MeetingPreviewData) => void;
}

const EditModal: FC<Props> = ({
  job,
  showModal,
  fieldsToEdit,
  closeModal,
  dataSource,
  handleSubmit,
}): JSX.Element => {
  const { t } = useTranslation();
  const [languages] = useState(getLanguages(t));
  const dispatch = useDispatch();
  const [newJob, setNewJob] = useState(job);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const onChange = (
    field: string,
    value: string | Date | string[] | number
  ) => {
    setFieldsToUpdate((prevFieldsToUpdate) => {
      let updatedFieldsToUpdate = { ...prevFieldsToUpdate };
      updatedFieldsToUpdate = { ...updatedFieldsToUpdate, [field]: value };

      return updatedFieldsToUpdate;
    });
    setNewJob((prevNewJob) => _.update(prevNewJob, field, () => value));
  };

  const editableJobsFields = [
    {
      field: "name",
      type: "input",
      valueType: "text",
      className: "largeInput",
      label: "jobName",
    },
    {
      field: "jobType",
      type: "dropDown",
      className: "largeDropdown",
      options: previewFormats,
      label: "previewFormat",
      multiSelect: false,
      onChange: async (value: PreviewFormat) => {
        if (
          !["subtitles", "subtitles-translation"].includes(value) &&
          _.get(newJob, "lang.output")
        ) {
          setNewJob((prevNewJob) => {
            return _.update(prevNewJob, "lang.output", (outputs) => {
              if (_.isArray(outputs)) {
                return outputs[0];
              } else {
                return outputs;
              }
            });
          });
        }
        const {
          transcriberPrice,
          prooferPrice,
        } = await CloudFunctionsService.getRoomPrice({
          jobType: value,
          clientId: newJob.clientId,
          lang: newJob.lang,
          translation: newJob.translation,
          meetingLength: newJob.meetingLength,
        });
        setNewJob((prevNewJob) => {
          let updatedJob = { ...prevNewJob };
          updatedJob = {
            ...prevNewJob,
            price: transcriberPrice,
            prooferPrice,
            jobType: value,
          };

          return updatedJob;
        });

        setFieldsToUpdate((prevFieldsToUpdate) => {
          let updatedFieldsToUpdate = { ...prevFieldsToUpdate };
          updatedFieldsToUpdate = {
            ...updatedFieldsToUpdate,
            price: transcriberPrice,
            prooferPrice,
            jobType: value,
          };
          return updatedFieldsToUpdate;
        });
      },
    },
    {
      field: "lang.input",
      type: "dropDown",
      className: "largeDropdown langInput",
      options: languages,
      label: "inputLang",
      multiSelect: false,
      onChange: (value: string[] | string) => {
        const field = "lang.input";

        onChange(field, [value] as string[]);
      },
    },
    {
      field: "lang.output",
      type: "dropDown",
      className: "largeDropdown langOutput",
      options: languages,
      label: "outputLang",
      multiSelect: false,
      onChange: (value: string[] | string) => {
        const field = "lang.output";
        if (!_.isArray(value)) {
          value = [value] as string[];
        }
        onChange(field, value);
      },
    },
    {
      field: "price",
      type: "input",
      valueType: "number",
      label: "price",
      className: "small",
      onChange: (value: string) => {
        onChange("price", Number(value));
      },
    },
    {
      field: "prooferPrice",
      type: "input",
      valueType: "number",
      label: "prooferPrice",
      className: "small",
      onChange: (value: string) => {
        onChange("prooferPrice", Number(value));
      },
    },
    {
      field: "deadline",
      fieldToUpdate: "deadline",
      type: "datepicker",
      label: "deadline",
      className: "small",
    },
    {
      field: "processProgressLastUpdate",
      fieldToUpdate: "processProgress.lastUpdate",
      type: "datepicker",
      label: "processProgressLastUpdate",
      className: "small",
      onChange: (value: Date) => {
        onChange("processProgress.lastUpdate", value);
      },
    },
    {
      field: "representative",
      type: "input",
      valueType: "text",
      className: "small",
      label: "representativeName",
    },
    {
      field: "representativePrice",
      type: "input",
      valueType: "number",
      className: "small",
      label: "representativePrice",
    },
    {
      field: "revenue",
      type: "currency",
      valueType: "number",
      className: "small",
      label: "revenue",
    },
    {
      field: "pageCount",
      type: "input",
      valueType: "number",
      className: "small",
      label: "pageCount",
    },
    {
      field: "invoiceSent",
      type: "dropDown",
      className: "invoiceSent small",
      options: invoiceSentOptions,
      label: "invoice",
    },
  ];
  const [filteredFields, setFilteredFields] = useState<Field[]>(
    editableJobsFields.filter((editableJobFields) =>
      fieldsToEdit.includes(editableJobFields.field)
    ) as Field[]
  );

  const handleCurrencyChange = (option: string | string[], field: Field) => {
    const newField = `${field.field}Currency`;
    setNewJob(
      _.update(newJob, newField, (preValue) => (option ? option : preValue))
    );
    setFieldsToUpdate({
      ...fieldsToUpdate,
      newField: _.get(newJob, newField),
    });
  };

  const submit = () => {
    closeModal();
    let job = { ...newJob };
    let newFieldsToUpdate = { ...fieldsToUpdate };
    const jobType = job.jobType;

    const langOutput = job.lang.output;
    const langInput = job.lang.input;
    if (langOutput && !langOutput.length) {
      job = _.update(job, "lang.output", () => langInput);

      newFieldsToUpdate = {
        ...newFieldsToUpdate,
        "lang.output": langInput,
      };
    }
    if (_.get(newFieldsToUpdate, "lang.input")) {
      CloudFunctionsService.rerunStt(
        job.id,
        _.get(newFieldsToUpdate, "lang.input")
      );

      if (dataSource === "elastic") {
        handleSubmit && handleSubmit(job);
      } else {
        dispatch(updateMeeting({ ...job, status: 1 }));
      }
      FirebaseService.updateJobFields(
        { ...newFieldsToUpdate, status: 1 },
        job.id
      );
    } else {
      if (dataSource === "elastic") {
        handleSubmit(job);
      } else {
        handleSubmit(job);
      }
      FirebaseService.updateJobFields(newFieldsToUpdate, job.id);
    }
  };

  return (
    <MessageModal
      className="editModal warning"
      title={t("edit")}
      body={
        <div className="bodyContainer editModalContainer">
          {filteredFields.map((field, i) => (
            <div
              className={classNames("field", field.field, field.className)}
              key={`${field.field}${i}`}
            >
              <div className="label">{t(field.label)}</div>
              {field.type === "input" ? (
                <Input
                  value={_.get(newJob, field.field)}
                  type={field.valueType}
                  onChange={(e) =>
                    field.onChange
                      ? field.onChange(e.target.value)
                      : onChange(field.field, e.target.value)
                  }
                ></Input>
              ) : field.type === "datepicker" ? (
                <Datepicker
                  placeholder={
                    _.get(newJob, field.field)
                      ? format(_.get(newJob, field.field), "dd/MM/yyyy/hh:mm")
                      : ""
                  }
                  onChange={(date) =>
                    field.onChange
                      ? field.onChange(date)
                      : onChange(field.field, date)
                  }
                />
              ) : field.type === "currency" ? (
                <div className="currencyContainer">
                  <Input
                    value={_.get(newJob, field.field)}
                    type={field.valueType}
                    onChange={(e) =>
                      field.onChange
                        ? field.onChange(e.target.value)
                        : onChange(field.field, e.target.value)
                    }
                  ></Input>
                  <Dropdown
                    options={currency}
                    className={"small"}
                    searchOption={false}
                    onChange={(option: string | string[]) =>
                      handleCurrencyChange(option, field)
                    }
                    value={_.get(newJob, `${field.field}Currency`)}
                    placeholder={t(_.get(newJob, `${field.field}Currency`))}
                    multiSelect={false}
                  ></Dropdown>
                </div>
              ) : (
                <Dropdown
                  options={field.options}
                  className={classNames(field.className)}
                  onChange={(option) =>
                    field.onChange
                      ? field.onChange(option)
                      : onChange(field.field, option as string)
                  }
                  value={_.get(newJob, field.field)}
                  placeholder={_.get(newJob, field.field)}
                  multiSelect={
                    ["subtitles", "subtitles-translation"].includes(
                      newJob.jobType
                    ) && field.field === "lang.output"
                      ? true
                      : field.multiSelect
                  }
                ></Dropdown>
              )}
            </div>
          ))}
        </div>
      }
      showModal={showModal}
      approve={{ text: t("approve"), action: () => submit() }}
      cancel={{ text: t("cancel"), action: closeModal }}
    />
  );
};

export default EditModal;
