export class LoggedInUser {
  id: string;
  username: string;
  country: string;
  role: string;
  imgUrl?: string;
  languages?: [] | string[];
  creationTime: Date;
  notificationsCount: number;
  phonenumber?: string;
  email?: string | null;
  emailVerified?: boolean | null;
  mfaState?:
    | "logged_out"
    | "missing_email"
    | "missing_email_verification"
    | "missing_mfa"
    | "mfa_active"
    | "missing_user_details"
    | null;
  providers: string[];
}

export class Settings {
  order?: "desc" | "asc";
  orderBy?: string;
  autoSave?: boolean;
  playerRewind?: number;
  playerForward?: number;
  playerRewindResume?: number;
  platformLanguage?: "he" | "en";
}

export class NewUserData {
  id?: string;
  phonenumber: string;
  username: string;
  mail: string;
  imgUrl: string | null;
  role: string;
  rejectedCount: number;
  completedCount: number;
}

export class LoggedInUserInfo {
  mail: string;
  phonenumber: string;
  currDebt: number;
  currMeetingsCount: number;
}

export type DealerTypesValues = {
  [type: string]: string;
};

export const dealerTypes: DealerTypesValues = {
  exemptDealer: "exempt_dealer",
  authorizedDealer: "authorized_dealer",
  employeeDealer: "employee_dealer",
  incorporation: "incorporation",
};

export class CurrTranscriberInfo {
  id: string;
  mail: string;
  phonenumber: string;
  username: string;
  completedCount: number;
  rejectedCount: number;
  currMeetingsCount: number;
  imgUrl: string | null;
  currDebt: number;
  role: string;
  dealerType: string;
  dealerNumer: string;
  dateOfBirth: Date;
  addressCity: string;
  languages: string[];
  hasContract: boolean;
  // bankAccount: {
  //     bankNumber: number;
  //     branchNumber: number;
  //     accountNumber: number;
  // };
}

export class TranscriberPreviewData {
  id: string;
  username: string;
  role?: string;
  currMeetingsCount: number;
  debt: number;
  creationTime?: Date;
  phoneNumber: string;
  mail?: string;
}

export class MinimalTranscriber {
  id: string;
  username: string;
  role?: string;
}
export class MinimalProofer {
  id: string;
  username: string;
}
