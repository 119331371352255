import React from "react";
import { useTranslation } from "react-i18next";

import {
  FocusedDocumentPageSlice,
  SettingPagesProps,
} from "../ExportConfigTypes";

import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";

import "./../SettingPages.scss";

export const FocusedDocumentPage = ({
  form,
}: SettingPagesProps<FocusedDocumentPageSlice>) => {
  const { t } = useTranslation();

  const focusedDocumentWatch: FocusedDocumentPageSlice = form?.watch() as FocusedDocumentPageSlice;
  const control = form?.control;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_focusedDocument_title")}:</div>
            <ControllerHOC control={control} name="focusedDocument.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_focusedDocument_explanation_1")}
          </div>
        </div>
        {/* SECTION END */}

        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_subjects_title")}:
            </div>
            <ControllerHOC name="focusedDocument.subjects" control={control}>
              <SwitchInput
                disabled={!focusedDocumentWatch?.focusedDocument?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_decisions_title")}:
            </div>
            <ControllerHOC name="focusedDocument.decisions" control={control}>
              <SwitchInput
                disabled={!focusedDocumentWatch?.focusedDocument?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_tasks_title")}:</div>
            <ControllerHOC name="focusedDocument.tasks" control={control}>
              <SwitchInput
                disabled={!focusedDocumentWatch?.focusedDocument?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
