import { MeetingPreviewData, ElasticJob } from "./../../models/meeting";
import {
  SET_MEETINGS,
  UPDATE_MEETING,
  REMOVE_MEETING,
  CLEAR_MEETINGS,
  MeetingActionTypes,
  UPDATE_MEETING_PRICE,
  UPDATE_MEETING_DEADLINE,
} from "./types";

export function setMeetings(
  meetings: MeetingPreviewData[],
  meetingPage = "meetings"
): MeetingActionTypes {
  return {
    type: SET_MEETINGS,
    meetings,
    meetingPage,
  };
}

export function updateMeeting(
  meeting: MeetingPreviewData | ElasticJob,
  meetingPage = "meetings"
): MeetingActionTypes {
  return {
    type: UPDATE_MEETING,
    meeting,
    meetingPage,
  };
}

export function updateMeetingPrice(
  meeting: MeetingPreviewData,
  price: number,
  priceType: "price" | "prooferPrice"
): MeetingActionTypes {
  return {
    type: UPDATE_MEETING_PRICE,
    meeting,
    price,
    priceType,
  };
}

export function updateMeetingDeadline(
  meetingId: string,
  deadline: Date
): MeetingActionTypes {
  return {
    type: UPDATE_MEETING_DEADLINE,
    meetingId,
    deadline,
  };
}

export function removeMeeting(
  meetingId: string,
  meetingPage = "meetings"
): MeetingActionTypes {
  return {
    type: REMOVE_MEETING,
    meetingId,
    meetingPage,
  };
}

export function clearMeetings(meetingPage = "meetings"): MeetingActionTypes {
  return {
    type: CLEAR_MEETINGS,
    meetingPage,
  };
}
