import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ErrorModal.scss";

interface Props {
  errorReason: string;
}

const LoadingModal: FC<Props> = ({ errorReason }): JSX.Element => {
  return (
    <div className="error-modal flex align-center justify-center">
      <FontAwesomeIcon icon={["fal", "exclamation-triangle"]} />
      <p>{errorReason}</p>
    </div>
  );
};

export default LoadingModal;
