import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import FirebaseService from "../../../services/FirebaseService";
import FileInput from "./../../FileInput/FileInput";
import MessageModal from "../../MessageModal/MessageModal";
import Logger from "../../../services/Logger";
import { popIndicator } from "../../../store/system/actions";
import BarLoader from "../../BarLoader/BarLoader";

import "./NoteModal.scss";

interface Props {
  notes: string;
  jobId: string;
  showModal: boolean;
  closeModal: () => void;
  onSubmit: (note: string) => void;
}

export interface NoteFile {
  name: string;
  type: string;
  url: string;
  id: string;
  userName?: string;
  date?: string;
}

const logger = Logger("NoteModal");

const NoteModal: FC<Props> = ({
  notes,
  jobId,
  showModal,
  closeModal,
  onSubmit,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [noteText, setNoteText] = useState(notes);
  const [noteFiles, setNoteFiles] = useState<NoteFile[]>([]);
  const [showLoader, setShowLoader] = useState(true);

  const user = FirebaseService.currentUserMetadata;
  const validFormats = [
    "application/pdf",
    "image/png",
    "image/jpeg",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".doc",
    ".docx",
  ];

  useEffect(() => {
    const fetchNotefiles = async () => {
      try {
        if (!jobId) return;
        setShowLoader(true);
        const items = await FirebaseService.getNoteFiles(jobId);
        setNoteFiles(items);
      } catch (err) {
        dispatch(
          popIndicator({
            type: "failure",
            txt: t("indicator_failed_to_fetch_files"),
          })
        );
        logger.error(err);
      } finally {
        setShowLoader(false);
      }
    };
    fetchNotefiles();
  }, [jobId]);

  const deleteNoteFile = async (noteFIle: NoteFile) => {
    try {
      setShowLoader(true);
      await FirebaseService.deleteNoteFile(jobId, noteFIle.name);
      const _noteFiles = noteFiles.filter(
        (_noteFile) => _noteFile.id !== noteFIle.id
      );
      setNoteFiles(_noteFiles);
    } catch (err) {
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("idicator_failed_to_delete_file"),
        })
      );
      logger.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  const onFilesUpload = async (e: any) => {
    try {
      setShowLoader(true);
      const files = [...(e.target.files as any)];
      const _noteFiles = noteFiles;

      await Promise.all(
        files.map(async (file) => {
          const noteFile = await FirebaseService.uploadNoteFile(
            file,
            jobId,
            user
          );
          _noteFiles.push(noteFile);
        })
      );
      setNoteFiles(_noteFiles);
    } catch (err) {
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("idicator_failed_to_upload_files"),
        })
      );
      logger.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  const renderNoteFileRow = (noteFile: NoteFile, i: any) => {
    return (
      <div key={i} className="note-file-row">
        <div className="row-1">
          <div className="note-file-name">
            <div className="icon">
              <FontAwesomeIcon icon={"file"} size="1x"></FontAwesomeIcon>
            </div>
            <a
              href={noteFile.url}
              rel="noopener noreferrer"
              target="_blank"
              className="name"
            >
              {noteFile.name}
            </a>
          </div>
          {user?.role === "super_user" && (
            <div className="delete" onClick={() => deleteNoteFile(noteFile)}>
              <FontAwesomeIcon icon={"trash"} size="1x"></FontAwesomeIcon>
            </div>
          )}
        </div>
        <div className="row-2">
          {`${t("uploaded_by")} ${noteFile.userName} ${t("on_date")} ${
            noteFile.date
          }`}
        </div>
      </div>
    );
  };

  return (
    <MessageModal
      className="NoteModal warning"
      title={t("notes")}
      body={
        <div className="bodyContainer">
          <div className="note">{t("note_file_edit_instructure")}</div>
          <div className="file-notes">
            {user?.role === "super_user" && !showLoader && (
              <>
                <label className="upload-file-btn" htmlFor="upload-file">
                  {t("upload_file")}
                </label>
                <FileInput
                  title="upload_file"
                  id={"upload-file"}
                  onFilesUpload={onFilesUpload}
                  validFormats={validFormats}
                  forwardedRef={undefined}
                ></FileInput>
              </>
            )}
            {showLoader && (
              <div className="loader-wrapper">
                <BarLoader />
              </div>
            )}
          </div>
          <div className="note-file-list">
            {noteFiles.length > 0 ? (
              noteFiles.map((noteFile, i) => renderNoteFileRow(noteFile, i))
            ) : (
              <div className="note-file-row">{t("no_files_attached")}</div>
            )}
          </div>

          <div className="note">{t("add_information_to_job")}</div>
          <div className="notes">
            <div className="textarea-container">
              <textarea
                disabled={user?.role !== "super_user"}
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      }
      showModal={showModal}
      approve={{
        text: t("approve"),
        action: () => {
          if (user?.role === "super_user") onSubmit(noteText);
          else closeModal();
        },
      }}
      cancel={{ text: t("cancel"), action: closeModal }}
    />
  );
};

export default NoteModal;
