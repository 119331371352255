import {
  ClientOption,
  ClientPreviewData,
  CurrClientInfo,
  MinimalClient,
} from "../../models/client";

export interface ClientState {
  clients: ClientPreviewData[];
  minimalClientList: MinimalClient[];
  currClient: CurrClientInfo | null;
  clientsOptions: {
    label: string;
    value: string;
    icon: string;
  }[];
}
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CLIENTS_OPTIONS = "SET_CLIENTS_OPTIONS";
export const SET_MINIMAL_CLIENTS = "SET_MINIMAL_CLIENTS";
export const CLEAR_CLIENTS = "CLEAR_CLIENTS";
export const SET_CURR_CLIENT = "SET_CURR_CLIENT";
export const CLEAR_CURR_CLIENT = "CLEAR_CURR_CLIENT";

interface SetClients {
  type: typeof SET_CLIENTS;
  clients: ClientPreviewData[];
}

interface setClientsOptions {
  type: typeof SET_CLIENTS_OPTIONS;
  clients: ClientOption[];
}

interface SetMinimalClients {
  type: typeof SET_MINIMAL_CLIENTS;
  minimalClients: MinimalClient[];
}

interface clearClients {
  type: typeof CLEAR_CLIENTS;
}

interface SetCurrClient {
  type: typeof SET_CURR_CLIENT;
  client: CurrClientInfo;
}

interface clearCurrClient {
  type: typeof CLEAR_CURR_CLIENT;
}

export type ClientActionTypes =
  | SetClients
  | clearClients
  | SetCurrClient
  | clearCurrClient
  | setClientsOptions
  | SetMinimalClients;
