export const invoiceSentOptions = [
  {
    label: "open",
    value: "open",
  },
  {
    label: "check",
    value: "check",
  },
  {
    label: "sent",
    value: "sent",
  },
];
