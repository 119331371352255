export const userDataMock = {
  id: "0V5416IBokVfWu8dTzm7wU5Hro32",
  username: "qa",
  role: "super_user",
  imgUrl:
    "https://firebasestorage.googleapis.com/v0/b/sumit-dev-0.appspot.com/o/user_photos%2F0V5416IBokVfWu8dTzm7wU5Hro32.png?alt=media&token=263788ae-5ce1-40af-93ea-dafcedb7eeb8",
  creationTime: new Date(
    "Thu Nov 04 2021 10:31:57 GMT+0200 (Israel Standard Time)"
  ),
  notificationsCount: 8,
};
