import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import FirebaseService from "../../services/FirebaseService";

import jobDataMock from "./../../mocks/jobMock";
import { ExportConfigContext, ExportConfigData } from "./ExportConfigTypes";
import ExportConfig from "./ExportConfig";

import Logger from "../../services/Logger";

const logger = Logger("ExportConfigPage");

interface ExportConfigRouterProps {
  allowSaveNew?: boolean;
}

const ExportConfigPage = ({
  allowSaveNew,
}: ExportConfigRouterProps): JSX.Element => {
  const [exportConfig, setExportConfig] = useState<ExportConfigData>();

  const { clientId, presetId } = useParams<{
    clientId: string;
    presetId: string;
  }>();
  const history = useHistory();

  const initialExportConfigContext = (): ExportConfigContext => {
    if (clientId) return "client";
    return "global";
  };

  const [context] = useState<ExportConfigContext>(initialExportConfigContext);

  useEffect(() => {
    const getExportConfig = async () => {
      let newExportConfig;
      if (context === "client") {
        newExportConfig = await FirebaseService.getClientExportConfigData(
          clientId,
          presetId
        );
      }
      if (!newExportConfig) {
        newExportConfig = await FirebaseService.getExportConfigData();
      }
      if (!newExportConfig) return;
      setExportConfig(newExportConfig);
    };
    getExportConfig();
  }, []);

  const handleSaveExportConfig = async (
    exportConfig: ExportConfigData,
    isNew?: boolean
  ) => {
    try {
      if (context === "client") {
        if (isNew) {
          await FirebaseService.setClientExportConfigData(
            clientId,
            exportConfig
          );
          history.goBack();
        } else {
          await FirebaseService.setClientExportConfigData(
            clientId,
            exportConfig,
            presetId
          );
        }
      } else {
        await FirebaseService.setExportConfigData(exportConfig);
      }
      logger.log(`Saved to ${context}`);
    } catch (err) {
      logger.error(err);
    }
  };

  return exportConfig ? (
    <ExportConfig
      exportConfig={exportConfig}
      handleSaveExportConfig={handleSaveExportConfig}
      jobsData={[jobDataMock]}
      allowExport={false}
      allowSaveNew={allowSaveNew}
    />
  ) : (
    <></>
  );
};

export default ExportConfigPage;
