import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Dialog } from "@material-ui/core";

import { AppState } from "../../store/rootReducer";
import {
  setMeetings,
  clearMeetings,
  updateMeeting,
  removeMeeting,
} from "../../store/meeting/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  resetSorting,
  setErrorReason,
  popIndicator,
} from "../../store/system/actions";
import { MeetingPreviewData, MeetingTableItem } from "../../models";

import { useTranslation } from "react-i18next";

import TimeService from "../../services/TimeService";
import * as SettingService from "../../services/SettingService";
import sortAndFilterService from "../../services/sortAndFilterService";
import FirebaseService from "../../services/FirebaseService";

import MeetingActions from "./MeetingActions";
import PageHeader from "../../components/PageHeader/PageHeader";
import MeetingsTable from "../../components/MeetingsTable/MeetingsTable";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ApproveModal from "../../components/MeetingPreview/ApproveModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import { getJobsUrl } from "../../utils/url";

import "./MeetingsPages.scss";

const TranscriberMeetingsPage: FC = (): JSX.Element => {
  const { id } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const meetings = useSelector(
    (state: AppState) => state.meetingStore.meetings
  );
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);

  const [meetingsTableData, setMeetingsTableData] = useState<
    MeetingTableItem[]
  >([]);
  const [searchBy, setSearchBy] = useState<string>("");
  const [transcriberName, setTranscriberName] = useState<string>("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [
    selectedMeeting,
    setSelectedMeeting,
  ] = useState<MeetingPreviewData | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.role !== "super_user") {
        history.push("/");
        return;
      } else {
        loadUserDetails();
      }
    }
    if (sorting.type !== "meetings") dispatch(resetSorting("meetings"));
    return () => {
      dispatch(clearMeetings());
    };
  }, [loggedInUser]);

  useEffect(() => {
    const formatMeeting = async () => {
      const formatMeetings = await getFormatMeetingsTable(meetings);
      if (formatMeetings) {
        setMeetingsTableData(formatMeetings);
      }
    };
    formatMeeting();
  }, [meetings]);

  const loadUserDetails = async () => {
    try {
      dispatch(setLoadingReason(t("loading transcriber meetings")));
      const meetings = await FirebaseService.getUserJobs(id);
      const transcriberRef = FirebaseService.getDocRef("users-v2", id);
      const transcriberDoc = await transcriberRef.get();
      setTranscriberName(transcriberDoc.data()?.username);
      const formatMeetings = await getFormatMeetingsTable(meetings);
      if (formatMeetings) {
        setMeetingsTableData(formatMeetings);
      }
      dispatch(setMeetings(meetings));
      dispatch(clearLoadingReason());
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };

  const searchAndSorting = {
    onSearchInput: onSearchBy,
    currSearchBy: searchBy,
  };

  const getFormatMeetingsTable = async (meetings: MeetingPreviewData[]) => {
    try {
      const formatMeetings = await Promise.all(
        meetings.map(async (meeting) => {
          const {
            id,
            name,
            deadline,
            meetingLength,
            speakers,
            lang,
            previewFormat,
            price,
            prooferPrice,
            status,
          } = meeting;
          const meetingObj = {
            status,
            id,
            name,
            role: t(status === 3 ? "transcribe" : "proof"),
            previewFormat: previewFormat,
            price: status === 3 ? price : prooferPrice,
            deadline,
            meetingLength: TimeService.getTimeStringFromSecs(meetingLength),
            speakers: speakers ? speakers.length : 0,
            lang: `${t(lang.input)} / ${t(lang.output)}`,
            actions: (
              <MeetingActions
                meeting={meeting}
                setSelectedMeeting={setSelectedMeeting}
                setShowModal={setShowModal}
              />
            ),
          };
          return meetingObj;
        })
      );
      return formatMeetings;
    } catch (err) {
      console.log("getFormatMeetingsTable", err);
    }
  };

  let meetingsToPreview = [...meetings];
  meetingsToPreview = sortAndFilterService.sortMeetings(
    meetingsToPreview,
    sorting.sortBy,
    sorting.order
  );
  if (searchBy)
    meetingsToPreview = sortAndFilterService.searchMeetings(
      meetingsToPreview,
      searchBy
    );
  const unAssignTranscriberFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    try {
      if (meeting.id) {
        setIsActionLoading(true);
        loggedInUser && (await FirebaseService.unassignTranscriber(meeting.id));
        if (!recordsRemain) {
          dispatch(removeMeeting(meeting.id));
          setMeetingsTableData(
            meetingsTableData.filter(
              (tableMeeting) => tableMeeting.id !== meeting.id
            )
          );
        } else {
          const updatedMeeting = { ...meeting };
          updatedMeeting.assignedTranscriber = null;
          updatedMeeting.assignedMethod = null;
          updatedMeeting.isApproved = false;
          dispatch(updateMeeting(updatedMeeting));
        }
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_request_declined"),
          })
        );
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      onSuccess(); //todo check this
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };
  const unAssignProoferFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    try {
      if (!loggedInUser) return;
      setIsActionLoading(true);
      await FirebaseService.unAssignProofer(meeting.id);
      onSuccess();
      if (!recordsRemain) {
        dispatch(removeMeeting(meeting.id));
        setMeetingsTableData(
          meetingsTableData.filter((meeting) => meeting.id !== meeting.id)
        );
      } else {
        const updatedMeeting = { ...meeting };
        updatedMeeting.assignedProofer = null;
        dispatch(updateMeeting(updatedMeeting));
      }
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_meeting_returned") })
      );
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const onCompClick = (meetingId: string) => {
    history.push(`/meeting/${meetingId}`);
    dispatch(clearMeetings());
  };

  const goToUserPage = (transcriberId: string) => {
    if (transcriberId === loggedInUser?.id) history.push("/my-profile");
    else history.push(`/transcriber/${transcriberId}`);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSuccess = () => {
    setIsActionLoading(false);
    closeModal();
  };

  const goToMeeting = (meeting: { [key: string]: any }) => {
    if (loggedInUser && loggedInUser.role.includes("super_user")) {
      history.push(getJobsUrl(meeting, loggedInUser));
      dispatch(clearMeetings());
    }
  };

  const orderTable = (property: string, descOrAsc: "desc" | "asc") => {
    SettingService.setSettings("orderBy", property, dispatch);
    SettingService.setSettings("order", descOrAsc, dispatch);
  };
  const goBack = () => {
    history.goBack();
  };
  return (
    <main className="main-container">
      <PageHeader
        title={t("transcriber_meetings")}
        backButton={{
          //@ts-ignore
          title: t(history.location.state?.name) || transcriberName,
          backAction: () => goBack(),
        }}
      />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <div className="meetings-page flex column align-center">
          {meetingsTableData.length > 0 && (
            <div className="meetingsTable">
              <MeetingsTable
                data={meetingsTableData}
                config={{
                  tableColumns: [
                    "id",
                    "name",
                    "deadline",
                    "meetingLength",
                    "speakers",
                    "lang",
                    "previewFormat",
                    "price",
                    "status",
                    "actions",
                    "role",
                  ],
                  tableName: t("TranscriberMeetingsPage"),
                  columns: {
                    processProgressLastUpdate: {
                      hidden: loggedInUser?.role !== "super_user",
                    },
                    clientName: { hidden: true },
                  },
                }}
                onRowClick={goToMeeting}
                orderTable={orderTable}
              />
            </div>
          )}
        </div>
      )}
      {meetings.length === 0 && !loadingReason && !errorReason && (
        <EmptyPageComp title={t("no_transcriber_meetings")} />
      )}
      {showModal && selectedMeeting && (
        <Dialog open={showModal} onClose={() => setShowModal(false)}>
          <ApproveModal
            type={
              selectedMeeting.status === 3
                ? "release_from_transcriber"
                : "release_from_proofer"
            }
            info={{ meetingName: selectedMeeting.name }}
            approveBtn={{
              text: t("approve"),
              look: "neutral",
              action: () => {
                if (selectedMeeting.status === 3) {
                  unAssignTranscriberFromMeeting(selectedMeeting, onSuccess);
                } else {
                  unAssignProoferFromMeeting(selectedMeeting, onSuccess);
                }
              },
            }}
            declineBtn={{
              text: t("cancel"),
              look: "approve",
              action: () => setShowModal(false),
            }}
            isPending={isActionLoading}
            specificClass={"cancelAbsolute"}
          ></ApproveModal>
        </Dialog>
      )}
    </main>
  );
};

export default TranscriberMeetingsPage;
