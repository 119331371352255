import { RoomData } from "./../../models/room";
import { SET_ROOM_DATA, CLEAR_ROOM_DATA, RoomActionTypes } from "./types";

export function setRoomData(roomData: RoomData): RoomActionTypes {
  return {
    type: SET_ROOM_DATA,
    roomData,
  };
}

export function clearRoomData(): RoomActionTypes {
  return {
    type: CLEAR_ROOM_DATA,
  };
}
