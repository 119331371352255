import React, { FC } from "react";
import _ from "lodash";
import classNames from "classnames";

import "../common/Input/Input.scss";

interface Props {
  className?: string;
  placeholder?: string;
  error?: string | null;
  register?: any;
  autofocus?: boolean;
  disabled?: boolean;
  type?: "text" | "number" | "password";
}

const FormInput: FC<Props> = ({
  className,
  placeholder,
  error,
  register,
  autofocus,
  disabled = false,
  type = "text",
}): JSX.Element => {
  return (
    <div className={classNames("FormInput", className, { error: !!error })}>
      <input
        placeholder={placeholder}
        autoFocus={autofocus}
        disabled={disabled}
        type={type}
        {...register}
      />
      {error && <div className="inputError">{error}</div>}
    </div>
  );
};

export default FormInput;
