import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { NewMeetingData } from "../../models/meeting";
import {
  PendingJob,
  SlicesJson,
  SlicesData,
  RequiredFieldsState,
  JobData,
} from "../../models/job";
import { processStatus } from "../../models";
import NewMeetingForm from "../../components/NewMeetingForm/NewMeetingForm";
import PriceProvider from "../../services/PriceProvider";
import { setMinimalClients } from "../../store/client/actions";
import { localeCodes } from "../../models/localeCodes";
import FirebaseService from "../../services/FirebaseService";
import { popIndicator } from "../../store/system/actions";
import FilesDropZone from "../../components/FilesDropZone/FilesDropZone";
import MembersSamplingComp from "../../components/MembersSamplingComp/MembersSamplingComp";
import FileInput from "../../components/FileInput/FileInput";
import "./NewMeetingPage.scss";
import CloudFunctionsService from "../../services/CloudFunctionsService";
import NewStreamModal from "../NewStreamModal/NewStreamModal";
import Button from "../../components/common/Button/Button";
import FeatureFlagsService from "../../services/FeatureFlagsService";

class ProcessingState {
  [id: string]: processStatus;
}

class DisabledState {
  [id: string]: { submitBtn: boolean; allForm: boolean };
}

const NewMeetingPage: FC = (): JSX.Element => {
  const validFormats = ["audio/mp3", "video/mp4", "audio/wav", "video/mov"];

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const maxFileSize = 2048 * 1000000; // 2GB

  const [showNewStreamJobModal, setShowNewStreamJobModal] = useState(false);

  const [validExtensions, setValidExtensions] = useState<string[]>([
    ...FirebaseService.audioFormats,
    ...FirebaseService.videoFormats,
  ]);
  const [pendingJobs, setPendingJobs] = useState<PendingJob[]>([]);
  const [disabledState, setDisabledState] = useState<DisabledState>({});
  const [processingState, setProcessingState] = useState<ProcessingState>({});
  const [currInSamplingIdx, setCurrInSamplingIdx] = useState<null | number>(
    null
  );
  const uploadFileInputRef = useRef<HTMLInputElement>(null);
  const [clientsSpeakers, setClientsSpeakers] = useState<{
    [id: string]: any[];
  }>({});

  useEffect(() => {
    if (!loggedInUser || loggedInUser.role !== "super_user") {
      history.push("/");
      return;
    }
    getValidExtensions();
    return () => {
      dispatch(setMinimalClients([]));
    };
  }, []);

  const getValidExtensions = async () => {
    try {
      const extentions = await FirebaseService.getRoomValidExtenstions();
      if (extentions) setValidExtensions(extentions);
    } catch (err) {
      return err;
    }
  };

  const validateFiles = (
    files: File[] | React.ChangeEvent<HTMLInputElement>
  ): { valid: boolean; msg: string } => {
    let validationMsg = "file_upload_error";
    if (!files) return { valid: false, msg: "files_no_files" };
    // check if every file has valid format & size
    const isValid = (files as File[]).every((file) => {
      let isValidFormat: boolean;
      const fileFormat = file.name.split(".").pop()?.toLowerCase();
      if (fileFormat) {
        isValidFormat = validExtensions.includes(fileFormat);
        if (!isValidFormat) {
          validationMsg = "file_unsupported_format";
          return false;
        }
      }
      // check for less than 2 GB
      if (file.size > maxFileSize) {
        validationMsg = "file_too_big";
        return false;
      }
      return true;
    });
    return { valid: isValid, msg: validationMsg };
  };

  const uploadFileBtnClick = (): void => {
    if (uploadFileInputRef.current) {
      uploadFileInputRef.current.click();
    }
  };

  const onFilesUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    isDroppedFile: boolean
  ) => {
    const files = isDroppedFile ? e : [...(e.target.files as any)];
    const filesStatus: { [id: string]: processStatus } = {};
    const newPendingJobs = (files as File[]).map(async (file) => {
      const id = "_" + FirebaseService.generateID();
      filesStatus[id] = processStatus.none;
      const duration = await getFileDuration(file);
      const files = isDroppedFile ? e : [...(e.target.files as any)];
      const isValidUpload = validateFiles(files);
      if (!isValidUpload || !isValidUpload.valid) {
        dispatch(popIndicator({ type: "failure", txt: t(isValidUpload.msg) }));
        return;
      }
      try {
        const handleUploadProggress = (transferred: number, total: number) => {
          const transferredPrecent = Math.round((transferred / total) * 100);
          const nameInput = document.getElementById(
            id + "nameinput"
          ) as HTMLInputElement;
          const submitBtn = document.getElementById(
            id + "submit"
          ) as HTMLButtonElement;
          if (nameInput && submitBtn) {
            nameInput.style.background = `linear-gradient(-90deg, rgba(255,255,255,1) ${transferredPrecent}%, rgba(235,235,235,1) ${transferredPrecent}%)`;
            if (transferredPrecent === 100) {
              submitBtn.disabled = false;
              submitBtn.classList.remove("disabled");
            }
          }
        };
        setProcessingState({
          ...processingState,
          ...filesStatus,
        });
        FirebaseService.uploadAudioFile(file, id, handleUploadProggress);
      } catch (err) {
        dispatch(
          popIndicator({
            type: "failure",
            txt: [
              t("indicator_failed_to_upload_file_1"),
              file.name,
              t("indicator_failed_to_upload_file_2"),
            ].join(" "),
          })
        );
      }
      const requiredFieldsInitial: RequiredFieldsState = {
        ownerId: { selected: false, userIndicator: false },
        file: { selected: false, userIndicator: false },
      };
      const newPending: PendingJob = {
        id,
        mediaSource: {
          name: file.name,
          src: URL.createObjectURL(file),
          duration,
        },
        slices: [],
        requiredFieldsState: requiredFieldsInitial,
        meeting: {
          name: file.name,
          assignedTranscriber: null,
          assignedMethod: null,
          isApproved: false,
          assignedProofer: null,
          clientId: null,
          deadline: new Date(Date.now() + 1000 * 60 * 60 * 24),
          clientDeadline: new Date(Date.now() + 1000 * 60 * 60 * 24),
          creationTime: new Date(Date.now()),
          meetingLength: duration,
          jobType: "protocol",
          representative: "",
          revenue: 0,
          lang: {
            input: ["he-IL"],
            output: ["he-IL"],
          },
          algorithm: "unsupervised",
          ownerId: null,
          price: 0,
          prooferPrice: 0,
          status: 1,
          wasPaid: true,
          invoiceSent: "open",
        },
      };
      const {
        transcriberPrice,
        prooferPrice,
      } = await CloudFunctionsService.getRoomPrice(newPending.meeting);
      newPending.meeting.price = transcriberPrice;
      newPending.meeting.prooferPrice = prooferPrice;
      return newPending;
    });
    Promise.all(newPendingJobs).then((ret) => {
      setPendingJobs([...pendingJobs.concat(ret as PendingJob[])]);
    });
  };

  const getFileDuration = (file: File) => {
    const promise = new Promise<number>((res) => {
      const tmpFilePath = URL.createObjectURL(file);
      const audio = document.createElement("audio");
      audio.src = tmpFilePath as string;
      audio.addEventListener(
        "loadedmetadata",
        () => {
          res(audio.duration);
        },
        false
      );
    });
    return Promise.resolve(promise);
  };

  const updatePendingMeeting = (meeting: NewMeetingData, id: string) => {
    setPendingJobs(
      pendingJobs.map((job) => {
        if (job.id === id) job.meeting = meeting;
        return job;
      })
    );
  };

  const submitJob = async (id: string) => {
    const filesStatus: { [id: string]: processStatus } = {};
    try {
      if (!loggedInUser) return;
      const idx = pendingJobs.findIndex((job) => job.id === id);
      const jobToSubmit = pendingJobs[idx];
      filesStatus[id] = processStatus.inProgress;
      setProcessingState((prevState) => {
        return { ...prevState, ...filesStatus };
      });
      if (jobToSubmit.meeting.algorithm === "supervised") {
        let isSlicesValid = true;
        let duplicateName = false;
        jobToSubmit.slices.forEach((slicesData) => {
          slicesData.slices.forEach((slice) => {
            if (slice[0] < 0 || slice[1] < 0) isSlicesValid = false;
          });
          let nameCount = 0;
          jobToSubmit.slices.forEach((slcData) => {
            if (slicesData.name === slcData.name) nameCount++;
          });
          if (nameCount > 1) duplicateName = true;
        });
        if (jobToSubmit.slices.length < 1) {
          dispatch(
            popIndicator({
              type: "failure",
              txt: t("indicator_missing_slices"),
            })
          );
          return;
        } else if (!isSlicesValid) {
          dispatch(
            popIndicator({
              type: "failure",
              txt: t("indicator_missing_members_samplings"),
            })
          );
          return;
        } else if (duplicateName) {
          dispatch(
            popIndicator({
              type: "failure",
              txt: t("indicator_members_names_duplicates"),
            })
          );
          return;
        }
      }
      setDisabledState({
        ...disabledState,
        [id]: { submitBtn: true, allForm: true },
      });
      const handleProggress = (transferred: number) => {
        const element: HTMLElement | null = document.getElementById(
          id + "submit"
        );
        if (element) {
          element.innerText = `${transferred}%`;
        }
        if (transferred === 100) {
          const formElement = document.getElementById(id + "entireform");
          if (formElement) formElement.style.display = "none";
        }
      };
      if (["unsupervised", "none"].includes(jobToSubmit.meeting.algorithm)) {
        await FirebaseService.createNewJob(jobToSubmit, handleProggress);
        filesStatus[id] = processStatus.done;
        setProcessingState((prevState) => {
          return { ...prevState, ...filesStatus };
        });
      } else if (jobToSubmit.meeting.algorithm !== "none") {
        const slicesJson: SlicesJson = {};
        jobToSubmit.slices.forEach((slicesData) => {
          slicesJson[slicesData.name] = slicesData.slices;
        });
        await FirebaseService.createNewJob(
          jobToSubmit,
          handleProggress,
          slicesJson,
          clientsSpeakers[_.get(jobToSubmit, "meeting.ownerId.id")]
        );
        filesStatus[id] = processStatus.done;
        setProcessingState((prevState) => {
          return { ...prevState, ...filesStatus };
        });
      }
    } catch (err) {
      console.log(err);
      setDisabledState({
        ...disabledState,
        [id]: { submitBtn: false, allForm: false },
      });
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
      const element: HTMLElement | null = document.getElementById(
        id + "submit"
      );
      if (element) {
        element.innerText = t("send");
      }
    }
  };

  const updateSlices = (slices: SlicesData[], jobIndex?: number) => {
    const pendingClone = [...pendingJobs];
    if (currInSamplingIdx !== null) {
      pendingClone[currInSamplingIdx].slices = slices;
      setPendingJobs(pendingClone);
    } else if (!_.isNil(jobIndex)) {
      pendingClone[jobIndex].slices = slices;
      setPendingJobs(pendingClone);
    }
  };

  const updateClientSpeakers = async (clientId: string) => {
    const slices = pendingJobs[currInSamplingIdx || 0].slices;
    const meeting = pendingJobs[currInSamplingIdx || 0].meeting;
    const newSpeakers: { name: string; clientId: string }[] = [];

    slices.forEach((s) => {
      if (_.some(clientsSpeakers[clientId], (cs) => cs.name === s.name)) return;
      newSpeakers.push({ name: s.name, clientId });
    });

    if (!meeting.ownerId) return;
    await FirebaseService.saveSpeakers(newSpeakers, meeting.ownerId.id);
    await handleClientChange(clientId);
  };

  const handleClientChange = async (clientId: string) => {
    const speakers = await FirebaseService.getSpeakers(clientId);
    setClientsSpeakers({
      ...clientsSpeakers,
      [clientId]: speakers,
    });
  };

  const getJobOwnerId = (jobIndex: number) => {
    return _.get(pendingJobs[jobIndex], "meeting.ownerId.id");
  };

  const updateRequiredFieldsState = (
    requiredFieldsState: RequiredFieldsState,
    id: string
  ) => {
    const pendingClone: PendingJob[] = pendingJobs.map((job) => {
      if (job.id !== id) return job;
      else {
        job.requiredFieldsState = requiredFieldsState;
        return job;
      }
    });
    setPendingJobs(pendingClone);
  };

  const abortJob = async (id: string) => {
    try {
      FirebaseService.deleteRoomInStorage(id);
      const idx = pendingJobs.findIndex((job) => job.id === id);
      const pendingJobsClone = [...pendingJobs];
      pendingJobsClone.splice(idx, 1);
      setPendingJobs(pendingJobsClone);
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const openMembersSampling = (idx: number) => {
    setCurrInSamplingIdx(idx);
  };

  const handleCreateNewStreamJob = async (newJob: Partial<JobData>) => {
    if (!loggedInUser) return;
    try {
      const newStreamJob = await FirebaseService.createNewStreamJob(newJob);
      history.push(`/job/${newStreamJob?.id}`);
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  return (
    <main className="main-container">
      <div
        className="new-meeting-page"
        style={{ display: currInSamplingIdx === null ? "flex" : "none" }}
      >
        <div className="header-section">
          <FileInput
            title="upload_file"
            onFilesUpload={onFilesUpload}
            validFormats={validFormats}
            forwardedRef={uploadFileInputRef}
            uploadFileBtnClick={uploadFileBtnClick}
          />
          <h1 className="main-title">{t("upload_meeting")}</h1>
          {FeatureFlagsService.isEnabled("liveInterview") && (
            <Button
              label={t("create_new_stream")}
              onClick={() => setShowNewStreamJobModal(true)}
            />
          )}
        </div>
        {_.compact(pendingJobs).length === 0 ? (
          <FilesDropZone
            title={t("drag_files_to_upload")}
            handleFiles={onFilesUpload}
          />
        ) : (
          _.compact(pendingJobs).map((job, idx) => {
            return (
              <NewMeetingForm
                key={idx}
                id={job.id}
                jobIndex={idx}
                updateMeeting={(meeting) =>
                  updatePendingMeeting(meeting, job.id)
                }
                meeting={job.meeting}
                disabledProps={disabledState[job.id]}
                onSubmit={() => submitJob(job.id)}
                requiredFieldsState={pendingJobs[idx].requiredFieldsState}
                updateRequiredFieldsState={(ret) =>
                  updateRequiredFieldsState(ret, job.id)
                }
                processingState={processingState[job.id]}
                openMembersSampling={() => openMembersSampling(idx)}
                onDeleteJob={() => abortJob(job.id)}
                clientSpeakers={
                  job.meeting.ownerId
                    ? clientsSpeakers[job.meeting.ownerId.id]
                    : []
                }
                handleClientChange={handleClientChange}
                slices={pendingJobs[idx].slices}
                updateSlices={updateSlices}
              />
            );
          })
        )}
      </div>
      {currInSamplingIdx !== null && (
        <MembersSamplingComp
          title={pendingJobs[currInSamplingIdx].meeting.name}
          slices={pendingJobs[currInSamplingIdx].slices}
          mediaSource={pendingJobs[currInSamplingIdx].mediaSource}
          onClose={() => {
            setCurrInSamplingIdx(null);
            updateClientSpeakers(
              _.get(pendingJobs[currInSamplingIdx], "meeting.ownerId.id")
            );
          }}
          updateSlices={updateSlices}
          speakers={clientsSpeakers[getJobOwnerId(currInSamplingIdx)]}
        />
      )}
      <NewStreamModal
        showModal={showNewStreamJobModal}
        createJob={handleCreateNewStreamJob}
        closeModal={() => setShowNewStreamJobModal(false)}
      />
    </main>
  );
};

export default NewMeetingPage;
