import React, { useEffect, useState } from "react";
import _ from "lodash";

import { useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { BookPager } from "../../components/common/V3/BookPager/BookPager";
import {
  PageList,
  KeyOfPageList,
  PageSlice,
} from "../../components/common/V3/PagerMenu/PagerMenu";
import { PagerMenu } from "../../components/common/V3/PagerMenu/PagerMenu";
import { Button } from "@material-ui/core";

import { ValidationsConfigForm } from "./settingPages/ValidationsConfigForm";
import {
  KeyOfValidationsConfigData,
  ValidationsConfigData,
  ValidationsFormPageSlice,
} from "./ValidationsConfigTypes";
import SelectLangModal from "./Modals/SelectLangModal";
import { popIndicator } from "../../store/system/actions";

import Logger from "../../services/Logger";

const logger = Logger("ValidationsConfig ");

export interface ConfigValidationsProps {
  availableValidations: { [v: string]: { [k: string]: "number" | "text" } };
  validationsConfig: ValidationsConfigData;
  handleSaveConfig?: (config: ValidationsConfigData, isNew?: boolean) => void;
}

const ValidationsConfig = ({
  handleSaveConfig,
  availableValidations,
  validationsConfig,
}: ConfigValidationsProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [configPageList, setConfigPageList] = useState<PageList>({});
  const [currentPage, setCurrentPage] = useState<string>("default");
  const [selectLangModal, setSelectLangModal] = useState(false);
  const [validationsConfigState, setValidationsConfigState] = useState(
    validationsConfig
  );

  const handleSave = async () => {
    try {
      if (!handleSaveConfig) return;

      await handleSaveConfig(validationsConfigState);

      dispatch(
        popIndicator({
          type: "success",
          txt: t("saved"),
        })
      );
    } catch (err: any) {
      logger.error(err);
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("save_fail"),
        })
      );
    }
  };

  const form = useForm<ValidationsFormPageSlice>({
    defaultValues: new ValidationsFormPageSlice(),
    mode: "onChange",
  });

  const handleFormChange = (
    slice: ValidationsFormPageSlice,
    lang: KeyOfValidationsConfigData
  ) => {
    const cloneValidationsConfig = { ...validationsConfigState };

    cloneValidationsConfig[lang] = slice;

    setValidationsConfigState(cloneValidationsConfig);
  };

  const buildConfigPageList = (newValidationConfig: ValidationsConfigData) => {
    const newConfigPageList: PageList = {};
    _.each(newValidationConfig, (langConfig, lang: KeyOfPageList) => {
      newConfigPageList[lang] = {
        form: form as UseFormReturn<PageSlice>,
        slice: ValidationsFormPageSlice as new (args: PageSlice) => PageSlice,
        name: t(lang as string),
        component: (
          <ValidationsConfigForm
            availableValidations={availableValidations}
            handleFormChange={handleFormChange}
            formValues={langConfig}
            lang={lang as string}
          ></ValidationsConfigForm>
        ),
      };
    });
    setConfigPageList(newConfigPageList);
  };

  useEffect(() => {
    if (!validationsConfig) return;
    buildConfigPageList(validationsConfigState);
  }, [validationsConfigState]);

  const addLanguage = (langs: string | string[]) => {
    const formattedLangs = _.isArray(langs) ? langs : [];
    const cloneValidationsConfigState = { ...validationsConfigState };

    formattedLangs.map((lang) => {
      cloneValidationsConfigState[lang] = {
        ...new ValidationsFormPageSlice(),
      };
    });

    setValidationsConfigState(cloneValidationsConfigState);
    setCurrentPage(formattedLangs[0]);
  };

  return (
    <>
      <BookPager
        containerClassName={"ValidationsConfig"}
        areaA={
          <PagerMenu
            pageList={configPageList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            bottomButtons={
              <div
                className="addLangButton"
                onClick={() => {
                  setSelectLangModal(true);
                }}
              >
                {t("add_lang")}
              </div>
            }
          ></PagerMenu>
        }
        areaActions={
          <Button className={"actionButton"} onClick={handleSave}>
            {t("configurations_save")}
          </Button>
        }
        modalHeight={true}
      ></BookPager>
      <SelectLangModal
        multiSelect={false}
        handleApprove={addLanguage}
        showModal={selectLangModal}
        closeModal={() => setSelectLangModal(false)}
      ></SelectLangModal>
    </>
  );
};

export default ValidationsConfig;
