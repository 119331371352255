import { TimeTrack } from "../../models";
import {
  RoomState,
  SET_ROOM_DATA,
  CLEAR_ROOM_DATA,
  RoomActionTypes,
} from "./types";

const initialState: RoomState = {
  roomData: {
    roomId: "",
    name: "",
    speakers: [],
    deadline: null,
    creationTime: new Date(),
    meetingLength: 0,
    previewFormat: "protocol",
    assignedProofer: null,
    assignedTranscriber: null,
    price: 0,
    prooferPrice: 0,
    clientId: "",
    status: 0,
    lang: {
      input: [""],
      output: [""],
    },
    editProgress: 0,
    assets: [],
    words: [],
    workTime: {
      transcribe: {},
      review: {},
    },
  },
};

function roomReducer(state = initialState, action: RoomActionTypes): RoomState {
  switch (action.type) {
    case SET_ROOM_DATA:
      return {
        ...state,
        roomData: { ...action.roomData },
      };
    case CLEAR_ROOM_DATA:
      return {
        ...state,
        roomData: initialState.roomData,
      };
    default:
      return state;
  }
}

export default roomReducer;
