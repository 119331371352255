import React, { FC, useState } from "react";
import { NotificationData } from "../../models/notification";
import NotificationService from "../../services/NotificationService";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./NotificationPreview.scss";

interface Props {
  notification: NotificationData;
  approveNotification: (onSuccess: () => void) => void;
  isApproved: boolean | null;
}

const NotificationPreview: FC<Props> = ({
  notification,
  approveNotification,
  isApproved,
}): JSX.Element => {
  const { t } = useTranslation();

  const [isPending, setIsPending] = useState(false);

  const onApprove = () => {
    setIsPending(true);
    const onSuccess = () => setIsPending(false);
    approveNotification(onSuccess);
  };

  const statusbarLook = isApproved ? "" : "approved";
  let time;
  try {
    time = format(
      //@ts-ignore
      new Date(notification.creationTime.toDate()),
      "dd/MM/yyyy - HH:mm"
    );
  } catch (er) {
    time = "";
  }

  const notificationBody = NotificationService.getNotificationBodyTextAndPath(
    notification
  )?.text;

  return (
    <div className="notification-preview">
      <div className={`status-bar ${statusbarLook}`}></div>
      <div className="main">
        <div className="content">
          <p className="notification-body-text">{notificationBody}</p>
          <div className="time">
            <p className="time-paragraph">{time}</p>
          </div>
        </div>
        <div className="is-read">
          {isPending && (
            <div className="loading-spinner">
              <CircularProgress style={{ width: "20px" }} />
            </div>
          )}
          {!isApproved && !isPending && (
            <button onClick={onApprove} className="notification-btn">
              {t("notifications_mark_as_read")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationPreview;
