import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import firebase from "firebase";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { AppState } from "../../store/rootReducer";
import AuthService from "../../services/AuthService";

import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";

import "./AuthPages.scss";

interface Props {
  onPasswordRecovery?: (email: string) => void;
}

type PasswordRecoveryInputs = {
  email: string;
};

const PasswordRecoveryStep: FC<Props> = ({
  onPasswordRecovery,
}): JSX.Element => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [sendRecoveryError, setSendRecoveryError] = useState("");
  const [sendRecoverySuccess, setSendRecoverySuccess] = useState(false);

  const handleSendRecovery = async (email: string) => {
    try {
      setIsLoading(true);
      await AuthService.sendPasswordRecovery(email);
      setSendRecoverySuccess(true);
      if (onPasswordRecovery) {
        onPasswordRecovery(email);
      }
    } catch (err: any) {
      setSendRecoveryError(t(err));
    } finally {
      setIsLoading(false);
      AuthService.initLogin();
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<PasswordRecoveryInputs>({
    mode: "onChange",
    shouldUnregister: false,
  });

  const sendRecovery: SubmitHandler<PasswordRecoveryInputs> = (
    data: PasswordRecoveryInputs
  ) => handleSendRecovery(data.email);

  return (
    <div className="PasswordRecoveryStep">
      {!sendRecoverySuccess ? (
        <form
          className="styledForm passwordRecoveryForm"
          onSubmit={handleSubmit(sendRecovery)}
        >
          <div className="formError passwordRecoveryError"></div>
          <FormInput
            className="styledInput alignEnd"
            placeholder={t("email")}
            autofocus={true}
            disabled={isLoading}
            register={register("email", {
              required: { value: true, message: t("required_field") },
            })}
          />
          <Button
            type="submit"
            className="button"
            label={t("send_password_recovery")}
            loading={isLoading}
            disabled={!isValid}
          />
        </form>
      ) : (
        <>
          <div className="sendRecoverySuccess">
            {t("send_recovery_success_note")}
          </div>
          <Link to={"/login"}>{t("back_to_login")}</Link>
        </>
      )}
    </div>
  );
};

export default PasswordRecoveryStep;
