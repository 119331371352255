import React, { FC, useEffect, useState } from "react";
import _ from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppState } from "../../store/rootReducer";
import {
  clearRawPaymentsData,
  clearPaymentsByMonths,
  setUserDebt,
} from "../../store/payment/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  resetSorting,
  setErrorReason,
} from "../../store/system/actions";
import { PaymentMonthPreviewData, Debt } from "../../models/payment";

import PageHeader from "../../components/PageHeader/PageHeader";
import PaymentPreview from "../../components/PaymentPreview/PaymentPreview";
import sortAndFilterService from "../../services/sortAndFilterService";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import GenericTable from "../../components/Table/GenericTable";
import MessageModal from "../../components/MessageModal/MessageModal";
import Input from "../../components/common/Input/Input";
import ContextMenu from "../ArchivePage/ContextMenu";

import FirebaseService from "../../services/FirebaseService";
import PaymentsService from "../../services/PaymentsService";
import CloudFunctionsService from "../../services/CloudFunctionsService";

import Logger from "../../services/Logger";

import "./PaymentsPages.scss";

const logger = Logger("MyPaymentsPage");

const MyPaymentsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: userId } = useParams<{ id: string }>();
  const history = useHistory();

  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);
  // const rawPaymentsData = useSelector(
  //   (state: AppState) => state.paymentStore.rawPaymentsData
  // );
  const paymentsByMonths = useSelector(
    (state: AppState) => state.paymentStore.paymentsByMonths
  );

  const userDebt = useSelector(
    (state: AppState) => state.paymentStore.userDebt
  );
  const [searchBy, setSearchBy] = useState<string>("");
  const [paymentsToPreview, setPaymentsToPreview] = useState<
    PaymentMonthPreviewData[]
  >([]);
  const [chosenYear, setChosenYear] = useState("");
  const [debts, setDebts] = useState<any[]>([]);
  const [chosenMonth, setChosenMonth] = useState<null | string>(null);
  const [chosenMonthIdx, setChosenMonthIdx] = useState<number | null>(null);
  const [debtsToPreview, setDebtsToPreview] = useState<Debt[]>([]);
  const [transcriberName, setTranscriberName] = useState<string>("");
  const [selectedDebt, setSelectedDebt] = useState<Debt>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [bonus, setBonus] = useState<number>();

  useEffect(() => {
    if (loggedInUser) {
      if (sorting.type !== "payments") {
        dispatch(resetSorting("payments"));
      }
      loadMyPayments(userId);
      loadUserName(userId);
    }
    return () => {
      dispatch(clearRawPaymentsData());
      dispatch(clearPaymentsByMonths());
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (paymentsByMonths.length > 0) {
      let sorted = [...paymentsByMonths];
      if (sorting.sortBy === "by-date" && sorting.order === "ascending")
        sorted = sorted.reverse();
      else if (sorting.sortBy === "by-price")
        sorted = sortAndFilterService.sortPayments(
          sorted,
          sorting.sortBy,
          sorting.order
        );
      if (chosenMonthIdx === null) {
        if (searchBy)
          sorted = sortAndFilterService.searchPayments(
            sorted,
            "month",
            searchBy
          );
      }
      setPaymentsToPreview(sorted);
    }
  }, [sorting, searchBy, paymentsByMonths]);

  useEffect(() => {
    if (chosenMonthIdx !== null) {
      let sorted = [...paymentsByMonths[chosenMonthIdx].debts];
      if (sorting.sortBy === "by-date" && sorting.order === "ascending")
        sorted = sorted.reverse();
      else if (sorting.sortBy === "by-price")
        sorted = sortAndFilterService.sortPayments(
          sorted,
          sorting.sortBy,
          sorting.order
        );
      if (searchBy)
        sorted = sortAndFilterService.searchPayments(sorted, "debt", searchBy);
      setDebtsToPreview(sorted);
    }
  }, [sorting, searchBy, chosenMonthIdx, paymentsByMonths]);
  const loadUserName = async (selectedUser: string) => {
    const transcriberRef = FirebaseService.getDocRef("users-v2", selectedUser);
    const transcriberDoc = await transcriberRef.get();
    setTranscriberName(transcriberDoc.data()?.username);
  };
  const loadMyPayments = async (selectedUser: string) => {
    dispatch(setLoadingReason(t("loading_payments")));
    try {
      if (loggedInUser) {
        const userToShowDebt = !selectedUser ? loggedInUser.id : selectedUser;
        const payments = await PaymentsService.getUserDebt(userToShowDebt);

        if (payments) {
          dispatch(setUserDebt(payments));
        }
      }
    } catch (err) {
      dispatch(setErrorReason(t("indicator_error_ocurred")));
    } finally {
      dispatch(clearLoadingReason());
    }
  };

  const onMonthCardClicked = (
    month: string,
    year: string,
    debts: string[]
  ): void => {
    setChosenMonth(month);
    setChosenYear(year);
    setDebts(debts);
  };

  const resetMonthSelection = () => {
    setChosenMonth(null);
  };

  // const uploadInvoice = async (
  //   invoiceFile: File,
  //   onSuccess: () => void,
  //   month: string,
  //   year: string
  // ) => {
  //   try {
  //     if (loggedInUser?.id) {
  //       const invoiceUrl = await FirebaseService.uploadInvoice(
  //         invoiceFile,
  //         month,
  //         year,
  //         loggedInUser.id
  //       );
  //       const invoiceRef: InvoiceRef = {
  //         month: month + "-" + year,
  //         url: invoiceUrl,
  //       };
  //       if (rawPaymentsData) {
  //         const rawPaymentsDataClone = { ...rawPaymentsData };
  //         rawPaymentsDataClone.invoiceRefs.push(invoiceRef);
  //         await FirebaseService.updateInvoiceRefs(
  //           rawPaymentsDataClone.invoiceRefs,
  //           loggedInUser.id
  //         );
  //         dispatch(setRawPaymentsData(rawPaymentsDataClone));
  //         constructPaymentsByMonths(rawPaymentsDataClone);
  //       }
  //       onSuccess();
  //       dispatch(
  //         popIndicator({
  //           type: "success",
  //           txt: t("indicator_invoice_received"),
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
  //     );
  //   }
  // };

  const handleBonusUpdate = async (newValue: number, debt: Debt) => {
    if (!debt.userId) return;
    const newBonus = Number(newValue);
    try {
      if (_.isNaN(newBonus)) {
        throw "newValue is not a number";
      }
      await CloudFunctionsService.addBonusToJobDebt(
        debt.id,
        debt.userId,
        newBonus
      );
      const newDebts = debts.map((debt) => {
        if (selectedDebt && debt.id === selectedDebt.id) {
          const newDebt = { ...debt, bonus };
          return newDebt;
        } else {
          return debt;
        }
      });
      if (chosenYear && userDebt.debtByMonth[chosenYear] && chosenMonth) {
        userDebt.debtByMonth[chosenYear][chosenMonth] = newDebts;
        dispatch(setUserDebt(userDebt));
      }
      setDebts(newDebts);
    } catch (error) {
      logger.log(error, "handleBonusUpdate");
    }
  };

  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };

  const searchAndSorting = {
    onSearchInput: onSearchBy,
    currSearchBy: searchBy,
  };

  const headerBackButton =
    chosenMonth !== null
      ? { title: t("payment"), backAction: () => resetMonthSelection() }
      : {
          title: transcriberName,
          backAction: () => history.push(`/transcriber/${userId}`),
        };

  const headerTitle = chosenMonth ? t(`month_${chosenMonth}`) : t("payment");

  // const monthSummaryDates =
  //   chosenMonthIdx !== null
  //     ? format(
  //         paymentsByMonths[chosenMonthIdx].debts[0].createdAt,
  //         "dd/MM/yyyy"
  //       ) +
  //       " - " +
  //       format(
  //         paymentsByMonths[chosenMonthIdx].debts[
  //           paymentsByMonths[chosenMonthIdx].debts.length - 1
  //         ].createdAt,
  //         "dd/MM/yyyy"
  //       )
  //     : "";
  const onChange = (value: number) => {
    if (selectedDebt) {
      setBonus(value);
    }
  };
  return (
    <main className="main-container">
      <PageHeader title={headerTitle} backButton={headerBackButton} />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <div className="payments-page flex column">
          {loggedInUser &&
            userId &&
            loggedInUser?.id !== userId &&
            !chosenMonth && (
              <div
                onClick={() => history.push(history.location.pathname + "/new")}
                className="addPayment"
              >
                {t("add_payment")}
                <FontAwesomeIcon size="1x" icon={["fal", "plus"]} />
              </div>
            )}
          {!chosenMonth &&
            !_.isEmpty(userDebt.debts) &&
            _.map(Object.keys(userDebt.debtByMonth), (year, idx) => (
              <div className="yearContainer" key={idx}>
                <div className="year">{year}</div>
                <div className="debtYearContainer">
                  {_.map(
                    Object.keys(userDebt.debtByMonth[year]),
                    (month, idx) => (
                      <div className="debtMonth" key={idx}>
                        <PaymentPreview
                          key={idx}
                          previewMode={"month"}
                          currPage={"my-payments"}
                          wasPaid={false}
                          invoiceUrl={"payment.invoiceUrl"}
                          className={classNames(
                            _.every(
                              userDebt.debtByMonth[year][month],
                              (d) => d.payTime
                            )
                              ? "positive"
                              : "negative"
                          )}
                          onAddInvoice={
                            // (invoiceFile: File, onSuccess)
                            () => console.log("onAddInvoice")
                            // uploadInvoice(
                            //   invoiceFile,
                            //   onSuccess,
                            //   payment.month,
                            //   payment.year
                            // )
                          }
                          title={`${t(`month_${month}`)} ${year}`}
                          subtitle={userDebt.debtByMonth[year][month].length}
                          sum={Number(
                            _.sumBy(
                              userDebt.debtByMonth[year][month],
                              (d: { sum: number; bonus?: number }) =>
                                d.sum
                                  ? Number(d.sum) + (d.bonus ? d.bonus : 0)
                                  : 0
                            ).toFixed(1)
                          )}
                          onClick={() =>
                            onMonthCardClicked(
                              month,
                              year,
                              userDebt.debtByMonth[year][month]
                            )
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          {!_.isNil(chosenMonth) && (
            <div className="monthlyDebts">
              <GenericTable
                config={{
                  tableName: `${t(chosenMonth)}`,
                  columns: {
                    description: { label: "name" },
                    status: { label: "status", className: "debtStatus" },
                    sum: { label: "sum", key: "sum" },
                    jobLength: {
                      label: "table_meeting_length",
                      key: "jobLengthSeconds",
                    },
                    bonus: { label: "bonus", key: "bonus" },
                    creationTime: { label: "date" },
                    contextMenu: {
                      label: " ",
                      width: "15px",
                      hidden: loggedInUser?.role !== "super_user",
                    },
                  },
                  formatters: {
                    creationTime: (date) => format(date.toDate(), "dd/MM/yyyy"),
                    jobLength: (jobLength) => (jobLength ? jobLength : "-"),
                    sum: (sum) =>
                      `${t("currency")} ${sum ? Number(sum).toFixed(1) : 0}`,
                    bonus: (bonus) =>
                      `${t("currency")} ${
                        bonus ? Number(bonus).toFixed(1) : 0
                      }`,
                    status: (status) =>
                      status === "pending" ? `${t("pending")}` : "",
                    contextMenu: (contextMenu, debt: Debt) =>
                      debt.status !== "pending" ? (
                        <ContextMenu
                          menuItems={[
                            {
                              onClick: () => {
                                setSelectedDebt(debt);
                                setOpenEditModal(true);
                              },
                              text: t("edit"),
                            },
                          ]}
                        ></ContextMenu>
                      ) : (
                        ""
                      ),
                  },
                  faded: (row) => row.status === "pending",
                  sumRow: true,
                }}
                additionalSearchFields={["description"]}
                data={debts}
                onRowClick={
                  loggedInUser?.role === "super_user"
                    ? (debt) => history.push(`/meeting/${debt.roomId}`)
                    : _.noop
                }
              />
            </div>
          )}
        </div>
      )}
      {_.isEmpty(userDebt.debts) && !loadingReason && !errorReason && (
        <EmptyPageComp title={t("no_available_payments")} />
      )}
      <MessageModal
        showModal={openEditModal}
        title={t("editBonus")}
        body={
          <div>
            <div className="label">{t("bonus")}</div>
            <Input
              value={_.get(selectedDebt, "bonus")}
              type={"number"}
              onChange={(e) => onChange(Number(e.target.value))}
            ></Input>
          </div>
        }
        cancel={{ text: t("cancel"), action: () => setOpenEditModal(false) }}
        approve={{
          text: t("approve"),
          action: () => {
            if (selectedDebt && _.isNumber(bonus) && bonus >= 0) {
              handleBonusUpdate(bonus, selectedDebt);
            }
            setOpenEditModal(false);
          },
        }}
      ></MessageModal>
    </main>
  );
};

export default MyPaymentsPage;
