import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FC } from "react";
import { Word, RoomAsset } from "../../models";
import Draggable from "react-draggable";

import "./VideoPlayer.scss";
import classNames from "classnames";
import FeatureFlagsService from "../../services/FeatureFlagsService";

const videoContainerId = "videoPlayerContainer";

interface VideoPlayerProps {
  videoSources: RoomAsset[];
  currentCaption: Word[][] | string;
  isFloating?: boolean;
  direction: "ltr" | "rtl";
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  // videoSources,
  currentCaption,
  isFloating = false,
  direction,
}) => {
  const [currentCaptionsArrs, setCurrentCaptionsArrs] = useState<string[][]>(
    []
  );
  // useEffect(() => {
  // 	const defaultVideo = videoSources.find((video) => video.name === "Default");
  // 	SoundManager.initializeMedia({
  // 		src: defaultVideo.src,
  // 		videoContainerId
  // 	})
  // }, [])

  // const currentTime = useSubscription(Rx.CurrentTime);
  // const currentWordIndex = _.findIndex(currentRangeWords, (word) => word.start >= currentTime);
  // const passedWords = currentRangeWords.slice(0, currentWordIndex).map(word => word.text).join(" ");
  // const nextWords = currentRangeWords.slice(currentWordIndex, currentRangeWords.length-1).map(word => word.text).join(" ");
  // const rangeStrings = currentRangeWords.map(range => range.map(word => word.text).join(" "));

  useEffect(() => {
    const splitCurrentCaption = () => {
      let currentCaptionWordsArr = [];
      if (_.isString(currentCaption)) {
        currentCaptionWordsArr = [[...currentCaption.split(" ")]];
      } else {
        currentCaptionWordsArr = _.map(currentCaption, (range) =>
          _.map(range, (word) => word.text)
        );
      }
      let splitCurrentCaptionWordsArr: string[][] = [];
      for (const caption of currentCaptionWordsArr) {
        if (
          caption.length > 8 &&
          FeatureFlagsService.isEnabled("alwaysSplitSubtitles")
        ) {
          const firstRow = caption.slice(0, caption.length / 2);
          const secondRow = caption.slice(caption.length / 2);
          splitCurrentCaptionWordsArr = splitCurrentCaptionWordsArr.concat(
            [firstRow],
            [secondRow]
          );
        } else {
          splitCurrentCaptionWordsArr.push(caption);
        }
      }
      setCurrentCaptionsArrs(splitCurrentCaptionWordsArr);
    };
    splitCurrentCaption();
  }, [currentCaption]);

  return (
    <Draggable
      disabled={!isFloating}
      position={isFloating ? undefined : { x: 0, y: 0 }}
      handle=".VideoPlayerComponent"
      scale={1}
    >
      <div
        className={classNames("VideoPlayerComponent", {
          floating: isFloating,
        })}
      >
        <div>
          <div className={videoContainerId} id={videoContainerId}>
            {/*Video is injected here by SoundManager*/}
            <div className="videoSubtitles">
              {/*<span className="passedWords">{passedWords}</span> <span>{nextWords}</span>*/}
              {_.map(currentCaptionsArrs, (caption) => (
                <span
                  className={classNames("rangeString", direction, {
                    liveCaptionEditing: _.isString(currentCaption),
                  })}
                >
                  {caption.join(" ")}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default VideoPlayer;
