import React, { ReactChild, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import TimeFormat from "hh-mm-ss";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";

import { AppState } from "../../store/rootReducer";
import { RoomAsset } from "../../models";
import { LoggedInUser } from "../../models/user";

import SoundManager from "../../services/SoundManager";
import FeatureFlagsService from "../../services/FeatureFlagsService";
import EditorService from "../../services/EditorService";

import "./MediaPlayerV3.scss";

interface MediaPlayerV3 {
  direction: "ltr" | "rtl";
  mediaSources: RoomAsset[];
  chosenMedia: RoomAsset | undefined;
  setChosenMedia: (media: RoomAsset) => void;
  hasVideo: boolean;
  initialPlaybackPosition: number;
  currentCaption: any;
  loggedInUser: LoggedInUser;
  displayText?: boolean;
  sendCurrTime?: (currTime: number) => void;
  subtitlesData?: string;
  offsets?: { [key: string]: number };
  isStreaming?: boolean;
  children?: ReactChild;
  jobId: string;
}

const MediaPlayerV3 = ({
  direction,
  mediaSources,
  chosenMedia,
  setChosenMedia,
  sendCurrTime,
  hasVideo,
  currentCaption,
  initialPlaybackPosition = 0,
  subtitlesData,
  loggedInUser,
  displayText = false,
  offsets,
  isStreaming,
  children,
  jobId,
}: MediaPlayerV3) => {
  //CONSTS
  const videoContainerId = "videoPlayerContainer";
  const speeds = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
  const maxPlayerWidth = 650;
  const maxPlayerHeight = 380;
  const minPlayerWidth = 380;
  const minPlayerHeight = 120;
  const initialdimensions = {
    width: 455,
    height: 280,
  };

  //SELECTORS
  const { playerRewind, playerForward, playerRewindResume } = useSelector(
    (state: AppState) => state.userStore.settings
  );

  //STATES
  const [currentCaptionsArrs, setCurrentCaptionsArrs] = useState<string[][]>(
    []
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [mediaLength, setMediaLength] = useState(0);
  const [currSpeedIdx, setCurrSpeedIdx] = useState<number>(4);
  const [showWaveForm, setShowWaveForm] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [isSourceListOpen, setIsSourceListOpen] = useState<boolean>(false);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const timerEl = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [dimensions, setdimensions] = useState<{
    width: number;
    height: number;
  }>(initialdimensions);

  const renewWaveForm = (): Promise<void> => {
    return new Promise((res, rej) => {
      try {
        const tempCurrTime = SoundManager?.currentTime
          ? SoundManager.currentTime
          : 0;
        setShowWaveForm(false);
        setTimeout(() => {
          setShowWaveForm(true);
          setTimeout(() => {
            SoundManager.setOffset(tempCurrTime);
            res();
          }, 100);
        }, 50);
      } catch (err) {
        rej(err);
      }
    });
  };

  const setCurrTime = (time: number) => {
    if (timerEl.current) {
      const tempCurrTime = SoundManager?.currentTime
        ? SoundManager?.currentTime
        : 0;
      timerEl.current.innerHTML = TimeFormat.fromS(
        Math.round(Math.round(time || 0)),
        `${Math.round(time || 0) > 3600 ? "hh:" : ""}mm:ss`
      );
      sendCurrTime && sendCurrTime(tempCurrTime);
    }
  };

  //EVENTS
  useEffect(() => {
    renewWaveForm();
  }, [isDraggable]);

  useEffect(() => {
    setIsPlaying(SoundManager.isPlaying);
  }, [SoundManager?.isPlaying]);

  useEffect(() => {
    const _renewWaveFormAfterResizing = async () => {
      if (isDraggable) {
        await renewWaveForm();
      }
    };
    _renewWaveFormAfterResizing();
  }, [dimensions]);

  useEffect(() => {
    const defaultMedia = mediaSources[0];
    if (!defaultMedia) return;
    setChosenMedia(defaultMedia);
    setTimeout(() => {
      if (isMounted) {
        setShowWaveForm(true);
      }
    }, 1000);
    () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    //I'VE TRIED TO WORK WITH KEY PRESS HOOK BUT I NEED TO DO COMBINE PRESSING
    const keyStrokesHandlerMPV3 = async (e: KeyboardEvent) => {
      if (!e.ctrlKey) return;
      if (e.code == "ArrowUp") {
        setIsOpen(true);
      } else if (e.code == "ArrowDown") {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", keyStrokesHandlerMPV3, false);
    return () => document.removeEventListener("keydown", keyStrokesHandlerMPV3);
  }, [playerRewind, playerForward, playerRewindResume]);

  useEffect(() => SoundManager.changeSpeed(speeds[currSpeedIdx]), [
    currSpeedIdx,
  ]);

  useEffect(() => {
    const splitCurrentCaption = () => {
      let currentCaptionWordsArr = [];
      if (_.isString(currentCaption)) {
        currentCaptionWordsArr = [[...currentCaption.split(" ")]];
      } else {
        currentCaptionWordsArr = _.map(currentCaption, (range) =>
          _.map(range, (word) => word.text)
        );
      }
      let splitCurrentCaptionWordsArr: string[][] = [];
      for (const caption of currentCaptionWordsArr) {
        if (
          caption.length > 8 &&
          FeatureFlagsService.isEnabled("alwaysSplitSubtitles")
        ) {
          const firstRow = caption.slice(0, caption.length / 2);
          const secondRow = caption.slice(caption.length / 2);
          splitCurrentCaptionWordsArr = splitCurrentCaptionWordsArr.concat(
            [firstRow],
            [secondRow]
          );
        } else {
          splitCurrentCaptionWordsArr.push(caption);
        }
      }
      setCurrentCaptionsArrs(splitCurrentCaptionWordsArr);
    };
    splitCurrentCaption();
  }, [currentCaption]);

  useEffect(() => {
    const onChosenMediaChange = async () => {
      if (!chosenMedia) return;
      const tempCurrTime = SoundManager?.currentTime
        ? SoundManager.currentTime
        : 0;
      SoundManager.initializeMedia({
        media: chosenMedia,
        setMedia: setChosenMedia,
        setCurrTime,
        setMediaLength,
        mediaLength,
        offset: setOffset(chosenMedia),
        autoPlay,
        isStreaming,
        jobId,
      });
      setIsSourceListOpen(false);
      setTimeout(() => {
        SoundManager.setOffset(tempCurrTime);
        setAutoPlay(true);
      }, 100);
      if (subtitlesData && displayText) {
        SoundManager.setSubtitles(subtitlesData);
      }
    };
    onChosenMediaChange();
    return () => {
      SoundManager.pause();
    };
  }, [chosenMedia]);

  useEffect(() => {
    if (!chosenMedia) return;
    if (subtitlesData && displayText) {
      SoundManager.setSubtitles(subtitlesData);
    }
  }, [subtitlesData]);

  // CONTROLLERS
  const goRewind = async () => {
    await SoundManager.playRelative((playerRewind || 5) * -1);
  };

  // const goRewindFast = () => {
  //   SoundManager.playRelative((playerRewind || 5) * -1 * 10);
  // };

  const goForward = async () => {
    await SoundManager.playRelative(playerForward || 5);
  };

  // const goForwardFast = () => {
  //   SoundManager.playRelative((playerForward || 5) * 10);
  // };

  const replay = () => {
    SoundManager.setOffset(0);
  };

  const setOffset = (media: RoomAsset) => {
    if (offsets) {
      return offsets[media.name] ? Math.abs(offsets[media.name] / 1000) : 0;
    }
  };

  const downloadMedia = (e: any, mediaSrc: string) => {
    e.stopPropagation();
    if (!mediaSrc) return;
    window.open(mediaSrc);
  };

  //RENDERING
  const renderSubtitles = () => {
    return (
      <div className="videoSubtitles">
        {_.map(currentCaptionsArrs, (caption) => (
          <span
            key={caption + ""}
            className={classNames("rangeString", direction, {
              liveCaptionEditing: _.isString(currentCaption),
            })}
          >
            {caption.join(" ")}
          </span>
        ))}
      </div>
    );
  };

  const renderTime = () => {
    return (
      <div className="mediaTimeRange">
        {SoundManager.status === "buffering" ? (
          <div className="curr-time unselectable">Buffering...</div>
        ) : (
          <>
            <div className="curr-time unselectable" ref={timerEl}></div>
            <div className="">/</div>
            <div className="curr-time unselectable">
              {TimeFormat.fromS(
                Math.round(Math.round(mediaLength || 0)),
                `${Math.round(mediaLength || 0) > 3600 ? "hh:" : ""}mm:ss`
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderTimeSpeed = () => {
    return (
      <div className="speed-control">
        <div className="speed-control-section">
          {speeds.reverse().map((speed: number, i: number) => {
            return (
              <div
                key={i + speed}
                className="speed"
                onClick={() => setCurrSpeedIdx(i)}
              >
                {speed}
              </div>
            );
          })}
        </div>

        <FontAwesomeIcon icon={["fas", "tachometer-alt"]} />
        <p className="unselectable">{speeds[currSpeedIdx]}</p>
      </div>
    );
  };

  const renderMediaPlayerClasses = (): string => {
    if (isDraggable) {
      return "draggable";
    }

    return !isOpen ? "close" : "";
  };

  return (
    <Draggable
      handle={`#${videoContainerId}`}
      bounds={"html"}
      axis={isDraggable ? "both" : "none"}
    >
      <div
        className={`MediaPlayerV3  ${direction} ${renderMediaPlayerClasses()}`}
        style={isDraggable ? { width: "fit-content" } : {}}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Resizable
          style={
            //When in draggable state, we need media window to be in `initial` position to reclaim its space
            isDraggable
              ? { position: "initial" }
              : {
                  position: "absolute",
                }
          }
          //Style of right and left grips wrapper
          handleWrapperStyle={{
            height: dimensions.height,
            position: "absolute",
            //Same as enable
            left: direction === "ltr" || isDraggable ? "10px" : "auto",
            right: direction === "rtl" && !isDraggable ? "10px" : "auto",
          }}
          //Grips
          handleComponent={{
            right: (
              <div className={`gripResize`}>
                <FontAwesomeIcon icon={["fal", "grip-lines-vertical"]} />
              </div>
            ),
            left: (
              <div className={`gripResize`}>
                <FontAwesomeIcon icon={["fal", "grip-lines-vertical"]} />
              </div>
            ),
          }}
          lockAspectRatio={true}
          maxWidth={maxPlayerWidth}
          maxHeight={maxPlayerHeight}
          minWidth={minPlayerWidth}
          minHeight={minPlayerHeight}
          defaultSize={initialdimensions}
          className={`media-player-v3-content ${!isOpen ? "close" : ""}`}
          //Enable left and right grips accourding to draggable state and direction
          enable={{
            //Same as handle wrapper style
            left: direction === "ltr" || isDraggable ? true : false,
            right: direction === "rtl" && !isDraggable ? true : false,
          }}
          onResizeStop={(e, direction, ref, d) => {
            setdimensions({
              width: dimensions.width + d.width,
              height: dimensions.height + d.height,
            });
          }}
        >
          <div id={videoContainerId} className="videoContainer">
            <div className="replay" onClick={replay}>
              <FontAwesomeIcon icon={["fal", "redo"]} />
            </div>
            {renderSubtitles()}
            {!hasVideo && (
              <FontAwesomeIcon
                className="videoPlaceholder"
                icon={["fal", "video-slash"]}
              ></FontAwesomeIcon>
            )}
            <div
              className="list-sources"
              onClick={() => setIsSourceListOpen(!isSourceListOpen)}
            >
              <FontAwesomeIcon icon={["fal", "list-music"]} />
            </div>
            <div
              className={classNames([
                "list-sources-container",
                { hidden: !isSourceListOpen },
              ])}
            >
              {mediaSources.map((ms: any, i: number) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setChosenMedia(ms);
                    }}
                    className={classNames([
                      "sourceItem",
                      { selected: chosenMedia?.src === ms.src },
                    ])}
                  >
                    {FeatureFlagsService.isEnabled("allowMediaDownload") && (
                      <div
                        onClick={(e: any) => {
                          downloadMedia(e, ms.src);
                        }}
                      >
                        <FontAwesomeIcon icon={["far", "download"]} />
                      </div>
                    )}
                    <div>{ms.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </Resizable>
        <div className={`mediaPlayerV3Controls  ${!isOpen ? "close" : ""}`}>
          <div className="part-1 time">
            {renderTimeSpeed()}
            {renderTime()}
          </div>
          <div className="part-2 controllers">
            {/* <FontAwesomeIcon
              className="left"
              onClick={goRewindFast}
              icon="fast-backward"
              size="2x"
            ></FontAwesomeIcon> */}
            <FontAwesomeIcon
              className="left"
              onClick={goRewind}
              icon={"backward"}
              size="2x"
            ></FontAwesomeIcon>
            <div
              onClick={() => {
                SoundManager.togglePlay((playerRewindResume || 0) * -1);
              }}
              className="play-pause"
            >
              {isPlaying ? (
                <FontAwesomeIcon size="2x" icon={`pause-circle`} />
              ) : (
                <FontAwesomeIcon size="2x" icon={`play-circle`} />
              )}
            </div>
            <FontAwesomeIcon
              className="right"
              onClick={goForward}
              icon="forward"
              size="2x"
            ></FontAwesomeIcon>
            {/* <FontAwesomeIcon
              className="right"
              onClick={goForwardFast}
              icon="fast-forward"
              size="2x"
            ></FontAwesomeIcon> */}
          </div>
          <div className="part-3">
            <FontAwesomeIcon
              className={`minimize`}
              onClick={() => {
                setIsDraggable(!isDraggable);
              }}
              icon={["fal", "compress"]}
              size="2x"
            ></FontAwesomeIcon>
            {!isDraggable && (
              <FontAwesomeIcon
                onClick={() => setIsOpen(!isOpen)}
                className={`toggle ${!isOpen ? "close" : ""}`}
                icon="angle-down"
                size="2x"
              ></FontAwesomeIcon>
            )}
          </div>
        </div>
        <div className={`media-player-v3-arranger ${!isOpen ? "close" : ""}`}>
          {showWaveForm && children}
        </div>
      </div>
    </Draggable>
  );
};

export default MediaPlayerV3;
