import { PreviewFormat } from "./meeting";

export type JobType =
  | "protocol"
  | "subtitles"
  | "summary"
  | "translation"
  | "brief"
  | "subtitles-translation"
  | "sync-subtitles"
  | "interview"
  | "text-enhancement"
  | "live-interview";

export const jobTypes: {
  [key in PreviewFormat]: {
    editMode: "protocol" | "subtitles" | "none" | "subtitles-translation";
    label: JobType;
    value: JobType;
    icon: string;
    stt: boolean;
    lang: "input" | "output";
    defaultFile?: boolean;
    remap?: boolean;
    showSubtitles?: boolean;
    stream?: boolean;
  };
} = {
  protocol: {
    editMode: "protocol",
    label: "protocol",
    value: "protocol",
    icon: "",
    stt: true,
    remap: true,
    lang: "input",
  },
  subtitles: {
    editMode: "subtitles",
    label: "subtitles",
    value: "subtitles",
    icon: "",
    stt: true,
    showSubtitles: true,
    lang: "input",
  },
  translation: {
    editMode: "none",
    label: "translation",
    value: "translation",
    icon: "",
    stt: false,
    lang: "output",
  },
  summary: {
    editMode: "protocol",
    label: "summary",
    value: "summary",
    icon: "",
    stt: false,
    defaultFile: true,
    lang: "input",
  },
  brief: {
    editMode: "protocol",
    label: "brief",
    value: "brief",
    icon: "",
    stt: false,
    defaultFile: true,
    lang: "input",
  },
  interview: {
    editMode: "protocol",
    label: "interview",
    value: "interview",
    icon: "",
    stt: true,
    remap: true,
    lang: "input",
  },
  "subtitles-translation": {
    editMode: "subtitles-translation",
    label: "subtitles-translation",
    value: "subtitles-translation",
    icon: "",
    stt: false,
    showSubtitles: true,
    lang: "output",
  },
  "sync-subtitles": {
    editMode: "subtitles",
    label: "sync-subtitles",
    value: "sync-subtitles",
    icon: "",
    stt: false,
    showSubtitles: true,
    lang: "output",
  },
  "text-enhancement": {
    editMode: "subtitles",
    label: "text-enhancement",
    value: "text-enhancement",
    icon: "",
    stt: true,
    showSubtitles: true,
    lang: "input",
  },
  "live-interview": {
    editMode: "protocol",
    label: "live-interview",
    value: "live-interview",
    icon: "",
    stt: false,
    showSubtitles: false,
    lang: "output",
    stream: true,
  },
};
