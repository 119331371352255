import classNames from "classnames";
import React, { FC, useState, useRef } from "react";
import { getLanguageDirection } from "../../utils/locales";
import i18n from "../../i18n";

import "./Badge.scss";

interface Props {
  hidden?: boolean;
  position?: "near" | "on";
  direction?: "rtl" | "ltr";
}

const Badge: FC<Props> = ({
  children,
  hidden = true,
  position = "near",
  direction,
}) => {
  return (
    <div className={"Badge"}>
      <div
        className={classNames(
          "badge",
          direction ? direction : getLanguageDirection(i18n.language),
          position,
          { hidden }
        )}
      ></div>
      {children}
    </div>
  );
};

export default Badge;
