import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import {
  fontSizeOptions,
  listTypeOptions,
  SettingPagesProps,
  TableOfSubjectsPageSlice,
} from "../ExportConfigTypes";

import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { DynamicToggleSelection } from "../../../components/common/V3/DynamicToggleSelection/DynamicToggleSelection";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";

import "./../SettingPages.scss";

export const TableOfSubjectsPage = ({
  form,
}: SettingPagesProps<TableOfSubjectsPageSlice>) => {
  const { t } = useTranslation();

  const tableOfSubjectsWatch: TableOfSubjectsPageSlice = form?.watch() as TableOfSubjectsPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_tableOfSubjects_title")}</div>
            <ControllerHOC control={control} name="tableOfSubjects.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_tableOfSubjects_explanation_1")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_new_page")}:
            </div>
            <ControllerHOC control={control} name="newPage">
              <SwitchInput
                // disabled={
                //   getValues ? !getValues("tableOfSubjects.isActive") : false
                // }
                disabled={true}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="input-title">{t("export_config_global_sign")}:</div>
          <ControllerHOC setValue={setValue} control={control} name={"sign"}>
            <DynamicToggleSelection
              disabled={!tableOfSubjectsWatch?.tableOfSubjects?.isActive}
              options={listTypeOptions}
              isMulti={false}
            />
          </ControllerHOC>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!tableOfSubjectsWatch?.tableOfSubjects?.isActive}
              />
            </ControllerHOC>

            <ControllerHOC
              control={control}
              name="font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!tableOfSubjectsWatch?.tableOfSubjects?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                disabled={!tableOfSubjectsWatch?.tableOfSubjects?.isActive}
                hasJustify={true}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="font.color"
            >
              <ColorPicker
                disabled={!tableOfSubjectsWatch?.tableOfSubjects?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
