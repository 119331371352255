import React, { FC } from "react";
import emptyFolderImg from "../../assets/images/empty-folder.png";
import "./EmptyPageComp.scss";

interface Props {
  title: string;
  newTitle?: string;
  newAction?: () => void;
}

const EmptyPageComp: FC<Props> = ({
  title,
  newTitle,
  newAction,
}): JSX.Element => {
  return (
    <div className="empty-page-cmp">
      <div></div>
      <h1>{title}</h1>
      {newTitle && newAction ? (
        <div>
          <span className="createNew" onClick={newAction}>
            {newTitle}
          </span>
        </div>
      ) : null}
      <img src={emptyFolderImg} alt="empty" />
    </div>
  );
};
export default EmptyPageComp;
