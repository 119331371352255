import React, { FC } from "react";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./CircleProgress.scss";

interface Props {
  progress: number;
  size?: "lg";
}
const CircleProgress: FC<Props> = ({ progress, size }): JSX.Element => {
  const sizePx = size === "lg" ? 60 : 40;
  return (
    <div className={classNames("CircleProgress", size)}>
      <div className="background" />
      <CircularProgress variant="determinate" value={progress} size={sizePx} />
      <div className="progressLabel">{progress.toFixed(0)}%</div>
    </div>
  );
};

export default CircleProgress;
