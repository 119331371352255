import React from "react";
import { useTranslation } from "react-i18next";

import {
  fontSizeOptions,
  GeneralPageSlice,
  SettingPagesProps,
} from "../ExportConfigTypes";

import { DynamicToggleSelection } from "../../../components/common/V3/DynamicToggleSelection/DynamicToggleSelection";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextInput } from "../../../components/common/V3/TextInput/TextInput";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";

import "./../SettingPages.scss";

export const GeneralPage = ({ form }: SettingPagesProps<GeneralPageSlice>) => {
  const { t } = useTranslation();

  const control = form?.control;
  const setValue = form?.setValue;
  const errors = form?.formState.errors;

  const generalWatch: GeneralPageSlice = form?.watch() as GeneralPageSlice;

  return (
    <form>
      <div className="setting-page">
        <div className="section">
          <div className="title">{t("export_config_general_title")}</div>
          <div className="sub-title">
            {t("export_config_general_explanation_1")}
          </div>
        </div>
        <div className="section">
          <div className="input-title">
            {t("export_config_general_preset_name")}
          </div>
          <ControllerHOC
            name="presetName"
            control={control}
            errors={errors}
            setValue={setValue}
          >
            <TextInput placeholder={t("export_config_general_preset_name")} />
          </ControllerHOC>
        </div>
        <div className="section">
          <div className="input-title">{t("export_config_global_format")}:</div>
          <ControllerHOC control={control} name={"format"} setValue={setValue}>
            <DynamicToggleSelection
              options={[
                {
                  value: "pages",
                  label: t("export_config_global_by_pages"),
                  content: t("export_config_global_by_pages"),
                },
                {
                  value: "audio",
                  label: t("export_config_global_audio_length"),
                  content: t("export_config_global_audio_length"),
                },
                {
                  value: "intervals",
                  label: t("export_intervals"),
                  content: t("export_intervals"),
                },
              ]}
              isMulti={false}
            />
          </ControllerHOC>
        </div>
        {generalWatch.format === "intervals" && (
          <div className="section">
            <div className="inputs-row short mb-1">
              <div className="input-title">{t("export_intervals_length")}</div>
              <ControllerHOC
                control={control}
                name={"interval"}
                errors={errors}
                setValue={setValue}
              >
                <TextInput type="number" placeholder={t("export_intervals")} />
              </ControllerHOC>
            </div>
            <div className="inputs-row short">
              <div className="input-title">{t("export_intervals_offset")}</div>
              <ControllerHOC
                control={control}
                name={"timeOffset"}
                errors={errors}
                setValue={setValue}
              >
                <TextInput
                  className="noicon"
                  type="text"
                  placeholder="00:00:00"
                  step={1}
                />
              </ControllerHOC>
            </div>
          </div>
        )}
        <div className="section">
          <div className="input-title">{t("export_config_global_font")}:</div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="font.fontFamily">
              <SelectInput
                options={[
                  { label: "Arial", value: "Arial" },
                  { label: "FrankRuehl", value: "FrankRuehl" },
                  { label: "Narkisim", value: "Narkisim" },
                  { label: "David", value: "David" },
                ]}
              />
            </ControllerHOC>
            <ControllerHOC control={control} name="font.fontSize">
              <SelectInput options={fontSizeOptions} />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name="font.align"
            >
              <TextAlignmentSelection hasJustify={true} />
            </ControllerHOC>
          </div>
        </div>
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_row_count")}:
            </div>
            <ControllerHOC control={control} name="rowCount" errors={errors}>
              <SwitchInput />
            </ControllerHOC>
          </div>
        </div>
      </div>
    </form>
  );
};
