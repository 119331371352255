import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getLanguages } from "../../../utils/locales";

import { popIndicator } from "../../../store/system/actions";

import MessageModal from "../../MessageModal/MessageModal";
import Dropdown from "../../common/Dropdown/Dropdown";

import FirebaseService from "../../../services/FirebaseService";
import Logger from "../../../services/Logger";

const logger = Logger("TranslationJobModal");

interface Props {
  jobId: string;
  showModal: boolean;
  closeModal: () => void;
}

const TranslationJobModal: FC<Props> = ({
  jobId,
  showModal,
  closeModal,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [languages] = useState(getLanguages(t));
  const [selectedLang, setSelectedLang] = useState<string[]>([]);

  const onChange = (output: string | string[]) => {
    let langs = output;

    if (!_.isArray(langs)) {
      langs = [langs];
    }

    setSelectedLang(langs);
  };
  const onApprove = async () => {
    try {
      if (!selectedLang.length) {
        throw "No languages was selected";
      }
      await FirebaseService.runDoneSubtitlesTranslation(selectedLang, jobId);
      dispatch(
        popIndicator({ type: "success", txt: t("job_sent_to_translate") })
      );
    } catch (err) {
      logger.error(err, "onApprove");
      dispatch(popIndicator({ type: "failure", txt: t("job_translate_fail") }));
    }
    closeModal();
  };

  return (
    <MessageModal
      className="jobTranslateModal warning"
      title={t("translate_job")}
      isApproveDisabled={!selectedLang.length}
      body={
        <div className="bodyContainer">
          <div className="note">{t("choose_lang_for_translate")}</div>
          <Dropdown
            options={languages}
            onChange={(option) => onChange(option)}
            value={selectedLang}
            placeholder={t("choose_languages")}
            multiSelect={true}
          ></Dropdown>
        </div>
      }
      showModal={showModal}
      approve={{ text: t("translate"), action: onApprove }}
      cancel={{ text: t("cancel"), action: closeModal }}
    />
  );
};

export default TranslationJobModal;
