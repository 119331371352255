import { ArchiveTree } from "../../models/archive";
import { ElasticJob, ArchiveFormatedJobs } from "../../models/meeting";

export interface ArchiveState {
  archivedMeetings: ElasticJob[];
}

export const SET_ARCHIVED_MEETINGS = "SET_ARCHIVED_MEETINGS";
export const UPDATE_ARCHIVED_MEETING = "UPDATE_ARCHIVED_MEETING";
export const SET_ARCHIVE_TREE = "SET_ARCHIVE_TREE";
export const UPDATE_ARCHIVED_MEETING_REPRESENTATIVE =
  "UPDATE_ARCHIVED_MEETING_REPRESENTATIVE";
export const UPDATE_ARCHIVED_MEETING_REVENUE =
  "UPDATE_ARCHIVED_MEETING_REVENUE";
export const UPDATE_ARCHIVED_MEETING_INVOICE_SENT =
  "UPDATE_ARCHIVED_MEETING_INVOICE_SENT";

interface SetArchivedMeetings {
  type: typeof SET_ARCHIVED_MEETINGS;
  meetings: ElasticJob[];
}

interface UpdateArchivedMeeting {
  type: typeof UPDATE_ARCHIVED_MEETING;
  meeting: ElasticJob;
}

interface SetArchiveTree {
  type: typeof SET_ARCHIVE_TREE;
  archiveTree: ArchiveTree | null;
}

interface UpdateRepresentative {
  type: typeof UPDATE_ARCHIVED_MEETING_REPRESENTATIVE;
  meetingId: string;
  representative: string;
}

interface UpdateRevenue {
  type: typeof UPDATE_ARCHIVED_MEETING_REVENUE;
  meetingId: string;
  revenue: number;
}

interface UpdateInvoiceSent {
  type: typeof UPDATE_ARCHIVED_MEETING_INVOICE_SENT;
  meetingId: string;
  invoiceSent: string;
}

export type ArchiveActionTypes =
  | SetArchivedMeetings
  | UpdateArchivedMeeting
  | UpdateRepresentative
  | UpdateRevenue
  | UpdateInvoiceSent
  | SetArchiveTree;
