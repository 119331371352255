export class Event {
  time: number;
  name: string;
  idle_duration?: number;
}

export interface TimeTrack {
  textEdit: 0;
  sync: 0;
  subtitlesBreak: 0;
  speakerBreak: 0;
  totalTime: 0;
}
