import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Badge from "../../components/Badge/Badge";

interface Props {
  menuItems: {
    onClick: (value?: string) => void;
    text: string;
  }[];
  showBadge?: boolean;
}

const ContextMenu: FC<Props> = ({ menuItems, showBadge }): JSX.Element => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleContextMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    window.removeEventListener("scroll", handleScroll);
    setAnchorEl(null);
  };
  const handleMenuItemClick = (
    event: React.SyntheticEvent<EventTarget>,
    onClick: () => void
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
    onClick && onClick();
  };
  const handleOpen = () => {
    window.addEventListener("scroll", handleScroll);
    return Boolean(anchorEl);
  };
  const handleScroll = () => {
    window.removeEventListener("scroll", handleScroll);
    setAnchorEl(null);
  };
  return (
    <div className="contextMenuContainer">
      <Button
        aria-controls={anchorEl ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        onClick={handleContextMenuClick}
      >
        <Badge hidden={showBadge}>
          <FontAwesomeIcon
            className="contextMenu"
            size="2x"
            icon={["fal", "ellipsis-v"]}
          />
        </Badge>
      </Button>
      <Menu
        disableScrollLock={true}
        id="basic-menu"
        anchorEl={anchorEl}
        open={handleOpen()}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item: { onClick: () => void; text: string }, i) => (
          <MenuItem
            key={i}
            onClick={(e) => handleMenuItemClick(e, item.onClick)}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ContextMenu;
