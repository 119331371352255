import _ from "lodash";
import { FirebaseService } from "./ServiceControler";
import Logger from "./Logger";
import { LoggedInUser } from "../models/user";

const logger = Logger("FeatureFlagService");
interface Feature {
  enabled: boolean;
  roles: string[];
  users: string[];
}

class FeatureFlagsService {
  private static instance: FeatureFlagsService;
  private featureFlags: { [feature: string]: Feature };

  constructor() {
    this.init();
  }

  private init = async () => {
    if (!this.featureFlags) {
      this.featureFlags = await FirebaseService.getFeatureFlags();
    }
  };

  public getAll = async () => {
    if (!this.featureFlags) {
      await this.init();
    }
    return this.featureFlags;
  };

  public isEnabled = (feature: string, user?: LoggedInUser | null) => {
    if (!this.featureFlags || !this.featureFlags[feature]) return false;
    let isAllowed;

    if (user && !_.isEmpty(this.featureFlags[feature].roles)) {
      isAllowed = this.featureFlags[feature].roles.includes(user.role);
    }

    if (
      user &&
      !isAllowed &&
      this.featureFlags[feature].users &&
      !_.isEmpty(this.featureFlags[feature].users)
    ) {
      isAllowed = this.featureFlags[feature].users.includes(user.id);
    }

    return (
      this.featureFlags[feature].enabled &&
      (_.isNil(isAllowed) ? true : isAllowed)
    );
  };

  public toggle = async (feature: string) => {
    try {
      await FirebaseService.toggleFeatureFlag(feature);
    } catch (err) {
      logger.error(err);
      throw err;
    }
  };

  public updateFeatureRoles = async (feature: string, roles: string[]) => {
    try {
      await FirebaseService.updateFeatureFlag(feature, { roles });
    } catch (err) {
      logger.error(err);
      throw err;
    }
  };

  public updateFeatureUsers = async (feature: string, users: string[]) => {
    try {
      await FirebaseService.updateFeatureFlag(feature, { users });
    } catch (err) {
      logger.error(err);
      throw err;
    }
  };

  public static getInstance = () => {
    if (!FeatureFlagsService.instance)
      FeatureFlagsService.instance = new FeatureFlagsService();
    return FeatureFlagsService.instance;
  };
}

export default FeatureFlagsService.getInstance();
