import { useState, useEffect } from "react";
//@ts-ignore
import createActivityDetector from "activity-detector";

export default function useActivity(
  options: ReturnType<createActivityDetector>
): boolean {
  const [isActive, setIsActive] = useState(true);
  useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on("active", () => setIsActive(true));
    activityDetector.on("idle", () => setIsActive(false));
    return (): void => activityDetector.stop();
  }, []);
  return isActive;
}
