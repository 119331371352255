import { countries } from "country-flag-icons";
import gematriya from "gematriya";
import { localeCodes } from "../models/localeCodes";

const excludedCountries = [
  "AC",
  "EU",
  "SZ",
  "TA",
  "TL",
  "XK",
  "UM",
  "GS",
  "SH",
  "IO",
];
const rtlLanguages = ["he", "he-IL", "ar", "ar-IL"];

const getCountries = (t: any) => {
  return countries
    .filter((c) => !excludedCountries.includes(c))
    .sort((a: string, b: string) =>
      t(`country_${a}`).localeCompare(t(`country_${b}`))
    )
    .map((c) => ({ label: t(`country_${c}`), value: c, icon: c }));
};

const getLanguages = (t: any) => {
  return localeCodes.map((l) => ({ label: t(l), value: l, icon: l }));
};

const getLanguageDirection = (lang: string) => {
  return rtlLanguages.includes(lang) ? "rtl" : "ltr";
};

const getDayByLang = (date: number, lang: string): string => {
  return lang && date
    ? new Date(date).toLocaleDateString(lang, { weekday: "long" })
    : "";
};

const getHebrewCalendar = (date: number) => {
  const year = parseInt(
    new Intl.DateTimeFormat("he", {
      // @ts-ignore
      calendar: "hebrew",
      year: "numeric",
    }).format(new Date(date))
  );

  const month = new Intl.DateTimeFormat("he", {
    // @ts-ignore
    calendar: "hebrew",
    month: "numeric",
  }).format(new Date(date));

  const day = parseInt(
    new Intl.DateTimeFormat("he", {
      // @ts-ignore
      calendar: "hebrew",
      day: "numeric",
    }).format(new Date(date))
  );

  return `${gematriya(day)} ${month} ${gematriya(year)}`;
};

const getIslamicCalendar = (date: number) => {
  return new Intl.DateTimeFormat("ar", {
    // @ts-ignore
    calendar: "islamic",
    dateStyle: "short",
  }).format(new Date(date));
};

const getRegularCalendar = (date: number): string => {
  return new Intl.DateTimeFormat(["ban", "id"]).format(new Date(date));
};

export {
  getCountries,
  getDayByLang,
  getLanguages,
  getLanguageDirection,
  getHebrewCalendar,
  getIslamicCalendar,
  getRegularCalendar,
};
