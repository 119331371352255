import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import i18n from "../../../i18n";

import EditorService from "../../../services/EditorService";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/rootReducer";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Input from "../Input/Input";

import { getLanguageDirection } from "../../../utils/locales";
import { useTranslation } from "react-i18next";
import { popIndicator } from "../../../store/system/actions";
import FeatureFlagsService from "../../../services/FeatureFlagsService";
import FirebaseService from "../../../services/FirebaseService";
import { ElasticJob, ExportOptions, RoomData } from "../../../models";
import { JobData } from "../../../models/job";
import classNames from "classnames";
import Logger from "../../../services/Logger";

import "./ExportModal.scss";

const logger = Logger("EditorPage");

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  meetings?: ElasticJob[] | null | { id: string; name: string }[];
  saveMeeting?: boolean;
  roomData?: RoomData | JobData;

  jobs?: { id: string; name: string }[];
  jobData?: JobData;
  onExport?: () => Promise<void>;
  frameRate?: number;
}

const ExportModal: FC<Props> = ({
  isOpen,
  setOpen,
  meetings,
  saveMeeting,
  roomData,
  jobs,
  jobData,
  onExport,
  frameRate: frameRateProp,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [format, setFormat] = useState(
    EditorService.subtitlesExportOptions[0].value
  );
  const [exportOptions, setExportOptions] = useState<ExportOptions>({
    subtitles: {
      split: true,
      autoBreak: false,
      embed: false,
      flip: false,
      frameRate: frameRateProp,
    },
    docx: {
      direction: "rtl",
      layout: "pages",
      times: false,
      interval: 0.5,
      timeOffset: "00:00:00",
      startIntervalWithSpeakerName: false,
    },
  });
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState(
    loggedInUser?.email ? loggedInUser.email : ""
  );
  const [sendToMail, setSendToMail] = useState(false);
  const [frameRate, setFrameRate] = useState(frameRateProp);

  useEffect(() => {
    if (!roomData) return;
    const outputDirection = EditorService.getLangDirection(
      roomData.lang.output[0]
    );
    setExportOptions({
      ..._.merge(exportOptions, {
        flip: outputDirection === "rtl",
      }),
    });
  }, [roomData]);

  useEffect(() => {
    const getFrameRateFromJobsClient = async () => {
      if (!frameRateProp && meetings && _.has(meetings[0], "clientid")) {
        const job = meetings[0] as ElasticJob;
        const clientFrameRate = await FirebaseService.getClientFrameRate(
          job.clientid
        );

        setFrameRate(clientFrameRate || 25);
      }
    };
    getFrameRateFromJobsClient();
  }, [meetings]);

  const validateEmail = (email: string) => {
    return email.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value: string = event.target.value as string;
    if (!["srt", "ass"].includes(value)) {
      setExportOptions({
        ..._.merge(exportOptions, {
          subtitles: {
            embed: false,
          },
        }),
      });
    }
    setFormat(value);
  };

  const callToExport = async () => {
    try {
      dispatch(popIndicator({ type: "info", txt: t("exporting") }));
      if (meetings?.length) {
        setOpen(false);
        const result = await EditorService.exportRoomToFileByType(
          meetings,
          format,
          exportOptions,
          saveMeeting,
          roomData,
          { sendToMail, email }
        );

        if (!result?.success) {
          dispatch(popIndicator({ type: "error", txt: t("exporting_fail") }));
          return;
        }
        dispatch(popIndicator({ type: "info", txt: t("exporting_success") }));
      }

      if (jobData || jobs?.length) {
        setOpen(false);

        if (onExport) {
          await onExport();
        }

        const result = await EditorService.exportJobs({
          jobs,
          type: format,
          options: exportOptions,
          jobData,
          mailInfo: { sendToMail, email },
        });

        if (!result?.success) {
          dispatch(popIndicator({ type: "error", txt: t("exporting_fail") }));
          return;
        }
        dispatch(popIndicator({ type: "info", txt: t("exporting_success") }));
      }
    } catch (error) {
      logger.error(error, "callToExport");
      dispatch(popIndicator({ type: "error", txt: t("exporting_fail") }));
    } finally {
      setOpen(false);
    }
  };

  return (
    <Dialog
      className={getLanguageDirection(i18n.language)}
      classes={{
        paper:
          "ExportModal jobSplitWarning selectModal warning paperDefaultSize",
      }}
      open={isOpen}
    >
      <DialogTitle>
        <div className="modalTitle">{t("job_export")}</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" style={{ left: "auto" }}>
              {t("export_as")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={format}
              label={t("role")}
              onChange={handleChange}
            >
              {EditorService.subtitlesExportOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.text)}
                </MenuItem>
              ))}
            </Select>
            {format === "docx" ? (
              <div className="docxOptions">
                <div className="docxLayout toggleContainer">
                  <ToggleButtonGroup
                    size="small"
                    value={exportOptions.docx.layout}
                    exclusive
                    style={{ backgroundColor: "white" }}
                    onChange={(e, value) => {
                      if (!value) return;
                      setExportOptions({
                        ..._.merge(exportOptions, {
                          docx: {
                            layout: value,
                          },
                        }),
                      });
                    }}
                  >
                    <ToggleButton value={"intervals"} key={"intervals"}>
                      {t("export_intervals")}
                    </ToggleButton>
                    <ToggleButton value={"pages"} key={"pages"}>
                      {t("pages")}
                    </ToggleButton>
                    <ToggleButton value={"minutes"} key={"minutes"}>
                      {t("minutes")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {exportOptions.docx.layout === "intervals" ? (
                  <>
                    <Input
                      className="inputContainer"
                      type="number"
                      min={0.5}
                      value={exportOptions.docx.interval}
                      onChange={(e) =>
                        setExportOptions({
                          ..._.merge(exportOptions, {
                            docx: {
                              interval: e.target.value,
                            },
                          }),
                        })
                      }
                    ></Input>
                    <div className="startIntervalWithSpeakerName checkboxContainer">
                      <input
                        type="checkbox"
                        checked={
                          exportOptions.docx.startIntervalWithSpeakerName
                        }
                        onChange={(e) =>
                          setExportOptions({
                            ..._.merge(exportOptions, {
                              docx: {
                                startIntervalWithSpeakerName: e.target.checked,
                              },
                            }),
                          })
                        }
                        id="startIntervalWithSpeakerName"
                        name="startIntervalWithSpeakerName"
                      />
                      <label>{t("intervals_start_with_speaker_name")}</label>
                    </div>
                    <Input
                      className="inputContainer"
                      type="number"
                      value={exportOptions.docx.timeOffset}
                      onChange={(e) =>
                        setExportOptions({
                          ..._.merge(exportOptions, {
                            docx: {
                              timeOffset: e.target.value,
                            },
                          }),
                        })
                      }
                    ></Input>
                  </>
                ) : (
                  <div className="docxTimes checkboxContainer">
                    <input
                      type="checkbox"
                      checked={exportOptions.docx.times}
                      onChange={(e) =>
                        setExportOptions({
                          ..._.merge(exportOptions, {
                            docx: {
                              times: e.target.checked,
                            },
                          }),
                        })
                      }
                      id="times"
                      name="times"
                    />
                    <label>{t("times")}</label>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="emailForm">
                  <div
                    className={classNames("checkboxContainer", { disabled })}
                  >
                    <input
                      type="checkbox"
                      disabled={disabled}
                      checked={sendToMail}
                      onChange={(e) => {
                        if (exportOptions.subtitles.embed) return;
                        setSendToMail(e.target.checked);
                      }}
                      id="sendToMail"
                      name="sendToMail"
                    ></input>
                    <label>{t("send_it_to_the_mail")}</label>
                  </div>
                  <Input
                    error={
                      sendToMail && !validateEmail(email)
                        ? t("enter_valid_email")
                        : null
                    }
                    disabled={!sendToMail}
                    placeholder={t("email") as string}
                    value={email}
                    required={true}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input>
                </div>
                {["srt", "ass"].includes(format) &&
                  FeatureFlagsService.isEnabled("embedSubtitlesCheckbox") && (
                    <div
                      className={classNames("checkboxContainer", {
                        disabled,
                      })}
                    >
                      <input
                        type="checkbox"
                        disabled={disabled}
                        checked={exportOptions.subtitles.embed}
                        onChange={(e) => {
                          setSendToMail(true);
                          setExportOptions({
                            ..._.merge(exportOptions, {
                              subtitles: {
                                embed: e.target.checked,
                              },
                            }),
                          });
                        }}
                        id="embed"
                        name="embed"
                      ></input>
                      <label>{t("embed")}</label>
                    </div>
                  )}
                {["srt", "txt", "ass", "vtt"].includes(format) &&
                  FeatureFlagsService.isEnabled("showFlipSubstilesOption") && (
                    <div
                      className={classNames("checkboxContainer", {
                        disabled,
                      })}
                    >
                      <input
                        type="checkbox"
                        disabled={disabled}
                        checked={exportOptions.subtitles.flip}
                        onChange={(e) =>
                          setExportOptions({
                            ..._.merge(exportOptions, {
                              subtitles: {
                                flip: e.target.checked,
                              },
                            }),
                          })
                        }
                        id="flip"
                        name="flip"
                      ></input>
                      <label>{t("fix_punctuation")}</label>
                    </div>
                  )}
                {exportOptions.subtitles.embed && (
                  <div className="embedding_msg">{t("embedding_msg")}</div>
                )}
                {format === "txt" && (
                  <div
                    className={classNames(
                      "framteRate field",
                      getLanguageDirection(i18n.language)
                    )}
                  >
                    <div className="framteRate label">{t("frame_rate")}</div>
                    <Input
                      type="number"
                      placeholder={t("frame_rate") as string}
                      value={frameRate}
                      required={true}
                      onChange={(e) =>
                        setExportOptions({
                          ..._.merge(exportOptions, {
                            subtitles: {
                              frameRate: e.target.value,
                            },
                          }),
                        })
                      }
                    ></Input>
                  </div>
                )}
              </>
            )}
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="buttons">
          <Button className="button cancel" onClick={() => setOpen(false)}>
            {t("cancel")}
          </Button>
          <Button
            className="button approve"
            variant="contained"
            onClick={callToExport}
            disabled={sendToMail && !validateEmail(email) ? true : false}
          >
            {t("export")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
