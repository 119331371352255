import React, { FC, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";
import Dropdown from "../Dropdown/Dropdown";

import "./OnClickDropdown.scss";

interface Option {
  label: string;
  value: any;
  icon: any;
}

interface Props {
  searchOption?: boolean;
  options: Option[];
  className?: string;
  placeholder: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  multiSelect?: boolean;
  onChange: (value: string[] | string) => void;
  dropdownClassName?: string;
  textClassName?: string;
}

const OnClickDropdown: FC<Props> = ({
  options,
  dropdownClassName,
  textClassName,
  onChange,
  placeholder,
  searchOption = true,
  multiSelect,
  isLoading,
  isDisabled,
}) => {
  const [show, setShow] = useState(false);
  const [pending, setPending] = useState(false);
  const mainElement = useRef<HTMLDivElement>(null);

  useOutsideClick(mainElement, () => {
    if (show) {
      setShow(!show);
    }
  });

  return (
    <div className="dropdown-container" ref={mainElement}>
      {pending ? (
        <div className="loading-spinner">
          <CircularProgress style={{ width: "20px" }} />
        </div>
      ) : show ? (
        <Dropdown
          options={options}
          className={dropdownClassName}
          onChange={async (value) => {
            setPending(true);
            setShow(false);
            await onChange(value);
            setPending(false);
          }}
          placeholder={placeholder}
          searchOption={searchOption}
          multiSelect={multiSelect}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      ) : (
        <div
          className={classNames(textClassName)}
          onClick={() => setShow(true)}
        >
          <span className="input">{placeholder}</span>
          <span className="editPen">
            {<FontAwesomeIcon icon={["fal", "pencil-alt"]} />}
          </span>
        </div>
      )}
    </div>
  );
};

export default OnClickDropdown;
