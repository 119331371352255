import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { popIndicator } from "../../store/system/actions";
import { Debt1 } from "../../models/payment";
import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PaymentsService from "../../services/PaymentsService";
import AddDebtForm from "../../components/AddDebtForm/AddDebtForm";
import "./PaymentsPages.scss";

const CustomPayment: FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<Record<string, string>>();
  const [isDisabled, setIsDisabled] = useState(false);

  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const addNewDebt = async (debt: Debt1) => {
    try {
      setIsDisabled(true);
      await PaymentsService.addDebts([debt]);
      history.push(`/transcriber/payments/${id}`);
      dispatch(
        popIndicator({ type: "success", txt: t("custom_payment_success") })
      );
    } catch (err) {
      console.log("Failed to pay custom", err);
      dispatch(
        popIndicator({ type: "success", txt: t("custom_payment_fail") })
      );
      throw new Error(err);
    }
  };

  return (
    <main className="main-container">
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason &&
        !errorReason &&
        loggedInUser &&
        loggedInUser.role === "super_user" && (
          <div className="payments-page flex column align-center">
            <PageHeader
              title={t("add_payment")}
              backButton={{
                title: t("transcriber"),
                backAction: () => history.push(`/transcriber/payments/${id}`),
              }}
            />
            <div className="payment-dialog flex column align-self-center">
              <AddDebtForm
                sendDebt={addNewDebt}
                userId={id}
                disabled={isDisabled}
              />
            </div>
          </div>
        )}
    </main>
  );
};

export default CustomPayment;
