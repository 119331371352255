import {
  ClientState,
  ClientActionTypes,
  SET_CURR_CLIENT,
  CLEAR_CURR_CLIENT,
  SET_CLIENTS,
  SET_MINIMAL_CLIENTS,
  CLEAR_CLIENTS,
  SET_CLIENTS_OPTIONS,
} from "./types";

const initialState: ClientState = {
  clients: [],
  minimalClientList: [],
  currClient: null,
  clientsOptions: [],
};

function clientReducer(
  state = initialState,
  action: ClientActionTypes
): ClientState {
  switch (action.type) {
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };
    case SET_CLIENTS_OPTIONS:
      return {
        ...state,
        clientsOptions: action.clients,
      };
    case SET_MINIMAL_CLIENTS:
      return {
        ...state,
        minimalClientList: action.minimalClients,
      };
    case CLEAR_CLIENTS:
      return {
        ...state,
        clients: [],
      };
    case SET_CURR_CLIENT:
      return {
        ...state,
        currClient: action.client,
      };
    case CLEAR_CURR_CLIENT:
      return {
        ...state,
        currClient: null,
      };
    default:
      return state;
  }
}

export default clientReducer;
