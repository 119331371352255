import React, { FC, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TimeService from "../../services/TimeService";
import FirebaseService from "../../services/FirebaseService";

import { AppState } from "../../store/rootReducer";
import { updateMeetingDeadline } from "../../store/meeting/actions";
import { MeetingPreviewData, PreviewFormat } from "../../models";

import ElasticGenericTable, {
  TableConfig,
} from "../Table/InfiniteGenericTable";
import EditableText from "../EditableText/EditableText";
import CircleProgress from "../common/CircleProgress/CircleProgress";
import Logger from "../../services/Logger";

import "./MeetingsTable.scss";

const logger = Logger("infinityMeetingsTable");
interface Props {
  data: { [key: string]: any }[];
  additionalSearchFields?: string[];
  hasMore: boolean;
  loadMore: (
    searchValue?: string,
    newSearch?: boolean,
    addedFilters?: {
      [key: string]: {
        value: string | number | string[] | Date | number[];
        type: "normal" | "range";
      };
    }
  ) => Promise<boolean | undefined>;
  dataCount?: number;
  rawData?: any[];
  orderTable?: (property: string, descOrAsc: "desc" | "asc") => void;
  onRowClick?: (row: { [key: string]: any }, event: React.MouseEvent) => void;
  onSelection?: (jobs: any[]) => void;
  config?: Partial<TableConfig>;
}

const InfiniteMeetingsTable: FC<Props> = ({
  additionalSearchFields,
  data,
  rawData,
  dataCount,
  orderTable,
  loadMore,
  onRowClick,
  onSelection,
  config,
  hasMore,
}) => {
  const { t } = useTranslation();
  const settings = useSelector((state: AppState) => state.userStore.settings);
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const defaultConfig: TableConfig = {
    tableName: "table",
    tableColumns: [],
    columns: {
      processProgress: { label: "", width: "50px" },
      name: { label: "table_meeting_name", width: "400px", ellipsis: true },
      previewFormat: { label: "table_meeting_format" },
      member: { label: "community_members", width: "280px" },
      editProgress: { label: "edit_progress", width: "50px" },
      status: { label: "status", width: "100px" },
      job: { label: "status" },
      meetingLength: { label: "table_meeting_length" },
      processProgressLastUpdate: { label: "uploaded", width: "100px" },
      deadline: {
        label: "table_meeting_deadline",
        width: "100px",
        ellipsis: true,
      },
      archivedAt: { label: "archive_at" },
      assignedTranscriber: { label: "transcriber" },
      assignedProofer: { label: "proofer" },
      lang: { label: "table_meeting_lang", width: "130px", ellipsis: true },
      role: { label: "role" },
      price: { label: "table_meeting_price", width: "150px" },
      prooferPrice: { label: "table_meeting_proofer_price", width: "150px" },
      representative: {
        label: "representative",
        width: "200px",
        ellipsis: true,
      },
      revenue: { label: t("revenue"), width: "140px" },
      invoiceSent: { label: t("invoice_sent"), width: "70px" },
      actions: { label: "", width: "200px" },
      contextMenu: { label: " ", width: "80px" },
    },
    formatters: {
      name: (name, meeting) => (
        <div className="fileName">
          <div className="jobName" title={name}>
            {name}
          </div>
          {loggedInUser?.role.includes("super_user") && meeting.clientName && (
            <div className="clientName">{meeting.clientName}</div>
          )}
        </div>
      ),
      member: (assignedTranscriber, meeting) => (
        <div className="memberCell">
          <div className="transcriberDiv">
            <span className="assignedTranscriber">
              {meeting.assignedTranscriber}
            </span>
            <div className="priceDiv">
              ({meeting.price && <span className="price">{meeting.price}</span>}
              <span className="currency">{t("currency")}</span>)
            </div>
          </div>
          <div className="prooferDiv">
            <span>{meeting.assignedProofer}</span>
            <div className="priceDiv">
              <span className="price">({meeting.prooferPrice}</span>
              <span className="currency">{t("currency")})</span>
            </div>
          </div>
        </div>
      ),
      processProgressLastUpdate: (processProgressLastUpdate) =>
        processProgressLastUpdate &&
        format(processProgressLastUpdate, "dd/MM/yyyy"),
      editProgress: (editProgress) => (
        <CircleProgress progress={editProgress} />
      ),
      deadline: (deadline: Date, meeting) => (
        <div className="deadlineCell">
          {deadline ? TimeService.getDeadlineString(deadline) : "0"}
        </div>
      ),
      previewFormat: (previewFormat: PreviewFormat, meeting) => (
        <div className="previewFormatCell">
          <div className="previewFormatDiv">{t(previewFormat)}</div>
          <div className="langDiv">{meeting.lang}</div>
        </div>
      ),
      prooferPrice: (price: number, meeting) =>
        price ? `${price} ${t(meeting.currency)}` : "-",
      status: (status: number) => (
        <div
          className={classNames(
            "status",
            { finished: status === 5 },
            { proofing: status === 4 },
            { transcribing: status === 3 }
          )}
        >
          <div>{status}</div>
        </div>
      ),
    },
    order: settings["order"],
    orderBy: settings["orderBy"],
    multiSelect: false,
    faded: (row: { [key: string]: any }) => (row.status < 3 ? true : false),
    actions: {
      export: {
        hidden: loggedInUser?.role !== "super_user",
      },
    },
    fieldsToFilter: ["price"],
  };

  const [tableConfig, setTableConfig] = useState<any>();
  const filterFieldsColumns = () => {
    const mergedConfig = _.merge(defaultConfig, config);
    let columns = {};
    const allTableColumns = _.keys(mergedConfig.columns);
    for (let i = 0; i < allTableColumns.length; i++) {
      if (
        mergedConfig.tableColumns &&
        mergedConfig.tableColumns.includes(allTableColumns[i])
      ) {
        columns = {
          ...columns,
          [allTableColumns[i]]: mergedConfig.columns[allTableColumns[i]],
        };
      }
    }
    const newTableConfig = { ...mergedConfig, columns: columns };
    setTableConfig(newTableConfig);
    return { ...newTableConfig, columns: columns };
  };

  return (
    <ElasticGenericTable
      data={data}
      dataCount={dataCount}
      rawData={rawData}
      additionalSearchFields={additionalSearchFields}
      config={tableConfig ? tableConfig : filterFieldsColumns()}
      className={"MeetingsTable"}
      loadMore={loadMore}
      onRowClick={onRowClick}
      orderTable={orderTable}
      hasMore={hasMore}
      onSelection={onSelection}
    ></ElasticGenericTable>
  );
};

export default InfiniteMeetingsTable;
