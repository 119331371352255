import { MeetingPreviewData, ArchivedMeetingData } from "./../models/meeting";
import { ClientPreviewData } from "./../models/client";
import { TranscriberPreviewData, MinimalTranscriber } from "./../models/user";
import i18next from "i18next";
import _ from "lodash";

function searchTranscribers(
  transcribers: MinimalTranscriber[] | TranscriberPreviewData[],
  searchBy: string
): MinimalTranscriber[] | TranscriberPreviewData[] {
  searchBy = searchBy.toLowerCase().trim();
  return transcribers.filter((transcriber) =>
    transcriber.username.toLowerCase().includes(searchBy)
  );
}

function searchClients(
  client: ClientPreviewData[],
  searchBy: string
): ClientPreviewData[] {
  searchBy = searchBy.trim();
  return client.filter((client) => {
    let correctLetters = 0;
    searchBy.split("").map((letter, idx) => {
      if (
        letter.toLocaleLowerCase() ===
        client.name.toLocaleLowerCase().split("")[idx]
      )
        correctLetters++;
    });
    if (searchBy.split("").length === correctLetters) return client;
  });
}

function searchMeetings(
  meetings: MeetingPreviewData[],
  searchBy: string
): MeetingPreviewData[] {
  searchBy = searchBy.trim();
  return meetings.filter((meeting) => {
    let correctLetters = 0;
    searchBy.split("").map((letter, idx) => {
      if (
        letter.toLocaleLowerCase() ===
        meeting.name.toLocaleLowerCase().split("")[idx]
      )
        correctLetters++;
    });
    if (searchBy.split("").length === correctLetters) return meeting;
  });
}

function searchPayments(
  payments: any[],
  previewMode: string,
  searchBy: string
): any[] {
  searchBy = searchBy.trim();
  return payments.filter((payment) => {
    let correctLetters = 0;
    searchBy.split("").map((letter, idx) => {
      if (previewMode === "debt") {
        if (
          letter.toLocaleLowerCase() ===
          payment.reason.toLocaleLowerCase().split("")[idx]
        )
          correctLetters++;
      } else {
        if (
          letter.toLocaleLowerCase() ===
          i18next.t(payment.month).toLocaleLowerCase().split("")[idx]
        )
          correctLetters++;
      }
    });
    if (searchBy.split("").length === correctLetters) return payment;
  });
}

function sortMeetings(
  meetings: MeetingPreviewData[],
  sortBy: string,
  order: string
): MeetingPreviewData[] {
  let fieldName = "";
  switch (sortBy) {
    case "by-deadline":
      fieldName = "deadline";
      break;
    case "by-length":
      fieldName = "meetingLength";
      break;
    case "by-members":
      fieldName = "speakers";
      break;
    case "by-price":
      fieldName = "price";
      break;
    case "by-progress":
      fieldName = "progress";
      break;
    default:
  }
  let sorted = meetings.sort((meeting1: any, meeting2: any): number => {
    if (!meeting1 || !meeting2) return 0;
    if (fieldName === "additionalMembers") {
      if (meeting1[fieldName].length > meeting2[fieldName].length) return 1;
      else if (meeting1[fieldName].length < meeting2[fieldName].length)
        return -1;
      else return 0;
    }
    if (fieldName === "deadline") {
      if (meeting1[fieldName].seconds > meeting2[fieldName].seconds) return 1;
      else if (meeting1[fieldName].seconds < meeting2[fieldName].seconds)
        return -1;
      else return 0;
    } else {
      if (meeting1[fieldName] > meeting2[fieldName]) return 1;
      else if (meeting1[fieldName] < meeting2[fieldName]) return -1;
      else return 0;
    }
  });
  if (order === "descending") sorted = sorted.reverse();
  return sorted;
}

function sortArchiveMeetings(
  meetings: ArchivedMeetingData[],
  sortBy: string,
  order: string
): ArchivedMeetingData[] {
  let fieldName = "";
  switch (sortBy) {
    case "by-title":
      fieldName = "name";
      break;
    case "by-date":
      fieldName = "archivedAt";
      break;
    case "by-owner":
      fieldName = "ownerName";
      break;
    default:
  }
  let sorted = meetings.sort((meeting1: any, meeting2: any): number => {
    if (meeting1[fieldName] > meeting2[fieldName]) return 1;
    else if (meeting1[fieldName] < meeting2[fieldName]) return -1;
    else return 0;
  });
  if (order === "descending") sorted = sorted.reverse();
  return sorted;
}

function sortTranscribers(
  transcribers: MinimalTranscriber[] | TranscriberPreviewData[],
  sortBy: string,
  order: string
): MinimalTranscriber[] {
  let fieldName = "";
  switch (sortBy) {
    case "by-title":
      fieldName = "username";
      break;
    case "by-meetings-count":
      fieldName = "currMeetingsCount";
      break;
    case "by-price":
      fieldName = "currDebt";
      break;
    default:
  }
  let sorted = _.sortBy(transcribers, fieldName);
  if (order === "descending") sorted = sorted.reverse();
  return sorted;
}

function sortClients(
  transcribers: ClientPreviewData[],
  sortBy: string,
  order: string
): ClientPreviewData[] {
  let fieldName = "";
  switch (sortBy) {
    case "by-title":
      fieldName = "name";
      break;
    case "by-meetings-count":
      fieldName = "currMeetingsCount";
      break;
    case "by-price":
      fieldName = "currDebt";
      break;
    default:
  }
  let sorted = transcribers.sort(
    (transcriber1: any, transcriber2: any): number => {
      if (transcriber1[fieldName] > transcriber2[fieldName]) return 1;
      else if (transcriber1[fieldName] < transcriber2[fieldName]) return -1;
      else return 0;
    }
  );
  if (order === "descending") sorted = sorted.reverse();
  return sorted;
}

function sortPayments(payments: any[], sortBy: string, order: string) {
  let fieldName = "";
  switch (sortBy) {
    case "by-price":
      fieldName = "sum";
      break;
    case "by-date":
      fieldName = "createdAt";
      break;
    default:
      break;
  }
  let sorted = payments.sort((payment1: any, payment2: any) => {
    if (payment1[fieldName] > payment2[fieldName]) return 1;
    else if (payment1[fieldName] < payment2[fieldName]) return -1;
    else return 0;
  });
  if (order === "descending") sorted = sorted.reverse();
  return sorted;
}

export default {
  searchTranscribers,
  searchMeetings,
  sortMeetings,
  sortTranscribers,
  sortClients,
  searchClients,
  sortPayments,
  searchPayments,
  sortArchiveMeetings,
};
