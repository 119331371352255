import { parsePhoneNumber } from "libphonenumber-js";
import { E164Number } from "libphonenumber-js/types";

import { differenceInMinutes, differenceInHours, isPast } from "date-fns";

export const secondsToTimeString = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = (seconds % 60).toFixed(3);
  let timeString = "";

  if (hrs > 0) {
    timeString += (hrs < 10 ? "0" : "") + hrs + ":";
  } else {
    timeString += "00:";
  }
  timeString +=
    (mins < 10 ? "0" : "") + mins + ":" + (Number(secs) < 10 ? "0" : "");

  timeString += "" + secs;

  return timeString;
};

export const getFormattedPhone = (number: string): E164Number => {
  const parsedPhoneNumber = parsePhoneNumber(number, "IL");
  const formattedPhoneNumber = parsedPhoneNumber.number;
  return formattedPhoneNumber;
};

export const formatPhoneByCountry = (number: string) => {
  const parsedPhoneNumber = parsePhoneNumber(number).formatInternational();
  return parsedPhoneNumber ? parsedPhoneNumber : "";
};

export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDeadlineStr = (deadline: Date) => {
  if (isPast(deadline)) return "00:00";
  const hours = differenceInHours(deadline, Date.now());
  const minutes = differenceInMinutes(deadline, Date.now()) % 60;
  let hoursZero = "";
  let minutesZero = "";
  if (hours < 10 && hours >= 0) hoursZero = "0";
  if (minutes < 10 && minutes >= 0) minutesZero = "0";
  return hoursZero + hours + ":" + minutesZero + minutes;
};

export const dataUrlToArrayBuffer = async (
  url: string
): Promise<ArrayBuffer> => {
  const res = await fetch(url);
  const blob = await res.blob();
  const buffer = await blob.arrayBuffer();
  return buffer;
};

export const fileSizeFormat = (size: number, suffix = true): string => {
  let formattedSize,
    sizeType = "";
  if (size / 1024 <= 1024) {
    formattedSize = (size / 1024).toFixed(1);
    sizeType = "kb";
  } else {
    formattedSize = (size / 1024 / 1024).toFixed(2);
    sizeType = "mb";
  }

  if (formattedSize === "0.0") {
    return "";
  }
  return suffix ? formattedSize + sizeType : formattedSize;
};
