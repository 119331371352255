import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatPhoneByCountry } from "../../utils/formatters";
import { AppState } from "../../store/rootReducer";
import { FilePicker } from "react-file-picker";
import { useTranslation } from "react-i18next";
import FirebaseService from "../../services/FirebaseService";
import {
  setLoggedInUserInfo,
  clearLoggedInUserInfo,
  setLoggedInUser,
  updateNotification,
  clearNotifications,
} from "../../store/user/actions";
import {
  clearLoadingReason,
  setLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import NotificationPreview from "../../components/NotificationPreview/NotificationPreview";
import InfoField from "../../components/InfoField/InfoField";
import { NotificationData } from "../../models/notification";
import DefaultAvatar from "../../assets/images/default-avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";

import ImageEditor from "../../components/ImageEditor/ImageEditor";
import NotificationService from "../../services/NotificationService";
import "./UserProfilePage.scss";

const UserProfilePage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loggedInUserInfo = useSelector(
    (state: AppState) => state.userStore.loggedInUserInfo
  );
  const userNotifications = useSelector(
    (state: AppState) => state.userStore.userNotifications
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );

  const [phoneStr, setPhoneStr] = useState<string>("");
  const [chosenImg, setChosenImg] = useState<null | File | string>(null);
  const [uploadProggress, setUploadProggress] = useState<boolean>(false);
  const [notificationsPreview, setNotificationsPreview] = useState<
    NotificationData[]
  >([]);

  useEffect(() => {
    if (loggedInUser) loadLoggedInUserInfo();
    return () => {
      dispatch(clearLoggedInUserInfo());
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (userNotifications.length > 0) {
      let sorted: NotificationData[] = [...userNotifications];
      sorted = sorted.sort((not1: NotificationData, not2: NotificationData) => {
        if (not1.creationTime > not2.creationTime) return -1;
        else if (not1.creationTime < not2.creationTime) return 1;
        else return 0;
      });
      setNotificationsPreview(sorted);
    }
  }, [userNotifications]);

  const loadLoggedInUserInfo = async () => {
    try {
      if (loggedInUser) {
        dispatch(setLoadingReason(t("bringing loggedin  user info")));
        const loggedInUserInfo = await FirebaseService.getLoggedInUserInfo(
          loggedInUser.id
        );
        if (loggedInUserInfo) {
          dispatch(setLoggedInUserInfo(loggedInUserInfo));
          setPhoneStr(getPhoneStr(loggedInUserInfo.phonenumber));
        }
      }
      dispatch(clearLoadingReason());
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };
  const getPhoneStr = (phone: string): string => {
    if (phone) {
      return formatPhoneByCountry(phone);
    } else return "";
  };

  const onApproveNotification = async (
    notification: NotificationData,
    onSuccess: () => void
  ) => {
    try {
      if (notification.id && loggedInUser) {
        await NotificationService.approveNotification(
          // update notification approved in firebase
          notification.id,
          loggedInUser.id
        );
        const newUserNotifications = [...userNotifications];
        newUserNotifications.find((not, idx) => {
          // update notification approved in app
          not.id === notification.id &&
            newUserNotifications[idx].approvedBy.push(loggedInUser.id);
        });
        dispatch(updateNotification(newUserNotifications));
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const closeImgEditor = () => {
    setChosenImg(null);
  };

  const uploadImage = async (imageBlob: Blob) => {
    try {
      if (loggedInUser?.id) {
        const displayUploadProgress = (transferred: number, total: number) => {
          Math.round((transferred / total) * 100);
        };
        setUploadProggress(true);
        const imgUrl = await FirebaseService.uploadImg(
          imageBlob,
          displayUploadProgress,
          loggedInUser.id
        );
        if (imgUrl) {
          const loggedInUserClone = { ...loggedInUser };
          loggedInUserClone.imgUrl = imgUrl;
          dispatch(setLoggedInUser(loggedInUserClone));
          closeImgEditor();
          setUploadProggress(false);
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const loggedInUserImg = loggedInUser?.imgUrl
    ? loggedInUser.imgUrl
    : DefaultAvatar;

  return (
    <main className="main-container">
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <>
          <h1 className="main-title user-profile">{t("profile")}</h1>
          <div className="user-profile-page">
            {!loadingReason && loggedInUserInfo && (
              <div className="user-info-wrapper">
                <div className="user-info">
                  {chosenImg && (
                    <div className="img-editor-wrapper">
                      <ImageEditor
                        image={chosenImg}
                        saveImage={uploadImage}
                        close={closeImgEditor}
                        isDisabled={uploadProggress}
                      />
                      {uploadProggress && <CircularProgress />}
                    </div>
                  )}
                  <div className="user-img">
                    <div className="img-wrapper">
                      <img src={loggedInUserImg} alt="" />
                    </div>
                    <FilePicker
                      extensions={["jpg", "png", "bmp", "tif"]}
                      maxSize={30}
                      onChange={setChosenImg}
                    >
                      <div className="camera">
                        <FontAwesomeIcon icon={["fas", "camera"]} />
                      </div>
                    </FilePicker>
                  </div>
                  <InfoField Icn={["fal", "at"]} text={loggedInUserInfo.mail} />
                  <InfoField
                    Icn={["fal", "phone"]}
                    text={phoneStr}
                    textDirection={"ltr"}
                  />
                  <InfoField
                    Icn={["fal", "shekel-sign"]}
                    text={loggedInUserInfo.currDebt.toLocaleString()}
                  />
                  <InfoField
                    Icn={["fal", "briefcase"]}
                    text={loggedInUserInfo.currMeetingsCount}
                  />
                </div>
              </div>
            )}
            {!loadingReason && loggedInUserInfo && (
              <div className="notifications">
                <div className="profileHeader">
                  {!loadingReason && notificationsPreview.length !== 0 && (
                    <button
                      onClick={async () => {
                        if (loggedInUser) {
                          try {
                            dispatch(
                              setLoadingReason(t("bringing_loggedin_user_info"))
                            );
                            loggedInUser.id &&
                              (await NotificationService.markAllUserNotificationsAsRead(
                                loggedInUser.id
                              ));
                            setNotificationsPreview([]);
                            dispatch(clearNotifications());
                            dispatch(clearLoadingReason());
                          } catch (err) {
                            dispatch(clearLoadingReason());
                            console.log(err);
                          }
                        }
                      }}
                    >
                      {t("mark_all_as_read")}
                    </button>
                  )}
                </div>
                {notificationsPreview.map((notification, i) => (
                  <NotificationPreview
                    approveNotification={(onSuccess) =>
                      onApproveNotification(notification, onSuccess)
                    }
                    isApproved={
                      loggedInUser &&
                      notification.approvedBy.includes(loggedInUser?.id)
                    }
                    notification={notification}
                    key={i}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </main>
  );
};

export default UserProfilePage;
