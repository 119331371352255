export const userRoles = [
  {
    label: "Super User",
    value: "super_user",
  },
  {
    label: "Transproofer",
    value: "transcriber+proofer",
  },
  {
    label: "Transcriber",
    value: "transcriber",
  },
  {
    label: "Proofer",
    value: "proofer",
  },
];
