import {
  LoggedInUserInfo,
  LoggedInUser,
  MinimalTranscriber,
  TranscriberPreviewData,
  CurrTranscriberInfo,
  Settings,
  MinimalProofer,
} from "../../models/user";
import { NotificationData } from "../../models/notification";
import { DropdownOption } from "../../components/common/Dropdown/Dropdown";

export interface UserState {
  loggedInUser: LoggedInUser | null;
  loggedInUserInfo: LoggedInUserInfo | null;
  transcribers: any[];
  proofers: any[];
  transcribersOptions: DropdownOption[];
  proofersOptions: DropdownOption[];
  userNotifications: NotificationData[];
  currTranscriber: CurrTranscriberInfo | null;
  settings: Settings;
}

export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const SET_LOGGED_IN_USER_INFO = "SET_LOGGED_IN_USER_INFO";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_LOGGED_IN_USER = "CLEAR_LOGGED_IN_USER";
export const CLEAR_LOGGED_IN_USER_INFO = "CLEAR_LOGGED_IN_USER_INFO";
export const SET_TRANSCRIBERS = "SET_TRANSCRIBERS";
export const SET_CURR_TRANSCRIBER = "SET_CURR_TRANSCRIBER";
export const SET_TRANSCRIBERS_OPTIONS = "SET_TRANSCRIBERS_OPTIONS";
export const SET_PROOFERS_OPTIONS = "SET_PROOFERS_OPTIONS";
export const CLEAR_CURR_TRANSCRIBER = "CLEAR_CURR_TRANSCRIBER";
export const CLEAR_TRANSCRIBERS = "CLEAR_TRANSCRIBERS";
export const UPDATE_TRANSCRIBER_ROLE = "UPDATE_TRANSCRIBER_ROLE";
export const GET_TRANSCRIBERS = "GET_TRANSCRIBERS";
export const GET_PROOFERS = "GET_PROOFERS";
export const SET_PROOFERS = "SET_PROOFERS";

interface SetLoggedInUser {
  type: typeof SET_LOGGED_IN_USER;
  user: LoggedInUser;
}
interface UpdateSettings {
  type: typeof UPDATE_SETTINGS;
  settings: Settings;
}
interface SetLoggedInUserInfo {
  type: typeof SET_LOGGED_IN_USER_INFO;
  loggedInUserInfo: LoggedInUserInfo;
}

interface UpdateNotification {
  type: typeof UPDATE_NOTIFICATION;
  notifications: NotificationData[];
}

interface PushNotification {
  type: typeof PUSH_NOTIFICATION;
  notification: NotificationData;
}

interface ClearNotifications {
  type: typeof CLEAR_NOTIFICATIONS;
}

interface ClearLoggedInUser {
  type: typeof CLEAR_LOGGED_IN_USER;
}

interface ClearLoggedInUserInfo {
  type: typeof CLEAR_LOGGED_IN_USER_INFO;
}

interface SetTranscribers {
  type: typeof SET_TRANSCRIBERS;
  transcribers: MinimalTranscriber[] | TranscriberPreviewData[];
}

interface SetProofers {
  type: typeof SET_PROOFERS;
  proofers: MinimalProofer[];
}

interface SetTranscribersOptions {
  type: typeof SET_TRANSCRIBERS_OPTIONS;
  transcribersOptions: DropdownOption[];
}

interface SetProofersOptions {
  type: typeof SET_PROOFERS_OPTIONS;
  proofersOptions: DropdownOption[];
}

interface UpdateTranscriberRole {
  type: typeof UPDATE_TRANSCRIBER_ROLE;
  transcriber: MinimalTranscriber | TranscriberPreviewData;
  newRole: string;
}

interface clearTranscribers {
  type: typeof CLEAR_TRANSCRIBERS;
}

interface SetCurrTranscriber {
  type: typeof SET_CURR_TRANSCRIBER;
  transcriber: CurrTranscriberInfo;
}

interface GetTranscribers {
  type: typeof GET_TRANSCRIBERS;
  transcribers: MinimalTranscriber[];
}

interface GetProofers {
  type: typeof GET_PROOFERS;
  proofers: MinimalProofer[];
}

interface clearCurrTranscriber {
  type: typeof CLEAR_CURR_TRANSCRIBER;
}

export type UserActionTypes =
  | UpdateSettings
  | SetLoggedInUser
  | ClearLoggedInUser
  | SetTranscribers
  | UpdateTranscriberRole
  | clearTranscribers
  | SetLoggedInUserInfo
  | ClearLoggedInUserInfo
  | UpdateNotification
  | SetCurrTranscriber
  | clearCurrTranscriber
  | PushNotification
  | GetTranscribers
  | GetProofers
  | SetProofers
  | SetTranscribersOptions
  | SetProofersOptions
  | ClearNotifications;
