import React, { FC, useState, useEffect } from "react";
import classNames from "classnames";
import config from "../../config";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import logo from "../../assets/sumit-logo-white.png";
import DefaultAvatar from "../../assets/images/default-avatar.jpg";
import NavigationMenu from "./NavigationMenu";
import NavigationButton from "./NavigationButton";
import { AppState } from "../../store/rootReducer";

import FirebaseService from "../../services/FirebaseService";

import "./TopBar.scss";

interface Props {
  hidden?: boolean;
}

const TopBar: FC<Props> = ({ hidden }): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const userNotifications = useSelector(
    (state: AppState) => state.userStore.userNotifications
  );

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [unapprovedCount, setUnapprovedCount] = useState<number>(0);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.code === "Escape") {
        setIsMenuOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      let count = 0;
      userNotifications.forEach((notification) => {
        if (!notification.approvedBy.includes(loggedInUser.id)) count++;
      });
      setUnapprovedCount(count);
    }
  }, [userNotifications]);

  const logout = () => {
    FirebaseService.auth.signOut();
  };

  const goHome = () => {
    if (loggedInUser && !!loggedInUser.role) history.push("/");
  };

  const goToProfile = () => {
    if (loggedInUser) history.push("/my-profile");
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  history.listen(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  const loggedInUserImg = loggedInUser?.imgUrl
    ? loggedInUser.imgUrl
    : DefaultAvatar;

  let greetingStr = "";
  const currHour = parseInt(format(Date.now(), "HH"));
  if (currHour >= 4 && currHour < 12) greetingStr = t("greeting_morning");
  else if (currHour >= 12 && currHour < 18) greetingStr = t("greeting_noon");
  else if (currHour >= 18 && currHour < 22) greetingStr = t("greeting_evening");
  else if (currHour >= 22 || (currHour >= 0 && currHour < 4))
    greetingStr = t("greeting_night");

  return (
    <>
      <div className={classNames("top-bar", { hidden })}>
        <div className="navigation-container">
          {loggedInUser && (
            <>
              <NavigationButton
                isMenuOpen={isMenuOpen}
                onToggleMenu={toggleMenu}
              />
              <div className="userContainer">
                <div className="user-img">
                  {unapprovedCount > 0 && (
                    <div className="notifications-count">
                      <p>{unapprovedCount}</p>
                    </div>
                  )}
                  <div onClick={goToProfile} className="img-wrapper">
                    <img src={loggedInUserImg} alt="" />
                  </div>
                </div>
                <h3 className="username">
                  {t(greetingStr) + " " + loggedInUser.username}
                </h3>
              </div>
            </>
          )}
        </div>
        <div className="logo-wrapper">
          <img src={logo} alt="logo" className="logo" onClick={goHome} />
          {config.environment !== "production" && (
            <div className="environment">{config.environment}</div>
          )}
        </div>
      </div>
      {loggedInUser && (
        <NavigationMenu
          currUserRole={loggedInUser.role}
          isMenuOpen={isMenuOpen}
          logOut={logout}
          toggleMenu={toggleMenu}
        />
      )}
    </>
  );
};

export default TopBar;
