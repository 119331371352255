import React from "react";

export interface HeaderProps {
  logoFirstPage: JSX.Element;
  logoOtherPages: JSX.Element;
  jobNameFirstPage: JSX.Element;
  jobNameOtherPages: JSX.Element;
  jobDateFirstPage: JSX.Element;
  jobDateOtherPages: JSX.Element;
}

export const Header = ({
  logoFirstPage,
  logoOtherPages,
  jobNameFirstPage,
  jobNameOtherPages,
  jobDateFirstPage,
  jobDateOtherPages,
}: HeaderProps) => {
  return (
    <div className="pageHeader">
      {logoFirstPage} {logoOtherPages} {jobNameFirstPage} {jobNameOtherPages}
      {jobDateFirstPage} {jobDateOtherPages}
    </div>
  );
};
