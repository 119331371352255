import { NotificationData } from "../../models";

export const userNotification: NotificationData = {
  info: {
    roomName: "לשכת עודים 1309",
    sentBy: {
      id: "pDNxvpIAgNRRCMYBSKmZCcucei63",
      name: "proofman",
    },
  },
  approvedBy: [],
  event: "requestTranscribe",
  recipient: "super_user",
  creationTime: new Date(1635177923),
  id: "19ky1vz085",
};
