import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { UseFormSetValue } from "react-hook-form";

import "./DynamicToggleSelection.scss";

export interface DynamicToggleSelectionProps {
  options: { value: string; label: string; content: any }[];
  isMulti: boolean;
  controller?: {
    name: any;
    setValue: UseFormSetValue<any>;
    field: any;
  };
  disabled?: boolean;
}

export const DynamicToggleSelection = ({
  options,
  isMulti,
  controller,
  disabled,
}: DynamicToggleSelectionProps) => {
  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (controller) {
      if (newValue === null && !isMulti) return;
      controller.setValue(controller.name, newValue);
    }
  };

  return (
    <div className="DynamicSelection">
      <ToggleButtonGroup
        {...controller?.field}
        size="small"
        exclusive={!isMulti}
        onChange={handleValueChange}
      >
        {options.map((option, i) => (
          <ToggleButton value={option.value} key={i} disabled={disabled}>
            <span className="selectionContent">{option.content}</span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
