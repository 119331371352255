import React, { FC } from "react";
import "./InfoField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  onClick?: () => void;
  Icn: IconProp;
  text: string | number;
  className?: string;
  textDirection?: string;
}

const InfoField: FC<Props> = ({
  onClick,
  className,
  Icn,
  text,
  textDirection,
}): JSX.Element => {
  const clicked = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div
        onClick={clicked}
        className={`info-field ${className ? className : ""} ${
          onClick ? "clickable" : ""
        }`}
      >
        <div className="icn-wrapper">
          <FontAwesomeIcon icon={Icn} />
        </div>
        <p className={textDirection ? textDirection : ""}>{text}</p>
      </div>
    </>
  );
};

export default InfoField;
