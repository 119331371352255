import React, { InputHTMLAttributes } from "react";
import { FieldErrors } from "react-hook-form";

import "./TextInput.scss";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  step?: number;
  controller?: {
    errors: FieldErrors<any>;
    field: any;
    name: any;
    setValue: any;
  };
}

export const TextInput = ({ controller, step, ...props }: TextInputProps) => {
  return (
    <div className="TextInput">
      <input
        {...controller?.field}
        {...props}
        type={props.type ? props.type : "text"}
        step={step}
        onChange={(e) => {
          if (controller) {
            if (props.type === "number") {
              controller.setValue(controller.name, Number(e.target.value));
            } else {
              controller.setValue(controller.name, e.target.value);
            }
          }
        }}
      />
    </div>
  );
};
