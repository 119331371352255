import { PaymentsData, PaymentMonthPreviewData } from "../../models/payment";

export interface PaymentState {
  rawPaymentsData: null | PaymentsData;
  paymentsByMonths: PaymentMonthPreviewData[];
  userDebt: any;
}
export const SET_RAW_PAYMENTS_DATA = "SET_RAW_PAYMENTS_DATA";
export const CLEAR_RAW_PAYMENTS_DATA = "CLEAR_RAW_PAYMENTS_DATA";
export const SET_PAYMENTS_BY_MONTHS = "SET_PAYMENTS_BY_MONTHS";
export const CLEAR_PAYMENTS_BY_MONTHS = "CLEAR_PAYMENTS_BY_MONTHS";
export const SET_USER_DEBT = "SET_USER_DEBT";

interface SetRawPaymentsData {
  type: typeof SET_RAW_PAYMENTS_DATA;
  payments: PaymentsData;
}

interface SetUserDebt {
  type: typeof SET_USER_DEBT;
  debts: any[];
}

interface ClearRawPaymentsData {
  type: typeof CLEAR_RAW_PAYMENTS_DATA;
}

interface SetPaymentsByMonths {
  type: typeof SET_PAYMENTS_BY_MONTHS;
  paymentsByMonths: PaymentMonthPreviewData[];
}

interface ClearPaymentsByMonths {
  type: typeof CLEAR_PAYMENTS_BY_MONTHS;
}

export type PaymentActionTypes =
  | SetRawPaymentsData
  | ClearRawPaymentsData
  | SetPaymentsByMonths
  | ClearPaymentsByMonths
  | SetUserDebt;
