import config from "./config";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import axios from "axios";

import translationAR from "./locales/ar/translationAR";
import translationEN from "./locales/en/translationEN";
import translationHE from "./locales/he/translationHE";

const EXPIRATION_TIME = 1 * 60 * 60 * 1000; //  1 hours
const LOCAL_TRANSLATIONS = {
  en: translationEN,
  he: translationHE,
  ar: translationAR,
};
const LANGUAGES = ["en", "he", "ar"];

const options = {
  caches: ["localStorage"],
  supportedLngs: LANGUAGES,
  nonExplicitSupportedLngs: false,
  preload: LANGUAGES,
  lng: localStorage.i18nextLng,
  fallbackLng: "en",
  load: "all",
  interpolation: {
    escapeValue: false,
  },
  keySeperator: false,
  backend: {
    backends: [LocalStorageBackend, HttpBackend],
    backendOptions: [
      {
        expirationTime: EXPIRATION_TIME,
      },
      {
        loadPath: (lngs) => ({ lngs }),
        request: async (options, loadPath, payload, callback) => {
          try {
            const currentLng = loadPath.lngs[0];
            const lngUrl = `${config.storage.url}${config.storage.buckets.translations}${currentLng}.json?alt=media`;
            let mergedLngJson = {};

            const result = await axios.get(lngUrl);
            Object.assign(LOCAL_TRANSLATIONS[currentLng], result.data);
            mergedLngJson = LOCAL_TRANSLATIONS[currentLng];

            callback(null, {
              status: result.status,
              data: JSON.stringify(mergedLngJson),
            });
          } catch (err) {
            callback(err, null);
          }
        },
      },
    ],
  },
};

i18n.use(ChainedBackend).use(detector).use(initReactI18next).init(options);

export default i18n;
