import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import stc from "string-to-color";

import { SpeakerSample } from "../../models";

import "./SpeakerSamples.scss";

const SpeakerSamples: FC<{
  speakers: SpeakerSample[] | null;
}> = ({ speakers }) => {
  const { t } = useTranslation();
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playingSample, setPlayingSample] = useState<string | null>();

  const togglePlay = () => {
    if (audio) {
      audio.play();
    }
  };
  useEffect(togglePlay, [audio]);

  const playSpeakerSample = (speaker: SpeakerSample) => {
    if (!playingSample) {
      setPlayingSample(speaker.id);
      setAudio(new Audio(speaker.sample));
    } else {
      audio?.pause();
      setPlayingSample(null);
      setAudio(null);
    }
  };

  return (
    <div className={classNames("SpeakerSamples")}>
      {_.isNull(speakers) ? (
        <div className="loading">{t("loading_speakers")}</div>
      ) : (
        <div className="speakers">
          {_.isEmpty(speakers)
            ? t("no_speakers")
            : _.map(speakers, (speaker: SpeakerSample, i) => (
                <div className="speaker" key={i}>
                  <div
                    className={classNames("playButton", {
                      stop: playingSample === speaker.id,
                    })}
                    onClick={() => playSpeakerSample(speaker)}
                  >
                    <FontAwesomeIcon
                      icon={[
                        "fas",
                        playingSample === speaker.id ? "stop" : "play",
                      ]}
                      size={"xs"}
                    />
                  </div>
                  <div
                    className="speakerName"
                    style={{ color: stc(speaker.name) }}
                  >
                    {speaker.name}
                  </div>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default SpeakerSamples;
