import { Dispatch } from "redux";
import { SortingOptions, Sorting } from "../../models/sorting";
import { getLanguageDirection } from "../../utils/locales";
import {
  SET_LOADING_REASON,
  CLEAR_LOADING_REASON,
  SET_ERROR_REASON,
  POP_INDICATOR,
  RESET_SORTING,
  UPDATE_SORTING,
  SystemActionTypes,
  SET_DIRECTION,
} from "./types";

export function setLoadingReason(loadingReason: string): SystemActionTypes {
  return {
    type: SET_LOADING_REASON,
    loadingReason,
  };
}

export function setDirection(language: string): SystemActionTypes {
  const direction = getLanguageDirection(language);
  return {
    type: SET_DIRECTION,
    direction,
  };
}

export function clearLoadingReason(): SystemActionTypes {
  return {
    type: CLEAR_LOADING_REASON,
  };
}

export function setErrorReason(errorReason: string | null): SystemActionTypes {
  return {
    type: SET_ERROR_REASON,
    errorReason,
  };
}

export const popIndicator = (
  indicator: { type: string; txt: string; sticky?: boolean } | null
) => (dispatch: Dispatch<SystemActionTypes>): void => {
  dispatch({
    type: POP_INDICATOR,
    indicator,
  });
  if (!indicator?.sticky) {
    setTimeout(
      () =>
        dispatch({
          type: POP_INDICATOR,
          indicator: null,
        }),
      3000
    );
  }
};

export function resetSorting(type: string): SystemActionTypes {
  const options = new SortingOptions(type).optionsArr;
  const order =
    type === "payments" || type === "archive" ? "descending" : "ascending";
  const sorting = {
    type,
    options,
    sortBy: options[0],
    order,
  };
  return {
    type: RESET_SORTING,
    sorting,
  };
}

export function updateSorting(newSorting: Sorting): SystemActionTypes {
  return {
    type: UPDATE_SORTING,
    newSorting,
  };
}
