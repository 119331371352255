import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AuditData } from "../../../models";

import "./AuditEvent.scss";

interface Props {
  auditEvent: AuditData;
}

const AuditEvent: FC<Props> = ({ auditEvent }) => {
  const { t } = useTranslation();

  return (
    <div className="Audit">
      <div className="typeAndTriggeredBy">
        <div className="type">{t(auditEvent.type)}</div>
        <div className="name">{auditEvent.username}</div>
      </div>
      <div className="dateAndTime">
        <div className="date">{auditEvent.date}</div>
        <div className="time">{auditEvent.time}</div>
      </div>
    </div>
  );
};

export default AuditEvent;
