import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AppState } from "../../store/rootReducer";
import AuthService from "../../services/AuthService";

import { getFormattedPhone } from "../../utils/formatters";

import BackButton from "../../components/common/BackButton/BackButton";

import SignUpStep from "./SignUpStep";
import PhoneVerifyStep from "./PhoneVerifyStep";
import ReAuth from "./ReAuth";

import "./AuthPages.scss";

const LoginPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [mfaStepVerificationId, setMfaStepVerificationId] = useState<
    firebase.default.auth.ConfirmationResult | undefined | string
  >();
  const [shouldReAuth, setShouldReAuth] = useState(false);
  const [phonenumber, setPhonenumber] = useState<string>();
  const [error, setError] = useState<string>();

  const handleSignUp = async (email: string, password: string) => {
    await AuthService.login(email, password);
    setPhonenumber(phonenumber);
  };

  const handleRequestEnrollMultiFactor = async (phonenumber: string) => {
    try {
      const formattedPhone = getFormattedPhone(phonenumber).toString();
      const verificationId = await AuthService.requestEnrollMultiFactor(
        formattedPhone
      );
      setMfaStepVerificationId(verificationId);
      return verificationId;
    } catch (err: any) {
      if (err === "auth/requires-recent-login") {
        setShouldReAuth(true);
      }
    }
  };

  const handleCompleteEnrollMultiFactor = async (code: string) => {
    setError("");
    try {
      if (loggedInUser?.id && mfaStepVerificationId && phonenumber) {
        await AuthService.completeEnrollMultiFactor(
          mfaStepVerificationId,
          code,
          phonenumber,
          loggedInUser?.id
        );
        history.go(0);
      }
    } catch (err: any) {
      if (err === "auth/requires-recent-login") {
        setShouldReAuth(true);
      }
      setError(t(err));
    }
  };

  const steps = [
    {
      label: t("sign_up_step_details"),
      states: ["", "logged_out"],
      step: <SignUpStep onSignUp={handleSignUp} />,
    },
  ];

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  useEffect(() => {
    AuthService.initLogin();
    if (!loggedInUser) return;

    history.push("/");
  }, [loggedInUser]);

  return (
    <div className="SignUpPage">
      <div className="authContainer">
        {shouldReAuth ? (
          <ReAuth />
        ) : (
          <div className="currentStepContainer">{steps[0].step}</div>
        )}
        <BackButton
          backButton={{
            title: t("back"),
            backAction: history.goBack,
          }}
        />
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};

export default LoginPage;
