import axios from "axios";
import WaveSurfer from "wavesurfer.js";
//@ts-ignore
import markers from "wavesurfer.js/dist/plugin/wavesurfer.markers.js";
//@ts-ignore
import regions from "wavesurfer.js/dist/plugin/wavesurfer.regions.js";
//@ts-ignore
import cursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor.js";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import { MarkerParams } from "wavesurfer.js/src/plugin/markers";

import { RoomAsset } from "../models";
import { MarkersData } from "../models/markers";

import EditorService from "./EditorService";
import TimeService from "./TimeService";
import TrackingService from "./TrackingService";
import CloudFunctionsService from "./CloudFunctionsService";

import { secondsToTimeString } from "../utils/formatters";

import Logger from "../services/Logger";
const logger = Logger("SoundManager");

const WaveSurferConfV2: WaveSurferParams = {
  container: "#waveform",
  progressColor: "#ffb402",
  height: 160,
  backend: "MediaElement",
  scrollParent: true,
  fillParent: true,
  hideScrollbar: false,
  minPxPerSec: 20,
  pixelRatio: 1,
  autoCenter: true,
  barHeight: 1.5,
  barMinHeight: 1,
  barWidth: 1,
};

const WaveSurferConfV3: WaveSurferParams = {
  container: "#waveform",
  progressColor: "#ffb402",
  backend: "MediaElement",
  scrollParent: true,
  hideScrollbar: false,
  autoCenter: true,
  barHeight: 1,
  responsive: true,
  barMinHeight: 0.5,
  normalize: true,
};

class SoundManager {
  private static instance: SoundManager;
  public media: HTMLAudioElement | HTMLVideoElement;
  public frameRate = 25;
  public frameLength = 1 / this.frameRate;
  public jobDuration = 0;
  private mediaOffset = 0;
  public isStream = false;
  public updatingStream = false;
  public status: "playing" | "buffering" | "ended" | null;

  public wavesurfer: WaveSurfer | null = null;

  public get isPlaying() {
    return this.media && !this.media.paused;
  }

  public get currentTime() {
    return this.media && this.media.currentTime;
  }

  public setOffset = (offset: number) => {
    if (!this.media) return;

    this.media.currentTime = offset + this.mediaOffset;
  };

  public playOffset = async (offset?: number) => {
    if (!this.media) return;
    this.media.currentTime = this.mediaOffset + (offset || 0);
    return this.play(true);
  };

  public setSubtitles = (subtitles: string) => {
    const track = document.querySelector("#track") as HTMLTrackElement;
    if (track) {
      track.src = `data:text/vtt;base64,${subtitles}`;
    }
  };

  public play = (playedWithOffset = false) => {
    if (!this.media) return;
    try {
      if (!playedWithOffset) {
        TrackingService.reportEvent("media_play");
      }
      return this.media.play();
    } catch (err) {
      logger.error(err, "play failed");
    }
  };

  public togglePlay = (offset?: number) => {
    try {
      if (this.isPlaying) {
        return this.pause();
      } else {
        return this.playRelative(offset || 0);
      }
    } catch (err) {
      logger.error(err, "togglePlay failed");
    }
  };

  public playRelative = (relativeOffset: number) => {
    if (!this.media) return;

    let offset = this.media.currentTime + relativeOffset;
    offset = Math.max(offset, 0);

    TrackingService.reportEvent("media_play", { jump: relativeOffset });
    return this.playOffset(offset);
  };

  public pause = () => {
    if (!this.media) return;
    try {
      TrackingService.reportEvent("media_pause");
      return this.media.pause();
    } catch (err) {
      logger.error(err, "pause failed");
    }
  };

  public initializeMedia = async ({
    media,
    setMedia,
    setMediaLength,
    setCurrTime,
    mediaLength,
    offset = 0,
    videoContainerId = "videoPlayerContainer",
    generalOffset,
    autoPlay = true,
    isStreaming = false,
    jobId,
  }: {
    media: RoomAsset;
    setMedia: (newMedia: RoomAsset) => void;
    setMediaLength: (mediaLength: number) => void;
    setCurrTime: (currTime: number) => void;
    mediaLength: number;
    offset?: number;
    videoContainerId?: string;
    generalOffset?: number;
    autoPlay?: boolean;
    isStreaming?: boolean;
    jobId: string;
  }) => {
    this.mediaOffset = generalOffset || 0;
    this.pause();

    this.media = SoundManager.Video(media.src, videoContainerId);

    this.media.onloadedmetadata = async () => {
      setMediaLength(this.media.duration);
      this.jobDuration = this.media.duration;
      if (autoPlay) {
        this.play();
      }
    };
    this.media.ontimeupdate = () => {
      setCurrTime(this.media.currentTime + offset);

      if (isStreaming && !this.updatingStream && !this.media.paused) {
        this.updateStream(jobId, setMedia);
      }
    };
    this.media.onerror = (err) => {
      console.error("Media player failed to play", err);
      const currentTime = this.currentTime;
      this.media.load();
      this.playOffset(currentTime);
    };
  };

  public initWaveform = async (
    jobId: string,
    duration: number,
    peaks?: number[] | string | null
  ) => {
    const waveformContainer = document.getElementById("waveform");
    if (!waveformContainer || this.wavesurfer) return;

    const videoPlayer = document.getElementById(
      "videoPlayer"
    ) as HTMLVideoElement;

    if (!videoPlayer) return;

    this.wavesurfer = WaveSurfer.create({
      ...WaveSurferConfV3,
      duration: duration || videoPlayer.duration,
      plugins: [
        regions.create({
          regionsMinLength: 0,
        }),
        cursor.create({
          showTime: true,
          opacity: 0.6,
          zIndex: 99,
          formatTimeCallback: secondsToTimeString,
          customShowTimeStyle: {
            backgroundColor: "#000",
            color: "#fff",
            padding: "3px",
            fontSize: "14px",
          },
        }),
        markers.create({
          markers: [],
        }),
      ],
    });

    const serverPeaks = await EditorService.getJobPeaks(jobId);
    this.wavesurfer.load(videoPlayer, serverPeaks || (peaks as number[]));

    TrackingService.reportEvent("waveform_init");
  };

  public clearWaveformRanges = () => {
    if (!this.wavesurfer) return;
    this.wavesurfer.regions.clear();
  };

  public createWaveformRanges = (
    ranges: { rangeIndex: number; start: number; end: number }[],
    rangesWords?: string[]
  ) => {
    if (!this.wavesurfer || !this.wavesurfer.regions) return;

    this.clearWaveformRanges();

    for (const [i, range] of ranges.entries()) {
      const regionData = {
        id: i.toString(),
        start: range.start,
        end: range.end,
        data: {
          startLimit: i > 0 ? ranges[i - 1].end + 0.0 : 0, // TODO change 0.0 to configurable number when using remote config
          endLimit:
            i !== ranges.length - 1
              ? ranges[i + 1].start - 0.0 // TODO change 0.0 to configurable number when using remote config
              : null,
          rangeIndex: range.rangeIndex,
        },
        attributes: {
          rangeText: "",
          rangeTimes: `${TimeService.getTimeStringFromSecs(
            range.end,
            true,
            true
          )} - ${TimeService.getTimeStringFromSecs(range.start, true, true)}`,
        },
      };
      if (rangesWords) {
        regionData.attributes.rangeText = rangesWords[i];
      }
      this.wavesurfer.addRegion(regionData);
    }
  };

  public createWaveformMarkers = (
    markers: MarkersData,
    onMarkerClick?: (marker: MarkerParams) => void
  ) => {
    if (!this.wavesurfer || !this.wavesurfer.markers) return;

    for (const marker of markers) {
      this.wavesurfer.markers.add({
        time: marker.start,
        color: "red",
      });
    }

    if (onMarkerClick) {
      this.wavesurfer.on("marker-click", (marker) => onMarkerClick(marker));
    }
  };

  public changeSpeed = (speed: number) => {
    if (!this.media) return;

    this.media.playbackRate = speed;
  };
  public getMediaOffset = () => this.mediaOffset;

  private static Video(src: string, videoContainerId: string) {
    let videoPlayer = document.getElementById(
      "videoPlayer"
    ) as HTMLVideoElement;

    if (videoPlayer) {
      videoPlayer.src = src;
      return videoPlayer;
    }
    videoPlayer = document.createElement("video");
    const track = document.createElement("track");

    track.id = "track";
    track.kind = "subtitles";
    videoPlayer.id = "videoPlayer";

    if (src != "") {
      videoPlayer.src = src;
    }
    const videoContainer = document.getElementById(videoContainerId);
    videoContainer && videoContainer.appendChild(videoPlayer);

    track.setAttribute("default", "");
    track && videoPlayer.appendChild(track);

    return videoPlayer;
  }

  public setFrameRate = (frameRate: number) => {
    if (!frameRate) return;
    this.frameRate = frameRate;
    this.frameLength = 1 / frameRate;
  };

  public updateStream = async (
    jobId: string,
    setMedia: (newMedia: RoomAsset) => void
  ) => {
    if (
      !this.updatingStream &&
      this.media.currentTime > 0 &&
      this.media.currentTime > this.media.duration - 30
    ) {
      this.updatingStream = true;

      await CloudFunctionsService.stream
        .liveInterviewStatus()
        .then(async ({ data, status }) => {
          if (!data) return;
          const newStreamFile = await EditorService.getAudioStream(jobId);
          this.status = "buffering";
          setMedia(newStreamFile);
          this.media.play();
        })
        .catch((err: any) => console.log("stream fetching failed", err))
        .finally(() => {
          this.status = "playing";
          this.updatingStream = false;
        });
    }
  };

  public static getInstance = () => {
    if (!SoundManager.instance) SoundManager.instance = new SoundManager();

    return SoundManager.instance;
  };
}

export default SoundManager.getInstance();
