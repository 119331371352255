import React, { FC, useEffect, useState } from "react";
import _ from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import {
  setLoadingReason,
  clearLoadingReason,
  resetSorting,
  setErrorReason,
  popIndicator,
} from "../../store/system/actions";

import { TranscriberPreviewData } from "../../models/user";
import {
  setTranscribers,
  clearTranscribers,
  updateTranscriberRole,
} from "../../store/user/actions";

import FirebaseService from "../../services/FirebaseService";
import * as SettingService from "../../services/SettingService";
import sortAndFilterService from "../../services/sortAndFilterService";
import PaymentsService from "../../services/PaymentsService";

import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import GenericTable, { TableConfig } from "../../components/Table/GenericTable";
import TableActions from "../MeetingsPages/TableActions";

import "./TranscribersPage.scss";

const TranscribersPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const settings = useSelector((state: AppState) => state.userStore.settings);
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);

  const [searchBy, setSearchBy] = useState<string>("");
  const [previewMode, setPreviewMode] = useState<"pending" | "transcribers">(
    "transcribers"
  );
  const [pendingTranscribers, setPendingTranscribers] = useState<
    TranscriberPreviewData[]
  >([]);

  const [transcribersTableData, setTranscribersTableData] = useState<
    TranscriberPreviewData[]
  >([]);
  useEffect(() => {
    if (sorting.type !== "transcribers")
      dispatch(resetSorting("transcribers/clients"));
    loadTranscribers();
    return () => {
      dispatch(clearTranscribers());
    };
  }, []);

  useEffect(() => {
    const formatMeeting = async () => {
      const formatedTranscribers = await getFormatTranscribersTable(
        transcribers
      );
      if (formatedTranscribers) {
        setTranscribersTableData(formatedTranscribers);
      }
    };
    formatMeeting();
  }, [transcribers]);
  const loadTranscribers = async () => {
    try {
      dispatch(setLoadingReason(t("loading_transcribers")));
      const transcribers = await FirebaseService.getTranscribersPreview();
      const pendingTranscribers = await FirebaseService.getPendingTranscribersPreview();
      const formatedTranscribers = await getFormatTranscribersTable([
        ...transcribers,
        ...pendingTranscribers,
      ]);
      if (formatedTranscribers) {
        dispatch(setTranscribers(formatedTranscribers));
        setTranscribersTableData(formatedTranscribers);
      }
      setPendingTranscribers(pendingTranscribers);
      dispatch(clearLoadingReason());
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };
  const approvePendingTranscriber = async (
    transcriber: TranscriberPreviewData
  ) => {
    try {
      await FirebaseService.approveNewTranscriber(transcriber.id);
      dispatch(updateTranscriberRole(transcriber, "transcriber"));
      dispatch(
        popIndicator({
          type: "success",
          txt: t("indicator_transcriber_approved"),
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };
  const getTableActionButtons = (transcriber: any) => {
    let mainButton;
    const buttonTypes = {
      approve: {
        label: t("approve"),
        className: "approve",
        onClick: () => approvePendingTranscriber(transcriber),
      },
    };
    if (approvePendingTransciberAction(transcriber))
      mainButton = buttonTypes.approve;
    return _.compact([mainButton]);
  };
  const approvePendingTransciberAction = (
    transcriber: TranscriberPreviewData
  ) => {
    return transcriber?.role === "pending-transcriber";
  };
  const getFormatTranscribersTable = async (
    transcribers: TranscriberPreviewData[]
  ) => {
    try {
      const formatMeetings = await Promise.all(
        transcribers.map(async (transcriber) => {
          const {
            debt,
            currMeetingsCount,
            id,
            phoneNumber,
            role,
            username,
            mail,
          } = transcriber;
          const meetingObj = {
            id,
            username,
            role,
            phoneNumber: phoneNumber ? phoneNumber : t("no_phone_number"),
            currMeetingsCount,
            debt,
            mail,
            actions: (
              <TableActions
                data={transcriber}
                getTableActionButtons={getTableActionButtons}
              />
            ),
          };
          return meetingObj;
        })
      );
      return formatMeetings;
    } catch (err) {
      console.log("getFormatMeetingsTable", err);
    }
  };
  const tableConfig: TableConfig = {
    tableName: "transcribers table",
    columns: {
      username: { label: "name" },
      role: { label: "role" },
      phoneNumber: { label: "phone_number" },
      currMeetingsCount: { label: "current_meetings_count" },
      debt: { label: "current_debt" },
      actions: { label: "", width: "150px" },
    },
    formatters: {
      debt: (currDebt: number) =>
        currDebt ? Number(currDebt).toFixed(1) : "-",
      role: (role: string): string => t(role),
    },
    order: settings["order"],
    orderBy: settings["orderBy"],
    multiSelect: false,
    faded: (row: { [key: string]: any }) =>
      "pending-transcriber" === row.role ? true : false,
    disabled: (row: { [key: string]: any }) =>
      row.role === "disabled" ? true : false,
  };
  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };

  const searchAndSorting = {
    onSearchInput: onSearchBy,
    currSearchBy: searchBy,
  };

  let transcribersToPreview = [...transcribers];
  transcribersToPreview = sortAndFilterService.sortTranscribers(
    transcribersToPreview,
    sorting.sortBy,
    sorting.order
  );
  if (searchBy)
    transcribersToPreview = sortAndFilterService.searchTranscribers(
      transcribersToPreview,
      searchBy
    );

  const goToTranscriber = (transcriber: { [key: string]: any }) => {
    history.push(`/transcriber/${transcriber.id}`);
  };
  const orderTable = (property: string, descOrAsc: "desc" | "asc") => {
    SettingService.setSettings("orderBy", property, dispatch);
    SettingService.setSettings("order", descOrAsc, dispatch);
  };

  return (
    <main className="main-container">
      <PageHeader
        backButton={
          previewMode === "pending"
            ? {
                title: t("transcribers"),
                backAction: () => setPreviewMode("transcribers"),
              }
            : undefined
        }
        title={
          previewMode === "transcribers"
            ? t("transcribers")
            : t("pending_for_approval")
        }
      />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <div className="meetings-page flex column">
          {transcribersTableData.length > 0 && (
            <div className="meetingsTable">
              <GenericTable
                additionalSearchFields={["username", "mail"]}
                data={transcribersTableData}
                config={tableConfig}
                onRowClick={goToTranscriber}
                orderTable={orderTable}
              />
            </div>
          )}
        </div>
      )}
      {previewMode === "transcribers" &&
        transcribers.length === 0 &&
        !loadingReason &&
        !errorReason && (
          <EmptyPageComp title={t("no_available_transcribers")} />
        )}
      {previewMode === "pending" &&
        pendingTranscribers.length === 0 &&
        !loadingReason &&
        !errorReason && (
          <EmptyPageComp title={t("no_available_transcribers")} />
        )}
    </main>
  );
};

export default TranscribersPage;
