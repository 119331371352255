import React, { useEffect, useState, useRef, FC } from "react";
import _ from "lodash";
import { jobTypes } from "../../models/jobTypes";
import { PreviewFormat } from "../../models";
import { JobData } from "../../models/job";

import ExportService from "../../services/ExportService";
import TimeService from "../../services/TimeService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { TableFooter } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logger from "../../services/Logger";

import classNames from "classnames";
import "./GenericTable.scss";

const logger = Logger("GenericTable");

export class TableConfig {
  tableName: string;
  tableColumns?: string[];
  columns: {
    [key: string]: { [key: string]: any };
  };
  formatters?: {
    [key: string]: (
      cellVal: any,
      rowVal: any,
      i?: number
    ) => string | number | JSX.Element;
  };
  multiSelect?: boolean;
  actions?: {
    [actionKey: string]: {
      label?: string;
      icon?: JSX.Element;
      action?: (row: any[]) => void;
      bulk?: boolean;
      hidden?: boolean;
    };
  };
  order?: "desc" | "asc";
  clickable?: (row: { [key: string]: any }) => boolean;
  orderBy?: string;
  faded?: (row: { [key: string]: any }) => boolean;
  disabled?: (row: { [key: string]: any }) => boolean;
  searchBar?: boolean;
  actionsBar?: boolean;
  sumRow?: boolean;
}

interface Props {
  additionalSearchFields?: string[];
  config: TableConfig;
  data: { [key: string]: any }[];
  className?: string;
  rawData?: any[];
  rowClassName?: (j: any) => string;
  orderTable?: (property: string, descOrAsc: "desc" | "asc") => void;
  onRowClick?: (row: { [key: string]: any }, event: React.MouseEvent) => void;
  onSelection?: (jobs: JobData[]) => void;
}
const GenericTable: FC<Props> = ({
  data,
  rawData,
  className,
  rowClassName,
  orderTable,
  additionalSearchFields,
  config,
  onRowClick,
  onSelection,
}) => {
  const { t } = useTranslation();
  const [orderedData, setOrderedData] = useState(data);
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState("creation_time");
  const [searchValue, setSearchValue] = useState("");

  const defaultActions = {};

  const defaultConfig: TableConfig = {
    tableName: "table",
    columns: config.columns || _.mapValues(data[0], (c) => ({ label: c })),
    formatters: {},
    order: "desc",
    orderBy: "name",
    multiSelect: false,
    faded: () => false,
    clickable: () => true,
    actions: defaultActions,
    searchBar: true,
    actionsBar: true,
  };

  const [tableConfig, setTableConfig] = useState(
    _.merge(defaultConfig, config)
  );

  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    setOrder(config["order"] ? config["order"] : order);
    setOrderBy(config["orderBy"] ? config["orderBy"] : orderBy);
  }, []);

  useEffect(() => {
    const dataAfterSearch = requestSearch(searchValue);
    const ordered = _.orderBy(
      dataAfterSearch,
      (row) => {
        try {
          if (["creationTime"].includes(orderBy)) {
            return new Date(row[orderBy].replaceAll("/", "-"));
          }
          return row[orderBy];
        } catch (err) {
          return row[orderBy];
        }
      },
      order
    );
    setOrderedData(ordered);
  }, [data, order, orderBy, searchValue]);

  useEffect(() => {
    setTableConfig(_.merge(defaultConfig, config));
  }, [orderedData]);

  useEffect(() => {
    if (!selected || !onSelection) return;
    onSelection(selected);
  }, [selected]);

  const removeFromSelected = (selectedData: { [key: string]: string }) => {
    setSelected(selected.filter((row) => row.id !== selectedData.id));
  };

  const addToSelected = (row: { [key: string]: string }) => {
    setSelected([...selected, row]);
  };

  const requestSearch = (searchedVal: string) => {
    let searchFields = [
      "name",
      "id",
      "meetingLength",
      "job",
      "previewFormat",
      "assignedTranscriber",
    ];
    if (additionalSearchFields) {
      searchFields = searchFields.concat(additionalSearchFields);
    }
    return data.filter((row) => {
      for (const searchField of searchFields) {
        if (row[searchField]) {
          const formatedCell = t(row[searchField]);
          if (
            formatedCell
              .toString()
              .toLocaleLowerCase()
              .includes(searchedVal.toLocaleLowerCase())
          ) {
            return true;
          }
        }
      }
    });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    const descOrAsc = isAsc ? "desc" : "asc";
    setOrder(descOrAsc);
    setOrderBy(property);
    orderTable && orderTable(property, descOrAsc);
  };

  const exportTable = () => {
    // if (rawData && !_.isEmpty(rawData)) {
    //   const rowIds = _.map(rawData, r => r.id);
    //   ExportService.toCSV({name: tableConfig.tableName, headers: [], data: rawData});
    //   return;
    // }
    const headers = [];
    for (const headerKey in tableConfig.columns) {
      if (
        Object.prototype.hasOwnProperty.call(tableConfig.columns, headerKey)
      ) {
        const header = tableConfig.columns[headerKey];
        if (header.hidden || header.label === "") continue;
        const headerLabel = header.label as string;
        headers.push({ id: headerKey, title: headerLabel });
      }
    }
    ExportService.toCSV({
      name: tableConfig.tableName,
      headers,
      data: orderedData,
    });
  };

  const handleOnRowClick = async (
    e: React.MouseEvent,
    row: { [key: string]: any }
  ) => {
    if (e.altKey) {
      navigator.clipboard.writeText(row.id).catch((err) => {
        logger.error("trying to copy row id");
      });
      return;
    }

    if (
      onRowClick &&
      jobTypes[row.previewFormat as PreviewFormat]?.editMode !== "none"
    ) {
      onRowClick(row, e);
    }
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {tableConfig.multiSelect && <TableCell className={"headerCell"} />}
        {_.map(tableConfig.columns, (column, key) => {
          if (column.hidden) return;
          let sum = null;
          if (config.sumRow && ["sum", "bonus"].includes(column.key)) {
            sum = (_.sumBy(data, column.key) || 0).toFixed(2);
          }
          if (config.sumRow && ["jobLengthSeconds"].includes(column.key)) {
            sum = TimeService.getTimeStringFromSecs(
              _.sumBy(data, "jobLengthSeconds") || 0
            );
          }
          return (
            <TableCell
              align={"right"}
              className="headerCell"
              sortDirection={orderBy === column.label ? order : false}
              onClick={() => handleRequestSort(key)}
              key={`${column.label}- ${key}`}
            >
              {t(column.label)} {sum ? `(${sum})` : null}
              <TableSortLabel
                active={orderBy === column.label}
                direction={orderBy === column.label ? order : "asc"}
              />
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <div className={classNames("GenericTable", className)}>
      <div className="table-top">
        {tableConfig.searchBar ? (
          <div className="table-search-bar">
            <div className="search-bar flex ">
              <div className="icn-wrapper flex align-center justify-center">
                <FontAwesomeIcon icon={["far", "search"]} />
              </div>
              <input
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                type="text"
                placeholder={t("search")}
              />
            </div>
            <div className="rowCount">{orderedData.length}</div>
          </div>
        ) : (
          <div className="table-search-bar"></div>
        )}
        {tableConfig.actionsBar && (
          <div className="actions">
            {tableConfig.actions && (
              <div className="actions">
                {_.map(
                  tableConfig.actions,
                  (action, key) =>
                    !action.hidden && (
                      <div
                        className={classNames("action", action.label)}
                        key={key}
                        onClick={() => {
                          action.action && action.action(selected);
                        }}
                      >
                        {action.icon}
                      </div>
                    )
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <TableContainer className="tableContainerComp">
        <Table
          stickyHeader
          aria-label="sticky table"
          className="tableContainer"
        >
          <TableHead className="tableHeader">{tableHeader()}</TableHead>
          <TableBody className="tableBody">
            {orderedData.map((row, i) => (
              <TableRow
                className={classNames(
                  "tableRow",
                  rowClassName && rowClassName(row),
                  {
                    disabled: tableConfig.disabled && tableConfig.disabled(row),
                    faded: tableConfig.faded && tableConfig.faded(row),
                    clickable:
                      tableConfig.clickable && tableConfig.clickable(row),
                  }
                )}
                hover
                onClick={(e) => handleOnRowClick(e, row)}
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                selected={false}
                key={row.id}
              >
                {tableConfig.multiSelect && (
                  <TableCell
                    className={classNames("rowCell", "checkbox", {
                      clickable: onRowClick,
                    })}
                    scope="row"
                    key={`checkbox-${row.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input
                      type="checkbox"
                      onClick={(e: any) => {
                        if (e.target.checked) {
                          addToSelected(row);
                        } else {
                          removeFromSelected(row);
                        }
                      }}
                    />
                  </TableCell>
                )}
                {_.keys(tableConfig.columns).map((column) =>
                  !tableConfig.columns[column].hidden ? (
                    <TableCell
                      className={classNames("rowCell", column, {
                        clickable: tableConfig.faded && tableConfig.faded(row),
                      })}
                      scope="row"
                      key={column}
                      style={{ width: tableConfig.columns[column].width }}
                    >
                      {tableConfig.formatters &&
                      tableConfig.formatters[column] ? (
                        <div
                          style={{ width: tableConfig.columns[column].width }}
                          className={classNames("tdContainer", column, {
                            ellipsis: tableConfig.columns[column].ellipsis,
                          })}
                        >
                          {tableConfig.formatters[column](
                            _.get(row, column),
                            row,
                            i
                          )}
                        </div>
                      ) : React.isValidElement(_.get(row, column)) ? (
                        _.get(row, column)
                      ) : (
                        <div
                          style={{ width: tableConfig.columns[column].width }}
                          className={classNames("tdContainer", column, {
                            ellipsis: tableConfig.columns[column].ellipsis,
                          })}
                        >
                          {_.get(row, column)}
                        </div>
                      )}
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            ))}
          </TableBody>
          {config.sumRow && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell colSpan={1}>
                  {t("sum")}: {(_.sumBy(data, "sum") || 0).toFixed(2)}
                </TableCell>
                <TableCell colSpan={1}>
                  {t("length")}:{" "}
                  {TimeService.getTimeStringFromSecs(
                    _.sumBy(data, "jobLengthSeconds") || 0
                  )}
                </TableCell>
                <TableCell colSpan={1}>
                  {t("bonus")}: {(_.sumBy(data, "bonus") || 0).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default GenericTable;
