import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/rootReducer";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FirebaseService from "../../services/FirebaseService";
import { Contact } from "../../models/client";
import { setCurrClient, clearCurrClient } from "../../store/client/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  setErrorReason,
  popIndicator,
} from "../../store/system/actions";
import InfoField from "../../components/InfoField/InfoField";
import RemoveEntityButton from "../../components/RemoveEntityButton/RemoveEntityButton";
import AddContactForm from "../../components/AddContactForm/AddContactForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";

import "./ClientPage.scss";

const ClientPage: FC = (): JSX.Element => {
  const { id } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const currClient = useSelector(
    (state: AppState) => state.clientStore.currClient
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );

  const [notesStr, setNotesStr] = useState<string>("");
  const [isNotesFocused, setIsNotesFocused] = useState<boolean>(false);
  const [isContactForm, setIsContactForm] = useState<boolean>(false);
  const [selectedContactIdx, setSelectedContactIdx] = useState<null | number>(
    null
  );
  const [selectedContact, setSelectedContact] = useState<null | Contact>(null);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.role !== "super_user") {
        history.push("/");
        return;
      } else {
        loadClient();
      }
    }
    return () => {
      dispatch(clearCurrClient());
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (currClient) setNotesStr(currClient.notes.repeat(1));
  }, [currClient?.notes]);

  const loadClient = async () => {
    try {
      dispatch(setLoadingReason("loading_client"));
      const client = await FirebaseService.getClient(id);
      if (client) {
        dispatch(setCurrClient(client));
        dispatch(clearLoadingReason());
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const removeClient = async () => {
    try {
      await FirebaseService.removeClient(id);
      history.push("/clients");
      dispatch(clearCurrClient());
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_client_removed") })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const removeContact = async (
    e: React.MouseEvent<HTMLDivElement>,
    idx: number
  ) => {
    e.stopPropagation();
    try {
      if (currClient) {
        const newContacts = [...currClient.contacts];
        newContacts.splice(idx, 1);
        await FirebaseService.updateClientContacts(currClient.id, newContacts);
        const updatedClient = { ...currClient };
        updatedClient.contacts = newContacts;
        dispatch(setCurrClient(updatedClient));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const addContact = async (newContact: Contact) => {
    try {
      if (currClient) {
        const newContacts = [...currClient.contacts];
        newContacts.push(newContact);
        await FirebaseService.updateClientContacts(currClient.id, newContacts);
        const updatedClient = { ...currClient };
        updatedClient.contacts = newContacts;
        dispatch(setCurrClient(updatedClient));
        setIsContactForm(false);
      }
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const updateContact = async (updatedContact: Contact) => {
    try {
      if (currClient) {
        const newContacts = currClient.contacts.map((contact, idx) => {
          if (idx === selectedContactIdx) return updatedContact;
          return contact;
        });
        await FirebaseService.updateClientContacts(currClient.id, newContacts);
        const updatedClient = { ...currClient };
        updatedClient.contacts = newContacts;
        dispatch(setCurrClient(updatedClient));
        clearSelectedContact();
      }
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const onContactClick = (idx: number) => {
    setSelectedContactIdx(idx);
    if (currClient) {
      const contactClone = { ...currClient.contacts[idx] };
      setSelectedContact(contactClone);
      setIsContactForm(true);
    }
  };

  const clearSelectedContact = () => {
    setSelectedContact(null);
    setSelectedContactIdx(null);
    setIsContactForm(false);
  };

  const updateNotes = async (e: React.FocusEvent<HTMLParagraphElement>) => {
    setIsNotesFocused(false);
    try {
      const newNotes = e.target.innerText;
      if (currClient) {
        await FirebaseService.updateClientNotes(currClient.id, newNotes);
        const updatedClient = { ...currClient };
        updatedClient.notes = newNotes;
        dispatch(setCurrClient(updatedClient));
      }
    } catch (err) {
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_update_failure") })
      );
    }
  };

  const clienNameStr = currClient ? currClient.name : "";
  const pageHeaderStr = clienNameStr;
  let headerTitle = pageHeaderStr;
  let headerBackButton;
  if (!isContactForm) {
    headerTitle = pageHeaderStr;
    headerBackButton = {
      title: t("clients"),
      backAction: () => history.push("/clients"),
    };
  } else if (isContactForm && !selectedContact) {
    headerTitle = t("add_contact");
    headerBackButton = {
      title: clienNameStr,
      backAction: () => setIsContactForm(false),
    };
  } else if (isContactForm && !!selectedContact) {
    headerTitle = t(selectedContact.fullname);
    headerBackButton = {
      title: clienNameStr,
      backAction: () => clearSelectedContact(),
    };
  }

  return (
    <main className="main-container">
      <PageHeader title={headerTitle} backButton={headerBackButton} />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {currClient && !loadingReason && !errorReason && (
        <div className="client-page">
          {!isContactForm && (
            <div className="info-and-contacts">
              <div className="info">
                <InfoField
                  Icn={["fal", "folder-open"]}
                  text={currClient.completedMeetingsCount}
                />
                <InfoField
                  Icn={["fal", "briefcase"]}
                  text={currClient.currMeetingsCount}
                  onClick={() => history.push(`/client/meetings/${id}`)}
                />
                <InfoField
                  Icn={["fal", "shekel-sign"]}
                  text={currClient.currDebt.toLocaleString()}
                  onClick={() => history.push(`/client/payments/${id}`)}
                />
              </div>
              <div className="grid-container">
                {currClient.contacts.map((contact, idx) => (
                  <div key={idx} className="contact">
                    <div className="icn-wrapper">
                      <FontAwesomeIcon icon={["fal", "user"]} />
                    </div>
                    <div className="contact-info-wrapper">
                      <div
                        className="contact-info"
                        onClick={() => onContactClick(idx)}
                      >
                        <p>
                          {contact.fullname} - {contact.role}
                        </p>
                      </div>
                      <div
                        onClick={(e) => removeContact(e, idx)}
                        className="remove-btn"
                      >
                        <FontAwesomeIcon icon={["fal", "times-circle"]} />
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  onClick={() => setIsContactForm(true)}
                  className="add-contact-btn"
                >
                  <FontAwesomeIcon icon={["fal", "user"]} />
                  {t("add_contact")}
                </button>
              </div>
            </div>
          )}
          {!isContactForm && (
            <div className="notes-and-remove-btn">
              <div className="notes">
                <div className="icn-wrapper">
                  <FontAwesomeIcon icon={["fal", "edit"]} />
                </div>
                <div
                  className={`notes-info ${isNotesFocused ? "outlined" : ""}`}
                >
                  <p
                    contentEditable={true}
                    onBlur={(e) => updateNotes(e)}
                    onFocus={() => setIsNotesFocused(true)}
                  >
                    {notesStr}
                  </p>
                </div>
              </div>
              <RemoveEntityButton
                text={t("remove_client")}
                onApprove={removeClient}
                entityName={currClient.name}
              />
            </div>
          )}
          {!!isContactForm && !selectedContact && (
            <AddContactForm sendContact={addContact} />
          )}
          {!!isContactForm && !!selectedContact && (
            <AddContactForm
              sendContact={updateContact}
              selectedContact={selectedContact}
            />
          )}
        </div>
      )}
    </main>
  );
};

export default ClientPage;
