import { ArchiveTree } from "../../models/archive";
import { ElasticJob } from "./../../models/meeting";

import {
  SET_ARCHIVED_MEETINGS,
  UPDATE_ARCHIVED_MEETING,
  SET_ARCHIVE_TREE,
  ArchiveActionTypes,
  UPDATE_ARCHIVED_MEETING_REPRESENTATIVE,
  UPDATE_ARCHIVED_MEETING_REVENUE,
  UPDATE_ARCHIVED_MEETING_INVOICE_SENT,
} from "./types";

export function setArchivedMeetings(
  meetings: ElasticJob[]
): ArchiveActionTypes {
  return {
    type: SET_ARCHIVED_MEETINGS,
    meetings,
  };
}

export function updateArchivedMeeting(meeting: ElasticJob): ArchiveActionTypes {
  return {
    type: UPDATE_ARCHIVED_MEETING,
    meeting,
  };
}

export function updateMeetingRepresentative(
  meetingId: string,
  representative: string
): ArchiveActionTypes {
  return {
    type: UPDATE_ARCHIVED_MEETING_REPRESENTATIVE,
    meetingId,
    representative,
  };
}

export function updateMeetingRevenue(
  meetingId: string,
  revenue: number
): ArchiveActionTypes {
  return {
    type: UPDATE_ARCHIVED_MEETING_REVENUE,
    meetingId,
    revenue,
  };
}

export function updateMeetingInvoiceSent(
  meetingId: string,
  invoiceSent: string
): ArchiveActionTypes {
  return {
    type: UPDATE_ARCHIVED_MEETING_INVOICE_SENT,
    meetingId,
    invoiceSent,
  };
}

export function setArchiveTree(
  archiveTree: ArchiveTree | null
): ArchiveActionTypes {
  return {
    type: SET_ARCHIVE_TREE,
    archiveTree,
  };
}
