import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";

interface ControllerHOCProps {
  children: any;
  name: string;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  errors?: FieldErrors<any>;
  getValues?: UseFormGetValues<any>;
}

export const ControllerHOC = ({
  children,
  control,
  name,
  setValue,
  errors,
  getValues,
}: ControllerHOCProps) => {
  return control ? (
    <Controller
      control={control}
      name={name}
      render={({ field }) =>
        React.cloneElement(children, {
          controller: { field, name, setValue, errors, getValues },
        })
      }
    />
  ) : (
    React.cloneElement(children, {
      controller: { name, setValue, errors, getValues },
    })
  );
};
