import React, { FC, ReactElement, useEffect, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Action } from "../../models/editor";
import { LoggedInUser } from "../../models/user";

import FeatureFlagsService from "../../services/FeatureFlagsService";
import classNames from "classnames";
import Badge from "../../components/Badge/Badge";

interface Props {
  actions: Action[];
  user: LoggedInUser;
  children: ReactElement;
  editorDirection?: "rtl" | "ltr";
}

const JobActionPanel: FC<Props> = ({
  actions,
  user,
  children,
  editorDirection,
}): JSX.Element => {
  const [allowedActions, setAllowedActions] = useState<Action[]>([]);

  useEffect(() => {
    setAllowedActions(actions.filter((a) => isActionAllowed(a)));
  }, [actions]);

  const isActionAllowed = (action: Action) => {
    const isRoleAllowed = action.roles
      ? action.roles.includes(user.role)
      : true;
    const isFFallowed = action.featureFlag
      ? FeatureFlagsService.isEnabled(action.featureFlag, user)
      : true;
    return isRoleAllowed && isFFallowed;
  };

  return (
    <div className="JobActionPanel">
      {_.map(allowedActions, (action) => {
        return (
          <div
            className={classNames("action", action.className, {
              disabled: action.disabled,
            })}
            onClick={action.disabled ? undefined : action.onClick}
            key={action.key}
          >
            <div className="actionLabel">{action.label}</div>
            <Badge
              hidden={action.badge?.hidden}
              position={action.badge?.position}
              direction={editorDirection}
            >
              <div className="actionIcon">
                <FontAwesomeIcon icon={action.icon} />
              </div>
            </Badge>
          </div>
        );
      })}
      {children}
    </div>
  );
};

export default JobActionPanel;
