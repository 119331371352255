import React, { FC, useState, useEffect, useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./SelectInput.scss";
import classNames from "classnames";

interface Props {
  onInput: (input: string) => void;
  onFirstOpen?: () => void;
  width?: number | string;
  values: { text: string; identifier: string; disabled?: boolean }[];
  placeholder?: string;
  Icn?: IconProp;
  pending?: boolean;
  align?: string;
  maxDropdownHeight?: number;
  requiredIndicator?: boolean;
  disabled?: boolean;
}

const SelectInput: FC<Props> = ({
  onInput,
  onFirstOpen,
  values,
  disabled,
  Icn,
  placeholder,
  requiredIndicator,
  pending,
  align,
  maxDropdownHeight,
}): JSX.Element => {
  const mainElement = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currValue, setCurrValue] = useState<string>("");

  useEffect(() => {
    if (!placeholder) {
      setCurrValue(values[0].text);
    }
  }, []);

  useOutsideClick(mainElement, () => {
    if (isOpen) {
      toggleDropdown();
    }
  });

  const toggleDropdown = () => {
    if (disabled) return;
    if (onFirstOpen && values.length === 0) {
      onFirstOpen();
    }
    setIsOpen(!isOpen);
  };

  const changeSelection = (text: string, identifier: string) => {
    if (disabled) return;
    onInput(identifier);
    setCurrValue(text);
    toggleDropdown();
  };

  return (
    <>
      <div
        className={`select-wrapper ${align === "center" ? "center" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {!!Icn && (
          <div className="icn-wrapper">
            <FontAwesomeIcon icon={Icn} />
          </div>
        )}
        <div className="select">
          {!isOpen && !currValue && (
            <div
              onClick={toggleDropdown}
              style={{
                border: `1px solid ${
                  requiredIndicator ? "rgb(175,43,43)" : "rgb(175,43,43, 00)"
                }`,
              }}
              className="main placeholder"
            >
              <p>{placeholder}</p>
            </div>
          )}
          {!isOpen && currValue && (
            <div onClick={toggleDropdown} className="main">
              <p>{currValue}</p>
            </div>
          )}
          {isOpen && (
            <div
              ref={mainElement}
              style={{
                maxHeight: maxDropdownHeight ? `${maxDropdownHeight}px` : "",
              }}
              className="drop-down"
            >
              {pending && (
                <div className="loading-spinner">
                  <CircularProgress style={{ width: "20px" }} />
                </div>
              )}
              {!pending &&
                values.map((value, idx) => (
                  <div
                    className={classNames("option", {
                      disabled: value.disabled,
                    })}
                    onClick={() =>
                      !value.disabled &&
                      changeSelection(value.text, value.identifier)
                    }
                    key={idx}
                  >
                    <div className="dot-container">
                      <div className="dot"></div>
                    </div>
                    <p>{value.text}</p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SelectInput;
