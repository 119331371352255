import React, { FC, useState, useRef } from "react";
import _ from "lodash";

import Slider from "rc-slider";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { getLanguageDirection } from "../../../utils/locales";

import "rc-slider/assets/index.css";
import "./RangeSliderField.scss";

interface Props {
  className?: string;
  placeholder?: string;
  prefix?: string;
  editable?: boolean;
  value?: number[];
  max?: number;
  min?: number;
  step?: number;
  inputType?: "text" | "number";
  onChange?: (value: any) => void;
  formatInput?: (value: string | number) => string;
}

const RangeSliderField: FC<Props> = ({
  formatInput,
  inputType = "text",
  editable = true,
  prefix,
  max = 1000,
  min = 0,
  step = 10,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = React.useState<number[]>(
    value ? value : [min, max]
  );

  const handleChange = (options: {
    newValues?: number[];
    newValue?: string;
    index?: number;
  }) => {
    const { newValue, index } = options;
    let { newValues } = options;

    newValues = newValues ? [...newValues] : [...values];

    if (value && !_.isNil(index)) {
      newValues[index] = Number(newValue);
    }
    setValues(newValues);
    onChange && onChange(newValues);
    setValues(newValues as number[]);
  };
  return (
    <div className="RangeSliderField">
      <Slider
        range={true}
        reverse={getLanguageDirection(i18n.language) === "rtl"}
        min={min}
        step={step}
        max={max}
        value={values}
        onChange={(newValues) =>
          handleChange({ newValues: newValues as number[] })
        }
      />
      <div
        className={classNames(`values`, getLanguageDirection(i18n.language))}
      >
        {values.map((value, index) =>
          editable ? (
            <div key={index} className={"valueFieldContainer"}>
              {prefix && <span className="currency">{t(prefix)}</span>}
              <input
                className="input"
                min={min}
                max={max}
                placeholder={value.toString()}
                type={inputType}
                value={formatInput ? formatInput(Number(value)) : value}
                onChange={(e) => {
                  handleChange({ newValue: e.target.value, index });
                }}
              ></input>
            </div>
          ) : (
            <div key={index} className={"valueFieldContainer"}>
              <div className="valueField">
                {formatInput ? formatInput(Number(value)) : value}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default RangeSliderField;
