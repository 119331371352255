import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import Logger from "../../services/Logger";
import FeatureFlagsService from "../../services/FeatureFlagsService";
import EditorService from "../../services/EditorService";

import { Word } from "../../models";
import { AppState } from "../../store/rootReducer";
import { JobData } from "../../models/job";
import { Action } from "../../models/editor";
import { RangeValidationConfig, SubtitlesRange } from "../../models/range";

import RangeSubtitles from "../../components/TextRange/RangeSubtitles";
import MessageModal from "../../components/MessageModal/MessageModal";

import {
  scrollInto,
  focusAndSetCursor,
  scrollToOffset,
} from "../../utils/focusAndScroll";
import { generateId } from "../../utils/generators";
import { reIndexWords } from "../../utils/rangesUtils";

const logger = Logger("EditorPage");

interface Props {
  job: JobData;
  saveJob: (saveMethod?: string | undefined) => Promise<void>;
  ranges: SubtitlesRange[];
  updateRanges: (ranges: SubtitlesRange[], rangeIndex?: number) => void;
  focusedRangeIndex: number;
  setFocusedRangeIndex: (rangeIx: number) => void;
  addActions: (actions: Action[]) => void;
  currentTime: number;
  setIsChanged: (isChanged: boolean) => void;
  timestampsEnterMethod: "word" | "player";
  toggleTimestampsEnterMethod: () => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  rangeValidationConfig?: RangeValidationConfig;
  editorDirection: "rtl" | "ltr";
  isDisabled: boolean;
  setIsLoading: (isLoading: string | boolean) => void;
  toast: (msg: string, type: "success" | "error" | "info") => void;
}

const Subtitles: FC<Props> = ({
  job,
  ranges,
  updateRanges,
  setFocusedRangeIndex,
  addActions,
  currentTime,
  saveJob,
  updateRangeTimes,
  rangeValidationConfig,
  editorDirection,
  timestampsEnterMethod,
  toggleTimestampsEnterMethod,
  isDisabled,
  setIsLoading,
  toast,
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showAlignModal, setShowAlignModal] = useState(false);

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const subtitlesActions = [
    {
      key: "createSubtitles",
      label: t("align_subtitles"),
      icon: ["fal", "stream"],
      onClick: () => setShowAlignModal(true),
    },
    {
      key: "timestampsEnterMethod",
      label: t("timestamps_enter_method_1", { timestampsEnterMethod }),
      icon: ["fal", "level-up"],
      className: "timestampsEnterMethod",
      onClick: () => toggleTimestampsEnterMethod(),
      featureFlag: "enterMode",
    },
  ];

  useEffect(() => {
    addActions(subtitlesActions);
    if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
      const {
        cursorPosition,
        rangeIx,
        scrollOffsetTop,
      } = EditorService.getLastPosition(job.roomId);
      scrollToOffset("editorContainer", scrollOffsetTop);
      focusAndSetCursor(rangeIx, cursorPosition);
    }
  }, []);

  /* --- KeyPress Handlers --- */
  const onPressEnter = ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    event,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    event: React.KeyboardEvent;
  }) => {
    if (event.shiftKey) {
      // Breaking to multiline
      scrollInto(`range-${rangeIndex}`);
    } else {
      // Breaking range
      event.preventDefault();
      event.stopPropagation();

      breakRange(
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex,
        wordCharIndex,
        event
      );
      scrollInto(`range-${rangeIndex + 1}`);
    }
  };

  // const onPressBackspace = ({
  //   rangeIndex,
  //   updatedRangeWords,
  //   selectedWordIndex,
  //   wordCharIndex,
  //   event,
  // }: {
  //   rangeIndex: number;
  //   updatedRangeWords: Word[];
  //   selectedWordIndex: number;
  //   wordCharIndex: number;
  //   event: React.KeyboardEvent;
  // }) => {
  //   breakRange(
  //     rangeIndex,
  //     updatedRangeWords,
  //     selectedWordIndex,
  //     wordCharIndex,
  //     event
  //   );

  //   scrollInto(`range-${rangeIndex + 1}`);
  // };
  /* --- KeyPress Handlers --- */

  // -Protocol Range Handlers

  const breakRange = async (
    rangeIndex: number,
    updatedRangeWords: Word[],
    selectedWordIndex: number,
    wordCharIndex: number,
    event: React.KeyboardEvent
  ) => {
    const {
      selectionStart,
      selectionEnd,
    } = event.target as HTMLTextAreaElement;
    const textLength = _.get(event.target, "textLength");
    const isCarretInEndOfWord =
      updatedRangeWords[selectedWordIndex].text.length === wordCharIndex;
    const breakIndex = isCarretInEndOfWord
      ? selectedWordIndex + 1
      : selectedWordIndex;

    if (selectionStart === textLength || selectionStart === 0) return;

    const wordToBreak =
      wordCharIndex > 0 &&
      !isCarretInEndOfWord &&
      _.clone(updatedRangeWords[breakIndex]);

    const firstPartWords = _.clone(updatedRangeWords);
    const secondPartWords = firstPartWords.splice(breakIndex);

    if (wordToBreak) {
      wordToBreak.text = wordToBreak.text.slice(0, wordCharIndex);
      secondPartWords[0].text = secondPartWords[0].text.slice(
        wordCharIndex,
        secondPartWords[0].text.length
      );
      firstPartWords.push(wordToBreak);
    }

    const updatedRanges = _.clone(ranges);

    const firstPartRange = {
      ...ranges[rangeIndex],
      id: generateId("r_"),
      words: firstPartWords,
      et: firstPartWords[firstPartWords.length - 1].end,
    };

    if (event && event.ctrlKey) {
      const secondPartRange = {
        ...ranges[rangeIndex],
        id: generateId("_r"),
        st: firstPartRange.et,
        words: reIndexWords(secondPartWords, rangeIndex),
      };

      updatedRanges.splice(rangeIndex, 1, firstPartRange, secondPartRange);
    } else if (updatedRanges[rangeIndex + 1]) {
      updatedRanges[rangeIndex] = firstPartRange;
      updatedRanges[rangeIndex + 1] = {
        ...ranges[rangeIndex + 1],
        st: secondPartWords[0].start,
        words: reIndexWords(
          _.concat(secondPartWords, ranges[rangeIndex + 1].words),
          rangeIndex + 1
        ),
      };
    }

    if (timestampsEnterMethod === "player") {
      const _currentTime = Number(currentTime.toFixed(1));
      const firstRange = updatedRanges[rangeIndex];
      const secondRange = updatedRanges[rangeIndex + 1];

      firstRange.et = _currentTime;
      firstRange.words[firstRange.words.length - 1].end = _currentTime;

      secondRange.st = _currentTime;
      secondRange.words[0].start = _currentTime;
    }

    updateRanges(updatedRanges, rangeIndex);

    setTimeout(() => focusAndSetCursor(rangeIndex + 1), 0);
  };

  const mergeRange = (
    rangeIndex: number,
    updatedRangeWords: Word[],
    mergeWithNextRange = false
  ) => {
    const updatedRanges = _.clone(ranges);

    const firstRange =
      updatedRanges[mergeWithNextRange ? rangeIndex : rangeIndex - 1];
    const secondRange =
      updatedRanges[mergeWithNextRange ? rangeIndex + 1 : rangeIndex];

    const rangeToMergeInto = firstRange;
    const rangeToMergeIntoLength = rangeToMergeInto.words
      .map((w) => w.text)
      .join(" ").length;

    rangeToMergeInto.words = reIndexWords(
      _.concat(rangeToMergeInto.words, secondRange.words),
      rangeIndex
    );

    rangeToMergeInto.et = secondRange.et;

    updatedRanges.splice(_.indexOf(updatedRanges, secondRange), 1);

    updateRanges(updatedRanges, rangeIndex);

    if (mergeWithNextRange) {
      const currentRangeLength = updatedRangeWords.map((w) => w.text).join(" ")
        .length;
      setTimeout(() => focusAndSetCursor(rangeIndex, currentRangeLength), 0);
    } else {
      setTimeout(
        () => focusAndSetCursor(rangeIndex - 1, rangeToMergeIntoLength),
        0
      );
    }
  };

  const deleteRange = (rangeIndex: number) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges.splice(rangeIndex, 1);

    updateRanges(updatedRanges, rangeIndex);
  };

  const updateRangeWords = (rangeIndex: number, rangeWords: Word[]) => {
    const updatedRanges = _.clone(ranges);
    const updatedRange = { ...ranges[rangeIndex], words: rangeWords };
    _.set(updatedRanges, `[${rangeIndex}]`, updatedRange);

    updateRanges(updatedRanges, rangeIndex);
  };
  // Subtitles Range Handlers

  // Actions

  const alignSubtitles = async () => {
    await saveJob("aligner");
    setShowAlignModal(false);
    setIsLoading(t("loading_aligning_subtitles") as string);
    const response = await EditorService.alignSubtitles(
      job.roomId,
      rangeValidationConfig
    );
    if (!response.success) {
      toast(t("subtitles_align_failed" as string), "error");
    } else {
      history.push(`/`);
      toast(t("subtitles_align_success"), "success");
    }
    setIsLoading(false);
  };

  // Actions

  return (
    <div className="SubtitlesEditor">
      <div id="editorContainer" className="editorRanges subtitlesRanges">
        {ranges.map((range, rangeIndex) => (
          <LazyLoad
            height={100}
            once
            offset={
              // Determain divs height by its word count
              ((range.words && range.words.length * 1.3) || 400) + 1000
            }
            scrollContainer="#editorContainer"
            unmountIfInvisible={true}
            key={range.id}
          >
            <RangeSubtitles
              job={job}
              ranges={ranges}
              range={range as SubtitlesRange}
              rangeIndex={rangeIndex}
              updateRangeWords={updateRangeWords}
              rangesCount={ranges.length}
              onPressEnter={onPressEnter}
              mergeRange={mergeRange}
              deleteRange={deleteRange}
              updateRangeTimes={updateRangeTimes}
              isPassed={Number(currentTime) > Number(range.et)}
              setFocusedRangeIndex={setFocusedRangeIndex}
              isCurrentPlayingRange={
                Number(currentTime) > Number(range.st) &&
                Number(currentTime) < Number(range.et)
              }
              direction={editorDirection}
              disabled={isDisabled}
            />
          </LazyLoad>
        ))}
      </div>
      <MessageModal
        className="alignSubtitlesWarning warning"
        title={t("align_subtitles")}
        body={t("modal_align_warning", {
          eta: Math.round((job.meetingLength + 360) / 60 / 3),
        })}
        showModal={showAlignModal}
        approve={{ text: t("approve"), action: alignSubtitles }}
        cancel={{ text: t("cancel"), action: () => setShowAlignModal(false) }}
      />
    </div>
  );
};

export default Subtitles;
