import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import { format } from "date-fns";

import { Dialog } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";

import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ApproveModal from "../../components/MeetingPreview/ApproveModal";
import MeetingsTable from "../../components/MeetingsTable/MeetingsTable";
import PageHeader from "../../components/PageHeader/PageHeader";

import Logger from "../../services/Logger";
import MeetingService from "../../services/MeetingService";
import FirebaseService from "../../services/FirebaseService";
import NotificationService from "../../services/NotificationService";
import TimeService from "../../services/TimeService";
import CloudFunctionsService from "../../services/CloudFunctionsService";
import { getLanguageDirection } from "../../utils/locales";

import { MeetingPreviewData, MeetingTableItem } from "../../models";
import {
  clearMeetings,
  removeMeeting,
  setMeetings,
  updateMeeting,
} from "../../store/meeting/actions";
import { AppState } from "../../store/rootReducer";
import {
  clearLoadingReason,
  popIndicator,
  resetSorting,
  setErrorReason,
  setLoadingReason,
} from "../../store/system/actions";
import MeetingActions from "./MeetingActions";
import { getTranscribers } from "../../store/user/actions";
import { getJobsUrl } from "../../utils/url";

const logger = Logger("myJobs");

const MyJobs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);

  const meetings = useSelector((state: AppState) => state.meetingStore.myJobs);
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );

  const [meetingsTableData, setMeetingsTableData] = useState<
    MeetingTableItem[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [
    selectedMeeting,
    setSelectedMeeting,
  ] = useState<MeetingPreviewData | null>(null);
  const [selectedButton, setSelectedButton] = useState("");
  const getMyMeetings = async () => {
    try {
      dispatch(setLoadingReason(t("loading_available_meetings")));
      if ("super_user" === loggedInUser?.role)
        await dispatch(getTranscribers());
      const meetings = await CloudFunctionsService.getMyJobs(
        loggedInUser?.id ? loggedInUser?.id : ""
      );
      if (meetings) {
        dispatch(setMeetings(meetings, "myJobs"));
        const formatMeetings = await getFormatMeetingsTable(meetings);
        if (formatMeetings) {
          setMeetingsTableData(formatMeetings);
        }
      }
      dispatch(clearLoadingReason());
    } catch (err) {
      logger.log(err, "loadAvailabelMeetings");
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearMeetings("myJobs"));
    };
  }, []);
  useEffect(() => {
    if (loggedInUser) {
      getMyMeetings();
    }
    return () => {
      dispatch(clearMeetings("myJobs"));
    };
  }, [loggedInUser]);

  useEffect(() => {
    const formatMeeting = async () => {
      const formatMeetings = await getFormatMeetingsTable(meetings);
      if (formatMeetings) {
        setMeetingsTableData(formatMeetings);
      }
    };
    formatMeeting();
  }, [meetings]);

  const getFormatMeetingsTable = async (meetings: MeetingPreviewData[]) => {
    try {
      const formatMeetings = await Promise.all(
        meetings.map(async (meeting) => {
          const {
            id,
            name,
            isApproved,
            deadline,
            meetingLength,
            clientName,
            assignedTranscriber,
            speakers,
            lang,
            previewFormat,
            processProgressLastUpdate,
            price,
            prooferPrice,
            status,
          } = meeting;
          const priceToShow = () => {
            if (price && status === 3) return price;
            if (prooferPrice && status === 4) return prooferPrice;
            return "-";
          };
          const transcriberId = assignedTranscriber?.id;
          const transName =
            transcriberId && transcribers
              ? MeetingService.getTranscriberFromTranscribers(
                  transcriberId,
                  transcribers,
                  "username"
                )
              : "";
          const deadlineAsNumber = Number(deadline);
          const meetingObj = {
            status,
            id,
            name,
            isApproved,
            job:
              status === 3
                ? t("job_transcribing")
                : status === 4
                ? t("job_reviewing")
                : t("processing"),
            previewFormat: previewFormat,
            price: priceToShow(),
            deadline: new Date(deadlineAsNumber * 1000),
            assignedTranscriber: transName ? transName : t("no_transcriber"),
            clientName: clientName ? clientName : t("no_client"),
            processProgressLastUpdate,
            meetingLength: meetingLength
              ? TimeService.getTimeStringFromSecs(meetingLength)
              : "-",
            speakers: speakers ? speakers.length : 0,
            lang: `${t(lang.input)} / ${t(lang.output)}`,
            actions: (
              <MeetingActions
                setSelectedMeeting={setSelectedMeeting}
                storeName={"myJobs"}
                setShowModal={setShowModal}
                meeting={meeting}
                setSelectedButton={setSelectedButton}
              />
            ),
          };
          return meetingObj;
        })
      );
      return formatMeetings;
    } catch (err) {
      console.log("getFormatMeetingsTable", err);
    }
  };
  const goToMeeting = (
    meeting: { [key: string]: any },
    event: React.MouseEvent
  ) => {
    if (meeting.isApproved) {
      const jobUrl = getJobsUrl(meeting, loggedInUser);
      if (event.ctrlKey || event.metaKey) {
        window.open(jobUrl, "_blank");
        return;
      }
      history.push(jobUrl);
      dispatch(clearMeetings("myJobs"));
    }
  };

  const sendToProof = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (meeting.id && loggedInUser) {
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        // NotificationService.dispatchNotification(
        //   "sentToProof",
        //   "super_user",
        //   info
        // );
        setIsActionLoading(true);
        await FirebaseService.deliverToProof(meeting.id);
        onSuccess();
        dispatch(removeMeeting(meeting.id, "myJobs"));
        dispatch(
          popIndicator({ type: "success", txt: t("indicator_sent_to_proof") })
        );
      }
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };
  const unassignMyself = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (!loggedInUser) return;
      if (meeting?.id) {
        setIsActionLoading(true);
        await FirebaseService.unassignTranscriber(meeting.id);
        if (
          meeting.assignedMethod === "assigned" ||
          (meeting.assignedMethod === "requested" && meeting.isApproved)
        ) {
          if (meeting.assignedBy) {
            const recipient = meeting.assignedBy;
            const info = {
              roomName: meeting.name,
              sentBy: { name: loggedInUser.username, id: loggedInUser.id },
            };
            // NotificationService.dispatchNotification(
            //   "transcriberReturnedMeeting",
            //   recipient,
            //   info
            // );
          }
        }
        if (loggedInUser?.role === "transcriber" && meeting.isApproved) {
          await FirebaseService.increaseRejectedCount(loggedInUser.id);
        }
        onSuccess();
        dispatch(removeMeeting(meeting.id, "myJobs"));
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_meeting_returned"),
          })
        );
      }
    } catch (err) {
      console.log(err);
      onSuccess(); //todo check this
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };
  const approveMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      setIsActionLoading(true);
      if (loggedInUser) await FirebaseService.approveProofing(meeting);
      onSuccess();
      if (meeting.id) dispatch(removeMeeting(meeting.id, "myJobs"));
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_sent_to_archive") })
      );
      onSuccess();
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const onSuccess = () => {
    setIsActionLoading(false);
    closeModal();
  };

  const unnasignProoferFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (!loggedInUser) return;
      setIsActionLoading(true);
      await FirebaseService.unAssignProofer(meeting.id);
      await FirebaseService.returnToTranscriber(meeting);
      onSuccess();
      dispatch(removeMeeting(meeting.id, "myJobs"));
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_meeting_returned") })
      );
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const typeOfAprroveModal = () => {
    if (selectedButton === "to_proof") {
      return "send_to_proof";
    }
    if (selectedButton === "to_send") {
      return "approve_meeting";
    }
    if (selectedButton === "return_to_available_meetings") {
      return "release_meeting";
    }
    if (selectedButton === "return_to_transcriber") {
      return "return_to_transcriber";
    }
    return "";
  };

  const onMeetingAction = () => {
    if (selectedButton === "to_proof" && selectedMeeting) {
      return sendToProof(selectedMeeting, onSuccess);
    }
    if (selectedButton === "return_to_transcriber" && selectedMeeting?.id) {
      return unnasignProoferFromMeeting(selectedMeeting, onSuccess);
    }
    if (selectedButton === "return_to_available_meetings" && selectedMeeting) {
      return unassignMyself(selectedMeeting, onSuccess);
    }
    if (selectedButton === "to_send" && selectedMeeting) {
      return approveMeeting(selectedMeeting, onSuccess);
    }
  };

  return (
    <>
      {loggedInUser && (
        <main className="main-container">
          <PageHeader title={t("my_jobs")} />
          {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
          {!!errorReason && <ErrorModal errorReason={errorReason} />}
          {!loadingReason && !errorReason && (
            <div className="meetings-page flex column align-center">
              {meetingsTableData.length > 0 && (
                <div className="meetingsTable">
                  <MeetingsTable
                    data={meetingsTableData}
                    rawData={meetings}
                    onRowClick={goToMeeting}
                    config={{
                      tableColumns: [
                        "id",
                        "name",
                        "isApproved",
                        "deadline",
                        "meetingLength",
                        "clientName",
                        "assignedTranscriber",
                        "speakers",
                        "lang",
                        "previewFormat",
                        "creationTime",
                        "price",
                        "status",
                        "actions",
                        "job",
                      ],
                      clickable: (row) => row.isApproved,
                      tableName: t("my_jobs"),
                      formatters: {
                        processProgressLastUpdate: (
                          processProgressLastUpdate
                        ) =>
                          processProgressLastUpdate &&
                          format(
                            new Date(processProgressLastUpdate),
                            "dd/MM/yyyy"
                          ),
                      },
                      columns: {
                        processProgressLastUpdate: {
                          hidden: loggedInUser?.role !== "super_user",
                        },
                        status: {
                          hidden: true,
                        },
                        clientName: { hidden: true },
                        assignedTranscriber: {
                          hidden:
                            loggedInUser.role === "super_user" ? false : true,
                        },
                        price: {
                          hidden:
                            loggedInUser.role === "super_user" ? true : false,
                        },
                        prooferPrice: {
                          hidden:
                            loggedInUser.role === "super_user" ? true : false,
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {meetingsTableData.length === 0 && !loadingReason && !errorReason && (
            <EmptyPageComp title={t("no_jobs")} />
          )}
          {showModal && selectedMeeting && (
            <Dialog
              className={getLanguageDirection(i18n.language)}
              open={showModal}
              onClose={() => setShowModal(false)}
            >
              <ApproveModal
                type={typeOfAprroveModal()}
                info={{
                  meetingName: selectedMeeting.name,
                  meetingPrice: selectedMeeting.price,
                }}
                approveBtn={{
                  text: t("approve"),
                  look: "neutral",
                  action: () => onMeetingAction(),
                }}
                declineBtn={{
                  text: t("cancel"),
                  look: "approve",
                  action: () => setShowModal(false),
                }}
                isPending={isActionLoading}
                specificClass={"cancelAbsolute"}
              ></ApproveModal>
            </Dialog>
          )}
        </main>
      )}
    </>
  );
};

export default MyJobs;
