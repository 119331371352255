import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  setRawPaymentsData,
  clearRawPaymentsData,
  clearPaymentsByMonths,
} from "../../store/payment/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  resetSorting,
  setErrorReason,
  popIndicator,
} from "../../store/system/actions";
import { PaymentsData, Debt } from "../../models/payment";
import FirebaseService from "../../services/FirebaseService";
import PageHeader from "../../components/PageHeader/PageHeader";
import PaymentPreview from "../../components/PaymentPreview/PaymentPreview";
import AddEntityCard from "../../components/AddEntityCard/AddEntityCard";
import sortAndFilterService from "../../services/sortAndFilterService";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import "./PaymentsPages.scss";
import PaymentsService from "../../services/PaymentsService";

const ClientPaymentsPage: FC = (): JSX.Element => {
  const { id } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);
  const rawPaymentsData = useSelector(
    (state: AppState) => state.paymentStore.rawPaymentsData
  );

  const [searchBy, setSearchBy] = useState<string>("");
  const [debtsToPreview, setDebtsToPreview] = useState<Debt[]>([]);
  const [clientName, setClientName] = useState<string>("");
  const [isPaymentForm, setIsPaymentForm] = useState<boolean>(false);
  const [summary, setSummary] = useState<{ dates: string; sum: number }>({
    dates: "",
    sum: 0,
  });

  useEffect(() => {
    if (sorting.type !== "payments") {
      dispatch(resetSorting("payments"));
    }
    loadclientPayments();
    loadClientDetails();
    return () => {
      dispatch(clearRawPaymentsData());
      dispatch(clearPaymentsByMonths());
    };
  }, [id]);

  useEffect(() => {
    if (rawPaymentsData) {
      let sorted = [...rawPaymentsData.debts];
      sorted = sortAndFilterService.sortPayments(
        sorted,
        sorting.sortBy,
        sorting.order
      );
      if (searchBy)
        sorted = sortAndFilterService.searchPayments(sorted, "debt", searchBy);
      setDebtsToPreview(sorted);
    }
  }, [sorting, searchBy, rawPaymentsData]);

  const loadclientPayments = async () => {
    dispatch(setLoadingReason("bringin payments"));
    try {
      if (id) {
        const payments: PaymentsData = await FirebaseService.getPayments(id);
        if (payments) {
          payments.debts = sortAndFilterService.sortPayments(
            payments.debts,
            "by-date",
            "descending"
          );
          setSummary({
            dates:
              format(payments.debts[0].createdAt, "dd/MM/yyyy") +
              " - " +
              format(
                payments.debts[payments.debts.length - 1].createdAt,
                "dd/MM/yyyy"
              ),
            sum: PaymentsService.getCurrDebt(payments, "client"),
          });
          dispatch(setRawPaymentsData(payments));
          dispatch(clearLoadingReason());
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const loadClientDetails = async () => {
    const transcriberRef = FirebaseService.getDocRef(
      FirebaseService.collections.clients,
      id
    );
    const transcriberDoc = await transcriberRef.get();
    setClientName(transcriberDoc.data()?.name);
  };

  const approvePayment = async (
    onSuccess: () => void,
    debt: Debt,
    idx: number
  ) => {
    try {
      if (rawPaymentsData) {
        const debtsPreviewClone = [...debtsToPreview];
        debt.wasPaid = true;
        debtsPreviewClone[idx] = debt;
        await FirebaseService.updateDebts(debtsPreviewClone, id);
        setSummary({
          ...summary,
          sum: summary.sum - debt.sum,
        });
        const rawPaymentsDataClone = { ...rawPaymentsData };
        rawPaymentsDataClone.debts = debtsPreviewClone;
        dispatch(setRawPaymentsData(rawPaymentsDataClone));
        onSuccess();
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_payment_approved"),
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  // const addNewDebt = async (debt: Debt) => {
  //   try {
  //     if (rawPaymentsData) {
  //       const rawPaymentsDataClone = { ...rawPaymentsData };
  //       rawPaymentsDataClone.debts.unshift(debt);
  //       await FirebaseService.updateDebts(rawPaymentsDataClone.debts, id);
  //       setSummary({
  //         ...summary,
  //         sum: summary.sum + debt.sum,
  //       });
  //       dispatch(setRawPaymentsData(rawPaymentsDataClone));
  //       setIsPaymentForm(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     dispatch(
  //       popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
  //     );
  //   }
  // };

  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };

  const searchAndSorting = {
    onSearchInput: onSearchBy,
    currSearchBy: searchBy,
  };

  const getHeaderBackButton = () => {
    if (!isPaymentForm)
      return {
        title: clientName,
        backAction: () => history.push(`/client/${id}`),
      };
    else
      return {
        title: t("add_debt"),
        backAction: () => setIsPaymentForm(false),
      };
  };

  const getHeaderTitle = () => {
    if (!isPaymentForm) return t("payment");
    else return t("add_debt");
  };

  const headerBackButton = getHeaderBackButton();

  const headerTitle = getHeaderTitle();

  return (
    <main className="main-container">
      <PageHeader
        title={headerTitle}
        backButton={headerBackButton}
        searchAndSorting={!isPaymentForm ? searchAndSorting : undefined}
      />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <div className="payments-page flex column align-center">
          {debtsToPreview.length > 0 && !isPaymentForm && (
            <div className="payments-grid-container">
              <AddEntityCard
                text={t("add_payment")}
                onClick={() => setIsPaymentForm(true)}
              />
              <PaymentPreview
                previewMode={"debt"}
                currPage={""}
                title={t("total_to_pay")}
                subtitle={summary.dates}
                sum={summary.sum}
                className={"grey"}
              />
              {debtsToPreview.map((debt, idx) => (
                <PaymentPreview
                  key={idx}
                  previewMode={"debt"}
                  wasPaid={debt.wasPaid}
                  currPage={"client-payments"}
                  title={debt.reason}
                  subtitle={format(debt.createdAt, "dd/MM/yyyy")}
                  onApprovePayment={(onSuccess) =>
                    approvePayment(onSuccess, debt, idx)
                  }
                  sum={debt.sum}
                />
              ))}
            </div>
          )}
          {/* {isPaymentForm && (
            <AddDebtForm sendDebt={(debt: Debt) => addNewDebt(debt)} />
          )} */}
        </div>
      )}
      {debtsToPreview.length === 0 && !loadingReason && !errorReason && (
        <EmptyPageComp title={t("no_available_payments")} />
      )}
    </main>
  );
};

export default ClientPaymentsPage;
