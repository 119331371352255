import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import firebase from "firebase";
import * as Sentry from "@sentry/browser";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

import AuthService from "../../services/AuthService";

import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";
import DigitInput from "../../components/common/DigitInput/DigitInput";

import Logger from "../../services/Logger";

const logger = Logger("PhoneVerifyStep");

import "./AuthPages.scss";

interface Props {
  onPhoneSend?: (phonenumber: string) => any;
  onVerificationSend?: (code: string) => any;
  askForPhonenumber?: boolean;
  error?: string;
}

type PhoneLoginInputs = {
  phonenumber: string;
};

type VerifyPhoneInputs = {
  code: string;
};

const PhoneVerifyStep: FC<Props> = ({
  onPhoneSend,
  onVerificationSend,
  askForPhonenumber = true,
  error,
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [
    verification,
    setVerification,
  ] = useState<firebase.auth.ConfirmationResult>();
  const [phoneVerificationError, setPhoneVerificationError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendPhonenumber = async (phonenumber: string) => {
    try {
      setIsLoading(true);
      setPhoneVerificationError(null);

      if (onPhoneSend) {
        const verificationId = await onPhoneSend(phonenumber);
        setVerification(verificationId);
      } else {
        const verificationId = await AuthService.loginPhone(phonenumber);
        setVerification(verificationId);
      }
    } catch (err: any) {
      Sentry.captureException(err);
      // if (err === "auth/unsupported-first-factor") {
      //   handleUnsupportedFirstFactor();
      // }
      setPhoneVerificationError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendVerification = async (code: string) => {
    try {
      setIsLoading(true);
      setPhoneVerificationError(null);
      if (onVerificationSend) {
        await onVerificationSend(code);
      } else {
        await verification?.confirm(code);
        history.push("/");
      }
    } catch (err: any) {
      const supportedErrorCodes = ["auth/invalid-verification-code"];
      if (supportedErrorCodes.includes(err.code)) {
        setPhoneVerificationError(t(err.code));
      } else {
        setPhoneVerificationError(t("auth/default"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<PhoneLoginInputs | VerifyPhoneInputs>({ mode: "onChange" });
  const sendPhone: SubmitHandler<PhoneLoginInputs> = (data: {
    phonenumber: string;
  }) => handleSendPhonenumber(data.phonenumber);
  const sendVerification: SubmitHandler<VerifyPhoneInputs> = (data: {
    code: string;
  }) => handleSendVerification(data.code);

  return (
    <div className="PhoneVerifyStep">
      {!verification && askForPhonenumber ? (
        <form
          className="styledForm phoneLogin centered"
          onSubmit={handleSubmit(sendPhone)}
        >
          <div className="formError phoneResult">
            {error || phoneVerificationError}
          </div>
          <FormInput
            className="styledInput alignEnd"
            placeholder={t("phone_number")}
            autofocus={true}
            disabled={isLoading}
            register={register("phonenumber", {
              required: { value: true, message: t("required_field") },
              minLength: 8,
            })}
          />
          <Button
            type="submit"
            className="button"
            label={t("send_verification")}
            loading={isLoading}
            disabled={!isValid}
          />
        </form>
      ) : (
        <form
          className="styledForm phoneLogin centered"
          onSubmit={handleSubmit(sendVerification)}
        >
          <div className="formError phoneResult">
            {error || phoneVerificationError}
          </div>
          <DigitInput
            onChange={
              register("code", {
                required: { value: true, message: t("required_field") },
                minLength: 6,
              }).onChange
            }
          />
          <Button
            type="submit"
            className="button"
            label={t("send_now")}
            loading={isLoading}
            disabled={!isValid}
          />
        </form>
      )}
    </div>
  );
};

export default PhoneVerifyStep;
