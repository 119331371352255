import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import i18n from "../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../utils/formatters";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { setClients, clearClients } from "../../store/client/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  resetSorting,
  setErrorReason,
} from "../../store/system/actions";
import { ClientPreviewData } from "../../models/client";

import * as SettingService from "../../services/SettingService";
import FirebaseService from "../../services/FirebaseService";
import { getLanguageDirection } from "../../utils/locales";

import GenericTable from "../../components/Table/GenericTable";
import PageHeader from "../../components/PageHeader/PageHeader";
import sortAndFilterService from "../../services/sortAndFilterService";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import "./ClientsPage.scss";

const ClientsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );

  const clients = useSelector((state: AppState) => state.clientStore.clients);
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);
  const [searchBy, setSearchBy] = useState<string>("");
  const settings = useSelector((state: AppState) => state.userStore.settings);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.role !== "super_user") {
        history.push("/");
        return;
      } else {
        loadClients();
      }
    }
    if (sorting.type !== "transcribers/clients")
      dispatch(resetSorting("transcribers/clients"));
    return () => {
      dispatch(clearClients());
    };
  }, [loggedInUser]);

  const loadClients = async () => {
    try {
      dispatch(setLoadingReason(t("loading_clients")));
      const clients = await FirebaseService.getClientsPreview();
      if (clients) {
        dispatch(setClients(clients));
      }
      dispatch(clearLoadingReason());
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };

  const searchAndSorting = {
    onSearchInput: onSearchBy,
    currSearchBy: searchBy,
  };

  let clientsToPreview = [...clients];
  clientsToPreview = sortAndFilterService.sortClients(
    clientsToPreview,
    sorting.sortBy,
    sorting.order
  );
  if (searchBy)
    clientsToPreview = sortAndFilterService.searchClients(
      clientsToPreview,
      searchBy
    );
  const orderTable = (property: string, descOrAsc: "desc" | "asc") => {
    SettingService.setSettings("orderBy", property, dispatch);
    SettingService.setSettings("order", descOrAsc, dispatch);
  };
  const tableConfig: {
    tableName: string;
    columns: {
      [key: string]: any;
    };
    formatters?: {
      [key: string]: (val: any) => string | number | JSX.Element;
    };
    multiSelect: boolean;
    order?: "desc" | "asc";
    orderBy?: string;
  } = {
    tableName: "clients",
    columns: {
      name: { label: "client_name" },
    },
    order: settings["order"],
    orderBy: settings["orderBy"],
    multiSelect: false,
  };
  const goToClient = (meeting: { [key: string]: any }) => {
    history.push(`/client/meetings/${meeting.id}`);
  };

  return (
    <main className="main-container">
      <PageHeader title={t("clients")} />
      {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <div className="meetings-page flex column">
          <div
            onClick={() => history.push("new-client")}
            className="add_client"
          >
            {t("add_client")}{" "}
            <FontAwesomeIcon size="1x" icon={["fal", "plus"]} />
          </div>
          {clients.length > 0 && (
            <div className="meetingsTable">
              <GenericTable
                data={clients}
                config={tableConfig}
                onRowClick={goToClient}
                orderTable={orderTable}
              />
            </div>
          )}
        </div>
      )}
      {clients.length === 0 && !loadingReason && !errorReason && (
        <EmptyPageComp title={t("no_clients")} />
      )}
    </main>
  );
};

export default ClientsPage;
