import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import { useDispatch } from "react-redux";
import { popIndicator } from "../../store/system/actions";
import { SlicesData } from "../../models/job";
import { RoomAsset } from "../../models";
import { useTranslation } from "react-i18next";
import TimeFormat from "hh-mm-ss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyMediaPlayer from "../../components/MediaPlayers/MyMediaPlayer";
import "./MembersSamplingComp.scss";

class Props {
  title: string;
  mediaSource: RoomAsset;
  slices: SlicesData[];
  onClose: () => void;
  updateSlices: (Slices: SlicesData[]) => void;
  speakers?: any;
}

const MembersSamplingComp: FC<Props> = ({
  title,
  mediaSource,
  slices,
  onClose,
  updateSlices,
  speakers,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currTime, setCurrTime] = useState<number>(0);
  const [isValidArr, setIsValidArr] = useState<boolean[]>([]);
  const [formattedSpeakers, setFormattedSpeakers] = useState<
    { label: string; value: string; clientId: string }[]
  >([]);
  const [chosenMedia, setChosenMedia] = useState<RoomAsset>();

  useEffect(() => {
    const validArr: boolean[] = slices.map((slicesData) => {
      let totalSecs = 0;
      slicesData.slices.forEach((slice) => {
        if (slice[0] >= 0 && slice[1] >= 0)
          totalSecs = totalSecs + (slice[1] - slice[0]);
      });
      return totalSecs > 45 ? true : false;
    });
    setIsValidArr(validArr);
  }, [slices]);

  useEffect(() => {
    if (_.isNil(speakers) || _.isNil(slices)) return;
    const uniqSpeakers = _.uniqBy([...speakers, ...slices], (s) => s.name);
    const formattedSpeakers = uniqSpeakers.map((s) => ({
      label: s.name,
      value: s.name,
      clientId: s.clientId,
      speakerId: s.speakerId,
    }));
    setFormattedSpeakers(formattedSpeakers);
  }, [slices, speakers]);

  // const validateNewName = (e: any) => {
  //   const newName = e.currentTarget.value;
  //   let nameCount = 0;
  //   slices.forEach((sliceData) => {
  //     if (sliceData.name === newName) nameCount++;
  //   });
  //   if (nameCount > 1) {
  //     dispatch(
  //       popIndicator({
  //         type: "failure",
  //         txt: t("indicator_members_names_duplicates"),
  //       })
  //     );
  //     e.currentTarget.focus();
  //   }
  // };

  const onAddMember = () => {
    const name = t("member") + " " + (slices.length + 1);
    const member: SlicesData = { name, slices: [], speakerId: null };
    updateSlices([member, ...slices]);
  };

  const removeMember = (idx: number) => {
    const slicesClone = [...slices];
    slicesClone.splice(idx, 1);
    updateSlices(slicesClone);
  };

  const removeSlice = (idx: number, sliceIdx: number) => {
    const slicesClone = [...slices];
    slicesClone[idx].slices.splice(sliceIdx, 1);
    updateSlices(slicesClone);
  };

  const updateName = (value: string, idx: number) => {
    const slicesClone = [...slices];
    slicesClone[idx].name = value;
    updateSlices(slicesClone);
  };

  const selectExistingSpeaker = (speaker: any, idx: number) => {
    const slicesClone = [...slices];
    slicesClone[idx].name = speaker.value;
    slicesClone[idx].speakerId = speaker.speakerId;
    updateSlices(slicesClone);
  };

  const setMarker = (place: string, idx: number) => {
    const slicesClone = [...slices];
    const memberToUpdate = slicesClone[idx];
    const currMarkers = memberToUpdate.slices[memberToUpdate.slices.length - 1];
    if (!currMarkers || (currMarkers[0] >= 0 && currMarkers[1] >= 0)) {
      if (place === "in") memberToUpdate.slices.push([currTime, -1]);
      if (place === "out") memberToUpdate.slices.push([-1, currTime]);
    } else {
      if (place === "in") {
        if (currMarkers[1] >= 0 && currTime > currMarkers[1]) {
          dispatch(
            popIndicator({
              type: "failure",
              txt: t("indicator_invalid_sequence"),
            })
          );
          return;
        }
        currMarkers[0] = currTime;
      } else {
        if (currTime < currMarkers[0]) {
          dispatch(
            popIndicator({
              type: "failure",
              txt: t("indicator_invalid_sequence"),
            })
          );
          return;
        }
        currMarkers[1] = currTime;
      }
    }
    updateSlices(slicesClone);
  };

  return (
    <div className="members-sampling-cmp">
      <div className="header-section">
        <h1 className="main-title">{title}</h1>
      </div>
      <div className="player-section">
        <MyMediaPlayer
          chosenMedia={chosenMedia}
          setChosenMedia={setChosenMedia}
          showMediaSourceManagement={false}
          mediaSources={[mediaSource]}
          sendCurrTime={setCurrTime}
          isDraggable={false}
        />
      </div>
      <div className="slices-container">
        {slices.length > 0 &&
          slices.map((slicesData, idx) => (
            <div
              key={idx}
              className={`member !${isValidArr[idx] && "invalid"}`}
            >
              <div className="name">
                <CreatableSelect
                  onChange={(option, changed) => {
                    if (changed.action === "select-option") {
                      selectExistingSpeaker(option, idx);
                    }
                    console.log({ option, changed });

                    if (!option) return;
                    updateName(option.value, idx);
                  }}
                  value={_.find(
                    formattedSpeakers,
                    (s) => s.label === slicesData.name
                  )}
                  options={formattedSpeakers}
                  placeholder="בחד דובר"
                  formatCreateLabel={(newOption) =>
                    `צור דובר חדש "${newOption}"`
                  }
                />
                <div onClick={() => removeMember(idx)} className="remove-btn">
                  <FontAwesomeIcon icon={["fal", "times-circle"]} />
                </div>
              </div>
              <div className="time-slices">
                {slicesData.slices.map((slice, sliceIdx) => (
                  <div key={sliceIdx} className="in-out-container">
                    {slice[0] >= 0 && (
                      <div className="card">
                        <p>
                          {TimeFormat.fromS(
                            Math.round(slice[0]),
                            `${slice[0] > 3600 ? "hh:" : ""}mm:ss`
                          )}
                        </p>
                      </div>
                    )}
                    {slice[0] < 0 && (
                      <div className="card blank">
                        <p>00:00</p>
                      </div>
                    )}
                    {slice[1] >= 0 && (
                      <div className="card">
                        <p>
                          {TimeFormat.fromS(
                            Math.round(slice[1]),
                            `${slice[1] > 3600 ? "hh:" : ""}mm:ss`
                          )}
                        </p>
                        {slice[0] >= 0 && slice[1] >= 0 && (
                          <div
                            onClick={() => removeSlice(idx, sliceIdx)}
                            className="remove-btn"
                          >
                            <FontAwesomeIcon icon={["fal", "times-circle"]} />
                          </div>
                        )}
                      </div>
                    )}
                    {slice[1] < 0 && (
                      <div className="card blank">
                        <p>00:00</p>
                      </div>
                    )}
                  </div>
                ))}
                {(slicesData.slices.length === 0 ||
                  (slicesData.slices[slicesData.slices.length - 1][0] >= 0 &&
                    slicesData.slices[slicesData.slices.length - 1][1] >=
                      0)) && (
                  <div className="in-out-container">
                    <div className="card blank">
                      <p>00:00</p>
                    </div>
                    <div className="card blank">
                      <p>00:00</p>
                    </div>
                  </div>
                )}
                <div className="in-out-btns-container">
                  <button
                    className="card"
                    onClick={() => {
                      setMarker("in", idx);
                    }}
                  >
                    <span className="record-icon-span">
                      <FontAwesomeIcon
                        size="sm"
                        color="red"
                        icon={["fal", "record-vinyl"]}
                      />
                    </span>
                    {TimeFormat.fromS(
                      Math.round(currTime),
                      `${currTime > 3600 ? "hh:" : ""}mm:ss`
                    )}
                  </button>
                  <button
                    className="card"
                    onClick={() => {
                      setMarker("out", idx);
                    }}
                  >
                    <span className="record-icon-span">
                      <FontAwesomeIcon
                        size="sm"
                        color="red"
                        icon={["fal", "record-vinyl"]}
                      />
                    </span>
                    {TimeFormat.fromS(
                      Math.round(currTime),
                      `${currTime > 3600 ? "hh:" : ""}mm:ss`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="actions">
        <button onClick={onAddMember} className="add-member">
          <div className="icn-wrapper">
            <FontAwesomeIcon icon={["fal", "user"]} />
          </div>
          {t("add_member")}
        </button>
        <button onClick={onClose} className="finish-btn">
          {t("finish_sampling")}
        </button>
      </div>
    </div>
  );
};

export default MembersSamplingComp;
