import React from "react";
import classNames from "classnames";
import { UseFormReturn } from "react-hook-form";

import {
  GeneralPageSlice,
  HeaderFooterPageSlice,
  InformationPageSlice,
  TableOfSubjectsPageSlice,
  RemarksPageSlice,
  SubjectsPageSlice,
  SpeakersPageSlice,
  DecisionsPageSlice,
  NotesPageSlice,
  TasksPageSlice,
  ImagesPageSlice,
  FocusedDocumentPageSlice,
} from "../../../../pages/ExportConfig/ExportConfigTypes";
import { ValidationsFormPageSlice } from "../../../../pages/ValidationsConfig/ValidationsConfigTypes";

import "./PagerMenu.scss";

export type PageSlice =
  | GeneralPageSlice
  | HeaderFooterPageSlice
  | InformationPageSlice
  | TableOfSubjectsPageSlice
  | RemarksPageSlice
  | SubjectsPageSlice
  | SpeakersPageSlice
  | DecisionsPageSlice
  | NotesPageSlice
  | TasksPageSlice
  | ImagesPageSlice
  | FocusedDocumentPageSlice
  | ValidationsFormPageSlice;

export type Page = {
  form: UseFormReturn<PageSlice>;
  name: string;
  slice: new (args: PageSlice) => PageSlice;
  component: JSX.Element;
};

export interface PageList {
  [key: string]: Page;
}

export type KeyOfPageList = keyof PageList;

export interface PagerMenuProps {
  pageList: PageList;
  currentPage: string;
  setCurrentPage: (currentPage: string) => void;
  bottomButtons?: React.ReactNode;
}

export const PagerMenu = ({
  bottomButtons,
  pageList,
  currentPage,
  setCurrentPage,
}: PagerMenuProps) => {
  return (
    <React.Fragment>
      <div className="pageListContainer">
        <div className="pageList">
          {Object.entries(pageList).map((entry: [string, Page], i: number) => (
            <div
              className={classNames([
                "page",
                { selected: entry[0] === currentPage },
              ])}
              key={i}
              onClick={() => setCurrentPage(entry[0])}
            >
              {entry[1].name}
            </div>
          ))}
        </div>
        {bottomButtons && <div className="bottomButtons">{bottomButtons}</div>}
      </div>
      <div className="pageWrapper">
        {Object.entries(pageList).map(
          (entry: [string, Page], i: number) =>
            entry[0] === currentPage && <div key={i}>{entry[1].component}</div>
        )}
      </div>
    </React.Fragment>
  );
};
