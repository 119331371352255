import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { AppState } from "../../store/rootReducer";
import AuthService from "../../services/AuthService";
import Logger from "../../services/Logger";

import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";
import DigitInput from "../../components/common/DigitInput/DigitInput";

const logger = Logger("LoginStep");

import "./AuthPages.scss";

type PhoneLoginInputs = {
  phonenumber: string;
};

type VerifyPhoneInputs = {
  code: string;
};

type EmailLoginInputs = {
  email: string;
  password: string;
};

const LoginStep: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [unsupportedFirstFactor, setUnsupportedFirstFactor] = useState(false);
  const [
    verification,
    setVerification,
  ] = useState<firebase.auth.ConfirmationResult>();
  const [phoneVerificationError, setPhoneVerificationError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const handleSendPhonenumber = async (phonenumber: string) => {
    try {
      setIsLoading(true);
      setPhoneVerificationError(null);
      const verification = await AuthService.loginPhone(phonenumber);
      setVerification(verification);
    } catch (err: any) {
      logger.error(err, "handleSendPhonenumber");
      if (err === "auth/unsupported-first-factor") {
        handleUnsupportedFirstFactor();
      }
      setPhoneVerificationError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendVerification = async (code: string) => {
    try {
      setIsLoading(true);
      setPhoneVerificationError(null);
      await verification?.confirm(code);
    } catch (err: any) {
      logger.error(err, "handleSendVerification");
      const supportedErrorCodes = ["auth/invalid-verification-code"];
      if (supportedErrorCodes.includes(err.code)) {
        setPhoneVerificationError(t(err.code));
      } else {
        setPhoneVerificationError(t("auth/default"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsupportedFirstFactor = () => {
    setUnsupportedFirstFactor(true);
    const redirectToEmailLogin = () => {
      setUnsupportedFirstFactor(false);
      setPhoneVerificationError(null);
    };

    setTimeout(redirectToEmailLogin, 5000);
  };

  const PhoneLoginStep = ({
    sendPhoneNumber = handleSendPhonenumber,
    sendVerificationCode = handleSendVerification,
  }) => {
    const {
      register,
      handleSubmit,
      watch,
      getValues,
      formState: { errors, isDirty, isValid },
    } = useForm<PhoneLoginInputs | VerifyPhoneInputs>({ mode: "onChange" });
    const sendPhone: SubmitHandler<PhoneLoginInputs> = (data: {
      phonenumber: string;
    }) => sendPhoneNumber(data.phonenumber);
    const sendVerification: SubmitHandler<VerifyPhoneInputs> = (data: {
      code: string;
    }) => sendVerificationCode(data.code);

    return (
      <div className="phoneLoginContainer">
        {!verification ? (
          <form
            className="styledForm phoneLogin centered"
            onSubmit={handleSubmit(sendPhone)}
          >
            <div className="formError phoneResult">
              {phoneVerificationError}
            </div>
            <FormInput
              className="styledInput alignEnd"
              placeholder={t("phone_number")}
              autofocus={true}
              disabled={isLoading}
              register={register("phonenumber", {
                required: { value: true, message: t("required_field") },
                minLength: 8,
              })}
            />
            <Button
              type="submit"
              className="button"
              label={t("send_verification")}
              loading={isLoading}
              disabled={!isValid}
            />
          </form>
        ) : (
          <form
            className="styledForm phoneLogin centered"
            onSubmit={handleSubmit(sendVerification)}
          >
            <div className="formError phoneResult">
              {phoneVerificationError}
            </div>
            <DigitInput
              onChange={
                register("code", {
                  required: { value: true, message: t("required_field") },
                  minLength: 6,
                }).onChange
              }
            />
            <Button
              type="submit"
              className="button"
              label={t("send_now")}
              loading={isLoading}
              disabled={!isValid}
            />
          </form>
        )}
      </div>
    );
  };

  return <PhoneLoginStep />;
};

export default LoginStep;
