export const scrollInto = (elementId: string) => {
  setTimeout(() => {
    const rangeToScrollInto = document.getElementById(elementId);
    if (rangeToScrollInto) {
      rangeToScrollInto.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    } else {
      scrollInto(elementId);
    }
  }, 100);
};

export const focusAndSetCursor = (
  rangeIndexToFocus: number,
  cursorPosition = 0
) => {
  const rangeToFocus = document.getElementById(
    `range-${rangeIndexToFocus}`
  ) as HTMLInputElement;

  if (rangeToFocus) {
    const cursorPositionToSet =
      cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length + 1; // +1 to set cursor after the space
    setTimeout(() => {
      rangeToFocus.focus();
      rangeToFocus.setSelectionRange(cursorPositionToSet, cursorPositionToSet);
    }, 0);
  } else {
    // Retry - for creating new range at the end
    setTimeout(() => focusAndSetCursor(rangeIndexToFocus, cursorPosition), 10);
  }
};

export const scrollToOffset = (
  elementId: string,
  offset: number,
  retries = 0
) => {
  setTimeout(() => {
    const rangeToScrollInto = document.getElementById(elementId);
    if (rangeToScrollInto) {
      rangeToScrollInto.scrollTop = offset;
    } else if (retries < 3) {
      scrollToOffset(elementId, offset, retries + 1);
    }
  }, 100);
};
