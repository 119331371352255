import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import classNames from "classnames";

import "./ImageUpload.scss";

interface ImageUploadProps {
  id: string;
  handleFileUpload: (file: File) => Promise<string>;
  controller?: {
    name: any;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
  };
  disabled?: boolean;
}

export const ImageUpload = ({
  id,
  controller,
  disabled,
  handleFileUpload,
}: ImageUploadProps) => {
  const initialCurrentImage = () => {
    if (controller) {
      return controller?.getValues(controller?.name);
    } else {
      return "";
    }
  };

  const [currentImage, setCurrentImage] = useState<string>(
    initialCurrentImage()
  );

  const handleOnChange = async (e: any) => {
    if (controller) {
      const file = e.target.files[0];
      const url = await handleFileUpload(file);
      controller.setValue(controller.name, url);
      setCurrentImage(url);
    }
  };

  const onLogoClick = (e: any) => {
    if (disabled) return;
    if (controller) {
      controller.setValue(controller.name, "");
      setCurrentImage("");
    }
  };

  return (
    <div className={classNames("ImageUpload", { disabled })}>
      <label htmlFor={id}>
        <FontAwesomeIcon icon={["far", "upload"]} />
        <input
          multiple={false}
          id={id}
          type="file"
          onChange={handleOnChange}
          accept="image/png, image/gif, image/jpeg , .svg"
          disabled={disabled}
        ></input>
      </label>
      {currentImage && (
        <div className="previewImageWrapper">
          <img className="previewImage" src={currentImage} alt="" />
          <div className="delete" onClick={onLogoClick}>
            <FontAwesomeIcon icon={"trash"}></FontAwesomeIcon>
          </div>
        </div>
      )}
    </div>
  );
};
