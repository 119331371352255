import React from "react";
import { useTranslation } from "react-i18next";

import {
  fontSizeOptions,
  NotesPageSlice,
  SettingPagesProps,
} from "../ExportConfigTypes";

import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

import "./../SettingPages.scss";

export const NotesPage = ({ form }: SettingPagesProps<NotesPageSlice>) => {
  const { t } = useTranslation();

  const notesWatch: NotesPageSlice = form?.watch() as NotesPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            {t("export_config_notes_title")}
            <ControllerHOC control={control} name="notes.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_notes_explanation_1")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="notes.font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!notesWatch?.notes?.isActive}
              />
            </ControllerHOC>

            <ControllerHOC
              control={control}
              name="notes.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!notesWatch?.notes?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="notes.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!notesWatch?.notes?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="notes.font.color"
            >
              <ColorPicker disabled={!notesWatch?.notes?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
