import React from "react";
import { useTranslation } from "react-i18next";

import {
  fontSizeOptions,
  RemarksPageSlice,
  SettingPagesProps,
} from "../ExportConfigTypes";

import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { MultiTextInput } from "../../../components/common/V3/MultiTextInput/MultiTextInput";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextInput } from "../../../components/common/V3/TextInput/TextInput";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

import "./../SettingPages.scss";

export const RemarksPage = ({ form }: SettingPagesProps<RemarksPageSlice>) => {
  const { t } = useTranslation();

  const remarksWatch: RemarksPageSlice = form?.watch() as RemarksPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;
  const getValues = form?.getValues;
  const errors = form?.formState.errors;

  return (
    <form>
      <div className="setting-page">
        {/* START SECTION */}
        <div className="section">
          <div className="title">{t("export_config_remarks_title")}</div>
          <div className="sub-title">
            {t("export_config_remarks_explanation_1")}
          </div>
        </div>
        {/* END SECTION */}
        {/* START SECTION */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_remarks_start_end")}:
            </div>
            <ControllerHOC control={control} name="startEnd.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="inputs-row mb-3">
            <ControllerHOC
              name="startEnd.text1"
              control={control}
              errors={errors}
            >
              <TextInput
                placeholder={"Please enter text"}
                disabled={!remarksWatch?.startEnd?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              name="startEnd.text2"
              control={control}
              errors={errors}
            >
              <TextInput
                placeholder={"Please enter text"}
                disabled={!remarksWatch?.startEnd?.isActive}
              />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="startEnd.font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!remarksWatch?.startEnd?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="startEnd.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!remarksWatch?.startEnd?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="startEnd.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!remarksWatch?.startEnd?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="startEnd.font.color"
            >
              <ColorPicker disabled={!remarksWatch?.startEnd?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* END SECTION */}
        {/* START SECTION */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_remarks_greeting")}:
            </div>
            <ControllerHOC control={control} name="greeting.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="inputs-row mb-3">
            <ControllerHOC
              name="greeting.text1"
              control={control}
              errors={errors}
            >
              <TextInput
                placeholder={"Please enter text"}
                disabled={!remarksWatch?.startEnd?.isActive}
              />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="greeting.font.fontSize">
              <SelectInput
                disabled={!remarksWatch?.greeting?.isActive}
                options={fontSizeOptions}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="greeting.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!remarksWatch?.greeting?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="greeting.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                disabled={!remarksWatch?.greeting?.isActive}
                hasJustify={false}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="greeting.font.color"
            >
              <ColorPicker disabled={!remarksWatch?.greeting?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* END SECTION */}
        {/* START SECTION */}
        <div className="section">
          <div className="inputs-row mb-3">
            <div className="input-title">
              {t("export_config_remarks_signature")}:
            </div>
          </div>
          <div className="inputs-row mb-3">
            <ControllerHOC control={control} name="signature.font.fontSize">
              <SelectInput options={fontSizeOptions} />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="signature.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection />
            </ControllerHOC>
          </div>
          <div className="input-rows">
            <ControllerHOC
              name={"signature.names"}
              getValues={getValues}
              setValue={setValue}
            >
              <MultiTextInput />
            </ControllerHOC>
          </div>
        </div>
        {/* END SECTION */}
      </div>
    </form>
  );
};
