import { useTranslation } from "react-i18next";
import React, { FC, useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./ApproveModal.scss";
import { MeetingPreviewData } from "../../models";
import classNames from "classnames";

class Info {
  meetingName?: string;
  meetingPrice?: number;
}

interface Props {
  type: string;
  info: Info;
  meeting?: MeetingPreviewData;
  approveBtn: {
    look: string;
    text: string;
    action: (() => Promise<void> | void | null) | undefined;
  };
  declineBtn: {
    look: string;
    text: string;
    action: () => Promise<void> | void | null;
  };
  isPending: boolean;
  specificClass?: string;
}

const ApproveModal: FC<Props> = ({
  type,
  info,
  approveBtn,
  declineBtn,
  specificClass,
  isPending,
}): JSX.Element => {
  const { t } = useTranslation();

  const [transition, setTransition] = useState<number>(100);

  useEffect(() => {
    setTimeout(() => setTransition(0), 0);
  }, []);

  const onDecline = () => {
    setTransition(100);
    setTimeout(() => declineBtn.action(), 200);
  };

  const onApprove = () => {
    approveBtn.action && approveBtn.action();
  };

  let modalStr = "";

  if (type === "release_meeting")
    modalStr =
      "<p>" +
      t("approve_release_meeting_1") +
      ` <span>${info.meetingName}</span>? ` +
      "</p>" +
      "<p>" +
      t("approve_release_meeting_2") +
      ` &#8362;${info.meetingPrice?.toLocaleString()} ` +
      t("approve_release_meeting_3") +
      "." +
      "</p>";
  else if (type === "send_to_proof")
    modalStr =
      "<p>" +
      `<span>${t("approve_send_to")}</span>` +
      ` <span class="roomName">${info.meetingName}<span style="color: black">?</span></span>` +
      "</p>";
  else if (type === "approve_meeting")
    modalStr =
      "<p>" +
      t("approve_approve_meeting") +
      ` <span>${info.meetingName}?</span>` +
      "</p>";
  else if (type === "return_to_transcriber")
    modalStr =
      "<p>" +
      t("approve_return_to_transcriber_1") +
      ` <span>${info.meetingName}</span> ` +
      `${t("approve_return_to_transcriber_2")}?` +
      "</p>";
  else if (type === "decline-to-transcriber")
    modalStr = "<p>" + t("approve_decline_to_transcriber") + "?" + "</p>";
  else if (type === "release_from_transcriber")
    modalStr =
      "<p>" +
      t("approve_box_release_meeting_1") +
      ` <span>${info.meetingName}</span> ` +
      `${t("approve_box_release_meeting_2")}?` +
      "</p>";
  else if (type === "release_from_proofer")
    modalStr =
      "<p>" +
      t("approve_box_release_meeting_1") +
      ` <span>${info.meetingName}</span> ` +
      `${t("approve_box_release_meeting_3")}?` +
      "</p>";
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{ transform: `translateY(${transition}%)` }}
      className={classNames("approve-modal", specificClass)}
    >
      <div dangerouslySetInnerHTML={{ __html: modalStr }}></div>
      <div className="btns">
        {!isPending && (
          <button
            onClick={onDecline}
            className={`meeting-preview-btn ${declineBtn.look}`}
          >
            {declineBtn.text}
          </button>
        )}
        {!isPending && (
          <button
            onClick={onApprove}
            className={`meeting-preview-btn ${approveBtn.look}`}
          >
            {approveBtn.text}
          </button>
        )}
        {isPending && (
          <div className="loading-spinner">
            <CircularProgress style={{ width: "20px" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ApproveModal;
