import config from "../config";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { NewMeetingData } from "../models";
import { Debt1 } from "../models/payment";
import { RangeValidationConfig } from "../models/range";

import FirebaseService from "./FirebaseService";
import TrackingService from "./TrackingService";

import Logger from "./Logger";
const logger = Logger("CloudFunctionsService");

const deliverToProofEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.deliverToProof;

const editProgressEndpoint =
  config.appEngine.url + config.appEngine.editProgress;

const rerunSttEndpoint = config.appEngine.url + config.appEngine.rerunStt;

const fixTimestampsEndpoint =
  config.cloudFunctions.url + config.cloudFunctions.fixTimestamps;
const alignSubtitlesEndpoint =
  config.appEngine.url + config.appEngine.alignSubtitles;
const jobSplitEndpoint = config.appEngine.url + config.appEngine.jobSplit;

const getMergedSplitJobEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.getMergedSplitJob;

const addDebtsEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.debts.addDebts;
const completeJobDebtsEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.debts.completeJobDebts;
const getDebtsByUserEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.debts.getDebtsByUser;

const wordsSnapshotEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.wordsSnapshot;
const jobSnapshotEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.jobSnapshot;

const restoreJobEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.restoreJob;
const resetJobEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.resetJob;
const wordsResetEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.wordsReset;
const getMyJobsEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.getMyJobs;
const getRoomPriceEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.getRoomPrice;

const disableUserEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.disableUser;

const enableUserEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.enableUser;

const json2subtitle =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.json2subtitle;

const createJobsSubtitles =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.createJobsSubtitles;

const mergeJobsEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.mergeJobs;

const approveProofingEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.approveProofing;

const importFileEndpoint =
  config.cloudFunctions.url + "platform-v2-" + config.cloudFunctions.importFile;

const isExistingUserEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.auth.isExistingUser;

const getMfaStatusByPhoneEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.auth.getMfaStatusByPhone;

const approveMultiFactorEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.auth.approveMultiFactor;

const createUserEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.auth.createUser;

const completeProviderSignupEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.auth.completeProviderSignup;

const createJobWordsEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.createJobWords;

const createSubtitlesTranslationJobEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.createSubtitlesTranslationJob;

const doneTextEnhancementEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.doneTextEnhancement;

const addBonusToJobDebtEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.debts.addBonusToJobDebt;

const copyNotesFromParentEndpoint =
  config.cloudFunctions.url +
  "platform-v2-" +
  config.cloudFunctions.copyNotesFromParent;

const startLiveInterviewEndpoint =
  config.cloudFunctions.url + "platform-v2-stream-startLiveStream";

const endLiveInterviewEndpoint =
  config.cloudFunctions.url + "platform-v2-stream-endLiveStream";

const liveInterviewStatusEndpoint =
  config.cloudFunctions.url + "platform-v2-stream-liveStreamStatus";

const triggerCloudFunction = async (url: string, data?: any) => {
  try {
    const userToken = await FirebaseService.auth.currentUser?.getIdToken();
    if (!userToken)
      throw new Error("cloudFunctionService Error - unable to get user token");
    const response = await axios.post(url, data, {
      headers: { Authorization: userToken },
    });

    return response;
  } catch (err) {
    logger.error(err, "triggerCloudFunction");
    Sentry.captureException(err);
    return { status: 500, data: { success: false } };
  }
};

const deliverToProof = async (userId: string, jobId: string) => {
  const response = await triggerCloudFunction(deliverToProofEndpoint, {
    userId,
    jobId,
  });

  if (response.status !== 200) {
    Sentry.captureMessage(
      `deliverToProof - failed. executionId: ${response.data?.executionId}`
    );
    throw new Error("deliverToProof - Failed");
  }

  return response;
};

const disableUser = async (userId: string) => {
  try {
    const response = await triggerCloudFunction(disableUserEndpoint, {
      userId,
    });

    if (response.status !== 200) {
      throw new Error("disableUser - Failed");
    }

    return response;
  } catch (error) {
    logger.error(error, "disableUser");
    throw error;
  }
};

const enableUser = async (userId: string) => {
  try {
    const response = await triggerCloudFunction(enableUserEndpoint, {
      userId,
    });

    if (response.status !== 200) {
      throw new Error("enableUser - Failed");
    }

    return response;
  } catch (error) {
    logger.error(error, "enableUser");
    throw error;
  }
};

const downloadSubtitles = async (
  rooms: {
    id?: string;
    name?: string;
  }[],
  subtitleFormat: string,
  options: {
    split: boolean;
    embed: boolean;
    flip: boolean;
  },
  mailInfo?: { sendToMail: boolean; email: string }
): Promise<void> => {
  const response = await triggerCloudFunction(json2subtitle, {
    rooms,
    subtitleFormat,
    split: options.split,
    embed: options.embed,
    flip: options.flip,
    mailInfo,
  });

  if (response.status !== 200) {
    throw new Error("json2subtitle - Failed to upload meeting");
  }
  const jobsToExport = response.data.rooms;
  if (!mailInfo?.sendToMail && jobsToExport) {
    for (const room of jobsToExport) {
      if (room.id) {
        const subtitlesUrl = await FirebaseService.getSubtitlesUrl(
          room.id,
          subtitleFormat
        );
        const subtitlesFilename = `${
          room.name || "subtitles"
        }.${subtitleFormat}`;

        await FirebaseService.downloadFile(subtitlesUrl, subtitlesFilename);
      }
    }
  }
};

const downloadJobsSubtitles = async (
  jobs: {
    id?: string;
    name?: string;
  }[],
  format: string,
  options: {
    autoBreak: boolean;
    embed: boolean;
    flip: boolean;
    frameRate: number;
  },
  mailInfo?: { sendToMail: boolean; email: string }
): Promise<void> => {
  const response = await triggerCloudFunction(createJobsSubtitles, {
    jobs,
    format,
    autoBreak: options.autoBreak,
    embed: options.embed,
    flip: options.flip,
    frameRate: options.frameRate,
    mailInfo,
  });

  if (response.status !== 200) {
    throw new Error("downloadJobsSubtitles - Failed to create job subtitles");
  }
  const jobsToExport = response.data.jobs;

  if (!mailInfo?.sendToMail) {
    for (const job of jobsToExport) {
      if (job.id) {
        const subtitlesUrl = await FirebaseService.getSubtitlesUrl(
          job.id,
          format
        );
        const subtitlesFilename = `${job.name || "subtitles"}.${format}`;

        await FirebaseService.downloadFile(subtitlesUrl, subtitlesFilename);
      }
    }
  }
};

const createJobWords = async (roomId: string): Promise<void> => {
  try {
    await triggerCloudFunction(createJobWordsEndpoint, {
      roomId,
    });
    return;
  } catch (err) {
    logger.error(err, "createJobWords");
    return;
  }
};

const getRoomPrice = async (
  job: Pick<
    NewMeetingData,
    "clientId" | "jobType" | "lang" | "translation" | "meetingLength"
  >
): Promise<any> => {
  try {
    const response = await triggerCloudFunction(getRoomPriceEndpoint, job);
    if (response.status !== 200) {
      throw "get room price failed";
    }
    return response.data;
  } catch (error) {
    console.log(error, "get room price failed");
    return { prooferPrice: 0, transcriberPrice: 0 };
  }
};

const wordsSnapshot = async (snapshotOptions: {
  roomId: string;
  suffix?: string;
  prefix?: string;
}): Promise<any> => {
  try {
    const response = await triggerCloudFunction(
      wordsSnapshotEndpoint,
      snapshotOptions
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const jobSnapshot = async (snapshotOptions: {
  jobId: string;
  filesToCopy?: string[];
  suffix?: string;
  prefix?: string;
}): Promise<any> => {
  try {
    const response = await triggerCloudFunction(
      jobSnapshotEndpoint,
      snapshotOptions
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const editProgress = async (roomId: string): Promise<any> => {
  try {
    const response = await triggerCloudFunction(editProgressEndpoint, {
      roomId,
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
  }
};

const rerunStt = async (roomId: string, lang: string): Promise<any> => {
  try {
    const response = await triggerCloudFunction(rerunSttEndpoint, {
      roomId,
      lang,
    });
    return response.data;
  } catch (err) {
    logger.error(err, "rerunStt");
  }
};

const addBonusToJobDebt = async (
  debtId: string,
  userId: string,
  bonus: number
): Promise<any> => {
  try {
    const response = await triggerCloudFunction(addBonusToJobDebtEndpoint, {
      debtId,
      userId,
      bonus,
    });
    return response.data;
  } catch (err) {
    logger.error(err, "addBonusToJobDebt");
  }
};

const wordsReset = async (roomId: string): Promise<any> => {
  try {
    const response = await triggerCloudFunction(wordsResetEndpoint, { roomId });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getMyJobs = async (userId: string): Promise<any> => {
  try {
    const response = await triggerCloudFunction(getMyJobsEndpoint, { userId });
    if (response.status !== 200) {
      throw new Error("get job failed");
    }
    return response.data;
  } catch (error) {
    logger.error(error, "getMyJobs");
    return false;
  }
};

const fixTimestamps = async (roomId: string) => {
  const response = await triggerCloudFunction(fixTimestampsEndpoint, {
    roomId,
  });

  if (response.status !== 200) {
    throw new Error("fixTimestampsEndpoint Failed");
  }

  return response;
};

const alignSubtitles = async (
  roomId: string,
  validation?: RangeValidationConfig
) => {
  const response = await triggerCloudFunction(alignSubtitlesEndpoint, {
    roomId,
    validation,
  });

  TrackingService.reportEvent("aligner_request", { job_id: roomId });

  return response;
};

const doneTextEnhancement = async (id: string) => {
  try {
    const response = await triggerCloudFunction(doneTextEnhancementEndpoint, {
      id,
    });

    if (!response.data.success) {
      throw new Error(response.data.code || "doneTextEnhancement Failed");
    }

    return response.data;
  } catch (err: any) {
    logger.error(err, "doneTextEnhancement");
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error(err.response?.data);
  }
};

const splitJob = async (roomId: string, splitCount: number | null) => {
  const splitOptions: any = {
    roomId,
  };
  if (splitCount) {
    splitOptions.split_count = splitCount;
  }
  const response = await triggerCloudFunction(jobSplitEndpoint, splitOptions);
  await triggerCloudFunction(copyNotesFromParentEndpoint, {
    sourceJobId: roomId,
  });

  TrackingService.reportEvent("split_request", { job_id: roomId });

  return response;
};

const getMergedSplitJob = async (parentId: string) => {
  const response = await triggerCloudFunction(getMergedSplitJobEndpoint, {
    parentId,
  });

  return response.data;
};

const restoreJob = async (jobId: string, filename: string) => {
  const response = await triggerCloudFunction(restoreJobEndpoint, {
    jobId,
    filename,
  });

  return response;
};

const addDebts = async (debts: Debt1[]) => {
  const response = await triggerCloudFunction(addDebtsEndpoint, debts);

  if (response.status !== 200) {
    throw new Error("addDebts Failed");
  }

  return response;
};

const completeJobDebts = async (jobId: string) => {
  const response = await triggerCloudFunction(completeJobDebtsEndpoint, {
    jobId,
  });

  if (response.status !== 200) {
    throw new Error("completeJobDebts Failed");
  }

  return response;
};

const getDebtsByUser = async () => {
  const response = await triggerCloudFunction(getDebtsByUserEndpoint);

  if (response.status !== 200) {
    throw new Error("getDebtsByUser Failed");
  }

  return response.data;
};

const mergeJobs = async (id: string) => {
  const response = await triggerCloudFunction(mergeJobsEndpoint, {
    id,
  });

  if (response.status !== 200) {
    throw new Error("mergeJobs Failed");
  }

  return response.data.parentId;
};

const isExistingUser = async ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  try {
    const response = await axios.post(isExistingUserEndpoint, { key, value });

    if (!response.data.success) {
      throw new Error(response.data.code || "isExistingUser Failed");
    }

    return response.data.isExistingUser;
  } catch (err: any) {
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error("isExistingUser Failed");
  }
};

const getMfaStatusByPhone = async (phoneNumber: string) => {
  try {
    const response = await axios.post(getMfaStatusByPhoneEndpoint, {
      phoneNumber: phoneNumber,
    });
    return response.data.mfaStatus;
  } catch (err: any) {
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error("getMfaStatusByPhone Failed");
  }
};

const approveProofing = async (jobId: string, userId: string) => {
  try {
    const response = await axios.post(approveProofingEndpoint, {
      jobId,
      userId,
    });
    if (!response.data.success) {
      throw new Error(response.data.code || "approveProofing Failed");
    }
  } catch (err: any) {
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error("approveProofing Failed");
  }
};

const approveMultiFactor = async (phonenumber: string, uid: string) => {
  try {
    const response = await axios.post(approveMultiFactorEndpoint, {
      phonenumber: phonenumber,
      uid,
    });

    return response.data.customToken;
  } catch (err: any) {
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error("approveMultiFactor Failed");
  }
};

const createUser = async (userData: any) => {
  try {
    const response = await axios.post(createUserEndpoint, userData);

    if (!response.data.success) {
      throw new Error(response.data.code || "createUser Failed");
    }

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error(err.response?.data);
  }
};

const completeProviderSignup = async (userData: any) => {
  try {
    const response = await axios.post(completeProviderSignupEndpoint, userData);

    if (!response.data.success) {
      throw new Error(response.data.code || "completeProviderSignup Failed");
    }

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error("createUser Failed");
  }
};

const createSubtitlesTranslationJob = async ({
  jobId,
  langs,
  jobData,
  isNewJob,
}: {
  jobId: string;
  langs?: string[];
  jobData?: NewMeetingData;
  isNewJob?: boolean;
}) => {
  try {
    const response = await axios.post(createSubtitlesTranslationJobEndpoint, {
      id: jobId,
      jobData,
      langs,
      isNewJob,
    });

    if (!response.data.success) {
      throw new Error(
        response.data.code || "createSubtitlesTranslation Failed"
      );
    }

    return response.data;
  } catch (err: any) {
    logger.error(err, "createSubtitlesTranslation");
  }
};

const resetJob = async (jobId: string, userId: string) => {
  const response = await triggerCloudFunction(resetJobEndpoint, {
    jobId,
    userId,
  });

  return response;
};

const importFile = async (
  id: string,
  type: string,
  outputFormats?: string[]
) => {
  try {
    const response = await triggerCloudFunction(importFileEndpoint, {
      id,
      type,
      outputFormats,
    });

    if (!response.data.success) {
      throw new Error(response.data.code || "importFile Failed");
    }

    return response.data;
  } catch (err: any) {
    logger.error(err, "importFile");
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error(err.response?.data);
  }
};

const startLiveInterview = async (jobId: string) => {
  try {
    const response = await triggerCloudFunction(startLiveInterviewEndpoint, {
      jobId,
    });

    if (!response.data.success) {
      throw new Error(response.data.code || "startLiveInterview Failed");
    }

    return response.data;
  } catch (err: any) {
    logger.error(err, "startLiveInterview");
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error(err.response?.data);
  }
};

const endLiveInterview = async (jobId: string) => {
  try {
    const response = await triggerCloudFunction(endLiveInterviewEndpoint, {
      jobId,
    });

    if (!response.data.success) {
      throw new Error(response.data.code || "endLiveInterview Failed");
    }

    return response.data;
  } catch (err: any) {
    logger.error(err, "endLiveInterview");
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error(err.response?.data);
  }
};

const liveInterviewStatus = async (jobId?: string) => {
  try {
    const response = await triggerCloudFunction(liveInterviewStatusEndpoint, {
      jobId,
    });

    if (!response.data.success) {
      throw new Error(response.data.code || "liveInterviewStatus Failed");
    }

    return response.data;
  } catch (err: any) {
    logger.error(err, "liveInterviewStatus");
    if (err.response?.data?.code) {
      throw new Error(err.response.data.code);
    }
    throw new Error(err.response?.data);
  }
};

const getLocators = async (jobId?: string) => {
  try {
    const response = await axios.post(
      config.appEngine.url + config.appEngine.exportMarkers,
      {
        jobId,
        offsets: 3600,
      }
    );
    if (!response.data.success) {
      throw new Error(response.data.code || "exportMarkers Failed");
    }
    return response.data?.msg?.content;
  } catch (err: any) {
    throw new Error(err);
  }
};

export default {
  deliverToProof,
  createJobWords,
  approveProofing,
  createSubtitlesTranslationJob,
  mergeJobs,
  doneTextEnhancement,
  disableUser,
  enableUser,
  wordsReset,
  wordsSnapshot,
  jobSnapshot,
  editProgress,
  addBonusToJobDebt,
  getMyJobs,
  downloadSubtitles,
  downloadJobsSubtitles,
  rerunStt,
  fixTimestamps,
  getRoomPrice,
  alignSubtitles,
  splitJob,
  importFile,
  getMergedSplitJob,
  restoreJob,
  resetJob,
  getLocators,
  debts: {
    addDebts,
    completeJobDebts,
    getDebtsByUser,
  },
  auth: {
    isExistingUser,
    createUser,
    approveMultiFactor,
    completeProviderSignup,
    getMfaStatusByPhone,
  },
  stream: {
    startLiveInterview,
    endLiveInterview,
    liveInterviewStatus,
  },
};
