export const archiveExcelFields = {
  id: "Job ID",
  name: "Job name",
  clientName: "Client name",
  previewFormat: "Job type",
  input: "Source language",
  output: "Target language",
  assignedTranscriber: "Transcriber",
  price: "Transcriber cost",
  assignedProofer: "Reviewer",
  prooferPrice: "Reviewer cost",
  meetingLength: "File length",
  creationTime: "Uploaded date",
  representative: "Representative name",
  representativePrice: "Representative cost",
  archivedAt: "Archived date",
  invoice: "Invoice status",
};
export const JobsPageExcelFields = {
  id: "Job ID",
  name: "Job name",
  clientName: "Client name",
  previewFormat: "Job type",
  input: "Source language",
  output: "Target language",
  assignedTranscriber: "Transcriber",
  price: "Transcriber cost",
  assignedProofer: "Reviewer",
  prooferPrice: "Reviewer cost",
  jobStatus: "Job Status",
  meetingLength: "File length",
  creationTime: "Uploaded date",
  deadline: "Deadline",
  processProgress: "Progress",
  transcribeTextEditTime: "Transcribe text editing time before sync",
  transcribeTimeSync: "Transcribe text editing time after sync",
  reviewTextEditTime: "review text editing time before sync",
  reviewTimeSync: "review text editing time after sync",
};

export const defaultExcelFields = {
  approve_transcribing_date: "approve_transcribing_date",
  approve_transcribing_time: "approve_transcribing_time",
  approve_transcribing_type: "approve_transcribing_type",
  approve_transcribing_username: "approve_transcribing_username",
  assigned_reviewer_date: "assigned_reviewer_date",
  assigned_reviewer_time: "assigned_reviewer_time",
  assigned_reviewer_type: "assigned_reviewer_type",
  assigned_reviewer_username: "assigned_reviewer_username",
  assigned_to_transcriber_date: "assigned_to_transcriber_date",
  assigned_to_transcriber_time: "assigned_to_transcriber_time",
  assigned_to_transcriber_type: "assigned_to_transcriber_type",
  assigned_to_transcriber_username: "assigned_to_transcriber_username",
  at_work_date: "at_work_date",
  at_work_time: "at_work_time",
  at_work_type: "at_work_type",
  at_work_username: "at_work_username",
  done_date: "done_date",
  done_time: "done_time",
  done_type: "done_type",
  done_username: "done_username",
  open_date: "open_date",
  open_time: "open_time",
  open_type: "open_type",
  open_username: "open_username",
  pending_date: "pending_date",
  pending_time: "pending_time",
  pending_type: "pending_type",
  pending_username: "pending_username",
  reviewing_date: "reviewing_date",
  reviewing_time: "reviewing_time",
  reviewing_type: "reviewing_type",
  reviewing_username: "reviewing_username",
  sent_to_archive_date: "sent_to_archive_date",
  sent_to_archive_time: "sent_to_archive_time",
  sent_to_archive_type: "sent_to_archive_type",
  sent_to_archive_username: "sent_to_archive_username",
  sent_to_review_date: "sent_to_review_date",
  sent_to_review_time: "sent_to_review_time",
  sent_to_review_type: "sent_to_review_type",
  sent_to_review_username: "sent_to_review_username",
  transcriber_claimed_job_date: "transcriber_claimed_job_date",
  transcriber_claimed_job_time: "transcriber_claimed_job_time",
  transcriber_claimed_job_type: "transcriber_claimed_job_type",
  transcriber_claimed_job_username: "transcriber_claimed_job_username",
  transcriber_requested_job_date: "transcriber_requested_job_date",
  transcriber_requested_job_time: "transcriber_requested_job_time",
  transcriber_requested_job_type: "transcriber_requested_job_type",
  transcriber_requested_job_username: "transcriber_requested_job_username",
  un_assigned_reviewer_date: "un_assigned_reviewer_date",
  un_assigned_reviewer_time: "un_assigned_reviewer_time",
  un_assigned_reviewer_type: "un_assigned_reviewer_type",
  un_assigned_reviewer_username: "un_assigned_reviewer_username",
  unassign_transcriber_date: "unassign_transcriber_date",
  unassign_transcriber_time: "unassign_transcriber_time",
  unassign_transcriber_type: "unassign_transcriber_type",
  unassign_transcriber_username: "unassign_transcriber_username",
  uploaded_date: "uploaded_date",
  uploaded_time: "uploaded_time",
  uploaded_type: "uploaded_type",
  uploaded_username: "uploaded_username",
};
