import { addDays, differenceInDays, format } from "date-fns";
import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { getLanguages } from "../../utils/locales";

import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setMinimalClients } from "../../store/client/actions";
import { AppState } from "../../store/rootReducer";
import { popIndicator } from "../../store/system/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dropdown from "../common/Dropdown/Dropdown";
import FileInput from "../FileInput/FileInput";
import SelectInput from "../FormInputs/SelectInput";

import FirebaseService from "../../services/FirebaseService";
import CloudFunctionsService from "../../services/CloudFunctionsService";

import { NewMeetingData } from "../../models/meeting";
import { RequiredFieldsState } from "../../models/job";
import { processStatus, PreviewFormat } from "../../models";
import { SlicesData } from "../../models/job";
import { localeCodes } from "../../models/localeCodes";

import { previewFormats } from "../../constants/previewFormats";
import Logger from "../../services/Logger";

import "./NewMeetingForm.scss";

const logger = Logger("NewMeetingForm");

interface Props {
  updateMeeting: (meeting: NewMeetingData) => void;
  meeting: NewMeetingData;
  id: string;
  jobIndex: number;
  openMembersSampling: () => void;
  onDeleteJob: () => void;
  onSubmit: () => void;
  updateRequiredFieldsState: (requiredFieldsState: RequiredFieldsState) => void;
  requiredFieldsState: RequiredFieldsState;
  processingState: processStatus;
  disabledProps?: { submitBtn: boolean; allForm: boolean };
  clientSpeakers?: any[];
  handleClientChange: (clientId: string) => void;
  slices: SlicesData[];
  updateSlices: (Slices: SlicesData[], jobIndex?: number) => void;
}

const AddContactForm: FC<Props> = ({
  updateMeeting,
  meeting,
  id,
  jobIndex,
  openMembersSampling,
  onDeleteJob,
  onSubmit,
  updateRequiredFieldsState,
  requiredFieldsState,
  processingState,
  disabledProps,
  clientSpeakers,
  handleClientChange,
  slices,
  updateSlices,
}): JSX.Element => {
  const minimalClientList = useSelector(
    (state: AppState) => state.clientStore.minimalClientList
  );
  const { t } = useTranslation();
  const [languages] = useState(getLanguages(t));
  const dispatch = useDispatch();

  const [disabledState, setDisabledState] = useState<{
    submitBtn: boolean;
    allForm: boolean;
  }>({ submitBtn: true, allForm: false });
  const [isLoading, setIsLoading] = useState(false);
  const [clientSelectValues, setClientSelectValues] = useState<
    { text: string; identifier: string }[]
  >([]);
  const [isClientsPending, setIsClientsPending] = useState<boolean>(false);
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [clientDeadline, setClientDeadline] = useState<Date>(new Date());
  const [membersCount, setMembersCount] = useState<number>(1);
  const [priceInput, setPriceInput] = useState<number>(meeting.price);
  const [prooferPriceInput, setProoferPriceInput] = useState<number>(
    meeting.prooferPrice
  );
  const [revenue, setRevenue] = useState<number>(meeting.revenue);
  const [allowFileImport, setAllowFileImport] = useState(false);

  useEffect(() => {
    getClientsList();
  }, []);

  useEffect(() => {
    if (minimalClientList.length > 0) {
      const clientValues = minimalClientList.map((client) => {
        return { text: client.name, identifier: client.id };
      });
      setClientSelectValues(clientValues);
    }
  }, [minimalClientList]);

  useEffect(() => {
    if (disabledProps) {
      setDisabledState(disabledProps);
      const nameInput = document.getElementById(
        id + "nameinput"
      ) as HTMLInputElement;
      const submitBtn = document.getElementById(
        id + "submit"
      ) as HTMLButtonElement;
      if (nameInput && submitBtn) {
        if (disabledProps.allForm) {
          nameInput.style.background = "#ebebeb";
          submitBtn.disabled = true;
          submitBtn.classList.add("disabled");
        } else {
          nameInput.style.background = "white";
          submitBtn.disabled = false;
          submitBtn.classList.remove("disabled");
        }
      }
    }
  }, [disabledProps]);

  useEffect(() => {
    if (!meeting.ownerId) return;
    handleClientChange(meeting.ownerId.id);
  }, [meeting.ownerId]);

  const getClientsList = async () => {
    if (minimalClientList.length === 0) {
      setIsClientsPending(true);
      const clients = await FirebaseService.getMinimalClients();
      const sortedClients = _.sortBy(clients, "name");
      dispatch(setMinimalClients(sortedClients));
      setIsClientsPending(false);
    }
  };

  const onFilesUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = e.target.files;
      if (files) {
        dispatch(
          popIndicator({ type: "info", txt: t("indicator_importing_subtitle") })
        );
        setIsLoading(true);
        const fileType = await FirebaseService.uploadFile(files[0], id);
        if (fileType) {
          await CloudFunctionsService.importFile(id, fileType, [
            "ready",
            "ranges",
          ]);
          updateRequiredFieldsState(
            _.update(requiredFieldsState, "file.selected", () => true)
          );
          dispatch(
            popIndicator({
              type: "success",
              txt: t("indicator_importing_subtitle_success"),
            })
          );
        }
      }
    } catch (err) {
      updateRequiredFieldsState(
        _.update(requiredFieldsState, "file.selected", () => false)
      );
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("indicator_importing_subtitle_fail"),
        })
      );
      logger.error(err, "onFilesUpload");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !requiredFieldsState.ownerId.selected ||
      (["sync-subtitles", "subtitles-translation"].includes(meeting.jobType) &&
        !requiredFieldsState.file.selected)
    ) {
      const requiredClone = { ...requiredFieldsState };
      requiredClone.ownerId.userIndicator = true;
      updateRequiredFieldsState(requiredClone);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_missing_fields") })
      );
      setTimeout(() => {
        requiredClone.ownerId.userIndicator = false;
        requiredClone.file.userIndicator = false;
        updateRequiredFieldsState(requiredClone);
      }, 1500);
      setIsLoading(false);
      return;
    } else {
      if (meeting.algorithm === "unsupervised") {
        const meetingClone = { ...meeting };
        meetingClone.sum_of_members = membersCount;
        updateMeeting(meetingClone);
      }
      onSubmit();
      setIsLoading(false);
    }
  };

  const handleInput = (field: string, value: string) => {
    if (disabledState?.allForm) return;
    const meetingClone = meeting;
    if (field === "name") meetingClone.name = value;
    else if (field === "ownerId") {
      meetingClone.ownerId = FirebaseService.getDocRef(
        FirebaseService.collections.clients,
        value
      );
      meetingClone.clientId = value;
      const requiredClone = { ...requiredFieldsState };
      requiredClone.ownerId.selected = true;
      updateRequiredFieldsState(requiredClone);
    } else if (field === "input-lang") {
      meetingClone.lang.input = [value];
      meetingClone.lang.output = [value];
    } else if (field === "output-lang") {
      const newOutputValue = _.isArray(value) ? value : [value];
      meetingClone.lang.output = newOutputValue;
    } else if (field === "algorithm") meetingClone.algorithm = value;
    else if (field === "representative") meetingClone.representative = value;
    updateMeeting(meetingClone);
  };

  const updateMeetingFormat = async (format: PreviewFormat, lang?: string) => {
    if (["subtitles-translation", "sync-subtitles"].includes(format)) {
      setAllowFileImport(true);
    } else {
      setAllowFileImport(false);
      updateRequiredFieldsState(
        _.update(requiredFieldsState, "file.selected", () => false)
      );
    }
    if (disabledState?.allForm) return;
    const meetingClone = { ...meeting };
    meetingClone.jobType = format;
    meetingClone.lang.output = lang ? [lang] : [meetingClone.lang.output[0]];
    try {
      dispatch(
        popIndicator({ type: "info", txt: t("indicator_calculate_price") })
      );
      const {
        transcriberPrice,
        prooferPrice,
      } = await CloudFunctionsService.getRoomPrice({
        jobType: meetingClone.jobType,
        clientId: meetingClone.clientId,
        lang: meetingClone.lang,
        translation: meetingClone.translation,
        meetingLength: meetingClone.meetingLength,
      });
      dispatch(
        popIndicator({
          type: "success",
          txt: t("indicator_calculate_price_success"),
        })
      );
      meetingClone.price = transcriberPrice;
      meetingClone.prooferPrice = prooferPrice;
      setPriceInput(transcriberPrice);
      setProoferPriceInput(prooferPrice);
      const requiredClone = { ...requiredFieldsState };
      updateRequiredFieldsState(requiredClone);
      updateMeeting(meetingClone);
    } catch (error) {
      console.log(error);
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("indicator_something_went_wrong"),
        })
      );
    }
  };

  const getDeadlineByClientDeadline = (_clientDeadline: Date): Date => {
    const _today = new Date();
    const gapInDays = differenceInDays(_clientDeadline, _today);
    return addDays(_today, Math.max(gapInDays * 0.66, 0));
  };

  useEffect(() => {
    const meetingClone = { ...meeting };
    meetingClone.deadline = deadline;
    updateMeeting(meetingClone);
  }, [deadline]);

  useEffect(() => {
    const meetingClone = { ...meeting };
    meetingClone.clientDeadline = clientDeadline;
    updateMeeting(meetingClone);
    const deadline = getDeadlineByClientDeadline(clientDeadline);
    setDeadline(deadline);
  }, [clientDeadline]);

  const transcriberPriceInputHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = Number(event.target.value) || 0;
    const meetingClone = { ...meeting };
    if (value > 9999) {
      value = 9999;
    }
    meetingClone.price = value;
    setPriceInput(value);
    updateMeeting(meetingClone);
  };
  const prooferPriceInputHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = Number(event.target.value) || 0;
    const meetingClone = { ...meeting };
    if (value > 9999) {
      value = 9999;
    }
    meetingClone.prooferPrice = value;
    setProoferPriceInput(value);
    updateMeeting(meetingClone);
  };

  const revenueInputHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = Number(event.target.value) || 0;
    const meetingClone = { ...meeting };
    if (value > 9999) {
      value = 9999;
    }
    meetingClone.revenue = value;
    setRevenue(value);
    updateMeeting(meetingClone);
  };

  return (
    <form
      id={id + "entireform"}
      className={`new-meeting-form ${disabledState?.allForm && "disabled"}`}
      onSubmit={handleSubmit}
    >
      <div className="meetingName fieldContainer">
        <div className="fieldName">{t("name")}</div>
        <input
          id={id + "nameinput"}
          onChange={(e) => handleInput("name", e.target.value)}
          className="name field"
          value={meeting.name}
          type="text"
          required={true}
          disabled={disabledState?.allForm}
        />
      </div>
      <div className="meetingClient fieldContainer">
        <div className="fieldName">{t("client")}</div>
        <Dropdown
          options={clientSelectValues.map((c) => ({
            label: c.text,
            value: c.identifier,
          }))}
          className={classNames("newMeeting fullWitdh")}
          onChange={(option) => handleInput("ownerId", option as string)}
          value={
            meeting.clientId || !_.isEmpty(clientSelectValues)
              ? clientSelectValues[0].identifier
              : undefined
          }
          multiSelect={false}
          placeholder={_.get(meeting, "client")}
          isLoading={isClientsPending}
          isDisabled={disabledState?.allForm}
        ></Dropdown>
      </div>
      <div className="meetingLang fieldContainer">
        <div className="fieldName">{t("language")}</div>
        <SelectInput
          onInput={(code) => handleInput("input-lang", code)}
          align={"center"}
          values={localeCodes.map((l) => ({ text: l, identifier: l }))}
          maxDropdownHeight={180}
          disabled={disabledState?.allForm}
        />
      </div>
      <div className="meetingSpeakers fieldContainer">
        <div className="fieldName">{t("members")}</div>
        <div className="fieldContent">
          <SelectInput
            onInput={(algorithm) => handleInput("algorithm", algorithm)}
            align={"center"}
            values={[
              { text: t("auto"), identifier: "unsupervised" },
              { text: t("manual"), identifier: "supervised" },
              {
                text: t("speaker_bank"),
                identifier: "bank",
                disabled: !meeting.ownerId,
              },
              { text: t("none"), identifier: "none" },
            ]}
            maxDropdownHeight={200}
            disabled={disabledState?.allForm}
          />
          {meeting.algorithm === "bank" ? (
            <div
              onClick={openMembersSampling}
              className={`members-btn algo-${meeting.algorithm}`}
            >
              <p>{t("sample")}</p>
            </div>
          ) : (
            <div
              onClick={() => {
                if (meeting.algorithm === "supervised") openMembersSampling();
              }}
              className={`members-btn algo-${meeting.algorithm}`}
            >
              {meeting.algorithm === "supervised" && <p>{t("sample")}</p>}
              {meeting.algorithm === "unsupervised" && <p>{membersCount}</p>}
              {meeting.algorithm === "supervised" && (
                <FontAwesomeIcon icon={["fal", "users"]} />
              )}
              {meeting.algorithm === "unsupervised" && (
                <div className="members-count-controls">
                  <FontAwesomeIcon
                    onClick={() =>
                      !disabledState?.allForm &&
                      setMembersCount(membersCount + 1)
                    }
                    icon={["fal", "chevron-up"]}
                  />
                  <FontAwesomeIcon
                    onClick={() =>
                      !disabledState?.allForm &&
                      membersCount > 1 &&
                      setMembersCount(membersCount - 1)
                    }
                    icon={["fal", "chevron-down"]}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {meeting.algorithm === "bank" ? (
          <div className="speakersDropdown">
            <Select
              isMulti={true}
              options={clientSpeakers?.map((s) => ({
                label: s.name,
                value: s.name,
                clientId: s.clientId,
                speakerId: s.speakerId,
              }))}
              onChange={(speakers, optionChanged) => {
                if (optionChanged.action.includes("remove")) {
                  const slicesClone = slices.filter(
                    //@ts-ignore
                    (s) => optionChanged.removedValue.value !== s.name
                  );
                  updateSlices(slicesClone, jobIndex);
                } else if (optionChanged.action === "clear") {
                  updateSlices([], jobIndex);
                } else {
                  if (
                    //@ts-ignore
                    _.some(slices, (s) => s.name === optionChanged.option.value)
                  )
                    return;
                  updateSlices(
                    [
                      ...slices,
                      {
                        //@ts-ignore
                        name: optionChanged.option.value,
                        slices: [[-1, -1]],
                        //@ts-ignore
                        speakerId: optionChanged.option.speakerId,
                      },
                    ],
                    jobIndex
                  );
                }
              }}
              value={slices.map(({ name }) => ({ value: name, label: name }))}
              placeholder="בחר דוברים"
              classNamePrefix="react-select"
            ></Select>
          </div>
        ) : null}
      </div>
      <div className="meetingRepresentative fieldContainer">
        <div className="fieldName">{t("representative")}</div>
        <input
          id={id + "nameinput"}
          onChange={(e) => handleInput("representative", e.target.value)}
          placeholder={t("enter_representative")}
          className="name field"
          value={meeting.representative}
          type="text"
          disabled={disabledState?.allForm}
        />
      </div>
      <div className="fieldName">{t("revenue")}</div>
      <div className="price field">
        <div className="currency-symbol"></div>
        <span className="currency-symbol">
          <input
            id={id + "nameinput"}
            onChange={revenueInputHandler}
            className="price-input"
            value={revenue}
            type="number"
            required={true}
            min="0"
            disabled={disabledState?.allForm}
          />
          {t("currency")}
        </span>
      </div>
      <div className="meetingFormat fieldContainer">
        <div className="fieldName">{t("format")}</div>
        <>
          <Dropdown
            options={previewFormats}
            className={classNames("newMeeting")}
            onChange={(option) => updateMeetingFormat(option as PreviewFormat)}
            value={_.get(meeting, "jobType")}
            multiSelect={false}
            placeholder={_.get(meeting, "jobType")}
          ></Dropdown>
          <Dropdown
            options={languages}
            className={classNames("newMeeting")}
            onChange={(option) => handleInput("output-lang", option as string)}
            value={_.get(meeting, "lang.output")}
            multiSelect={["subtitles", "subtitles-translation"].includes(
              meeting.jobType
            )}
            placeholder={_.get(meeting, "lang.output")}
          ></Dropdown>
          {allowFileImport && (
            <FileInput
              disabled={disabledState?.allForm || isLoading}
              title="upload_file"
              onFilesUpload={onFilesUpload}
              validFormats={["srt"]}
              multiple={false}
            ></FileInput>
          )}
        </>
      </div>
      <div className="fieldContainerDouble">
        <div className="fieldContainer">
          <label>
            {t("client_deadline")}
            <input
              min={format(new Date(), "yyyy-MM-dd")}
              type={"date"}
              value={format(clientDeadline, "yyyy-MM-dd")}
              onChange={(e) => setClientDeadline(new Date(e.target.value))}
            />
          </label>
        </div>
        <div className="fieldContainer">
          <label>
            {t("deadline")}
            <input
              min={format(new Date(), "yyyy-MM-dd")}
              type={"date"}
              value={format(deadline, "yyyy-MM-dd")}
              onChange={(e) => setDeadline(new Date(e.target.value))}
            />
          </label>
        </div>
        <div className="meetingPrice fieldContainer">
          <div className="fieldName">{t("price")}</div>
          <div className="price field">
            <span className="currency-symbol">
              <input
                className="price-input"
                min="0"
                onChange={transcriberPriceInputHandler}
                value={priceInput}
              />
              {t("currency")}
            </span>
          </div>
          <div className="price field">
            <span className="currency-symbol">
              <input
                className="price-input"
                min="0"
                onChange={prooferPriceInputHandler}
                value={prooferPriceInput}
              />
              {t("currency")}
            </span>
          </div>
        </div>
      </div>
      <div className="actions">
        <div
          onClick={() => !disabledState?.allForm && onDeleteJob()}
          className="delete-btn"
        >
          <p>{t("delete")}</p>
        </div>
        <button
          id={id + "submit"}
          className={classNames("submit", {
            disabled: disabledState.submitBtn || isLoading,
          })}
          disabled={disabledState.submitBtn || isLoading}
          type="submit"
        >
          {processingState === processStatus.none && !isLoading && t("send")}
          {(processingState === processStatus.inProgress || isLoading) && (
            <div className="loading-spinner">
              <CircularProgress style={{ width: "20px", color: "white" }} />
            </div>
          )}

          {processingState === processStatus.done && (
            <div className="loading-spinner">
              <FontAwesomeIcon icon={["fal", "check"]} />
            </div>
          )}
          {processingState === processStatus.error && (
            <div className="loading-spinner">
              <div>X</div>
            </div>
          )}
        </button>
      </div>
    </form>
  );
};

export default AddContactForm;
