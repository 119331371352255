import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";

import "./Button.scss";

interface Props {
  label: string;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit";
  disabled?: boolean;
  loading?: boolean;
  timer?: number;
}

const Button: FC<Props> = ({
  label,
  className,
  onClick,
  type = "button",
  disabled = false,
  loading = false,
  timer,
}): JSX.Element => {
  const [countdown, setCountdown] = useState(0);

  const runTimer = () => {
    if (timer && timer > 0) {
      setCountdown(timer);
      let count = timer;
      const interval = setInterval(() => {
        count--;
        setCountdown(count);
        if (count === 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  };

  useEffect(runTimer, [timer]);

  return (
    <div
      className={classNames("ButtonComponent", className, {
        disabled,
        loading,
      })}
    >
      {onClick ? (
        <button
          type={"button"}
          className="button"
          disabled={disabled || countdown > 0}
          onClick={onClick}
        >
          {label}
          {!countdown && (
            <div className={classNames("loading", { hidden: !loading })}>
              <CircularProgress size={20} />
            </div>
          )}
          <div className={classNames("countdown", { hidden: countdown < 1 })}>
            {countdown}
          </div>
        </button>
      ) : (
        <button type={type} className="button" disabled={disabled}>
          {label}
          {!countdown && (
            <div className={classNames("loading", { hidden: !loading })}>
              <CircularProgress size={20} />
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default Button;
