import React from "react";
import { PreviewItem } from "../Preview";

export interface FooterProps {
  logo: PreviewItem;
  pageNumbering: PreviewItem;
  footerText: string;
}

export const Footer = ({ logo, pageNumbering, footerText }: FooterProps) => {
  return (
    <div className="pageFooter">
      {logo.show && logo.content && (
        <div
          className="logo"
          style={{
            justifyContent: logo.align,
          }}
        >
          <p>{footerText}</p>
          {typeof logo.content === "string" && (
            <img style={logo.cssProps} src={logo.content}></img>
          )}
        </div>
      )}
      {pageNumbering.content && pageNumbering.show && (
        <div
          className="paragraph"
          style={{
            justifyContent: pageNumbering.align,
          }}
        >
          <div>{pageNumbering.content}</div>
        </div>
      )}
    </div>
  );
};
