export const currency = [
  {
    label: "usd",
    value: "usd",
    icon: "",
  },
  {
    label: "ils",
    value: "ils",
    icon: "",
  },
];
