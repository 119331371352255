import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarEditor from "react-avatar-editor";

import "./ImageEditor.scss";

interface Props {
  image: string | File;
  saveImage: (imageBlob: Blob) => void;
  close: () => void;
  isDisabled: boolean;
}

const ImageEditor: FC<Props> = ({
  image,
  saveImage,
  close,
  isDisabled,
}): JSX.Element => {
  const { t } = useTranslation();

  const [editor, setEditor] = useState<AvatarEditor>();
  const [imgScale, setImgScale] = useState<number>(125);

  const onClickSave = () => {
    if (editor) {
      const canvasScaled: HTMLCanvasElement = editor.getImageScaledToCanvas();
      canvasScaled.toBlob((blob) => {
        if (blob) saveImage(blob);
      });
    }
  };

  const setEditorRef = (editorRef: AvatarEditor) => setEditor(editorRef);

  return (
    <div className="img-editor">
      <AvatarEditor
        ref={setEditorRef}
        image={image}
        width={250}
        height={250}
        borderRadius={999}
        border={50}
        color={[255, 255, 255, 0.6]}
        scale={imgScale / 100}
        rotate={0}
      />
      <input
        className="slider"
        type="range"
        min="100"
        max="350"
        disabled={isDisabled}
        value={imgScale}
        onChange={(e) => setImgScale(parseInt(e.target.value))}
      />
      <div className="btns">
        <button
          disabled={isDisabled}
          className="meeting-preview-btn neutral-hollow"
          onClick={close}
        >
          {t("cancel")}
        </button>
        <button
          disabled={isDisabled}
          className="meeting-preview-btn positive"
          onClick={onClickSave}
        >
          {t("approve")}
        </button>
      </div>
    </div>
  );
};

export default ImageEditor;
