export default [
  {
    username: "qa",
    id: "0V5416IBokVfWu8dTzm7wU5Hro32",
  },
  {
    username: "אשר קנינו",
    id: "7HVRGRtcjwOGQ1yJEm84Ger1PFj1",
  },
  {
    username: "מור חלילי",
    id: "8XeliXKvlId5bD7PJGAXdufjVPh1",
  },
  {
    username: "עידו טלמורע",
    id: "BnseI22D5RXtEWXYjjDVWte0RgR2",
  },
  {
    username: "",
    id: "IG5cFtsiF3W0fVv1V0wwT9N6Rnn1",
  },
  {
    username: "מתמלל",
    id: "NlM5jTFbeDXUv05xjAhimqPETDp2",
  },
  {
    username: "bar tester",
    id: "Uo1OFbe0M7OGbnsjncf4dyvkUin1",
  },
  {
    username: "חגית",
    id: "Y5p6fAETT2YuwGyeCoNoaXYAdjI3",
  },
  {
    username: "מליסה מנש",
    id: "YTGMRcWpdyMGm00pyGLbMUc7sgM2",
  },
  {
    username: "שי כהן",
    id: "aLq88oNBrHWSQw78dGDJi3C0WUb2",
  },
  {
    username: "ידידיה",
    id: "aVtZMfVoircjpCugNj5RMdQCvq62",
  },
  {
    username: "עדי פולק",
    id: "daeRsimxO5Xg0Yz9LNPB568ASej1",
  },
  {
    username: "proofman",
    id: "pDNxvpIAgNRRCMYBSKmZCcucei63",
  },
];
