import * as React from "react";
import _ from "lodash";
import isString from "lodash/isString";
import { FC, useState, useEffect, useRef, RefObject } from "react";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TimeService from "../../services/TimeService";

import "./TimerPicker.scss";

interface TimerBlockProps {
  value: string;
  type: string;
  step: number;
  min: string | number;
  max: string | number;
  selectAllClick?: boolean;
  arrowNav: (type: string, direction: string) => void;
  changeFocus: (key: string) => void;
  onChange: (newVal: string) => void;
  onBlur: () => void;
  inputRef: RefObject<HTMLInputElement>;
  testid: string;
}

const TimerBlock: FC<TimerBlockProps> = ({
  value,
  type,
  step,
  min,
  max,
  onChange,
  selectAllClick = true,
  arrowNav,
  changeFocus,
  onBlur,
  inputRef,
  testid,
}) => {
  const [newValue, setNewValue] = useState<number | string>(value);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const calculateNewValue = (value: string) => {
    if (value === "0" || value === "00") {
      return value;
    }

    if (Number(value) < 0) {
      return max;
    }

    if (value > max) {
      return min;
    }

    if (
      Number(value) < 10 &&
      value.startsWith("0") &&
      value.toString().length > min.toString().length
    ) {
      return value.replace(/^0/g, "");
    }

    if (value.length > min.toString().length && value.startsWith("0")) {
      return value.replace(/^0/g, "");
    }

    return value;
  };

  const handleChange = (
    event:
      | React.KeyboardEvent
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | string
  ) => {
    const targetValue = isString(event)
      ? event
      : (event.target as HTMLTextAreaElement).value;
    const calculatedValue = calculateNewValue(targetValue);

    onChange(calculatedValue.toString());
    setNewValue(calculatedValue);
    setIsChanged(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.nativeEvent.code) {
      case "Backspace":
        event.preventDefault();
        handleChange(min.toString());
        break;
      case "ArrowLeft":
        event.preventDefault();
        arrowNav(type, "left");
        break;
      case "ArrowRight":
        event.preventDefault();
        arrowNav(type, "right");
        break;
      default:
        changeFocus(event.nativeEvent.code);
        break;
    }
  };

  const handleBlur = () => {
    if (!isChanged) return;
    const stringValue = newValue.toString();
    let zeroPrefixedValue = newValue;
    if (stringValue.length < min.toString().length) {
      const gap = min.toString().length - stringValue.length;
      zeroPrefixedValue =
        Array.from({ length: gap }, () => "0").join("") + newValue;
      setNewValue(zeroPrefixedValue);
    }
    onBlur();
    setIsChanged(false);
  };

  const handleFocus = () => {
    if (selectAllClick) {
      inputRef.current && inputRef.current.select();
    }
  };

  return (
    <input
      data-testid={testid}
      ref={inputRef}
      type="number"
      step={step}
      value={newValue}
      className={`TimerBlock ${type}`}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

interface TimerPickerProps {
  className: string;
  value: string | number;
  step: number;
  handleChange: (time: string) => void;
  handleBlur: () => void;
  deductTime: () => void;
  addTime: () => void;
  disabled?: boolean;
}

const TimerPicker: FC<TimerPickerProps> = ({
  className,
  value,
  handleChange,
  handleBlur,
  step = 0.1,
  addTime,
  deductTime,
  disabled = false,
}) => {
  const hoursRef = useRef<HTMLInputElement>(null);
  const minutesRef = useRef<HTMLInputElement>(null);
  const secondsRef = useRef<HTMLInputElement>(null);
  const millisRef = useRef<HTMLInputElement>(null);

  const [hours, setHours] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [seconds, setSeconds] = useState("0");
  const [millis, setMillis] = useState("0");

  useEffect(() => {
    let time = value as string;
    if (_.isNumber(value)) {
      time = TimeService.getTimeStringFromSecs(value, false, true);
    }
    parseTime(time);
  }, [value]);

  const parseTime = (timeString: string) => {
    const [
      parsedHours = "00",
      parsedMinutes = "00",
      parsedSeconds = "00",
      parsedMillis = "000",
    ] = timeString.split(/[.:]/);

    setHours(parsedHours);
    setMinutes(parsedMinutes);
    setSeconds(parsedSeconds);
    setMillis(parsedMillis);
  };

  const stringifyTime = (
    hours: string,
    minutes: string,
    seconds: string,
    millis: string
  ) => {
    return `${hours}:${minutes}:${seconds}.${millis}`;
  };

  const arrowNav = (type: string, direction: string) => {
    switch (type) {
      case "hours":
        if (direction === "left") break;
        minutesRef.current && minutesRef.current.focus();
        break;
      case "minutes":
        if (direction === "left") {
          hoursRef.current && hoursRef.current.focus();
        } else {
          secondsRef.current && secondsRef.current.focus();
        }
        break;
      case "seconds":
        if (direction === "left") {
          minutesRef.current && minutesRef.current.focus();
        } else {
          millisRef.current && millisRef.current.focus();
        }
        break;
      case "milliseconds":
        if (direction === "right") break;
        secondsRef.current && secondsRef.current.focus();
        break;
    }
  };

  return (
    <div className={classNames("TimerPicker", className, { disabled })}>
      <div className="timerContainer">
        {disabled ? (
          <div className="readOnlyTime">{`${hours}:${minutes}:${seconds}.${millis}`}</div>
        ) : (
          <>
            <TimerBlock
              testid="hoursInput"
              inputRef={hoursRef}
              value={hours}
              type="hours"
              step={step * 10}
              min={"00"}
              max={99}
              arrowNav={arrowNav}
              changeFocus={(key: string) => {
                if (!key.startsWith("Arrow") && hours.length === 1) {
                  setTimeout(() => {
                    minutesRef.current?.focus();
                  }, 0);
                }
              }}
              onChange={(newVal: string) => {
                const timeString = stringifyTime(
                  newVal,
                  minutes,
                  seconds,
                  millis
                );
                handleChange(timeString);
                setHours(newVal);
              }}
              onBlur={handleBlur}
            />
            <span className="separator">:</span>
            <TimerBlock
              testid="minutesInput"
              inputRef={minutesRef}
              value={minutes}
              type="minutes"
              step={step * 10}
              min={"00"}
              max={59}
              arrowNav={arrowNav}
              changeFocus={(key: string) => {
                if (!key.startsWith("Arrow") && minutes.length === 1) {
                  setTimeout(() => {
                    secondsRef.current?.focus();
                  }, 0);
                }
              }}
              onChange={(newVal: string) => {
                const timeString = stringifyTime(
                  hours,
                  newVal,
                  seconds,
                  millis
                );
                handleChange(timeString);
                setMinutes(newVal);
              }}
              onBlur={handleBlur}
            />
            <span className="separator">:</span>
            <TimerBlock
              testid="secondsInput"
              inputRef={secondsRef}
              value={seconds}
              type="seconds"
              step={step * 10}
              min={"00"}
              max={59}
              arrowNav={arrowNav}
              changeFocus={(key: string) => {
                if (!key.startsWith("Arrow") && seconds.length === 1) {
                  setTimeout(() => {
                    millisRef.current?.focus();
                  }, 0);
                }
              }}
              onChange={(newVal: string) => {
                const timeString = stringifyTime(
                  hours,
                  minutes,
                  newVal,
                  millis
                );
                handleChange(timeString);
                setSeconds(newVal);
              }}
              onBlur={handleBlur}
            />
            <span className="separator">.</span>
            <TimerBlock
              testid="millisecondsInput"
              inputRef={millisRef}
              value={millis}
              type="milliseconds"
              step={step * 1000}
              min={"000"}
              max={999}
              arrowNav={arrowNav}
              changeFocus={(key: string) => key}
              onChange={(newVal: string) => {
                const timeString = stringifyTime(
                  hours,
                  minutes,
                  seconds,
                  newVal
                );
                handleChange(timeString);
                setMillis(newVal);
              }}
              onBlur={handleBlur}
            />
          </>
        )}
      </div>
      {addTime && !disabled && (
        <div className="minusButton timingButton" onClick={addTime}>
          <FontAwesomeIcon icon={["fal", "plus"]} />
        </div>
      )}
      {deductTime && !disabled && (
        <div className="plusButton timingButton" onClick={deductTime}>
          <FontAwesomeIcon icon={["fal", "minus"]} />
        </div>
      )}
    </div>
  );
};

export default TimerPicker;
