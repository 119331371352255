import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  format,
} from "date-fns";
const getTimeStringFromSecs = (
  numOfSecs: number,
  onlyMins = false,
  withMilli = false
): string => {
  const stringifiedNumber = numOfSecs.toString();
  const [secondsStr, milisecondsStr] = stringifiedNumber.split(".");
  const fixedNumber = Number(
    milisecondsStr ? `${secondsStr}.${milisecondsStr.slice(0, 3)}` : secondsStr
  );

  const milliseconds = (fixedNumber % 1).toFixed(3).substring(2);
  const seconds = fixedNumber % 60;
  const minutes = (fixedNumber / 60) % 60;
  const hours = fixedNumber / 3600;

  if (onlyMins)
    return (
      _get2DigitNumber(fixedNumber / 60) +
      ":" +
      _get2DigitNumber(fixedNumber % 60) +
      (withMilli ? "." + milliseconds : "")
    );

  return (
    _get2DigitNumber(hours) +
    ":" +
    _get2DigitNumber(minutes) +
    ":" +
    _get2DigitNumber(seconds) +
    (withMilli ? "." + milliseconds : "")
  );
};

const getTimeNumberFromString = (time: string): number => {
  const [hours = 0, minutes = 0, seconds = 0] = time.split(":");
  const hourSeconds = Number(hours) * 3600;
  const minutesSeconds = Number(minutes) * 60;

  return hourSeconds + minutesSeconds + Number(seconds);
};

const _get2DigitNumber = (n: number): number | string => {
  const floored = Math.floor(n);
  if (floored < 10) return `0${floored}`;
  return floored;
};

const getDateString = (date: Date): string => {
  return (
    _get2DigitNumber(date.getDate()) +
    "/" +
    _get2DigitNumber(date.getMonth() + 1) +
    "/" +
    _get2DigitNumber(date.getFullYear())
  );
};

const compareDates = (a: Date, b: Date): number => {
  if (a.getFullYear() !== b.getFullYear())
    return a.getFullYear() - b.getFullYear();

  if (a.getMonth() !== b.getMonth()) return a.getMonth() - b.getMonth();

  if (a.getDate() !== b.getDate()) return a.getDate() - b.getDate();

  return 0;
};
const getDeadlineVerbalString = (deadline: Date, t: any): any => {
  let hours = differenceInHours(deadline, Date.now());
  const minutes = differenceInMinutes(deadline, Date.now());
  if (hours <= 0 && minutes <= 0) return "0";
  const days = differenceInDays(deadline, Date.now());
  const daysWhenTheHourDivideBy24 = hours / 24;
  hours = hours % 24;
  if (days && hours) {
    return `${days} ${t("days")}, ${hours} ${t("hours_shortcut")}`;
  } else if (!days && hours) {
    return hours <= 2 ? t("two_hours") : `${hours} ${t("hours")}`;
  } else if (!hours && days) {
    return `${daysWhenTheHourDivideBy24} ${t("days")}`;
  } else {
    return `${minutes % 60} ${t("minuts")}`;
  }
};

const getDeadlineString = (deadline: Date): string => {
  return format(deadline, "dd/MM/yy HH:SS");
};

const getDeadlineStr = (deadline: Date): string => {
  const hours = differenceInHours(deadline, Date.now());
  if (0 >= hours) return "0";
  const minutes = differenceInMinutes(deadline, Date.now()) % 60;
  let hoursZero = "";
  let minutesZero = "";
  if (hours < 10 && hours >= 0) hoursZero = "0";
  if (minutes < 10 && minutes >= 0) minutesZero = "0";
  const time = hoursZero + hours + ":" + minutesZero + minutes;
  return time;
};
const getDeadlineStatus = (deadline: Date): string => {
  const hours = differenceInHours(deadline, Date.now());
  if (hours <= 12) return "high";
  const className = hours >= 24 ? "low" : "medium";
  return className;
};

const getFixedFrameRateTime = ({
  time,
  frameRate,
  timeToAdd = 0,
  framesToAdd = 1,
  operator,
}: {
  time: number;
  frameRate: number;
  timeToAdd?: number;
  framesToAdd?: number;
  operator?: "add" | "subtract";
}): number => {
  if (time + timeToAdd < 0) {
    return 0;
  }
  const remainder = (time * frameRate) % 1;
  const currentFrame = Math.round(time * frameRate);
  let fixedTime = currentFrame / frameRate;

  if (operator == "add") {
    const currentFrame =
      remainder && remainder > 0.98
        ? Math.round(time * frameRate)
        : Math.floor(time * frameRate);
    fixedTime = (currentFrame + framesToAdd) / frameRate;
  }

  if (operator == "subtract") {
    const currentFrame =
      remainder && remainder < 0.98
        ? Math.round(time * frameRate)
        : Math.ceil(time * frameRate);
    fixedTime = (currentFrame - framesToAdd) / frameRate;
  }

  return Number(fixedTime.toFixed(3));
};

export default {
  getDeadlineStatus,
  getDeadlineStr,
  getTimeStringFromSecs,
  getTimeNumberFromString,
  getDateString,
  getDeadlineVerbalString,
  getDeadlineString,
  getFixedFrameRateTime,
  compareDates,
};
