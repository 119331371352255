import React from "react";
import { JobData } from "../../../models/job";
import { ExportConfigData } from "../ExportConfigTypes";
import { getFlexDirection, getTextStyling } from "../Preview";
import { Content } from "./Content";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { JobDate } from "./JobDate";
import { JobName } from "./JobName";
import { Logo } from "./Logo";

export interface PreviewPageProps {
  settings: ExportConfigData;
  pageNumber: number;
  documentLayout: React.CSSProperties;
  docDir: "ltr" | "rtl";
  jobData: JobData;
  children?: React.ReactNode;
}

export const PreviewPage = ({
  settings,
  pageNumber,
  documentLayout,
  docDir,
  jobData,
  children,
}: PreviewPageProps) => {
  const FIRST_PAGE = 1;
  return (
    <div className="pageContainer">
      <div className="previewPage" style={documentLayout}>
        <Header
          logoFirstPage={
            <Logo
              logo={{
                show:
                  settings?.headerFooter?.firstPageHeader?.isActive &&
                  settings?.headerFooter?.firstPageHeader?.logo?.isActive &&
                  pageNumber === FIRST_PAGE,
                content: settings?.headerFooter?.firstPageHeader?.logo?.url,
                align: getFlexDirection(
                  docDir,
                  settings?.headerFooter?.firstPageHeader?.logo?.align
                ),
                cssProps: {
                  width:
                    settings?.headerFooter?.firstPageHeader?.logo?.size + "pt",
                },
              }}
            />
          }
          logoOtherPages={
            <Logo
              logo={{
                show:
                  settings?.headerFooter?.otherPagesHeader?.isActive &&
                  settings?.headerFooter?.otherPagesHeader?.logo?.isActive &&
                  pageNumber > FIRST_PAGE,
                content: settings?.headerFooter?.otherPagesHeader?.logo?.url,
                align: getFlexDirection(
                  docDir,
                  settings?.headerFooter?.otherPagesHeader?.logo?.align
                ),
                cssProps: {
                  width:
                    settings?.headerFooter?.otherPagesHeader?.logo?.size + "pt",
                },
              }}
            />
          }
          jobNameFirstPage={
            <JobName
              jobNamePreviewItem={{
                show:
                  settings?.headerFooter?.firstPageHeader?.isActive &&
                  settings?.headerFooter?.firstPageHeader?.jobName?.isActive &&
                  pageNumber === FIRST_PAGE,
                content: jobData.name,
                align: getFlexDirection(
                  docDir,
                  settings?.headerFooter?.firstPageHeader?.jobName?.align
                ),
              }}
              style={getTextStyling(settings?.general?.font)}
            />
          }
          jobNameOtherPages={
            <JobName
              jobNamePreviewItem={{
                show:
                  settings?.headerFooter?.otherPagesHeader?.isActive &&
                  settings?.headerFooter?.otherPagesHeader?.jobName?.isActive &&
                  pageNumber > FIRST_PAGE,
                content: jobData.name,
                align: getFlexDirection(
                  docDir,
                  settings?.headerFooter?.otherPagesHeader?.jobName?.align
                ),
              }}
              style={getTextStyling(settings?.general?.font)}
            />
          }
          jobDateFirstPage={
            <JobDate
              jobDatePreviewItem={{
                show:
                  settings?.headerFooter?.firstPageHeader?.isActive &&
                  settings?.headerFooter?.firstPageHeader?.jobDate?.isActive &&
                  pageNumber === FIRST_PAGE,
                content: jobData.creationTime,
                align: getFlexDirection(
                  docDir,
                  settings?.headerFooter?.firstPageHeader?.jobDate?.align
                ),
              }}
              calendars={settings?.information?.jobDate?.calendars}
              lang={jobData.lang.output[0]}
              style={getTextStyling(settings?.general?.font)}
            />
          }
          jobDateOtherPages={
            <JobDate
              jobDatePreviewItem={{
                show:
                  settings?.headerFooter?.otherPagesHeader?.isActive &&
                  settings?.headerFooter?.otherPagesHeader?.jobDate?.isActive &&
                  pageNumber > FIRST_PAGE,
                content: jobData.creationTime,
                align: getFlexDirection(
                  docDir,
                  settings?.headerFooter?.otherPagesHeader?.jobDate?.align
                ),
              }}
              calendars={settings?.information?.jobDate?.calendars}
              lang={jobData.lang.output[0]}
              style={getTextStyling(settings?.general?.font)}
            />
          }
        />
        <JobName
          jobNamePreviewItem={{
            show:
              settings?.information?.jobName?.isActive &&
              pageNumber === FIRST_PAGE,
            content: jobData.name,
            align: getFlexDirection(
              docDir,
              settings?.information?.jobName?.align
            ),
          }}
          style={getTextStyling(settings?.information?.jobName)}
        />
        <JobDate
          lang={jobData.lang.output[0]}
          jobDatePreviewItem={{
            show:
              settings?.information?.jobDate?.isActive &&
              pageNumber === FIRST_PAGE,
            content: jobData.creationTime,
            align: getFlexDirection(
              docDir,
              settings?.information?.jobDate?.align
            ),
          }}
          calendars={settings?.information?.jobDate?.calendars}
          style={getTextStyling(settings?.information?.jobDate)}
        />
        <Content
          docDir={docDir}
          jobData={jobData}
          settings={settings}
          content={children}
        />
        <Footer
          footerText={settings?.headerFooter?.footer?.footerText}
          logo={{
            show:
              settings?.headerFooter?.footer?.isActive &&
              settings?.headerFooter?.footer?.logo?.isActive,
            content: settings?.headerFooter?.footer?.logo?.url,
            align: getFlexDirection(
              docDir,
              settings?.headerFooter?.footer?.logo?.align
            ),
            cssProps: {
              width: settings?.headerFooter?.footer?.logo?.size + "pt",
            },
          }}
          pageNumbering={{
            show:
              settings?.headerFooter?.footer?.isActive &&
              settings?.headerFooter?.footer?.pageNumbering?.isActive,
            content: pageNumber,
            align: getFlexDirection(
              docDir,
              settings?.headerFooter?.footer?.pageNumbering?.align
            ),
          }}
        />
      </div>
    </div>
  );
};
