import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./SubmitButton.scss";

interface Props {
  text: string;
  Icn?: IconProp;
  disabled?: boolean;
}

const SubmitButton: FC<Props> = ({ text, Icn, disabled }): JSX.Element => {
  return (
    <>
      <button type="submit" disabled={disabled} className="submit-btn">
        {Icn && <FontAwesomeIcon icon={Icn} />} {text}
      </button>
    </>
  );
};

export default SubmitButton;
