import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./SearchBar.scss";

interface Props {
  onInput: (input: string) => void;
}

const SearchBar: FC<Props> = ({ onInput }): JSX.Element => {
  const { t } = useTranslation();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInput(e.target.value);
  };

  return (
    <div className="search-bar flex justify-between">
      <div className="icn-wrapper flex align-center justify-center">
        <FontAwesomeIcon icon={["far", "search"]} />
      </div>
      <input type="text" onChange={handleInput} placeholder={t("search")} />
    </div>
  );
};

export default SearchBar;
