import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Select from "react-select";

import FirebaseService from "../../services/FirebaseService";
import FeatureFlagsService from "../../services/FeatureFlagsService";
import Logger from "../../services/Logger";

import { popIndicator } from "../../store/system/actions";
import GenericTable from "../../components/Table/GenericTable";
import Dropdown from "../../components/common/Dropdown/Dropdown";

import { userRoles } from "../../constants/userRoles";

import "./AdminPage.scss";

const logger = Logger("AdminPage");

interface Users {
  [userId: string]: {
    id: string;
    username: string;
  };
}
interface OptionType {
  value: string;
  label: string;
}

const AdminPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [featureFlags, setFeatureFlags] = useState<
    {
      name: string;
      enabled: boolean;
      roles: string[];
      users: string[];
    }[]
  >();
  const [allUsers, setAllUsers] = useState<Users>({});
  const [loading, setLoading] = useState<{ [name: string]: boolean }>({});

  useEffect(() => {
    const init = async () => {
      const ffObj = await FeatureFlagsService.getAll();
      const ffList = _.map(ffObj, (ff: any, name) => ({ name, ...ff }));
      const users = await FirebaseService.getAllUsers({ keyBy: "id" });
      setAllUsers(users as Users);
      setFeatureFlags(_.sortBy(ffList, "name"));
    };
    init();
  }, []);

  const handleFFtoggle = async (feature: string) => {
    if (!featureFlags) return;
    try {
      setLoading({ ...loading, [feature]: true });
      await FeatureFlagsService.toggle(feature);
      const updatedFFs = featureFlags;
      const ffIndex = _.findIndex(featureFlags, { name: feature });
      updatedFFs[ffIndex].enabled = !featureFlags[ffIndex].enabled;
      setFeatureFlags(updatedFFs);
      dispatch(popIndicator({ type: "success", txt: t("ff_toggle_success") }));
    } catch (err) {
      logger.error(err, "handleFFtoggle");
      dispatch(popIndicator({ type: "failure", txt: t("ff_toggle_fail") }));
    } finally {
      setLoading({ ...loading, [feature]: false });
    }
  };

  const handleFFRoleChange = async (feature: string, roles: string[]) => {
    if (!featureFlags) return;
    try {
      setLoading({ ...loading, [feature]: true });
      await FeatureFlagsService.updateFeatureRoles(feature, roles);
      const updatedFFs = featureFlags;
      const ffIndex = _.findIndex(featureFlags, { name: feature });
      updatedFFs[ffIndex].roles = roles;
      setFeatureFlags(updatedFFs);
      dispatch(popIndicator({ type: "success", txt: t("ff_toggle_success") }));
    } catch (err) {
      logger.error(err, "handleFFtoggle");
      dispatch(popIndicator({ type: "failure", txt: t("ff_toggle_fail") }));
    } finally {
      setLoading({ ...loading, [feature]: false });
    }
  };

  const handleFFUserChange = async (feature: string, users: OptionType[]) => {
    if (!featureFlags) return;
    try {
      setLoading({ ...loading, [feature]: true });
      const userIds = _.map(users, (u) => u.value);
      await FeatureFlagsService.updateFeatureUsers(feature, userIds);
      const updatedFFs = featureFlags;
      const ffIndex = _.findIndex(featureFlags, { name: feature });
      updatedFFs[ffIndex].users = userIds;
      setFeatureFlags(updatedFFs);
      dispatch(popIndicator({ type: "success", txt: t("ff_toggle_success") }));
    } catch (err) {
      logger.error(err, "handleFFtoggle");
      dispatch(popIndicator({ type: "failure", txt: t("ff_toggle_fail") }));
    } finally {
      setLoading({ ...loading, [feature]: false });
    }
  };

  return (
    <main className="main-container AdminPage">
      <h1 className="pageHeader">{t("admin_page")}</h1>
      <div className="featureFlags">
        <h2 className="title">{t("feature_flags")}</h2>
        <div className="featuresContainer">
          {featureFlags && (
            <GenericTable
              config={{
                tableName: t("admin_page"),
                columns: {
                  name: { label: t("feature_flag") },
                  roles: { label: t("role") },
                  users: { label: t("users") },
                  enabled: { label: t("status") },
                },
                formatters: {
                  enabled: (val, row) => (
                    <Switch
                      checked={val}
                      onClick={() => {
                        handleFFtoggle(row.name);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={loading[row.name]}
                    />
                  ),
                  roles: (val, row) => (
                    <Dropdown
                      options={[...userRoles]}
                      value={row.roles}
                      placeholder={t("all")}
                      multiSelect={true}
                      onChange={(roles) => {
                        handleFFRoleChange(row.name, roles as string[]);
                      }}
                      searchOption={false}
                      isDisabled={loading[row.name]}
                    />
                  ),
                  users: (val, row) => (
                    <Select
                      className="ffUsers"
                      isMulti={true}
                      options={_.map(_.values(allUsers), (u) => ({
                        value: u?.id,
                        label: u?.username,
                      }))}
                      onChange={(users) =>
                        handleFFUserChange(row.name, users as OptionType[])
                      }
                      value={_.map(row.users, (userId) => ({
                        value: userId,
                        label: allUsers[userId]?.username,
                      }))}
                      placeholder="בחר משתמשים"
                      classNamePrefix="react-select"
                      isDisabled={loading[row.name]}
                    />
                  ),
                },
                actionsBar: false,
              }}
              data={featureFlags}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default AdminPage;
