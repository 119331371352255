import React, { FC, useState, useEffect, useRef } from "react";
import config from "../../config";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { AppState } from "../../store/rootReducer";
import AuthService from "../../services/AuthService";
import FirebaseService from "../../services/FirebaseService";

import Logger from "../../services/Logger";

import PhoneVerifyStep from "./PhoneVerifyStep";
import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";
import AuthProvidersButtons from "../../components/AuthProvidersButtons/AuthProvidersButtons";

const logger = Logger("LoginPage");

import "./AuthPages.scss";

type LoginEmailInputs = {
  email: string;
  password: string;
};

const LoginPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [loginWithPhone, setLoginWithPhone] = useState(false);
  const [unsupportedFirstFactor, setUnsupportedFirstFactor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    verification,
    setVerification,
  ] = useState<firebase.auth.ConfirmationResult>();
  const [phoneVerificationError, setPhoneVerificationError] = useState(null);
  const [
    secondFactor,
    setSecondFactor,
  ] = useState<firebase.auth.MultiFactorResolver>();
  const [
    secondFactorVerification,
    setSecondFactorVerification,
  ] = useState<string>();
  const [error, setError] = useState<string>();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const loginProviders = [
    {
      label: "google",
      onClick: () => handleProviderLogin("google"),
    },
    // {
    //   label: "facebook",
    //   onClick: () => handleProviderLogin("facebook")
    // }
  ];

  useEffect(() => {
    AuthService.initLogin();
    const qs = history.location.search;
    if (qs && qs.includes("phone=true")) {
      setLoginWithPhone(true);
    }
  }, []);

  const handleEmailLogin = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      setError("");
      const login = await AuthService.login(email, password);
      if (login.secondFactor) {
        setSecondFactor(login.secondFactor);
        initSecondFactor(login.secondFactor);
      } else if (login.success === true) {
        handleSuccessLogin();
      }
    } catch (err: any) {
      logger.error(err, "handleEmailLogin");
      setPhoneVerificationError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleProviderLogin = async (provider: "google" | "facebook") => {
    try {
      setIsLoading(true);
      setError("");
      const login = await AuthService.signUpWithProvider(provider);
      if (login.isNew && login.user.user) {
        login.user.user.getIdToken(true);
      }

      if (login.secondFactor) {
        setSecondFactor(login.secondFactor);
        initSecondFactor(login.secondFactor);
      } else if (login.success === true) {
        handleSuccessLogin();
        location.reload();
      }
    } catch (err: any) {
      logger.error(err, "handleProviderLogin");
      setPhoneVerificationError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendPhonenumber = async (phonenumber: string) => {
    try {
      setError("");
      setIsLoading(true);
      setPhoneVerificationError(null);
      const verification = await AuthService.loginPhone(phonenumber);
      setVerification(verification);
    } catch (err: any) {
      logger.error(err, "handleSendPhonenumber");
      if (err === "auth/unsupported-first-factor") {
        handleUnsupportedFirstFactor();
      }
      setPhoneVerificationError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendVerification = async (code: string) => {
    try {
      setIsLoading(true);
      setPhoneVerificationError(null);
      await verification?.confirm(code);
    } catch (err: any) {
      const supportedErrorCodes = ["auth/invalid-verification-code"];
      if (supportedErrorCodes.includes(err.code)) {
        setPhoneVerificationError(t(err.code));
      } else {
        setPhoneVerificationError(t("auth/default"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsupportedFirstFactor = () => {
    setUnsupportedFirstFactor(true);
    const redirectToEmailLogin = () => {
      setLoginWithPhone(false);
      setUnsupportedFirstFactor(false);
      setPhoneVerificationError(null);
    };

    setTimeout(redirectToEmailLogin, 5000);
  };

  const initSecondFactor = async (
    resolver: firebase.auth.MultiFactorResolver
  ) => {
    try {
      await AuthService.initLogin();
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verification = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        FirebaseService.verifier
      );
      setSecondFactorVerification(verification);
    } catch (err: any) {
      logger.error(err, "initSecondFactor");
      setError(t(err.code));
    }
  };

  const resolveSecondFactor = async (code: string) => {
    setError("");
    if (!secondFactor || !secondFactorVerification) return;
    try {
      const credentials = firebase.auth.PhoneAuthProvider.credential(
        secondFactorVerification,
        code
      );
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
        credentials
      );
      await secondFactor.resolveSignIn(multiFactorAssertion);
      handleSuccessLogin();
    } catch (err: any) {
      logger.error(err, "resolveSecondFactor");
      setError(t(err.code));
    }
  };

  const handleSuccessLogin = () => {
    history.push("/");
  };

  const LoginEmailForm = () => {
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid, isDirty },
    } = useForm<LoginEmailInputs>({ mode: "onChange" });
    const login: SubmitHandler<LoginEmailInputs> = (data: {
      email: string;
      password: string;
    }) => handleEmailLogin(data.email, data.password);
    return (
      <form className="styledForm" onSubmit={handleSubmit(login)}>
        <div className="formError phoneResult">{phoneVerificationError}</div>
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("email")}
          autofocus={true}
          disabled={isLoading}
          error={isDirty && !isValid ? errors.email?.message : ""}
          register={register("email", {
            required: { value: true, message: t("required_field") },
          })}
        />
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("password")}
          type="password"
          disabled={isLoading}
          error={isDirty && !isValid ? errors.password?.message : ""}
          register={register("password", {
            required: { value: true, message: t("required_field") },
          })}
        />
        <Button
          type="submit"
          label={t("sign_in")}
          className={"fullWidth"}
          loading={isLoading}
          disabled={!isValid}
        />
        <Link to={"/password-recovery"}>{t("forgot_password")}</Link>
      </form>
    );
  };

  return (
    <div className="LoginPage">
      <div className="authContainer">
        {secondFactor ? (
          <div className="loginSecondFactorContainer">
            <div className="headerTitle">{t("second_factor_required")}</div>
            <div className="note">
              <div className="noteBody">
                {t("second_factor_note", {
                  //@ts-ignore
                  phonenumber: secondFactor.hints[0].phoneNumber,
                  interpolation: { escapeValue: false },
                })
                  .split("/n")
                  .map((l, i) => (
                    <div
                      dangerouslySetInnerHTML={{ __html: l }}
                      className="noteLine"
                      key={i}
                    />
                  ))}
              </div>
            </div>
            <PhoneVerifyStep
              onVerificationSend={resolveSecondFactor}
              askForPhonenumber={false}
              error={error}
            />
          </div>
        ) : (
          <>
            {/* <div className="headerIcon">
            <img src={Logo} alt="sumit" />
          </div> */}
            <div className="headerTitle">
              {loginWithPhone ? t("attention") : t("welcome_login")}
            </div>
            {loginWithPhone ? (
              <PhoneVerifyStep />
            ) : (
              <div className="splitContainer">
                <div className="userPasswordContainer">
                  <div className="or">{t("or")}</div>
                  <LoginEmailForm />
                </div>
                {config.authProviders && (
                  <>
                    <div className="providersContainer">
                      <AuthProvidersButtons providers={loginProviders} />
                    </div>
                  </>
                )}
              </div>
            )}
            {loginWithPhone && (
              <div className="bottomNote">
                <div className="noteBody">
                  <div
                    className="link goBack"
                    onClick={() => setLoginWithPhone(false)}
                  >
                    {unsupportedFirstFactor
                      ? t("usupported_first_factor_redirect")
                      : t("go_back_mail_login")}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!secondFactor && (
        <div className="newUser">
          <Link to={"/sign-up"}>{t("i_want_to_sign_up")}</Link>
        </div>
      )}
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};

export default LoginPage;
