import {
  PaymentState,
  SET_RAW_PAYMENTS_DATA,
  CLEAR_RAW_PAYMENTS_DATA,
  SET_PAYMENTS_BY_MONTHS,
  CLEAR_PAYMENTS_BY_MONTHS,
  PaymentActionTypes,
  SET_USER_DEBT,
} from "./types";

const initialState: PaymentState = {
  rawPaymentsData: null,
  paymentsByMonths: [],
  userDebt: {},
};

function clientReducer(
  state = initialState,
  action: PaymentActionTypes
): PaymentState {
  switch (action.type) {
    case SET_RAW_PAYMENTS_DATA:
      return {
        ...state,
        rawPaymentsData: action.payments,
      };
    case CLEAR_RAW_PAYMENTS_DATA:
      return {
        ...state,
        rawPaymentsData: null,
      };
    case SET_PAYMENTS_BY_MONTHS:
      return {
        ...state,
        paymentsByMonths: action.paymentsByMonths,
      };
    case CLEAR_PAYMENTS_BY_MONTHS:
      return {
        ...state,
        paymentsByMonths: [],
      };
    case SET_USER_DEBT:
      return {
        ...state,
        userDebt: action.debts,
      };
    default:
      return state;
  }
}

export default clientReducer;
