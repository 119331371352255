import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { AppState } from "../../store/rootReducer";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FilePicker } from "react-file-picker";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import FirebaseService from "../../services/FirebaseService";
import PaymentsService from "../../services/PaymentsService";
import CloudFunctionsService from "../../services/CloudFunctionsService";

import {
  clearCurrTranscriber,
  setCurrTranscriber,
} from "../../store/user/actions";
import {
  setLoadingReason,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import InfoField from "../../components/InfoField/InfoField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveEntityButton from "../../components/RemoveEntityButton/RemoveEntityButton";
import PageHeader from "../../components/PageHeader/PageHeader";
import { localeCodes } from "../../models/localeCodes";
import { dealerTypes } from "../../models/user";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

import "./TranscriberPage.scss";

const TranscriberPage: FC = (): JSX.Element => {
  const { id } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const currTranscriber = useSelector(
    (state: AppState) => state.userStore.currTranscriber
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );

  const [isPending, setIsPending] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [userImg, setUserImg] = useState<string>("");
  const [userDebt, setUserDebt] = useState<number>(0);
  const [phoneStr, setPhoneStr] = useState<string>("");
  const [languagesStr, setLanguagesStr] = useState<string>("");
  const [userRole, setUserRole] = useState(currTranscriber?.role);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTranscriber();
    return () => {
      dispatch(clearCurrTranscriber());
    };
  }, []);

  useEffect(() => {
    if (currTranscriber) {
      setUserRole(currTranscriber.role);
      if (currTranscriber.hasContract) setIsDisabled(false);
      const { languages } = currTranscriber;
      if (!languages) return;
      let langStr = "";
      languages.forEach((value, idx) => {
        let valStr = t(value);
        if (languages.length - 1 !== idx) valStr = valStr + ", ";
        langStr = langStr + valStr;
      });
      setLanguagesStr(langStr);
    }
  }, [currTranscriber]);

  useEffect(() => {
    if (currTranscriber?.imgUrl) setUserImg(currTranscriber.imgUrl);
    else
      setUserImg(
        "https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg"
      );
  }, [currTranscriber?.imgUrl]);

  const loadTranscriber = async () => {
    try {
      dispatch(setLoadingReason(t("loading_transcriber_information")));
      const transcriber = await FirebaseService.getTranscriber(id);
      if (transcriber) {
        const userDebt = await PaymentsService.getUserDebt(transcriber.id);
        dispatch(setCurrTranscriber(transcriber));
        setPhoneStr(getPhoneStr(transcriber.phonenumber));
        dispatch(clearLoadingReason());
        setUserDebt(userDebt ? userDebt.sum : 0);
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const getPhoneStr = (phone: string) => {
    if (phone && phone.startsWith("+972")) {
      return (
        phone.substr(0, 4) + " " + phone.substr(4, 2) + " " + phone.substr(6, 7)
      );
    } else return "";
  };

  const approveTranscriber = async () => {
    if (isPending) return;
    try {
      setIsPending(true);
      setIsDisabled(true);
      await FirebaseService.approveNewTranscriber(id);
      currTranscriber &&
        dispatch(
          setCurrTranscriber({
            ...currTranscriber,
            role: "transcriber",
          })
        );
      setIsPending(false);
      dispatch(
        popIndicator({
          type: "success",
          txt: t("indicator_transcriber_approved"),
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };
  const handleSubmitNewRole = async () => {
    if (userRole && currTranscriber) {
      try {
        setLoading(true);
        dispatch(setCurrTranscriber({ ...currTranscriber, role: userRole }));
        await FirebaseService.changeUserRole(currTranscriber.id, userRole);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  const handleRoleChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setUserRole(event.target.value as string);
  };

  const disableUser = async () => {
    if (isPending) return;
    try {
      setIsPending(true);
      await CloudFunctionsService.disableUser(id);
      history.push("/transcribers");
      dispatch(clearCurrTranscriber());
      dispatch(
        popIndicator({
          type: "success",
          txt: t("indicator_transcriber_disabled"),
        })
      );
    } catch (err) {
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("indicator_transcriber_disable_error"),
        })
      );
    } finally {
      setIsPending(false);
    }
  };

  const enableUser = async () => {
    if (isPending) return;
    try {
      setIsPending(true);
      await CloudFunctionsService.enableUser(id);
      history.push("/transcribers");
      dispatch(clearCurrTranscriber());
      dispatch(
        popIndicator({
          type: "success",
          txt: t("indicator_transcriber_enabled"),
        })
      );
    } catch (err) {
      dispatch(
        popIndicator({
          type: "failure",
          txt: t("indicator_transcriber_enable_error"),
        })
      );
    } finally {
      setIsPending(false);
    }
  };

  const uploadContract = async (contractFile: File) => {
    if (isPending) return;
    try {
      setIsPending(true);
      setIsDisabled(true);
      await FirebaseService.uploadContract(contractFile, id);
      currTranscriber &&
        dispatch(
          setCurrTranscriber({
            ...currTranscriber,
            hasContract: true,
          })
        );
      setIsPending(false);
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_contract_uploaded") })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const dealerTypeStr = currTranscriber
    ? t(dealerTypes[currTranscriber.dealerType])
    : "";
  const userNameTitle = currTranscriber ? currTranscriber.username : "";
  const totalPastMeetingsCount = currTranscriber
    ? currTranscriber.completedCount + currTranscriber.rejectedCount
    : 0;
  const pastMeetingsStr = currTranscriber
    ? `${
        totalPastMeetingsCount - currTranscriber.rejectedCount
      }/${totalPastMeetingsCount}`
    : 0;

  return (
    <main className="main-container">
      <PageHeader
        title={userNameTitle}
        backButton={{
          title: t("transcribers"),
          backAction: () => history.push("/transcribers"),
        }}
      />
      {loadingReason && <LoadingModal loadingReason={loadingReason} />}
      {!!errorReason && <ErrorModal errorReason={errorReason} />}
      {!loadingReason && !errorReason && (
        <div className="transcriber-page">
          {currTranscriber && !loadingReason && (
            <div className="details">
              <InfoField
                className="at"
                Icn={["fal", "at"]}
                text={currTranscriber.mail}
              />
              <InfoField
                className="phone"
                Icn={["fal", "phone"]}
                text={phoneStr}
                textDirection={"ltr"}
              />
              <InfoField
                className="birthday"
                Icn={["fal", "birthday-cake"]}
                text={format(currTranscriber.dateOfBirth, "dd/MM/yyyy")}
                textDirection={"ltr"}
              />
              <InfoField
                className="birthday"
                Icn={["fal", "city"]}
                text={currTranscriber.addressCity}
                textDirection={"ltr"}
              />
              <InfoField
                className="birthday"
                Icn={["fal", "globe"]}
                text={languagesStr}
                textDirection={"ltr"}
              />
              {currTranscriber.role !== "pending-transcriber" && (
                <InfoField
                  className="nis"
                  Icn={["fal", "shekel-sign"]}
                  onClick={() => history.push(`/transcriber/payments/${id}`)}
                  text={userDebt}
                />
              )}
              {currTranscriber.role !== "pending-transcriber" && (
                <InfoField
                  className="rejected"
                  onClick={() => history.push(`/transcriber/meetings/${id}`)}
                  Icn={["fal", "briefcase"]}
                  text={currTranscriber.currMeetingsCount}
                />
              )}
              {currTranscriber.role !== "pending-transcriber" && (
                <InfoField
                  className="completed"
                  Icn={["fal", "clipboard-list-check"]}
                  text={pastMeetingsStr}
                />
              )}
              {currTranscriber.role !== "pending-transcriber" &&
                (loading ? (
                  <div className="loading-spinner">
                    <CircularProgress style={{ width: "20px" }} />
                  </div>
                ) : (
                  <div className="info-field userRoleDiv">
                    <div className="icn-wrapper">
                      <FontAwesomeIcon icon={["fal", "user"]} />
                    </div>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ left: "auto" }}
                      >
                        {t("role")}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        className={classNames({
                          disabled: userRole === "disabled",
                        })}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userRole ? userRole : currTranscriber.role}
                        label={t("role")}
                        onChange={handleRoleChange}
                        disabled={userRole === "disabled"}
                      >
                        <MenuItem value="super_user">
                          {t("super_user")}
                        </MenuItem>
                        <MenuItem value="transcriber">
                          {t("transcriber")}
                        </MenuItem>
                        <MenuItem value="transcriber+proofer">
                          {t("transcriber+proofer")}
                        </MenuItem>
                        <MenuItem value="proofer">{t("proofer")}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ))}
              {currTranscriber.role === "pending-transcriber" && (
                <InfoField
                  Icn={["fal", "badge-percent"]}
                  text={dealerTypeStr}
                />
              )}
              {currTranscriber.role === "pending-transcriber" && (
                <InfoField
                  Icn={["fal", "clipboard-list-check"]}
                  text={currTranscriber.dealerNumer}
                />
              )}
              {currTranscriber.role === "pending-transcriber" &&
                !currTranscriber.hasContract && (
                  <div className="upload-contract-wrapper">
                    <div className="icn-wrapper">
                      <FontAwesomeIcon icon={["fal", "file-contract"]} />
                    </div>
                    <FilePicker
                      disabled={isPending}
                      extensions={["jpg", "png", "bmp", "docx", "pdf", "txt"]}
                      maxSize={25}
                      onChange={uploadContract}
                    >
                      <div className={`text ${isPending ? "disabled" : ""}`}>
                        <p>{t("upload_contract")}</p>
                        <div className="mini-icn">
                          <FontAwesomeIcon icon={["fal", "file-upload"]} />
                        </div>
                      </div>
                    </FilePicker>
                  </div>
                )}
              <button
                className={classNames("submit approve", {
                  disabled:
                    currTranscriber.role === "super_user" ||
                    currTranscriber.role === userRole,
                })}
                type="button"
                disabled={
                  currTranscriber.role === "super_user" ||
                  currTranscriber.role === userRole
                }
                onClick={handleSubmitNewRole}
              >
                {t("approve")}
              </button>
            </div>
          )}
          {currTranscriber && !loadingReason && (
            <div className="entity-edit">
              <div>
                {currTranscriber.role !== "pending-transcriber" && (
                  <div className="user-img-wrapper">
                    <div className="user-img">
                      <img src={userImg} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className="entity-btns">
                {currTranscriber.role === "pending-transcriber" && (
                  <button
                    onClick={approveTranscriber}
                    disabled={isDisabled}
                    className={`approve ${isDisabled ? "disabled" : ""}`}
                  >
                    <p>{t("approve_transcriber")}</p>
                  </button>
                )}
                {currTranscriber.role === "disabled" ? (
                  <button className={classNames("submit cancel")} type="button">
                    {isPending ? (
                      <div className="loading-spinner">
                        <CircularProgress />
                      </div>
                    ) : (
                      <p onClick={enableUser}>{t("enable_transcriber")}</p>
                    )}
                  </button>
                ) : (
                  <button
                    className={classNames("submit cancel", {
                      disabled: currTranscriber.role === "disabled",
                    })}
                    type="button"
                    disabled={currTranscriber.role === "disabled"}
                  >
                    {isPending ? (
                      <div className="loading-spinner">
                        <CircularProgress />
                      </div>
                    ) : (
                      <p
                        onClick={() =>
                          currTranscriber.role !== "disabled" && disableUser()
                        }
                      >
                        {t("disable_transcriber")}
                      </p>
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default TranscriberPage;
