import { Word } from "../models";

export const reIndexWords = (words: Word[], rangeIndex: number) => {
  let lineIndex = 0;

  return words.map((word, i) => {
    if (words[i + 1] && words[i + 1].line_ix === word.line_ix) {
      word.line_ix = lineIndex;
    } else {
      word.line_ix = lineIndex;
      lineIndex++;
    }
    word.range_ix = rangeIndex;
    return word;
  });
};
