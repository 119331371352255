import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Steps.scss";

interface Props {
  steps: {
    label: string;
  }[];
  currentStep: number;
  className?: string;
}

const Steps: FC<Props> = ({ steps, currentStep, className }): JSX.Element => {
  const [width] = useState((steps.length - 1) * 150);

  return (
    <div className={classNames("StepsComponent", className)} style={{ width }}>
      <div className="stepsLine" style={{ width: width - 100 }} />
      {_.map(steps, (step, i) => {
        const isStepDone = currentStep > i;
        const isStepActive = currentStep === i;
        return (
          <div
            className={classNames("step", {
              done: isStepDone,
              active: isStepActive,
            })}
            key={i}
          >
            <div className="stepId">
              {isStepDone ? (
                <FontAwesomeIcon size="sm" icon={["fal", "check"]} />
              ) : (
                i + 1
              )}
            </div>
            <div className="stepLabel">{step.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
