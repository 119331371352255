import { Settings } from "../models/user";
import { updateSettings } from "../store/user/actions";

const defaultLanguage = localStorage.getItem("i18nextLng");

const defaultSettings = {
  order: "desc",
  orderBy: "deadline",
  autoSave: true,
  playerRewind: 2,
  playerForward: 2,
  playerRewindResume: 0,
  platformLanguage: defaultLanguage,
};

const setSettings = (
  key: string,
  value: string | number | boolean,
  dispatch: any
) => {
  const settings = localStorage.getItem("settings");
  if (settings) {
    let newSettings: Settings = JSON.parse(settings);
    newSettings = { ...newSettings, [key]: value };
    dispatch(updateSettings(newSettings));
    localStorage.setItem("settings", JSON.stringify(newSettings));
  } else {
    const newSettings: Settings = { [key]: value };
    dispatch(updateSettings(newSettings));
    localStorage.setItem("settings", JSON.stringify(newSettings));
  }
};

const getSettings = (): Settings => {
  const savedSettings = localStorage.getItem("settings");
  const savedSettingsObj: Settings = JSON.parse(savedSettings || "{}");

  return {
    ...defaultSettings,
    ...savedSettingsObj,
  } as Settings;
};

const clearLocalStorage = ({ preserveSettings = false }) => {
  if (preserveSettings) {
    const settings = getSettings();
    localStorage.clear();
    localStorage.setItem("settings", JSON.stringify(settings));
  } else {
    localStorage.clear();
  }
};
export { setSettings, getSettings, clearLocalStorage };
