import React, { FC } from "react";
import classNames from "classnames";

interface Props {
  label?: string;
  className?: string;
}

const LabelField: FC<Props> = ({ label, children, className }) => {
  return (
    <div className={classNames("labelField", className)}>
      {label && <div className="label">{label}</div>}
      {children}
    </div>
  );
};

export default LabelField;
