function downloadContent(content: any, type: string, destination: string) {
  const link = document.createElement("a");
  const file = new Blob([content], { type });

  link.href = URL.createObjectURL(file);
  link.download = destination;

  link.click();
  URL.revokeObjectURL(link.href);
}

export { downloadContent };
