import { RoomData } from "../../models/room.js";
import meetingsMock from "./meetingsMock.js";
import { userDataMock } from "./userDataMock.js";
import roomDataMock from "./roomDataMock.js";
import transcribersMock from "./transcribersMock.js";

class FirebaseMock {
  private static instance: FirebaseMock;
  public verifier: true;
  public isTest = true;
  public getRoomData = async (id: "_sun4gsrxv" | "_w9qlh3gjcu") => {
    try {
      return roomDataMock[id];
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };
  public auth = () => {
    return {
      onAuthStateChanged: () => true,
      settings: { appVerificationDisabledForTesting: true },
      RecaptchaVerifie: true,
    };
  };
  public saveMeeting = async (roomData: RoomData) => {
    const { words, assets, ...room } = roomData;
    return room;
  };
  public getUser = async () => {
    return userDataMock;
  };
  public cancelMeetingProcess = async () => {
    return { success: true };
  };
  public getAvailableMeetings = async (): Promise<any[]> => {
    return new Promise((resolve, reject) => resolve(meetingsMock));
  };
  public getMinimalTranscribers = async () => {
    return transcribersMock;
  };
  public getAuthenticatedUserInfo = async (userId: string) => {
    return userDataMock;
  };
  public openNotificationsPipe = async () => {
    return;
  };
  public static getInstance = () => {
    if (!FirebaseMock.instance) FirebaseMock.instance = new FirebaseMock();
    return FirebaseMock.instance;
  };
}

export default FirebaseMock.getInstance();
