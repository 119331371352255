import React, { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch } from "react-redux";

import {
  Button,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";

import FirebaseService from "../../services/FirebaseService";
import ExportService from "../../services/ExportService";

import { BookPager } from "../../components/common/V3/BookPager/BookPager";

import { JobData } from "../../models/job";
import { popIndicator } from "../../store/system/actions";
import { getLanguageDirection } from "../../utils/locales";

import {
  Page,
  PageList,
  PagerMenu,
  KeyOfPageList,
  PageSlice,
} from "../../components/common/V3/PagerMenu/PagerMenu";

import { GeneralPage } from "./settingPages/GeneralPage";
import { HeaderFooterPage } from "./settingPages/HeaderFooterPage";
import { InformationPage } from "./settingPages/InformationPage";
import { TableOfSubjectsPage } from "./settingPages/TableOfSubjectsPage";
import { RemarksPage } from "./settingPages/RemarksPage";
import { SpeakersPage } from "./settingPages/SpeakersPage";
import { SubjectsPage } from "./settingPages/SubjectsPage";
import { DecisionsPage } from "./settingPages/DecisionsPage";
import { NotesPage } from "./settingPages/NotesPage";
import { TasksPage } from "./settingPages/TasksPage";
import { ImagesPage } from "./settingPages/ImagesPage";
import { FocusedDocumentPage } from "./settingPages/FocusedDocumentPage";

import {
  DecisionsPageSlice,
  ExportConfigData,
  FocusedDocumentPageSlice,
  GeneralPageSlice,
  HeaderFooterPageSlice,
  ImagesPageSlice,
  InformationPageSlice,
  KeyOfExportConfigData,
  NotesPageSlice,
  RemarksPageSlice,
  SpeakersPageSlice,
  SubjectsPageSlice,
  TableOfSubjectsPageSlice,
  TasksPageSlice,
} from "./ExportConfigTypes";

import { Align } from "../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";

import { Preview } from "./Preview";

import Logger from "../../services/Logger";

import "./ExportConfig.scss";

const logger = Logger("ExportConfig");

export interface ExportConfigProps {
  exportConfig: ExportConfigData;
  jobsData: JobData[];
  handleSaveExportConfig?: (
    exportConfig: ExportConfigData,
    isNew?: boolean
  ) => void;
  presets?: { id: string; config: ExportConfigData }[];
  modalHeight?: boolean;
  allowExport?: boolean;
  allowSave?: boolean;
  allowSaveNew?: boolean;
}

const ExportConfig = ({
  exportConfig,
  handleSaveExportConfig,
  jobsData,
  modalHeight,
  allowExport = true,
  allowSave = true,
  allowSaveNew = true,
  presets,
}: ExportConfigProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState<string>("general");
  const [docDir, setDocDir] = useState<"rtl" | "ltr">("ltr");
  const [currExportConfig, setCurrExportConfig] = useState(exportConfig);
  const [selectedPreset, setSelectedPreset] = useState<string>("");

  const handlePresetSelect = (preset: {
    id: string;
    config: ExportConfigData;
  }) => {
    setCurrExportConfig(preset.config);
    setSelectedPreset(preset.id);
  };

  const handleSave = async (isNew?: boolean) => {
    try {
      if (!handleSaveExportConfig) return;
      setIsLoading(true);
      const isValid = await checkFormValidation();
      if (!isValid) throw new Error("Form is not valid!");
      const newExportConfigData = getValuesFromForms();
      handleSaveExportConfig(newExportConfigData, isNew);
      dispatch(
        popIndicator({
          type: "success",
          txt: t("saved"),
        })
      );
    } catch (err: any) {
      logger.error(err);
      dispatch(
        popIndicator({
          type: "failure",
          txt: err.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportFile = async () => {
    try {
      setIsLoading(true);
      const docxExportConfigData = getValuesFromForms();
      await ExportService.docxExport(jobsData, docxExportConfigData);
    } catch (err) {
      logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const logoUpload = async (file: File): Promise<string> => {
    return await FirebaseService.imageUploadForDocx(file);
  };

  //FORMS SETTINGS START
  const generalSchema = yup.object().shape({
    presetName: yup.string().required(),
    format: yup.string().required(),
    interval: yup.number().when("format", {
      is: "intervals",
      then: yup.number().min(0.5).required("Interval is required"),
    }),
    timeOffset: yup.string(),
    font: yup.object({
      fontFamily: yup.string().required(),
      fontSize: yup.number().required(),
      align: yup.mixed<keyof typeof Align>(),
    }),
    rowCount: yup.boolean(),
  });

  const general = useForm<GeneralPageSlice>({
    resolver: yupResolver(generalSchema),
    defaultValues: new GeneralPageSlice(),
    mode: "onChange",
  });

  const headerFooterSchema = yup.object().shape({
    footer: yup.object({
      footerText: yup.string(),
    }),
  });

  const headerFooter = useForm<HeaderFooterPageSlice>({
    resolver: yupResolver(headerFooterSchema),
    defaultValues: new HeaderFooterPageSlice(),
    mode: "onChange",
  });

  const information = useForm<InformationPageSlice>({
    defaultValues: new InformationPageSlice(),
  });

  const tableOfSubjects = useForm<TableOfSubjectsPageSlice>({
    defaultValues: new TableOfSubjectsPageSlice(),
  });

  const remarks = useForm<RemarksPageSlice>({
    defaultValues: new RemarksPageSlice(),
  });

  const subjects = useForm<SubjectsPageSlice>({
    defaultValues: new SubjectsPageSlice(),
  });

  const speakers = useForm<SpeakersPageSlice>({
    defaultValues: new SpeakersPageSlice(),
  });

  const decisions = useForm<DecisionsPageSlice>({
    defaultValues: new DecisionsPageSlice(),
  });

  const notes = useForm<NotesPageSlice>({
    defaultValues: new NotesPageSlice(),
  });

  const tasks = useForm<TasksPageSlice>({
    defaultValues: new TasksPageSlice(),
  });

  const images = useForm<ImagesPageSlice>({
    defaultValues: new ImagesPageSlice(),
  });

  const focusedDocument = useForm<FocusedDocumentPageSlice>({
    defaultValues: new FocusedDocumentPageSlice(),
  });
  //FORMS SETTINGS END

  const getValuesFromForms = (): ExportConfigData => {
    return {
      general: general.getValues(),
      headerFooter: headerFooter.getValues(),
      information: information.getValues(),
      tableOfSubjects: tableOfSubjects.getValues(),
      remarks: remarks.getValues(),
      subjects: subjects.getValues(),
      speakers: speakers.getValues(),
      decisions: decisions.getValues(),
      notes: notes.getValues(),
      tasks: tasks.getValues(),
      images: images.getValues(),
      focusedDocument: focusedDocument.getValues(),
    };
  };

  const pageList: PageList = {
    general: {
      form: general as UseFormReturn<PageSlice>,
      name: t("export_config_general_title"),
      slice: GeneralPageSlice as new (args: PageSlice) => PageSlice,
      component: <GeneralPage form={general} />,
    },
    headerFooter: {
      form: headerFooter as UseFormReturn<PageSlice>,
      name: t("export_config_header_footer_title"),
      slice: HeaderFooterPageSlice as new (args: PageSlice) => PageSlice,
      component: (
        <HeaderFooterPage form={headerFooter} logoUpload={logoUpload} />
      ),
    },
    information: {
      form: information as UseFormReturn<PageSlice>,
      name: t("export_config_information_title"),
      slice: InformationPageSlice as new (args: PageSlice) => PageSlice,
      component: <InformationPage form={information} />,
    },
    tableOfSubjects: {
      form: tableOfSubjects as UseFormReturn<PageSlice>,
      name: t("export_config_tableOfSubjects_title"),
      slice: TableOfSubjectsPageSlice as new (args: PageSlice) => PageSlice,
      component: <TableOfSubjectsPage form={tableOfSubjects} />,
    },
    remarks: {
      form: remarks as UseFormReturn<PageSlice>,
      name: t("export_config_remarks_title"),
      slice: RemarksPageSlice as new (args: PageSlice) => PageSlice,
      component: <RemarksPage form={remarks} />,
    },
    subjects: {
      form: subjects as UseFormReturn<PageSlice>,
      name: t("export_config_subjects_title"),
      slice: SubjectsPageSlice as new (args: PageSlice) => PageSlice,
      component: <SubjectsPage form={subjects} />,
    },
    speakers: {
      form: speakers as UseFormReturn<PageSlice>,
      name: t("export_config_speakers_title"),
      slice: SpeakersPageSlice as new (args: PageSlice) => PageSlice,
      component: <SpeakersPage form={speakers} />,
    },
    decisions: {
      form: decisions as UseFormReturn<PageSlice>,
      name: t("export_config_decisions_title"),
      slice: DecisionsPageSlice as new (args: PageSlice) => PageSlice,
      component: <DecisionsPage form={decisions} />,
    },
    notes: {
      form: notes as UseFormReturn<PageSlice>,
      name: t("export_config_notes_title"),
      slice: NotesPageSlice as new (args: PageSlice) => PageSlice,
      component: <NotesPage form={notes} />,
    },
    tasks: {
      form: tasks as UseFormReturn<PageSlice>,
      name: t("export_config_tasks_title"),
      slice: TasksPageSlice as new (args: PageSlice) => PageSlice,
      component: <TasksPage form={tasks} />,
    },
    images: {
      form: images as UseFormReturn<PageSlice>,
      name: t("export_config_images_title"),
      slice: ImagesPageSlice as new (args: PageSlice) => PageSlice,
      component: <ImagesPage form={images} />,
    },
    focusedDocument: {
      form: focusedDocument as UseFormReturn<PageSlice>,
      name: t("export_config_focusedDocument_title"),
      slice: FocusedDocumentPageSlice as new (args: PageSlice) => PageSlice,
      component: <FocusedDocumentPage form={focusedDocument} />,
    },
  };

  const checkFormValidation = async (): Promise<boolean> => {
    let falseCounter = 0;
    await Promise.all(
      Object.entries(pageList).map(async (entry: [string, Page]) => {
        const trigger = entry[1].form.trigger;
        if (!trigger) {
          falseCounter++;
          return;
        }
        const isValid = await trigger();
        if (!isValid) {
          falseCounter++;
        }
      })
    );
    return falseCounter === 0;
  };

  //INJECTING DATA TO FORMS
  useEffect(() => {
    if (!currExportConfig) return;
    _.each(pageList, (page, key) => {
      const formData = currExportConfig[key as KeyOfExportConfigData];
      const slice: new (args: PageSlice) => PageSlice =
        pageList[key as KeyOfPageList].slice;
      if (formData && page.form.reset) {
        page.form.reset(new slice(formData));
      }
    });
  }, [currExportConfig]);

  useEffect(() => {
    if (!jobsData) return;
    const jobData = jobsData[0];
    const docLangOutput = jobData?.lang?.output[0]
      ? jobData?.lang?.output[0]
      : "en";
    const direction = getLanguageDirection(docLangOutput);
    setDocDir(direction);
  }, [jobsData]);

  useEffect(() => {
    if (presets?.length) {
      setSelectedPreset(presets[0].id);
    }
  }, [presets]);
  return (
    <div className="ExportConfig">
      <BookPager
        modalHeight={modalHeight}
        areaA={
          <PagerMenu
            pageList={pageList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        }
        areaB={
          jobsData[0] && (
            <Preview
              pageList={pageList}
              jobData={jobsData[0]}
              docDir={docDir}
            />
          )
        }
        areaActions={
          <>
            {allowSave && (
              <Button
                className={"actionButton"}
                onClick={() => handleSave(false)}
              >
                {t("export_configurations_save")}
              </Button>
            )}
            {allowExport && (
              <Button
                className={"actionButton exportButton"}
                onClick={handleExportFile}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loading-spinner">
                    <CircularProgress style={{ width: 20, height: 24 }} />
                  </div>
                ) : (
                  t("export_docx_file")
                )}
              </Button>
            )}
            {allowSaveNew && (
              <Button
                className={"actionButton"}
                onClick={() => handleSave(true)}
              >
                {t("export_configurations_save_as_new")}
              </Button>
            )}
            {presets && presets.length > 0 ? (
              <FormControl variant="outlined" className="presetsSelect">
                <Select id="presetSelect" value={selectedPreset}>
                  {presets
                    .map((p) => ({
                      ...p,
                      name: p.config.general.presetName,
                    }))
                    .map((p, i) => (
                      <MenuItem
                        key={i}
                        value={p.id}
                        onClick={() => {
                          handlePresetSelect(p);
                        }}
                      >
                        {t("export_configurations_presets")}: {p.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
          </>
        }
      ></BookPager>
    </div>
  );
};

export default ExportConfig;
