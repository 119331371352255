import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./FilesDropZone.scss";

interface Props {
  title: string;
  handleFiles: (
    files: React.ChangeEvent<HTMLInputElement>,
    isDroppedFile: boolean
  ) => void;
}

const FilesDropZone: FC<Props> = ({ handleFiles, title }): JSX.Element => {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles) handleFiles(acceptedFiles, true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ className: "drop-zone" })}>
      <input {...getInputProps()} />
      <h1>{title}</h1>
      <div className="icn-wrapper">
        <FontAwesomeIcon icon={["fal", "folder-open"]} />
      </div>
    </div>
  );
};

export default FilesDropZone;
