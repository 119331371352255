import React, { FC, useState, useEffect, useRef } from "react";
import firebase from "firebase";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { AppState } from "../../store/rootReducer";
import AuthService from "../../services/AuthService";

import PasswordRecoveryStep from "./PasswordRecoveryStep";
import BackButton from "../../components/common/BackButton/BackButton";

import "./AuthPages.scss";

const PasswordRecoveryPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  useEffect(() => {
    AuthService.initLogin();
  });

  return (
    <div className="PasswordRecoveryPage">
      <div className="authContainer">
        <div className="headerTitle">{t("password_recovery_title")}</div>
        <div className="note">
          <div className="noteBody">
            {t("password_recovery_note")
              .split("/n")
              .map((l, i) => (
                <div className="noteLine" key={i}>
                  {l}
                </div>
              ))}
          </div>
        </div>
        <PasswordRecoveryStep />
        <BackButton
          backButton={{
            title: t("back"),
            backAction: history.goBack,
          }}
        />
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};

export default PasswordRecoveryPage;
