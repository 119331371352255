import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import FirebaseService from "./FirebaseService";
import {
  PaymentsData,
  PaymentMonthPreviewData,
  Debt,
  Debt1,
  PaidApproval,
  InvoiceRef,
} from "../models/payment";

import { MeetingPreviewData } from "../models";
import CloudFunctionsService from "./CloudFunctionsService";
import TimeService from "./TimeService";

const addDebts = async (debtsToAdd: Debt1 | Debt1[]): Promise<void> => {
  const debts = !_.isArray(debtsToAdd) ? [debtsToAdd] : debtsToAdd;
  try {
    await CloudFunctionsService.debts.addDebts(debts);
  } catch (err) {
    console.log("Failed to add debt", err);
  }
};

const getUserDebt = async (userId: string) => {
  try {
    const debtsDocs = await FirebaseService.colRefs.debts
      .where("userId", "==", userId)
      .orderBy("creationTime", "desc")
      .get();
    const debtByMonth: { [key: string]: { [key: string]: any[] } } = {};
    const debts = await Promise.all(
      debtsDocs.docs.map(async (debt) => {
        const debtData = debt.data();

        const debtDate = debtData.date
          ? debtData.date.toDate()
          : debtData.creationTime.toDate();

        const debtMonth = format(debtDate, "MMM").toLowerCase();
        const debtYear = format(debtDate, "yyy").toLowerCase();
        if (!debtByMonth[debtYear]) {
          debtByMonth[debtYear] = {};
        }
        if (!debtByMonth[debtYear][debtMonth]) {
          debtByMonth[debtYear][debtMonth] = [];
        }

        if (debtData.roomId) {
          const jobRef = await FirebaseService.db
            .collection("rooms-v2")
            .doc(debtData.roomId)
            .get();
          const job = jobRef.data();
          const jobLength = job && job.meetingLength;
          const formatedJobLength = jobLength
            ? TimeService.getTimeStringFromSecs(jobLength)
            : "-";
          debtByMonth[debtYear][debtMonth].push({
            ...debtData,
            id: debt.id,
            jobLength: formatedJobLength,
            jobLengthSeconds: jobLength || 0,
          });
          return {
            ...debtData,
            id: debt.id,
            jobLength: formatedJobLength,
            jobLengthSeconds: jobLength || 0,
          };
        } else {
          debtByMonth[debtYear][debtMonth].push({ ...debtData, id: debt.id });
          return { ...debtData, id: debt.id };
        }
      })
    );

    const sum = Number(
      _.sumBy(debts as Debt[], (d: Debt) =>
        Number(Number(d.sum) + (d.bonus ? Number(d.bonus) : 0))
      ).toFixed(1)
    );
    return {
      sum,
      debts,
      debtByMonth,
    };
  } catch (err) {
    console.log("getUserDebts failed.", err);
  }
};

const completeMeetingDebts = async (
  meeting: MeetingPreviewData
): Promise<void> => {
  await CloudFunctionsService.debts.completeJobDebts(meeting.id);
};

const addTranscriberPendingDebt = async (
  meeting: MeetingPreviewData,
  description?: string
) => {
  const transcriberDebt = {
    userId: meeting.assignedTranscriber ? meeting.assignedTranscriber.id : null,
    sum: meeting.price,
    description: description || `Transcriber | ${meeting.name}`,
    roomId: meeting.id,
    creationTime: new Date(),
    status: "pending",
  } as Debt1;

  await addDebts(transcriberDebt);
};

const completeTranscriberPendingDebt = async (
  userId: string,
  meetingId: string
) => {
  try {
    const transcriberPendingDebtRef = await FirebaseService.colRefs.debts
      .where("uid", "==", userId)
      .where("roomId", "==", meetingId)
      .where("status", "==", "pending")
      .get();

    if (transcriberPendingDebtRef.docs.length > 1) {
      throw new Error("Multiple debst for pendingTranscriberDebt");
    }

    const debtDoc = FirebaseService.colRefs.debts.doc(
      transcriberPendingDebtRef.docs[0].id
    );
    await debtDoc.update({
      status: "unpaid",
    });
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
};

const _getMeetingProoferPrice = async (
  meeting: MeetingPreviewData
): Promise<number> => {
  const prooferPaymentModelDoc = await FirebaseService.colRefs.configs
    .doc("payment-models")
    .get();
  const prooferPaymentModelData = prooferPaymentModelDoc.data();
  if (!prooferPaymentModelData) throw new Error("Failed to get payment models");
  return (
    (meeting.meetingLength / 60) *
    prooferPaymentModelData.proofer[meeting.previewFormat].multiplier
  );
};

const getAllTranscribersDebts = async () => {
  try {
    const debts = await CloudFunctionsService.debts.getDebtsByUser();
    return debts;
  } catch (err) {
    console.log("getAllTranscribersDebts failed");
    throw new Error(err);
  }
};

function getPaymentsByMonths(
  payments: PaymentsData
): PaymentMonthPreviewData[] {
  payments.debts = payments.debts.sort((debt1: Debt, debt2: Debt) => {
    if (debt1.createdAt > debt2.createdAt) return -1;
    else if (debt1.createdAt < debt2.createdAt) return 1;
    else return 0;
  });
  const paymentsByMonths: PaymentMonthPreviewData[] = [];
  payments.debts.forEach((debt: Debt) => {
    const debtMonth = format(debt.createdAt, "MMM");
    const debtYear = format(debt.createdAt, "yyyy");
    const monthIdx = paymentsByMonths.findIndex(
      (paymentMonth) =>
        debtYear === paymentMonth.year && debtMonth === paymentMonth.month
    );
    if (monthIdx < 0) {
      const debts = [debt];
      paymentsByMonths.push({
        year: debtYear,
        month: debtMonth,
        debts,
        sum: debt.sum,
      });
    } else {
      paymentsByMonths[monthIdx].debts.push(debt);
      paymentsByMonths[monthIdx].sum =
        paymentsByMonths[monthIdx].sum + debt.sum;
    }
  });
  return paymentsByMonths;
}

function checkIfPaid(
  paymentsByMonths: PaymentMonthPreviewData[],
  paidApprovals: PaidApproval[]
): PaymentMonthPreviewData[] {
  paymentsByMonths = paymentsByMonths.map((paymentMonth) => {
    let wasPaid = false;
    paidApprovals.forEach((approval) => {
      if (approval.paidFor === paymentMonth.month + "-" + paymentMonth.year)
        wasPaid = true;
    });
    paymentMonth.wasPaid = wasPaid;
    return paymentMonth;
  });
  return paymentsByMonths;
}

function getInvoiceUrls(
  paymentsByMonths: PaymentMonthPreviewData[],
  invoiceRefs: InvoiceRef[]
): PaymentMonthPreviewData[] {
  paymentsByMonths = paymentsByMonths.map((paymentMonth) => {
    let invoice = undefined;
    invoiceRefs.forEach((invoiceRef) => {
      if (invoiceRef.month === paymentMonth.month + "-" + paymentMonth.year)
        invoice = invoiceRef.url;
    });
    paymentMonth.invoiceUrl = invoice;
    return paymentMonth;
  });
  return paymentsByMonths;
}

function getCurrDebt(payments: PaymentsData, type: string): number {
  let currDebt = 0;
  if (type === "user") {
    let paymentsByMonths = getPaymentsByMonths(payments);
    paymentsByMonths = checkIfPaid(paymentsByMonths, payments.paidApprovals);
    paymentsByMonths.forEach((payment) => {
      if (!payment.wasPaid) currDebt = currDebt + payment.sum;
    });
  } else if (type === "client") {
    payments.debts.forEach((debt) => {
      if (!debt.wasPaid) currDebt = currDebt + debt.sum;
    });
  }
  return currDebt;
}

export default {
  getPaymentsByMonths,
  checkIfPaid,
  getInvoiceUrls,
  getCurrDebt,
  addDebts,
  addTranscriberPendingDebt,
  getUserDebt,
  completeMeetingDebts,
  getAllTranscribersDebts,
};
