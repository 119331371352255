import React, { FC, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";

import classNames from "classnames";
import Dropdown from "../common/Dropdown/Dropdown";

import Switch from "@material-ui/core/Switch";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";

import { setSettings } from "../../services/SettingService";
import FeatureFlagsService from "../../services/FeatureFlagsService";

import Debugger from "../Debugger/Debugger";

import { menuConfig } from "./menuConfig";
import { version } from "../../../package.json";
import "./NavigationMenu.scss";

interface Option {
  label: string;
  value: string;
  icon: any;
}

interface Props {
  isMenuOpen: boolean;
  currUserRole: string | null;
  logOut: () => void;
  toggleMenu: () => void;
}

const NavigationMenu: FC<Props> = ({
  isMenuOpen,
  currUserRole,
  logOut,
  toggleMenu,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const userSettings = useSelector(
    (state: AppState) => state.userStore.settings
  );

  const mainElement = useRef<HTMLDivElement>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useOutsideClick(mainElement, () => {
    if (isMenuOpen) {
      toggleMenu();
    }
  });

  const handleToggleGroupClick = (key: string, value: string | number) => {
    setSettings(key, value, dispatch);
  };

  const handleSettingChange = (
    key: string,
    value: boolean | string | number
  ) => {
    setSettings(key, value, dispatch);
  };

  return (
    <div
      data-testid="side-navigation-menu"
      ref={mainElement}
      className={classNames("nav-menu", {
        open: isMenuOpen,
        settingsOpen: isSettingsOpen,
      })}
    >
      <div className="menuItems">
        {currUserRole &&
          menuConfig.nav
            .filter((item) => item.roles.includes(currUserRole as string))
            .map(
              (menuItem) =>
                (!menuItem.featureFlag ||
                  FeatureFlagsService.isEnabled(
                    menuItem.featureFlag,
                    loggedInUser
                  )) && (
                  <NavLink key={menuItem.path} exact to={menuItem.path}>
                    {t(menuItem.label)}
                  </NavLink>
                )
            )}
        <a
          data-testid="side-navigation-menu-logout-btn"
          onClick={() => {
            logOut();
          }}
        >
          {t("log_out")}
        </a>
      </div>

      <div className="versionContainer">
        <div className="version">v{version}</div>
        <div className="userSettingsButton">
          <FontAwesomeIcon
            icon={["fal", "cog"]}
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          />
        </div>
      </div>
      <div
        className={classNames("userSettingsContainer", {
          open: isSettingsOpen,
        })}
      >
        {currUserRole &&
          menuConfig.userSettings
            .filter((s) => s.roles.includes(currUserRole as string))
            .map((setting) => (
              <div className={`setting ${setting.label}`} key={setting.label}>
                <div className="settingLabel">{t(setting.label)}</div>
                {setting.type === "toggle" && (
                  <Switch
                    checked={_.get(userSettings, setting.key)}
                    onClick={() =>
                      handleSettingChange(
                        setting.key,
                        !_.get(userSettings, setting.key)
                      )
                    }
                  />
                )}
                {setting.type === "select" && (
                  <Dropdown
                    searchOption={false}
                    options={
                      _.filter(setting.options, (o: { permission: string[] }) =>
                        o.permission && loggedInUser
                          ? o.permission.includes(loggedInUser.role)
                          : true
                      ) as Option[]
                    }
                    className="languageDropdown"
                    placeholder={_.get(userSettings, setting.key)}
                    onChange={(value) =>
                      handleToggleGroupClick(setting.key, value as string)
                    }
                  ></Dropdown>
                )}
                {setting.type === "buttonGroup" && (
                  <ToggleButtonGroup
                    size="small"
                    value={_.get(userSettings, setting.key)}
                    exclusive
                    style={{ backgroundColor: "white" }}
                    onChange={(e, value) =>
                      handleToggleGroupClick(setting.key, value)
                    }
                  >
                    {_.map(setting.options, (option) => (
                      //@ts-ignore
                      <ToggleButton value={option} key={option}>
                        {option}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              </div>
            ))}
      </div>
    </div>
  );
};

export default NavigationMenu;
