export class SortingOptions {
  optionsArr: string[];

  constructor(type: string) {
    if (type === "meetings")
      this.optionsArr = [
        "by-deadline",
        "by-length",
        "by-members",
        "by-price",
        "by-progress",
      ];
    else if (type === "payments") this.optionsArr = ["by-date", "by-price"];
    else if (type === "transcribers/clients")
      this.optionsArr = ["by-title", "by-meetings-count", "by-price"];
    else if (type === "archive")
      this.optionsArr = ["by-date", "by-title", "by-owner"];
  }
}

export class Sorting {
  type: string;
  options: string[];
  sortBy: string;
  order: string;
}
