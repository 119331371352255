export enum ExportsTypes {
  docx,
  srt,
}

export class ExportOptions {
  subtitles: SubtitlesExportOptions;
  docx: DocxExportOptions;
}

class SubtitlesExportOptions {
  split? = false;
  autoBreak? = false;
  embed = false;
  flip: boolean;
  frameRate?: number;
}
export class DocxExportOptions {
  direction: "rtl" | "ltr" = "rtl";
  layout: "pages" | "minutes" | "intervals" = "pages";
  times = false;
  interval: number;
  timeOffset: string;
  startIntervalWithSpeakerName = false;
}
