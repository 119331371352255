import i18n from "../i18n";

export const getDefaultWords = () => {
  const DEFAULT_WORDS_STRING = i18n.t("default_job_words");

  return DEFAULT_WORDS_STRING.split(" ").map((w, i) => {
    const startTime = i * 0.3;
    const endTime = (i + 1) * 0.3;
    return {
      word: w,
      text: w,
      range_ix: 0,
      line_ix: 0,
      start: startTime,
      end: endTime,
      start_time: startTime,
      end_time: endTime,
      speaker: "speaker",
    };
  });
};
