import React, { FC, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TimeService from "../../services/TimeService";
import FirebaseService from "../../services/FirebaseService";

import { AppState } from "../../store/rootReducer";
import { updateMeetingDeadline } from "../../store/meeting/actions";
import { MeetingPreviewData, PreviewFormat } from "../../models";

import GenericTable, { TableConfig } from "../Table/GenericTable";
import EditableText from "../EditableText/EditableText";
import CircleProgress from "../common/CircleProgress/CircleProgress";

import "./MeetingsTable.scss";

interface Props {
  additionalSearchFields?: string[];
  data: { [key: string]: any }[];
  rawData?: any[];
  orderTable?: (property: string, descOrAsc: "desc" | "asc") => void;
  onRowClick?: (row: { [key: string]: any }, event: React.MouseEvent) => void;
  onSelection?: (jobs: any[]) => void;
  config?: Partial<TableConfig>;
}

const MeetingsTable: FC<Props> = ({
  additionalSearchFields,
  data,
  rawData,
  orderTable,
  onRowClick,
  onSelection,
  config,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector((state: AppState) => state.userStore.settings);
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const defaultConfig: TableConfig = {
    tableName: "table",
    tableColumns: [],
    columns: {
      processProgress: { label: "", width: "50px" },
      name: { label: "table_meeting_name", width: "400px", ellipsis: true },
      previewFormat: { label: "table_meeting_format", width: "120px" },
      member: { label: "community_members", width: "280px" },
      editProgress: { label: "edit_progress", width: "50px" },
      status: { label: "status", width: "100px" },
      job: { label: "status", width: "120px" },
      meetingLength: { label: "table_meeting_length", width: "120px" },
      creationTime: { label: "uploaded", width: "150px" },
      deadline: {
        label: "table_meeting_deadline",
        width: "150px",
        ellipsis: true,
      },
      archivedAt: { label: "archive_at" },
      assignedTranscriber: { label: "transcriber" },
      assignedProofer: { label: "proofer" },
      lang: { label: "table_meeting_lang", width: "130px", ellipsis: true },
      role: { label: "role" },
      price: { label: "table_meeting_price", width: "120px" },
      prooferPrice: { label: "table_meeting_proofer_price", width: "120px" },
      representative: {
        label: "representative",
        width: "160px",
        ellipsis: true,
      },
      revenue: { label: t("revenue"), width: "140px" },
      invoiceSent: { label: t("invoice_sent"), width: "70px" },
      actions: { label: "", width: "150px" },
      contextMenu: { label: " ", width: "80px" },
    },
    formatters: {
      name: (name, meeting) => (
        <div className="fileName">
          <div className="jobName" title={name}>
            {name}
          </div>
          {loggedInUser?.role.includes("super_user") && meeting.clientName && (
            <div className="clientName">{meeting.clientName}</div>
          )}
        </div>
      ),
      member: (assignTranscriber, meeting) => (
        <div className="memberCell">
          <div className="transcriberDiv">
            <span>{meeting.assignedTranscriber}</span>
            <div className="priceDiv">
              <span className="price">({meeting.price}</span>
              <span className="currency">{t("currency")})</span>
            </div>
          </div>
          <div className="prooferDiv">
            <span>{meeting.assignedProofer}</span>
            <div className="priceDiv">
              <span className="price">({meeting.prooferPrice}</span>
              <span className="currency">{t("currency")})</span>
            </div>
          </div>
        </div>
      ),
      job: (jobStatus: string) => (
        <div className="jobCell">
          <div className={classNames("jobStatusDiv", jobStatus)}>
            <span>{t(jobStatus)}</span>
          </div>
        </div>
      ),
      processProgressLastUpdate: (processProgressLastUpdate) =>
        processProgressLastUpdate &&
        format(processProgressLastUpdate, "dd/MM/yyyy"),
      editProgress: (editProgress) => (
        <CircleProgress progress={editProgress} />
      ),
      creationTime: (creationTime: Date, meeting) => (
        <>
          {meeting.uploadDate
            ? format(new Date(meeting.uploadDate), "dd/MM/yyyy - HH:mm")
            : "-"}
        </>
      ),
      deadline: (deadline: Date, meeting) => (
        <div className="deadlineCell">
          {loggedInUser?.role === "super_user" && meeting.clientDeadline && (
            <div className="clientDeadlineDiv">
              {t("c_deadline")}
              {format(meeting.clientDeadline, "dd/MM/yyyy - HH:mm")}
            </div>
          )}
          {meeting.deadline && (
            <div
              className={
                loggedInUser?.role === "super_user"
                  ? "deadlineDiv"
                  : "clientDeadlineDiv"
              }
            >
              {loggedInUser?.role === "super_user" && t("t_deadline")}
              {format(meeting.deadline, "dd/MM/yyyy - HH:mm")}
            </div>
          )}
        </div>
      ),
      previewFormat: (previewFormat: PreviewFormat, meeting) => (
        <div className="previewFormatCell">
          <div className="previewFormatDiv">{t(previewFormat)}</div>
          <div className="langDiv">{meeting.lang}</div>
        </div>
      ),
      price: (price: number, meeting) => {
        if (meeting.status === 4) {
          return meeting.prooferPrice
            ? `${meeting.prooferPrice} ${t("currency")}`
            : "-";
        } else {
          return price ? `${price} ${t("currency")}` : "-";
        }
      },
      prooferPrice: (price: number) =>
        price ? `${price} ${t("currency")}` : "-",
      status: (status: number) => (
        <div
          className={classNames(
            "status",
            { finished: status === 5 },
            { proofing: status === 4 },
            { transcribing: status === 3 }
          )}
        >
          <div>{status}</div>
        </div>
      ),
      invoiceSent: (invoiceSent: string) => {
        return (
          invoiceSent && (
            <div className={classNames("invoiceSent", invoiceSent)}>
              {t(invoiceSent)}
            </div>
          )
        );
      },
      archivedAt: (archived: Date) =>
        archived ? format(archived, "dd/MM/yyyy") : " - ",
    },
    order: settings["order"],
    orderBy: settings["orderBy"],
    multiSelect: false,
    faded: (row: { [key: string]: any }) => (row.status < 3 ? true : false),
    actions: {},
  };

  const [tableConfig, setTableConfig] = useState<any>();

  const filterFiledsColumns = () => {
    const mergedConfig = _.merge(defaultConfig, config);
    let columns = {};
    const allTableColumns = _.keys(mergedConfig.columns);
    for (let i = 0; i < allTableColumns.length; i++) {
      if (
        mergedConfig.tableColumns &&
        mergedConfig.tableColumns.includes(allTableColumns[i])
      ) {
        columns = {
          ...columns,
          [allTableColumns[i]]: mergedConfig.columns[allTableColumns[i]],
        };
      }
    }
    const newTableConfig = { ...mergedConfig, columns: columns };
    setTableConfig(newTableConfig);
    return { ...newTableConfig, columns: columns };
  };

  return (
    <div id="MeetingsTable" className="MeetingsTable">
      <GenericTable
        additionalSearchFields={additionalSearchFields}
        config={tableConfig ? tableConfig : filterFiledsColumns()}
        data={data}
        rawData={rawData}
        rowClassName={(j: any) => (j.v3id ? "v3" : "")}
        onRowClick={onRowClick}
        onSelection={onSelection}
        orderTable={orderTable}
      ></GenericTable>
    </div>
  );
};

export default MeetingsTable;
