import React, { FC } from "react";
import _ from "lodash";
import i18n from "../../i18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import Button from "../common/Button/Button";
import { getLanguageDirection } from "../../utils/locales";

import classNames from "classnames";

import "./MessageModal.scss";
interface Props {
  showModal: boolean;
  title: string | JSX.Element;
  body: string | JSX.Element | JSX.Element[];
  approve?: {
    text: string;
    action: () => void | Promise<void>;
  };
  cancel?: {
    text: string;
    action: () => void | Promise<void>;
  };
  buttons?: {
    text: string;
    action: () => void | Promise<void>;
  }[];
  className?: string;
  isApproveDisabled?: boolean;
  isLoading?: boolean;
}

const MessageModal: FC<Props> = ({
  showModal,
  title,
  body,
  approve,
  cancel,
  buttons,
  className,
  isApproveDisabled,
  isLoading,
}): JSX.Element => {
  return (
    <Dialog
      className={getLanguageDirection(i18n.language)}
      classes={{ paper: classNames("MessageModal", className) }}
      open={showModal}
    >
      <DialogTitle>
        {_.isElement(title) ? title : <div className="modalTitle">{title}</div>}
      </DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        {_.isElement(body) ? body : <div className="modalBody">{body}</div>}
      </DialogContent>
      <DialogActions>
        <div className="buttons">
          {!_.isEmpty(buttons)
            ? _.map(buttons, (btn) => (
                <button onClick={btn.action} key={btn.text}>
                  {btn.text}
                </button>
              ))
            : [
                cancel && (
                  <Button
                    className="button cancel"
                    onClick={cancel.action}
                    key={1}
                    label={cancel.text}
                    loading={isLoading}
                  ></Button>
                ),
                approve && (
                  <Button
                    className="button approve"
                    disabled={isApproveDisabled}
                    // variant="contained"
                    onClick={approve.action}
                    key={2}
                    label={approve.text}
                    loading={isLoading}
                  ></Button>
                ),
              ]}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MessageModal;
