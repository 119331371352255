import { MeetingPreviewData, ElasticJob } from "../../models/meeting";

export interface MeetingState {
  meetings: MeetingPreviewData[];
  myJobs: MeetingPreviewData[];
}

export const SET_MEETINGS = "SET_MEETINGS";
export const UPDATE_MEETING = "UPDATE_MEETING";
export const CLEAR_MEETINGS = "CLEAR_MEETINGS";
export const REMOVE_MEETING = "REMOVE_MEETING";
export const UPDATE_TANSCRIBER_PRICE = "UPDATE_TANSCRIBER_PRICE";
export const UPDATE_MEETING_PRICE = "UPDATE_MEETING_PRICE";
export const UPDATE_MEETING_DEADLINE = "UPDATE_MEETING_DEADLINE";

interface SetMeetings {
  type: typeof SET_MEETINGS;
  meetings: MeetingPreviewData[] | ElasticJob[];
  meetingPage: string;
}

interface UpdateMeeting {
  type: typeof UPDATE_MEETING;
  meeting: MeetingPreviewData | ElasticJob;
  meetingPage: string;
}

interface UpdatePrice {
  type: typeof UPDATE_MEETING_PRICE;
  meeting: MeetingPreviewData;
  price: number;
  priceType: "price" | "prooferPrice";
}

interface UpdateDeadline {
  type: typeof UPDATE_MEETING_DEADLINE;
  meetingId: string;
  deadline: Date;
}

interface ClearMeetings {
  type: typeof CLEAR_MEETINGS;
  meetingPage: string;
}

interface RemoveMeeting {
  type: typeof REMOVE_MEETING;
  meetingId: string;
  meetingPage: string;
}

export type MeetingActionTypes =
  | SetMeetings
  | ClearMeetings
  | RemoveMeeting
  | UpdateMeeting
  | UpdateDeadline
  | UpdatePrice;
