import "date-fns";
import React, { FC, useState, useRef } from "react";
import _ from "lodash";
import he from "date-fns/locale/he";
import en from "date-fns/locale/en-US";
import DatePicker from "react-datepicker";
import i18n from "../../../i18n";

interface Props {
  dates?: Date[];
  className?: string;
  placeholder?: string;
  onDateChange?: (value: Date[]) => void;
}

const RangeDatePicker: FC<Props> = ({
  dates,
  className,
  placeholder,
  onDateChange,
}) => {
  const [startDate, setStartDate] = useState<Date>(
    dates ? dates[0] : new Date()
  );
  const [endDate, setEndDate] = useState<null | Date>(dates ? dates[1] : null);
  const onChange = (dates: any) => {
    onDateChange && onDateChange(dates);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="DatePicker">
      <DatePicker
        locale={i18n.language.includes("he") ? he : en}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        disabledKeyboardNavigation
      />
    </div>
  );
};

export default RangeDatePicker;
