import React, { useEffect, useState } from "react";
import _ from "lodash";

import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  defaultValidationConfig,
  KeyOfValidationsFormPageSlice,
  SettingPagesProps,
  ValidationsFormPageSlice,
} from "../ValidationsConfigTypes";

import { TextInput } from "../../../components/common/V3/TextInput/TextInput";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";

import "../SettingPages.scss";

export const ValidationsConfigForm = ({
  formValues,
  availableValidations,
  lang,
  handleFormChange,
}: SettingPagesProps<ValidationsFormPageSlice>) => {
  const { t } = useTranslation();

  const genericSchema = yup.object().shape({
    isLessThanFramesBetweenRanges: yup.object({
      minFrames: yup.number().required(),
    }),
    isLessThanFrames: yup.object({
      minFrames: yup.number().required(),
    }),
    isMoreThanCharsInRange: yup.object({
      maxCharsInRange: yup.number().required(),
    }),
    isMoreThanLines: yup.object({
      maxLines: yup.number().required(),
    }),
    isMoreThanCharsInLine: yup.object({
      maxCharsInLine: yup.number().required(),
    }),
    isMoreThanCharsPerSecond: yup.object({
      maxChars: yup.number().required(),
    }),
    isMoreThanSeconds: yup.object({
      maxSeconds: yup.number().required(),
    }),
  });

  const form = useForm<ValidationsFormPageSlice>({
    resolver: yupResolver(genericSchema),
    defaultValues: new ValidationsFormPageSlice(formValues),
    mode: "onChange",
  });

  const validationsWatch: ValidationsFormPageSlice = form?.watch() as ValidationsFormPageSlice;

  const control = form?.control;
  const setValue = form?.setValue;
  const errors = form?.formState.errors;

  const getActiveValidations = () => {
    const _activeValidations: { [v: string]: boolean } = {};
    for (const validationKey in availableValidations) {
      _activeValidations[validationKey] = validationsWatch[
        validationKey as KeyOfValidationsFormPageSlice
      ]
        ? true
        : false;
    }
    return _activeValidations;
  };

  const [activeValidations, setActiveValidations] = useState(
    getActiveValidations()
  );

  const generateFormFields = () => {
    return _.keys(availableValidations)
      .sort()
      .map((validationKey) => {
        const availableValidation = availableValidations[validationKey];
        const parameters = Object.keys(availableValidation);
        return (
          <div className="section" key={validationKey}>
            <div className="row">
              <div className="input-title">
                {t(`validation_${validationKey}`)}:
              </div>
              <SwitchInput
                checked={activeValidations[validationKey]}
                onChange={(value) => {
                  setActiveValidations({
                    ...activeValidations,
                    [validationKey]: value,
                  });
                  if (value === false) {
                    setValue(
                      validationKey as KeyOfValidationsFormPageSlice,
                      false
                    );
                  } else {
                    const validationValue = {};
                    parameters.map((parameter) => {
                      setValue(
                        `${validationKey}.${parameter}` as KeyOfValidationsFormPageSlice,
                        availableValidation[parameter] === "number"
                          ? _.get(
                              defaultValidationConfig,
                              `${validationKey}.${parameter}`
                            )
                          : ""
                      );
                    });
                    if (parameters.length === 0) {
                      // @ts-ignore
                      setValue(validationKey, validationValue);
                    }
                  }
                }}
              />
            </div>
            {parameters.map((parameter) => (
              <div className="column indentation-level-1" key={parameter}>
                <div className="input-sub-title">
                  {t(`validation_${parameter.replace(".", "_")}`)}:
                </div>
                {activeValidations[validationKey] ? (
                  <ControllerHOC
                    control={control}
                    name={`${validationKey}.${parameter}`}
                    errors={errors}
                    setValue={setValue}
                  >
                    <TextInput min={0} type={availableValidation[parameter]} />
                  </ControllerHOC>
                ) : (
                  <TextInput
                    type={availableValidation[parameter]}
                    disabled={!activeValidations[validationKey]}
                  />
                )}
              </div>
            ))}
          </div>
        );
      });
  };

  return (
    <form onChange={() => handleFormChange(form.getValues(), lang)}>
      <div className="ValidationsConfigForm">
        <div className="section">{generateFormFields()}</div>
      </div>
    </form>
  );
};
