import React, { FC, useState, useEffect, useRef } from "react";
import config from "../../config";
import _ from "lodash";
import firebase from "firebase";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

import AuthService from "../../services/AuthService";
import FirebaseService from "../../services/FirebaseService";

import AuthProvidersButtons from "../../components/AuthProvidersButtons/AuthProvidersButtons";
import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";
import Dropdown from "../../components/common/Dropdown/Dropdown";
import Datepicker from "../../components/common/Datepicker/Datepicker";

import { getFormattedPhone } from "../../utils/formatters";
import { getCountries, getLanguages } from "../../utils/locales";
import Logger from "../../services/Logger";

import "./AuthPages.scss";

const logger = Logger("SignUpStep");
interface Props {
  onSignUp?: (email: string, password: string) => void;
  onProviderSignUp?: () => void;
}

type SignUpInputs = {
  name: string;
  country: string;
  languages: string[];
  birthday: Date;
  email: string;
  password: string;
  passwordConfirm: string;
};

const SignUpStep: FC<Props> = ({ onSignUp }): JSX.Element => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [signUpError, setSignUpError] = useState("");

  const [countries] = useState(getCountries(t));
  const [languages] = useState(getLanguages(t));

  const handleSignUp = async (data: SignUpInputs) => {
    try {
      setIsLoading(true);
      await AuthService.signUp({
        ...data,
      });
      if (onSignUp) {
        await onSignUp(data.email, data.password);
      }
    } catch (err: any) {
      logger.error(err, "handleSignUp");
      setSignUpError(t(err));
    } finally {
      setIsLoading(false);
      await AuthService.initLogin();
    }
  };

  const handleProviderSignUp = async (provider: "google" | "facebook") => {
    try {
      setIsLoading(true);
      setSignUpError("");
      const login = await AuthService.signUpWithProvider(provider);
      if (login.isNew && login.user.user) {
        login.user.user.getIdToken(true);
      }
    } catch (err: any) {
      logger.error(err, "handleProviderSignUp");
      setSignUpError(t(err));
    } finally {
      setIsLoading(false);
      location.reload();
    }
  };

  const signUpProviders = [
    {
      label: "google",
      onClick: () => handleProviderSignUp("google"),
    },
  ];

  const SignUpForm = () => {
    const {
      register,
      handleSubmit,
      watch,
      getValues,
      setValue,
      formState: { errors, isDirty, isValid },
    } = useForm<SignUpInputs>({ mode: "onChange", shouldUnregister: false });
    const signUp: SubmitHandler<SignUpInputs> = (data: SignUpInputs) =>
      handleSignUp(data);

    return (
      <form className="styledForm signUp" onSubmit={handleSubmit(signUp)}>
        <div className="formError phoneResult">{signUpError}</div>
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("email")}
          error={isDirty && !isValid ? errors.email?.message : ""}
          disabled={isLoading}
          register={register("email", {
            required: { value: true, message: t("required_field") },
          })}
        />
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("password")}
          type="password"
          error={isDirty && !isValid ? errors.password?.message : ""}
          disabled={isLoading}
          register={register("password", {
            required: { value: true, message: t("required_field") },
          })}
        />
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("password_confirm")}
          type="password"
          error={isDirty && !isValid ? errors.passwordConfirm?.message : ""}
          disabled={isLoading}
          register={register("passwordConfirm", {
            required: { value: true, message: t("required_field") },
            validate: (value) =>
              value === getValues("password") ||
              (t("password_missmatch") as string),
          })}
        />
        <Button
          type="submit"
          className={"fullWidth"}
          label={t("sign_up")}
          loading={isLoading}
          disabled={!isValid || isLoading}
        />
      </form>
    );
  };

  return (
    <div className="SignUpStep">
      <div className="headerTitle">{t("sign_up")}</div>
      <div className="splitContainer">
        <div className="userPasswordContainer">
          <div className="or">{t("or")}</div>
          <SignUpForm />
        </div>
        {config.authProviders && (
          <>
            <div className="providersContainer">
              <AuthProvidersButtons
                providers={signUpProviders}
                buttonLabelPrefix={t("sign_up_with")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignUpStep;
