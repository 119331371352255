import React from "react";
import classNames from "classnames";

import "./BookPager.scss";

export interface BookPagerProps {
  areaA: React.ReactNode;
  areaActions: React.ReactNode;
  areaB?: React.ReactNode;
  modalHeight?: boolean;
  containerClassName?: string;
}

export const BookPager = ({
  areaA,
  areaB,
  areaActions,
  modalHeight,
  containerClassName = "main-container",
}: BookPagerProps) => {
  return (
    <main className={containerClassName}>
      <div className={classNames("BookPager", { modalHeight: modalHeight })}>
        <div className={classNames("firstRow", { oneArea: !areaB })}>
          <div className="areaA">{areaA}</div>
          {areaB && <div className="areaB">{areaB}</div>}
        </div>
        <div className="secondRow">
          <div className="areaActions">{areaActions}</div>
        </div>
      </div>
    </main>
  );
};
