import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DynamicOptionSelection } from "@sumit26/sumit-ai-components";

import {
  ExportConfigCalendars,
  fontSizeOptions,
  InformationPageSlice,
  SettingPagesProps,
} from "../ExportConfigTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { DynamicToggleSelection } from "../../../components/common/V3/DynamicToggleSelection/DynamicToggleSelection";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";

import "./../SettingPages.scss";

export const InformationPage = ({
  form,
}: SettingPagesProps<InformationPageSlice>) => {
  const { t } = useTranslation();
  const informationWatch: InformationPageSlice = form?.watch() as InformationPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            {t("export_config_information_first_page_information")}
          </div>
          <div className="sub-title">
            {t("export_config_information_explanation_1")}
          </div>
        </div>
        {/* SECTION START */}

        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_job_name")}:
            </div>
            <ControllerHOC control={control} name="jobName.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="jobName.fontSize">
              <SelectInput
                disabled={!informationWatch?.jobName?.isActive}
                options={fontSizeOptions}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="jobName.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!informationWatch?.jobName?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="jobName.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!informationWatch?.jobName?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="jobName.color"
            >
              <ColorPicker disabled={!informationWatch?.jobName?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_job_date")}:
            </div>
            <ControllerHOC control={control} name="jobDate.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="inputs-row mb-3">
            <ControllerHOC
              setValue={setValue}
              control={control}
              name={"jobDate.calendars"}
            >
              <DynamicOptionSelection
                options={[
                  {
                    value: ExportConfigCalendars.regular,
                    label: t("export_config_global_regular_calendar"),
                  },
                  {
                    value: ExportConfigCalendars.hebrew,
                    label: t("export_config_global_hebrew_calendar"),
                  },
                  {
                    value: ExportConfigCalendars.muslim,
                    label: t("export_config_global_muslim_calendar"),
                  },
                ]}
                isMulti={true}
                disabled={!informationWatch?.jobDate?.isActive}
              />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="jobDate.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!informationWatch?.jobDate?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="jobDate.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!informationWatch?.jobDate?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="jobDate.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!informationWatch?.jobDate?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="jobDate.color"
            >
              <ColorPicker
                icon={<FontAwesomeIcon icon={["fal", "pencil-alt"]} />}
                disabled={!informationWatch?.jobDate?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_information_attendees")}</div>
            <ControllerHOC control={control} name="attendees.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_information_explanation_2")}
          </div>
        </div>
        {/* SECTION START */}
        {/* SECTION START */}
        <div className="section">
          <div className="input-title">{t("export_config_global_format")}:</div>
          <ControllerHOC setValue={setValue} control={control} name={"format"}>
            <DynamicToggleSelection
              disabled={!informationWatch?.attendees?.isActive}
              options={[
                {
                  value: "row",
                  label: t("export_config_global_row"),
                  content: t("export_config_global_row"),
                },
                {
                  value: "list",
                  label: t("export_config_global_list"),
                  content: t("export_config_global_list"),
                },
              ]}
              isMulti={false}
            />
          </ControllerHOC>
        </div>
        {/* SECTION START */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_show_title")}:
            </div>
            <ControllerHOC control={control} name="showTitle">
              <SwitchInput disabled={true} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION START */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_show_role_type")}:
            </div>
            <ControllerHOC control={control} name="showRoleType">
              <SwitchInput disabled={true} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION START */}
      </div>
    </form>
  );
};
