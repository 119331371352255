import React from "react";
import { useTranslation } from "react-i18next";

import {
  fontSizeOptions,
  SettingPagesProps,
  SpeakersPageSlice,
} from "../ExportConfigTypes";

import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

import "./../SettingPages.scss";

export const SpeakersPage = ({
  form,
}: SettingPagesProps<SpeakersPageSlice>) => {
  const { t } = useTranslation();

  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">{t("export_config_speakers_title")}:</div>
          <div className="sub-title">
            {t("export_config_speakers_explanation_1")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_show_title")}:
            </div>
            <ControllerHOC name="showTitle" control={control}>
              <SwitchInput disabled={true} />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_timing")}:
            </div>
            <ControllerHOC name="timing" control={control}>
              <SwitchInput disabled={true} />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_new_line")}:
            </div>
            <ControllerHOC name="newLine" control={control}>
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_indentation")}:
            </div>
            <ControllerHOC name="indentation" control={control}>
              <SwitchInput />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <ControllerHOC control={control} name="font.fontSize">
              <SelectInput options={fontSizeOptions} />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection hasJustify={true} />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="font.color"
            >
              <ColorPicker />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
