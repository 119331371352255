import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { TOptions } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RangeValidationResults } from "../../../models/range";

import "./RangeValidation.scss";

interface Props {
  validation: RangeValidationResults;
  direction: "ltr" | "rtl";
}

const RangeValidationComponent: FC<Props> = ({ validation, direction }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames("RangeValidation", direction)}>
      {validation.errors && !_.isEmpty(validation.errors) && (
        <div className="validationsContainer errorsContainer">
          <FontAwesomeIcon
            className="validationIcon validationErrorsIcon"
            icon={["fal", "exclamation-circle"]}
          />

          <div className="rangeErrors validationsList">
            {_.map(_.keys(validation.errors), (error) => (
              <div className="error" key={error}>
                {t(
                  `validation/${error}`,
                  validation.errors && (validation.errors[error] as TOptions)
                )}{" "}
              </div>
            ))}
            {/* {validation.warnings &&
              !_.isEmpty(validation.warnings) &&
              _.map(_.keys(validation.warnings), (warning) => (
                <div className="warning" key={warning}>
                  {t(`validation/${warning}`)}
                </div>
              ))} */}
          </div>
        </div>
      )}

      {/* {validation.warnings &&
        !_.isEmpty(validation.warnings) &&
        (!validation.errors || _.isEmpty(validation.errors)) && (
          <div className="validationsContainer warningsContainer">
            <FontAwesomeIcon
              className="validationIcon validationWarningsIcon"
              icon={["fal", "info-circle"]}
            />

            <div className="rangewarnings validationsList">
              {_.map(_.keys(validation.warnings), (warning) => (
                <div className="warning" key={warning}>
                  {t(`validation/${warning}`)}
                </div>
              ))}
            </div>
          </div>
        )} */}
    </div>
  );
};

export default RangeValidationComponent;
