import axios from "axios";
import _ from "lodash";
import * as Sentry from "@sentry/react";
import Logger from "./Logger";
import { JobType, jobTypes } from "../models/jobTypes";
import config from "../config";
import {
  JobData,
  JobDocument,
  NewJob,
  PendingJob,
  SlicesJson,
} from "./../models/job";
import { DocumentData, DocumentReference } from "@firebase/firestore-types";
import {
  Reference,
  ListResult,
  FirebaseStorage,
} from "@firebase/storage-types";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import mime from "mime-types";
import { format } from "date-fns";
import { NotificationData, AuditData } from "./../models";
import { ArchiveTree, ArchiveNode } from "./../models/archive";
import { NewMeetingData, PreviewFormat } from "./../models/meeting";
import {
  TranscriberPreviewData,
  MinimalTranscriber,
  MinimalProofer,
} from "./../models/user";
import { RoomData, RoomAsset, SpeakerSample } from "./../models/room";
import { Debt, PaidApproval, InvoiceRef } from "../models/payment";
import {
  ClientData,
  CurrClientInfo,
  Contact,
  ClientPreviewData,
  MinimalClient,
} from "../models/client";
import { MeetingPreviewData, ArchivedMeetingData } from "../models/meeting";
import {
  LoggedInUserInfo,
  LoggedInUser,
  CurrTranscriberInfo,
  NewUserData,
} from "../models/user";
import { Word, RoomRevision } from "../models";

import { ExportConfigData } from "../pages/ExportConfig/ExportConfigTypes";
import { getDefaultWords } from "../constants/defaultJobWords";

import PaymentsService from "./PaymentsService";
import EditorService from "./EditorService";
import CloudFunctionsService from "./CloudFunctionsService";
import NotificationService from "./NotificationService";
import AuthService from "./AuthService";
import TrackingService, { WorkTime } from "./TrackingService";
import { NoteFile } from "../components/Modals/NoteModal/NoteModal";
import { JobRange } from "../models/range";
import { JobMarkers } from "../models/markers";
import {
  RawConfigValidation,
  ValidationsConfigData,
} from "../pages/ValidationsConfig/ValidationsConfigTypes";

const logger = Logger("FirebaseService");

class FirebaseService {
  private static instance: FirebaseService;

  public db: firebase.firestore.Firestore;
  public auth: firebase.auth.Auth;
  public storage: firebase.storage.Storage;
  public analytics: firebase.analytics.Analytics;
  public currentUserMetadata: LoggedInUser | null;
  public verifier: firebase.auth.RecaptchaVerifier;
  public provider: firebase.auth.AuthProvider;
  public collections: { [colName: string]: string };
  public subCollections: {
    [colName: string]: {
      [subColName: string]: string;
    };
  };
  public buckets: { [bucket: string]: string };
  public colRefs: { [colName: string]: firebase.firestore.CollectionReference };

  public audioFormats = ["wav", "mp3", "ogg", "mpga", "m4a"];
  public videoFormats = ["mp4", "mkv", "mov", "wmv", "m4v", "qt"];

  public isTest = false;

  private constructor() {
    const fb = !firebase.apps.length
      ? firebase.initializeApp(config.firebase)
      : firebase.app();
    this.db = fb.firestore();
    this.auth = fb.auth();
    this.storage = firebase.storage();
    this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    this.analytics = firebase.analytics();
    this.currentUserMetadata = null;

    this.collections = {
      rooms: "rooms-v2",
      jobs: "jobs",
      clients: "clients-v2",
      emails: "emails-v2",
      users: "users-v2",
      payments: "payments-v2",
      notifications: "notifications",
      transcriberDebts: "debts",
      speakers: "speakers",
      audit: "audit",
    };

    this.subCollections = {
      clients: {
        exportConfigs: "exportConfigs",
        subtitlesExportConfigs: "subtitlesExportConfigs",
      },
    };

    this.buckets = {
      rooms: "rooms",
      voiset: "voiset",
      exportConfig: "export-config",
      clients: "client",
    };

    this.colRefs = {
      debts: this.db.collection("debts"),
      rooms: this.db.collection("rooms-v2"),
      configs: this.db.collection("configs"),
      speakers: this.db.collection("speakers"),
      clients: this.db.collection("clients-v2"),
    };
  }

  private isFileExists = async (
    fileRef: firebase.storage.Reference
  ): Promise<boolean> => {
    try {
      await fileRef.getDownloadURL();
      return true;
    } catch (err: any) {
      if (err.code === "storage/object-not-found") {
        return false;
      } else {
        throw err;
      }
    }
  };

  public getFeatureFlags = async () => {
    const featureFlags = (
      await this.colRefs.configs.doc("featureFlags").get()
    ).data();
    return featureFlags && featureFlags["platform-v2"];
  };

  public toggleFeatureFlag = async (feature: string) => {
    const featureFlagsRef = this.colRefs.configs.doc("featureFlags");
    const featureFlags = (await featureFlagsRef.get()).data();

    if (!featureFlags) return;

    await featureFlagsRef.update({
      "platform-v2": {
        ...featureFlags["platform-v2"],
        [feature]: {
          ...featureFlags["platform-v2"][feature],
          enabled: !featureFlags["platform-v2"][feature].enabled,
        },
      },
    });
  };

  public updateFeatureFlag = async (
    featureName: string,
    feature: { enabled?: boolean; roles?: string[]; users?: string[] }
  ) => {
    const featureFlagsRef = this.colRefs.configs.doc("featureFlags");
    const featureFlags = (await featureFlagsRef.get()).data();

    if (!featureFlags) return;

    await featureFlagsRef.update({
      "platform-v2": {
        ...featureFlags["platform-v2"],
        [featureName]: {
          ...featureFlags["platform-v2"][featureName],
          ...feature,
        },
      },
    });
  };

  public getDocRef = (collection: string, id: string) =>
    this.db.doc(`${collection}/${id}`);

  public getAuthenticatedUserInfo = async (userId: string) => {
    const userObj = this.auth.currentUser;
    const userRef = await this.db
      .collection(this.collections.users)
      .doc(userId)
      .get();
    const providers: string[] = [];
    const userData = userRef.data();
    const mfaState = userObj && AuthService.getMfaState(userData);
    userObj &&
      userObj.providerData.forEach((profile: any) => {
        providers.push(profile.providerId);
      });
    if (!userData) {
      return null;
    } else {
      const authedUser: LoggedInUser = {
        id: userRef.id,
        username: userData.username,
        role: userData.role,
        imgUrl: userData.imgUrl,
        country: userData.country,
        creationTime: userData.creationTime
          ? new Date(userData.creationTime.seconds * 1000)
          : new Date(),
        notificationsCount: 8,
        languages: userData.languages,
        phonenumber: userData.phonenumber,
        email: userObj && userObj.email,
        emailVerified: userObj && userObj.emailVerified,
        mfaState,
        providers,
      };
      this.currentUserMetadata = authedUser;
      return authedUser;
    }
  };

  public setAnalyticsUser = (user: firebase.User | null) => {
    const analyticsUser = user ? user.uid : "nouser";
    this.analytics.setUserId(analyticsUser);

    if (this.currentUserMetadata) {
      this.analytics.setUserProperties({
        user_role: this.currentUserMetadata.role,
      });
    }
  };

  public openNotificationsPipe = async (
    userId: string,
    userCreationTime: Date,
    handleNotification: (notification: NotificationData) => void
  ) => {
    const userRole = await this._getUserRole(userId);
    const notificationsCollection = this.collections.notifications;
    this.db.collection(notificationsCollection).onSnapshot((snap) => {
      const changes = snap.docChanges();
      changes.forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        if (data && !_.isEmpty(data) && data.creationTime) {
          const creationTime = new Date(data.creationTime.toDate());
          if (change.type === "added" && creationTime > userCreationTime) {
            if (data.recipient.id === userId || data.recipient === userRole) {
              let isApproved = false;
              data.approvedBy.forEach((approvedById: string) => {
                if (approvedById === userId) isApproved = true;
              });
              const notification: NotificationData = {
                id: doc.id,
                event: data.event,
                recipient: data.recipient,
                info: data.info,
                creationTime: new Date(data.creationTime.seconds * 1000),
                approvedBy: data.approvedBy,
              };
              NotificationService.pushBrowserNotification(notification);
              handleNotification(notification);
            }
          }
        }
      });
    });
  };
  public sendNotification = async (notification: NotificationData) => {
    const id = this.generateID();
    await this.db
      .collection(this.collections.notifications)
      .doc(id)
      .set({ ...notification, id });
  };

  public getLoggedInUserInfo = async (userId: string) => {
    const doc = await this.db
      .collection(this.collections.users)
      .doc(userId)
      .get();
    const data = doc.data();
    if (data) {
      const currMeetings = await this.getUserCurrMeetings(userId);
      const userDebt = await PaymentsService.getUserDebt(userId);
      const loggedInUserInfo: LoggedInUserInfo = {
        mail: data.mail,
        phonenumber: data.phonenumber,
        currDebt: userDebt ? userDebt.sum : 0,
        currMeetingsCount: currMeetings.length,
      };
      return loggedInUserInfo;
    }
  };

  public getAllUsers = async ({ keyBy }: { keyBy?: string }) => {
    const userDocs = await this.db.collection(this.collections.users).get();
    const users = userDocs.docs.map((u) => ({ ...u.data(), id: u.id }));
    const sortedUsers = _.sortBy(users, "username");
    if (keyBy) {
      return _.keyBy(
        sortedUsers.filter((u) => u.id),
        keyBy
      );
    }
    return sortedUsers;
  };

  public getUser = async (userId: string) => {
    const doc = await this.db
      .collection(this.collections.users)
      .doc(userId)
      .get();
    const user = doc.data();
    if (user) return user;
  };

  public approveNotification = async (
    notificationId: string,
    userId: string
  ) => {
    const notificationRef = this.db
      .collection(this.collections.notifications)
      .doc(notificationId);
    const doc = await notificationRef.get();
    const data = doc.data();
    if (data) {
      const approvedBy = data.approvedBy;
      approvedBy.push(userId);
      await notificationRef.update({ approvedBy });
    }
  };

  public addUser = async (newUser: NewUserData) => {
    await this.db
      .collection(this.collections.users)
      .doc(newUser.id)
      .set(newUser);
  };

  public getMinimalTranscribers = async () => {
    const transcribers: MinimalTranscriber[] = [];
    const transcribersRef = await this.db
      .collection(this.collections.users)
      .where("role", "in", [
        "super_user",
        "transcriber",
        "transcriber+proofer",
        "disabled",
      ])
      .get();
    transcribersRef.docs.forEach((doc) => {
      const data = doc.data();
      transcribers.push({
        username: data.username,
        id: doc.id,
        role: data.role,
      });
    });
    return transcribers;
  };

  public getMinimalProofers = async () => {
    const proofers: MinimalProofer[] = [];
    const proofersRef = await this.db
      .collection(this.collections.users)
      .where("role", "in", [
        "super_user",
        "proofer",
        "transcriber+proofer",
        "disabled",
      ])
      .get();
    proofersRef.docs.forEach((doc) => {
      const data = doc.data();
      proofers.push({
        username: data.username,
        id: doc.id,
      });
      // proofersOptions.push({
      //   value: doc.id,
      //   label: data.username,
      //   icon: "",
      // });
    });
    return proofers;
  };

  public getPendingTranscribersPreview = async () => {
    const transcribers: TranscriberPreviewData[] = [];
    const transcribersRef = await this.db
      .collection(this.collections.users)
      .where("role", "==", "pending-transcriber")
      .get();
    transcribersRef.docs.forEach((doc) => {
      const data = doc.data();
      try {
        transcribers.push({
          id: doc.id,
          username: data.username,
          role: data.role,
          phoneNumber: data.phonenumber,
          creationTime: new Date(data.creationTime.seconds * 1000),
          currMeetingsCount: 0,
          debt: 0,
        });
      } catch (err) {
        console.log(err);
      }
    });
    return transcribers;
  };

  public getTranscribersPreview = async () => {
    try {
      const transcribersRef = await this.db
        .collection(this.collections.users)
        .where("role", "in", [
          "transcriber",
          "proofer",
          "transcriber+proofer",
          "super_user",
          "disabled",
        ])
        .get();
      const transcribers = transcribersRef.docs.map(async (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          phoneNumber: data.phonenumber,
          role: data.role,
          username: data.username,
          currMeetingsCount: 0,
          creationTime: data.creationTime
            ? new Date(data.creationTime.seconds * 1000)
            : new Date(),
          debt: data.debt || 0,
          mail: data.mail,
        };
      });
      // const combinedExtraSyncData = transcribers.map(async (transcriber) => {
      //   const meetingsCount = await this.getUserJobs(transcriber.id);
      //   const currDebt = await this._getCurrDebt(transcriber.id, "user");
      //   return {
      //     ...transcriber,
      //     currMeetingsCount: meetingsCount.length,
      //     // currDebt,
      //   };
      // });
      const transcribersArr = Promise.all(transcribers);
      return transcribersArr;
    } catch (err) {
      console.log("a");

      throw new Error(err);
    }
  };

  public getMinimalClients = async () => {
    const clientsToReturn: MinimalClient[] = [];
    const clientsRef = await this.db.collection(this.collections.clients).get();
    clientsRef.docs.forEach((doc) => {
      const data = doc.data();
      clientsToReturn.push({
        id: doc.id,
        name: data.name,
      });
    });
    return clientsToReturn;
  };

  public getClientsPreview = async () => {
    const clients: ClientPreviewData[] = [];
    const clientsRef = await this.db.collection(this.collections.clients).get();
    clientsRef.docs.forEach(async (doc) => {
      const data = doc.data();
      clients.push({
        id: doc.id,
        name: data.name,
        role: data.role,
        currMeetingsCount: 0,
        archivedMeetings: 0,
        currDebt: 0,
      });
    });

    return clients;
  };
  private getClientMeetingCountByStatus = async (
    status: number[],
    clientId: string
  ) => {
    const clientDoc = this.db.doc(`${this.collections.clients}/${clientId}`);
    const clientMeetingsRef = await this.db
      .collection(this.collections.rooms)
      .where("ownerId", "==", clientDoc)
      .where("status", "in", [5])
      .get();
    return clientMeetingsRef.docs.length;
  };

  public getTranscriber = async (userId: string) => {
    const doc = await this.db
      .collection(this.collections.users)
      .doc(userId)
      .get();
    const data = doc.data();
    if (data) {
      const meetingsCount = await this.getUserJobs(userId);
      const transcriber: CurrTranscriberInfo = {
        id: doc.id,
        username: data.username,
        mail: data.mail,
        phonenumber: data.phonenumber,
        imgUrl: data.imgUrl,
        dateOfBirth: data.dateOfBirth
          ? new Date(data.dateOfBirth.seconds * 1000)
          : new Date(0),
        currDebt: await this._getCurrDebt(userId, "user"),
        addressCity: data.addressCity,
        role: data.role,
        hasContract: data.hasContract,
        dealerNumer: data.dealerNumber,
        dealerType: data.dealerType,
        languages: data.languages,
        completedCount: data.completedCount,
        rejectedCount: data.rejectedCount,
        currMeetingsCount: meetingsCount.length,
      };
      return transcriber;
    }
  };

  public addUserDetails = async (userData: any) => {
    await this.db
      .collection("users-v2")
      .doc(userData.userId)
      .update({
        creationTime: new Date(),
        username: userData.username || userData.name,
        languages: userData.languages || [],
        country: userData.country || null,
        birthday: userData.birthday || null,
      });
    return userData.userId;
  };

  public getClient = async (clientId: string) => {
    const doc = await this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .get();
    const data = doc.data();
    if (data) {
      const currMeetings = await this.getClientMeetings(clientId);
      const client: CurrClientInfo = {
        id: doc.id,
        name: data.name,
        contacts: data.contacts,
        notes: data.notes,
        currDebt: await this._getCurrDebt(doc.id, "client"),
        completedMeetingsCount: 157,
        currMeetingsCount: currMeetings.length,
      };
      return client;
    }
  };

  public getClientMeetings = async (
    clientId: string,
    combined?: boolean,
    checkNotes?: boolean
  ) => {
    const clients = await this.getClients();
    const clientDoc = this.db.doc(`${this.collections.clients}/${clientId}`);
    const meetingsToReturn: MeetingPreviewData[] = [];
    const assigned: MeetingPreviewData[] = [];
    const clientMeetingsRef = await this.db
      .collection(this.collections.rooms)
      .where("ownerId", "==", clientDoc)
      .where("status", "in", [3, 4, 5])
      .get();
    await Promise.all(
      clientMeetingsRef.docs.map(async (doc) => {
        const meetingPreviewData = await this._getMeetingPreviewData(
          doc,
          clients,
          checkNotes
        );
        const data = doc.data();
        if (combined) {
          if (
            data.assignedTranscriber !== null &&
            (data.isApproved || data.assignedMethod === "claimed")
          )
            assigned.push(meetingPreviewData);
          else meetingsToReturn.push(meetingPreviewData);
        } else {
          meetingsToReturn.push(meetingPreviewData);
        }
      })
    );
    if (combined) {
      const assignedCombined = await this._combineUserInfo(
        assigned,
        "assignedTranscriber"
      );
      assignedCombined.forEach((mtng) => {
        if (mtng) meetingsToReturn.push(mtng);
      });
    }
    return meetingsToReturn;
  };

  public getSpeakers = async (client?: string) => {
    const speakerCollection = this.colRefs.speakers;
    const speakerDocs = client
      ? await speakerCollection.where("clientId", "==", client).get()
      : await speakerCollection.get();
    const speakers = [];
    for (const speakerDoc of speakerDocs.docs) {
      const speaker = await speakerDoc.data();
      speakers.push({ ...speaker, speakerId: speakerDoc.id });
    }
    return speakers;
  };

  public saveSpeakers = async (
    speakers: { name: string; clientId?: string; speakerId?: string }[],
    clientId: string
  ) => {
    speakers.forEach(async (speaker) => {
      const speakerRef = this.colRefs.speakers.doc();
      speakerRef.set({ clientId, name: speaker.name });
    });
  };

  public deleteNoteFile = async (jobId: string, fileName: string) => {
    const noteFileRef = this.storage.ref(
      `${this.buckets.rooms}/${jobId}/notes/${fileName}`
    );
    await noteFileRef.delete();
  };

  public removeTranscriber = async (userId: string) => {
    await this.db.collection(this.collections.users).doc(userId).delete();
    const photoName = `${userId}.png`;
    const photoRef = this.storage.ref(`user_photos/${photoName}`);
    await photoRef.delete();
    const meetingsRef = this.db
      .collection(this.collections.rooms)
      .where(
        "assignedTranscriber",
        "==",
        this.getDocRef(this.collections.users, userId)
      );
    const meetingDocs = await meetingsRef.get();
    meetingDocs.docs.forEach(async (doc) => {
      if (doc.data().status != 5) await this.unassignTranscriber(doc.id);
    });
  };

  public approveNewTranscriber = async (userId: string) => {
    await this.db
      .collection(this.collections.users)
      .doc(userId)
      .update({ role: "transcriber" });
  };

  public removeClient = async (id: string) => {
    await this.db.collection(this.collections.clients).doc(id).delete();
  };

  public updateClient = async (client: ClientData) => {
    if (!client.id) {
      const id = "_" + this.generateID();
      await this.db.collection(this.collections.clients).doc(id).set(client);
    } else {
      await this.db
        .collection(this.collections.clients)
        .doc(client.id)
        .set(client);
    }
  };

  public increaseRejectedCount = async (transcriberId: string) => {
    const transcriberRef = this.db
      .collection(this.collections.users)
      .doc(transcriberId);
    const doc = await transcriberRef.get();
    const data = doc.data();
    if (data) {
      if (data.rejectedCount) {
        const rejectedCount = data.rejectedCount + 1;
        await transcriberRef.update({ rejectedCount });
      } else {
        await transcriberRef.update({ rejectedCount: 1 });
      }
    }
  };

  public increaseCompletedCount = async (transcriberId: string) => {
    const transcriberRef = this.db
      .collection(this.collections.users)
      .doc(transcriberId);
    const doc = await transcriberRef.get();
    const data = doc.data();
    if (data) {
      let completedCount = 0;
      if (data.completedCount) completedCount = data.completedCount + 1;
      await transcriberRef.update({ completedCount });
    }
  };

  public updateClientNotes = async (clientId: string, newNotes: string) => {
    await this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .update({ notes: newNotes });
  };

  public updateClientContacts = async (
    clientId: string,
    newContacts: Contact[]
  ) => {
    await this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .update({ contacts: newContacts });
  };

  public getClients = async () => {
    const clientsCollection = this.db.collection(this.collections.clients);
    const clients = await clientsCollection.get();
    const clientsMap = _.map(clients.docs, (c) => {
      const client = c.data();
      return {
        id: c.id,
        name: client.name,
      };
    });
    return clientsMap;
  };

  public getAvailableMeetings = async (
    loggedInUserId: string,
    includeProcessing = true
  ) => {
    const userRole = await this._getUserRole(loggedInUserId);
    const clients = await this.getClients();
    if (userRole.includes("pending")) return [];
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    if (userRole === "super_user") {
      const availableRef = await meetingsRef
        .where("status", "in", includeProcessing ? [1, 3] : [3])
        // .where("assignedTranscriber", "==", null)
        .where("isApproved", "==", false)
        .get();
      const requestedRef = await meetingsRef
        .where("status", "==", 3)
        .where("assignedMethod", "==", "requested")
        .where("assignedMethod", "==", "assigned")
        .where("isApproved", "==", false)
        .get();
      for (const meetingDoc of availableRef.docs) {
        const meetingPreviewData = await this._getMeetingPreviewData(
          meetingDoc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
      for (const meetingDoc of requestedRef.docs) {
        const meetingPreviewData = await this._getMeetingPreviewData(
          meetingDoc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    } else {
      const availableRef = await meetingsRef
        .where("status", "==", 3)
        .where("assignedTranscriber", "==", null)
        .get();
      for (const meetingDoc of availableRef.docs) {
        const meetingPreviewData = await this._getMeetingPreviewData(
          meetingDoc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    }
    return meetingsToReturn;
  };
  public getAvailableJobs = async (
    loggedInUser: LoggedInUser,
    includeProcessing = true,
    mergeTableFeature: boolean
  ) => {
    if (loggedInUser.role === "transcriber") {
      return this.getAvailableMeetings(loggedInUser.id, includeProcessing);
    } else if (loggedInUser.role === "proofer") {
      return this.getAvailableProofings();
    } else if (loggedInUser.role === "transcriber+proofer") {
      const transcribingMeetings = await this.getAvailableProofings();
      const proofingMeetings = await this.getAvailableMeetings(
        loggedInUser.id,
        includeProcessing
      );
      return [...transcribingMeetings, ...proofingMeetings];
    } else if (loggedInUser.role === "super_user") {
      if (mergeTableFeature) {
        const allUnfinishedMeetings = await this.getAllUnfinishedMeetings(
          loggedInUser.id,
          includeProcessing
        );
        return allUnfinishedMeetings;
      } else {
        const transcribingMeetings = await this.getAvailableProofings();
        const proofingMeetings = await this.getAvailableMeetings(
          loggedInUser.id,
          includeProcessing
        );
        return [...transcribingMeetings, ...proofingMeetings];
      }
    }
  };

  public getJob = async (
    jobId: string,
    clients: {
      id: string;
      name: string;
    }[]
  ) => {
    try {
      const jobDoc = await this.db
        .collection(this.collections.rooms)
        .doc(jobId)
        .get();

      const formatedJob =
        jobDoc && clients && this._getMeetingPreviewData(jobDoc, clients);
      return formatedJob;
    } catch (err) {
      logger.log(err, "getSpecificJob");
    }
  };

  public getJobChilds = async (jobId: string) => {
    try {
      const jobDoc = await this.db
        .collection(this.collections.rooms)
        .doc(jobId)
        .get();

      const jobData = jobDoc.data();
      const jobChilds = _.get(jobData, "split.childs");
      return jobChilds;
    } catch (err) {
      logger.log(err);
      throw err;
    }
  };

  public getAllUnfinishedMeetings = async (
    loggedInUserId: string,
    includeProcessing = true
  ) => {
    const userRole = await this._getUserRole(loggedInUserId);
    const clients = await this.getClients();
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    if (userRole === "super_user") {
      const allUnfinishedMeetings = await meetingsRef
        .where("status", "in", includeProcessing ? [1, 3, 4] : [3, 4])
        .get();
      await Promise.all(
        await allUnfinishedMeetings.docs.map(async (meetingDoc) => {
          const meetingPreviewData = await this._getMeetingPreviewData(
            meetingDoc,
            clients,
            true
          );
          meetingsToReturn.push(meetingPreviewData);
        })
      );
    }
    return meetingsToReturn;
  };

  public getUserMeetings = async (loggedInUserId: string) => {
    const clients = await this.getClients();
    const userRole = await this._getUserRole(loggedInUserId);
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    if (userRole === "super_user") {
      const superUserMeetings = await meetingsRef
        .where("status", "==", 3)
        .where(
          "assignedTranscriber",
          "==",
          this.db.doc(`${this.collections.users}/${loggedInUserId}`)
        )
        .get();
      for (const meetingDoc of superUserMeetings.docs) {
        const meetingPreviewData = await this._getMeetingPreviewData(
          meetingDoc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    } else if (
      userRole === "transcriber" ||
      userRole === "transcriber+proofer"
    ) {
      const assigned = await meetingsRef
        .where("status", "==", 3)
        .where(
          "assignedTranscriber",
          "==",
          this.db.doc(`${this.collections.users}/${loggedInUserId}`)
        )
        .where("assignedMethod", "==", "assigned")
        .get();
      const requested = await meetingsRef
        .where("status", "==", 3)
        .where(
          "assignedTranscriber",
          "==",
          this.db.doc(`${this.collections.users}/${loggedInUserId}`)
        )
        .where("assignedMethod", "==", "requested")
        .where("isApproved", "==", true)
        .get();
      for (const meetingDoc of assigned.docs) {
        const meetingPreviewData = await this._getMeetingPreviewData(
          meetingDoc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
      for (const meetingDoc of requested.docs) {
        const meetingPreviewData = await this._getMeetingPreviewData(
          meetingDoc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    }
    return meetingsToReturn;
  };

  public getUserCurrMeetings = async (userId: string) => {
    const clients = await this.getClients();
    const userDoc = this.db.doc(`${this.collections.users}/${userId}`);
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const userMeetings = await meetingsRef
      .where("status", "==", 3)
      .where("assignedTranscriber", "==", userDoc)
      .get();

    for (const doc of userMeetings.docs) {
      const data = doc.data();
      if (data.isApproved || data.assignedMethod === "claimed") {
        const meetingPreviewData = await this._getMeetingPreviewData(
          doc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    }
    return meetingsToReturn;
  };
  public getUserJobs = async (userId: string) => {
    const clients = await this.getClients();
    const userDoc = this.db.doc(`${this.collections.users}/${userId}`);
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const proofing = await meetingsRef
      .where("status", "==", 4)
      .where("assignedProofer", "==", userDoc)
      .get();
    const transcribing = await meetingsRef
      .where("status", "==", 3)
      .where("assignedTranscriber", "==", userDoc)
      .get();
    const userMeetings = [...proofing.docs, ...transcribing.docs];
    for (const doc of userMeetings) {
      const data = doc.data();
      if (data.isApproved || data.assignedMethod === "claimed") {
        const meetingPreviewData = await this._getMeetingPreviewData(
          doc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    }
    return meetingsToReturn;
  };

  public getUserProofingMeetings = async (userId: string) => {
    const clients = await this.getClients();
    const userDoc = this.db.doc(`${this.collections.users}/${userId}`);
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const userMeetings = await meetingsRef
      .where("status", "==", 3)
      .where("assignedTranscriber", "==", userDoc)
      .get();
    for (const doc of userMeetings.docs) {
      const data = doc.data();
      if (data.isApproved || data.assignedMethod === "claimed") {
        const meetingPreviewData = await this._getMeetingPreviewData(
          doc,
          clients
        );
        meetingsToReturn.push(meetingPreviewData);
      }
    }
    return meetingsToReturn;
  };

  public getAssignedMeetings = async () => {
    const clients = await this.getClients();
    const assigned: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const meetingDocs = await meetingsRef
      .where("status", "==", 3)
      .where("isApproved", "==", true)
      .where("assignedTranscriber", "!=", null)
      .get();
    for (const meetingDoc of meetingDocs.docs) {
      const meetingPreviewData = await this._getMeetingPreviewData(
        meetingDoc,
        clients
      );
      assigned.push(meetingPreviewData);
    }
    const assignedCombined = await this._combineUserInfo(
      assigned,
      "assignedTranscriber"
    );
    const meetingsToReturn: MeetingPreviewData[] = [];
    assignedCombined.forEach((combined) => {
      if (combined) {
        return meetingsToReturn.push(combined);
      }
    });
    return meetingsToReturn;
  };

  public getAssignedProofings = async () => {
    const clients = await this.getClients();
    const assigned: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const meetingDocs = await meetingsRef
      .where("assignedProofer", "!=", null)
      .where("status", "==", 4)
      .get();
    for (const meetingDoc of meetingDocs.docs) {
      const meetingPreviewData = await this._getMeetingPreviewData(
        meetingDoc,
        clients
      );
      assigned.push(meetingPreviewData);
    }
    const assignedCombined = await this._combineUserInfo(
      assigned,
      "assignedTranscriber"
    );
    const meetingsToReturn: MeetingPreviewData[] = [];
    assignedCombined.forEach((combined) => {
      if (combined) {
        return meetingsToReturn.push(combined);
      }
    });
    return meetingsToReturn;
  };

  public getAvailableProofings = async () => {
    const clients = await this.getClients();
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const proofingsDocs = await meetingsRef
      .where("status", "==", 4)
      .where("assignedProofer", "==", null)
      .get();
    for (const meetingDoc of proofingsDocs.docs) {
      const meetingPreviewData = await this._getMeetingPreviewData(
        meetingDoc,
        clients
      );
      meetingsToReturn.push(meetingPreviewData);
    }
    return meetingsToReturn;
  };

  public getClientJobs = async (
    loggedInUser: LoggedInUser,
    clientId: string
  ) => {
    if (loggedInUser.role === "super_user") {
      const meetingsToReturn: MeetingPreviewData[] = [];
      const jobRef = this.db.collection(this.collections.rooms);

      const clientsCollection = this.db
        .collection(this.collections.clients)
        .doc(clientId);
      const clientData = (await clientsCollection.get()).data();
      if (!clientData) return;
      const client = [
        {
          id: clientData.id,
          name: clientData.name,
        },
      ];

      const clientJobs = await jobRef
        .where("status", "in", [1, 3, 4, 5])
        .where("clientId", "==", clientId)
        .get();
      await Promise.all(
        clientJobs.docs.map(async (jobDoc) => {
          const meetingPreviewData = await this._getMeetingPreviewData(
            jobDoc,
            client
          );
          meetingsToReturn.push(meetingPreviewData);
        })
      );
      return meetingsToReturn;
    }
  };

  public getUserProofings = async (userId: string) => {
    const clients = await this.getClients();
    const meetingsToReturn: MeetingPreviewData[] = [];
    const meetingsRef = this.db.collection(this.collections.rooms);
    const proofingsDocs = await meetingsRef
      .where("status", "==", 4)
      .where(
        "assignedProofer",
        "==",
        this.db.doc(`${this.collections.users}/${userId}`)
      )
      .get();
    for (const meetingDoc of proofingsDocs.docs) {
      const meetingPreviewData = await this._getMeetingPreviewData(
        meetingDoc,
        clients
      );
      meetingsToReturn.push(meetingPreviewData);
    }
    return meetingsToReturn;
  };

  public getArchivedMeetings = async () => {
    const meetingsRef = this.db.collection(this.collections.rooms);
    const archivedDocs = await meetingsRef.where("status", "==", 5).get();
    const clients: { id: string; name: string }[] = await this.getClients();
    const clientsById = _.keyBy(clients, (c) => c.id);
    const meetingsToReturn = archivedDocs.docs.map(async (meetingDoc) =>
      this._getArchivedMeetingData(meetingDoc, clientsById)
    );

    const archivedMeetings = await Promise.all(meetingsToReturn);

    return archivedMeetings;
  };

  public getDataFromRef = async (rawData: DocumentData) => {
    let result = await rawData.get();
    result = await result.data();
    return result;
  };
  public getArchiveTree = async (userId: string) => {
    const folders: ArchiveNode[] = [];
    const archiveTree: ArchiveTree = {
      folders,
    };
    const userDoc = await this.db
      .collection(this.collections.users)
      .doc(userId)
      .get();
    const data = userDoc.data();
    if (data) {
      if (data.archiveTree) {
        data.archiveTree.folders.forEach((folder: ArchiveNode) => {
          archiveTree.folders.push(folder);
        });
      }
    }
    return archiveTree;
  };

  public updateArchiveTree = async (
    archiveTree: ArchiveTree,
    userId: string
  ) => {
    await this.db
      .collection(this.collections.users)
      .doc(userId)
      .update({ archiveTree });
  };

  public updateMeeting = async (meeting: MeetingPreviewData) => {
    if (!meeting.id) {
      const id = "_" + this.generateID();
      await this.db.collection(this.collections.rooms).doc(id).set(meeting);
    } else {
      await this.db
        .collection(this.collections.rooms)
        .doc(meeting.id)
        .set(meeting);
    }
  };

  public updateJob = async (meeting: Partial<JobDocument>) => {
    const cleanMeeting = _.omitBy(meeting, _.isNil);
    await this.db
      .collection(this.collections.rooms)
      .doc(cleanMeeting.id)
      .update(cleanMeeting);
  };

  public updateJobFields = async (
    fieldsToUpdate: {
      [key: string]: string | string[] | number | boolean | WorkTime;
    },
    jobId: string
  ) => {
    await this.db
      .collection(this.collections.rooms)
      .doc(jobId)
      .update(fieldsToUpdate);
  };

  public runDoneSubtitlesTranslation = async (
    langs: string[],
    jobId: string
  ) => {
    // await this.updateJobFields({ "lang.output": outputs }, jobId);
    await CloudFunctionsService.createSubtitlesTranslationJob({ jobId, langs });
  };

  public updateNotes = async (note: string, jobId?: string) => {
    await this.db
      .collection(this.collections.rooms)
      .doc(jobId)
      .update({ notes: note });
  };

  public saveMeeting = async (roomData: RoomData | JobData) => {
    const { words, assets, ...room } = roomData;
    try {
      const speakers = EditorService.getSpeakersFromWords(words);
      const roomFieldsToSave = this.getFormattedRoomBeforeSave(roomData);

      if (!room.roomId) {
        const id = room.roomId ? room.roomId : "_" + this.generateID();
        await this.db
          .collection(this.collections.rooms)
          .doc(id)
          .set({ ...roomFieldsToSave, speakers }, { merge: true });
      } else {
        const roomDocToUpdate = await this.db
          .collection(this.collections.rooms)
          .doc(room.roomId);
        await roomDocToUpdate.set(
          { ...roomFieldsToSave, speakers },
          { merge: true }
        );

        const wordsFileRef = this.storage
          .ref()
          .child(`rooms/${room.roomId}/ready.json`);
        const formattedWords = this.getFormattedWordsBeforeSave(words);
        const wordsBlob = new Blob([JSON.stringify(formattedWords)], {
          type: "application/json",
        });

        await wordsFileRef.put(wordsBlob, { cacheControl: "public,max-age=0" });

        // CloudFunctionsService.editProgress(room.roomId);

        const updatedRoomDoc = await roomDocToUpdate.get();
        return updatedRoomDoc.data();
      }
    } catch (err) {
      console.log(err);
      throw new Error("Failed - saveMeeting");
    }
  };

  public saveWorkTime = async (jobId: string, workTime: WorkTime) => {
    try {
      const jobDocToUpdate = await this.db
        .collection(this.collections.rooms)
        .doc(jobId);
      await jobDocToUpdate.set({ workTime }, { merge: true });
    } catch (err) {
      logger.error(err, "saveWorkTime");
    }
  };

  public saveJob = async (jobData: JobData, metaData: any) => {
    const { ranges, assets, ...job } = jobData;
    const words = EditorService.getWordsFromRanges(ranges);

    try {
      const jobFieldsToSave = this.getFormattedJobBeforeSave(jobData);
      if (!job.roomId) {
        const id = job.roomId ? job.roomId : "_" + this.generateID();
        await this.db
          .collection(this.collections.rooms)
          .doc(id)
          .set({ ...jobFieldsToSave }, { merge: true });
      } else {
        const jobDocToUpdate = await this.db
          .collection(this.collections.rooms)
          .doc(job.roomId);
        await jobDocToUpdate.set({ ...jobFieldsToSave }, { merge: true });

        const wordsFileRef = this.storage
          .ref()
          .child(`rooms/${job.roomId}/ready.json`);
        const formattedWords = this.getFormattedWordsBeforeSave(words);
        const wordsBlob = new Blob([JSON.stringify(formattedWords)], {
          type: "application/json",
        });

        const formattedRanges = this.getFormattedRangesBeforeSave(ranges);
        const rangesFileRef = this.storage
          .ref()
          .child(`rooms/${job.roomId}/ranges.json`);
        const rangesBlob = new Blob([JSON.stringify(formattedRanges)], {
          type: "application/json",
        });

        await wordsFileRef.put(wordsBlob, {
          cacheControl: "public,max-age=0",
          customMetadata: metaData,
        });
        await rangesFileRef.put(rangesBlob, {
          cacheControl: "public,max-age=0",
          customMetadata: metaData,
        });

        // CloudFunctionsService.editProgress(job.roomId);

        const updatedJobDoc = await jobDocToUpdate.get();
        return updatedJobDoc.data();
      }
    } catch (err) {
      console.log(err);
      throw new Error("Failed - saveJob");
    }
  };

  public hasNotes = async (idJob: string, job: JobData): Promise<boolean> => {
    if (!_.isNil(job.hasNotes)) {
      return job.hasNotes;
    }
    const noteFiles: firebase.storage.ListResult = await this.storage
      .ref(`${this.buckets.rooms}/${idJob}/notes`)
      .listAll();

    const _hasNotes = !_.isEmpty(noteFiles.items);

    if (idJob && _hasNotes) {
      await this.db
        .collection(this.collections.rooms)
        .doc(idJob)
        .update({ hasNotes: _hasNotes });
    }

    return _hasNotes;
  };

  private getFormattedRoomBeforeSave = (
    roomData:
      | (RoomData & { jobType?: string })
      | (JobData & { previewFormat?: string })
  ) => {
    const formattedFields = {
      jobType: roomData.previewFormat || roomData.jobType,
      workTime: roomData.workTime || { transcribe: {}, review: {} },
      timeSynced: roomData.timeSynced,
    };
    return _.omitBy(formattedFields, _.isNil);
  };

  private getFormattedJobBeforeSave = (jobData: JobData) => {
    const formattedFields: {
      workTime: WorkTime;
      timeSynced?: boolean;
      speakers?: any[];
    } = {
      workTime: jobData.workTime || { transcribe: {}, review: {} },
      timeSynced: jobData.timeSynced,
    };
    if (jobData.jobType === "protocol") {
      formattedFields["speakers"] = _.uniq(
        _.map(jobData.ranges as JobRange[], (r) => r.speakerName)
      )?.filter((s) => !_.isEmpty(s));
    }
    return _.omitBy(formattedFields, _.isNil);
  };

  private getFormattedRoomBeforeUpload = (
    roomData: NewMeetingData,
    id: string
  ) => {
    const newRoom = { ...roomData };
    _.update(newRoom, "lang.output", (output) =>
      output.length ? output : newRoom.lang.input
    );

    return _.omitBy(newRoom, _.isUndefined);
  };

  private getFormattedWordsBeforeSave = (words: Word[]) => {
    const reorderedWords = EditorService.reorderWordsRangeIndex(words);

    return reorderedWords.map((word: Word) => ({
      word: word.text,
      speaker: word.speaker,
      start_time: word.start,
      end_time: word.end,
      range_ix: word.range_ix,
      line_ix: word.line_ix ? word.line_ix : 0,
    }));
  };

  private getFormattedRangesBeforeSave = (ranges: JobRange[]) => {
    const formattedRanges = ranges.map((r) => ({
      ...r,
      words: r.words.map((w) => ({
        ...w,
        start_time: w.start_time || w.start,
        end_time: w.end_time || w.end,
        word: w.text || w.word,
      })),
    }));
    return formattedRanges;
  };

  public cancelMeetingProcess = async (id: string) => {
    try {
      await this.colRefs.rooms.doc(id).set({ status: 6 }, { merge: true });
      return { success: true };
    } catch (err) {
      console.log("cancelMeetingProcess failed");
      return { success: false };
    }
  };

  public markAsDelivered = async (meetingId: string) => {
    await this.db
      .collection(this.collections.rooms)
      .doc(meetingId)
      .update({ deliveredToClient: true });
  };
  public getTranscriberRef = (transcriberId: string) => {
    const transcriberRef = this.db.doc(
      `${this.collections.users}/${transcriberId}`
    );
    return transcriberRef;
  };

  public getProoferRef = (prooferId: string) => {
    const prooferRef = this.db.doc(`${this.collections.users}/${prooferId}`);
    return prooferRef;
  };

  public assignTranscriber = async (
    meetingId: string,
    transcriberId: string,
    method: string
  ) => {
    const assignedById = this.currentUserMetadata?.id;

    if (!assignedById) return;
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);
    await meetingRef.update({
      assignedTranscriber: this.db.doc(
        `${this.collections.users}/${transcriberId}`
      ),
      assignedMethod: method,
      isApproved: false,
      transcriberId,
    });
    const snapshotOptions = {
      jobId: meetingId,
      filesToCopy: ["ranges.json", "ready.json"],
      prefix: "assignedTranscriber",
      suffix: transcriberId,
    };

    await CloudFunctionsService.jobSnapshot(snapshotOptions);

    if (method === "assigned") {
      const assignedBy = this.db
        .collection(this.collections.users)
        .doc(assignedById);
      await meetingRef.update({ assignedBy });
      this.addEventToJob("assigned_to_transcriber", meetingId);
    }
    if (method === "requested") {
      this.addEventToJob("transcriber_requested_job", meetingId);
    }
    if (method === "claimed") {
      await meetingRef.update({ isApproved: true });
      this.addEventToJob("transcriber_claimed_job", meetingId);
      this.addEventToJob("at_work", meetingId);
    }
  };

  public changeClientField = async (
    clientId: string,
    newValue: string | number | boolean,
    fieldName: string
  ) => {
    const meetingRef = this.db
      .collection(this.collections.clients)
      .doc(clientId);
    try {
      await meetingRef.update({ [fieldName]: newValue });
      return true;
    } catch (err) {
      logger.log(err, "changeClientField");
      return false;
    }
  };

  public changePrice = async (
    meetingId: string,
    price: number | string | Date,
    isProoferPrice = false
  ) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);

    const priceToUpdate = isProoferPrice ? "prooferPrice" : "price";
    await meetingRef.update({ [priceToUpdate]: price });
  };

  public changeDeadline = async (
    meetingId: string,
    deadline: Date | string
  ) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);

    await meetingRef.update({ deadline });
  };

  public changeUserRole = async (userId: string, newRole: string) => {
    const meetingRef = this.db.collection(this.collections.users).doc(userId);

    await meetingRef.update({ role: newRole });
  };

  public approveTranscriber = async (meetingId: string) => {
    const loggedInUser = this.currentUserMetadata;
    if (!loggedInUser) return;
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);
    await meetingRef.update({
      isApproved: true,
    });
    if (loggedInUser.id) {
      const assignedBy = this.db
        .collection(this.collections.users)
        .doc(loggedInUser.id);
      await meetingRef.update({ assignedBy });
    }
    this.addEventToJob("approve_transcribing", meetingId);
    this.addEventToJob("at_work", meetingId);
    TrackingService.reportEvent("approve_transcriber", { job_id: meetingId });
  };

  public returnToTranscriber = async (meeting: MeetingPreviewData) => {
    const transcriberPendingDebtRef = await this.db
      .collection(this.collections.transcriberDebts)
      .where("userId", "==", meeting.assignedTranscriber?.id)
      .where("roomId", "==", meeting.id)
      .where("status", "==", "pending")
      .get();

    for (const transcriberPendingDebtDoc of transcriberPendingDebtRef.docs) {
      await transcriberPendingDebtDoc.ref.delete();
    }

    await this.db
      .collection(this.collections.rooms)
      .doc(meeting.id)
      .update({ status: 3 });

    TrackingService.reportEvent("status_to_open", { job_id: meeting.id });
  };

  public assignProofer = async (meetingId: string, prooferId: string) => {
    await this.db
      .collection(this.collections.rooms)
      .doc(meetingId)
      .update({
        assignedProofer: this.db.doc(`${this.collections.users}/${prooferId}`),
        prooferId,
        reviewerId: prooferId,
      });
    const snapshotOptions = {
      jobId: meetingId,
      filesToCopy: ["ranges.json", "ready.json"],
      prefix: "assignedReviewer",
      suffix: prooferId,
    };
    await CloudFunctionsService.jobSnapshot(snapshotOptions);
    this.addEventToJob("assigned_reviewer", meetingId);
    this.addEventToJob("reviewing", meetingId);
    TrackingService.reportEvent("assign_transcriber", { job_id: meetingId });
  };

  public unassignTranscriber = async (meetingId: string) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);
    await meetingRef.update({
      assignedTranscriber: null,
      assignedMethod: null,
      assignedBy: null,
      isApproved: false,
      transcriberId: null,
    });

    this.addEventToJob("unassign_transcriber", meetingId);
    this.addEventToJob("open", meetingId);
    TrackingService.reportEvent("unassign_transcriber", { job_id: meetingId });
  };

  public unAssignProofer = async (meetingId: string) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);
    meetingRef.update({ assignedProofer: null, prooferId: null });

    this.addEventToJob("un_assigned_reviewer", meetingId);
    this.addEventToJob("pending", meetingId);

    TrackingService.reportEvent("unassign_proofer", { job_id: meetingId });
  };

  public deliverToProof = async (meetingId: string) => {
    const user = this.currentUserMetadata;
    if (!user) return;
    try {
      const meeting = await this.colRefs.rooms.doc(meetingId).get();
      const meetingData = meeting.data() as MeetingPreviewData;

      if (!meetingData.assignedTranscriber) {
        const error = new Error(
          `deliverToProof - missing assignedTranscriber in ${meetingId}`
        );
        Sentry.captureException(error);
        throw error;
      }

      if (jobTypes[meetingData.jobType as PreviewFormat].remap) {
        EditorService.remapJob(meetingData.id, meetingData.meetingLength);
      }

      await CloudFunctionsService.deliverToProof(
        meetingData.assignedTranscriber.id,
        meetingId
      );

      this.addEventToJob("sent_to_review", meetingId);
      this.addEventToJob("pending", meetingId);

      TrackingService.reportEvent("status_to_proof", { job_id: meetingId });
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  };

  public changeRepresentative = async (
    meetingId: string,
    representative: string
  ) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);

    await meetingRef.update({ representative });
  };

  public changeRevenue = async (meetingId: string, revenue: number) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);

    await meetingRef.update({ revenue });
  };

  public changeInvoiceSent = async (meetingId: string, invoiceSent: string) => {
    const meetingRef = this.db
      .collection(this.collections.rooms)
      .doc(meetingId);

    await meetingRef.update({ invoiceSent });
  };

  public approveProofing = async (meeting: MeetingPreviewData) => {
    const loggedInUser = this.currentUserMetadata;
    if (!loggedInUser) return;
    try {
      await CloudFunctionsService.approveProofing(meeting.id, loggedInUser.id);
      TrackingService.reportEvent("status_to_archive", { job_id: meeting.id });
    } catch (err) {
      logger.error(err, "approveProofing");
      throw err;
    }
  };

  public getPayments = async (id: string) => {
    const paymentsDoc = await this.db
      .collection(this.collections.payments)
      .doc(id)
      .get();
    const data = paymentsDoc.data();
    const debts: Debt[] = [];
    const paidApprovals: PaidApproval[] = [];
    const invoiceRefs: InvoiceRef[] = [];
    if (data) {
      if (data.debts)
        data.debts.forEach((data: DocumentData) => {
          const debt: Debt = {
            createdAt: new Date(data.createdAt.seconds * 1000),
            reason: data.reason,
            sum: data.sum,
            id: data.id,
          };
          if (data.wasPaid) debt.wasPaid = data.wasPaid;
          debts.push(debt);
        });
      if (data.paidApprovals)
        data.paidApprovals.forEach((data: DocumentData) => {
          const paidApproval: PaidApproval = {
            createdAt: new Date(data.createdAt.seconds * 1000),
            paidFor: data.paidFor,
            sum: data.sum,
          };
          paidApprovals.push(paidApproval);
        });
      if (data.invoiceRefs)
        data.invoiceRefs.forEach((data: DocumentData) => {
          const invoiceRef: InvoiceRef = {
            month: data.month,
            url: data.url,
          };
          invoiceRefs.push(invoiceRef);
        });
    }
    return { debts, paidApprovals, invoiceRefs };
  };

  public updateInvoiceRefs = async (invoiceRefs: InvoiceRef[], id: string) => {
    await this.db
      .collection(this.collections.payments)
      .doc(id)
      .update({ invoiceRefs });
  };

  public updatePaidApprovals = async (
    paidApprovals: PaidApproval[],
    id: string
  ) => {
    await this.db
      .collection(this.collections.payments)
      .doc(id)
      .update({ paidApprovals });
  };

  public updateDebts = async (debts: Debt[], id: string) => {
    await this.db
      .collection(this.collections.payments)
      .doc(id)
      .update({ debts });
  };

  // public getParticipants = async (id: string, additionalMemebers: string[], renamedIdentifiedParticipandsIds: string[]) => {
  //     const membersQuerySnapshot = await this.db.collection(this.collections.rooms).doc(id).collection("members").get();
  //     const membersData = membersQuerySnapshot.docs.map((d: any) => d.data());

  //     const participants = additionalMemebers || [];
  //     const identifiedParticipants: {[id: string]: string} = {};
  //     membersData.forEach(m => {
  //         if(!renamedIdentifiedParticipandsIds || !renamedIdentifiedParticipandsIds.includes(m.id))
  //             participants.push(m.name)
  //         identifiedParticipants[m.id] = m.name;
  //     });

  //     return { participants, identifiedParticipants }
  // }
  public getRawJob = async (jobId: string) => {
    const roomDoc = await this.db
      .collection(this.collections.rooms)
      .doc(jobId)
      .get();
    const roomData = roomDoc.data();
    return roomData;
  };
  public getRoomData = async (
    id: string,
    options?: { filename?: string }
  ): Promise<RoomData> => {
    try {
      const roomDoc = await this.db
        .collection(this.collections.rooms)
        .doc(id)
        .get();
      const roomData = roomDoc.data();
      const words = await this.getRoomWords(id, options);
      const { roomAssets, offsets } = await this.getRoomAssets(id);

      if (!roomData || !words || !roomAssets) {
        throw new Error("getMeeting failed");
      }
      return {
        roomId: id,
        name: roomData.name,
        speakers:
          roomData.speakers || EditorService.getSpeakersFromWords(words),
        deadline: new Date(roomData.deadline.seconds * 1000),
        creationTime: new Date(roomData.creationTime.seconds * 1000),
        meetingLength: roomData.meetingLength,
        previewFormat: roomData.jobType || "protocol",
        price: roomData.price,
        prooferPrice: roomData.prooferPrice,
        assignedProofer: roomData.assignedProofer,
        assignedTranscriber: roomData.assignedTranscriber,
        status: roomData.status,
        lang: roomData.lang,
        processProgress: _.get(roomData, "processProgress.progress"),
        processProgressLastUpdate: _.get(roomData, "processProgress.lastUpdate")
          ? roomData.processProgress.lastUpdate.toDate()
          : null,
        process: {
          status: roomData.process?.status || "ready",
          progress: roomData.process?.progress || null,
          errors: roomData.process?.errors || [],
        },
        timeTrack: this.getTimeTrack(roomData.timeTrack),
        editProgress: roomData.editProgress || 0,
        clientId: roomData.clientId,
        split: roomData.split,
        assets: roomAssets,
        translation: roomData.translation,
        words,
        offsets,
        workTime: roomData.workTime || { transcribe: {}, review: {} },
        timeSynced: roomData.timeSynced,
        notes: roomData.notes || "",
      };
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  public getJobData = async (id: string): Promise<JobDocument> => {
    try {
      const jobDoc = await this.db
        .collection(this.collections.rooms)
        .doc(id)
        .get();
      const jobData = jobDoc.data();
      if (jobData) {
        _.update(jobData, "lang.output", (output) =>
          _.isArray(output) ? output : [jobData.lang.output]
        );
        _.update(jobData, "lang.input", (input) =>
          _.isArray(input) ? input : [jobData.lang.input]
        );
      }
      return { id, ...jobData } as JobDocument;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  public getRoomWords = async (id: string, options?: { filename?: string }) => {
    try {
      const filename = options?.filename || "ready.json";
      const wordsUrl: string = await this.storage
        .ref(`rooms/${id}/${filename}`)
        .getDownloadURL();
      const wordsRequest = await axios.get(wordsUrl);

      if (wordsRequest.status !== 200) {
        throw new Error("getRoomWords failed");
      }

      let words = wordsRequest.data;

      if (!words || _.isEmpty(words)) {
        words = getDefaultWords();
      }

      const reorderedWords = EditorService.reorderWordsRangeIndex(words);
      const validWords = _.values(_.omitBy(reorderedWords, (w) => !w.speaker));
      const formattedWords = validWords
        .map((word: any) => ({
          text: word.word,
          start: word.start_time,
          end: word.end_time,
          ..._.pick(word, [
            "word",
            "range_ix",
            "line_ix",
            "start_time",
            "end_time",
            "speaker",
          ]),
        }))
        .filter((word: Word) => !!word.text);
      return formattedWords;
    } catch (err) {
      logger.error(err, "getRoomWords");
    }
  };

  public getAuditEvents = async (jobsIds: string[]) => {
    const aduits: { [id: string]: AuditData[] } = {};
    await Promise.all(
      jobsIds.map(async (jobId) => {
        const auditEvents = [];
        const eventRef = await this.db
          .collection(this.collections.rooms)
          .doc(jobId)
          .collection(this.collections.audit)
          .get();
        for (const doc of eventRef.docs) {
          const audit = doc.data();
          const date = audit.creationTime.toDate();

          const formatedDate = format(date, "dd/MM/yyyy");
          const time = format(date, "HH:mm");
          const formatedAudit = {
            creationTime: audit.creationTime,
            type: audit.type,
            username: audit.triggeredBy.name,
            userId: audit.triggeredBy.id,
            date: formatedDate,
            time,
          };
          auditEvents.push(formatedAudit);
        }
        const orderedAudit = _.orderBy(auditEvents, ["creationTime"], ["asc"]);
        aduits[jobId] = orderedAudit;
      })
    );
    return aduits;
  };

  public createSubCollection = async ({
    collection,
    id,
    subCollection,
    data,
  }: {
    collection: string;
    id: string;
    subCollection: string;
    data: any;
  }) => {
    const ref = this.db
      .collection(collection)
      .doc(id)
      .collection(subCollection)
      .doc(this.generateID());
    await ref.set(data);
  };

  public addEventToJob = async (
    type:
      | "done"
      | "transcriber_claimed_job"
      | "pending"
      | "at_work"
      | "reviewing"
      | "open"
      | "un_assigned_reviewer"
      | "sent_to_archive"
      | "sent_to_review"
      | "approve_transcribing"
      | "uploaded"
      | "assigned_to_transcriber"
      | "transcriber_requested_job"
      | "unassign_transcriber"
      | "assigned_reviewer",
    jobId: string
  ) => {
    const user = this.currentUserMetadata;
    if (!user) return;
    await this.createSubCollection({
      collection: "rooms-v2",
      subCollection: "audit",
      id: jobId,
      data: {
        type,
        creationTime: new Date(),
        triggeredBy: {
          id: user.id,
          name: user.username,
        },
      },
    });
  };

  public getJobRanges = async (
    id: string,
    options?: { filename?: string; filterEmptyRanges?: boolean }
  ) => {
    try {
      const filename = options?.filename || "ranges.json";
      const rangesUrl: string = await this.storage
        .ref(`rooms/${id}/${filename}`)
        .getDownloadURL();
      const ranges = await axios.get(rangesUrl);
      if (ranges.status !== 200) {
        return [];
      }

      // TEMP - REMOVE WHEN ALIGNING WITH ALGO TEAM
      const rangesData = ranges.data.map((r: JobRange) => {
        if (_.isEmpty(r.words) && options?.filterEmptyRanges) {
          return null;
        }
        return {
          ...r,
          words: r.words.map((w) => ({
            ...w,
            start: w.start || w.start_time,
            end: w.end || w.end_time,
            text: w.text || w.word,
          })),
        };
      });

      return _.compact(rangesData);
      // TEMP - REMOVE WHEN ALIGNING WITH ALGO TEAM
    } catch (err) {
      return [];
      console.log(err);
      throw new Error(err);
    }
  };

  public getJobMarkers = async (jobId: string) => {
    try {
      const markers: JobMarkers = {};
      const markersFiles: firebase.storage.ListResult = await this.storage
        .ref(`${this.buckets.rooms}/${jobId}/markers`)
        .listAll();
      if (markersFiles && markersFiles.items) {
        await Promise.all(
          markersFiles.items.map(async (markersFile) => {
            const markersType = markersFile.name.split(".")[0];
            const markerFileUrl = await markersFile.getDownloadURL();
            const markerFileRequest = await axios.get(markerFileUrl);
            markers[markersType] = markerFileRequest.data;
          })
        );
      }

      return markers;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  public getJobAnnotations = async (
    id: string,
    options?: { filename?: string }
  ) => {
    try {
      const filename = options?.filename || "annotations.json";
      const annotationsUrl: string = await this.storage
        .ref(`rooms/${id}/${filename}`)
        .getDownloadURL();
      const annotations = await axios.get(annotationsUrl);

      if (annotations.status !== 200) {
        throw new Error("getJobAnnotations failed");
      }

      if (_.isArray(annotations.data)) {
        const annObj = {};
        for (let i = 0; i < _.compact(annotations.data).length; i++) {
          _.set(annObj, i, [annotations.data[i]]);
        }
        console.log(annObj);
        return annObj;
      } else {
        return annotations.data;
      }
    } catch (err) {
      return [];
      console.log(err);
      throw new Error(err);
    }
  };

  public getRoomAssets = async (id: string) => {
    const roomAssets: RoomAsset[] = [];
    //@ts-ignore
    const allMediaSources: ListResult = await this.storage
      .ref(`${this.buckets.rooms}/${id}`)
      .listAll();

    const filteredSortedMediaRefs = this.filterSortAssetsByFormat(
      allMediaSources,
      new Set([...this.audioFormats, ...this.videoFormats])
    );
    let offsets: { [roomName: string]: number } | undefined;
    const wordsFileRef = allMediaSources.items.find(
      (item) => item.name === "offsets.json"
    );
    if (wordsFileRef) {
      offsets = await (await axios.get(await wordsFileRef.getDownloadURL()))
        .data;
    }

    for (const mediaRef of filteredSortedMediaRefs) {
      const url = await mediaRef.getDownloadURL();
      const { customMetadata } = await mediaRef.getMetadata();
      const mediaFormat = _.last(mediaRef.name.split("."))?.toLowerCase() || "";
      const mediaType = this.videoFormats.includes(mediaFormat)
        ? "video"
        : "audio";

      let frameRate;
      if (customMetadata?.frameRate) {
        frameRate = Number(customMetadata.frameRate);
      }

      roomAssets.push({
        name: mediaRef.name ? mediaRef.name : "",
        src: url,
        type: mediaType,
        frameRate: Number(customMetadata?.frameRate || 25),
      });
    }
    return { roomAssets, offsets };
  };

  public getSpeakerSamples = async (clientId: string) => {
    const speakerSamples: SpeakerSample[] = [];

    // @ts-ignore
    const clientSamples: ListResult = await this.storage
      .ref(`${this.buckets.voiset}/${clientId}/speakers`)
      .listAll();

    const speakerNames: { [speakerId: string]: string } = {};
    const speakerDocs = await this.db
      .collection(this.collections.speakers)
      .where("clientId", "==", clientId)
      .get();
    _.each(speakerDocs.docs, (doc) => (speakerNames[doc.id] = doc.get("name")));

    for (const sample of clientSamples.items) {
      const speakerId = sample.name.split(".")[0];

      if (_.find(speakerSamples, { id: speakerId })) continue;

      const speakerName = speakerNames[speakerId];
      const url = await sample.getDownloadURL();

      const speakerSample = {
        id: speakerId,
        name: speakerName,
        sample: url,
      };

      speakerSamples.push(speakerSample);
    }

    return speakerSamples;
  };

  public getTimeTrack = (timeTrack: any) => {
    try {
      if (_.isNumber(timeTrack)) {
        return {
          transcriber: {
            totalTime: timeTrack,
          },
          proofer: {},
        };
      }
      if (_.isNumber(_.get(timeTrack, "transcriber"))) {
        return {
          transcriber: {
            totalTime: timeTrack.transcriber,
          },
          proofer: {
            totalTime: timeTrack.proofer || 0,
          },
        };
      }
      if (_.isNil(timeTrack)) {
        return {
          transcriber: {},
          proofer: {},
        };
      }
      return timeTrack;
    } catch (err) {
      logger.error(err, "getTimeTrack");
      return null;
    }
  };
  public filterSortAssetsByFormat = (
    assets: ListResult,
    formats: Set<string>
  ) => {
    const filtered = assets.items.filter((asset: Reference) => {
      const assetFormat = _.last(asset.name.split("."))?.toLowerCase();
      return (
        assetFormat &&
        formats.has(assetFormat) &&
        _.some(["ready", "raw", "stream", "_sr_"], (name) =>
          asset.name.includes(name)
        )
      );
    });
    //@ts-ignore
    const sorted = filtered.sort((asset1: Reference, previousAsset) => {
      const aFormat = _.last(asset1.name.split("."));
      const previousAFormat = _.last(previousAsset.name.split("."));

      if (previousAFormat && this.videoFormats.includes(previousAFormat)) {
        if (previousAsset.name.includes("orig_raw")) {
          return 0;
        }
      }
      if (aFormat && this.videoFormats.includes(aFormat.toLowerCase())) {
        if (asset1.name === "ready") return 0;
        return -1;
      }
      if (aFormat && this.audioFormats.includes(aFormat.toLowerCase())) {
        if (asset1.name === "ready") return 0;
        return -1;
      }
    });

    return sorted;
  };

  public getNoteFiles = async (jobId: string): Promise<NoteFile[] | []> => {
    const noteFilesRefs: NoteFile[] = [];
    const noteFiles: firebase.storage.ListResult = await this.storage
      .ref(`${this.buckets.rooms}/${jobId}/notes`)
      .listAll();
    if (noteFiles && noteFiles.items) {
      await Promise.all(
        noteFiles.items.map(async (fileNote) => {
          const _url = await fileNote.getDownloadURL();
          const metaData = await fileNote.getMetadata();
          noteFilesRefs.push({
            name: fileNote.name,
            type: fileNote.name.split(".")[fileNote.name.split(".").length - 1],
            url: _url,
            id: _.get(metaData, "customMetadata.id"),
            userName: _.get(metaData, "customMetadata.userName"),
            date: _.get(metaData, "customMetadata.date"),
          });
        })
      );
      return noteFilesRefs;
    } else {
      return [];
    }
  };

  public getJobRevisions = async (
    jobData: JobData | RoomData,
    filter?: string[],
    allowedReset = true
  ) => {
    const revisions: firebase.storage.ListResult = await this.storage
      .ref(`${this.buckets.rooms}/${jobData.roomId}/revisions`)
      .listAll();
    const revisionTypes: { [type: string]: RoomRevision[] } = {};
    const sortedRevisions = _.orderBy(revisions.items, "name", "desc");
    await Promise.all(
      sortedRevisions.map(async (revision) => {
        const name = revision.name.split(".")[0];
        const type = name.includes("_") ? name.split("_")[0] : name;

        let fileType = "";

        if (
          [
            "assignedTranscriber",
            "assignedReviewer",
            "archive",
            "beforeResetJob",
            "reset",
            "restoreBackup",
          ].includes(revision.name.split("_")[1])
        ) {
          fileType = revision.name.split("_")[1];
        }

        if (!revisionTypes[type]) revisionTypes[type] = [];
        if (!fileType && revisionTypes[type].length === 27) return;
        if (filter && !filter.includes(type)) return;

        const {
          customMetadata: metadata,
          timeCreated,
          size,
        } = await revision.getMetadata();

        revisionTypes[type].push({
          filename: revision.name,
          type,
          fileType,
          date: new Date(timeCreated),
          metadata,
          size,
        });
      })
    );

    const latestRevisionsByType = _.mapValues(revisionTypes, (r) =>
      _.orderBy(r, ["date"], "desc")
    );

    const latestRevisions = _.flatten(_.values(latestRevisionsByType));

    if (allowedReset) {
      const resetRevision = {
        filename: "reset",
        type: "ranges",
        date: jobData.creationTime,
        fileType: "reset",
      };
      latestRevisions.push(resetRevision);
    }

    return latestRevisions;
  };

  public restoreJob = async (jobId: string, filename: string) => {
    if (!jobId || !filename) throw "Job restore failed";

    if (filename === "reset") {
      const userId = this.currentUserMetadata?.id
        ? this.currentUserMetadata?.id
        : "";
      const restoreJobResult = await CloudFunctionsService.resetJob(
        jobId,
        userId
      );
      return restoreJobResult.data;
    }

    const restoreJobResult = await CloudFunctionsService.restoreJob(
      jobId,
      filename
    );
    return restoreJobResult.data;
  };

  public getRoomValidExtenstions = async () => {
    const doc = await this.db.collection("configs").doc("editor").get();
    const data = doc.data();
    if (data) {
      const ext: string[] = data.supportedExt;
      return ext;
    } else return null;
  };

  public createNewJob = async (
    job: PendingJob,
    proggressCb: (proggress: number) => void,
    slicesJson?: SlicesJson,
    clientsSpeakers?: any[]
  ) => {
    const newRoom: Partial<NewMeetingData> = this.getFormattedRoomBeforeUpload(
      job.meeting,
      job.id
    );
    if (job.meeting.jobType === "subtitles-translation") {
      const { ownerId, ...jobData } = job.meeting;
      await CloudFunctionsService.createSubtitlesTranslationJob({
        jobId: job.id,
        jobData,
        isNewJob: true,
      });
      return;
    } else if (!jobTypes[job.meeting.jobType].stt) {
      newRoom.status = 3;
      newRoom.processProgress = { lastUpdate: new Date() };

      if (jobTypes[job.meeting.jobType].defaultFile) {
        const readyJson = JSON.stringify([
          {
            word: ".",
            speaker: "speaker",
            start_time: 0,
            end_time: 0,
            range_ix: 0,
          },
        ]);

        const file = new File([readyJson], "ready.json", {
          type: "application/json",
        });

        await this.uploadFile(file, job.id, "ready");
      }

      const roomsRef = this.db.collection(this.collections.rooms).doc(job.id);
      await roomsRef.set(newRoom);
      return;
    }

    const roomsRef = this.db.collection(this.collections.rooms).doc(job.id);

    await roomsRef.set(newRoom);
    if (slicesJson) {
      const samples: any = {};
      const speakers: any = {};

      _.mapKeys(slicesJson, (val, speakerName) => {
        const speaker = clientsSpeakers?.find((s) => s.name === speakerName);
        samples[speaker.speakerId] = slicesJson[speakerName];
        speakers[speaker.speakerId] = speakerName;
      });

      const speakerBankSlices = {
        samples,
        clientId: _.get(newRoom, "ownerId.id"),
        speakers,
      };

      const blob = new Blob([JSON.stringify(speakerBankSlices)], {
        type: "application/json",
      });
      const fileRef = this.storage.ref(
        `${this.buckets.rooms}/${job.id}/slices.json`
      );
      await fileRef.put(blob);
    }
    const newJob: NewJob = {
      operation: "manual_upload",
      algorithm: newRoom.algorithm || "none",
      collection: this.collections.rooms,
      ownerId: newRoom.ownerId ? newRoom.ownerId.id : "",
      roomId: job.id,
    };
    await this.db.collection(this.collections.jobs).doc(job.id).set(newJob);
    const unsubscribe = this.db
      .collection(this.collections.rooms)
      .onSnapshot((snap) => {
        const changes = snap.docChanges();
        changes.forEach((change) => {
          const doc = change.doc;
          if (doc.id === job.id) {
            const data = doc.data();
            if (data) {
              if (data.processingState) {
                proggressCb(data.processingState);
                if (data.processingState === 100) unsubscribe();
              }
            }
          }
        });
      });
    this.addEventToJob("uploaded", job.id);
    this.addEventToJob("open", job.id);
  };

  public createNewStreamJob = async (
    job: Partial<JobData>,
    clientsSpeakers?: any[]
  ) => {
    try {
      const formattedJob = {
        additionalMemebers: ["speaker"],
        algorithm: "none",
        assignedMethod: null,
        assignedProofer: null,
        assignedTranscriber: null,
        clientId: "_zsy7vju7au",
        creationTime: new Date(),
        deadline: new Date(),
        id: this.generateID(),
        isApproved: false,
        isStreaming: true,
        jobType: "live-interview" as PreviewFormat,
        lang: {
          input: ["he-IL"],
          output: ["he-IL"],
        },
        meetingLength: 0,
        name: job.name || "stream",
        noSpeech: [],
        ownerId: this.colRefs.clients.doc("_zsy7vju7au"),
        price: 0,
        prooferPrice: 0,
        representative: "",
        revenue: 0,
        invoiceSent: "false",
        speakers: ["speaker"],
        status: 3,
        timeSynced: false,
        wasPaid: true,
      };
      const newRoom: Partial<NewMeetingData> = this.getFormattedRoomBeforeUpload(
        formattedJob,
        formattedJob.id
      );

      const roomsRef = this.db
        .collection(this.collections.rooms)
        .doc(formattedJob.id);

      await roomsRef.set(newRoom);

      const readyFileRef = this.storage
        .ref()
        .child(`rooms/${formattedJob.id}/ready.json`);
      const readyBlob = new Blob([JSON.stringify(getDefaultWords())], {
        type: "application/json",
      });
      await readyFileRef.put(readyBlob, {
        cacheControl: "public,max-age=0",
      });

      this.addEventToJob("open", formattedJob.id);

      return formattedJob;
    } catch (err) {
      logger.error("Failed to create new stream job");
    }
  };

  public uploadImg = async (
    file: Blob,
    cbUploadProggress: (transferred: number, total: number) => void,
    userId: string
  ) => {
    const fileName = `${userId}.png`;
    const fileRef = this.storage.ref(`user_photos/${fileName}`);
    const uploadTask = fileRef.put(file);
    uploadTask.on("state_changed", (snapshot) =>
      cbUploadProggress(snapshot.bytesTransferred, snapshot.totalBytes)
    );
    const imgUrl = await uploadTask.then((snap) => {
      return snap.ref.getDownloadURL();
    });
    await this.db
      .collection(this.collections.users)
      .doc(userId)
      .update({ imgUrl });
    return imgUrl;
  };

  public uploadNoteFile = async (
    file: File,
    id: string,
    user: LoggedInUser | null
  ): Promise<NoteFile> => {
    const extension = mime.extension(file.type);
    const fileName = file.name.substring(0, file.name.lastIndexOf("."));
    let fileRef = this.storage.ref(
      `${this.buckets.rooms}/${id}/notes/${fileName}.${extension}`
    );

    const isExists = await this.isFileExists(fileRef);
    if (isExists) {
      const unique = Math.random().toString(36).substr(2, 9);
      fileRef = this.storage.ref(
        `${this.buckets.rooms}/${id}/notes/${fileName}_copy_${unique}.${extension}`
      );
    }

    const uploadTask = fileRef.put(file, {
      customMetadata: {
        userId: user ? user.id : "",
        date: format(Date.now(), "dd.MM.yyyy hh:mm"),
        userName: user ? user.username : "",
        id: new Date().getUTCMilliseconds().toString(),
      },
    });
    const snap = await uploadTask;
    const url = await snap.ref.getDownloadURL();
    const note = {
      url: url,
      name: snap.ref.name,
      type: snap.ref.name.split(".")[snap.ref.name.split(".").length - 1],
      id: snap.metadata.customMetadata ? snap.metadata.customMetadata?.id : "",
      userName: snap.metadata.customMetadata
        ? snap.metadata.customMetadata?.userName
        : "",
      date: snap.metadata.customMetadata
        ? snap.metadata.customMetadata?.date
        : "",
    };
    return note;
  };

  public uploadInvoice = async (
    file: File,
    month: string,
    year: string,
    id: string
  ) => {
    const extension = mime.extension(file.type);
    const fileName = `${month}-${year}`;
    const fileRef = this.storage.ref(`invoices/${id}/${fileName}.${extension}`);
    const uploadTask = fileRef.put(file);
    const invoiceUrl = await uploadTask.then((snap) => {
      return snap.ref.getDownloadURL();
    });
    return invoiceUrl;
  };

  public uploadContract = async (file: File, id: string) => {
    try {
      const extension = mime.extension(file.type);
      const fileName = `contract_${format(Date.now(), "dd.MM.yyyy_hh:mm")}`;
      const fileRef = this.storage.ref(`users/${id}/${fileName}.${extension}`);
      const uploadTask = fileRef.put(file);
      await uploadTask;
      await this.db
        .collection(this.collections.users)
        .doc(id)
        .update({ hasContract: true });
    } catch (err) {
      console.log("uploadContract failed", err);
      throw new Error(err);
    }
  };

  public uploadAudioFile = async (
    file: File,
    id: string,
    cbUploadProggress: (transferred: number, total: number) => void
  ) => {
    const extension = mime.extension(file.type);
    const fileName = "raw" + "." + extension;
    const fileRef = this.storage.ref(`${this.buckets.rooms}/${id}/${fileName}`);
    const uploadTask = fileRef.put(file);
    uploadTask.on("state_changed", (snapshot) =>
      cbUploadProggress(snapshot.bytesTransferred, snapshot.totalBytes)
    );
    const fileUrl = await uploadTask.then((snap) => {
      return snap.ref.getDownloadURL();
    });
    return fileUrl;
  };

  public uploadFile = async (file: File, id: string, name = "raw") => {
    let extension = mime.extension(file.type);
    if (!extension) {
      extension = file.name.split(".").pop() as string;
    }
    const fileName = name + "." + extension;
    const fileRef = this.storage.ref(`${this.buckets.rooms}/${id}/${fileName}`);
    await fileRef.put(file);

    return extension;
  };

  public saveTranslations = async (translations: {
    [lang: string]: { [key: string]: string };
  }) => {
    for (const lang in translations) {
      if (Object.prototype.hasOwnProperty.call(translations, lang)) {
        const langTranslations = translations[lang];
        const fileName = `${lang}.json`;
        const transFileRef = this.storage
          .ref()
          .child(`translations/${fileName}`);
        const translationBlob = new Blob([JSON.stringify(langTranslations)], {
          type: "application/json",
        });

        await transFileRef.put(translationBlob);
      }
    }
  };

  public deleteRoomInStorage = async (id: string) => {
    console.log(`Can't delete folders in Firebase! ${id}`);
  };

  public generateID = () => Math.random().toString(36).substr(2, 10);

  private _addDebt = async (debt: Debt, id: string) => {
    const payments = await this.getPayments(id);
    payments.debts.unshift(debt);
    await this.updateDebts(payments.debts, id);
  };

  private _getUserRole = async (userId: string) => {
    const doc = await this.db
      .collection(this.collections.users)
      .doc(userId)
      .get();
    const user = doc.data();
    if (user) return user.role;
  };

  private _getMeetingPreviewData = async (
    meetingDoc: DocumentData,
    clients: { id: string; name: string }[],
    checkNotes = false
  ) => {
    const data = meetingDoc.data();
    const hiddenNotesBadge = checkNotes
      ? await this.hasNotes(meetingDoc.id, data)
      : false;

    const lastUpdate = _.get(data, "processProgress.lastUpdate");
    const deadline = _.get(data, "deadline");
    const clientDeadline = _.get(data, "clientDeadline");
    const creationTime = _.get(data, "creationTime");
    const output = _.isArray(data.lang.output)
      ? data.lang.output
      : [data.lang.output];
    const input = _.isArray(data.lang.input)
      ? data.lang.input
      : [data.lang.input];

    const client = clients.find(
      (c) => data.ownerId?.id && c.id === data.ownerId.id
    );
    const meeting: MeetingPreviewData = {
      id: meetingDoc.id,
      name: data.name,
      clientId: data.clientId,
      assignedTranscriber: data.assignedTranscriber,
      assignedMethod: data.assignedMethod,
      doneDate: data.doneDate || null,
      invoiceSent: data.invoiceSent,
      clientName: client && client.name,
      isApproved: data.isApproved,
      representative: data.representative,
      representativePrice: data.representativePrice,
      revenueCurrency: data.revenueCurrency,
      pageCount: data.pageCount,
      revenue: data.revenue,
      assignedProofer: data.assignedProofer,
      assignedBy: data.assignedBy,
      speakers: data.speakers,
      deadline: deadline ? deadline.toDate() : false,
      clientDeadline:
        clientDeadline && clientDeadline.toDate
          ? clientDeadline.toDate()
          : null,
      creationTime: creationTime ? creationTime.toDate() : false,
      meetingLength: data.meetingLength,
      previewFormat: data.jobType,
      jobType: data.jobType,
      lang: {
        input,
        output,
      },
      price: data.price,
      archivedAt: data.archivedAt?.seconds ? data.archivedAt.toDate() : null,
      notes: data.notes,
      prooferPrice: data.prooferPrice || 0,
      status: data.status,
      processProgress: _.get(data, "processProgress.progress"),
      processProgressLastUpdate:
        lastUpdate && lastUpdate.toDate ? lastUpdate.toDate() : false,
      process: {
        status: data.processProgress?.status || "ready",
        progress: data.processProgress?.progress || null,
        errors: data.processProgress?.errors || [],
      },
      editProgress: data.editProgress || 0,
      reviewerId: data.reviewerId,
      transcriberId: data.transcriberId,
      translation: data.translation,
      workTime: data.workTime || { transcribe: {}, review: {} },
      hasNotes: hiddenNotesBadge,
      v3id: data.v3id,
    };
    return meeting;
  };

  private _getArchivedMeetingData = async (
    meetingDoc: DocumentData,
    clientsById: _.Dictionary<{
      id: string;
      name: string;
    }>
  ) => {
    const data = meetingDoc.data();

    const lastUpdate = _.get(data, "processProgress.lastUpdate");

    const deliveredToClient = data.deliveredToClient ? true : false;
    const ownerName =
      clientsById[data.ownerId?.id] && clientsById[data.ownerId.id].name;

    const meeting: ArchivedMeetingData = {
      id: meetingDoc.id,
      status: data.status,
      name: data.name,
      meetingLength: data.meetingLength,
      representative: data.representative,
      representativePrice: data.representativePrice,
      invoiceSent: data.invoiceSent,
      revenue: data.revenue,
      assignedProofer: data.assignedProofer,
      archivedAt: data.archivedAt?.seconds ? data.archivedAt.toDate() : null,
      deliveredToClient,
      ownerId: data.ownerId,
      translation: data.translation,
      processProgressLastUpdate:
        lastUpdate && lastUpdate.toDate ? lastUpdate.toDate() : false,
      ownerName: ownerName ? ownerName : "no_owner",
      assignedTranscriber: data.assignedTranscriber,
      previewFormat: data.jobType,
      price: data.price,
      prooferPrice: data.prooferPrice,
      clientId: data.clientId,
      reviewerId: data.reviewerId,
      transcriberId: data.transcriberId,
    };
    return meeting;
  };

  private getOwnerName = async (data: DocumentData, clients: any) => {
    if (!_.isNil(data.ownerId)) {
      const clientDoc = await data.ownerId.get();
      const clientData = clientDoc.data();
      return clientData ? clientData.name : "no_owner";
    }
    return "no_owner";
  };
  private _combineUserInfo = async (
    meetings: MeetingPreviewData[],
    type: string
  ) => {
    const combined = meetings.map(async (meeting) => {
      const userRef = meeting.assignedTranscriber;
      if (userRef) {
        const userDoc = await userRef.get();
        const data = userDoc.data();
        if (data) {
          return {
            ...meeting,
            assignedUserInfo: {
              id: userDoc.id,
              imgUrl: data.imgUrl,
            },
          };
        }
      }
    });
    return Promise.all(combined);
  };

  private _getCurrDebt = async (id: string, type: string) => {
    const payments = await this.getPayments(id);
    return PaymentsService.getCurrDebt(payments, type);
  };

  public utilMeetingsReset = async () => {
    const ownerIds: string[] = [];
    const members = [
      "יוסי",
      "צילה",
      "גילה",
      "בילה",
      "ניסו",
      "שישו",
      "שמחה",
      "מקס",
      "מוריס",
      "יאנה",
      "דנה",
      "חנה",
      "אליאנה",
      "מריה",
      "תמר",
      "חני",
      "רני",
      "דני",
      "לירן",
      "אבירן",
      "בני",
      "עדי",
      "נעמה",
      "סאני",
      "גון",
    ];
    const owners = await this.db.collection(this.collections.clients).get();
    owners.docs.forEach((doc) => {
      ownerIds.push(doc.id);
    });
    // const langOptArr = [localeCodes.heIL, localeCodes.enUS, localeCodes.arEG, localeCodes.ruRU];
    const getRandomInt = (min: number, max: number) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    };
    const meetingsRef = await this.db.collection(this.collections.rooms).get();
    meetingsRef.docs.forEach(async (doc) => {
      const id = doc.id;
      // const ownerId = ownerIds[getRandomInt(0, ownerIds.length)];
      const membersClone = [...members];
      const speakers: string[] = [];
      const length = getRandomInt(1, 6);
      for (let i = length; i > 0; i--) {
        const idx = getRandomInt(0, membersClone.length);
        speakers.push(membersClone[idx]);
        membersClone.splice(idx, 1);
      }
      // const lang = {
      //     input: langOptArr[getRandomInt(0, langOptArr.length)],
      //     output: langOptArr[getRandomInt(0, langOptArr.length)]
      // };
      await this.db
        .collection(this.collections.rooms)
        .doc(id)
        .update({
          assignedTranscriber: null,
          assignedProofer: null,
          isApproved: false,
          assignedMethod: null,
          status: 3,
          speakers,
          meetingLength: getRandomInt(20, 6000),
          deadline: new Date(
            getRandomInt(Date.now() + 100000000, Date.now() + 1000000000)
          ),
          deliveredToClient: firebase.firestore.FieldValue.delete(),
          archivedAt: firebase.firestore.FieldValue.delete(),
          deliveredAt: firebase.firestore.FieldValue.delete(),
          assignedBy: firebase.firestore.FieldValue.delete(),
        });
    });
  };

  public getSubtitlesUrl = async (roomId: string, subtitleFormat: string) => {
    const subtitlesRef = this.storage.ref(
      `${this.buckets.rooms}/${roomId}/subtitles.${subtitleFormat}`
    );
    return await subtitlesRef.getDownloadURL();
  };

  public downloadFile = async (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const blob = xhr.response;
      saveAs(blob, filename);
    };
    xhr.open("GET", url);
    xhr.send();
  };

  public saveRoomPeaks = async (roomId: string, peaks: number[]) => {
    const peaksRef = this.storage.ref().child(`rooms/${roomId}/peaks.json`);
    const peaksBlob = new Blob([JSON.stringify(peaks)], {
      type: "application/json",
    });

    await peaksRef.put(peaksBlob);
  };

  public updateUserMail = async (email: string) => {
    await this.db
      .collection(this.collections.users)
      .doc(this.auth.currentUser?.uid)
      .update({ mail: email });
  };

  public updateUserPhone = async (phonenumber: string) => {
    await this.db
      .collection(this.collections.users)
      .doc(this.auth.currentUser?.uid)
      .update({ phonenumber });
  };

  public subscribeActionToUser = async (
    uid: string,
    action: (doc: any) => void
  ) => {
    return this.db
      .collection(this.collections.users)
      .doc(uid)
      .onSnapshot((doc) => {
        action(doc.data());
      });
  };

  private isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  public getRef = (ref: string) => {
    if (ref && this.isJson(ref)) {
      const docRef = JSON.parse(ref);
      const id = docRef && _.last(docRef._path?.segments as string[]);
      return id && id;
    }
  };

  public setExportConfigData = async (exportConfigData: ExportConfigData) => {
    await this.colRefs.configs.doc("exportConfig").set(exportConfigData);
  };

  public setSubtitlesConfigData = async (
    exportConfigData: ExportConfigData
  ) => {
    await this.colRefs.configs
      .doc("subtitlesExportConfig")
      .set(exportConfigData);
  };

  public getExportConfigData = async (): Promise<ExportConfigData> => {
    return (
      await this.colRefs.configs.doc("exportConfig").get()
    ).data() as ExportConfigData;
  };

  public getGlobalValidationsConfigData = async (
    jobType: string
  ): Promise<any | undefined> => {
    const validationConfig = (
      await this.colRefs.configs.doc("validation").get()
    ).data() as RawConfigValidation;
    if (validationConfig) {
      const validationConfigPerPage = validationConfig.validation[jobType];
      return validationConfigPerPage;
    }
  };

  public setClientExportConfigData = async (
    clientId: string,
    exportConfigData: ExportConfigData,
    preset?: string
  ) => {
    if (preset) {
      await this.db
        .collection(this.collections.clients)
        .doc(clientId)
        .collection(this.subCollections.clients.exportConfigs)
        .doc(preset)
        .set(exportConfigData);
    } else {
      await this.db
        .collection(this.collections.clients)
        .doc(clientId)
        .collection(this.subCollections.clients.exportConfigs)
        .add(exportConfigData);
    }
  };
  public getClientExportConfigData = async (
    clientId: string,
    preset = "default"
  ): Promise<ExportConfigData> => {
    return (
      await this.db
        .collection(this.collections.clients)
        .doc(clientId)
        .collection(this.subCollections.clients.exportConfigs)
        .doc(preset)
        .get()
    ).data() as ExportConfigData;
  };

  public getClientExportConfigPresets = async (
    clientId: string
  ): Promise<{ id: string; config: ExportConfigData }[]> => {
    const snap = await this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .collection(this.subCollections.clients.exportConfigs)
      .get();
    return snap.docs.map((s) => ({
      id: s.id,
      config: s.data() as ExportConfigData,
    }));
  };

  public getValidationsConfigData = async (
    clientId: string,
    jobType: JobType,
    lang?: string
  ): Promise<ValidationsConfigData | undefined> => {
    const globalValidationConfig = await this.getGlobalValidationsConfigData(
      jobType
    );
    const clientValidationConfig: ValidationsConfigData = await this.getClientValidationConfig(
      clientId,
      jobType
    );

    let validationConfig = _.merge(
      globalValidationConfig,
      clientValidationConfig
    );

    if (lang) {
      const mergedDefaultConfig = {
        ...globalValidationConfig.default,
        ...clientValidationConfig.default,
      };
      const globalLangConfig = globalValidationConfig[lang] || {};
      const clientLangConfig = clientValidationConfig[lang] || {};
      validationConfig = {
        ...mergedDefaultConfig,
        ...globalLangConfig,
        ...clientLangConfig,
      };
    }

    return validationConfig;
  };

  public getClientValidationsConfigLang = async (
    clientId: string
  ): Promise<{ id: string; config: ValidationsConfigData }[]> => {
    const snap = await this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .collection(this.subCollections.clients.ValidationsExportConfigs)
      .get();
    return snap.docs.map((s) => ({
      id: s.id,
      config: s.data() as ValidationsConfigData,
    }));
  };

  public getAvailableValidations = async () => {
    const validation = (
      await this.colRefs.configs.doc("validation").get()
    ).data();
    if (validation) {
      return validation.availableValidations;
    }
  };

  public setValidationsConfigData = async (
    clientId: string,
    validationsConfigData: {
      validation: {
        [x: string]: ValidationsConfigData;
      };
    }
  ) => {
    if (clientId) {
      await this.db
        .collection(this.collections.clients)
        .doc(clientId)
        .set({ validation: validationsConfigData.validation }, { merge: true });
    } else {
      logger.error("Validation Config - trying to override global validation");
      // await this.colRefs.configs
      //   .doc("validation")
      //   .update(validationsConfigData.validation);
    }
  };

  public deleteClientExportConfigPreset = async (
    clientId: string,
    preset: string
  ): Promise<void> => {
    return this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .collection(this.subCollections.clients.exportConfigs)
      .doc(preset)
      .delete();
  };

  public getClientValidationConfig = async (
    clientId: string,
    jobType: JobType
  ) => {
    let validationConfig = {};

    const client = (
      await this.db.collection(this.collections.clients).doc(clientId).get()
    ).data();

    if (client && _.get(client, `validation.${jobType}`)) {
      validationConfig = client.validation[jobType];
    }

    return validationConfig;
  };

  public imageUploadForDocx = async (file: File): Promise<string> => {
    const extension = mime.extension(file.type);
    const fileName = file.name.substring(0, file.name.lastIndexOf("."));

    let fileRef = this.storage.ref(
      `${this.buckets.exportConfig}/default/${fileName}.${extension}`
    );

    const isExists = await this.isFileExists(fileRef);

    if (isExists) {
      const unique = Math.random().toString(36).substr(2, 9);
      fileRef = this.storage.ref(
        `${this.buckets.exportConfig}/default/${fileName}_copy_${unique}.${extension}`
      );
    }

    const uploadTask = fileRef.put(file);
    const snap = await uploadTask;
    const url = await snap.ref.getDownloadURL();
    return url;
  };

  public getClientFrameRate = async (clientId: string) => {
    const clientDoc = await this.db
      .collection(this.collections.clients)
      .doc(clientId)
      .get();
    const clientData = clientDoc.data();

    return clientData?.frameRate;
  };

  public static getInstance = () => {
    if (!FirebaseService.instance)
      FirebaseService.instance = new FirebaseService();
    return FirebaseService.instance;
  };
}

export default FirebaseService.getInstance();
