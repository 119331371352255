import { Sorting } from "../../models/sorting";

export interface SystemState {
  loadingReason: string | null;
  errorReason: string | null;
  indicatorPopupState: { type: string; txt: string } | null;
  sorting: Sorting;
  direction: "ltr" | "rtl";
}

export const SET_LOADING_REASON = "SET_LOADING_REASON";
export const CLEAR_LOADING_REASON = "CLEAR_LOADING_REASON";
export const SET_ERROR_REASON = "SET_ERROR_REASON";
export const POP_INDICATOR = "POP_INDICATOR";
export const RESET_SORTING = "RESET_SORTING";
export const UPDATE_SORTING = "UPDATE_SORTING";
export const SET_DIRECTION = "SET_DIRECTION";

interface SetLoadingReason {
  type: typeof SET_LOADING_REASON;
  loadingReason: string | null;
}

interface ClearLoadingReason {
  type: typeof CLEAR_LOADING_REASON;
}

interface SetErrorReason {
  type: typeof SET_ERROR_REASON;
  errorReason: string | null;
}

interface PopIndicator {
  type: typeof POP_INDICATOR;
  indicator: { type: string; txt: string } | null;
}

interface ResetSorting {
  type: typeof RESET_SORTING;
  sorting: Sorting;
}

interface UpdasteSorting {
  type: typeof UPDATE_SORTING;
  newSorting: Sorting;
}

interface SetDirection {
  type: typeof SET_DIRECTION;
  direction: "rtl" | "ltr";
}

export type SystemActionTypes =
  | SetLoadingReason
  | SetDirection
  | ClearLoadingReason
  | ResetSorting
  | UpdasteSorting
  | PopIndicator
  | SetErrorReason;
