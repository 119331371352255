export const menuConfig = {
  nav: [
    {
      label: "jobs",
      path: "/",
      roles: ["super_user", "transcriber", "transcriber+proofer", "proofer"],
      icon: "",
    },
    {
      label: "my_jobs",
      path: "/my-jobs",
      roles: ["super_user", "proofer", "transcriber+proofer", "transcriber"],
      icon: "",
    },
    {
      label: "transcribers",
      path: "/transcribers",
      roles: ["super_user"],
      icon: "",
    },
    {
      label: "clients",
      path: "/clients",
      roles: ["super_user"],
      icon: "",
    },
    {
      label: "archive",
      path: "/archive",
      roles: ["super_user"],
      icon: "",
    },
    {
      label: "upload_meeting",
      path: "/new-meeting",
      roles: ["super_user"],
      icon: "",
    },
    {
      label: "payment",
      path: "/my-payments",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      icon: "",
    },
    {
      label: "export_configuration",
      path: "/export-config",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      icon: "",
      featureFlag: "exportConfigurationV3",
    },
    {
      label: "demo",
      path: "/job/demo",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      icon: "",
    },
    {
      label: "admin_page",
      path: "/admin",
      roles: ["super_user"],
      icon: "",
    },
  ],
  userSettings: [
    {
      key: "autoSave",
      label: "auto_save",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      type: "toggle",
      icon: "",
    },
    {
      key: "playerRewind",
      label: "player_back_skip",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      type: "buttonGroup",
      options: [1, 2, 3],
      icon: "",
    },
    {
      key: "playerForward",
      label: "player_forward_skip",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      type: "buttonGroup",
      options: [1, 2, 3],
      icon: "",
    },
    {
      key: "playerRewindResume",
      label: "player_rewind_resume",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      type: "buttonGroup",
      options: [0, 1, 3],
      icon: "",
    },
    {
      key: "platformLanguage",
      label: "platform_language",
      roles: ["super_user", "proofer", "transcriber", "transcriber+proofer"],
      type: "select",
      options: [
        { label: "he", value: "he", icon: "" },
        { label: "en", value: "en", icon: "" },
        { label: "ar", value: "ar", icon: "", permission: ["super_user"] },
      ],
      icon: "",
    },
  ],
};
