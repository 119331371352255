import { Annotation } from "./annotations";

export type Transcript = {
  text: string;
  type?: TranscriptType;
  speaker?: string;
  annotationType?: Annotation["type"];
  time?: string | undefined;
}[];

export enum TranscriptType {
  annotation = "annotation",
  speaker = "speaker",
}
