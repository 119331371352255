import { UseFormReturn } from "react-hook-form";
import { HeadingLevel } from "docx";
import _ from "lodash";

import { Align } from "../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextDecoration } from "../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

const MAX_FONT_SIZE = 22;
const MIN_FONT_SIZE = 12;

export enum ListType {
  minus = "1-",
  brackets = "[1]",
  hebrew = "א.",
  none = "none",
}

export enum ExportConfigCalendars {
  regular = "regular",
  hebrew = "hebrew",
  muslim = "muslim",
}

export const fontSizeOptions = _.times(MAX_FONT_SIZE + 1, (num: number) => ({
  label: `${num}`,
  value: num,
})).slice(MIN_FONT_SIZE, MAX_FONT_SIZE + 1);

export const DecisionViewOptions = [
  {
    value: "a",
    label: "A",
    content: "A",
  },
  {
    value: "b",
    label: "B",
    content: "B",
  },
  {
    value: "c",
    label: "C",
    content: "C",
  },
];

export const listTypeOptions = [
  {
    value: ListType.minus,
    label: ListType.minus,
    content: ListType.minus,
  },
  {
    value: ListType.brackets,
    label: ListType.brackets,
    content: ListType.brackets,
  },
  {
    value: ListType.hebrew,
    label: ListType.hebrew,
    content: ListType.hebrew,
  },
  {
    value: ListType.none,
    label: ListType.none,
    content: ListType.none,
  },
];

export interface ExportConfigLogoSettings {
  isActive: boolean;
  url?: string;
  file?: string;
  size: number;
  align: Align;
}

export interface ExportConfigFontSettings {
  fontFamily?: string;
  fontSize?: number;
  align?: Align;
  color?: string;
  textDecoration?: TextDecoration[];
}

export type ExportConfigContext = "job" | "client" | "global";

export interface ExportConfigData {
  general: GeneralPageSlice;
  headerFooter: HeaderFooterPageSlice;
  information: InformationPageSlice;
  tableOfSubjects: TableOfSubjectsPageSlice;
  remarks: RemarksPageSlice;
  subjects: SubjectsPageSlice;
  speakers: SpeakersPageSlice;
  decisions: DecisionsPageSlice;
  notes: NotesPageSlice;
  tasks: TasksPageSlice;
  images: ImagesPageSlice;
  focusedDocument: FocusedDocumentPageSlice;
}

export type KeyOfExportConfigData = keyof ExportConfigData;

export class GeneralPageSlice {
  presetName?: string = "";
  format: "pages" | "audio" | "intervals" = "pages";
  interval = 0.5;
  timeOffset = "00:00:00";
  font: ExportConfigFontSettings = {
    fontFamily: "arial",
    fontSize: 20,
    align: Align.center,
  };
  rowCount = false;

  constructor(args?: GeneralPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, _.merge(this, args));
    }
  }
}

export class HeaderFooterPageSlice {
  firstPageHeader = {
    isActive: false,
    logo: {
      isActive: false,
      align: Align.center,
      size: 180,
      url: "",
      file: "",
    },
    jobName: {
      isActive: false,
      align: Align.center,
    },
    jobDate: {
      isActive: false,
      align: Align.center,
    },
  };

  otherPagesHeader = {
    isActive: false,
    logo: {
      isActive: false,
      align: Align.center,
      size: 180,
      url: "",
      file: "",
    },
    jobName: {
      isActive: false,
      align: Align.center,
    },
    jobDate: {
      isActive: false,
      align: Align.center,
    },
  };

  footer: {
    isActive: boolean;
    logo: ExportConfigLogoSettings;
    pageNumbering: {
      isActive: boolean;
      align: Align;
    };
    footerText: string;
  } = {
    isActive: false,
    logo: {
      isActive: false,
      align: Align.center,
      size: 70,
      url: "",
      file: "",
    },
    pageNumbering: {
      isActive: false,
      align: Align.center,
    },
    footerText: "",
  };

  constructor(args?: HeaderFooterPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, _.merge(this, args));
    }
  }
}

export class InformationPageSlice {
  jobName: {
    isActive: boolean;
    fontSize: number;
    textDecoration: TextDecoration[];
    color: string;
    align: Align;
  } = {
    isActive: false,
    fontSize: 16,
    textDecoration: [],
    color: "",
    align: Align.center,
  };
  jobDate = {
    calendars: [ExportConfigCalendars.hebrew],
    isActive: false,
    fontSize: 16,
    textDecoration: [],
    color: "",
    align: Align.center,
  };
  attendees = {
    isActive: false,
  };
  format = "row";
  showTitle = false;
  showRoleType = false;

  constructor(args?: InformationPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class TableOfSubjectsPageSlice {
  tableOfSubjects = {
    isActive: false,
  };
  newPage = false;
  sign: ListType = ListType.none;
  font: ExportConfigFontSettings = {
    fontSize: 16,
    textDecoration: [],
    align: Align.center,
    color: "",
  };

  constructor(args?: TableOfSubjectsPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class RemarksPageSlice {
  startEnd: {
    isActive: boolean;
    text1: string;
    text2: string;
    font: ExportConfigFontSettings;
  } = {
    isActive: false,
    text1: "",
    text2: "",
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  greeting = {
    isActive: false,
    text1: "",
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  signature = {
    font: {
      fontSize: 16,
      textDecoration: [],
    },
    names: [],
  };

  constructor(args?: RemarksPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class SubjectsPageSlice {
  subject = {
    isActive: false,
  };
  sign: ListType = ListType.none;
  font = {
    fontSize: 16,
    textDecoration: [],
    align: Align.center,
    color: "",
  };

  constructor(args?: SubjectsPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class SpeakersPageSlice {
  showTitle = false;
  timing = false;
  newLine = false;
  indentation = false;
  font: ExportConfigFontSettings = {
    fontSize: 16,
    textDecoration: [],
    color: "",
    align: Align.center,
  };

  constructor(args?: SpeakersPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class DecisionsPageSlice {
  decisions: {
    isActive: boolean;
    viewType: "a" | "b" | "c";
    numbering: boolean;
    font: ExportConfigFontSettings;
  } = {
    isActive: false,
    viewType: "a",
    numbering: false,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  votes = {
    isActive: false,
    numbering: false,
    attendeesVotes: false,
    format: "table",
    font: {
      fontSize: 16,
      textDecoration: [],
      color: "",
    },
  };
  gatherDecisions = {
    isActive: false,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };

  constructor(args?: DecisionsPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class NotesPageSlice {
  notes = {
    isActive: false,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };

  constructor(args?: NotesPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class TasksPageSlice {
  tasks = {
    isActive: false,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  gatherTasks = {
    isActive: false,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };

  constructor(args?: TasksPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class ImagesPageSlice {
  images = {
    isActive: false,
    align: Align.center,
  };

  constructor(args?: ImagesPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export class FocusedDocumentPageSlice {
  focusedDocument = {
    isActive: false,
    subjects: false,
    decisions: false,
    tasks: false,
  };

  constructor(args?: FocusedDocumentPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}
export interface SettingPagesProps<T> {
  form?: UseFormReturn<T>;
  logoUpload?: (file: File) => Promise<string>;
}

//DOCX TYPES
export interface TextRunContent {
  phrases: {
    text: string;
    fontSettings: ExportConfigFontSettings;
    break?: number;
  }[];
  fontSettings?: ExportConfigFontSettings;
  heading?: HeadingLevel;
  frame?: boolean;
  disableRowCount?: boolean;
  indent?: boolean;
}

export interface ParagraphContent {
  textRunContent: TextRunContent[];
  fontSettings: ExportConfigFontSettings;
  marginTop: number;
}

export interface SignatureTableContent {
  columsContent: string;
  fontSettings: ExportConfigFontSettings;
}

export interface TableOfContentsContent {
  title: string;
  fontSettings: ExportConfigFontSettings;
}

export interface IntervalsTable {
  time: string;
  contentSlice: ParagraphContent[] | null;
}

export interface DocxData {
  sections: {
    initialSection: ParagraphContent[];
    tableOfSubjectsSection: TableOfContentsContent[];
    jobContentSection: ParagraphContent[];
    jobContentSectionAsIntervals: IntervalsTable[];
    jobSignatureSection: SignatureTableContent[];
  };
}

export interface DocxHeaderItemSettings {
  show: boolean;
  align: Align;
  style: ExportConfigFontSettings;
}
