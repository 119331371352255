import React from "react";
import { PreviewItem } from "../Preview";

export interface LogoProps {
  logo: PreviewItem;
}

export const Logo = ({ logo }: LogoProps) => {
  return logo.show && logo.content ? (
    <div
      className="logo"
      style={{
        justifyContent: logo.align,
      }}
    >
      {typeof logo.content === "string" && (
        <img style={logo.cssProps} src={logo.content}></img>
      )}
    </div>
  ) : (
    <></>
  );
};
