import React from "react";

import { UseFormSetValue } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import "./TextAlignmentSelection.scss";

export enum Align {
  start = "start",
  end = "end",
  center = "center",
  justify = "justify",
  right = "right",
  left = "left",
}

export interface TextAlignmentSelectionProps {
  hasJustify: boolean;
  controller?: {
    setValue: UseFormSetValue<any>;
    name: any;
    field: any;
  };
  disabled?: boolean;
}

export const TextAlignmentSelection = ({
  hasJustify,
  controller,
  disabled,
}: TextAlignmentSelectionProps) => {
  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (controller && newValue?.length) {
      controller.setValue(controller.name, newValue);
    }
  };

  const alignOptions = [
    <ToggleButton disabled={disabled} value={Align.left} key="left">
      <span className="selectionContent">
        <FontAwesomeIcon icon="align-left"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
    <ToggleButton disabled={disabled} value={Align.center} key="center">
      <span className="selectionContent">
        <FontAwesomeIcon icon="align-center"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
    <ToggleButton disabled={disabled} value={Align.right} key="right">
      <span className="selectionContent">
        <FontAwesomeIcon icon="align-right"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
  ];

  const justifyOption = [
    <ToggleButton disabled={disabled} value={Align.justify} key="full">
      <span className="selectionContent">
        <FontAwesomeIcon icon="align-justify"></FontAwesomeIcon>
      </span>
    </ToggleButton>,
  ];

  return (
    <div className="TextAlignmentSelection">
      <ToggleButtonGroup
        {...controller?.field}
        size="small"
        exclusive={true}
        onChange={handleValueChange}
      >
        {hasJustify ? [...alignOptions, justifyOption] : alignOptions}
      </ToggleButtonGroup>
    </div>
  );
};
