import React, { FC, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";

import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./EditableText.scss";

const EditableText: FC<{
  onCancleEditMode?: () => void;
  onConfirmEditMode?: () => void;
  onFocusEditMode?: () => void;
  ellipsis?: boolean;
  className?: string;
  placeholder?: string | number;
  value: string | number;
  formatter?: (value: number | string) => string | number;
  spinnerColor?: string;
  showIcon?: boolean;
  prefix?: string;
  isEditable?: boolean;
  type?: string;
  handleSetNewValue: (newValue: number | string) => Promise<boolean> | void;
}> = ({
  spinnerColor = "black",
  showIcon = false,
  ellipsis = true,
  className,
  prefix,
  formatter,
  value,
  placeholder = value,
  handleSetNewValue,
  isEditable = true,
  type = "text",
  onFocusEditMode,
  onConfirmEditMode,
  onCancleEditMode,
}) => {
  const [newValue, setNewValue] = useState(value);
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pending, setPending] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter", "Tab"])) {
      handleApprove();
    }

    if (handleKeyboardShortcut(e, ["Escape"])) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    if (onCancleEditMode) {
      onCancleEditMode();
    }
    setEditMode(false);
    setNewValue(value);
  };
  const handleApprove = async () => {
    if (onConfirmEditMode) {
      onConfirmEditMode();
    }
    if (!newValue || _.isEmpty(newValue)) {
      setNewValue(value);
      setEditMode(false);
      return;
    }
    try {
      setPending(true);
      const newValueResult = await handleSetNewValue(newValue);
      if (newValueResult === false) {
        setError(true);
        setPending(false);
        setEditMode(false);
        return;
      } else if (formatter) {
        setNewValue(formatter(newValue));
      }
    } catch (error) {
      console.log(error);
    }
    setPending(false);
    setError(false);
    setEditMode(false);
  };

  return (
    <div
      onClick={(e) => {
        isEditable && e.stopPropagation();
      }}
      className={classNames("EditableText", className, {
        editMode,
      })}
    >
      {pending ? (
        <div className="loading-spinner">
          <CircularProgress style={{ width: "20px", color: spinnerColor }} />
        </div>
      ) : editMode && isEditable ? (
        <div
          className={classNames("newInputContainer", {
            error,
          })}
        >
          <input
            className="freeTextInput"
            type={type}
            autoFocus={true}
            placeholder={placeholder as string}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div className="iconContainer">
            <FontAwesomeIcon
              className="icon approve"
              icon={["fal", "check"]}
              onClick={handleApprove}
            />
            <FontAwesomeIcon
              className="icon close"
              icon={["fal", "times"]}
              onClick={handleCancel}
            />
          </div>
        </div>
      ) : (
        <div
          className={classNames({
            inputContainer: isEditable,
            ellipsis: ellipsis,
          })}
          onClick={() => {
            if (isEditable && onFocusEditMode) {
              onFocusEditMode();
            }
            return isEditable && setEditMode(true);
          }}
        >
          <span className="input">
            {prefix ? prefix : ""} {newValue}
          </span>
          {showIcon && isEditable ? (
            <span className="editPen">
              {<FontAwesomeIcon icon={["fal", "pencil-alt"]} />}
            </span>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default EditableText;
