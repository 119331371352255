import _ from "lodash";
import FileSaver from "file-saver";
import { TFunction } from "react-i18next";
import { Packer } from "docx";
import PizZip from "pizzip";
import "pizzip/dist/pizzip-utils";

import { defaultExcelFields } from "../constants/excelFields";
import { getLanguageDirection } from "../utils/locales";

import { AuditData } from "../models";
import { JobData } from "../models/job";

import { createDocx } from "../pages/ExportConfig/DocxCreator";
import { ExportConfigData } from "../pages/ExportConfig/ExportConfigTypes";
import { format } from "date-fns";

const toCSV = ({
  name,
  headers,
  data,
  direction = "rtl",
  includeId = true,
}: {
  name: string;
  headers: { id: string; title: string }[];
  data: any[];
  direction?: "ltr" | "rtl";
  includeId?: boolean;
}) => {
  let headersArr = [...headers];
  if (_.isEmpty(headers))
    headersArr = _.map(_.keys(data[0]), (h) => ({ id: h, title: h }));
  if (direction === "ltr") headersArr.reverse();
  if (includeId && !_.isEmpty(headers))
    headersArr.push({ id: "id", title: "id" });
  const headerString = headersArr.map((h) => h.title).join(",");

  const dataArray = [];
  for (const row of data) {
    const rowArray = [];
    for (const header of headersArr) {
      if (row[header.id]) {
        rowArray.push(`"${row[header.id]}"`);
      } else {
        rowArray.push(``);
      }
    }
    dataArray.push(rowArray.join(","));
  }
  const dataString = dataArray.join("\r\n");

  const csvBlob = new Blob(["\uFEFF", headerString, "\r\n", dataString], {
    type: "text/csv;charset=utf-8",
  });
  FileSaver.saveAs(csvBlob, `${name}.csv`, { autoBom: true });
};

const exportDataToExcel = (
  data: any[],
  fileName: string,
  dictionary: { [key: string]: string }
) => {
  const mergedDictinary = _.merge(dictionary, defaultExcelFields);
  if (data) {
    const headers = [];
    for (const headerKey in mergedDictinary) {
      const field = mergedDictinary[headerKey];
      if (field) {
        headers.push({ id: headerKey, title: field });
      }
    }

    toCSV({
      name: fileName,
      headers,
      data: data,
      includeId: false,
    });
  }
};

const getAuditEventsForExcel = async (
  jobs: any[],
  jobsAuditEvents: {
    [id: string]: AuditData[];
  },
  t: TFunction<string>
) => {
  const jobsWithAudit = jobs.map((job) => {
    const jobAuditEvents = jobsAuditEvents[job.id];
    if (jobsAuditEvents && jobAuditEvents && jobAuditEvents.length) {
      let updatedJob = { ...job };
      jobAuditEvents.map((event) => {
        const eventWithNewKeys = _.mapKeys(
          event,
          (value, key) => `${event.type}_${key}`
        );
        eventWithNewKeys[`${event.type}_type`] = t(event.type);
        updatedJob = { ...updatedJob, ...eventWithNewKeys };
      });
      return updatedJob;
    } else {
      return job;
    }
  });
  return jobsWithAudit;
};

const docxExport = async (
  jobsData: JobData[],
  docxExportConfigData: ExportConfigData
) => {
  if (jobsData.length > 1) {
    await docxBulkExport(jobsData, docxExportConfigData);
    return;
  }

  const jobDirection = getLanguageDirection(jobsData[0].lang.output[0]);
  const docx = await createDocx(
    docxExportConfigData,
    jobsData[0],
    jobDirection
  );
  const blob = await Packer.toBlob(docx);
  saveAs(blob, `${jobsData[0].name}.docx`);
};

const docxBulkExport = async (
  jobsData: JobData[],
  docxExportConfigData: ExportConfigData
) => {
  const jobDirection = getLanguageDirection(jobsData[0].lang.output[0]);
  const docxBlobs = await Promise.all(
    jobsData.map(async (j) => {
      const docx = await createDocx(docxExportConfigData, j, jobDirection);
      const buffer = await Packer.toBuffer(docx);
      return {
        name: j.name,
        buffer,
      };
    })
  );

  const filename = `Docxs (${docxBlobs.length}) - ${format(
    Date.now(),
    "dd/mm/yyyy"
  )}`;

  zipDocxs(docxBlobs, filename);
};

const zipDocxs = async (
  docxBlobs: {
    name: string;
    buffer: Buffer;
  }[],
  filename: string
) => {
  const zip = new PizZip();

  for (const docx of docxBlobs) {
    zip.file(`${docx.name}.docx`, docx.buffer);
  }

  const compressExport = zip.generate({ type: "blob" });

  saveAs(compressExport, filename);
};

export default {
  toCSV,
  exportDataToExcel,
  getAuditEventsForExcel,
  docxExport,
};
