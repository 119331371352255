import React, { FC, useEffect, useRef } from "react";
import "./TextInput.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  focus?: boolean;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  type: "text" | "number" | "date";
  onInput: (input: string) => void;
  width: number;
  placeholder: string;
  Icn?: IconProp;
  required?: boolean;
}

const TextInput: FC<Props> = ({
  focus,
  className,
  disabled,
  required,
  type,
  value,
  onInput,
  width,
  placeholder,
  Icn,
}): JSX.Element => {
  const textInput = useRef(document.createElement("input"));

  useEffect(() => {
    if (focus) textInput.current.focus();
  }, [focus]);

  return (
    <>
      <div className={`input-wrapper ${className ? className : ""}`}>
        {!!Icn && (
          <div className="icn-wrapper">
            <FontAwesomeIcon icon={Icn} />
          </div>
        )}
        {!value && (
          <input
            className={className ? className : ""}
            disabled={disabled}
            type={type}
            ref={textInput}
            style={{ width: `${width}px` }}
            onChange={(e) => {
              onInput(e.target.value);
            }}
            placeholder={placeholder}
            required={required}
          />
        )}
        {!!value && (
          <input
            className={className ? className : ""}
            disabled={disabled}
            type={type}
            ref={textInput}
            style={{ width: `${width}px` }}
            value={value}
            onChange={(e) => {
              onInput(e.target.value);
            }}
            placeholder={placeholder}
            required={required}
          />
        )}
      </div>
    </>
  );
};

export default TextInput;
