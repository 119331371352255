import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as AlephIcn } from "../../assets/icons/aleph.svg";
import { AppState } from "../../store/rootReducer";
import { updateSorting } from "../../store/system/actions";

import "./Sorting.scss";

const Sorting: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sorting = useSelector((state: AppState) => state.systemStore.sorting);

  const changeOrder = () => {
    let newOrder;
    if (sorting.order === "descending") newOrder = "ascending";
    else newOrder = "descending";
    const newSorting = {
      type: sorting.type,
      options: sorting.options,
      sortBy: sorting.sortBy,
      order: newOrder,
    };
    dispatch(updateSorting(newSorting));
  };

  const changeSorting = (sortBy: string) => {
    const newSorting = {
      type: sorting.type,
      options: sorting.options,
      sortBy: sortBy,
      order: sorting.order,
    };
    dispatch(updateSorting(newSorting));
  };

  return (
    <div className="sorting flex column align-center justify-between">
      <div className="toggle-section flex align-center justify-between">
        <p className="sort-by-title">{t("sort_by")}</p>
        <div className="flex align-center justify-between">
          <p>{t("ascending")}</p>
          <div
            onClick={() => changeOrder()}
            className="toggle-btn flex align-center"
          >
            <div
              className={`inner-circle ${
                sorting.order === "descending" ? "switched" : ""
              }`}
            ></div>
          </div>
          <p>{t("descending")}</p>
        </div>
      </div>
      <div className="icns-wrapper">
        {sorting.options.map((sortingOption, idx) => {
          if (sortingOption === "by-date")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "calendar-alt"]} />
              </div>
            );
          else if (sortingOption === "by-deadline")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "hourglass-half"]} />
              </div>
            );
          else if (sortingOption === "by-length")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "clock"]} />
              </div>
            );
          else if (sortingOption === "by-meetings-count")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "briefcase"]} />
              </div>
            );
          else if (sortingOption === "by-price")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "shekel-sign"]} />
              </div>
            );
          else if (sortingOption === "by-progress")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "chart-pie-alt"]} />
              </div>
            );
          else if (sortingOption === "by-members")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "users"]} />
              </div>
            );
          else if (sortingOption === "by-title")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn aleph ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <div className="icn-wrapper">
                  <AlephIcn />
                </div>
              </div>
            );
          else if (sortingOption === "by-owner")
            return (
              <div
                key={idx}
                onClick={() => changeSorting(sortingOption)}
                className={`icn ${
                  sortingOption === sorting.sortBy ? "active" : ""
                }`}
              >
                <FontAwesomeIcon icon={["fal", "user"]} />
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default Sorting;
