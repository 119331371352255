import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../../i18n";

import { getLanguageDirection } from "../../../utils/locales";

import "./BackButton.scss";

interface Props {
  backButton?: {
    title: string;
    backAction: () => void;
  };
}

const BackButton: FC<Props> = ({ backButton }): JSX.Element => {
  return (
    <div className="back-wrapper flex back-button">
      {backButton && backButton.title && (
        <div className="back-btn" onClick={backButton.backAction}>
          <div className="back-icn-wrapper">
            <FontAwesomeIcon
              icon={[
                "fal",
                getLanguageDirection(i18n.language) === "ltr"
                  ? "angle-left"
                  : "angle-right",
              ]}
            />
          </div>
          <div>{backButton.title}</div>
        </div>
      )}
    </div>
  );
};

export default BackButton;
