import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../store/rootReducer";
import { FirebaseService } from "../../services/ServiceControler";

import "../AuthPages/AuthPages.scss";

const PendingTranscriber = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  useEffect(() => {
    if (!loggedInUser) return;
    if (loggedInUser.role !== "pending-transcriber") {
      history.push("/");
      return;
    }
    FirebaseService.subscribeActionToUser(loggedInUser?.id, (user) => {
      if (user.role !== "pending-transcriber") {
        location.reload();
      }
    });
  }, [loggedInUser]);

  const logout = () => {
    FirebaseService.auth.signOut();
  };

  return (
    <div className="LoginPage">
      <div className="authContainer">
        <div className="loginSecondFactorContainer">
          <div className="headerTitle">{t("pending_user_title")}</div>
          <div className="note">
            <div className="noteBody">
              <div className="noteLine">
                {t("pending_user_message_1")}
                <br />
                {t("pending_user_message_2")}
                <br />
                {t("pending_user_message_3")}
              </div>
            </div>
          </div>
        </div>
        <div className="bottomNote logout">
          <div className="noteNote">
            <div className="link goBack" onClick={() => logout()}>
              {t("log_out")}
            </div>
          </div>
        </div>
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};

export default PendingTranscriber;
