import React, { FC, useState, ChangeEvent, useRef, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector } from "react-redux";
import { AppState } from "../../../store/rootReducer";

import "./Dropdown.scss";

export interface DropdownOption {
  label: string;
  value: any;
  icon?: any;
}

interface Props {
  options: DropdownOption[];
  onChange: (value: string[] | string) => void;
  placeholder: string;
  value?: string | string[];
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  multiSelect?: boolean;
  searchOption?: boolean;
}

const Dropdown: FC<Props> = ({
  options,
  onChange,
  placeholder,
  value,
  className,
  isLoading,
  isDisabled,
  multiSelect,
  searchOption = true,
}) => {
  const { t } = useTranslation();
  const mainElement = useRef<HTMLDivElement>(null);

  const dir = useSelector((state: AppState) => state.systemStore.direction);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption>();
  const [multiSelected, setMultiSelected] = useState<DropdownOption[]>([]);
  const [selectedLabel, setSelectedLabel] = useState(placeholder);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!value || _.isEmpty(value) || !options) return;
    if (value === "" || value[0] === "") {
      setSelectedLabel(placeholder);
      return;
    }
    const optionsByKey = _.keyBy(options, "value");

    // Single Selected
    if (_.isString(value)) {
      setSelectedOption(optionsByKey[value]);
      setSelectedLabel(optionsByKey[value].label);
      return;
    }
    // Multiselected
    setMultiSelected(_.filter(options, (o) => value.includes(o.value)));
    const selectedString =
      value.length > 1
        ? `${t(optionsByKey[value[0]].label)} +${value.length - 1}`
        : t(optionsByKey[value[0]].label);
    setSelectedLabel(selectedString);
  }, [value]);

  useOutsideClick(mainElement, () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  });

  const handleSelect = (option: DropdownOption) => {
    setSelectedOption(option);
    setSelectedLabel(option.label);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleMultiSelect = (option: DropdownOption) => {
    let newSelected = [];
    if (multiSelected.includes(option)) {
      newSelected = multiSelected.filter((o) => o.value !== option.value);
    } else {
      newSelected = [...multiSelected, option];
    }

    if (newSelected.length === 0) {
      setSelectedLabel(placeholder);
    } else {
      const selectedString =
        newSelected.length > 1
          ? `${t(newSelected[0].label)} +${newSelected.length - 1}`
          : t(newSelected[0].label);
      setSelectedLabel(selectedString);
    }

    setMultiSelected(newSelected);
    onChange(newSelected.map((s) => s.value));
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return (
    <div
      className={classNames(dir, "Dropdown", className, {
        isOpen,
      })}
      ref={mainElement}
    >
      <div
        className={classNames("selectedLabel", {
          placeholder: multiSelect ? _.isEmpty(multiSelected) : !selectedOption,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {t(selectedLabel)}
      </div>
      <div
        className={classNames("dropdownContainer", {
          isOpen,
          isDisabled,
          isLoading,
        })}
      >
        {searchOption && (
          <div className="search">
            <input
              type="text"
              placeholder={t("search")}
              onChange={handleSearch}
            />
          </div>
        )}
        <div
          className={classNames(dir, `optionsContainer`, {
            isOpen: isOpen,
          })}
        >
          {_.map(
            options.filter((o) => o.label.toLowerCase().includes(searchValue)),
            (o) =>
              multiSelect ? (
                <div className="option checkbox" key={o.value}>
                  <input
                    type="checkbox"
                    id={o.value}
                    name={o.value}
                    value={o.value}
                    checked={
                      multiSelected.find((option) => option.value === o.value)
                        ? true
                        : false
                    }
                    onChange={() => handleMultiSelect(o)}
                  />
                  <label htmlFor={o.value}>{o.label}</label>
                </div>
              ) : (
                <div
                  className="option"
                  onClick={() => handleSelect(o)}
                  key={o.value}
                >
                  {t(o.label)}
                </div>
              )
          )}
        </div>
      </div>
      <FontAwesomeIcon
        className="arrow"
        icon={["fal", "chevron-down"]}
        onClick={() => setIsOpen(!isOpen)}
      />
    </div>
  );
};

export default Dropdown;
