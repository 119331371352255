import React from "react";
import classNames from "classnames";

import i18n from "../../../i18n";

import { Transcript, TranscriptType } from "../../../models";
import { ExportConfigData } from "../ExportConfigTypes";
import { getListNumberBySign, getTextStyling } from "../Preview";

export interface ProtocolRangesProps {
  ranges: Transcript | null;
  settings: ExportConfigData;
  lang: string;
  from: number;
  to: number;
}

export const ProtocolRanges = ({
  ranges,
  settings,
  lang,
  from,
  to,
}: ProtocolRangesProps): JSX.Element => {
  const protocols: JSX.Element[] = [];
  let subjectsCounter = 0;

  ranges?.slice(from, to).map((r, i) => {
    if (
      r.type === TranscriptType.annotation &&
      r.annotationType === "subject"
    ) {
      subjectsCounter = subjectsCounter + 1;
    }

    protocols.push(
      <div key={i}>
        {r.type !== TranscriptType.annotation ? (
          <div className="annotationWrapper">
            <div
              className="paragraph"
              style={{
                textAlign: settings.general?.font?.align,
                display: settings?.speakers?.indentation ? "flex" : "block",
              }}
            >
              <span
                style={{
                  display: settings.speakers.newLine ? "block" : "inline-block",
                  alignItems: "flex-start",
                  textAlign: settings.speakers?.font?.align,
                  justifyContent: settings.speakers?.font?.align,
                  flex: "25%",
                  ...getTextStyling(settings.speakers?.font),
                }}
              >
                {`${r.speaker}:`}
              </span>

              <span
                style={{
                  flex: "75%",
                  marginTop:
                    settings?.speakers?.newLine &&
                    settings?.speakers?.indentation
                      ? (settings?.speakers?.font?.fontSize
                          ? settings?.speakers?.font?.fontSize + 5
                          : 0) + "px"
                      : "0px",
                }}
              >{` ${r.text} `}</span>
            </div>
          </div>
        ) : (
          <div className="annotationWrapper">
            {settings?.subjects?.subject?.isActive &&
              r.annotationType === "subject" && (
                <div
                  className="paragraph"
                  style={{
                    ...getTextStyling(settings.subjects?.font),
                    textAlign: settings.subjects?.font?.align,
                    justifyContent: settings.subjects?.font?.align,
                  }}
                >
                  {getListNumberBySign(
                    settings?.subjects?.sign,
                    subjectsCounter
                  )}
                  {r.text}
                </div>
              )}

            {settings?.notes?.notes?.isActive && r.annotationType === "note" && (
              <div
                className="paragraph"
                style={{
                  ...getTextStyling(settings.notes?.notes?.font),
                  textAlign: settings.notes?.notes?.font?.align,
                  justifyContent: settings.notes?.notes?.font?.align,
                }}
              >
                * {r.text}
              </div>
            )}

            {settings?.decisions?.decisions?.isActive &&
              r.annotationType === "decision" && (
                <div
                  className={classNames([
                    "paragraph",
                    {
                      textBoxA:
                        settings?.decisions?.decisions?.viewType === "a",
                    },
                    {
                      textBoxB:
                        settings?.decisions?.decisions?.viewType === "b",
                    },
                  ])}
                  style={{
                    ...getTextStyling(settings.decisions?.decisions?.font),
                    textAlign: settings.decisions?.decisions?.font?.align,
                    justifyContent: settings.decisions?.decisions?.font?.align,
                  }}
                >
                  {`${i18n.t("decision", { lng: lang })}: ${r.text}`}
                </div>
              )}
          </div>
        )}
      </div>
    );
  });
  return <>{protocols}</>;
};
