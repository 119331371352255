import _ from "lodash";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";
import { createVtt } from "../../utils/createFormat";
import { focusAndSetCursor } from "../../utils/focusAndScroll";

import { Word } from "../../models";
import { JobData } from "../../models/job";
import { SubtitlesRange } from "../../models/range";

import EditorService from "../../services/EditorService";
import SoundManager from "../../services/SoundManager";
import TimeService from "../../services/TimeService";
import TrackingService from "../../services/TrackingService";
import FeatureFlagsService from "../../services/FeatureFlagsService";

import TimerPicker from "../TimerPicker/TimerPicker";
import RangeValidation from "../../pages/Editor/components/RangeValidation";

import "./TextRange.scss";

interface Props {
  job: JobData;
  ranges: SubtitlesRange[];
  range: SubtitlesRange;
  rangeIndex: number;
  setFocusedRangeIndex: (i: number) => void;
  rangesCount: number;
  onPressEnter: (options: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    event: React.KeyboardEvent;
  }) => void;
  mergeRange: (
    rangeIndex: number,
    updatedRangeWords: Word[],
    mergeWithNextRange: boolean
  ) => void;
  deleteRange: (rangeIndex: number) => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  isPassed: boolean;
  isCurrentPlayingRange: boolean;
  updateRangeWords: (rangeIndex: number, rangeWords: Word[]) => void;
  direction: "ltr" | "rtl";
  disabled?: boolean;
}

const RangeSubtitles: FC<Props> = ({
  job,
  ranges,
  range,
  rangeIndex,
  setFocusedRangeIndex,
  rangesCount,
  onPressEnter,
  mergeRange,
  deleteRange,
  isPassed,
  updateRangeWords,
  updateRangeTimes,
  isCurrentPlayingRange,
  direction,
  disabled,
}) => {
  const { t } = useTranslation();

  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [oldPlainWords, setOldPlainWords] = useState("");
  const [plainWords, setPlainWords] = useState("");

  const [rangeTimes, setRangeTimesState] = useState<{ [key: string]: string }>({
    start: "",
    end: "",
  });

  useEffect(() => {
    try {
      if (!range) return;
      setRangeAndPlainWords({ setOld: true });
      setRangeTimes();
    } catch (err) {
      console.log(rangeIndex);
      throw err;
    }
  }, []);

  useEffect(() => {
    if (!range) return;
    const isFocused = document.activeElement === textInputRef.current;
    if (!isFocused || !isChanged) {
      setRangeAndPlainWords({ setOld: true });
    }
  }, [job, range, range.words.length]);

  useEffect(() => {
    if (isCurrentPlayingRange && range.id === ranges[rangeIndex].id) {
      const rangeString = range.words.map((word) => word.text).join(" ");
      if (plainWords !== rangeString) {
        const subtitles = createVtt(ranges, true, plainWords, rangeIndex);
        SoundManager.setSubtitles(subtitles);
      }
    }
  }, [isCurrentPlayingRange, plainWords]);

  if (!range) return null;

  const setRangeAndPlainWords = ({
    rangeWords = range.words,
    isRangeEmpty = false,
    setOld = false,
  }: {
    rangeWords?: Word[];
    isRangeEmpty?: boolean;
    setOld?: boolean;
  } = {}) => {
    const rangeMultilineString = rangeWords
      .map((word, i) => {
        let wordText = word.text;
        if (_.has(word, "line_ix") && rangeWords[i + 1]) {
          const lineBreak = word.line_ix !== rangeWords[i + 1].line_ix;
          wordText = lineBreak ? `${wordText}\n` : wordText;
        }
        return wordText;
      })
      .join(" ")
      .replace(/\n /g, "\n");

    if (isRangeEmpty) {
      handleMergeRange();
      return;
    }
    setPlainWords(rangeMultilineString);
    if (setOld) setOldPlainWords(rangeMultilineString);
  };

  if (_.isEmpty(range.words)) return null;

  /* --- Range Manipulators --- */

  const handlePressEnter = async (
    event: React.KeyboardEvent,
    selectionStart: number,
    selectionEnd?: number
  ) => {
    const spreadRangeWordTimes = FeatureFlagsService.isEnabled(
      "spreadRangeWordTimes"
    );
    const updatedRangeWords = spreadRangeWordTimes
      ? EditorService.getRangeWordsFromMultilineStringAndSpreadTimes(
          range,
          plainWords,
          oldPlainWords,
          rangesCount
        )
      : EditorService.getRangeWordsFromMultilineString(
          range,
          plainWords,
          oldPlainWords,
          rangesCount
        );

    const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
      selectionStart || 0,
      updatedRangeWords
    );

    onPressEnter({
      rangeIndex,
      updatedRangeWords,
      selectedWordIndex: rangeWordIndex,
      wordCharIndex,
      event,
    });
  };

  const handlePressShiftEnter = async (
    event: React.KeyboardEvent,
    selectionStart: number,
    selectionEnd?: number
  ) => {
    const spreadRangeWordTimes = FeatureFlagsService.isEnabled(
      "spreadRangeWordTimes"
    );
    const updatedRangeWords = spreadRangeWordTimes
      ? EditorService.getRangeWordsFromMultilineStringAndSpreadTimes(
          range,
          plainWords,
          oldPlainWords,
          rangesCount
        )
      : EditorService.getRangeWordsFromMultilineString(
          range,
          plainWords,
          oldPlainWords,
          rangesCount
        );

    const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
      selectionStart || 0,
      updatedRangeWords
    );

    onPressEnter({
      rangeIndex,
      updatedRangeWords,
      selectedWordIndex: rangeWordIndex,
      wordCharIndex,
      event,
    });
  };

  const handleMergeRange = async (mergeWithNextRange = false) => {
    const spreadRangeWordTimes = FeatureFlagsService.isEnabled(
      "spreadRangeWordTimes"
    );
    const updatedRangeWords = spreadRangeWordTimes
      ? EditorService.getRangeWordsFromMultilineStringAndSpreadTimes(
          range,
          plainWords,
          oldPlainWords,
          rangesCount
        )
      : EditorService.getRangeWordsFromMultilineString(
          range,
          plainWords,
          oldPlainWords,
          rangesCount
        );

    mergeRange(rangeIndex, updatedRangeWords, mergeWithNextRange);
  };

  const handleDeleteRange = async () => {
    deleteRange(rangeIndex);
  };

  /* --- Range Manipulators --- */

  /* --- Helpers --- */

  const getSelectedWordIndex = (cursorPosition: number, words: Word[]) => {
    const trimmedWordCount = plainWords
      .trim()
      .split(" ")
      .filter((word) => word).length;
    const cursorDiff =
      cursorPosition > 0 ? getCursorFixedPositionAfterTrim(cursorPosition) : 0;
    let rangeWordIndex = 0;
    let wordCharIndex: number = cursorPosition + cursorDiff;

    while (
      wordCharIndex > 0 &&
      wordCharIndex > words[rangeWordIndex].text.length
    ) {
      const wordToCheck = words[rangeWordIndex].text.length;
      wordCharIndex = wordCharIndex - (wordToCheck + 1); // +1 for space
      rangeWordIndex++;
    }

    return { rangeWordIndex, wordCharIndex };
  };

  const getCursorFixedPositionAfterTrim = (cursorPosition: number) => {
    const isBeginningOfWord =
      cursorPosition === 0 || plainWords[cursorPosition - 1] === " ";
    const rangeFirstHalfTrimmedLength = plainWords
      .slice(0, cursorPosition)
      .trim()
      .split(" ")
      .filter((word) => word)
      .join(" ").length;
    const cursorDiff =
      rangeFirstHalfTrimmedLength +
      (isBeginningOfWord ? 1 : 0) -
      cursorPosition;
    return cursorDiff;
  };

  /* --- Helpers --- */

  /* --- Time Manipulators --- */

  const setRangeTimes = () => {
    try {
      const start = TimeService.getTimeStringFromSecs(range.st, false, true);
      const end = TimeService.getTimeStringFromSecs(range.et, false, true);
      setRangeTimesState({
        start,
        end,
      });
    } catch (err) {
      Sentry.captureException({
        err,
        rangesCount,
        rangeLength: range.words.length,
        rangeIndex,
      });
    }
  };

  const handleRangeTimeChange = (
    time: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    position: "start" | "end",
    method: "button" | "text"
  ) => {
    const newTime = _.isString(time) ? time : time.target.value;

    setRangeTimesState({
      ...rangeTimes,
      [position]: newTime,
    });
  };

  const handleRangeTimeBlur = (position: "start" | "end") => {
    const timeInSec = TimeService.getTimeNumberFromString(rangeTimes[position]);
    updateRangeTimes({ rangeIndex, [position]: timeInSec, method: "text" });
    setIsChanged(true);
  };

  const addSubtractRangeTime = (
    operator: "add" | "subtract",
    position: "start" | "end"
  ) => {
    let timeToAdd = SoundManager.frameLength || 0.1;
    if (operator === "subtract") {
      timeToAdd = -timeToAdd;
    }

    const updatedTimeInSecs = TimeService.getFixedFrameRateTime({
      time: range[position === "start" ? "st" : "et"],
      timeToAdd,
      frameRate: SoundManager.frameRate,
      operator,
    });

    const newTimeString = TimeService.getTimeStringFromSecs(
      updatedTimeInSecs,
      false,
      true
    );
    setRangeTimesState({
      ...rangeTimes,
      [position]: newTimeString,
    });

    updateRangeTimes({
      rangeIndex,
      [position]: updatedTimeInSecs,
      method: "button",
    });
  };

  /* --- Time Manipulators --- */

  /* --- Editor Shortcuts --- */

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [], false)) {
      handlePressEnter(e, selectionStart);
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === 0]
      )
    ) {
      if (rangeIndex === 0) return;
      handleMergeRange();
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === textLength, rangesCount > 1]
      )
    ) {
      // Deletion of entire range
      // deleteRange();
    }

    if (
      handleKeyboardShortcut(e, ["Delete"], [selectionStart === textLength])
    ) {
      handleMergeRange(true);
      // focusAndSetCursor(rangeIndex, textLength);
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Delete" || "Backspace"],
        [
          e.ctrlKey || e.metaKey,
          e.shiftKey,
          FeatureFlagsService.isEnabled("deleteRange"),
        ]
      )
    ) {
      handleDeleteRange();
    }

    if (handleKeyboardShortcut(e, ["Tab"])) {
      if (
        (e.nativeEvent.shiftKey && rangeIndex === 0) ||
        (!e.nativeEvent.shiftKey && rangeIndex === ranges.length - 1)
      ) {
        return;
      }
      const rangeIndexToFocus = e.nativeEvent.shiftKey
        ? rangeIndex - 1
        : rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    }

    if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowLeft") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowRight") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey], false)) {
      updateRanges();
    }

    if (handleKeyboardShortcut(e, ["KeyX"], [e.ctrlKey || e.metaKey])) {
      return;
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [e.shiftKey])) {
      handlePressShiftEnter(e, selectionStart);
      return;
    }
  };

  /* --- Editor Shortcuts --- */

  /* --- Event Handlers --- */

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!e.altKey) return;
    let { selectionStart } = e.target as HTMLTextAreaElement;

    const editedWords = plainWords.split(" ");
    const lengths = editedWords.map((word) => word.length);

    let clickedWord = -1;
    while (selectionStart > 0) {
      clickedWord++;
      selectionStart = selectionStart - lengths[clickedWord] - 1;
    }
    if (selectionStart === 0) clickedWord++;

    if (range.words && range.words[clickedWord]) {
      SoundManager.setOffset(range.words[clickedWord].start);
    }
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocusedRangeIndex(rangeIndex);

    TrackingService.reportEvent("text_edit_start", {}, job);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputString = e.target.value === "" ? "." : e.target.value;

    setIsChanged(true);
    setPlainWords(newInputString);
    setFocusedRangeIndex(rangeIndex);
  };

  const handleBlur = async () => {
    setFocusedRangeIndex(-1);
    updateRanges();
  };

  const updateRanges = () => {
    if (isChanged) {
      const spreadRangeWordTimes = FeatureFlagsService.isEnabled(
        "spreadRangeWordTimes"
      );
      const updatedRangeWords = spreadRangeWordTimes
        ? EditorService.getRangeWordsFromMultilineStringAndSpreadTimes(
            range,
            plainWords,
            oldPlainWords,
            rangesCount
          )
        : EditorService.getRangeWordsFromMultilineString(
            range,
            plainWords,
            oldPlainWords,
            rangesCount
          );

      updateRangeWords(rangeIndex, updatedRangeWords);
      setOldPlainWords(plainWords);
    }

    TrackingService.reportEvent(
      "text_edit_end",
      { text_changed: isChanged },
      job
    );
  };

  /* --- Event Handlers --- */

  return (
    <div
      className={classNames("textRange", "protocol", direction, {
        error: !_.isEmpty(range.validation?.errors),
        passed: isPassed,
        overlapping:
          range.validation?.errors?.overlapping_prev ||
          range.validation?.errors?.overlapping_next ||
          range.validation?.errors?.start_after_end ||
          range.validation?.errors?.out_of_range,
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
    >
      <div className="rangeTimes subtitles">
        <TimerPicker
          className={classNames({
            ltr: direction === "ltr",
            overlapping:
              range.validation?.errors?.overlapping_prev ||
              range.validation?.errors?.start_after_end ||
              range.validation?.errors?.out_of_range,
          })}
          value={range.st}
          handleChange={(time: string) =>
            handleRangeTimeChange(time, "start", "text")
          }
          handleBlur={() => handleRangeTimeBlur("start")}
          step={SoundManager.frameLength || 0.1}
          deductTime={() => addSubtractRangeTime("subtract", "start")}
          addTime={() => addSubtractRangeTime("add", "start")}
          disabled={disabled}
        />
        <TimerPicker
          className={classNames({
            ltr: direction === "ltr",
            overlapping:
              range.validation?.errors?.overlapping_next ||
              range.validation?.errors?.start_after_end ||
              range.validation?.errors?.out_of_range,
          })}
          value={range.et}
          handleChange={(time: string) =>
            handleRangeTimeChange(time, "end", "text")
          }
          handleBlur={() => handleRangeTimeBlur("end")}
          step={SoundManager.frameLength || 0.1}
          deductTime={() => addSubtractRangeTime("subtract", "end")}
          addTime={() => addSubtractRangeTime("add", "end")}
          disabled={disabled}
        />
      </div>

      <div className="rangeText">
        <div className={classNames("textContainer", { disabled })}>
          {!disabled && (
            <textarea
              id={`range-${rangeIndex}`}
              ref={textInputRef}
              className="textRangeField"
              value={plainWords}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              onClick={handleClick}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          )}
          <div className="dummyRange">{plainWords}</div>
          {range.validation && (
            <RangeValidation
              validation={range.validation}
              direction={direction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RangeSubtitles;
