import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import i18n from "../../../i18n";

import FirebaseService from "../../../services/FirebaseService";

import { getLanguageDirection } from "../../../utils/locales";
import { JobData } from "../../../models/job";

import ExportConfig from "../../../pages/ExportConfig/ExportConfig";
import { ExportConfigData } from "../../../pages/ExportConfig/ExportConfigTypes";

import Logger from "../../../services/Logger";

import "./ExportModalV3.scss";

const logger = Logger("ExportModalV3");

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  jobsData: JobData[];
  exportConfig: ExportConfigData;
  presets: { id: string; config: ExportConfigData }[];
}

const ExportModalV3: FC<Props> = ({
  isOpen,
  setOpen,
  jobsData,
  exportConfig,
  presets,
}): JSX.Element => {
  return (
    <Dialog
      className={`ExportModalV3 ${getLanguageDirection(i18n.language)}`}
      open={isOpen}
    >
      <DialogTitle>
        <div className="titleWrapper">
          <div className="closeBtn" onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={"times"} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <ExportConfig
          presets={presets}
          jobsData={jobsData}
          exportConfig={exportConfig}
          modalHeight={true}
          allowSave={false}
          allowSaveNew={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ExportModalV3;
