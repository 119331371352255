import React from "react";
import { PreviewItem } from "../Preview";

export interface JobNameProps {
  jobNamePreviewItem: PreviewItem;
  style: React.CSSProperties;
}

export const JobName = ({ jobNamePreviewItem, style }: JobNameProps) => {
  return jobNamePreviewItem?.show ? (
    <div
      className="paragraph"
      style={{ ...style, justifyContent: jobNamePreviewItem.align }}
    >
      <div className="jobName"> {jobNamePreviewItem?.content}</div>
    </div>
  ) : (
    <></>
  );
};
