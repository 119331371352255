import React from "react";
import { useTranslation } from "react-i18next";

import {
  DecisionsPageSlice,
  DecisionViewOptions,
  fontSizeOptions,
  SettingPagesProps,
} from "../ExportConfigTypes";

import { ColorPicker } from "../../../components/common/V3/ColorPicker/ColorPicker";
import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { DynamicToggleSelection } from "../../../components/common/V3/DynamicToggleSelection/DynamicToggleSelection";
import { SelectInput } from "../../../components/common/V3/SelectInput/SelectInput";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextDecorationSelection } from "../../../components/common/V3/TextDecorationSelection/TextDecorationSelection";

import "./../SettingPages.scss";

export const DecisionsPage = ({
  form,
}: SettingPagesProps<DecisionsPageSlice>) => {
  const { t } = useTranslation();

  const decisionsWatch: DecisionsPageSlice = form?.watch() as DecisionsPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_decisions_title")}</div>
            <ControllerHOC control={control} name="decisions.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_decisions_explanation_1")}
          </div>
        </div>
        <div className="section">
          <div className="input-title">
            {t("export_config_global_view_type")}:
          </div>
          <ControllerHOC
            setValue={setValue}
            control={control}
            name={"decisions.viewType"}
          >
            <DynamicToggleSelection
              disabled={!decisionsWatch?.decisions?.isActive}
              options={DecisionViewOptions}
              isMulti={false}
            />
          </ControllerHOC>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_numbering")}:
            </div>
            <ControllerHOC name="decisions.numbering" control={control}>
              <SwitchInput disabled={!decisionsWatch?.decisions?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="decisions.font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!decisionsWatch?.decisions?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="decisions.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!decisionsWatch?.decisions?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="decisions.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                disabled={!decisionsWatch?.decisions?.isActive}
                hasJustify={false}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="decisions.font.color"
            >
              <ColorPicker disabled={!decisionsWatch?.decisions?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_decisions_votes")}:</div>
            <ControllerHOC control={control} name="votes.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_decisions_explanation_2")}
          </div>
        </div>
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_decisions_attendees_votes")}:
            </div>
            <ControllerHOC name="votes.attendeesVotes" control={control}>
              <SwitchInput disabled={!decisionsWatch?.votes?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        <div className="section">
          <div className="input-title">{t("export_config_global_format")}:</div>
          <ControllerHOC
            setValue={setValue}
            control={control}
            name={"votes.format"}
          >
            <DynamicToggleSelection
              disabled={!decisionsWatch?.votes?.isActive}
              options={[
                {
                  value: "list",
                  label: t("export_config_global_list"),
                  content: t("export_config_global_list"),
                },
                {
                  value: "table",
                  label: t("export_config_global_table"),
                  content: t("export_config_global_table"),
                },
              ]}
              isMulti={false}
            />
          </ControllerHOC>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC control={control} name="votes.font.fontSize">
              <SelectInput
                options={fontSizeOptions}
                disabled={!decisionsWatch?.votes?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="votes.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!decisionsWatch?.votes?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="votes.font.color"
            >
              <ColorPicker disabled={!decisionsWatch?.votes?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_decisions_gather_decisions")}</div>
            <ControllerHOC control={control} name="gatherDecisions.isActive">
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_decisions_explanation_3")}
          </div>
        </div>
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_font")}:</div>
          </div>
          <div className="inputs-row">
            <ControllerHOC
              control={control}
              name="gatherDecisions.font.fontSize"
            >
              <SelectInput
                options={fontSizeOptions}
                disabled={!decisionsWatch?.gatherDecisions?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="gatherDecisions.font.textDecoration"
              setValue={setValue}
            >
              <TextDecorationSelection
                disabled={!decisionsWatch?.gatherDecisions?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              name="gatherDecisions.font.align"
              setValue={setValue}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!decisionsWatch?.gatherDecisions?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              control={control}
              name="gatherDecisions.font.color"
            >
              <ColorPicker
                disabled={!decisionsWatch?.gatherDecisions?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
      </div>
    </form>
  );
};
