import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClientData, Contact } from "../../models/client";
import FirebaseService from "../../services/FirebaseService";
import PageHeader from "../../components/PageHeader/PageHeader";
import TextInput from "../../components/FormInputs/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageModal from "../../components/MessageModal/MessageModal";
import Input from "../../components/common/Input/Input";
import { JobData } from "../../models/job";
import { NewMeetingData } from "../../models";

interface NewStreamModalProps {
  createJob: (job: Partial<JobData>) => Promise<any>;
  showModal: boolean;
  closeModal: () => void;
}

const NewStreamModal = ({
  createJob,
  showModal,
  closeModal,
}: NewStreamModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [newStreamJob, setNewStreamJob] = useState({
    name: "",
  });

  const handleChangeJobName = (e: any) => {
    const name = e.target.value;
    setNewStreamJob({
      ...newStreamJob,
      name: name,
    });
  };

  const handleCreateNewStream = async () => {
    setIsLoading(true);
    await createJob(newStreamJob);
    setIsLoading(false);
  };

  return (
    <MessageModal
      className="createNewStream warning"
      title={t("create_new_stream")}
      body={
        <div className="bodyContainer">
          <div className="note">{t("live_interview_create_warning")}</div>
          <Input value={newStreamJob.name} onChange={handleChangeJobName} />
        </div>
      }
      showModal={showModal}
      approve={{ text: t("approve"), action: handleCreateNewStream }}
      cancel={{ text: t("cancel"), action: closeModal }}
      isApproveDisabled={isLoading}
    />
  );
};

export default NewStreamModal;
