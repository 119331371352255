import {
  ArchiveState,
  ArchiveActionTypes,
  SET_ARCHIVED_MEETINGS,
  UPDATE_ARCHIVED_MEETING,
  SET_ARCHIVE_TREE,
  UPDATE_ARCHIVED_MEETING_REVENUE,
  UPDATE_ARCHIVED_MEETING_REPRESENTATIVE,
  UPDATE_ARCHIVED_MEETING_INVOICE_SENT,
} from "./types";

const initialState: ArchiveState = {
  archivedMeetings: [],
};

function meetingReducer(
  state = initialState,
  action: ArchiveActionTypes
): ArchiveState {
  switch (action.type) {
    case SET_ARCHIVED_MEETINGS:
      return {
        ...state,
        archivedMeetings: action.meetings,
      };
    case UPDATE_ARCHIVED_MEETING: {
      const meetingIndex = state.archivedMeetings.findIndex((m) => {
        return m.id === action.meeting.id;
      });
      const newArchivedMeetings = [...state.archivedMeetings];

      newArchivedMeetings[meetingIndex] = action.meeting;

      return { ...state, archivedMeetings: newArchivedMeetings };
    }
    case UPDATE_ARCHIVED_MEETING_REPRESENTATIVE: {
      const meetingIndex = state.archivedMeetings.findIndex((m) => {
        return m.id === action.meetingId;
      });
      const newArchivedMeetings = [...state.archivedMeetings];
      newArchivedMeetings[meetingIndex].representative = action.representative;
      return { ...state, archivedMeetings: newArchivedMeetings };
    }
    case UPDATE_ARCHIVED_MEETING_REVENUE: {
      const meetingIndex = state.archivedMeetings.findIndex((m) => {
        return m.id === action.meetingId;
      });
      const newArchivedMeetings = [...state.archivedMeetings];
      newArchivedMeetings[meetingIndex].revenue = action.revenue;
      return { ...state, archivedMeetings: newArchivedMeetings };
    }
    case UPDATE_ARCHIVED_MEETING_INVOICE_SENT: {
      const meetingIndex = state.archivedMeetings.findIndex((m) => {
        return m.id === action.meetingId;
      });
      const newArchivedMeetings = [...state.archivedMeetings];
      newArchivedMeetings[meetingIndex].invoicesent = action.invoiceSent;
      return { ...state, archivedMeetings: newArchivedMeetings };
    }
    default:
      return state;
  }
}

export default meetingReducer;
