import _ from "lodash";
import { JobRange } from "../models/range";
import { secondsToTimeString } from "./formatters";

export const createVtt = (
  ranges: JobRange[],
  encodeBase64 = true,
  plainWords?: string,
  index?: number
) => {
  let vttFormat = `WEBVTT\n\n`;

  ranges.map((range, i) => {
    vttFormat += `${secondsToTimeString(range.st)} --> ${secondsToTimeString(
      range.et
    )}\n`;
    const rangeString =
      plainWords && index === i
        ? plainWords
        : range.words
            .map((word, i) => {
              let wordText = word.text;
              if (_.has(word, "line_ix") && range.words[i + 1]) {
                const lineBreak = word.line_ix !== range.words[i + 1].line_ix;
                wordText = lineBreak ? `${wordText}\n` : wordText;
              }
              return wordText;
            })
            .join(" ")
            .replace(/\n /g, "\n");
    vttFormat += `${rangeString}\n\n`;
  });
  if (encodeBase64) {
    const b64 = window.btoa(unescape(encodeURIComponent(vttFormat)));
    return b64;
  } else {
    return vttFormat;
  }
};
