import React from "react";
import { useTranslation } from "react-i18next";

import { HeaderFooterPageSlice, SettingPagesProps } from "../ExportConfigTypes";

import { ControllerHOC } from "../../../components/common/V3/ControllerHOC/ControllerHOC";
import { ImageUpload } from "../../../components/common/V3/ImageUpload/ImageUpload";
import { SwitchInput } from "../../../components/common/V3/SwitchInput/SwitchInput";
import { TextAlignmentSelection } from "../../../components/common/V3/TextAlignmentSelection/TextAlignmentSelection";
import { TextInput } from "../../../components/common/V3/TextInput/TextInput";
import { SliderInput } from "../../../components/common/V3/SliderInput/SliderInput";

import "./../SettingPages.scss";

export const HeaderFooterPage = ({
  logoUpload,
  form,
}: SettingPagesProps<HeaderFooterPageSlice>) => {
  const { t } = useTranslation();

  const headerFooterWatch: HeaderFooterPageSlice = form?.watch() as HeaderFooterPageSlice;
  const control = form?.control;
  const setValue = form?.setValue;
  const getValues = form?.getValues;
  const errors = form?.formState.errors;

  return (
    <form>
      <div className="setting-page">
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_header_footer_first_page_header")}</div>
            <ControllerHOC control={control} name={"firstPageHeader.isActive"}>
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_header_footer_explanation_1")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_logo")}:</div>
            <ControllerHOC
              control={control}
              name={"firstPageHeader.logo.isActive"}
            >
              <SwitchInput
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"firstPageHeader.logo.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              getValues={getValues}
              setValue={setValue}
              name={"firstPageHeader.logo.url"}
            >
              {logoUpload && (
                <ImageUpload
                  id={"first-page-logo"}
                  disabled={!headerFooterWatch?.firstPageHeader?.isActive}
                  handleFileUpload={logoUpload}
                />
              )}
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"firstPageHeader.logo.size"}
            >
              <SliderInput
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_job_name")}:
            </div>
            <ControllerHOC
              control={control}
              name={"firstPageHeader.jobName.isActive"}
            >
              <SwitchInput
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"firstPageHeader.jobName.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_job_date")}:
            </div>
            <ControllerHOC
              control={control}
              name={"firstPageHeader.jobDate.isActive"}
            >
              <SwitchInput
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>

            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"firstPageHeader.jobDate.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.firstPageHeader?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_header_footer_other_pages_header")}</div>
            <ControllerHOC control={control} name={"otherPagesHeader.isActive"}>
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_header_footer_explanation_2")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_logo")}:</div>
            <ControllerHOC
              control={control}
              name={"otherPagesHeader.logo.isActive"}
            >
              <SwitchInput
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"otherPagesHeader.logo.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              getValues={getValues}
              name={"otherPagesHeader.logo.url"}
            >
              {logoUpload && (
                <ImageUpload
                  handleFileUpload={logoUpload}
                  id={"other-pages-logo"}
                  disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
                />
              )}
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"otherPagesHeader.logo.size"}
            >
              <SliderInput
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_job_name")}:
            </div>
            <ControllerHOC
              control={control}
              name={"otherPagesHeader.jobName.isActive"}
            >
              <SwitchInput
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"otherPagesHeader.jobName.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_job_date")}:
            </div>
            <ControllerHOC
              control={control}
              name={"otherPagesHeader.jobDate.isActive"}
            >
              <SwitchInput
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"otherPagesHeader.jobDate.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.otherPagesHeader?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="title">
            <div>{t("export_config_header_footer_footer")}:</div>
            <ControllerHOC control={control} name={"footer.isActive"}>
              <SwitchInput />
            </ControllerHOC>
          </div>
          <div className="sub-title">
            {t("export_config_header_footer_explanation_3")}
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">{t("export_config_global_logo")}:</div>
            <ControllerHOC control={control} name={"footer.logo.isActive"}>
              <SwitchInput disabled={!headerFooterWatch?.footer?.isActive} />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"footer.logo.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.footer?.isActive}
              />
            </ControllerHOC>
            <ControllerHOC
              setValue={setValue}
              getValues={getValues}
              name={"footer.logo.url"}
            >
              {logoUpload && (
                <ImageUpload
                  handleFileUpload={logoUpload}
                  id={"logo-footer"}
                  disabled={!headerFooterWatch?.footer?.isActive}
                />
              )}
            </ControllerHOC>
          </div>
          <div className="inputs-row">
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"footer.logo.size"}
            >
              <SliderInput disabled={!headerFooterWatch?.footer?.isActive} />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        {/* SECTION START */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_global_page_numbering")}:
            </div>
            <ControllerHOC
              control={control}
              name={"footer.pageNumbering.isActive"}
            >
              <SwitchInput disabled={!headerFooterWatch?.footer?.isActive} />
            </ControllerHOC>
            <ControllerHOC
              control={control}
              setValue={setValue}
              name={"footer.pageNumbering.align"}
            >
              <TextAlignmentSelection
                hasJustify={false}
                disabled={!headerFooterWatch?.footer?.isActive}
              />
            </ControllerHOC>
          </div>
        </div>
        {/* SECTION END */}
        <div className="section">
          <div className="inputs-row">
            <div className="input-title">
              {t("export_config_general_footer_text")}
            </div>
            <ControllerHOC
              name={"footer.footerText"}
              control={control}
              errors={errors}
              setValue={setValue}
            >
              <TextInput
                disabled={!headerFooterWatch?.footer?.isActive}
                placeholder={t("export_config_general_footer_text")}
              />
            </ControllerHOC>
          </div>
        </div>
      </div>
    </form>
  );
};
