import React, { FC, useState, useEffect, useRef } from "react";
import i18n from "../../i18n";

import SearchBar from "../SearchBar/SearchBar";
import { useTranslation } from "react-i18next";
import sortAndFilterService from "../../services/sortAndFilterService";
import { getLanguageDirection } from "../../utils/locales";

import CircularProgress from "@material-ui/core/CircularProgress";
import { LoggedInUser, MinimalTranscriber } from "../../models/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import "./TranscribersList.scss";
interface Props {
  close: () => void;
  setStatusClr: (clr: string) => void;
  transcribers: MinimalTranscriber[];
  loggedInUser?: LoggedInUser | null;
  meetingName: string;
  meetingStatus: number;
  handleAssignedTranscriber: (transcriber: MinimalTranscriber) => void;
  isLoading: boolean;
}

const TranscribersList: FC<Props> = ({
  close,
  setStatusClr,
  transcribers,
  meetingName,
  meetingStatus,
  handleAssignedTranscriber,
  isLoading,
}): JSX.Element => {
  const { t } = useTranslation();

  const [isOverFlowActive, setIsOverFlowActive] = useState(false);
  const [searchBy, setSearchBy] = useState<string>("");
  const [
    chosenTranscriber,
    setChosenTranscriber,
  ] = useState<MinimalTranscriber | null>(null);
  const [transition, setTransition] = useState<number>(100);
  const headerRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    setTimeout(() => setTransition(0), 0);
  }, []);

  const onClose = () => {
    setTransition(100);
    setTimeout(() => close(), 300);
  };

  const chooseTranscriber = (transcriber: MinimalTranscriber) => {
    setChosenTranscriber(transcriber);
    setTransition(100);
    setStatusClr("positive");
  };

  const approveChosenTranscriber = () => {
    if (chosenTranscriber) handleAssignedTranscriber(chosenTranscriber);
  };

  const declineTranscriber = () => {
    setTransition(0);
    setTimeout(() => setChosenTranscriber(null), 300);
    setStatusClr("main");
  };

  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };

  let transcribersToPreview = [...transcribers];
  transcribersToPreview = sortAndFilterService.sortTranscribers(
    transcribersToPreview,
    "by-title",
    "ascending"
  );
  transcribersToPreview = searchBy
    ? sortAndFilterService.searchTranscribers(transcribersToPreview, searchBy)
    : transcribersToPreview;

  const checkTextOverFlow = () => {
    if (headerRef && headerRef.current) {
      const isOverflow =
        headerRef.current.offsetHeight < headerRef.current.scrollHeight ||
        headerRef.current.offsetWidth < headerRef.current.scrollWidth;
      setIsOverFlowActive(isOverflow);
    }
  };
  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ transform: `translateY(${transition}%)` }}
        className="transcribers-list"
      >
        <div className="header">
          <div onClick={onClose} className="title">
            <div className="back-icn-wrapper">
              <FontAwesomeIcon
                icon={[
                  "fal",
                  getLanguageDirection(i18n.language) === "ltr"
                    ? "angle-left"
                    : "angle-right",
                ]}
              />
            </div>
            <h3
              onMouseEnter={checkTextOverFlow}
              className="name"
              ref={headerRef}
              data-tip
              data-for={meetingName
                .replaceAll("\r", "")
                .replaceAll("\n", "")
                .replaceAll("\r\n", "")}
            >
              {meetingName
                .replaceAll("\r", "")
                .replaceAll("\n", "")
                .replaceAll("\r\n", "")}
            </h3>
            {isOverFlowActive && (
              <ReactTooltip
                className="tooltip-comp"
                id={meetingName
                  .replaceAll("\r", "")
                  .replaceAll("\n", "")
                  .replaceAll("\r\n", "")}
                place="bottom"
              >
                <span className="tooltip-content">
                  {meetingName
                    .replaceAll("\r", "")
                    .replaceAll("\n", "")
                    .replaceAll("\r\n", "")}
                </span>
              </ReactTooltip>
            )}
          </div>
          <SearchBar onInput={onSearchBy} />
        </div>
        {transcribers.length === 0 && <CircularProgress />}
        {transcribers.length > 0 && (
          <div className="list">
            {transcribersToPreview.map(
              (transcriber: MinimalTranscriber, idx: number) =>
                transcriber.username.length > 0 && (
                  <div
                    className="item"
                    key={idx}
                    onClick={() => chooseTranscriber(transcriber)}
                  >
                    <div className="dot-container">
                      <div className="dot"></div>
                    </div>
                    <p>{transcriber.username}</p>
                  </div>
                )
            )}
          </div>
        )}
      </div>
      {!!chosenTranscriber && (
        <div onClick={(e) => e.stopPropagation()} className="approve-modal">
          <p>
            {t("approve_assigned_transcriber_1")}
            <span> {chosenTranscriber?.username} </span>
            {meetingStatus === 3
              ? t("approve_assigned_transcriber_2")
              : t("approve_assigned_proofer_2")}
            <span>{meetingName}?</span>
          </p>
          <div className="btns">
            {!isLoading && (
              <button
                onClick={declineTranscriber}
                className="meeting-preview-btn negative-hollow"
              >
                {t("cancel")}
              </button>
            )}
            {!isLoading && (
              <button
                onClick={approveChosenTranscriber}
                className="meeting-preview-btn positive"
              >
                {t("approve")}
              </button>
            )}
            {isLoading && (
              <div className="loading-spinner">
                <CircularProgress style={{ width: "20px" }} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TranscribersList;
