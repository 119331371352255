import React, { FC, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/rootReducer";

import { RoomAsset } from "../../models";
import TimeFormat from "hh-mm-ss";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoundManager from "../../services/SoundManager";
import { animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import classNames from "classnames";
import "./MyMediaPlayer.scss";

class Props {
  mediaSources: RoomAsset[];
  offsets?: { [key: string]: number };
  chosenMedia: RoomAsset | undefined;
  setChosenMedia: React.Dispatch<React.SetStateAction<RoomAsset | undefined>>;
  sendCurrTime?: (currTime: number) => void;
  isDraggable?: boolean;
  isFloating?: boolean;
  toggleIsFloating?: () => void;
  showMediaSourceManagement?: boolean;
  isSplitView?: boolean;
  allowSplit?: boolean;
}

const MyMediaPlayer: FC<Props> = ({
  mediaSources,
  offsets,
  setChosenMedia,
  chosenMedia,
  sendCurrTime,
  isDraggable,
  isSplitView,
  isFloating,
  showMediaSourceManagement,
  toggleIsFloating,
  allowSplit,
}): JSX.Element => {
  const mainEl = useRef<HTMLDivElement>(null);
  const speeds = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  const { playerRewind, playerForward, playerRewindResume } = useSelector(
    (state: AppState) => state.userStore.settings
  );
  //! READ ME:
  //! do not change media player element's width in css! if you want to
  //! change the width, change with the code below
  const mainElDataInit = {
    width: isSplitView ? window.innerWidth * 0.28 : window.innerWidth * 0.55, // calculate vw
    height: 0,
    top: 0,
  };
  const [mediaLength, setMediaLength] = useState(0);
  const [isMouseOverSpeed, setIsMouseOverSpeed] = useState<boolean>(false);
  const [currSpeedIdx, setCurrSpeedIdx] = useState<number>(2);
  const [currTime, setCurrTime] = useState<number>(0);
  const [currPrecent, setCurrPrecent] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [translateXMain, setTranslateXMain] = useState<number>(0);
  const [translateYMain, setTranslateYMain] = useState<number>(0);
  const [isMouseOverMarker, setIsMouseOverMarker] = useState<boolean>(false);
  const [markerOffsetTime, setMarkerOffsetTime] = useState<number | null>(null);
  const [markerDragOffset, setMarkerDragOffset] = useState<number>(0);
  const [isMarkerDragging, setIsMarkerDragging] = useState<boolean>(false);
  const [currTimeFreeze, setCurrTimeFreeze] = useState<number>(0);
  const [toggleTime, setToggleTime] = useState<boolean>(true);
  const [autoPlay, setAutoPlay] = useState(false);

  const markerDragBind = useDrag(
    ({ down, movement: [x] }) => {
      if (down) {
        handleMarkerDrag(x);
        setIsMarkerDragging(true);
      } else {
        markerOffsetTime && SoundManager.setOffset(markerOffsetTime);
        markerOffsetTime && setCurrTime(markerOffsetTime);
        setMarkerDragOffset(0);
        setMarkerOffsetTime(null);
        setIsMarkerDragging(false);
      }
    },
    {
      bounds: {
        left: -mainElDataInit.width,
        right: mainElDataInit.width,
      },
    }
  );
  const handleMarkerDrag = (x: number) => {
    if (!chosenMedia) return;
    const movementPrecentage = (x / mainElDataInit.width) * 100;
    const newLiveMarkerPosition = movementPrecentage + currPrecent;
    if (newLiveMarkerPosition <= 0 || newLiveMarkerPosition >= 100) return;
    const movementInSecs = (mediaLength / 100) * movementPrecentage; //! bug?
    setMarkerOffsetTime(currTimeFreeze + movementInSecs);
    setMarkerDragOffset(x);
  };

  const mainDragBind = useDrag(
    ({ offset: [x, y] }) => {
      handleMainDrag(x, y);
    },
    {
      enabled: !isMouseOverMarker && !!isDraggable,
      bounds: {
        left: (screenWidth - mainElDataInit.width - 30) / -2,
        right: (screenWidth - mainElDataInit.width - 30) / 2,
        top: -(window.innerHeight * 0.9 - 100), // user screen - chrome static navigation (10vw) - 100px of top bar
        bottom: 0,
      },
    }
  );
  const setOffset = (media: RoomAsset) => {
    if (offsets) {
      return offsets[media.name] ? Math.abs(offsets[media.name] / 1000) : 0;
    }
  };
  const handleMainDrag = (x: number, y: number) => {
    setTranslateXMain(x);
    setTranslateYMain(y);
  };

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // re-positioning the media player element when user toggle the split view
    setTranslateXMain(0);
    setTranslateYMain(0);
  }, [isSplitView]);
  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    const defaultMedia = mediaSources[0];

    if (!defaultMedia) return;
    setChosenMedia(defaultMedia);
    SoundManager.initializeMedia({
      media: defaultMedia,
      setMedia: console.log,
      setMediaLength,
      setCurrTime,
      mediaLength,
      autoPlay,
      jobId: "",
    });
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (mainEl) {
      handleWindowResize();
    }
  }, [mainEl]);

  useEffect(() => SoundManager.changeSpeed(speeds[currSpeedIdx]), [
    currSpeedIdx,
  ]);

  useEffect(() => {
    if (!chosenMedia) return;
    sendCurrTime && sendCurrTime(currTime);
    setCurrPrecent((currTime / mediaLength) * 100);
  }, [currTime]);

  useEffect(() => {
    if (isMarkerDragging) {
      setMarkerOffsetTime(currTime);
      setCurrTimeFreeze(currTime);
    } else setCurrTimeFreeze(0);
  }, [isMarkerDragging]);

  useEffect(() => {
    const onChosenMediaChange = async () => {
      if (!chosenMedia) return;
      SoundManager.initializeMedia({
        media: chosenMedia,
        setMedia: console.log,
        setCurrTime,
        setMediaLength,
        mediaLength,
        offset: setOffset(chosenMedia),
        autoPlay,
        jobId: "",
      });
      setAutoPlay(true);
    };
    onChosenMediaChange();
    return () => {
      SoundManager.pause();
    };
  }, [chosenMedia]);

  if (!chosenMedia) return <></>;
  const downloadAudio = () => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const blob = xhr.response;
      saveAs(blob, chosenMedia.name);
    };
    xhr.open("GET", chosenMedia.src);
    xhr.send();
  };

  const changeSpeed = (direction: number) => {
    const changeTo = currSpeedIdx + direction;
    if (changeTo < 0 || changeTo > speeds.length - 1) return;
    setCurrSpeedIdx(currSpeedIdx + direction);
  };

  const goRewind = () => {
    SoundManager.playRelative((playerRewind || 5) * -1);
  };

  const goForward = () => {
    SoundManager.playRelative(playerForward || 5);
  };

  return (
    <animated.div
      data-testid="my-media-player"
      ref={mainEl}
      onMouseDown={() => setIsDragging(true)}
      onMouseUp={() => setIsDragging(false)}
      className={classNames("media-player", {
        draggable: isDraggable,
        dragging: isDragging,
        floating: isFloating,
      })}
      style={{
        transform: `translate(${translateXMain}px, ${translateYMain}px)`,
        width: `100%`,
      }}
      {...mainDragBind()}
    >
      <div className="control-deck">
        <div className="speed">
          <div className="icn-wrapper">
            <FontAwesomeIcon icon={["fal", "clock"]} />
          </div>
          <div
            onMouseOver={() => setIsMouseOverSpeed(true)}
            onMouseLeave={() => setIsMouseOverSpeed(false)}
            className="speed-control"
          >
            {isMouseOverSpeed && (
              <div className="arrow" onClick={() => changeSpeed(1)}>
                <FontAwesomeIcon icon={["fas", "caret-up"]} />
              </div>
            )}
            <p className="unselectable">{speeds[currSpeedIdx]}</p>
            {isMouseOverSpeed && (
              <div className="arrow" onClick={() => changeSpeed(-1)}>
                <FontAwesomeIcon icon={["fas", "caret-down"]} />
              </div>
            )}
          </div>
        </div>
        <div className="playback">
          <div className="btns">
            <div onClick={() => goRewind()} className="skip5 back">
              <FontAwesomeIcon
                style={{ transform: "scaleX(-1)" }}
                icon={["fas", "share"]}
              />
              <p className="unselectable">{playerRewind}</p>
            </div>
            <div
              onClick={() =>
                SoundManager.togglePlay((playerRewindResume || 0) * -1)
              }
              className="play-pause"
            >
              {!SoundManager.isPlaying && (
                <FontAwesomeIcon icon={["far", "play-circle"]} />
              )}
              {SoundManager.isPlaying && (
                <FontAwesomeIcon icon={["far", "pause-circle"]} />
              )}
            </div>
            <div onClick={() => goForward()} className="skip5 next">
              <p className="unselectable">{playerForward}</p>
              <FontAwesomeIcon icon={["fas", "share"]} />
            </div>
          </div>
          <div className="time-info">
            {toggleTime && !isMarkerDragging && (
              <div className="media-time-range">
                <div className="curr-time unselectable">
                  {TimeFormat.fromS(
                    Number(currTime.toFixed(1)),
                    `${Math.round(currTime) > 3600 ? "hh:" : ""}mm:ss.sss`
                  )}
                  -
                </div>
                <div className="curr-time unselectable">
                  {TimeFormat.fromS(
                    Math.round(Math.round(mediaLength || 0)),
                    `${
                      Math.round(mediaLength || 0) > 3600 ? "hh:" : ""
                    }mm:ss.sss`
                  )}
                </div>
              </div>
            )}
            {isMarkerDragging && markerOffsetTime && (
              <div className="curr-time unselectable">
                {TimeFormat.fromS(
                  Number(markerOffsetTime.toFixed(1)),
                  `${Math.round(markerOffsetTime) > 3600 ? "hh:" : ""}mm:ss.sss`
                )}
              </div>
            )}
          </div>
        </div>
        {allowSplit && (
          <div className="viewModeIcon">
            <FontAwesomeIcon
              icon={["fal", isFloating ? "columns" : "window-restore"]}
              onClick={toggleIsFloating}
            />
          </div>
        )}

        {!!showMediaSourceManagement && (
          <div className="media-source-management">
            <div className="icns-container">
              <div className="media-list icn">
                <FontAwesomeIcon icon={["fas", "list-music"]} />
              </div>
              <div onClick={downloadAudio} className="download-media icn">
                <FontAwesomeIcon icon={["far", "arrow-to-bottom"]} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          background: `linear-gradient(90deg, #3c5069 ${currPrecent}%, #323232 ${currPrecent}%)`,
        }}
        className="timeline"
      >
        <animated.div style={{ width: "100%" }} {...markerDragBind()}>
          <div
            className="marker-container"
            style={{
              left: `${
                isMarkerDragging
                  ? (currTimeFreeze / mediaLength) * 100
                  : currPrecent
              }%`,
              transform: `translateX(${markerDragOffset}px`,
            }}
            onMouseOver={() => setIsMouseOverMarker(true)}
            onMouseLeave={() => setIsMouseOverMarker(false)}
          >
            <div className="icns-wrapper">
              <FontAwesomeIcon icon={["fas", "caret-left"]} />
              <FontAwesomeIcon icon={["fas", "caret-right"]} />
            </div>
            <div className="seperator"></div>
          </div>
        </animated.div>
      </div>
    </animated.div>
  );
};

export default MyMediaPlayer;
