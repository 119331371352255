import {
  SystemState,
  SystemActionTypes,
  SET_LOADING_REASON,
  SET_ERROR_REASON,
  CLEAR_LOADING_REASON,
  POP_INDICATOR,
  RESET_SORTING,
  UPDATE_SORTING,
  SET_DIRECTION,
} from "./types";

const initialState: SystemState = {
  direction: "rtl",
  loadingReason: null,
  errorReason: null,
  indicatorPopupState: null,
  sorting: {
    type: "",
    options: [],
    sortBy: "",
    order: "",
  },
};

function systemReducer(
  state = initialState,
  action: SystemActionTypes
): SystemState {
  switch (action.type) {
    case SET_DIRECTION:
      return {
        ...state,
        direction: action.direction,
      };
    case SET_LOADING_REASON:
      return {
        ...state,
        loadingReason: action.loadingReason,
      };
    case CLEAR_LOADING_REASON:
      return {
        ...state,
        loadingReason: null,
      };
    case SET_ERROR_REASON:
      return {
        ...state,
        errorReason: action.errorReason,
      };
    case POP_INDICATOR:
      return {
        ...state,
        indicatorPopupState: action.indicator,
      };
    case RESET_SORTING:
      return {
        ...state,
        sorting: { ...action.sorting },
      };
    case UPDATE_SORTING:
      return {
        ...state,
        sorting: { ...action.newSorting },
      };
    default:
      return state;
  }
}

export default systemReducer;
