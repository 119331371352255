import React, { ChangeEvent, ChangeEventHandler, FC, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import useDigitInput from "react-digit-input";

import "./DigitInput.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  onChange?: any;
}

const DigitInput: FC<Props> = ({
  className,
  disabled = false,
  onChange,
}): JSX.Element => {
  const handleOnChange = (inputGroupValue: string) => {
    setValue(inputGroupValue);
    onChange({ target: { name: "code", value: inputGroupValue.trim() } });
  };

  const [value, setValue] = React.useState("");
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange: handleOnChange,
  });

  return (
    <div
      className={classNames("DigitInputComponent", className, {
        disabled,
      })}
    >
      <input className="digit" inputMode="decimal" autoFocus {...digits[0]} />
      <input className="digit" inputMode="decimal" {...digits[1]} />
      <input className="digit" inputMode="decimal" {...digits[2]} />
      <span className="hyphen" />
      <input className="digit" inputMode="decimal" {...digits[3]} />
      <input className="digit" inputMode="decimal" {...digits[4]} />
      <input className="digit" inputMode="decimal" {...digits[5]} />
    </div>
  );
};

export default DigitInput;
