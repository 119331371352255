import _ from "lodash";
import { jobDictionary } from "../constants/jobDictionary";
import { ElasticJob, MeetingPreviewData } from "../models";
import { MinimalProofer, MinimalTranscriber } from "../models/user";

const formtJobToCamelCase = (newJob: ElasticJob) =>
  //@ts-ignore
  _.mapKeys(newJob, (value, key) => jobDictionary[key]);

const formatJobToElasticJob = (newJob: MeetingPreviewData) => {
  const elasticJob = _.mapKeys(newJob, (value, key: string) =>
    key.toLowerCase()
  );
  //@ts-ignore
  return elasticJob as ElasticJob;
};

const getTranscriberFromTranscribers = (
  transcriberId: string,
  transcribers: MinimalTranscriber[],
  fieldToFilter?: string
) => {
  const transcriber = transcribers.find((t) => t.id === transcriberId);

  return fieldToFilter && transcriber
    ? _.get(transcriber, fieldToFilter)
    : transcriber;
};

const getMember = (
  memberId: string,
  members: MinimalTranscriber[] | MinimalProofer[],
  fieldToFilter?: string
) => {
  const member = members.find((t) => t.id === memberId);
  return fieldToFilter && member ? _.get(member, fieldToFilter) : member;
};

const getProoferFromProofers = (
  prooferId: string,
  proofers: MinimalTranscriber[],
  fieldToFilter?: string
) => {
  const transcriber = proofers.find((t) => t.id === prooferId);

  return fieldToFilter && transcriber
    ? _.get(transcriber, fieldToFilter)
    : transcriber;
};
const unTranscriberMeeting = (meeting: MeetingPreviewData) => {
  meeting.assignedTranscriber = null;
  delete meeting.assignedUserInfo;
  meeting.assignedMethod = null;
  meeting.isApproved = false;
  return meeting;
};
export default {
  formtJobToCamelCase,
  getProoferFromProofers,
  formatJobToElasticJob,
  unTranscriberMeeting,
  getTranscriberFromTranscribers,
  getMember,
};
