import { dataUrlToArrayBuffer } from "./formatters";

export interface ImageWithRatio {
  buffer: ArrayBuffer;
  width: number;
  height: number;
}

export const getImageWithRatioFromImageUrl = async (
  url: string,
  width: number
): Promise<ImageWithRatio> => {
  const getImageElementFromUrl = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  };

  const calculateAspectRatioFit = async (
    imageUrl: string,
    newWidth: number
  ): Promise<{ width: number; height: number }> => {
    const img: HTMLImageElement = await getImageElementFromUrl(imageUrl);
    const srcWidth = img.width;
    const srcHeight = img.height;
    const ratio = srcHeight / srcWidth;
    const newHeight = ratio * newWidth;
    return { width: newWidth, height: newHeight };
  };

  const imageWithRation: ImageWithRatio = {
    buffer: await dataUrlToArrayBuffer(url),
    ...(await calculateAspectRatioFit(url, width)),
  };
  return imageWithRation;
};
