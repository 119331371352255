import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

import AuthService from "../../services/AuthService";

import FormInput from "../../components/FormInputs/FormInput";
import Button from "../../components/common/Button/Button";
import Dropdown from "../../components/common/Dropdown/Dropdown";
import Datepicker from "../../components/common/Datepicker/Datepicker";

import { getCountries, getLanguages } from "../../utils/locales";

import Logger from "../../services/Logger";

const logger = Logger("AddUserDetails");

import "./AuthPages.scss";

type userDetailsInputs = {
  name: string;
  country: string;
  languages: string[];
  birthday: Date;
  email: string;
  password: string;
  passwordConfirm: string;
  checkbox: string;
};

const AddUserDetails = (): JSX.Element => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [countries] = useState(getCountries(t));
  const [languages] = useState(getLanguages(t));
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const handleAddDetails = async (data: userDetailsInputs) => {
    try {
      setIsLoading(true);
      if (loggedInUser) {
        await AuthService.addDetails({
          ...data,
          userId: loggedInUser.id,
        });
        location.reload();
      }
    } catch (err: any) {
      logger.error(err, "handleAddDetails");
      setError(t(err));
    } finally {
      setIsLoading(false);
    }
  };

  const AddDetailsForm = () => {
    const {
      register,
      handleSubmit,
      watch,
      getValues,
      setValue,
      formState: { errors, isDirty, isValid },
    } = useForm<userDetailsInputs>({
      mode: "onChange",
      shouldUnregister: false,
    });
    const addDetails: SubmitHandler<userDetailsInputs> = (
      data: userDetailsInputs
    ) => handleAddDetails(data);

    const [userValue, setUserValue] = useState<{
      name: string;
      country: string;
      languages: [] | string[];
      checkbox: boolean;
    }>({ name: "", country: "", languages: [], checkbox: false });

    register("country", {
      required: { value: true, message: t("required_field") },
    });
    register("languages", {
      required: { value: true, message: t("required_field") },
    });

    useEffect(() => {
      if (loggedInUser) {
        const { username, country, languages } = loggedInUser;
        setValue("name", loggedInUser.username);
        setValue("country", loggedInUser.country);
        setValue(
          "languages",
          loggedInUser.languages?.length ? loggedInUser.languages : []
        );
        setUserValue({
          ...userValue,
          name: username,
          country: country,
          languages: languages ? languages : [],
          checkbox: false,
        });
      }
    }, [loggedInUser]);

    return (
      <form className="styledForm signUp" onSubmit={handleSubmit(addDetails)}>
        <div className="formError phoneResult">{error}</div>
        <FormInput
          className="styledInput"
          placeholder={t("name")}
          error={isDirty && !isValid ? errors.name?.message : ""}
          disabled={isLoading}
          register={register("name", {
            required: { value: true, message: t("required_field") },
          })}
        />
        <Dropdown
          value={userValue.country}
          options={countries}
          onChange={(c) => {
            setValue("country", _.isArray(c) ? c[0] : c, {
              shouldValidate: true,
            });
            setUserValue({ ...userValue, country: _.isArray(c) ? c[0] : c });
          }}
          placeholder={t("country")}
          className="styledInput"
        />
        <Dropdown
          value={_.map(userValue.languages, (l) =>
            l.includes("-") ? l : `${l.slice(0, 2)}-${l.slice(-2)}`
          )}
          options={languages}
          onChange={(l) => {
            setValue("languages", _.isArray(l) ? l : [l], {
              shouldValidate: true,
            });
            setUserValue({ ...userValue, languages: _.isArray(l) ? l : [l] });
          }}
          multiSelect={true}
          placeholder={t("languages")}
          className="styledInput"
        />
        <div className={classNames("flex checkbox", { error: !!error })}>
          <input
            disabled={isLoading}
            type="checkbox"
            {...register("checkbox", {
              required: { value: true, message: t("required_field") },
            })}
          />
          <label className="checkboxLabel" htmlFor="checkbox">
            <span>{`${t("agree_to_terms1")}`}</span>
            <a
              href="https://sumit-ai.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              {t("agree_to_terms2")}
            </a>
            <span>{t("agree_to_terms3")}</span>
            <a
              href="https://sumit-ai.com/terms-of-use/"
              target="_blank"
              rel="noreferrer"
            >
              {t("agree_to_terms4")}
            </a>
          </label>
          {error && <div className="inputError">{error}</div>}
        </div>
        <Button
          type="submit"
          className={"fullWidth"}
          label={t("confirm")}
          loading={isLoading}
          disabled={!isValid || isLoading}
        />
      </form>
    );
  };

  return (
    <div className="SignUpStep">
      <div className="splitContainer">
        <div className="userPasswordContainer">
          <AddDetailsForm />
        </div>
      </div>
    </div>
  );
};

export default AddUserDetails;
