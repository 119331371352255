import _ from "lodash";
import { LoggedInUser } from "../models/user";
import FeatureFlagsService from "../services/FeatureFlagsService";

export const getJobsUrl = (job: any, user?: LoggedInUser | null) => {
  let pagePath = "job";
  if (
    !FeatureFlagsService.isEnabled("newSubtitlesEditor", user) &&
    ((job?.jobType && job.jobType === "subtitles") ||
      job.previewFormat === "subtitles")
  ) {
    pagePath = "meeting";
  }
  return `/${pagePath}/${job.id}`;
};
