import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import i18n from "../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import classNames from "classnames";
import { format } from "date-fns";

import { previewFormats } from "../../constants/previewFormats";
import { getLanguageDirection } from "../../utils/locales";

import { AppState } from "../../store/rootReducer";

import SearchBar from "../SearchBar/SearchBar";
import RangeDatePicker from "../common/RangeDatePicker/RangeDatePicker";
import Popup from "../common/../Popup/Popup";
import RangeSliderField from "../common/RangeSliderField/RangeSliderField";
import PopupDropdown from "../PopupDropdown/PopupDropdown";

import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";

import TimeService from "../../services/TimeService";

import "react-datepicker/dist/react-datepicker.css";

import "./TableFilter.scss";

interface Props {
  onChange: (obj: any) => void;
  dataCount?: number;
  fieldsToFilter: string[];
  handleOnSearch: (value: string) => void;
  options?: {
    label: string;
    value: string;
    icon: string;
  }[];
  name?: string;
}

const TableFilter: FC<Props> = ({
  onChange,
  dataCount = 0,
  handleOnSearch,
  fieldsToFilter,
  children,
}): JSX.Element => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState("");

  const fieldsRef = useRef<HTMLDivElement>(null);

  useOutsideClick(fieldsRef, () => {
    setOpenPopup("");
  });

  const [filters, setFilters] = useState<{
    [key: string]: any;
  }>({});
  const clientsOptions = useSelector(
    (state: AppState) => state.clientStore.clientsOptions
  );
  const dir = useSelector((state: AppState) => state.systemStore.direction);
  const proofersOptions = useSelector(
    (state: AppState) => state.userStore.proofersOptions
  );
  const transcribersOptions = useSelector(
    (state: AppState) => state.userStore.transcribersOptions
  );

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  const handleRangeDebounce = useCallback(
    _.debounce((newFilters) => {
      setFilters(newFilters);
    }, 500),
    []
  );

  const closePopup = (fieldName: string) =>
    setOpenPopup(fieldName === openPopup ? "" : fieldName);

  const clearValue = (fieldName: string, close = true) => {
    const { [fieldName]: keyToDelete, ...newFiltersValues } = filters;

    setFilters(newFiltersValues);

    close && closePopup(fieldName);
  };
  const selectedFilters = () => {
    const selectedFiltersValues = [];
    for (const filterName in filters) {
      selectedFiltersValues.push(
        <div
          onClick={() => clearValue(filterName, false)}
          key={filterName}
          className={classNames("filterContainer", filterName)}
        >
          <div className="filter">
            {_.get(filters, `${filterName}.visualFilterValue`)}
          </div>
          <div className="icon">x</div>
        </div>
      );
    }
    return selectedFiltersValues;
  };

  const filtersField = [
    {
      type: "range",
      label: "price",
      field: "price.value",
      title: "pick_range",
      fieldName: "price",
      handleFilterChange: (values: number[]) => {
        const visualString =
          values.length > 1
            ? values
                .map((value) => {
                  return `${value}${t("currency")}`;
                })
                .join(" - ")
            : values;
        handleRangeDebounce({
          ...filters,
          price: {
            value: values,
            type: "range",
            visualFilterValue: visualString,
          },
        });
        return values;
      },
      prefix: "currency",
      inputType: "number",
    },
    {
      type: "range",
      label: "length",
      title: "pick_range",
      field: "meetinglength.value",
      fieldName: "meetinglength",
      handleFilterChange: (values: number[]) => {
        const visualString =
          values.length > 1
            ? values
                .map((value) => {
                  return TimeService.getTimeStringFromSecs(Number(value));
                })
                .join(" - ")
            : values;
        handleRangeDebounce({
          ...filters,
          meetinglength: {
            value: values,
            type: "range",
            visualFilterValue: visualString,
          },
        });
        return values;
      },
      formatInput: (value: number | string) => {
        return TimeService.getTimeStringFromSecs(Number(value));
      },
      editable: false,
      value: [0, 18000],
      max: 18000,
      step: 50,
    },
    {
      type: "dateRange",
      title: "pick_range",
      label: "archived_at",
      fieldName: "archivedat",
      field: "archivedat.value",
      handleFilterChange: (values: Date[]) => {
        const dates = _.compact(values);
        if (dates.length !== 2) {
          return;
        }

        const visualString = dates
          .map((date) => format(date, "dd/MM/yyyy"))
          .join(" - ");

        setFilters({
          ...filters,
          archivedat: {
            value: dates,
            type: "range",
            visualFilterValue: visualString,
          },
        });
        closePopup("archivedat");
        return values;
      },
      getDate: (indicator: 0 | 1) => {
        const dates = _.get(filters, "uploaded.value");
        if (dates && dates[indicator]) {
          return dates[indicator];
        } else {
          return new Date();
        }
      },
    },
    {
      type: "dropdown",
      label: "job_types",
      title: "job_types",
      field: "jobtype.value",
      fieldName: "jobtype",
      options: previewFormats,
      handleFilterChange: (
        values: string | string[],
        labels: string[] | string
      ) => {
        if (!values.length) {
          clearValue("jobtype", false);
          return;
        }
        const selectedString =
          labels.length > 1
            ? `${t(labels[0])} +${labels.length - 1}`
            : t(labels[0]);
        setFilters({
          ...filters,
          jobtype: {
            value: values,
            type: "normal",
            visualFilterValue: selectedString,
          },
        });
      },
    },
    {
      type: "dropdown",
      label: "client",
      title: "client",
      field: "clientid.value",
      fieldName: "clientid",
      searchOption: true,
      options: clientsOptions,
      handleFilterChange: (
        values: string | string[],
        labels: string[] | string
      ) => {
        if (!values.length) {
          clearValue("clientid", false);
          return;
        }
        const selectedString =
          labels.length > 1
            ? `${t(labels[0])} +${labels.length - 1}`
            : t(labels[0]);
        setFilters({
          ...filters,
          clientid: {
            value: values,
            type: "normal",
            visualFilterValue: selectedString,
          },
        });
      },
    },
    {
      type: "dropdown",
      label: "proofer",
      title: "proofer",
      field: "reviewerid.value",
      fieldName: "reviewerid",
      searchOption: true,
      options: proofersOptions,
      handleFilterChange: (
        values: string | string[],
        labels: string[] | string
      ) => {
        if (!values.length) {
          clearValue("reviewerid", false);
          return;
        }
        const selectedString =
          labels.length > 1
            ? `${t(labels[0])} +${labels.length - 1}`
            : t(labels[0]);
        setFilters({
          ...filters,
          reviewerid: {
            value: values,
            type: "normal",
            visualFilterValue: selectedString,
          },
        });
      },
    },
    {
      type: "dropdown",
      label: "transcriber",
      title: "transcriber",
      field: "transcriberid.value",
      fieldName: "transcriberid",
      searchOption: true,
      options: transcribersOptions,
      handleFilterChange: (
        values: string | string[],
        labels: string[] | string
      ) => {
        if (!values.length) {
          clearValue("transcriberid", false);
          return;
        }
        const selectedString =
          labels.length > 1
            ? `${t(labels[0])} +${labels.length - 1}`
            : t(labels[0]);

        setFilters({
          ...filters,
          transcriberid: {
            value: values,
            type: "normal",
            visualFilterValue: selectedString,
          },
        });
      },
    },
  ];
  return (
    <div className="TableFilter">
      <div className="filters">
        <div className="filtersField" ref={fieldsRef}>
          {filtersField.map(
            (filterField) =>
              fieldsToFilter.includes(filterField.fieldName) &&
              (filterField.type === "dropdown" && filterField.options ? (
                <div
                  key={filterField.label}
                  className={classNames("field", dir, {
                    active:
                      _.get(openPopup, filterField.fieldName) ||
                      _.get(filters, filterField.fieldName),
                  })}
                >
                  <div
                    className={`${filterField.fieldName}Label label`}
                    onClick={() => closePopup(filterField.fieldName)}
                  >
                    {t(filterField.label)}
                  </div>
                  {openPopup === filterField.fieldName && (
                    <Popup
                      title={
                        <div className="titleContainer">
                          <div className="title">{t(filterField.title)}:</div>
                          <div
                            className="icon"
                            onClick={() => clearValue(filterField.fieldName)}
                          >
                            {<FontAwesomeIcon icon={["fal", "undo"]} />}
                          </div>
                        </div>
                      }
                      body={
                        <PopupDropdown
                          searchOption={filterField.searchOption}
                          className="dropdown"
                          multiSelect={true}
                          value={_.get(filters, filterField.field)}
                          options={filterField.options}
                          onChange={filterField.handleFilterChange}
                        ></PopupDropdown>
                      }
                    ></Popup>
                  )}
                </div>
              ) : filterField.type === "dateRange" ? (
                <div
                  key={filterField.label}
                  className={classNames("field", dir, {
                    active:
                      _.get(openPopup, filterField.fieldName) ||
                      _.get(filters, filterField.fieldName),
                  })}
                >
                  <div
                    className={`${filterField.fieldName}Label label`}
                    onClick={() => closePopup(filterField.fieldName)}
                  >
                    {t(filterField.label)}
                  </div>
                  {openPopup === filterField.fieldName && filterField.getDate && (
                    <Popup
                      title={
                        <div className="titleContainer">
                          <div className="title">{t(filterField.title)}:</div>
                          <div
                            className="icon"
                            onClick={() => clearValue(filterField.fieldName)}
                          >
                            {<FontAwesomeIcon icon={["fal", "undo"]} />}
                          </div>
                        </div>
                      }
                      body={
                        <RangeDatePicker
                          dates={_.get(filters, filterField.field)}
                          onDateChange={filterField.handleFilterChange}
                        />
                      }
                    ></Popup>
                  )}
                </div>
              ) : (
                <div
                  key={filterField.label}
                  className={classNames("field", dir, {
                    active:
                      _.get(openPopup, filterField.fieldName) ||
                      _.get(filters, filterField.fieldName),
                  })}
                >
                  <div
                    className={`${filterField.fieldName}Label label`}
                    onClick={() => closePopup(filterField.fieldName)}
                  >
                    {t(filterField.label)}
                  </div>
                  {openPopup === filterField.fieldName && (
                    <Popup
                      className={filterField.fieldName}
                      title={
                        <div className="titleContainer">
                          <div className="title">
                            {filterField.title && t(filterField.title) + ":"}
                          </div>
                          <div
                            className="icon"
                            onClick={() => clearValue(filterField.fieldName)}
                          >
                            {<FontAwesomeIcon icon={["fal", "undo"]} />}
                          </div>
                        </div>
                      }
                      body={
                        <RangeSliderField
                          max={filterField.max}
                          value={
                            _.get(filters, filterField.field)
                              ? _.get(filters, filterField.field)
                              : filterField.value
                          }
                          step={filterField.step}
                          editable={filterField.editable}
                          //@ts-ignore
                          onChange={filterField.handleFilterChange}
                          inputType={filterField.inputType as "number" | "text"}
                          formatInput={filterField.formatInput}
                          prefix={filterField.prefix}
                        ></RangeSliderField>
                      }
                    ></Popup>
                  )}
                </div>
              ))
          )}
        </div>
        <div className="searchComponent">
          <div className="rowCount">{dataCount}</div>
          <SearchBar onInput={handleOnSearch}></SearchBar>
        </div>
      </div>
      <div className="filtersAndActions">
        <div className={classNames("visualFilters", dir)}>
          {selectedFilters()}
        </div>
        {children}
      </div>
    </div>
  );
};

export default TableFilter;
