import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { ReactComponent as GoogleIcon } from "../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";

import "./AuthProvidersButtons.scss";

interface Props {
  providers: {
    label: string;
    onClick: () => void;
  }[];
  buttonLabelPrefix?: string;
}

const AuthProvidersButtons: FC<Props> = ({
  providers,
  buttonLabelPrefix,
}): JSX.Element => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const defaultButtonLabelPrefix = t("sign_in_with");

  const handleOnClick = async (provider: {
    label: string;
    onClick: () => void;
  }) => {
    if (!provider.onClick) return;

    setIsLoading(true);
    try {
      await provider.onClick();
    } catch (err) {
      console.log(err);
    }
  };

  const getIcon = (key: string) => {
    switch (key) {
      case "google":
        return <GoogleIcon />;
      case "facebook":
        return <FacebookIcon />;
      default:
        break;
    }
  };

  return (
    <div className="AuthProvidersButtons">
      <div className="buttonsContainer">
        {providers.map((provider) => {
          const providerIcon = getIcon(provider.label);
          return (
            <ProviderButton
              label={`${
                buttonLabelPrefix ? buttonLabelPrefix : defaultButtonLabelPrefix
              } ${_.startCase(provider.label)}`}
              icon={providerIcon}
              onClick={() => handleOnClick(provider)}
              provider={provider.label}
              key={provider.label}
            />
          );
        })}
      </div>
    </div>
  );
};

const ProviderButton: FC<{
  provider: string;
  icon: any;
  label: string;
  onClick: () => void;
}> = ({ provider, icon, label, onClick }) => {
  return (
    <div className={`ProviderButton ${provider}`} onClick={onClick}>
      <div className="icon">{icon}</div>
      <div className="label">{label}</div>
    </div>
  );
};

export default AuthProvidersButtons;
