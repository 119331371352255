import React, { useState } from "react";
import Switch from "@mui/material/Switch";

import "./SwitchInput.scss";

export interface SwitchInputProps {
  controller?: {
    name: any;
    field: any;
  };
  disabled?: boolean;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

export const SwitchInput = ({
  controller,
  disabled,
  checked = false,
  onChange,
}: SwitchInputProps) => {
  const handleChange = (e: any) => {
    if (controller) {
      controller?.field.onChange(e.target.checked);
    } else {
      const isChecked = e.target.checked;
      onChange && onChange(isChecked);
    }
  };

  return (
    <div className="SwitchInput">
      <Switch
        className="Switch"
        {...controller?.field}
        onChange={handleChange}
        checked={controller ? controller.field.value : checked}
        disabled={disabled}
      />
    </div>
  );
};
