import React, { useEffect, useState } from "react";
import classNames from "classnames";
import i18n from "./i18n";
import config from "./config";
import _ from "lodash";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import {
  FirebaseService,
  AuthService,
  firebase,
  NotificationService,
} from "./services/ServiceControler";
import FeatureFlagsService from "./services/FeatureFlagsService";
import CloudFunctionsService from "./services/CloudFunctionsService";

import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MeetingsPage from "./pages/MeetingsPages/MeetingsPage";
import MeetingsTrackingPage from "./pages/MeetingsPages/MeetingsTrackingPage";
import ProofingsTrackingPage from "./pages/MeetingsPages/ProofingsTrackingPage";
import TranscriberMeetingsPage from "./pages/MeetingsPages/TranscriberMeetingsPage";
import ClientMeetingsPage from "./pages/MeetingsPages/ClientMeetingsPage";
import SignUpPage from "./pages/AuthPages/SignUpPage";
import LoginPage from "./pages/AuthPages/LoginPage";
import PasswordRecoveryPage from "./pages/AuthPages/PasswordRecoveryPage";
import MfaPage from "./pages/AuthPages/MfaPage";
import NewMeetingPage from "./pages/NewMeetingPage/NewMeetingPage";
import UserProfilePage from "./pages/UserProfilePage/UserProfilePage";
import NewTranscriberPage from "./pages/NewTranscriberPage/NewTranscriberPage";
import NewClientPage from "./pages/NewClientPage/NewClientPage";
import TranscribersPage from "./pages/TranscribersPage/TranscribersPage";
import TranscriberPage from "./pages/TranscriberPage/TranscriberPage";
import ClientsPage from "./pages/ClientsPage/ClientsPage";
import ClientPage from "./pages/ClientPage/ClientPage";
import MyPaymentsPage from "./pages/PaymentsPages/MyPaymentsPage";
import ClientPaymentsPage from "./pages/PaymentsPages/ClientPaymentsPage";
import CustomPayment from "./pages/PaymentsPages/CustomPayment";
import ArchivePage from "./pages/ArchivePage/ArchivePage";
import InfiniteArchivePage from "./pages/ArchivePage/InfiniteArchivePage";
import MyJobs from "./pages/MeetingsPages/MyJobs";
import PendingTranscriber from "./pages/PendingTranscriber/PendingTranscriber";
import EditorPage from "./pages/EditorPage/EditorPage";
import Editor from "./pages/Editor/Editor";
import TranslationsEditor from "./pages/TranslationsEditor/TranslationsEditor";
import AdminPage from "./pages/AdminPage/AdminPage";
import ExportConfigPage from "./pages/ExportConfig/ExportConfigPage";

import sumitLogo from "./assets/sumit-logo.png";

import TopBar from "./components/TopBar/TopBar";
import IndicatorPopup from "./components/IndicatorPopup/IndicatorPopup";

import {
  setLoggedInUser,
  clearLoggedInUser,
  pushNotification,
  clearNotifications,
  updateNotification,
} from "./store/user/actions";
import { clearMeetings } from "./store/meeting/actions";
import {
  popIndicator,
  setDirection,
  setErrorReason,
} from "./store/system/actions";
import { NotificationData } from "./models/notification";
import { AppState } from "./store/rootReducer";

import { getLanguageDirection } from "./utils/locales";
import { ReactComponent as Logo } from "./assets/logo.svg";

import "./style/global.scss";

function App(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [hideTopBar, setHideTopBar] = useState(true);
  const allowedAuthPathes = ["logout", "password-recovery", "sign-up"];
  const userAuthRoles = ["super_user", "transcriber", "proofer"];
  const userSettings = useSelector(
    (state: AppState) => state.userStore.settings
  );
  const dir = useSelector((state: AppState) => state.systemStore.direction);
  const langChange = () => {
    if (userSettings.platformLanguage) {
      i18n.changeLanguage(userSettings.platformLanguage);
      dispatch(setDirection(userSettings.platformLanguage));
    }
  };
  useEffect(() => {
    langChange();
  }, [userSettings.platformLanguage]);
  useEffect(() => {
    dispatch(setDirection(i18n.language));
  }, []);

  const login = async (user: any) => {
    try {
      let authenticatedUser = await FirebaseService.getAuthenticatedUserInfo(
        user.uid
      );
      setIsLoading(false);
      if (!authenticatedUser) {
        redirectToLogin();
        return;
      }
      if (!authenticatedUser.phonenumber) {
        try {
          const phonenumber = AuthService.getPhonenumberFromAuthUser(user);
          if (phonenumber) {
            authenticatedUser = { ...authenticatedUser, phonenumber };
            await FirebaseService.updateUserPhone(phonenumber);
          }
          history.push(`/${config.mfaEnrollmentPath}`);
        } catch (err) {
          console.log(err);
        }
      }
      if (
        authenticatedUser &&
        FeatureFlagsService.isEnabled("enforceMfa") &&
        authenticatedUser?.mfaState !== "mfa_active"
      ) {
        // diffrentiate new and old users
        history.push(`/${config.mfaEnrollmentPath}`);
      } else {
        setHideTopBar(false);
      }
      mixpanel.identify(authenticatedUser.id);
      Sentry.setUser({
        uid: authenticatedUser.id,
        username: authenticatedUser.username,
        email: authenticatedUser.email || "",
      });
      if (!authenticatedUser.role) {
        history.push("my-profile");
      }
      // if (userAuthRoles.includes(authenticatedUser.role)) {
      //   const handleNotification = (notification: NotificationData) => {
      //     dispatch(pushNotification(notification));
      //   };
      //   // FirebaseService.openNotificationsPipe(
      //   //   authenticatedUser.id,
      //   //   authenticatedUser.creationTime,
      //   //   handleNotification
      //   // );
      //   NotificationService.getUserNotifications(authenticatedUser.id).then(
      //     (userNotifications) => {
      //       if (!userNotifications) return;
      //     }
      //   );
      // }
      if ("pending-transcriber" === authenticatedUser.role) {
        setHideTopBar(true);
        if (FeatureFlagsService.isEnabled("enforceMfa")) {
          const mfaStatus =
            authenticatedUser.phonenumber &&
            (await CloudFunctionsService.auth.getMfaStatusByPhone(
              authenticatedUser.phonenumber
            ));
          if (
            mfaStatus &&
            mfaStatus.emailVerified &&
            mfaStatus.multiFactor &&
            authenticatedUser.username &&
            authenticatedUser.country &&
            authenticatedUser.languages
          ) {
            history.push("/pending-transcriber");
          }
        } else {
          history.push("/pending-transcriber");
        }
      }
      dispatch(setLoggedInUser(authenticatedUser));
    } catch (err) {
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_could_not_login") })
      );
      throw new Error(err);
    }
  };

  const logout = () => {
    setIsLoading(false);
    dispatch(clearLoggedInUser());
    dispatch(clearMeetings());
    dispatch(clearNotifications());
    redirectToLogin();
  };

  const redirectToLogin = () => {
    if (!allowedAuthPathes.includes(window.location.pathname.slice(1))) {
      history.push("/login");
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      FirebaseService.setAnalyticsUser(user); // Refactor entire login func and relocate this line inside.
      if (user) {
        await login(user);
      } else {
        logout();
      }
    });
    const unlisten = history.listen(() => dispatch(setErrorReason(null)));
    langChange();
    return () => {
      dispatch(clearNotifications());
      unlisten();
    };
  }, []);

  return (
    <div className={classNames("app", dir)} key={i18n.language}>
      <TopBar hidden={hideTopBar} />
      {hideTopBar && (
        <div className="sumitLogoContainer">
          <img className="sumitLogo" src={sumitLogo}></img>
        </div>
      )}
      <IndicatorPopup />
      <Switch>
        <Route exact component={LoginPage} path="/login" />
        <Route
          exact
          component={PasswordRecoveryPage}
          path="/password-recovery"
        />
        <Route exact component={SignUpPage} path="/sign-up" />
        <Route
          exact
          component={MfaPage}
          path={`/${config.mfaEnrollmentPath}`}
        />
        <Route exact component={MeetingsPage} path="/" />
        <Route
          exact
          component={PendingTranscriber}
          path="/pending-transcriber"
        />
        <Route exact component={UserProfilePage} path="/my-profile" />
        <Route exact component={MyJobs} path="/my-jobs" />
        <Route
          exact
          component={TranscriberMeetingsPage}
          path="/transcriber/meetings/:id"
        />
        <Route
          exact
          component={ClientMeetingsPage}
          path="/client/meetings/:id"
        />

        {FeatureFlagsService.isEnabled("exportConfigurationV3") && (
          <Route
            exact
            render={(props) => (
              <ExportConfigPage key={"client"} allowSaveNew={true} {...props} />
            )}
            path="/client/:clientId/export-config/:presetId"
          />
        )}

        <Route exact component={NewMeetingPage} path="/new-meeting" />
        <Route exact component={TranscribersPage} path="/transcribers" />
        <Route exact component={TranscriberPage} path="/transcriber/:id" />
        <Route exact component={NewTranscriberPage} path="/new-transcriber" />
        <Route exact component={ClientsPage} path="/clients" />
        <Route exact component={NewClientPage} path="/new-client" />
        <Route exact component={ClientPage} path="/client/:id" />
        <Route exact component={MyPaymentsPage} path="/my-payments/" />
        <Route
          exact
          component={MyPaymentsPage}
          path="/transcriber/payments/:id"
        />
        <Route
          exact
          component={CustomPayment}
          path="/transcriber/payments/:id/new"
        />
        <Route
          exact
          component={ClientPaymentsPage}
          path="/client/payments/:id"
        />
        <Route
          exact
          component={
            FeatureFlagsService.isEnabled("infinityScroll")
              ? InfiniteArchivePage
              : ArchivePage
          }
          path="/archive/"
        />
        <Route exact component={EditorPage} path="/meeting/:id" />
        <Route exact component={Editor} path="/job/:id" />
        <Route exact component={Editor} path="/editor/:id" />

        {FeatureFlagsService.isEnabled("exportConfigurationV3") && (
          <Route
            exact
            render={(props) => (
              <ExportConfigPage allowSaveNew={false} key="global" {...props} />
            )}
            path="/export-config"
          />
        )}

        <Route exact component={TranslationsEditor} path="/translations" />
        <Route exact component={AdminPage} path="/admin" />
      </Switch>
      <div className={classNames("loadingLogo", { hidden: !isLoading })}>
        <Logo className={"logo"} />
      </div>
    </div>
  );
}

export default App;
