import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "../../models/client";
import TextInput from "../FormInputs/TextInput";
import SubmitButton from "../SubmitButton/SubmitButton";

import "./AddContactForm.scss";

interface Props {
  sendContact: (newContact: Contact) => void;
  selectedContact?: Contact;
}

const AddContactForm: FC<Props> = ({
  sendContact,
  selectedContact,
}): JSX.Element => {
  const { t } = useTranslation();

  const [fullname, setFullname] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");

  useEffect(() => {
    if (selectedContact) {
      setFullname(selectedContact.fullname);
      setRole(selectedContact.role);
      setMail(selectedContact.mail);
      setPhonenumber(selectedContact.phonenumber);
    }
  }, []);

  const submitNewContact = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newContact = {
      fullname,
      role,
      mail,
      phonenumber,
    };
    sendContact(newContact);
  };

  const submitBtnStr = selectedContact ? t("update_contact") : t("add_contact");

  return (
    <div className="add-contact-form-wrapper">
      {!selectedContact && (
        <form
          className="add-contact-form"
          onSubmit={(e) => submitNewContact(e)}
          action=""
        >
          <TextInput
            type={"text"}
            focus={true}
            className={"name"}
            onInput={setFullname}
            width={408}
            placeholder={t("full_name")}
            required={true}
            Icn={["fal", "user"]}
          />
          <TextInput
            type={"text"}
            className={"mail"}
            onInput={setMail}
            width={408}
            placeholder={t("mail_address")}
            required={true}
            Icn={["fal", "at"]}
          />
          <TextInput
            type={"text"}
            className={"role"}
            onInput={setRole}
            width={408}
            placeholder={t("role")}
            required={true}
            Icn={["fal", "folder-open"]}
          />
          <TextInput
            type={"text"}
            className={"phone ltr"}
            onInput={setPhonenumber}
            width={408}
            placeholder={t("phone_number")}
            required={true}
            Icn={["fal", "phone"]}
          />
          <SubmitButton text={submitBtnStr} Icn={["fal", "user"]} />
        </form>
      )}
      {!!selectedContact && (
        <form
          className="add-contact-form"
          onSubmit={(e) => submitNewContact(e)}
          action=""
        >
          <TextInput
            type={"text"}
            className={"name"}
            value={fullname}
            onInput={setFullname}
            width={408}
            placeholder={t("full_name")}
            required={true}
            Icn={["fal", "user"]}
          />
          <TextInput
            type={"text"}
            className={"mail"}
            value={mail}
            onInput={setMail}
            width={408}
            placeholder={t("mail_address")}
            required={true}
            Icn={["fal", "at"]}
          />
          <TextInput
            type={"text"}
            className={"role"}
            value={role}
            onInput={setRole}
            width={408}
            placeholder={t("role")}
            required={true}
            Icn={["fal", "folder-open"]}
          />
          <TextInput
            type={"text"}
            className={"phone ltr"}
            value={phonenumber}
            onInput={setPhonenumber}
            width={408}
            placeholder={t("phone_number")}
            required={true}
            Icn={["fal", "phone"]}
          />
          <SubmitButton text={submitBtnStr} Icn={["fal", "user"]} />
        </form>
      )}
    </div>
  );
};

export default AddContactForm;
