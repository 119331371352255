import React, { useCallback, useEffect, useRef, useState } from "react";
import _, { range } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { MarkerParams } from "wavesurfer.js/src/plugin/markers";

import { JobData } from "../../models/job";
import { JobRange } from "../../models/range";
import { JobMarkers } from "../../models/markers";

import SoundManager from "../../services/SoundManager";
import TimeService from "../../services/TimeService";

import TrackingService from "../../services/TrackingService";
import BarLoader from "../BarLoader/BarLoader";

import "./WaveformRangesV3.scss";
import FeatureFlagsService from "../../services/FeatureFlagsService";

interface Props {
  job: JobData;
  markers: JobMarkers;
  ranges: JobRange[];
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text" | "waveform";
  }) => void;
  jobId: string;
  focusRange: (rangeIndex: number, cursorPosition: number) => void;
  initialZoomValue: number;
  showZoomOption: boolean;
  duration: number;
}

const WaveformRangesV3: React.FC<Props> = ({
  job,
  ranges,
  markers,
  updateRangeTimes,
  jobId,
  focusRange,
  initialZoomValue,
  showZoomOption,
  duration,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [zoomValue, setZoomValue] = useState<number>(initialZoomValue);
  const updateRangeTimesStart = useRef<number | null>(null);

  useEffect(() => {
    let peaks = localStorage.getItem(jobId);
    peaks = peaks ? JSON.parse(peaks) : null;

    SoundManager.initWaveform(jobId, duration, peaks);

    updateWaveformRanges();
    updateMarkers();

    return () => {
      // SoundManager.wavesurfer?.destroy();
      SoundManager.wavesurfer = null;
    };
  }, []);

  useEffect(() => {
    SoundManager.wavesurfer?.un("waveform-ready", _.noop);
    SoundManager.wavesurfer?.on("waveform-ready", () => {
      setIsLoading(false);
      savePeaks();
    });
    SoundManager.wavesurfer?.un("ready", _.noop);
    SoundManager.wavesurfer?.on("ready", () => {
      // const savedPeaks = localStorage.getItem(jobId);
      // if (savedPeaks) {
      //   setIsLoading(false);
      // }
    });
    SoundManager.wavesurfer?.un("region-dblclick", _.noop);
    SoundManager.wavesurfer?.on("region-dblclick", (range) =>
      onRangeDoubleClick(Number(range.data.rangeIndex))
    );
    SoundManager.wavesurfer?.un("loading", _.noop);
    SoundManager.wavesurfer?.on("loading", (progress: number) =>
      setLoadingProgress(progress)
    );
  }, [SoundManager.wavesurfer]);

  useEffect(() => {
    SoundManager.wavesurfer?.zoom(zoomValue);
  }, [zoomValue]);

  useEffect(() => {
    const waveformRanges = generateWaveformRanges();

    if (waveformRanges.length > 0) {
      SoundManager.createWaveformRanges(waveformRanges);
    }
  }, [ranges]);

  const updateWaveformRanges = () => {
    const waveformRanges = generateWaveformRanges();

    if (waveformRanges.length > 0) {
      SoundManager.createWaveformRanges(waveformRanges);
    }

    SoundManager.wavesurfer?.un("region-update-end", _.noop);
    SoundManager.wavesurfer?.on("region-update-end", (range) => {
      handleUpdateRangeTimes({
        rangeIndex: range.data.rangeIndex,
        start: range.start,
        end: range.end,
      });
    });

    SoundManager.wavesurfer?.un("region-updated", _.noop);
    SoundManager.wavesurfer?.on("region-updated", preventRangeOverlapping);
    SoundManager.wavesurfer?.on("region-updated", updateRegionTimes);
  };

  const updateMarkers = () => {
    if (!FeatureFlagsService.isEnabled("waveformMarkers")) return;
    for (const markersType in markers) {
      if (Object.prototype.hasOwnProperty.call(markers, markersType)) {
        const markersData = markers[markersType];
        SoundManager.createWaveformMarkers(markersData, handleMarkerClick);
      }
    }
  };

  const handleMarkerClick = (marker: MarkerParams) => {
    SoundManager.setOffset(marker.time);
  };

  const generateWaveformRanges = () => {
    const waveformRanges = ranges.map((range, rangeIndex) => {
      if (range.st > duration || range.et > duration) return null;
      return {
        rangeIndex,
        start: range.st,
        end: range.et,
      };
    });

    return _.compact(waveformRanges);
  };

  const savePeaks = () => {
    const savedPeaks = localStorage.getItem(jobId);
    if (savedPeaks) return;

    const peaks = _.get(SoundManager, "wavesurfer.backend.mergedPeaks");
    // FirebaseService.saveRoomPeaks(jobId, peaks as number[])
    // if (peaks) {
    //   localStorage.setItem(jobId, JSON.stringify(peaks));
    // }
  };

  const preventRangeOverlapping = (region: {
    data: { startLimit: number; endLimit: number };
    start: number;
    end: number;
    isDragging: boolean;
    isResizing: boolean;
  }) => {
    if (!region.data) return;

    if (!updateRangeTimesStart.current) {
      updateRangeTimesStart.current = Date.now();
    }

    const regionLength = region.end - region.start;

    if (region.start < region.data.startLimit) {
      region.start = region.data.startLimit;
      if (region.isDragging) {
        region.end = region.data.startLimit + regionLength;
      }
    }

    if (region.end > region.data.endLimit && region.data.endLimit) {
      region.end = region.data.endLimit;
      if (region.isDragging) {
        region.start = region.data.endLimit - regionLength;
      }
    }
  };

  const updateRegionTimes = (region: {
    start: number;
    end: number;
    attributes: { rangeTimes: string };
  }) => {
    region.attributes = {
      ...region.attributes,
      rangeTimes: `${TimeService.getTimeStringFromSecs(
        region.start,
        true,
        true
      )} - ${TimeService.getTimeStringFromSecs(region.end, true, true)}`,
    };
  };

  const handleUpdateRangeTimes = ({
    rangeIndex,
    start,
    end,
  }: {
    rangeIndex: number;
    start: number;
    end: number;
  }) => {
    const updatedRanges = [...ranges];

    const startTimeFrameRateFixed = TimeService.getFixedFrameRateTime({
      time: start,
      frameRate: SoundManager.frameRate,
    });
    const endTimeFrameRateFixed = TimeService.getFixedFrameRateTime({
      time: end,
      frameRate: SoundManager.frameRate,
    });

    updateRangeTimes({
      rangeIndex,
      start: startTimeFrameRateFixed,
      end: endTimeFrameRateFixed,
      method: "waveform",
    });

    reportTimeUpdate({
      oldStart: ranges[rangeIndex].st,
      newStart: start,
      oldEnd: ranges[rangeIndex].et,
      newEnd: end,
    });
  };

  const reportTimeUpdate = ({
    oldStart,
    oldEnd,
    newStart,
    newEnd,
  }: {
    [key: string]: number;
  }) => {
    const isRangeDragging = oldStart !== newStart && oldEnd !== newEnd;
    const changedPosition = isRangeDragging
      ? "both"
      : oldStart !== newStart
      ? "start"
      : "end";
    const timeDiff =
      isRangeDragging || changedPosition === "start"
        ? newStart - oldStart
        : newEnd - oldEnd;

    const eventDuration = updateRangeTimesStart.current
      ? Date.now() - updateRangeTimesStart.current
      : 0;

    TrackingService.reportEvent(
      "range_time_change_start",
      {
        time_diff: timeDiff,
        time_position: changedPosition,
        change_method: "waveform",
        timestamp: updateRangeTimesStart.current,
      },
      job
    );

    TrackingService.reportEvent(
      "range_time_change_end",
      {
        time_diff: timeDiff,
        time_position: changedPosition,
        change_method: "waveform",
        event_duration: eventDuration,
      },
      job
    );

    updateRangeTimesStart.current = null;
  };

  const handleZoom = (zoom: "in" | "out", val?: number) => {
    if (!SoundManager.wavesurfer) return;

    const newZoomValue = val
      ? val
      : zoom === "in"
      ? zoomValue + 10
      : zoomValue - 10;

    if (newZoomValue >= 0) {
      setZoomValue(newZoomValue);
    }
  };

  const onRangeDoubleClick = (rangeIndex: number) => {
    focusRange(rangeIndex, 0);
  };

  return (
    <div className="WaveformRanges">
      <div id="waveformWrapper" className={classNames({ hidden: isLoading })}>
        {showZoomOption && (
          <div className="zoomContainer">
            <div className="zoomIn zoomButton" onClick={() => handleZoom("in")}>
              <FontAwesomeIcon icon={["fas", "plus-circle"]} />
            </div>
            <div
              className="zoomOut zoomButton"
              onClick={() => handleZoom("out")}
            >
              <FontAwesomeIcon icon={["fas", "minus-circle"]} />
            </div>
            <div className="zoomValue">{zoomValue}%</div>
          </div>
        )}
        <div className="rangesContainer"></div>
        <div id="waveform" className="waveformContainer"></div>
        <div id="wave-timeline"></div>
      </div>
      {isLoading && (
        <div className="loaderContainer">
          <BarLoader isAnimating={true} progress={loadingProgress} />
        </div>
      )}
    </div>
  );
};

export default WaveformRangesV3;
