import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

import "./MultiTextInput.scss";

export interface MultiTextInputProps {
  controller?: {
    name: any;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
  };
}

export const MultiTextInput = ({ controller }: MultiTextInputProps) => {
  const [inputs, setInputs] = useState<string[]>([""]);

  useEffect(() => {
    if (controller) {
      const newInputs = controller.getValues(controller.name);
      if (newInputs.length > 0) setInputs(newInputs);
    }
  }, [controller?.getValues]);

  const handleChange = (value: string, index: number) => {
    if (controller) {
      const newInputs = inputs;
      newInputs[index] = value;
      setInputs([...newInputs]);
      controller.setValue(controller.name, [...newInputs]);
    }
  };

  const handleRemove = (index: number) => {
    if (controller) {
      const newInputs = inputs.filter((input, i) => index !== i);
      setInputs([...newInputs]);
      controller.setValue(controller.name, [...newInputs]);
    }
  };

  const handleAdd = () => {
    if (controller) {
      const newInputs = inputs;
      newInputs.push("");
      setInputs([...newInputs]);
      controller.setValue(controller.name, [...newInputs]);
    }
  };

  return (
    <div className="MultiTextInput">
      {inputs.map((input, i) => (
        <div key={i} className="eachInputWrapper">
          {i !== inputs.length - 1 ? (
            <FontAwesomeIcon
              icon={["far", "minus-circle"]}
              onClick={() => handleRemove(i)}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              icon={["far", "plus-circle"]}
              onClick={handleAdd}
            ></FontAwesomeIcon>
          )}
          <input
            type={"text"}
            value={input}
            onChange={(e: any) => handleChange(e.target.value, i)}
          />
        </div>
      ))}
    </div>
  );
};
