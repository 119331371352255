import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import FeatureFlagsService from "../../services/FeatureFlagsService";
import Logger from "../../services/Logger";
import FirebaseService from "../../services/FirebaseService";
import EditorService from "../../services/EditorService";

import { SpeakerSample, Word } from "../../models";
import { AppState } from "../../store/rootReducer";
import { JobData } from "../../models/job";
import { Action } from "../../models/editor";
import { Annotation } from "../../models/annotations";
import { SpeakerRange, JobRange } from "../../models/range";

import JobSpeakersPanel from "./JobSpeakersComponent";
import RangeSpeaker from "../../components/TextRange/RangeSpeaker";
import MessageModal from "../../components/MessageModal/MessageModal";
import SpeakerSamples from "../../components/SpeakerSamples/SpeakerSamples";
import RangeAnnotation from "../../components/RangeAnnotation/RangeAnnotation";

import {
  focusAndSetCursor,
  scrollInto,
  scrollToOffset,
} from "../../utils/focusAndScroll";

const logger = Logger("EditorPage");

interface Props {
  job: JobData;
  setJob: (job: JobData) => void;
  saveJob: (saveMethod?: string) => Promise<void>;
  ranges: SpeakerRange[];
  updateRanges: (ranges: SpeakerRange[], rangeIndex?: number) => void;
  focusedRangeIndex: number;
  setFocusedRangeIndex: (rangeIx: number) => void;
  setFocusedRangeWordsString: React.Dispatch<React.SetStateAction<string>>;
  addActions: (actions: Action[]) => void;
  currentTime: number;
  setIsChanged: (isChanged: boolean) => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  editorDirection: "rtl" | "ltr";
  setIsLoading: (isLoading: string | boolean) => void;
  isDisabled: boolean;
  toast: (msg: string, type: "success" | "error" | "info") => void;
  allowTimeEdit?: boolean;
}

const Protocol: FC<Props> = ({
  job,
  setJob,
  saveJob,
  ranges,
  updateRanges,
  focusedRangeIndex,
  setFocusedRangeIndex,
  setFocusedRangeWordsString,
  addActions,
  currentTime,
  setIsChanged,
  updateRangeTimes,
  editorDirection,
  setIsLoading,
  isDisabled,
  toast,
  allowTimeEdit,
}): JSX.Element => {
  const { t } = useTranslation();

  const [speakerSamples, setSpeakerSamples] = useState<SpeakerSample[] | null>(
    null
  );

  const [showSpeakerSamplesModal, setShowSpeakerSamplesModal] = useState(false);
  const [showRemapModal, setShowRemapModal] = useState(false);

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const protocolActions = [
    {
      key: "speakerSamples",
      label: t("speakers_samples"),
      icon: ["fal", "users"],
      onClick: () => setShowSpeakerSamplesModal(true),
    },
    {
      key: "remap",
      label: t("remap"),
      icon: ["fal", "stream"],
      onClick: () => setShowRemapModal(true),
    },
  ];
  useEffect(() => {
    addActions(protocolActions);
    FirebaseService.getSpeakerSamples(job.clientId).then((samples) =>
      setSpeakerSamples(samples)
    );
    if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
      const {
        cursorPosition,
        rangeIx,
        scrollOffsetTop,
      } = EditorService.getLastPosition(job.roomId);
      scrollToOffset("editorContainer", scrollOffsetTop);

      focusAndSetCursor(rangeIx, cursorPosition);
    }
  }, []);

  // KeyPress Handlers
  const onPressEnter = ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    range,
    event,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    range: JobRange;
    event: React.KeyboardEvent;
  }) => {
    breakRange({
      rangeIndex,
      updatedRangeWords,
      selectedWordIndex,
      wordCharIndex,
      range,
      event,
    });

    scrollInto(`range-${rangeIndex + 1}`);
  };

  // Action Handlers

  const handleRemap = async () => {
    try {
      setIsLoading(t("loading_remap") as string);
      await saveJob("remap");
      const remap = await EditorService.remapJob(job.roomId, job.meetingLength);
      if (remap.success) {
        toast(t("remap_success"), "success");
        setIsLoading(t("reloading...") as string);
        setTimeout(() => location.reload(), 2000);
      } else {
        throw new Error(remap.msg);
      }
    } catch (error) {
      toast(t("remap_fail" as string), "error");
      setIsLoading(false);
    }
  };

  // Handlers

  // KeyPress Handlers

  // -Protocol Range Handlers
  const changeRangeSpeaker = (
    rangeIndex: number,
    speakerName: string | null
  ) => {
    const updatedRanges = _.clone(ranges);
    updatedRanges[rangeIndex].speakerName = speakerName;
    updatedRanges[rangeIndex].speakerNameEdit = false;

    setIsChanged(true);

    updateRanges(updatedRanges);

    if (speakerName && !job.speakers.includes(speakerName)) {
      const jobSpeakers = [...job.speakers, speakerName];
      const updatedJob = {
        ...job,
        ranges: updatedRanges,
        speakers: jobSpeakers,
      };

      setJob(updatedJob);
    }
    // TRACKING SERVICE
  };

  const renameSpeaker = (
    oldSpeaker: string,
    newSpeaker: string,
    allSpeakers: string[],
    mergeSpeakerRanges = false
  ) => {
    const updatedRanges: SpeakerRange[] = [];

    let shouldMergeRange = false;
    for (const [i, range] of ranges.entries()) {
      const speakerName = range.speakerName;
      const updatedRange = { ...range };
      if (speakerName && [oldSpeaker, newSpeaker].includes(speakerName)) {
        const lastRange = _.last(updatedRanges);
        if (mergeSpeakerRanges && shouldMergeRange && lastRange) {
          lastRange.words = [...lastRange?.words, ...range.words];
          lastRange.et = range.et;
          continue;
        } else {
          updatedRange.speakerName = newSpeaker;
          shouldMergeRange = true;
        }
      } else {
        shouldMergeRange = false;
      }
      updatedRanges.push(updatedRange);
    }

    const newSpeakers = job.speakers.filter((s) => s !== oldSpeaker);
    newSpeakers.push(newSpeaker);

    updateRanges(updatedRanges);

    setJob({
      ...job,
      speakers: newSpeakers,
      ranges: updatedRanges,
    });
    setIsChanged(false);
    return true;
  };

  const breakRange = async ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    range,
    event,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    range?: JobRange;
    event: React.KeyboardEvent;
  }) => {
    const {
      selectionStart,
      selectionEnd,
    } = event.target as HTMLTextAreaElement;
    if (!updatedRangeWords[selectedWordIndex]) return; //created in live-interview - check it
    const textLength = _.get(event.target, "textLength");
    const isCarretInEndOfWord =
      updatedRangeWords[selectedWordIndex].text.length === wordCharIndex;
    const breakIndex = isCarretInEndOfWord
      ? selectedWordIndex + 1
      : selectedWordIndex;

    if (selectionStart === textLength) {
      if (event.ctrlKey) {
        createNewAnnotation(rangeIndex, true);
      }
      return;
    }

    if (selectionStart === 0 && rangeIndex > 0) {
      if (event.ctrlKey) {
        createNewAnnotation(rangeIndex - 1);
      }
      return;
    }

    const wordToBreak =
      wordCharIndex > 0 &&
      !isCarretInEndOfWord &&
      _.clone(updatedRangeWords[breakIndex]);

    const firstPartWords = _.clone(updatedRangeWords);
    const secondPartWords = firstPartWords.splice(breakIndex);

    if (wordToBreak) {
      wordToBreak.text = wordToBreak.text.slice(0, wordCharIndex);
      secondPartWords[0].text = secondPartWords[0].text.slice(
        wordCharIndex,
        secondPartWords[0].text.length
      );
      firstPartWords.push(wordToBreak);
    }

    const existingRange = ranges[rangeIndex] as SpeakerRange;

    const firstPartRange = EditorService.createNewSpeakerRange({
      words: firstPartWords,
      speakerName: existingRange.speakerName,
      speakerId: existingRange.speakerId,
      six: 0,
      eix: 0,
    });

    const secondPartRange = EditorService.createNewSpeakerRange({
      words: secondPartWords,
      six: 0,
      eix: 0,
      speakerNameEdit: true,
      annotations: existingRange.annotations,
    });

    if (range?.time_edit) {
      firstPartRange.time_edit = true;
      // REVERT OMIT AFTER ALGO TAKES time_edit FROM RANGE (INSTEAD OF WORD)
      firstPartRange.words[0].time_edit = true;
    }

    if (event && event.ctrlKey) {
      // Create annotation between ranges
      const newAnnotation = EditorService.createNewAnnotation(rangeIndex);
      firstPartRange.annotations.push(newAnnotation);
      secondPartRange.speakerName = firstPartRange.speakerName;
      secondPartRange.speakerNameEdit = false;
    }

    if (_.isEmpty(firstPartRange.words) || _.isEmpty(secondPartRange.words)) {
      logger.error("breakRange: Empty words range!!!");
    }

    const updatedRanges = _.clone(ranges);
    updatedRanges.splice(rangeIndex, 1, firstPartRange, secondPartRange);

    updateRanges(updatedRanges);
  };

  const mergeRange = (
    rangeIndex: number,
    words: Word[] = job.words,
    mergeWithNextRange = false
  ) => {
    const updatedRanges = _.clone(ranges);
    updatedRanges.splice(rangeIndex, 1);
    const rangeToMergeInto = updatedRanges[
      mergeWithNextRange ? rangeIndex : rangeIndex - 1
    ] as SpeakerRange;

    rangeToMergeInto.words = mergeWithNextRange
      ? [...words, ...rangeToMergeInto.words]
      : [...rangeToMergeInto.words, ...words];

    rangeToMergeInto.speakerName =
      ranges[mergeWithNextRange ? rangeIndex : rangeIndex - 1].speakerName;

    if (_.isEmpty(rangeToMergeInto.words)) {
      logger.error("mergeRange: Empty words range!!!");
    }

    updateRanges(updatedRanges);
  };

  const updateRangeWords = (rangeIndex: number, rangeWords: Word[]) => {
    const updatedRanges = _.clone(ranges);
    const updatedRange = { ...ranges[rangeIndex], words: rangeWords };
    _.set(updatedRanges, `[${rangeIndex}]`, updatedRange);

    updateRanges(updatedRanges, rangeIndex);
  };
  // Protocol Range Handlers

  // Annotations Handlers
  const createNewAnnotation = (rangeIndex: number, unshift = false) => {
    const updatedRanges = _.clone(ranges);
    const newAnnotation = EditorService.createNewAnnotation(rangeIndex);
    if (unshift) {
      updatedRanges[rangeIndex].annotations.unshift(newAnnotation);
    } else {
      updatedRanges[rangeIndex].annotations.push(newAnnotation);
    }

    updateRanges(updatedRanges);
  };

  const updateAnnotation = (
    rangeIndex: number,
    annotationIndex: number,
    annotation: Annotation
  ) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges[rangeIndex].annotations[annotationIndex] = annotation;

    updateRanges(updatedRanges);
  };

  const deleteAnnotation = (rangeIndex: number, annotationIndex: number) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges[rangeIndex].annotations.splice(annotationIndex, 1);

    updateRanges(updatedRanges);
  };
  // -Annotations Handlers

  return (
    <>
      <JobSpeakersPanel speakers={job.speakers} renameSpeaker={renameSpeaker} />
      <div className="ProtocolEditor">
        <div id="editorContainer" className="editorRanges protocolRanges">
          {ranges.map((range, rangeIndex) => (
            <LazyLoad
              once
              height={100}
              offset={
                // Determain divs height by its word count
                ((range.words && range.words.length * 1.3) || 400) + 1000
              }
              scrollContainer="#editorContainer"
              unmountIfInvisible={rangeIndex !== focusedRangeIndex}
              key={range.id}
            >
              <RangeSpeaker
                job={job}
                ranges={ranges}
                range={range as SpeakerRange}
                updateRangeWords={updateRangeWords}
                rangesCount={
                  _.filter(ranges, (r) => r.type === "speaker").length
                }
                changeRangeSpeaker={changeRangeSpeaker}
                onPressEnter={onPressEnter}
                mergeRange={mergeRange}
                updateRangeTimes={updateRangeTimes}
                isPassed={Number(currentTime) > Number(range.et)}
                rangeIndex={rangeIndex}
                setFocusedRangeIndex={setFocusedRangeIndex}
                isTemporarySpeaker={!!range.speakerNameEdit}
                direction={editorDirection}
                disabled={isDisabled}
                allowTimeEdit={allowTimeEdit}
              />
              {_.map(range.annotations, (annotation: Annotation, i: number) => (
                <RangeAnnotation
                  annotation={annotation}
                  rangeIndex={rangeIndex}
                  annotationIndex={i}
                  updateAnnotation={updateAnnotation}
                  deleteAnnotation={deleteAnnotation}
                  direction={editorDirection}
                  isTemp={!!annotation.temp}
                  disabled={isDisabled}
                  key={annotation.id}
                />
              ))}
            </LazyLoad>
          ))}
        </div>
        <MessageModal
          className="speakerSamples"
          title={t("speakers_samples")}
          body={<SpeakerSamples speakers={speakerSamples} />}
          showModal={showSpeakerSamplesModal}
          cancel={{
            text: t("close"),
            action: () => setShowSpeakerSamplesModal(false),
          }}
        />
        <MessageModal
          className="remap"
          title={t("remap")}
          body={t("remap_message")}
          showModal={showRemapModal}
          approve={{
            text: t("approve"),
            action: handleRemap,
          }}
          cancel={{
            text: t("close"),
            action: () => setShowRemapModal(false),
          }}
        />
      </div>
    </>
  );
};

export default Protocol;
