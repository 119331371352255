import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { AppState } from "../../store/rootReducer";

import BarLoader from "../BarLoader/BarLoader";

import { Word } from "../../models";

import SoundManager from "../../services/SoundManager";
import TimeService from "../../services/TimeService";
import TrackingService from "../../services/TrackingService";
import FeatureFlagsService from "../../services/FeatureFlagsService";

import "./WaveformRanges.scss";

interface Props {
  ranges: number[];
  timeRanges: {
    start: number;
    end: number;
    rangeIndex: number;
    overlapping: boolean;
  }[];
  words: Word[];
  updateRoomWords: (roomWords: Word[]) => void;
  roomId: string;
  focusRange: (rangeIndex: number, cursorPosition: number) => void;
  initialZoomValue: number;
  showZoomOption: boolean;
}

const WaveformRanges: React.FC<Props> = ({
  ranges,
  timeRanges,
  words,
  updateRoomWords,
  roomId,
  focusRange,
  initialZoomValue,
  showZoomOption,
}) => {
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [zoomValue, setZoomValue] = useState<number>(initialZoomValue);
  const updateRangeTimesStart = useRef<number | null>(null);

  useEffect(() => {
    let peaks = localStorage.getItem(roomId);
    peaks = peaks ? JSON.parse(peaks) : null;

    {
      /* REMOVE FEATURE FLAG */
    }
    SoundManager.initWaveform(roomId, 0, peaks);
    return () => {
      // SoundManager.wavesurfer?.destroy();
      SoundManager.wavesurfer = null;
    };
  }, []);

  useEffect(() => {
    //@ts-ignore
    SoundManager.wavesurfer?.un("waveform-ready");
    SoundManager.wavesurfer?.on("waveform-ready", () => {
      setIsLoading(false);
      savePeaks();
    });
    //@ts-ignore
    SoundManager.wavesurfer?.un("ready");
    SoundManager.wavesurfer?.on("ready", () => {
      const savedPeaks = localStorage.getItem(roomId);
      if (savedPeaks) {
        setIsLoading(false);
      }
    });
    //@ts-ignore
    SoundManager.wavesurfer?.un("region-dblclick");
    SoundManager.wavesurfer?.on("region-dblclick", (range) =>
      onRangeDoubleClick(Number(range.id))
    );
    //@ts-ignore
    SoundManager.wavesurfer?.un("loading");
    SoundManager.wavesurfer?.on("loading", (progress: number) =>
      setLoadingProgress(progress)
    );
  }, [SoundManager.wavesurfer]);

  useEffect(() => {
    SoundManager.wavesurfer?.zoom(zoomValue);
  }, [zoomValue]);

  useEffect(() => {
    updateWaveformRanges(words, ranges);
  }, [timeRanges]);

  const updateWaveformRanges = (
    updatedWords: Word[],
    updatedRanges: number[]
  ) => {
    // const rangesWords = getRangesWords(updatedWords, updatedRanges);

    if (timeRanges?.length > 0) {
      SoundManager.createWaveformRanges(timeRanges);
    }

    //@ts-ignore
    SoundManager.wavesurfer?.un("region-update-end");
    SoundManager.wavesurfer?.on("region-update-end", handleRangeTimeUpdate);

    //@ts-ignore
    SoundManager.wavesurfer?.un("region-updated");
    SoundManager.wavesurfer?.on("region-updated", preventRangeOverlapping);
    SoundManager.wavesurfer?.on("region-updated", updateRangeTimes);
  };

  const savePeaks = () => {
    const savedPeaks = localStorage.getItem(roomId);
    if (savedPeaks) return;

    const peaks = _.get(SoundManager, "wavesurfer.backend.mergedPeaks");
    // FirebaseService.saveRoomPeaks(roomId, peaks as number[])
    if (peaks) {
      localStorage.setItem(roomId, JSON.stringify(peaks));
    }
  };

  const getRangesWords = (updatedWords: Word[], updatedRanges: number[]) => {
    const rangesWordsArr = [];

    for (let i = 0; i < updatedRanges.length; i++) {
      if (i === 0) {
        rangesWordsArr.push(getRangeString(0, updatedRanges[1]));
        continue;
      }
      if (i === updatedRanges.length - 1) {
        rangesWordsArr.push(
          getRangeString(updatedRanges[i], updatedWords.length - 1)
        );
        continue;
      }
      rangesWordsArr.push(
        getRangeString(updatedRanges[i], updatedRanges[i + 1])
      );
    }

    return rangesWordsArr;
  };

  const getRangeString = (start: number, end: number) => {
    const rangeString = words
      .slice(start, end)
      .map((w) => w.text)
      .join(" ");
    return rangeString.length > 100
      ? `${rangeString.slice(0, 100)}...`
      : rangeString;
  };

  const preventRangeOverlapping = (region: {
    data: { startLimit: number; endLimit: number };
    start: number;
    end: number;
    isDragging: boolean;
    isResizing: boolean;
  }) => {
    if (!region.data) return;

    if (!updateRangeTimesStart.current) {
      updateRangeTimesStart.current = Date.now();
    }

    const regionLength = region.end - region.start;

    if (region.start < region.data.startLimit) {
      region.start = region.data.startLimit;
      if (region.isDragging) {
        region.end = region.data.startLimit + regionLength;
      }
    }

    if (region.end > region.data.endLimit && region.data.endLimit) {
      region.end = region.data.endLimit;
      if (region.isDragging) {
        region.start = region.data.endLimit - regionLength;
      }
    }
  };

  const updateRangeTimes = (region: {
    start: number;
    end: number;
    attributes: { rangeTimes: string };
  }) => {
    region.attributes = {
      ...region.attributes,
      rangeTimes: `${TimeService.getTimeStringFromSecs(
        region.start,
        true,
        true
      )} - ${TimeService.getTimeStringFromSecs(region.end, true, true)}`,
    };
  };

  const handleRangeTimeUpdate = ({
    id,
    start,
    end,
  }: {
    id: string;
    start: number;
    end: number;
  }) => {
    const rangeIndex = Number(id);

    const updatedMeetingWords = words;
    const startWordIndex = ranges[rangeIndex];
    const endWordIndex = ranges[rangeIndex + 1]
      ? ranges[rangeIndex + 1] - 1
      : updatedMeetingWords.length - 1;

    const oldStart = updatedMeetingWords[startWordIndex].start;
    const oldEnd = updatedMeetingWords[endWordIndex].end;

    updatedMeetingWords[startWordIndex].start = start;
    updatedMeetingWords[endWordIndex].end = end;

    updateRoomWords(updatedMeetingWords);

    reportTimeUpdate({
      oldStart,
      newStart: start,
      oldEnd,
      newEnd: end,
    });
  };

  const reportTimeUpdate = ({
    oldStart,
    oldEnd,
    newStart,
    newEnd,
  }: {
    [key: string]: number;
  }) => {
    const isRangeDragging = oldStart !== newStart && oldEnd !== newEnd;
    const changedPosition = isRangeDragging
      ? "both"
      : oldStart !== newStart
      ? "start"
      : "end";
    const timeDiff =
      isRangeDragging || changedPosition === "start"
        ? newStart - oldStart
        : newEnd - oldEnd;

    const eventDuration = updateRangeTimesStart.current
      ? Date.now() - updateRangeTimesStart.current
      : 0;

    updateRangeTimesStart.current = null;
  };

  const handleZoom = (zoom: "in" | "out", val?: number) => {
    if (!SoundManager.wavesurfer) return;

    const newZoomValue = val
      ? val
      : zoom === "in"
      ? zoomValue + 10
      : zoomValue - 10;

    if (newZoomValue >= 0) {
      setZoomValue(newZoomValue);
    }
  };

  const onRangeDoubleClick = (rangeIndex: number) => {
    focusRange(rangeIndex, 0);
  };

  return (
    <div className="WaveformRanges">
      <div id="waveformWrapper" className={classNames({ hidden: isLoading })}>
        {showZoomOption && (
          <div className="zoomContainer">
            <div className="zoomIn zoomButton" onClick={() => handleZoom("in")}>
              <FontAwesomeIcon icon={["fas", "plus-circle"]} />
            </div>
            <div
              className="zoomOut zoomButton"
              onClick={() => handleZoom("out")}
            >
              <FontAwesomeIcon icon={["fas", "minus-circle"]} />
            </div>
            <div className="zoomValue">{zoomValue}%</div>
          </div>
        )}
        <div className="rangesContainer"></div>
        <div id="waveform" className="waveformContainer"></div>
        <div id="wave-timeline"></div>
      </div>
      {isLoading && (
        <div className="loaderContainer">
          <BarLoader isAnimating={true} progress={loadingProgress} />
        </div>
      )}
    </div>
  );
};

export default WaveformRanges;
