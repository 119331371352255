import React from "react";

import { getDocumentDate } from "../ExportConfigHelpers";
import { ExportConfigCalendars } from "../ExportConfigTypes";
import { PreviewItem } from "../Preview";

export interface JobDateProps {
  lang: string;
  jobDatePreviewItem: PreviewItem;
  calendars: ExportConfigCalendars[];
  style: React.CSSProperties;
}

export const JobDate = ({
  lang,
  jobDatePreviewItem,
  calendars,
  style,
}: JobDateProps) => {
  return jobDatePreviewItem?.show ? (
    <div
      className="paragraph"
      style={{ ...style, justifyContent: jobDatePreviewItem.align }}
    >
      {getDocumentDate(lang, calendars, jobDatePreviewItem.content as Date)}
    </div>
  ) : (
    <></>
  );
};
